import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import FaDownload from 'react-icons/lib/md/file-download';

import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from './Press.scss';
// eslint-disable-next-line css-modules/no-unused-class
import whitepaperButtonStyle from '../WhitepaperButton/WhitepaperButton.scss';
import messages from './messages';

const renderParagraph = (header, content) => (
  <div>
    <h2>{header}</h2>
    <p className={s.paragraph}>{content}</p>
  </div>
);

const externalLinks = [
  {
    year: '2023',
    links: [
      {
        title: 'Report Nachbericht Podium BRZ (german)',
        link:
          'https://www.report.at/plus/22080-ki-in-der-anwendung-der-nachbericht-zum-publikumsgespraech',
      },
      {
        title: 'Artikel in "Der Standard" (german)',
        link:
          'https://www.derstandard.at/consent/tcf/story/2000145192279/wo-sich-kuenstliche-intelligenz-auf-das-leben-in-wien-auswirkt',
      },
      {
        title: 'Brutkasten Artikel über ChatGPT (german)',
        link:
          'https://brutkasten.com/chatgpt-wird-2023-das-jahr-der-neuen-ai/3/',
      },
    ],
  },
  {
    year: '2022',
    links: [
      {
        title: 'Youtube-Introduction to danube.ai (english, ca 15 min)',
        link: 'https://www.youtube.com/watch?v=o3cUSq-HW1Q',
      },
      {
        title: 'Artikel über danube.ai in eCommerce Webshops (german)',
        link:
          'https://medianet.at/news/marketing-and-media/mit-empathischer-ki-zum-top-webshop-50855.html',
      },
      {
        title:
          'Artikel über Choice Overload und wie danube.ai helfen kann (german)',
        link:
          'https://www.logistik-express.com/wie-viel-energie-kosten-uns-kaufentscheidungen-und-wie-kuenstliche-intelligenz-helfen-kann/',
      },
    ],
  },
];

const renderLinks = (year, links) => (
  <div className={s.externalLinks}>
    <h3>{year}</h3>
    <ul>
      {links.map(el => (
        <li>
          <span className={s.articleLine}>
            <a href={el.link} target="_blank" rel="noopener noreferrer">
              {el.title}
            </a>
          </span>
        </li>
      ))}
    </ul>
  </div>
);

class Press extends React.Component {
  render() {
    return (
      <div className={s.pressContainer}>
        <Row className={layoutStyle.noMargin}>
          <Col md={5}>
            <h1>
              <FormattedMessage {...messages.pressHeader} />
            </h1>
          </Col>
          <Col md={7}>
            {renderParagraph(
              <FormattedMessage {...messages.logoVariations} />,
              <a href="/danube_press_photos_2022.zip">
                <button
                  className={`btn-blue-white btn-high btn-medium btn-full-round ${
                    whitepaperButtonStyle.whitepaperButton
                  }`}
                >
                  <FaDownload className={whitepaperButtonStyle.icon} />
                  <FormattedMessage
                    className={whitepaperButtonStyle.text}
                    {...messages.downloadLogos}
                  />
                </button>
              </a>,
            )}
            {renderParagraph(
              <FormattedMessage {...messages.inTheNews} />,
              externalLinks.map(el => renderLinks(el.year, el.links)),
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle, whitepaperButtonStyle)(Press);
