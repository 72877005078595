import PropTypes from 'prop-types';

/* eslint-disable prettier/prettier */
const superHeroShapeObj = {};
superHeroShapeObj.name = PropTypes.string;
superHeroShapeObj.experience = PropTypes.number;
superHeroShapeObj.travelTime = PropTypes.number;
superHeroShapeObj.salary = PropTypes.number;
superHeroShapeObj.strength = PropTypes.string;
superHeroShapeObj.social = PropTypes.string;
superHeroShapeObj.superPowers = PropTypes.arrayOf(PropTypes.string);
superHeroShapeObj.benefits = PropTypes.arrayOf(PropTypes.string);
superHeroShapeObj.danubeScore = PropTypes.number;
superHeroShapeObj.matches = PropTypes.arrayOf(PropTypes.number);

export const superHeroShape = PropTypes.shape(superHeroShapeObj);

/* eslint-disable prettier/prettier */
const filterShapeObj = {};
filterShapeObj.salaryFrom = PropTypes.number;
filterShapeObj.salaryTo = PropTypes.number;
filterShapeObj.salaryImportance = PropTypes.number;
filterShapeObj.experienceImportance = PropTypes.number;
filterShapeObj.travelTimeImportance = PropTypes.number;
filterShapeObj.strength = PropTypes.arrayOf(PropTypes.string);
filterShapeObj.social = PropTypes.arrayOf(PropTypes.string);
filterShapeObj.superPowers = PropTypes.arrayOf(PropTypes.string);
filterShapeObj.benefits = PropTypes.arrayOf(PropTypes.string);
/* eslint-enable prettier/prettier */

export const filterShape = PropTypes.shape(filterShapeObj);
