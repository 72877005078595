import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { Link } from 'react-router-dom';

import layoutStyle from '../../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from '../../../Page.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../../messages';
import wikiMessages from '../../../messages';
import {
  codeStyle,
  danubeRecommendationExample1Data,
  danubeRecommendationExample1,
  danubeRecommendationExample1Result,
  danubeRecommendationExample1ResultData,
  danubeRecommendationExample2Data,
  danubeRecommendationExample2,
  danubeRecommendationExample2Result,
  danubeRecommendationExample2ResultData,
} from '../../codeBlocks';

class danubeRecommendationExamples extends React.Component {
  render() {
    return (
      <div className={s.apiText}>
        <p>
          <h1>
            <FormattedMessage {...wikiMessages.danubeRecommendation} />
            {' - '}
            <FormattedMessage {...wikiMessages.examples} />
          </h1>
          <FormattedMessage
            {...messages.examplesText3}
            values={{
              endpoint: (
                <Link
                  to="/api/cloud/documentation/recommendation/danube-recommendation"
                  target="_blank"
                >
                  <FormattedMessage {...wikiMessages.danubeRecommendation} />
                </Link>
              ),
            }}
          />
        </p>
        <p>
          <h2>
            <FormattedMessage {...messages.danubeRecommendationExample1} />
          </h2>
          <FormattedHTMLMessage {...messages.danubeRecommendationText7} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {danubeRecommendationExample1Data}
          </SyntaxHighlighter>
          <br />
          <FormattedHTMLMessage {...messages.postText} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {danubeRecommendationExample1}
          </SyntaxHighlighter>
          <br />
          <b>
            <FormattedHTMLMessage {...messages.returns} />
          </b>
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {danubeRecommendationExample1Result}
          </SyntaxHighlighter>
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {danubeRecommendationExample1ResultData}
          </SyntaxHighlighter>
        </p>
        <p>
          <h2>
            <FormattedMessage {...messages.danubeRecommendationExample2} />
          </h2>
          <FormattedHTMLMessage {...messages.danubeRecommendationText8} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {danubeRecommendationExample2Data}
          </SyntaxHighlighter>
          <br />
          <FormattedHTMLMessage {...messages.postText} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {danubeRecommendationExample2}
          </SyntaxHighlighter>
          <br />
          <b>
            <FormattedHTMLMessage {...messages.returns} />
          </b>
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {danubeRecommendationExample2Result}
          </SyntaxHighlighter>
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {danubeRecommendationExample2ResultData}
          </SyntaxHighlighter>
        </p>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle)(danubeRecommendationExamples);
