export const codeStyle = {
  padding: '10px',
  border: 'none',
  background: 'white',
  fontSize: '18px',
};

/* eslint-disable prettier/prettier */

export const jsonExample = (
  `
[
  {
    "id": "job-1",
    "salaryFrom": 36000,
    "salaryTo": 50000,
    "daysAgo": 240,
    "companyType": "Startup",
    "jobLevel": "Experienced",
    "technologies": ["Python", "Java", "C++", "C"],
    "benefits": ["Flexible working hours", "Team events"]
  },
  {
    "id": "job-2",
    "salaryFrom": 42000,
    "salaryTo": 60000,
    "daysAgo": 100,
    "companyType": "Established company",
    "technologies": ["Git", "Docker", "JavaScript"]
  }
]
`.trim());

/* eslint-enable prettier/prettier */
