import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { compose } from 'react-apollo';
import { withLogout } from 'components/Auth/withLogout';

import s from './TopNavigation.scss'; // eslint-disable-line css-modules/no-unused-class
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

const renderNavItems = navItems =>
  navItems.map(navItem => {
    if (navItem.customContent) {
      return (
        <NavItem key={navItem.key} href="#" className={s.navbarText}>
          {navItem.customContent}
        </NavItem>
      );
    }

    if (navItem.subNavs) {
      return (
        <NavDropdown
          className={s.navDropDown}
          key={navItem.key}
          id={navItem.key}
          title={navItem.label}
        >
          {navItem.subNavs.map(
            subNavItem =>
              subNavItem.customContent ? (
                <MenuItem key={subNavItem.key}>
                  {subNavItem.customContent}
                </MenuItem>
              ) : (
                <MenuItem
                  key={subNavItem.key}
                  componentClass={Link}
                  to={`/${subNavItem.link}`}
                  href={`/${subNavItem.link}`}
                >
                  {subNavItem.label}
                </MenuItem>
              ),
          )}
        </NavDropdown>
      );
    }

    return (
      <NavItem
        className={s.navbarText}
        key={navItem.key}
        componentClass={Link}
        to={`/${navItem.link}`}
        href={`/${navItem.link}`}
      >
        {navItem.label}
      </NavItem>
    );
  });

class TopNavigation extends React.Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    homeUrl: PropTypes.string,
    customLogoOnClick: PropTypes.func,
    navItemsLeft: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.any,
        link: PropTypes.string, // either link or subnavs
        subNavs: PropTypes.array, // either link or subnavs
      }),
    ),
    navItemsRight: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.any,
        link: PropTypes.string, // either link or subnavs
        subNavs: PropTypes.array, // either link or subnavs
      }),
    ),
  };

  static defaultProps = {
    homeUrl: '/',
    customLogoOnClick: null,
    navItemsLeft: [],
    navItemsRight: [],
  };

  handleSelect(eventKey) {
    switch (eventKey) {
      case 2.1:
        this.props.logout();
        break;
      default:
    }
  }

  static renderLink(homeUrl) {
    return (
      <Link to={homeUrl}>
        <div className={s.logoIcon}>
          <img src="/fishy_blue_horizontal.png" alt="" />
        </div>
        <div className={s.logoName}>danube.ai</div>
      </Link>
    );
  }

  static renderCustom(customLogoOnClick) {
    return (
      <Button onClick={customLogoOnClick} className={s.logoWrapperButton}>
        <div className={s.logoIcon}>
          <img src="/fishy_blue_horizontal.png" alt="" />
        </div>
        <div className={s.logoName}>danube.ai</div>
      </Button>
    );
  }

  render() {
    const {
      homeUrl,
      customLogoOnClick,
      navItemsLeft,
      navItemsRight,
    } = this.props;

    return (
      <Navbar
        className={s.navbar}
        fixedTop
        fluid
        collapseOnSelect
        onSelect={k => this.handleSelect(k)}
      >
        <Navbar.Header>
          <Navbar.Brand className={s.logoContainer}>
            {customLogoOnClick
              ? TopNavigation.renderCustom(customLogoOnClick)
              : TopNavigation.renderLink(homeUrl)}
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse className={s.navbarCollapse}>
          {navItemsLeft && <Nav>{renderNavItems(navItemsLeft)}</Nav>}
          <LanguageSwitcher
            pullRight
            dropDownClassName={s.langSwitcherDropDown}
          />
          {navItemsRight && (
            <Nav pullRight>{renderNavItems(navItemsRight)}</Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default compose(
  withLogout,
  withStyles(s),
)(TopNavigation);
