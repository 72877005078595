import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm, reset } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './EditPasswordForm.scss';
import messages from '../messages';
import render from '../../ReduxForm/renderField';
import validations from '../../modules/form/validations';
import validationMessages from '../../ReduxForm/messages';
import Loading from '../../Loading';

const validate = values => {
  const errors = {};

  if (!validations.required(values.username)) {
    errors.username = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.checkPassword(values.password)) {
    errors.password = <FormattedMessage {...validationMessages.password} />;
  }
  if (
    (values.password && !values.passwordConfirmation) ||
    (values.password && values.password !== values.passwordConfirmation)
  ) {
    errors.passwordConfirmation = (
      <FormattedMessage {...validationMessages.passwordConfirmation} />
    );
  }

  return errors;
};

class EditPasswordForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
    }),
    initialValues: PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    errors: [],
    user: {
      id: 0,
      username: '',
    },
  };

  render() {
    const { handleSubmit, errors, initialValues } = this.props;
    if (!initialValues) {
      return <Loading />;
    }
    return (
      <div className={s.editPasswordForm}>
        <form className="default-form" onSubmit={handleSubmit}>
          <fieldset>
            <Field
              id="username"
              name="username"
              label={<FormattedMessage {...messages.username} />}
              type="text"
              component={render.renderInput}
              disabled
            />
            <Field
              id="password"
              name="password"
              label={<FormattedMessage {...messages.password} />}
              type="password"
              component={render.renderInput}
            />
            <Field
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              label={<FormattedMessage {...messages.passwordConfirmation} />}
              component={render.renderInput}
            />
            {errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {errors.map(err => (
                  <li key={err} className="validationError">
                    {err}
                  </li>
                ))}
              </ul>
            )}
            <button type="submit" className="btn-secondary btn-round btn-large">
              <FormattedMessage {...messages.save} />
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

const afterSubmit = (result, dispatch) => dispatch(reset('user'));

export default reduxForm({
  form: 'user',
  validate,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
  onSubmitSuccess: afterSubmit,
})(injectIntl(withStyles(s)(EditPasswordForm)));
