import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const optionType = PropTypes.shape({
  value: PropTypes.any,
  label: PropTypes.string,
});

class Select_ extends React.Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string, // only on inital field set
        optionType,
        PropTypes.arrayOf(optionType),
      ]),
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.shape({
        id: PropTypes.string,
      }),
      warning: PropTypes.bool,
    }).isRequired,
    options: PropTypes.arrayOf(optionType).isRequired,
    styles: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    isSearchable: PropTypes.bool,
    noOptionsMessage: PropTypes.string,
    innerPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    styles: {},
    placeholder: '',
    isDisabled: false,
    isMulti: false,
    isSearchable: true,
    noOptionsMessage: '',
    innerPadding: null,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectedOptions) {
    this.props.input.onChange(selectedOptions);
  }

  render() {
    const {
      className,
      input: { value },
      meta: { touched, error, warning },
      options,
      styles,
      placeholder,
      isDisabled,
      isMulti,
      isSearchable,
      noOptionsMessage,
      innerPadding,
    } = this.props;

    return (
      <div>
        <Select
          className={className}
          value={value}
          onChange={this.handleChange}
          options={options}
          styles={{
            ...styles,
            control: base => ({
              ...base,
              boxShadow: 'none',
            }),
            valueContainer: base => {
              const newStyle = { ...base };
              if (innerPadding != null) newStyle.padding = innerPadding;
              return newStyle;
            },
          }}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isSearchable={isSearchable}
          noOptionsMessage={() => noOptionsMessage}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#e5f7fe',
              primary: '#92deff',
            },
          })}
        />
        {touched &&
          ((error && <p className="bg-danger">{error}</p>) ||
            (warning && <p className="bg-warning">{warning}</p>))}
      </div>
    );
  }
}

export default Select_;
