import { defineMessages } from 'react-intl';

const messages = defineMessages({
  requestDemoButton: {
    id: 'requestDemo.requestDemoButton',
    defaultMessage: 'Request Demo',
    description: 'Button Label for Request Demo Button.',
  },
  requestDemoTitle: {
    id: 'requestDemo.requestDemoTitle',
    defaultMessage: "Enter your e-mail and we'll get back to you",
    description: 'Title for Request Demo Modal.',
  },
  requestDemoEmail: {
    id: 'requestDemo.requestDemoEmail',
    defaultMessage: 'e-mail',
    description: 'Placeholder for Request Demo Modal email field.',
  },
});

export default messages;
