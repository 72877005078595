import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

// eslint-disable-next-line css-modules/no-unused-class
import s from './CherryTree.scss';
// eslint-disable-next-line css-modules/no-unused-class
import bs from './CherryTreeButtons.scss';
import messages from './messages';
import CherryTreeWhitepaperButton from './CherryTreeWhitepaperButton';
import CherryTreeScientificPaperButton from './CherryTreeScientificPaperButton';
import CherryTreePitchDeckButton from './CherryTreePitchDeckButton';
import CherryTreeArticleButton from './CherryTreeArticleButton';
import FirstPage from './FirstPage';

function getComicViewedCookie() {
  return Cookies.get('comic_viewed');
}

class CherryTree extends React.Component {
  constructor(props) {
    super(props);

    this.scrollToSecondPage = this.scrollToSecondPage.bind(this);

    this.secondPageRef = React.createRef();

    this.state = {
      renderFirstPage: false,
    };
  }

  componentDidMount() {
    const comicViewed = getComicViewedCookie();

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      renderFirstPage: true,
      comicViewed,
    });
  }

  scrollToSecondPage() {
    if (this.secondPageRef.current) {
      window.scroll({
        top: this.secondPageRef.current.offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  render() {
    return (
      <div className={s.cherryTreeContainer}>
        <div className={s.firstPage}>
          {this.state.renderFirstPage && (
            <>
              <FirstPage
                comicViewed={this.state.comicViewed}
                scrollToSecondPage={this.scrollToSecondPage}
              />
            </>
          )}
          <div
            className={s.firstPageOverlay}
            style={{ opacity: this.state.renderFirstPage ? 0 : 1 }}
          />
        </div>
        <div className={s.secondPage} ref={this.secondPageRef}>
          <div className={s.centeredContent}>
            <h2>
              <FormattedMessage
                {...messages.page2Header}
                values={{
                  logo: <span className={s.logo}>Cherry Tree</span>,
                  method: <i>Genetic AI</i>,
                }}
              />
            </h2>
            <div className={s.columns}>
              <div className={s.leftContent}>
                <h3>
                  <FormattedMessage {...messages.page2Line1} />
                </h3>
                <div className={s.buttonSection}>
                  <CherryTreeWhitepaperButton />
                  <CherryTreeScientificPaperButton />
                  <CherryTreeArticleButton />
                  <CherryTreePitchDeckButton />
                </div>
                <h3>
                  <FormattedMessage {...messages.page2Line2} />
                </h3>
                <div className={s.buttonSection}>
                  <a
                    href="https://github.com/danube-ai/pikaia"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      className={`${bs.cherryTreeButton} ${bs.pikaiaButton}`}
                    >
                      <FormattedMessage {...messages.pikaiaButton} />
                      <img src="/cherrytree/python.png" alt="" />
                    </button>
                  </a>
                </div>
              </div>
              <div className={s.rightContent}>
                <div className={s.infographicSection}>
                  <img
                    src={
                      this.props.locale === 'de-DE'
                        ? '/cherrytree/infographic-de.jpg'
                        : '/cherrytree/infographic-en.jpg'
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={s.fader} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: state && state.intl ? state.intl.locale : null,
});

export default connect(mapStateToProps)(withStyles(s, bs)(CherryTree));
