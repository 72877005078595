import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FieldArray, reduxForm } from 'redux-form';
import { ScrollSync } from 'react-scroll-sync';
import { Row, Col, Panel } from 'react-bootstrap';

import s from './ColumnImporters.scss'; // eslint-disable-line css-modules/no-unused-class
import panelStyle from '../Dashboard/NewAnalysis/DataPreSettings/DataPreSettings.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import newAnalysisMessages from '../Dashboard/NewAnalysis/messages';
import ColumnImporter from './ColumnImporter';
import DataSettings from '../Dashboard/NewAnalysis/DataPreSettings/DataSettings';
import { viewModes } from '../ResultsTable';

import {
  COLUMN_TYPE_NUMBER,
  COLUMN_TYPE_NUMERIC_PERCENTAGE,
  COLUMN_RULE_MAX_PERCENTAGE,
} from './../../constants';
import { isNumericColumn, generateRuleObject, isNumeric } from './ColumnHelper';

const validate = formValues => {
  // console.log('validate', formValues);

  const errors = {};

  const allImportersErrors = [];

  // check all column importers
  formValues.columnImporters.forEach((importer, cIndex) => {
    let newMatched = true;

    if (importer.skipped) {
      return;
    }
    const importerErrors = {};

    if (
      !importer.name ||
      (typeof importer.name === 'string' && importer.name.trim() === '')
    ) {
      importerErrors.name = 'Column name required!';
    }
    if (!importer.columnType || importer.columnType === '') {
      importerErrors.columnType = 'Column data type required!';
    }

    // column needs to be numeric for percentage type to work
    if (
      importer.columnType === COLUMN_TYPE_NUMERIC_PERCENTAGE &&
      !isNumericColumn(importer.rows)
    ) {
      importerErrors.columnType = {
        _error: 'Column is not numeric', // TODO -----------
      };
    }

    // check rules of column importer
    if (!importer.isUniqueIdColumn) {
      const allRuleErrors = [];
      const rule = generateRuleObject(importer.columnType, {
        oneEqualsTo: importer.oneEqualsTo,
        hundredPercentEqualsTo: importer.hundredPercentEqualsTo,
      });
      const ruleErrors = {};
      if (
        !rule.ruleValue ||
        (!isNumeric(rule.ruleValue) &&
          (typeof rule.ruleValue === 'string' && rule.ruleValue.trim() === ''))
      ) {
        ruleErrors.ruleValue = 'Rule value required!';
      }
      if (
        importer.columnType === COLUMN_TYPE_NUMBER &&
        isNaN(rule.ruleValue) // eslint-disable-line no-restricted-globals
      ) {
        ruleErrors.ruleValue = 'Rule value needs to be a number!';
      }
      if (
        importer.columnType !== COLUMN_TYPE_NUMERIC_PERCENTAGE &&
        rule.ruleType === COLUMN_RULE_MAX_PERCENTAGE
      ) {
        ruleErrors.ruleValue =
          'Column needs to be set to percentage for this rule';
      }

      if (rule.ruleType === COLUMN_RULE_MAX_PERCENTAGE) {
        // eslint-disable-next-line no-restricted-globals
        if (!isNumeric(rule.ruleValue)) {
          ruleErrors.ruleValue = 'Rule value needs to be a number!';
        }

        const maxValue = Math.max(
          ...importer.rows
            .filter(val => isNumeric(val))
            .map(val => parseFloat(val)),
          0,
        );

        if (parseFloat(rule.ruleValue) < maxValue) {
          ruleErrors.ruleValue = `Rule value needs to be a at least ${maxValue}!`;
        }

        if (!isNumeric(importer.columnScoreInput)) {
          importerErrors.columnScoreInput = {
            _error: 'Score is not numeric', // TODO -----------
          };
        }
      }

      if (ruleErrors.ruleValue) {
        newMatched = false;
      }
      allRuleErrors[0] = ruleErrors;
      importerErrors.rules = allRuleErrors;
    }

    if (
      importerErrors.name ||
      importerErrors.columnScoreInput ||
      (importerErrors.rules && importerErrors.rules._error) || // eslint-disable-line no-underscore-dangle
      importerErrors.columnType !== undefined
    ) {
      newMatched = false;
    }

    importer.matched = newMatched; // eslint-disable-line no-param-reassign
    allImportersErrors[cIndex] = importerErrors;
  });

  errors.columnImporters = allImportersErrors;
  return errors;
};

class ColumnImporters extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    hasChanged: PropTypes.bool.isRequired,
    isDanubifying: PropTypes.bool.isRequired,
    onDataSettingsApplied: PropTypes.func.isRequired,
    usecaseView: PropTypes.bool.isRequired,
    viewMode: PropTypes.string.isRequired,
    forceWidgetHorizontalScroll: PropTypes.bool,
  };

  static defaultProps = {
    forceWidgetHorizontalScroll: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      settingsExpanded: false,
    };

    this.toggleSettingsExpanded = this.toggleSettingsExpanded.bind(this);
    this.renderColumnImporters = this.renderColumnImporters.bind(this);

    this.noErrors = false;
  }

  toggleSettingsExpanded() {
    this.setState({ settingsExpanded: !this.state.settingsExpanded });
  }

  renderColumnImporters = ({ fields }) => {
    let uniqueIdColumnImporter;
    const noErrors = true;

    const columnImporters = fields.map((field, index) => {
      const fieldData = fields.get(index);

      const columnImporter = (
        <ColumnImporter
          key={`columnImporter-${index}`} // eslint-disable-line
          index={index}
          field={field}
          fieldData={fieldData}
          rowScores={this.props.rowScores}
          rowScoreChanges={this.props.rowScoreChanges}
          onApply={this.props.onApply}
          onApplyAndSort={this.props.onApplyAndSort}
          usecaseView={this.props.usecaseView}
          viewMode={this.props.viewMode}
        />
      );

      if (fieldData.isUniqueIdColumn) {
        uniqueIdColumnImporter = columnImporter;
        return null;
      }
      return columnImporter;
    });

    if (noErrors) {
      this.noErrors = noErrors;
    }

    // don't render tools panel in usecase view
    const toolsPanel = this.props.usecaseView
      ? []
      : [...this.renderToolsPanel({ fields })];

    const overflowXScroll =
      this.props.usecaseView && !this.props.forceWidgetHorizontalScroll
        ? 'hidden'
        : ' auto';

    return [
      toolsPanel,
      <div className={s.columnsWrapper} key="columns">
        <div className={s.columnImportersContainer}>
          {uniqueIdColumnImporter}
          <div
            className={s.horiontalScrollView}
            style={{
              overflowX: overflowXScroll,
            }}
          >
            {columnImporters}
          </div>
        </div>
      </div>,
    ];
  };

  renderToolsPanel({ fields }) {
    const caretImage = this.state.settingsExpanded
      ? '/caret_expanded.png'
      : '/caret.png';

    let matchedCount = 0;
    let unmatchedCount = 0;
    let skippedCount = 0;
    let fixedCount = 0;

    fields.forEach((field, index) => {
      const fieldData = fields.get(index);
      // update statistics
      if (fieldData.skipped) skippedCount += 1;
      else if (fieldData.fixed) fixedCount += 1;
      else if (fieldData.matched) matchedCount += 1;
      else unmatchedCount += 1;
    });

    return [
      <div key="toolsPanel">
        <div className={s.statisticsContainer}>
          <div className={s.statistic}>
            <div className={s.count}> {matchedCount} </div>
            <FormattedMessage {...messages.matched} />
          </div>
          <br />
          <div className={s.statistic}>
            <div className={s.count}> {unmatchedCount} </div>
            <FormattedMessage {...messages.unmatched} />
          </div>
          <br />
          <div className={s.statistic}>
            <div className={s.count}> {skippedCount} </div>
            <FormattedMessage {...messages.skipped} />
          </div>
          <br />
          <div className={s.statistic}>
            <div className={s.count}> {fixedCount} </div>
            <FormattedMessage {...messages.fixed} />
          </div>
        </div>
        <div className={s.settingsToggleContainer}>
          <button
            type="button"
            className={s.expandButton}
            onClick={this.toggleSettingsExpanded}
          >
            <img className={s.settingsIcon} src="/wheel.png" alt="" />
            <FormattedMessage {...newAnalysisMessages.settings} />
            <img className={s.caretIcon} src={caretImage} alt="" />
          </button>
        </div>
        <div className={s.buttonsContainer}>
          <button
            className="btn-primary"
            type="button"
            onClick={this.props.onToggleNormalAndCompactView}
          >
            Toggle View
          </button>
          <button
            className="btn-primary"
            type="button"
            onClick={this.props.onReset}
          >
            <FormattedMessage {...messages.reset} />
          </button>
          <button
            className="btn-primary"
            type="button"
            onClick={this.props.onBack}
          >
            <FormattedMessage {...messages.back} />
          </button>
          <button
            className="btn-secondary"
            disabled={!this.noErrors || this.props.isDanubifying}
            type="submit"
          >
            <FormattedMessage {...messages.danubify} />
            {this.props.hasChanged && ' *'}
          </button>
        </div>
      </div>,
      <div key="settingsPanel">
        <Row className={s.panelContainer}>
          <Col lg={8}>
            <Panel
              className={panelStyle.settingsPanel}
              expanded={this.state.settingsExpanded}
              onToggle={this.toggleSettingsExpanded}
            >
              <Panel.Collapse>
                <Panel.Body className={panelStyle.panelBody}>
                  <DataSettings
                    formName="columns"
                    showImportSettings={false}
                    showApplyButton
                    onDataSettingsApplied={this.props.onDataSettingsApplied}
                  />
                </Panel.Body>
              </Panel.Collapse>
            </Panel>
          </Col>
        </Row>
      </div>,
    ];
  }

  render() {
    const { handleSubmit, viewMode } = this.props;
    let containerClass = s.pageContainer;
    switch (viewMode) {
      case viewModes.USECASE_WIDGET:
        containerClass = `${containerClass} ${s.compactContainer} ${
          s.widgetContainer
        }`;
        break;
      case viewModes.COMPACT:
        containerClass = `${containerClass} ${s.compactContainer}`;
        break;
      default:
        break;
    }
    return (
      <form onSubmit={handleSubmit}>
        <fieldset>
          <ScrollSync>
            <div className={containerClass}>
              <FieldArray
                id="columnImporters"
                name="columnImporters"
                component={this.renderColumnImporters}
                settingsExpanded={this.state.settingsExpanded} // workaround - pass settingsExpanded to FieldArray to trigger re-render
              />
            </div>
          </ScrollSync>
        </fieldset>
      </form>
    );
  }
}

export default reduxForm({
  form: 'columns',
  validate,
  enableReinitialize: true,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(withStyles(s, buttonStyle)(ColumnImporters));
