import React from 'react';

import PageProperties from 'components/PageProperties';
import Api from 'components/Api/Page';
import routeMessages from 'routes/messages';

class ApiWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.apiWikiTitle,
            description: routeMessages.apiWikiDescription,
            keywords: routeMessages.apiWikiKeywords,
          }}
        />
        <Api />
      </div>
    );
  }
}

export default ApiWrapper;
