import { defineMessages } from 'react-intl';

const messages = defineMessages({
  // messages for Landing Page
  media: {
    id: 'landingPage.media',
    defaultMessage: 'MEDIA',
    description: 'Link Label for media.',
  },
  recruitment: {
    id: 'landingPage.recruitment',
    defaultMessage: 'RECRUITMENT',
    description: 'Link Label for recruitment.',
  },
  eCommerce: {
    id: 'landingPage.eCommerce',
    defaultMessage: 'E-COMMERCE',
    description: 'Link Label for eCommerce.',
  },
  mainHeader: {
    id: 'landingPage.mainHeader',
    defaultMessage: 'Predictive analytics<br />with little data',
    description: 'Header for main landing page.',
  },
  mediaHeader: {
    id: 'landingPage.mediaHeader',
    defaultMessage:
      'What your reader<br />likes gets harder<br />to tell every day',
    description: 'Header for media image.',
  },
  recruitmentHeader: {
    id: 'landingPage.recruitmentHeader',
    defaultMessage:
      'Recruiting the right<br />people is critical<br />to your business',
    description: 'Header for recruitment image.',
  },
  eCommerceHeader: {
    id: 'landingPage.eCommerceHeader',
    defaultMessage:
      'What your customer<br />likes gets harder to tell<br />every day',
    description: 'Header for eCommerce image.',
  },
});

export default messages;
