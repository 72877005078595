import { defineMessages } from 'react-intl';

import messages from './messages';

// additional messages which may not be used as route titles, but which are used for recommended pages
const additionalMessages = defineMessages({
  landingPageTitle: {
    id: 'routes.additionalMessages.landingPage.title',
    defaultMessage: 'Home',
    description: 'Additional title for pages',
  },
  landingPageSubTitle: {
    id: 'routes.additionalMessages.landingPage.subtitle',
    defaultMessage: 'danube.ai can be used for media, recruiting and eCommerce',
    description: 'Additional title for pages',
  },

  mediaLandingPageTitle: {
    id: 'routes.additionalMessages.mediaLandingPage.title',
    defaultMessage: 'Media',
    description: 'Additional title for pages',
  },
  mediaLandingPageSubTitle: {
    id: 'routes.additionalMessages.mediaLandingPage.subtitle',
    defaultMessage: 'danube.ai makes your readers happy',
    description: 'Additional title for pages',
  },

  ecommerceLandingPageTitle: {
    id: 'routes.additionalMessages.ecommerceLandingPage.title',
    defaultMessage: 'eCommerce',
    description: 'Additional title for pages',
  },
  ecommerceLandingPageSubTitle: {
    id: 'routes.additionalMessages.ecommerceLandingPage.subtitle',
    defaultMessage: `danube.ai makes your customers happy`,
    description: 'Additional title for pages',
  },

  recruitingLandingPageTitle: {
    id: 'routes.additionalMessages.recrutingLandingPage.title',
    defaultMessage: 'Recruiting',
    description: 'Additional title for pages',
  },
  recruitingLandingPageSubTitle: {
    id: 'routes.additionalMessages.recrutingLandingPage.subtitle',
    defaultMessage: 'danube.ai fills your open position',
    description: 'Additional title for pages',
  },

  netflixUseCaseTitle: {
    id: 'routes.additionalMessages.netflixUseCase.title',
    defaultMessage: 'Use Case - Netflix',
    description: 'Additional title for pages',
  },
  netflixUseCaseSubTitle: {
    id: 'routes.additionalMessages.netflixUseCase.subtitle',
    defaultMessage: 'See how danube.ai can be used for media recommendations',
    description: 'Additional title for pages',
  },

  trainingUseCaseTitle: {
    id: 'routes.additionalMessages.trainingUseCase.title',
    defaultMessage: 'Use Case - Training',
    description: 'Additional title for pages',
  },
  trainingUseCaseSubTitle: {
    id: 'routes.additionalMessages.trainingUseCase.subtitle',
    defaultMessage: 'See how danube.ai improves your training efficiency',
    description: 'Additional title for pages',
  },

  educationUseCaseTitle: {
    id: 'routes.additionalMessages.educationUseCase.title',
    defaultMessage: 'Use Case - Education',
    description: 'Additional title for pages',
  },
  educationUseCasesSubTitle: {
    id: 'routes.additionalMessages.educationUseCase.subtitle',
    defaultMessage: 'Let danube.ai balance test scores',
    description: 'Additional title for pages',
  },

  industryUseCaseTitle: {
    id: 'routes.additionalMessages.industryUseCase.title',
    defaultMessage: 'Use Case - Industry',
    description: 'Additional title for pages',
  },
  industryUseCaseSubTitle: {
    id: 'routes.additionalMessages.industryUseCase.subtitle',
    defaultMessage: 'See how danube.ai optimizes your industrial processes',
    description: 'Additional title for pages',
  },

  demoJobsTitle: {
    id: 'routes.additionalMessages.demoJobs.title',
    defaultMessage: 'Jobs - Demo',
    description: 'Title for Demo page',
  },
  demoJobsDescription: {
    id: 'routes.additionalMessages.demoJobs.description',
    defaultMessage: `
      danube.ai finds your perfect job
    `,
    description: 'Description for Demo page',
  },
  demoRealEstateTitle: {
    id: 'routes.additionalMessages.demoRealEstate.title',
    defaultMessage: 'Real Estate - Demo',
    description: 'Title for Demo page',
  },
  demoRealEstateDescription: {
    id: 'routes.additionalMessages.demoRealEstate.description',
    defaultMessage: `
      danube.ai finds your perfect apartment
    `,
    description: 'Description for Demo page',
  },
  demoPhonesTitle: {
    id: 'routes.additionalMessages.demoPhones.title',
    defaultMessage: 'Phones - Demo',
    description: 'Title for Demo page',
  },
  demoPhonesDescription: {
    id: 'routes.additionalMessages.demoPhones.description',
    defaultMessage: `
      danube.ai finds your perfect phone
    `,
    description: 'Description for Demo page',
  },
  demoRecruitingTitle: {
    id: 'routes.additionalMessages.demoRecruiting.title',
    defaultMessage: 'Recruiting - Demo',
    description: 'Title for Demo page',
  },
  demoRecruitingDescription: {
    id: 'routes.additionalMessages.demoRecruiting.description',
    defaultMessage: `
      danube.ai finds your perfect employee
    `,
    description: 'Description for Demo page',
  },
  showcaseDescription: {
    id: 'routes.additionalMessages.showcase.description',
    defaultMessage: `
      See how you can use danube.ai
    `,
    description: 'Description for Showcase page',
  },
  showcaseGeizhalsTitle: {
    id: 'routes.additionalMessages.showcaseGeizhals.title',
    defaultMessage: `
      Showcase: Geizhals.at
    `,
    description: 'Title for Showcase Geizhals page',
  },
  showcaseGeizhalsDescription: {
    id: 'routes.additionalMessages.showcaseGeizhals.description',
    defaultMessage: `
      See how Geizhals.at uses danube.ai
    `,
    description: 'Description for Showcase Geizhals page',
  },
  showcaseDevjobsTitle: {
    id: 'routes.additionalMessages.showcaseDevjobs.title',
    defaultMessage: `
      Showcase: devjobs.at
    `,
    description: 'Title for Showcase devjobs.at page',
  },
  showcaseDevjobsDescription: {
    id: 'routes.additionalMessages.showcaseDevjobs.description',
    defaultMessage: `
      See how devjobs.at uses danube.ai
    `,
    description: 'Description for Showcase devjobs.at page',
  },
});

/* eslint-disable prettier/prettier */
// This map is used by the recommendation components, which tracks subpages (paths) but needs to display their names.
// Note: Pages which are not in this map will be excluded from history tracking and recommending
export default {
  // '/login': messages.loginTitle,
  // '/admin' <-- admin page should not be tracked or used when recommending sub pages!!!
  // '/my/dashboard' <-- my/ urls should be excluded maybe?
  // '/my/account' <-- my/ urls should be excluded maybe?
  // '/my/settings' <-- my/ urls should be excluded maybe?
  // '/my/results/:id' <-- my/results/ urls should be excluded
  // '/results/:id' <-- results/ urls should be excluded
  // '/privacy-agreement' <-- should be excluded
  // '/reset-password' <-- should be excluded
  // '/use-cases': { <-- exclude as the default use-cases url is also the netflix use case
  // '/real-data' <-- /real-data/ urls should be excluded
  '/': {
    title: additionalMessages.landingPageTitle,
    subTitle: additionalMessages.landingPageSubTitle,
  },
  '/media': {
    title: additionalMessages.mediaLandingPageTitle,
    subTitle: additionalMessages.mediaLandingPageSubTitle
  },
  '/ecommerce': {
    title: additionalMessages.ecommerceLandingPageTitle,
    subTitle: additionalMessages.ecommerceLandingPageSubTitle
  },
  '/recruiting': {
    title: additionalMessages.recruitingLandingPageTitle,
    subTitle: additionalMessages.recruitingLandingPageSubTitle,
  },
  '/registration': {
    title: messages.registrationTitle,
    subTitle: messages.registrationDescription,
  },
  // '/use-cases/media-suggestions': {
  //   title: additionalMessages.netflixUseCaseTitle,
  //   subTitle: additionalMessages.netflixUseCaseSubTitle,
  // },
  // '/use-cases/training': {
  //   title: additionalMessages.trainingUseCaseTitle,
  //   subTitle: additionalMessages.trainingUseCaseSubTitle,
  // },
  // '/use-cases/education': {
  //   title: additionalMessages.educationUseCaseTitle,
  //   subTitle: additionalMessages.educationUseCasesSubTitle,
  // },
  // '/use-cases/industry': {
  //   title: additionalMessages.industryUseCaseTitle,
  //   subTitle: additionalMessages.industryUseCaseSubTitle,
  // },
  '/technology': {
    title: messages.technologyTitle,
    subTitle: messages.technologyDescription,
  },
  '/press': {
    title: messages.pressTitle,
    subTitle: messages.pressDescription,
  },
  '/imprint': {
    title: messages.imprintTitle,
    subTitle: messages.imprintDescription,
  },
  '/privacy': {
    title: messages.privacyTitle,
    subTitle: messages.privacyDescription,
  },
  '/about': {
    title: messages.aboutUsTitle,
    subTitle: messages.aboutUsDescription,
  },
  '/contact': {
    title: messages.contactSalesTitle,
    subTitle: messages.contactSalesDescription,
  },
  '/partners/enliteai': {
    title: messages.partnerEnliteAITitle,
    subTitle: messages.partnerEnliteAIDescription,
  },
  '/api/cloud/documentation/use-api': {
    title: messages.apiWikiTitle,
    subTitle: messages.apiWikiDescription,
  },
  // note: sub-pages of api are excluded
  '/faq': {
    title: messages.faqTitle,
    subTitle: messages.faqDescription
  },
  // '/demos/jobs': {
  //   title: additionalMessages.demoJobsTitle,
  //   subTitle: additionalMessages.demoJobsDescription,
  // },
  // '/demos/immo': {
  //   title: additionalMessages.demoRealEstateTitle,
  //   subTitle: additionalMessages.demoRealEstateDescription,
  // },
  // '/demos/phones': {
  //   title: additionalMessages.demoPhonesTitle,
  //   subTitle: additionalMessages.demoPhonesDescription,
  // },
  // '/demos/recruiting': {
  //   title: additionalMessages.demoRecruitingTitle,
  //   subTitle: additionalMessages.demoRecruitingDescription,
  // },
  '/showcases/geizhals': {
    title: additionalMessages.showcaseGeizhalsTitle,
    subTitle: additionalMessages.showcaseGeizhalsDescription,
  },
  '/showcases/devjobs': {
    title: additionalMessages.showcaseDevjobsTitle,
    subTitle: additionalMessages.showcaseDevjobsDescription,
  },
  // '/pricing': {
  //   title: messages.pricingTitle,
  //   subTitle: additionalMessages.pricingDescription,
  // },
  '/cherrytree': {
    title: messages.cherryTreeTitle,
    subTitle: messages.cherryTreeDescription
  },
};
/* eslint-enable prettier/prettier */
