import React from 'react';

import PageProperties from 'components/PageProperties';
import CherryTree from 'components/CherryTree';
import routeMessages from 'routes/messages';

class ApiWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.cherryTreeTitle,
            description: routeMessages.cherryTreeDescription,
            keywords: routeMessages.cherryTreeKeywords,
          }}
        />
        <CherryTree />
      </div>
    );
  }
}

export default ApiWrapper;
