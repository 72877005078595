import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Panel } from 'react-bootstrap';

import DatasetGraph from './DatasetGraph';
import s from './DatasetCard.scss';
import buttonStyle from '../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import importerMessages from '../ResultsTable/messages';
import DatasetCardSettingsForm from './DatasetCardSettingsForm';

class DatasetCard extends React.Component {
  static defaultProps = {
    onChangeDataSettings: null,
  };

  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      lastChangedDate: PropTypes.string,
      scores: PropTypes.arrayOf(PropTypes.number),
      topRows: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
      ),
      topColumns: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
      ),
      settings: PropTypes.shape({
        strategy: PropTypes.string,
        impact: PropTypes.number,
        mixFactor: PropTypes.number,
        uniqueIdColumn: PropTypes.string,
        initialValueRow: PropTypes.number,
      }),
    }).isRequired,
    onView: PropTypes.func.isRequired,
    onDanubify: PropTypes.func.isRequired,
    onChangeDataSettings: PropTypes.func,
    onDeleteDataset: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.handleDeleteDataset = this.handleDeleteDataset.bind(this);
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  handleDeleteDataset() {
    this.setState({ expanded: false });
    this.props.onDeleteDataset();
  }

  render() {
    const { data, onView, onDanubify } = this.props;
    const caretImage = this.state.expanded
      ? '/caret_expanded.png'
      : '/caret.png';
    return (
      <Panel
        className={s.collapsiblePanel}
        expanded={this.state.expanded}
        onToggle={this.toggleExpanded}
      >
        <Panel.Heading className={s.panelHeading}>
          <div className={s.headingContent}>
            <span className={s.title}> {data.title} </span>
            <div className={s.buttonsContainer}>
              <button className="btn-primary" onClick={onView}>
                <FormattedMessage {...messages.view} />
              </button>
              <button
                className="btn-secondary"
                style={{ marginLeft: '20px' }}
                onClick={onDanubify}
              >
                <FormattedMessage {...importerMessages.danubify} />
              </button>
            </div>
          </div>
          <button className={s.expandButton} onClick={this.toggleExpanded}>
            <img src={caretImage} alt="" />
          </button>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body className={s.panelBody}>
            <Row>
              <Col md={6}>
                <div className={`${s.ranking} ${s.first}`}>
                  <div className={s.subHeader}>
                    <FormattedMessage
                      {...messages.topRows}
                      values={{
                        count: data.topRows.length,
                      }}
                    />
                  </div>
                  {data.topRows.map((row, index) => (
                    // eslint-disable-next-line
                    <div className={s.rankingLine} key={`row-${index}`}>
                      <span className={s.boldText}>{index + 1}. </span>
                      <span>{row.name}</span>
                      <div className={s.pullRight}>
                        <span className={s.boldText}>{row.score} </span>
                        <span className={s.boldText}>
                          <FormattedMessage {...messages.points} />
                        </span>
                      </div>
                      <div className={s.clearfix} />
                    </div>
                  ))}
                </div>
                <div className={s.ranking}>
                  <div className={s.subHeader}>
                    <FormattedMessage
                      {...messages.topColumns}
                      values={{
                        count: data.topColumns.length,
                      }}
                    />
                  </div>
                  {data.topColumns.map((col, index) => (
                    // eslint-disable-next-line
                    <div className={s.rankingLine} key={`col-${index}`}>
                      <span className={s.boldText}>{index + 1}. </span>
                      <span>{col.name}</span>
                      <div className={s.pullRight}>
                        <span className={s.boldText}>{col.score} </span>
                      </div>
                      <div className={s.clearfix} />
                    </div>
                  ))}
                </div>
              </Col>
              <Col md={6} className={s.col}>
                <div className={s.subHeader}>
                  <FormattedMessage {...messages.graph} />
                </div>
                <DatasetGraph scores={data.scores} />
              </Col>

              <Col md={6} className={s.col} style={{ marginTop: '10px' }}>
                <Row>
                  <Col md={12} className={s.col}>
                    <button
                      className="btn-dark"
                      style={{
                        marginLeft: '20px',
                        float: 'right',
                        marginBottom: '20px',
                      }}
                      onClick={this.handleDeleteDataset}
                    >
                      <FormattedMessage {...messages.deleteDataset} />
                    </button>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div>
                      <div className={`${s.lastChangedLabelContainer}`}>
                        <FormattedMessage {...messages.lastChanged} />
                      </div>
                      <div className={`${s.lastChangedDateContainer}`}>
                        <span>{data.lastChangedDate}</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <DatasetCardSettingsForm
              form={`datasetCardForm_${data.id}`}
              formName={`datasetCardForm_${data.id}`}
              onChange={this.props.onChangeDataSettings}
              initialValues={{
                settings: data.settings,
              }}
            />
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    );
  }
}

export default withStyles(s, buttonStyle)(DatasetCard);
