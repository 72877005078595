import React from 'react';
import fetch from 'node-fetch';
import { injectIntl, defineMessages, intlShape } from 'react-intl';
import CookieBanner from 'react-cookie-banner';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './DanubeApiUnavailableBanner.scss';

function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 2 Aug 2001 20:47:11 UTC; path=/;`;
}

const messages = defineMessages({
  errorContainer: {
    id: 'danubeApi.errorContainer',
    defaultMessage: 'No connection to server. Some features may not work.',
    description: 'Label for error message when danube api connection failed',
  },
  closeErrorContainer: {
    id: 'danubeApi.closeErrorContainer',
    defaultMessage: 'Dismiss',
    description:
      'Label to close error message container when danube api connection failed',
  },
});

class DanubeApiUnavailableBanner extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.keepAlive = this.keepAlive.bind(this);

    this.state = { isApiAlive: true };
  }

  componentDidMount() {
    setInterval(this.keepAlive, 60000); // every 60 seconds
  }

  keepAlive() {
    fetch(`/keepAlive`, {
      method: 'GET',
    })
      .then(result => {
        const { isApiAlive } = this.state;
        if (result && result.status === 200) {
          // api came to life again. delete cookie, that hides the banner
          if (!isApiAlive) {
            deleteCookie('ignore-api-error');
          }
          this.setState({ isApiAlive: true });
        } else {
          this.setState({ isApiAlive: false });
        }
      })
      .catch(() => this.setState({ isApiAlive: false }));
  }

  render() {
    const { isApiAlive } = this.state;
    const { intl } = this.props;
    return (
      <div>
        {/* Note(andreas.roschal): disabled for now */}
        {false &&
          !isApiAlive && (
            <CookieBanner
              message={intl.formatMessage(messages.errorContainer)}
              buttonMessage={intl.formatMessage(messages.closeErrorContainer)}
              onAccept={() => {}}
              dismissOnScroll={false}
              disableStyle
              className={s.apiUnavailableContainer}
              cookie="ignore-api-error"
            />
          )}
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(DanubeApiUnavailableBanner));
