import { defineMessages } from 'react-intl';

const messages = defineMessages({
  filterAndSort: {
    id: 'demo.geizhals.search.filterAndSort',
    defaultMessage: `Find your perfect phone`,
    description: 'label for field in search mask',
  },

  priceImportance: {
    id: 'demo.geizhals.search.price.importance',
    defaultMessage: `
      How important is a low price?
    `,
    description: 'label for field in search mask',
  },
  memoryImportance: {
    id: 'demo.geizhals.search.memory.importance',
    defaultMessage: 'How important is a large memory?',
    description: 'label for field in search mask',
  },
  cameraImportance: {
    id: 'demo.geizhals.search.camera.importance',
    defaultMessage: 'How important is a good camera?',
    description: 'label for field in search mask',
  },
  batteryImportance: {
    id: 'demo.geizhals.search.battery.importance',
    defaultMessage: 'How important is a good battery power?',
    description: 'label for field in search mask',
  },
  displayZollPreference: {
    id: 'demo.geizhals.search.displayZoll.preference',
    defaultMessage: 'What display size do you prefer?',
    description: 'label for field in search mask',
  },
  preferBig: {
    id: 'demo.geizhals.search.preferBig',
    defaultMessage: 'Larger is better',
    description: 'label for field in search mask',
  },
  preferMedium: {
    id: 'demo.geizhals.search.preferMedium',
    defaultMessage: 'Medium display size',
    description: 'label for field in search mask',
  },
  preferSmall: {
    id: 'demo.geizhals.search.preferSmall',
    defaultMessage: 'Smaller is better',
    description: 'label for field in search mask',
  },

  comparisonLink: {
    id: 'demo.geizhals.search.comparisonLink',
    defaultMessage: `
      Geizhals comparison link (https://geizhals.at/?cmp=...)
    `,
    description: 'label for field in search mask',
  },
});

export default messages;
