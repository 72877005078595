exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".User-adminPage-fxQC_ {\n  padding: 20px; }\n", "", {"version":3,"sources":["/builds/wa/danube-frontend/src/components/User/User.scss"],"names":[],"mappings":"AAAA;EACE,cAAc,EAAE","file":"User.scss","sourcesContent":[".adminPage {\n  padding: 20px; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"adminPage": "User-adminPage-fxQC_"
};