import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';
import Loading from 'components/Loading';
import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';

import DatasetCard from './DatasetCard';
import NewAnalysis from './NewAnalysis';
import {
  calculateRowScore,
  formatForAlgorithm,
  getTopXRowsByScoreFromDataset,
  getTopXColumnsByScoreFromDataset,
  applyRulesToData,
  getFish,
  parseDataMatrix,
} from '../ResultsTable/ColumnHelper';

import s from './Dashboard.scss';
import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import {
  parseCalibration,
  validateCalibration,
} from '../ResultsTable/CalibrationValidator';

const getData = gql`
  query userQuery {
    me {
      id
      username
    }
    getLatestCalibrations {
      id
      rawDataId
      userId
      columnSettings
      rules
      columnTypes
      columnScores
      updatedAt
      strategy
      mixFactor
      impact
      rawData {
        id
        name
        rawData
        initialValueRow
        uniqueIdColumn
      }
    }
  }
`;

const getDataset = gql`
  query getData($id: String!) {
    getData(id: $id) {
      id
      rawDataId
      userId
      columnSettings
      rules
      columnTypes
      columnScores
      updatedAt
      strategy
      mixFactor
      impact
      rawData {
        id
        name
        rawData
        initialValueRow
        uniqueIdColumn
      }
    }
  }
`;

class Dashboard extends React.Component {
  static contextTypes = {
    client: PropTypes.object,
  };

  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        username: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
      getLatestCalibrations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          columnSettings: PropTypes.string.isRequired,
          rules: PropTypes.string.isRequired,
          columnTypes: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    createOrUpdateCalibratedData: PropTypes.func.isRequired,
    deleteDataset: PropTypes.func.isRequired,
    startWorkerSync: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.onViewDataset = this.onViewDataset.bind(this);
    this.onDanubifyDataset = this.onDanubifyDataset.bind(this);
    this.generateDatasets = this.generateDatasets.bind(this);
    this.onDanubified = this.onDanubified.bind(this);
    this.onChangeDataSettings = this.onChangeDataSettings.bind(this);
    this.onDeleteDataset = this.onDeleteDataset.bind(this);
    this.onNewAnalysisAdded = this.onNewAnalysisAdded.bind(this);
    this.renderCell = this.renderCell.bind(this);

    this.datasetCount = 0;
    this.fishCount = 0;
    this.lastChangedDate = null;
  }

  async onDanubified({ datasetId }) {
    const queryResult = await this.context.client.query({
      query: getDataset,
      variables: {
        id: datasetId,
      },
      fetchPolicy: 'network-only',
    });
    const { getLatestCalibrations } = this.props.data;
    const newCalibratedData = [];
    getLatestCalibrations.forEach(calibratedData => {
      if (calibratedData.rawData.id === queryResult.data.getData.rawData.id) {
        newCalibratedData.push(queryResult.data.getData);
      } else {
        newCalibratedData.push(calibratedData);
      }
    });
    this.context.client.writeQuery({
      query: getData,
      data: {
        ...this.props.data,
        getLatestCalibrations: newCalibratedData,
      },
    });
  }

  onViewDataset(data) {
    const { getLatestCalibrations } = this.props.data;
    const calibration = getLatestCalibrations.find(
      dataset => dataset.id === data.id,
    );
    this.props.history.push({
      pathname: 'results',
      search: `?id=${calibration.rawDataId}`,
    });
  }

  async onChangeDataSettings(formValues, datasetId) {
    const { settings } = formValues;
    const settingsPatch = {
      strategy: settings.strategy,
      impact: settings.impact,
      mixFactor: settings.mixFactor,
    };
    // find dataset to get calibration id
    const { getLatestCalibrations } = this.props.data;
    const calibration = getLatestCalibrations.find(
      dataset => dataset.rawDataId === datasetId,
    );
    await this.props.createOrUpdateCalibratedData(
      calibration.id,
      settingsPatch,
    );
  }

  async onDanubifyDataset(data) {
    const queryResult = await this.context.client.query({
      query: getDataset,
      variables: {
        id: data.id,
      },
      fetchPolicy: 'network-only',
    });
    const dataset = parseCalibration(queryResult.data.getData);
    const calibration = parseCalibration(queryResult.data.getData);
    const algorithmData = formatForAlgorithm(calibration);
    algorithmData.storeResult = true;

    await this.props.startWorkerSync({
      data: algorithmData,
      calibratedDataId: data.id,
    });

    this.onDanubified({ datasetId: dataset.id });
  }

  async onDeleteDataset(datasetId) {
    await this.props.deleteDataset(datasetId);
    const { getLatestCalibrations } = this.props.data;
    const newCalibratedData = [];

    getLatestCalibrations.forEach(calibratedData => {
      if (calibratedData.rawData.id !== datasetId) {
        newCalibratedData.push(calibratedData);
      }
    });

    this.context.client.writeQuery({
      query: getData,
      data: {
        ...this.props.data,
        getLatestCalibrations: newCalibratedData,
      },
    });
  }

  onNewAnalysisAdded(analysis) {
    if (!analysis) return;
    if (!validateCalibration(parseCalibration(analysis))) return;

    const { getLatestCalibrations } = this.props.data;
    const newCalibrations = getLatestCalibrations.map(
      calibration => calibration,
    );
    newCalibrations.push(analysis);
    this.context.client.writeQuery({
      query: getData,
      data: {
        ...this.props.data,
        getLatestCalibrations: newCalibrations,
      },
    });
  }

  generateDatasets() {
    const { getLatestCalibrations } = this.props.data;
    let fishCount = 0;
    let lastChangedDate = null;
    let datasets = [];
    getLatestCalibrations.forEach(dataset => {
      const calibration = parseCalibration(dataset);

      if (!validateCalibration(calibration)) return;

      const uniqueIdColumn = JSON.parse(calibration.rawData.uniqueIdColumn);
      // get 2d data array with emptyAsZero rule applied
      const dataMatrix = parseDataMatrix(calibration.rawData.rawData);
      const ruleAppliedData = applyRulesToData(
        dataMatrix,
        calibration.columnSettings,
        calibration.rules,
        uniqueIdColumn,
      );

      const fish = getFish(
        ruleAppliedData,
        calibration.columnSettings,
        uniqueIdColumn,
      );
      const columns = calibration.columnScores;
      // calculate scores and get top elements
      const scores = [];
      if (fish.includes(null)) {
        return;
      }
      fish.forEach(row => {
        scores.push(calculateRowScore(row, columns));
      });

      const topRows = getTopXRowsByScoreFromDataset(
        3,
        scores,
        calibration.rawData.rawData,
        uniqueIdColumn,
      );
      const topColumns = getTopXColumnsByScoreFromDataset(
        3,
        columns,
        calibration.columnSettings,
      );
      const parsedDataset = {
        id: calibration.id,
        datasetId: calibration.rawData.id,
        title: calibration.rawData.name,
        lastChangedDate: calibration.updatedAt,
        topRows,
        topColumns,
        scores,
        settings: {
          strategy: calibration.strategy,
          impact: calibration.impact,
          mixFactor: calibration.mixFactor,
          uniqueIdColumn: `${parseInt(uniqueIdColumn, 10) + 1}`,
          initialValueRow: calibration.rawData.initialValueRow + 1,
        },
      };
      if (
        !lastChangedDate ||
        moment(calibration.updateAt, 'DD.MM.YYYY HH:mm:ss').isBefore(
          moment(lastChangedDate, 'DD.MM.YYYY HH:mm:ss'),
        )
      ) {
        lastChangedDate = dataset.updatedAt;
      }
      fishCount += fish.length;

      datasets.push(parsedDataset);
    });
    this.datasetCount = datasets.length;
    this.fishCount = fishCount;
    this.lastChangedDate = lastChangedDate;
    datasets = datasets.sort((dataset1, dataset2) =>
      moment(dataset2.lastChangedDate, 'DD.MM.YYYY HH:mm:ss').diff(
        moment(dataset1.lastChangedDate, 'DD.MM.YYYY HH:mm:ss'),
      ),
    );
    return datasets;
  }

  renderCell(datasets, index) {
    const dataset1 = datasets[0];
    const dataset2 = datasets[1];

    const content = dataset => (
      <Col md={12} lg={6}>
        <DatasetCard
          key={`datasetCardForm_${dataset.id}`}
          data={dataset}
          onView={() => {
            this.onViewDataset(dataset, index);
          }}
          onDanubify={() => {
            this.onDanubifyDataset(dataset, index);
          }}
          onChangeDataSettings={formValues => {
            this.onChangeDataSettings(formValues, dataset.datasetId);
          }}
          onDeleteDataset={() => this.onDeleteDataset(dataset.datasetId)}
        />
      </Col>
    );
    return (
      <Row className={layoutStyle.noMargin}>
        {content(dataset1)}
        {dataset2 && content(dataset2) /* second value of pair can be empty */}
      </Row>
    );
  }

  render() {
    const {
      data: { loading, /* me, */ error },
    } = this.props;
    if (loading || !this.props.data) return <Loading />;
    if (error) return <div>{error.message.split(':')[1]}</div>;

    let datasets = this.generateDatasets();
    // group into pairs, starting from index 1 (index 0 is placed right to new analysis)
    // pairs get rendered in a new row each
    datasets = datasets.reduce((result, value, index, array) => {
      if (index === 0) result.push([value]);
      else if (index % 2 === 1) result.push(array.slice(index, index + 2));
      return result;
    }, []);
    return (
      <div>
        <div className={`${s.metaDataContainer}`}>
          <Row className={layoutStyle.noMargin}>
            <Col sm={12} md={8} lg={6}>
              <Row>
                <Col md={3}>
                  <div className={`${s.datasetCountContainer}`}>
                    <span>{this.datasetCount}</span>
                  </div>
                  <div className={`${s.datasetLabelContainer}`}>
                    <FormattedMessage {...messages.datasets} />
                  </div>
                </Col>
                <Col md={5}>
                  <div className={`${s.fishCountContainer}`}>
                    <span>{this.fishCount}</span>
                    <img src="/fishy_blue.png" alt="" />
                  </div>
                  <div className={`${s.fishLabelContainer}`}>
                    <FormattedMessage {...messages.fishTotal} />
                  </div>
                </Col>
                <Col md={4}>
                  <div className={`${s.lastChangedLabelContainer}`}>
                    <FormattedMessage {...messages.lastChanged} />
                  </div>
                  <div className={`${s.lastChangedDateContainer}`}>
                    <span>{this.lastChangedDate}</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div>
          <Row className={layoutStyle.noMargin}>
            <Col md={12} lg={6}>
              <NewAnalysis
                history={this.props.history}
                onNewAnalysisAdded={this.onNewAnalysisAdded}
              />
            </Col>

            {datasets.map(this.renderCell)}
          </Row>
        </div>
      </div>
    );
  }
}

const createOrUpdateCalibratedData = gql`
  mutation createOrUpdateCalibratedData(
    $id: String
    $patch: CalibratedDataInput!
  ) {
    createOrUpdateCalibratedData(id: $id, patch: $patch) {
      id
    }
  }
`;

const deleteDataset = gql`
  mutation deleteDataset($id: String!) {
    deleteDataset(id: $id)
  }
`;

const startWorkerSyncMutation = gql`
  mutation startWorkerSync(
    $data: CalibrationInput!
    $calibratedDataId: String
  ) {
    startWorkerSync(data: $data, calibratedDataId: $calibratedDataId) {
      newColumnScores
    }
  }
`;

export default compose(
  graphql(getData, {
    name: 'data',
  }),
  graphql(startWorkerSyncMutation, {
    props: ({ mutate }) => ({
      startWorkerSync: ({ data, calibratedDataId }) =>
        mutate({
          variables: { data, calibratedDataId },
        }),
    }),
  }),
  graphql(createOrUpdateCalibratedData, {
    props: ({ mutate }) => ({
      createOrUpdateCalibratedData: (id, patch) =>
        mutate({
          variables: { id, patch },
        }),
    }),
  }),
  graphql(deleteDataset, {
    props: ({ mutate }) => ({
      deleteDataset: id =>
        mutate({
          variables: { id },
        }),
    }),
  }),
)(AuthenticationWrapper(withStyles(s, layoutStyle, buttonStyle)(Dashboard)));
