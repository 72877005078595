import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './CheckTable.scss';

class CheckTable extends React.Component {
  static propTypes = {
    tableRows: PropTypes.arrayOf(PropTypes.array).isRequired,
    darkLines: PropTypes.bool,
  };

  static defaultProps = {
    darkLines: false,
  };

  constructor(props) {
    super(props);

    this.renderCheck = this.renderCheck.bind(this);
    this.renderCross = this.renderCross.bind(this);
    this.renderTableRow = this.renderTableRow.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  renderCheck() {
    return <img className={s.icon} src="/check.png" alt="" />;
  }

  // eslint-disable-next-line class-methods-use-this
  renderCross() {
    return <img className={s.icon} src="/cross.png" alt="" />;
  }

  // eslint-disable-next-line class-methods-use-this
  renderTableRow(row, isLastRow) {
    const { darkLines } = this.props;

    const bottomBorderStyle = darkLines
      ? `${s.bottomBorder} ${s.dark}`
      : s.bottomBorder;

    const rightBorderStyle = darkLines
      ? `${s.rightBorder} ${s.dark}`
      : s.rightBorder;

    return (
      <tr className={!isLastRow ? bottomBorderStyle : null}>
        {row.map((value, index) => {
          const isLastColumn = index === row.length - 1;

          let displayeValue;
          if (value === true) displayeValue = this.renderCheck();
          else if (value === false) displayeValue = this.renderCross();
          else displayeValue = value;

          // eslint-disable-next-line prettier/prettier
          const cellClass = `${!isLastColumn ? rightBorderStyle : ''} ${index > 0 ? s.centeredCell : ''}`;

          return <td className={cellClass}>{displayeValue}</td>;
        })}
      </tr>
    );
  }

  render() {
    const { tableRows } = this.props;

    return (
      <div className={s.tableWrapper}>
        <table>
          <tbody>
            {tableRows.map((row, index) =>
              this.renderTableRow(row, index === tableRows.length - 1),
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withStyles(s)(CheckTable);
