import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Loading.scss';

class Loading extends React.Component {
  render() {
    return (
      <div className={s.loading}>
        <div className={s.loadingWhiteBackground} />
        <div className={s.loadingWrapper}>
          <img src="/fishy_blue.svg" alt="" className={s.empty} />
          <div className={s.rectangle} />
          <img src="/fishy_full.svg" alt="" className={s.full} />
        </div>
        <span className={s.loadingText}>Loading</span>
      </div>
    );
  }
}

export default withStyles(s)(Loading);
