import { defineMessages } from 'react-intl';

const messages = defineMessages({
  alertErrorTitle: {
    id: 'import.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertImporterErrorMessage: {
    id: 'import.alertImporterErrorMessage',
    defaultMessage: 'There has been an error while reading the imported file!',
    description: 'Message for error alert dialog if imported data is faulty',
  },
  alertUniqueIdColumnNotFoundMessage: {
    id: 'import.alertUniqueIdColumnNotFoundMessage',
    defaultMessage: 'Id column could not be found!',
    description:
      'Message for error alert dialog if no unique column was found when uploading',
  },
  alertInitialValueRowNotFound: {
    id: 'import.alertInitialValueRowNotFound',
    defaultMessage: 'Initial value row could not be found!',
    description:
      'Message for error alert dialog if no initial value row was found when uploading',
  },
  wrongFileFormat: {
    id: 'import.wrongFileFormat',
    defaultMessage:
      'The uploaded file has a wrong format. Only .xls and .xlsx files are allowed. Please choose another file.',
    description: 'Error message for wrong file format on import.',
  },
  newAnalysis: {
    id: 'import.newAnalysis',
    defaultMessage: 'New Analysis',
    description: 'Label for new analysis header.',
  },
  start: {
    id: 'import.start',
    defaultMessage: 'Start',
    description: 'Label for start button.',
  },

  /* pre settings messages */
  preSettingsHeader: {
    id: 'import.presettings.header',
    defaultMessage: 'Select data field',
    description: 'Header for presettings',
  },
  testResults: {
    id: 'import.presettings.testResults',
    defaultMessage: 'Test results',
    description: 'Label for Test Results template',
  },
  recruitingList: {
    id: 'import.presettings.recruitingList',
    defaultMessage: 'Recruiting list',
    description: 'Label for Recruiting List template',
  },
  sportsLiga: {
    id: 'import.presettings.sportsLiga',
    defaultMessage: 'Sports (Liga)',
    description: 'Label for Sports (Liga) template',
  },
  sportsChampionship: {
    id: 'import.presettings.sportsChampionship',
    defaultMessage: 'Sports (Championship)',
    description: 'Label for Sports (Championship) template',
  },
  custom: {
    id: 'import.presettings.custom',
    defaultMessage: 'Custom',
    description: 'Label for Custom template',
  },
  strategy: {
    id: 'import.presettings.strategy',
    defaultMessage: 'Strategy',
    description: 'Label for Strategy input',
  },
  mixingFactor: {
    id: 'import.presettings.mixingFactor',
    defaultMessage: 'Mixing factor',
    description: 'Label for Mixing Factor input',
  },
  algorithmImpact: {
    id: 'import.presettings.algorithmImpact',
    defaultMessage: 'Algorithm impact',
    description: 'Label for Algorithm Impact input',
  },
  idColumn: {
    id: 'import.presettings.idColumn',
    defaultMessage: 'Id column',
    description: 'Label for Id Column input',
  },
  initialValueRow: {
    id: 'import.presettings.initialValueRow',
    defaultMessage: 'Initial value row',
    description: 'Label for Initial Value Row input',
  },
  initialSort: {
    id: 'import.presettings.initialSort',
    defaultMessage: 'Initial sort',
    description: 'Label for Initial Sort input',
  },
  globalMaximum: {
    id: 'import.presettings.globalMaximum',
    defaultMessage: 'Global Maximum',
    description: 'Label for Global Maximum input',
  },
  fair: {
    id: 'import.presettings.fair',
    defaultMessage: 'fair',
    description: 'Label for fair option',
  },
  exclusive: {
    id: 'import.presettings.exclusive',
    defaultMessage: 'exclusive',
    description: 'Label for exclusive option',
  },
  mixed: {
    id: 'import.presettings.mixed',
    defaultMessage: 'mixed',
    description: 'Label for mixed option',
  },
  inverse: {
    id: 'import.presettings.inverse',
    defaultMessage: 'inverse',
    description: 'Label for inverse option',
  },
  low: {
    id: 'import.presettings.low',
    defaultMessage: 'low',
    description: 'Label for low option',
  },
  medium: {
    id: 'import.presettings.medium',
    defaultMessage: 'medium',
    description: 'Label for medium option',
  },
  hard: {
    id: 'import.presettings.hard',
    defaultMessage: 'hard',
    description: 'Label for hard option',
  },
  hardest: {
    id: 'import.presettings.hardest',
    defaultMessage: 'hardest',
    description: 'Label for hardest option',
  },

  /* button texts */
  cancel: {
    id: 'import.cancel',
    defaultMessage: 'Cancel',
    description: 'Label for Cancel button',
  },
  next: {
    id: 'import.next',
    defaultMessage: 'Next',
    description: 'Label for Next button',
  },
  settings: {
    id: 'import.settings',
    defaultMessage: 'Settings',
    description: 'Label for Settings button',
  },
});

export default messages;
