/* eslint-disable no-underscore-dangle */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  intlShape,
  injectIntl,
} from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaChevronDown from 'react-icons/lib/fa/chevron-down';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import TopNavigation from 'components/TopNavigation';
import { navItemsRight } from 'components/TopNavigation/navItems';
import { componentKeys, ClickTracker } from 'components/Analytics';

import s from './SubLandingPages.scss'; // eslint-disable-line css-modules/no-unused-class
import landingPageStyle from '../LandingPage.scss'; // eslint-disable-line css-modules/no-unused-class
import mainLandingPageStyle from '../MainLandingPage/LandingPageCarousel.scss'; // eslint-disable-line css-modules/no-unused-class
import topNavStyle from '../../TopNavigation/TopNavigation.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import mainLandingPageMessages from '../MainLandingPage/messages';
import globalMessages from '../../messages/globalMessages';
import landingPageMessages from '../messages';
import Tile from './Tile';
import RecommendationComponent from '../../Recommendation';
import Carousel from '../../Carousel';
import Footer from '../../Footer';

const scrollToSlide = slideRef => {
  const topOffset = window.innerWidth <= 1080 ? 75 : 100;

  window.scroll({
    top: slideRef.offsetTop - topOffset,
    left: 0,
    behavior: 'smooth',
  });
};

class ECommerceLandingPage extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      zoomedImage: null,
    };

    this.frontPageRef = React.createRef();
    this.slide1Ref = React.createRef();

    this.renderFrontPage = this.renderFrontPage.bind(this);
    this.renderSlide1 = this.renderSlide1.bind(this);
    this.renderSlide2 = this.renderSlide2.bind(this);
    this.renderSlide3 = this.renderSlide3.bind(this);
    this.renderSlide4 = this.renderSlide4.bind(this);
  }

  componentDidMount() {
    if (window.location.hash && window.location.hash === '#content') {
      scrollToSlide(this.slide1Ref.current);
    }
  }

  renderFrontPage() {
    return (
      <div
        className={`${s.frontPage} ${s.marginRemover}`}
        ref={this.frontPageRef}
      >
        <img
          className={`${s.bgImage} ${s.hideOnMobile}`}
          src="/landing_page/WDanube-Landingpage-E-Commerce.jpg"
          alt=""
        />
        <img
          className={`${s.bgImage} ${s.hideOnDesktop}`}
          src="/landing_page/WDanube-Landingpage-E-Commerce_mobile.jpg"
          alt=""
        />
        <div className={mainLandingPageStyle.bgOverlay} />
        <div className={s.content}>
          <div className={s.inner}>
            <div className={s.leftContent}>
              <h1>
                <FormattedHTMLMessage
                  {...mainLandingPageMessages.eCommerceHeader}
                />
              </h1>
              <ClickTracker
                componentKey={
                  componentKeys.ECOMMERCE_LANDING_PAGE_LEARN_MORE_BUTTON
                }
                style={{ display: 'inline-block' }}
              >
                <button
                  className={`btn-secondary btn-round btn-large btn-wide ${s.learnMoreButton}`} // eslint-disable-line prettier/prettier
                  onClick={() => {
                    scrollToSlide(this.slide1Ref.current);
                  }}
                >
                  <FormattedMessage {...landingPageMessages.learnMore} />
                </button>
              </ClickTracker>
            </div>
          </div>
          <div className={s.downButtonContainer}>
            <ClickTracker
              componentKey={
                componentKeys.ECOMMERCE_LANDING_PAGE_LEARN_MORE_BUTTON
              }
              style={{ display: 'inline-block' }}
            >
              <button
                onClick={() => {
                  scrollToSlide(this.slide1Ref.current);
                }}
              >
                <FaChevronDown />
              </button>
            </ClickTracker>
          </div>
        </div>
      </div>
    );
  }

  renderSlide1() {
    return (
      <div
        className={`${s.page} ${s.darkBluePage} ${s.marginRemover}`}
        ref={this.slide1Ref}
      >
        <h1>
          <FormattedMessage {...messages.ecommerceSlide1Text1} />
        </h1>
        <div className={s.tilesWrapper}>
          <Tile
            imagePath="/landing_page/sub_landing_pages/arrows_miss.png"
            content={
              <FormattedHTMLMessage {...messages.ecommerceSlide1TileText1} />
            }
          />
          <Tile
            imagePath="/landing_page/sub_landing_pages/arrows_outwards.png"
            content={
              <FormattedHTMLMessage {...messages.ecommerceSlide1TileText2} />
            }
          />
          <Tile
            imagePath="/landing_page/sub_landing_pages/shelf.png"
            content={
              <FormattedHTMLMessage {...messages.ecommerceSlide1TileText3} />
            }
          />
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSlide2() {
    return (
      <div className={`${s.page} ${s.lightBluePage} ${s.marginRemover}`}>
        <h1>
          <FormattedMessage
            {...messages.ecommerceSlide2Text1}
            values={{
              danube: <span className={s.blueText}>danube.ai</span>,
            }}
          />
        </h1>
        <div className={s.tilesWrapper}>
          <Tile
            imagePath="/landing_page/sub_landing_pages/arrow_hit.png"
            content={
              <FormattedHTMLMessage {...messages.ecommerceSlide2TileText1} />
            }
            light
          />
          <Tile
            imagePath="/landing_page/sub_landing_pages/arrows_inwards.png"
            content={
              <FormattedHTMLMessage {...messages.ecommerceSlide2TileText2} />
            }
            light
          />
          <Tile
            imagePath="/landing_page/sub_landing_pages/line_graph.png"
            content={
              <FormattedHTMLMessage {...messages.ecommerceSlide2TileText3} />
            }
            light
          />
        </div>
        <div className={s.centeredButtonContainer}>
          <ClickTracker
            componentKey={componentKeys.ECOMMERCE_LANDING_PAGE_CONTACT_BUTTON}
            style={{ display: 'inline-block' }}
          >
            <Link to="/contact?inquiry=eCommerce">
              <button className="btn-secondary btn-round btn-large">
                <FormattedMessage {...globalMessages.getInTouch} />
              </button>
            </Link>
          </ClickTracker>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSlide3() {
    return (
      <div className={`${s.page} ${s.lightBluePage} ${s.marginRemover}`}>
        <h1>
          <FormattedMessage {...messages.betterThanOthers} />
        </h1>
        <div className={s.illustrationWrapper}>
          <img
            src="/landing_page/sub_landing_pages/ecommerce/graph_1.png"
            alt=""
          />
        </div>
        <div className={s.centeredButtonContainer}>
          <ClickTracker
            componentKey={componentKeys.ECOMMERCE_LANDING_PAGE_CONTACT_BUTTON}
            style={{ display: 'inline-block' }}
          >
            <Link to="/contact?inquiry=eCommerce">
              <button className="btn-secondary btn-round btn-large">
                <FormattedMessage {...globalMessages.getInTouch} />
              </button>
            </Link>
          </ClickTracker>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSlide4() {
    return (
      <div className={`${s.page} ${s.greyPage} ${s.marginRemover}`}>
        <div className={s.carouselWrapper}>
          <Carousel sliderProps={{ draggable: false }}>
            <div className={s.carouselSlide}>
              <div className={s.carouselSlideInner}>
                <div className={s.topContent}>
                  <button
                    className={s.unstyledButton}
                    onClick={() => {
                      this.setState({
                        zoomedImage:
                          '/landing_page/sub_landing_pages/ecommerce/eCommerce_demo_slide_1.jpg',
                      });
                    }}
                  >
                    <img
                      className={s.fullImage}
                      src="/landing_page/sub_landing_pages/ecommerce/eCommerce_demo_slide_1.jpg"
                      alt=""
                    />
                  </button>
                </div>
                <div className={s.bottomText}>
                  <FormattedMessage {...messages.mediaSlide4Text1} />
                </div>
              </div>
            </div>
            <div className={s.carouselSlide}>
              <div className={s.carouselSlideInner}>
                <div className={s.topContent}>
                  <button
                    className={s.unstyledButton}
                    onClick={() => {
                      this.setState({
                        zoomedImage:
                          '/landing_page/sub_landing_pages/ecommerce/eCommerce_demo_slide_2.jpg',
                      });
                    }}
                  >
                    <img
                      className={s.fullImage}
                      src="/landing_page/sub_landing_pages/ecommerce/eCommerce_demo_slide_2.jpg"
                      alt=""
                    />
                  </button>
                </div>
                <div className={s.bottomText}>
                  <FormattedMessage {...messages.mediaSlide4Text2} />
                </div>
              </div>
            </div>
            <div className={s.carouselSlide}>
              <div className={s.carouselSlideInner}>
                <div className={s.topContent}>
                  <button
                    className={s.unstyledButton}
                    onClick={() => {
                      this.setState({
                        zoomedImage:
                          '/landing_page/sub_landing_pages/ecommerce/eCommerce_demo_slide_3.jpg',
                      });
                    }}
                  >
                    <img
                      className={s.fullImage}
                      src="/landing_page/sub_landing_pages/ecommerce/eCommerce_demo_slide_3.jpg"
                      alt=""
                    />
                  </button>
                </div>
                <div className={s.bottomText}>
                  <FormattedMessage {...messages.mediaSlide4Text3} />
                </div>
              </div>
            </div>
          </Carousel>
        </div>
        <div className={s.centeredButtonContainer}>
          <ClickTracker
            componentKey={componentKeys.ECOMMERCE_LANDING_PAGE_CONTACT_BUTTON}
            style={{ display: 'inline-block' }}
          >
            <Link to="/contact?inquiry=media">
              <button className="btn-secondary btn-round btn-large">
                <FormattedMessage {...globalMessages.getInTouch} />
              </button>
            </Link>
          </ClickTracker>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <TopNavigation
          navItemsRight={navItemsRight}
          // customLogoOnClick={() => scrollToSlide(this.frontPageRef.current)}
        />
        {this.renderFrontPage()}
        {this.renderSlide1()}
        {this.renderSlide2()}
        {this.renderSlide3()}
        {this.renderSlide4()}
        <div className={s.marginRemover}>
          <RecommendationComponent useDarkMode />
          <Footer />
        </div>
        <Modal
          show={this.state.zoomedImage !== null}
          dialogClassName={s.dialog}
          backdrop
          onHide={() => {
            this.setState({ zoomedImage: null });
          }}
          enforceFocus={false}
        >
          <Modal.Body className={s.dialogBody}>
            <button
              className={s.unstyledButton}
              onClick={() => {
                this.setState({ zoomedImage: null });
              }}
            >
              <img src={this.state.zoomedImage} alt="" />
            </button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(
  injectIntl(
    withStyles(
      s,
      topNavStyle,
      buttonStyle,
      landingPageStyle,
      mainLandingPageStyle,
    )(ECommerceLandingPage),
  ),
);

/* eslint-enable no-underscore-dangle */
