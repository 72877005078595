import React from 'react';

import PageProperties from 'components/PageProperties';
import Technology from 'components/Technology';
import routeMessages from 'routes/messages';

class TechnologyWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          key="technology-page-properties"
          page={{
            title: routeMessages.technologyTitle,
            description: routeMessages.technologyDescription,
            keywords: routeMessages.technologyKeywords,
          }}
        />
        <Technology />
      </div>
    );
  }
}

export default TechnologyWrapper;
