import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SyntaxHighlighter from 'react-syntax-highlighter';

import layoutStyle from '../../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from '../../../Page.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../../messages';
import wikiMessages from '../../../messages';
import {
  codeStyle,
  setRulesRequest,
  ruleType,
  equalityScoreType,
  setRulesResponse,
  percentageRuleFormulas,
} from '../../codeBlocks';

class SetRules extends React.Component {
  render() {
    return (
      <div className={s.apiText}>
        <p>
          <h1>
            <FormattedMessage {...wikiMessages.setRules} />
          </h1>
          <FormattedHTMLMessage {...messages.danubePredictionText1} />
        </p>
        <p>
          <h2>
            <FormattedMessage {...messages.request} />
          </h2>
          <FormattedHTMLMessage {...messages.danubePredictionText2} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {setRulesRequest}
          </SyntaxHighlighter>
          <br />
          <FormattedHTMLMessage
            {...messages.danubePredictionText3}
            values={{
              exampleLink: '/api/cloud/examples/prediction/set-rules',
            }}
          />
          <br />
          <br />
          <FormattedHTMLMessage {...messages.danubePredictionText3_1} />
          <br />
          <FormattedHTMLMessage {...messages.danubePredictionText4} />
          <br />
          <FormattedHTMLMessage {...messages.danubePredictionText5} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {ruleType}
          </SyntaxHighlighter>
          <FormattedHTMLMessage {...messages.danubePredictionText6} />
          <br />
          <FormattedHTMLMessage {...messages.danubePredictionText6dot5} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {percentageRuleFormulas}
          </SyntaxHighlighter>
          <br />
          <FormattedHTMLMessage {...messages.danubePredictionText7} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {equalityScoreType}
          </SyntaxHighlighter>
          <FormattedHTMLMessage {...messages.danubePredictionText8} />
        </p>
        <p>
          <h2>
            <FormattedMessage {...messages.response} />
          </h2>
          <FormattedHTMLMessage {...messages.danubePredictionText9} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {setRulesResponse}
          </SyntaxHighlighter>
        </p>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle)(SetRules);
