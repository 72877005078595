import { defineMessages } from 'react-intl';

const messages = defineMessages({
  api: {
    id: 'apiAndsdk.api',
    defaultMessage: 'API',
    description: 'header for api and sdk page',
  },
  useApi: {
    id: 'apiAndsdk.useApi',
    defaultMessage: 'Use API',
    description: 'header for api and sdk page',
  },
  prediction: {
    id: 'apiAndsdk.prediction',
    defaultMessage: 'Prediction',
    description: 'header for api and sdk page',
  },
  recommendation: {
    id: 'apiAndsdk.recommendation',
    defaultMessage: 'Recommendation',
    description: 'header for api and sdk page',
  },
  sdk: {
    id: 'apiAndsdk.sdk',
    defaultMessage: 'SDK',
    description: 'header for api and sdk page',
  },
  installSdk: {
    id: 'apiAndsdk.installSdk',
    defaultMessage: 'Install SDK',
    description: 'header for api and sdk page',
  },
  initializeClient: {
    id: 'apiAndsdk.initializeClient',
    defaultMessage: 'Initialize DanubeClient',
    description: 'header for api and sdk page',
  },
  getApiKey: {
    id: 'apiAndsdk.getApiKey',
    defaultMessage: 'Get API Key',
    description: 'header for api and sdk page',
  },
  examples: {
    id: 'apiAndsdk.examples',
    defaultMessage: 'Examples',
    description: 'header for api and sdk page',
  },
  formats: {
    id: 'apiAndsdk.formats',
    defaultMessage: 'Data Formats',
    description: 'header for api and sdk page',
  },
  predictionData: {
    id: 'apiAndsdk.predictionData',
    defaultMessage: 'Prediction Data',
    description: 'header for api and sdk page',
  },
  documentation: {
    id: 'apiAndsdk.documentation',
    defaultMessage: 'Documentation',
    description: 'header for api and sdk page',
  },
  overview: {
    id: 'apiAndsdk.overview',
    defaultMessage: 'Overview',
    description: 'header for api and sdk page',
  },
  setRules: {
    id: 'apiAndsdk.setRules',
    defaultMessage: 'setRules',
    description: 'header for api and sdk page',
  },
  getRules: {
    id: 'apiAndsdk.getRules',
    defaultMessage: 'getRules',
    description: 'header for api and sdk page',
  },
  danubePrediction: {
    id: 'apiAndsdk.danubePrediction',
    defaultMessage: 'danubePrediction',
    description: 'header for api and sdk page',
  },
  danubeRecommendation: {
    id: 'apiAndsdk.danubeRecommendation',
    defaultMessage: 'danubeRecommendation',
    description: 'header for api and sdk page',
  },
  resetCorrelationMatrix: {
    id: 'apiAndsdk.resetCorrelationMatrix',
    defaultMessage: 'resetCorrelationMatrix',
    description: 'header for api and sdk page',
  },
  endpoint: {
    id: 'apiAndsdk.endpoint',
    defaultMessage: 'endpoint',
    description: 'header for api and sdk page',
  },
  method: {
    id: 'apiAndsdk.method',
    defaultMessage: 'method',
    description: 'header for api and sdk page',
  },
  notAvaiableYet: {
    id: 'apiAndsdk.notAvaiableYet',
    defaultMessage: 'This API is not available yet.',
    description: 'header for api and sdk page',
  },
});

export default messages;
