import React from 'react';

import PageProperties from 'components/PageProperties';
import LandingPage from 'components/LandingPage';
import routeMessages from 'routes/messages';

class LandingPageWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.landingPageTitle,
            description: routeMessages.landingPageDescription,
            keywords: routeMessages.landingPageKeywords,
          }}
        />
        <LandingPage />
      </div>
    );
  }
}

export default LandingPageWrapper;
