import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';

import buttonStyle from '../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import render from '../ReduxForm/renderField';
import validations from '../ReduxForm/validations';
import validationMessages from '../ReduxForm/messages';
import * as normalizers from '../ReduxForm/normalizers';
import privacyMessages from './messages';
import s from './PrivacyAgreementForm.scss';

const selector = formValueSelector('privacyAgreementForm');

const validate = values => {
  const errors = {};
  if (!validations.required(values.name)) {
    errors.username = <FormattedMessage {...validationMessages.required} />;
  }
  return errors;
};

class PrivacyAgreementForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
    handleCancel: PropTypes.func.isRequired,
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit} className={s.privacyForm}>
        <fieldset>
          <h3>
            <FormattedMessage {...privacyMessages.gdpr} />
          </h3>
          <Field
            id="privacyPolicyAccepted"
            name="privacyPolicyAccepted"
            label={
              <FormattedHTMLMessage {...privacyMessages.privacyPolicyRead} />
            }
            type="checkbox"
            component={render.renderCheckbox}
            normalize={normalizers.booleanNormalizer}
          />
          <button
            className={`btn-primary btn-round btn-large ${s.button}`}
            disabled={this.props.submitting}
            onClick={this.props.handleCancel}
          >
            <FormattedMessage {...privacyMessages.reject} />
          </button>
          <button
            type="submit"
            className={`btn-secondary btn-round btn-large ${s.button}`}
            disabled={this.props.submitting || !this.props.checkboxValue}
          >
            <FormattedMessage {...privacyMessages.submit} />
          </button>
        </fieldset>
      </form>
    );
  }
}

export default connect(state => ({
  checkboxValue: selector(state, 'privacyPolicyAccepted'),
}))(
  reduxForm({
    form: 'privacyAgreementForm',
    validate,
  })(withStyles(buttonStyle, s)(injectIntl(PrivacyAgreementForm))),
);
