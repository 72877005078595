import React from 'react';

import PageProperties from 'components/PageProperties';
import Pricing from 'components/Pricing';
import routeMessages from 'routes/messages';

class PricingWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.pricingTitle,
            description: routeMessages.pricingDescription,
            keywords: routeMessages.pricingKeywords,
          }}
        />
        <Pricing />
      </div>
    );
  }
}

export default PricingWrapper;
