import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import s from './Page.scss'; // eslint-disable-line css-modules/no-unused-class
import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import SideNavigation from './SideNavigation';

import messages from './messages';
import GetApiKey from './OtherPages/GetApiKey';

import ApiDocUseApi from './ApiPages/Documentation/UseApi';

import ApiDocPredictionOverview from './ApiPages/Documentation/Prediction/Overview';
import ApiDocPredictionSetRules from './ApiPages/Documentation/Prediction/SetRules';
import ApiDocPredictionGetRules from './ApiPages/Documentation/Prediction/GetRules';
import ApiDocPredictionDanubePrediction from './ApiPages/Documentation/Prediction/DanubePrediction';

import ApiDocRecommendationOverview from './ApiPages/Documentation/Recommendation/Overview';
import ApiDocRecommendationDanubeRecommendation from './ApiPages/Documentation/Recommendation/DanubeRecommendation';
import ApiDocRecommendationResetCorrelationMatrix from './ApiPages/Documentation/Recommendation/ResetCorrelationMatrix';

import ApiExamplesPredictionSetRules from './ApiPages/Examples/Prediction/SetRules';
import ApiExamplesPredictionGetRules from './ApiPages/Examples/Prediction/GetRules';
import ApiExamplesPredictionDanubePrediction from './ApiPages/Examples/Prediction/DanubePrediction';

import ApiExamplesRecommendationDanubeRecommendation from './ApiPages/Examples/Recommendation/DanubeRecommendation';
import ApiExamplesRecommendationResetCorrelationMatrix from './ApiPages/Examples/Recommendation/ResetCorrelationMatrix';

import InstallSdk from './SdkPages/Documentation/InstallSdk';
import SdkClientInitialization from './SdkPages/Documentation/ClientInitialization';

import SdkDocPredictionOverview from './SdkPages/Documentation/Prediction/Overview';
import SdkDocPredictionSetRules from './SdkPages/Documentation/Prediction/SetRules';
import SdkDocPredictionGetRules from './SdkPages/Documentation/Prediction/GetRules';
import SdkDocPredictionDanubePrediction from './SdkPages/Documentation/Prediction/DanubePrediction';

import SdkDocRecommendationOverview from './SdkPages/Documentation/Recommendation/Overview';
import SdkDocRecommendationDanubeRecommendation from './SdkPages/Documentation/Recommendation/DanubeRecommendation';
import SdkDocRecommendationResetCorrelationMatrix from './SdkPages/Documentation/Recommendation/ResetCorrelationMatrix';

import SdkExamplesPredictionSetRules from './SdkPages/Examples/Prediction/SetRules';
import SdkExamplesPredictionGetRules from './SdkPages/Examples/Prediction/GetRules';
import SdkExamplesPredictionDanubePrediction from './SdkPages/Examples/Prediction/DanubePrediction';

import SdkExamplesRecommendationDanubeRecommendation from './SdkPages/Examples/Recommendation/DanubeRecommendation';
import SdkExamplesRecommendationResetCorrelationMatrix from './SdkPages/Examples/Recommendation/ResetCorrelationMatrix';

import DanubePredictionDataFormat from './HelperPages/PredictionDataFormat';

const apiNavs = [
  {
    id: 'getApiKey',
    name: 'Get API key',
    path: '/api/get-api-key',
    component: GetApiKey,
  },
  {
    id: 'api',
    name: <FormattedMessage {...messages.api} />,
    subNavs: [
      {
        id: 'api-1',
        name: <FormattedMessage {...messages.documentation} />,
        subNavs: [
          {
            id: 'api-1.1',
            name: <FormattedMessage {...messages.useApi} />,
            path: '/api/cloud/documentation/use-api',
            component: ApiDocUseApi,
          },
          {
            id: 'api-1.2',
            name: <FormattedMessage {...messages.prediction} />,
            subNavs: [
              {
                id: 'api-1.2.1',
                name: <FormattedMessage {...messages.overview} />,
                path: '/api/cloud/documentation/prediction/overview',
                component: ApiDocPredictionOverview,
              },
              {
                id: 'api-1.2.2',
                name: <FormattedMessage {...messages.setRules} />,
                path: '/api/cloud/documentation/prediction/set-rules',
                component: ApiDocPredictionSetRules,
              },
              {
                id: 'api-1.2.3',
                name: <FormattedMessage {...messages.getRules} />,
                path: '/api/cloud/documentation/prediction/get-rules',
                component: ApiDocPredictionGetRules,
              },
              {
                id: 'api-1.2.4',
                name: <FormattedMessage {...messages.danubePrediction} />,
                path: '/api/cloud/documentation/prediction/danube-prediction',
                component: ApiDocPredictionDanubePrediction,
              },
            ],
          },
          {
            id: 'api-1.3',
            name: <FormattedMessage {...messages.recommendation} />,
            subNavs: [
              {
                id: 'api-1.3.1',
                name: <FormattedMessage {...messages.overview} />,
                path: '/api/cloud/documentation/recommendation/overview',
                component: ApiDocRecommendationOverview,
              },
              {
                id: 'api-1.3.2',
                name: <FormattedMessage {...messages.danubeRecommendation} />,
                path:
                  '/api/cloud/documentation/recommendation/danube-recommendation',
                component: ApiDocRecommendationDanubeRecommendation,
              },
              {
                id: 'api-1.3.3',
                name: <FormattedMessage {...messages.resetCorrelationMatrix} />,
                path:
                  '/api/cloud/documentation/recommendation/reset-correlation-matrix',
                component: ApiDocRecommendationResetCorrelationMatrix,
              },
            ],
          },
        ],
      },
      {
        id: 'api-2',
        name: <FormattedMessage {...messages.examples} />,
        subNavs: [
          {
            id: 'api-2.1',
            name: <FormattedMessage {...messages.prediction} />,
            subNavs: [
              {
                id: 'api-2.1.1',
                name: <FormattedMessage {...messages.setRules} />,
                path: '/api/cloud/examples/prediction/set-rules',
                component: ApiExamplesPredictionSetRules,
              },
              {
                id: 'api-2.1.2',
                name: <FormattedMessage {...messages.getRules} />,
                path: '/api/cloud/examples/prediction/get-rules',
                component: ApiExamplesPredictionGetRules,
              },
              {
                id: 'api-2.1.3',
                name: <FormattedMessage {...messages.danubePrediction} />,
                path: '/api/cloud/examples/prediction/danube-prediction',
                component: ApiExamplesPredictionDanubePrediction,
              },
            ],
          },
          {
            id: 'api-2.2',
            name: <FormattedMessage {...messages.recommendation} />,
            subNavs: [
              {
                id: 'api-2.2.1',
                name: <FormattedMessage {...messages.danubeRecommendation} />,
                path:
                  '/api/cloud/examples/recommendation/danube-recommendation',
                component: ApiExamplesRecommendationDanubeRecommendation,
              },
              {
                id: 'api-2.2.2',
                name: <FormattedMessage {...messages.resetCorrelationMatrix} />,
                path:
                  '/api/cloud/examples/recommendation/reset-correlation-matrix',
                component: ApiExamplesRecommendationResetCorrelationMatrix,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'sdk',
    name: <FormattedMessage {...messages.sdk} />,
    subNavs: [
      {
        id: 'sdk-1',
        name: <FormattedMessage {...messages.documentation} />,
        subNavs: [
          {
            id: 'sdk-1.1',
            name: <FormattedMessage {...messages.installSdk} />,
            path: '/api/sdk/documentation/install',
            component: InstallSdk,
          },
          {
            id: 'sdk-1.2',
            name: <FormattedMessage {...messages.initializeClient} />,
            path: '/api/sdk/documentation/initialize-client',
            component: SdkClientInitialization,
          },
          {
            id: 'sdk-1.3',
            name: <FormattedMessage {...messages.prediction} />,
            subNavs: [
              {
                id: 'sdk-1.3.1',
                name: <FormattedMessage {...messages.overview} />,
                path: '/api/sdk/documentation/prediction/overview',
                component: SdkDocPredictionOverview,
              },
              {
                id: 'api-1.3.2',
                name: <FormattedMessage {...messages.setRules} />,
                path: '/api/sdk/documentation/prediction/set-rules',
                component: SdkDocPredictionSetRules,
              },
              {
                id: 'api-1.3.3',
                name: <FormattedMessage {...messages.getRules} />,
                path: '/api/sdk/documentation/prediction/get-rules',
                component: SdkDocPredictionGetRules,
              },
              {
                id: 'api-1.3.4',
                name: <FormattedMessage {...messages.danubePrediction} />,
                path: '/api/sdk/documentation/prediction/danube-prediction',
                component: SdkDocPredictionDanubePrediction,
              },
            ],
          },
          {
            id: 'sdk-1.4',
            name: <FormattedMessage {...messages.recommendation} />,
            subNavs: [
              {
                id: 'sdk-1.4.1',
                name: <FormattedMessage {...messages.overview} />,
                path: '/api/sdk/documentation/recommendation/overview',
                component: SdkDocRecommendationOverview,
              },
              {
                id: 'api-1.4.2',
                name: <FormattedMessage {...messages.danubeRecommendation} />,
                path:
                  '/api/sdk/documentation/recommendation/danube-recommendation',
                component: SdkDocRecommendationDanubeRecommendation,
              },
              {
                id: 'api-1.4.3',
                name: <FormattedMessage {...messages.resetCorrelationMatrix} />,
                path:
                  '/api/sdk/documentation/recommendation/reset-correlation-matrix',
                component: SdkDocRecommendationResetCorrelationMatrix,
              },
            ],
          },
        ],
      },
      {
        id: 'sdk-2',
        name: <FormattedMessage {...messages.examples} />,
        subNavs: [
          {
            id: 'sdk-2.1',
            name: <FormattedMessage {...messages.prediction} />,
            subNavs: [
              {
                id: 'sdk-2.1.1',
                name: <FormattedMessage {...messages.setRules} />,
                path: '/api/sdk/examples/prediction/set-rules',
                component: SdkExamplesPredictionSetRules,
              },
              {
                id: 'sdk-2.1.2',
                name: <FormattedMessage {...messages.getRules} />,
                path: '/api/sdk/examples/prediction/get-rules',
                component: SdkExamplesPredictionGetRules,
              },
              {
                id: 'sdk-2.1.3',
                name: <FormattedMessage {...messages.danubePrediction} />,
                path: '/api/sdk/examples/prediction/danube-prediction',
                component: SdkExamplesPredictionDanubePrediction,
              },
            ],
          },
          {
            id: 'sdk-2.2',
            name: <FormattedMessage {...messages.recommendation} />,
            subNavs: [
              {
                id: 'sdk-2.2.1',
                name: <FormattedMessage {...messages.danubeRecommendation} />,
                path: '/api/sdk/examples/recommendation/danube-recommendation',
                component: SdkExamplesRecommendationDanubeRecommendation,
              },
              {
                id: 'sdk-2.2.2',
                name: <FormattedMessage {...messages.resetCorrelationMatrix} />,
                path:
                  '/api/sdk/examples/recommendation/reset-correlation-matrix',
                component: SdkExamplesRecommendationResetCorrelationMatrix,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 'formats',
    name: <FormattedMessage {...messages.formats} />,
    subNavs: [
      {
        id: 'formats-1',
        name: <FormattedMessage {...messages.predictionData} />,
        path: '/api/formats/prediction-data',
        component: DanubePredictionDataFormat,
      },
    ],
  },
];

const getRoutesRecursive = (navs, routes) => {
  navs.forEach(nav => {
    if (nav.subNavs) {
      getRoutesRecursive(nav.subNavs, routes);
    } else {
      routes.push({ ...nav });
    }
  });
  return routes;
};

class ApiWiki extends React.Component {
  constructor(props) {
    super(props);

    const routesArray = [];
    this.routes = getRoutesRecursive(apiNavs, routesArray);
  }

  render() {
    return (
      <div className={`${s.wikiContainer} ${s.marginRemover}`}>
        <Row className={layoutStyle.noMargin}>
          <Col lg={4} className={s.pagePadding}>
            <SideNavigation navItems={apiNavs} />
          </Col>
          <Col lg={8} className={layoutStyle.noPadding}>
            <Switch>
              {this.routes.map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  component={route.component}
                  exact
                />
              ))}
            </Switch>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle)(ApiWiki);
