import { defineMessages } from 'react-intl';

const messages = defineMessages({
  required: {
    id: 'validation.required',
    defaultMessage: 'This field is required',
    description: 'Validation error message for required fields',
  },
  oneRequired: {
    id: 'validation.oneRequired',
    defaultMessage: 'At least one field is required',
    description:
      'Validation error message for for at least one required fields',
  },
  passwordConfirmation: {
    id: 'validation.passwordConfirmation',
    defaultMessage: 'Passwords do not match',
    description: 'Validation error message for password Confirmation',
  },
  password: {
    id: 'validation.password',
    defaultMessage:
      'Your password must be at least 8 characters long, containing an upper case letter, digit and special character',
    description: 'Validation error message for password',
  },
  email: {
    id: 'validation.email',
    defaultMessage: 'Not a valid e-mail address',
    description: 'Validation error message for malformed e-mail addresses',
  },
  datasetName: {
    id: 'validation.datasetName',
    defaultMessage: 'Dataset name can not be longer than 255 characters',
    description: 'Validation error message for dataset name',
  },
  submit: {
    id: 'form.submit',
    defaultMessage: 'Save',
    description: 'Default submit button label',
  },
  cancel: {
    id: 'form.cancel',
    defaultMessage: 'Cancel',
    description: 'Default cancel button label',
  },
  unsavedChanges: {
    id: 'form.unsavedChanges',
    defaultMessage: 'This form contains unsaved changes',
    description: 'Info message if form contains unsaved changes',
  },
  add: {
    id: 'form.add',
    defaultMessage: 'Add',
    description: 'Default value for add (entry) buttons',
  },
  remove: {
    id: 'form.remove',
    defaultMessage: 'Delete',
    description: 'Default value for remove (entry) buttons',
  },
  pleaseChoose: {
    id: 'form.pleaseChoose',
    defaultMessage: 'Please choose ...',
    description: 'Default value for empty option for select fields',
  },
});

export default messages;
