import React from 'react';

import PageProperties from 'components/PageProperties';
import Imprint from 'components/Imprint';
import routeMessages from 'routes/messages';

class ImprintWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.imprintTitle,
            description: routeMessages.imprintDescription,
            keywords: routeMessages.imprintKeywords,
          }}
        />
        <Imprint />
      </div>
    );
  }
}

export default ImprintWrapper;
