import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-bootstrap';

import s from './LandingPage.scss';
import SearchMaskForm from '../SearchMask/SearchMaskForm';
import ResultsList from '../ResultsList';
import messages from '../SearchMask/messages';

const randomString = (charCount, chars) => {
  let randString = '';
  for (let i = 0; i < charCount; i += 1) {
    randString += chars[Math.floor(Math.random() * chars.length)];
  }
  return randString;
};

class LandingPage extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      skipPageLoadQuery: true,
    };

    this.onSearch = this.onSearch.bind(this);
    this.scrollToResults = this.scrollToResults.bind(this);

    this.sessionId = randomString(
      64,
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    );

    this.resultListRef = React.createRef();
  }

  onSearch(formValues) {
    this.setState({ skipPageLoadQuery: false });
    const filter = { ...formValues.filter };
    const sort = { ...formValues.sort };

    // map select (label + value) to single property (value)
    /* eslint-disable no-param-reassign */
    const remapSelect = (object, id, multi = false) => {
      if (!(id in object)) {
        object[id] = { value: '' };
      }
      if (multi) {
        if (Array.isArray(object[id])) {
          object[id] = object[id].map(prop => prop.value);
        } else {
          // no value set
          object[id] = [];
        }
      } else if (object[id].value.trim() === '') {
        delete object[id];
      } else {
        object[id] = object[id].value;
      }
    };
    /* eslint-enable no-param-reassign */
    remapSelect(filter, 'localization_address_zip', true);
    remapSelect(filter, 'type_transferType');
    remapSelect(sort, 'strategy');
    remapSelect(sort, 'ordering');

    /* eslint-disable no-param-reassign */
    const toNumber = (object, id) => {
      if (id in object) {
        object[id] = Number(object[id]);
      }
    };
    /* eslint-enable no-param-reassign */

    toNumber(filter, 'priceInformation_primaryPriceFrom');
    toNumber(filter, 'priceInformation_primaryPriceTo');
    toNumber(filter, 'area_total_areaFrom');
    toNumber(filter, 'area_total_areaTo');
    toNumber(filter, 'area_numberOfRoomsFrom');
    toNumber(filter, 'area_numberOfBalconiesFrom');
    toNumber(filter, 'area_numberOfTerraceFrom');
    toNumber(filter, 'Balconies_and_Terraces');

    // restructure sorting object to match server pattern
    const sortServer = {};

    /* eslint-disable prettier/prettier */
    if (sort.ordering === 'desc') {
      if (sort.strategy === 'zip') sortServer.sortLocalization_address_zipDesc = true;
      if (sort.strategy === 'price') sortServer.sortPriceInformation_primaryPriceDesc = true;
      if (sort.strategy === 'area') sortServer.sortArea_total_areaDesc = true;
      if (sort.strategy === 'danube') sortServer.sortDanubeDesc = true;
    } else if (sort.ordering === 'asc') {
      if (sort.strategy === 'zip') sortServer.sortLocalization_address_zipAsc = true;
      if (sort.strategy === 'price') sortServer.sortPriceInformation_primaryPriceAsc = true;
      if (sort.strategy === 'area') sortServer.sortArea_total_areaAsc = true;
      if (sort.strategy === 'danube') sortServer.sortDanubeAsc = true;
    }
    /* eslint-enable prettier/prettier */
    // merge filter and sort, as on server side all parameters are in one object
    const filterAndSort = {
      ...filter,
      ...sortServer,
    };

    this.setState({
      filter: filterAndSort,
    });
  }

  scrollToResults() {
    if (this.resultListRef.current) {
      window.scroll({
        top: this.resultListRef.current.offsetTop + 74,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  render() {
    const { intl } = this.props;

    return (
      <div className={s.landingPageContainer}>
        <Row>
          <Col mdOffset={2} md={8}>
            <SearchMaskForm
              onSubmit={this.onSearch}
              initialValues={{
                filter: {
                  type_transferType: {
                    value: 'RENT',
                    label: intl.formatMessage(messages.type_rent),
                  },
                  showSuggestions: false,
                },
                sort: {
                  strategy: {
                    value: 'danube',
                    label: intl.formatMessage(messages.sortWithDanubeAI),
                  },
                  ordering: {
                    value: 'asc',
                    label: intl.formatMessage(messages.ascending),
                  },
                },
              }}
            />
            <div ref={this.resultListRef}>
              <ResultsList
                filter={this.state.filter}
                skipPageLoadQuery={this.state.skipPageLoadQuery}
                sessionId={this.sessionId}
                onLoadingFinished={this.scrollToResults}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(LandingPage));
