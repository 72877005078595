import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { Field, change } from 'redux-form';

import {
  COLUMN_TYPE_BOOLEAN,
  COLUMN_TYPE_NUMERIC_PERCENTAGE,
} from './../../constants';

import s from './ColumnImporter.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import render from '../ReduxForm/renderField';
import * as normalizers from '../ReduxForm/normalizers';

class ColumnSettingsContainer extends React.Component {
  static propTypes = {
    field: PropTypes.string.isRequired,
    columnType: PropTypes.string.isRequired,
    rows: PropTypes.arrayOf(PropTypes.string).isRequired,
    skipped: PropTypes.bool.isRequired,
    fixed: PropTypes.bool.isRequired,
    settingsExpanded: PropTypes.bool.isRequired,
    onApply: PropTypes.func.isRequired,
    changeFieldValue: PropTypes.func.isRequired,
    onColumnTypeChanged: PropTypes.func.isRequired,
  };

  render() {
    const {
      field,
      columnType,
      rows,
      skipped,
      settingsExpanded,
      fixed,
      onApply,
      onApplyAndSort,
    } = this.props;

    let settingsClass = `${s.columnSettingsContainer}`;
    if (settingsExpanded) {
      settingsClass += ` ${s.expanded}`;
    }

    return (
      <div className={`${settingsClass} ${s.withSpacing}`}>
        <div className={s.settingsHeader}>
          <h2>
            <FormattedMessage {...messages.rules} />
          </h2>
        </div>

        <div className={s.settingsBody}>
          <div className={s.skipFixedContainer}>
            <button
              className={
                skipped ? `${s.toggleButton} ${s.toggled}` : `${s.toggleButton}`
              }
              type="button"
              onClick={() => {
                if (!skipped && fixed) {
                  this.props.changeFieldValue(
                    'columns',
                    `${field}.fixed`,
                    !fixed,
                  );
                }
                this.props.changeFieldValue(
                  'columns',
                  `${field}.skipped`,
                  !skipped,
                );
              }}
            >
              <FormattedMessage {...messages.skip} />
            </button>
            <button
              className={
                fixed ? `${s.toggleButton} ${s.toggled}` : `${s.toggleButton}`
              }
              type="button"
              onClick={() => {
                if (!fixed && skipped) {
                  this.props.changeFieldValue(
                    'columns',
                    `${field}.skipped`,
                    !skipped,
                  );
                }
                this.props.changeFieldValue(
                  'columns',
                  `${field}.fixed`,
                  !fixed,
                );
              }}
            >
              <FormattedMessage {...messages.fixed} />
            </button>
          </div>

          <div className={s.inputFieldContainer}>
            <span className={s.fieldLabel}>
              <FormattedMessage {...messages.name} />
            </span>
            <Field
              id={`${field}.name`}
              name={`${field}.name`}
              type="text"
              component={render.renderInput}
            />
          </div>

          <div className={s.inputFieldContainer}>
            <span className={s.fieldLabel}>
              <FormattedMessage {...messages.columnScore} />
            </span>
            <Field
              id={`${field}.columnScoreInput`}
              name={`${field}.columnScoreInput`}
              type="number"
              min={0}
              step="any"
              component={render.renderInput}
            />
          </div>

          <Field
            id={`${field}.columnType`}
            name={`${field}.columnType`}
            formName="columns"
            values={{
              left: COLUMN_TYPE_BOOLEAN,
              right: COLUMN_TYPE_NUMERIC_PERCENTAGE,
            }}
            labelFormatter={label => {
              switch (label) {
                case COLUMN_TYPE_BOOLEAN:
                  return <FormattedMessage {...messages.binary} />;
                case COLUMN_TYPE_NUMERIC_PERCENTAGE:
                  return <FormattedMessage {...messages.percentage} />;
                default:
                  return label;
              }
            }}
            onSwitch={newValue =>
              this.props.onColumnTypeChanged(field, newValue)
            }
            component={render.renderSwitcher}
          />

          {columnType === COLUMN_TYPE_BOOLEAN && (
            <div
              className={`${s.inputFieldContainer} ${
                s.oneEqualsToFieldContainer
              }`}
            >
              <span className={s.fieldLabel}>1 =</span>
              <Field
                id={`${field}.oneEqualsTo`}
                name={`${field}.oneEqualsTo`}
                component={render.renderSelect}
              >
                {[...new Set(rows.filter(row => row && row.trim() !== ''))].map(
                  (row, rIndex) => (
                    // eslint-disable-next-line
                    <option key={`selectRow-${rIndex}`} value={row}>
                      {row}
                    </option>
                  ),
                )}
              </Field>
            </div>
          )}

          {columnType === COLUMN_TYPE_NUMERIC_PERCENTAGE && (
            <div className={`${s.inputFieldContainer} ${s.hundredEqualsTo}`}>
              <span className={s.fieldLabel}>
                <FormattedMessage {...messages.hundredPercentAbove} /> =
              </span>
              <Field
                id={`${field}.hundredPercentEqualsTo`}
                name={`${field}.hundredPercentEqualsTo`}
                type="number"
                component={render.renderInput}
              />
            </div>
          )}

          <div className={s.treatAsZeroContainer}>
            <Field
              id={`${field}.treatEmptyAsZero`}
              name={`${field}.treatEmptyAsZero`}
              label={
                columnType === COLUMN_TYPE_NUMERIC_PERCENTAGE ? (
                  <FormattedMessage {...messages.treatAsZeroPercentage} />
                ) : (
                  <FormattedMessage {...messages.treatAsZero} />
                )
              }
              type="checkbox"
              component={render.renderCheckbox}
              normalize={normalizers.booleanNormalizer}
            />
          </div>

          <div className={s.submitButtonDiv}>
            <button
              className="btn-primary"
              style={{ display: 'block', marginBottom: '2px' }}
              type="button"
              onClick={onApply}
            >
              <FormattedMessage {...messages.apply} />
            </button>
            <button
              className="btn-primary"
              style={{ display: 'block', fontSize: '12px' }}
              type="button"
              onClick={onApplyAndSort}
            >
              <FormattedMessage {...messages.applyAndSort} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (form, field, value) =>
    dispatch(change(form, field, value)),
});

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(s, buttonStyle)(ColumnSettingsContainer));
