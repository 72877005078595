import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col } from 'react-bootstrap';

import Loading from '../../../../components/Loading';
import s from './LandingPage.scss';
import SearchMaskForm from '../SearchMask/SearchMaskForm';
import ResultsList from '../ResultsList';

class LandingPage extends React.Component {
  static propTypes = {
    educationLevelsQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      educationLevels: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    additionalSkillsQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      additionalSkills: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      skipPageLoadQuery: true,
    };

    this.onSearch = this.onSearch.bind(this);
    this.scrollToResults = this.scrollToResults.bind(this);

    this.resultListRef = React.createRef();
  }

  onSearch(formValues) {
    const filter = { ...formValues.filter };

    filter.prevEmployerCount = '#2';

    if ('educationLevel' in filter) {
      if (filter.educationLevel == null) filter.educationLevel = [];
      else filter.educationLevel = filter.educationLevel.map(ed => ed.value);
    }

    if ('additionalSkills' in filter) {
      if (filter.additionalSkills == null) filter.additionalSkills = [];
      else filter.additionalSkills = filter.additionalSkills.map(ed => ed.value); // eslint-disable-line prettier/prettier
    }

    this.setState({
      skipPageLoadQuery: false,
      filter,
    });
  }

  scrollToResults() {
    if (this.resultListRef.current) {
      window.scroll({
        top: this.resultListRef.current.offsetTop + 74,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  render() {
    const { educationLevelsQuery, additionalSkillsQuery } = this.props;

    if (!educationLevelsQuery || !additionalSkillsQuery) return null;

    if (educationLevelsQuery.loading || additionalSkillsQuery.loading) {
      return <Loading />;
    }

    return (
      <div className={s.landingPageContainer}>
        <Row>
          <Col mdOffset={2} md={8}>
            <SearchMaskForm
              onSubmit={this.onSearch}
              educationLevels={educationLevelsQuery.educationLevels}
              additionalSkills={additionalSkillsQuery.additionalSkills}
              initialValues={{
                filter: {
                  salaryImportance: 0.5,
                  experienceOfficeImportance: 0.5,
                  educationLevel: [],
                  msOfficeLevelImportance: 0.5,
                  germanLevelImportance: 0.5,
                  englishLevelImportance: 0.5,
                  additionalSkills: [],
                },
              }}
            />
            <div ref={this.resultListRef}>
              <ResultsList
                filter={this.state.filter}
                skipPageLoadQuery={this.state.skipPageLoadQuery}
                onLoadingFinished={this.scrollToResults}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const educationLevelsQuery = gql`
  query educationLevelsQuery {
    educationLevels
  }
`;

const additionalSkillsQuery = gql`
  query additionalSkillsQuery {
    additionalSkills
  }
`;

export default compose(
  graphql(educationLevelsQuery, {
    name: 'educationLevelsQuery',
  }),
  graphql(additionalSkillsQuery, {
    name: 'additionalSkillsQuery',
  }),
)(withStyles(s)(LandingPage));
