import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SyntaxHighlighter from 'react-syntax-highlighter';

import layoutStyle from '../../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from '../../../Page.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../../messages';
import wikiMessages from '../../../messages';
import {
  codeStyle,
  danubeRecommendationRequest,
  recommendationInputDataType,
  danubeRecommendationResponse,
} from '../../codeBlocks';

class DanubeRecommendation extends React.Component {
  render() {
    return (
      <div className={s.apiText}>
        <p>
          <h1>
            <FormattedMessage {...wikiMessages.danubeRecommendation} />
          </h1>
          <FormattedHTMLMessage {...messages.danubeRecommendationText1} />
        </p>
        <p>
          <h2>
            <FormattedMessage {...messages.request} />
          </h2>
          <FormattedHTMLMessage {...messages.danubeRecommendationText2} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {danubeRecommendationRequest}
          </SyntaxHighlighter>
          <br />
          <FormattedHTMLMessage
            {...messages.danubePredictionText3}
            values={{
              exampleLink:
                '/api/cloud/examples/recommendation/danube-recommendation',
            }}
          />
          <br />
          <br />
          <FormattedHTMLMessage {...messages.danubeRecommendationText3} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {recommendationInputDataType}
          </SyntaxHighlighter>
          <FormattedHTMLMessage {...messages.danubeRecommendationText4} />
        </p>
        <p>
          <h2>
            <FormattedMessage {...messages.response} />
          </h2>
          <FormattedHTMLMessage {...messages.danubeRecommendationText5} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {danubeRecommendationResponse}
          </SyntaxHighlighter>
          <FormattedHTMLMessage {...messages.danubeRecommendationText6} />
        </p>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle)(DanubeRecommendation);
