import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { Link } from 'react-router-dom';

import layoutStyle from '../../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from '../../../Page.scss'; // eslint-disable-line css-modules/no-unused-class
import apiMessages from '../../../ApiPages/messages';
import messages from '../../messages';
import wikiMessages from '../../../messages';
import { codeStyle, resetCorrelationMatríx } from '../../codeBlocks';

class ResetCorrelationMatrix extends React.Component {
  render() {
    return (
      <div className={s.apiText}>
        <p>
          <h1>
            <FormattedMessage {...wikiMessages.resetCorrelationMatrix} />
          </h1>
          <FormattedHTMLMessage {...messages.danubeRecommendationText3} />{' '}
          <FormattedMessage
            {...apiMessages.danubeRecommendationResetMatrixWarning}
            values={{
              contactUs: (
                <Link to="/contact" target="_blank">
                  <FormattedMessage
                    {...apiMessages.danubeRecommendationOverviewContactUs}
                  />
                </Link>
              ),
            }}
          />
        </p>
        <p>
          <h2>
            <FormattedMessage {...messages.method} />
          </h2>
          <FormattedHTMLMessage {...messages.sdkDanubeRecommendationText2} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {resetCorrelationMatríx}
          </SyntaxHighlighter>
        </p>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle)(ResetCorrelationMatrix);
