import React from 'react';
import PropTypes from 'prop-types';

import AuthorizationWrapper from 'components/Auth/AuthorizationWrapper';
import RouteBundler from 'components/RouteBundler';
import Dashboard from 'components/Dashboard';
import User from 'components/User';
import { View as AnalyticsView } from 'components/Analytics';
import Hashes from 'components/Analytics/Hashes';
import roles from 'core/roles';

/* eslint-disable prettier/prettier */
const routes = [
  { path: '', component: Dashboard, exact: true },
  { path: 'dashboard', component: Dashboard, exact: true },
  { path: 'users', component: User },
  { path: 'analytics', component: AnalyticsView },
  { path: 'hashes', component: Hashes },
];
/* eslint-enable prettier/prettier */

class Admin extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    return (
      <RouteBundler
        prefix={`${this.props.match.path}/`}
        routes={routes}
        me={this.props.me}
      />
    );
  }
}

export default AuthorizationWrapper([roles.ADMIN])(Admin);
