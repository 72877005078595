import { SET_REDIRECT_SOURCE, SET_REDIRECT_ACTION } from '../constants';

const initialState = {
  source: null,
  action: null,
};

export default function redirect(state = { ...initialState }, action) {
  switch (action.type) {
    case SET_REDIRECT_SOURCE:
      return {
        ...state,
        source: action.payload.source,
      };
    case SET_REDIRECT_ACTION:
      return {
        ...state,
        action: action.payload.action,
      };
    default:
      return state;
  }
}
