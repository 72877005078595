import { defineMessages } from 'react-intl';

const messages = defineMessages({
  dashboard: {
    id: 'sidebar.dashboard',
    defaultMessage: 'Dashboard',
    description: 'Label for dashboard link in sidebar',
  },
  users: {
    id: 'sidebar.users',
    defaultMessage: 'Users',
    description: 'Label for users link in sidebar',
  },
  analytics: {
    id: 'sidebar.analytics',
    defaultMessage: 'Analytics',
    description: 'Label for analytics link in sidebar',
  },
  hashes: {
    id: 'sidebar.hashes',
    defaultMessage: 'Hashes',
    description: 'Label for hashes link in sidebar',
  },
});

export default messages;
