import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import { componentKeys, ClickTracker } from 'components/Analytics';

import requestWhitepaperStyles from '../WhitepaperButton/WhitepaperButton.scss'; // eslint-disable-line css-modules/no-unused-class
import s from './RequestDemoModal/RequestDemoModal.scss'; // eslint-disable-line css-modules/no-unused-class
import HubspotAlert from '../HubspotAlert';
import messages from './RequestDemoModal/messages';
import { parseGetParameters } from '../../core/url';

class RequestDemoButton extends React.Component {
  static propTypes = {
    isTopNav: PropTypes.bool.isRequired,
    localeToFormIdMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);

    let showRequestModal = false;

    if (props.location && props.location.search) {
      const getParameters = parseGetParameters(props.location.search);
      if (getParameters.requestDemo) {
        showRequestModal = true;
      }
    }

    this.state = {
      showRequestModal,
      showLogo: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.setState({ showLogo: true });
  }

  render() {
    const { localeToFormIdMap } = this.props;
    const { showRequestModal, showLogo } = this.state;

    const buttonStyle = this.props.isTopNav
      ? `btn-orange btn-high btn-full-round ${s.requestButtonTopNav}`
      : `btn-orange btn-high btn-large btn-full-round ${s.requestButton}`;

    return [
      <ClickTracker componentKey={componentKeys.REQUEST_DEMO_BUTTON}>
        <button
          key="request-demo-button"
          className={buttonStyle}
          onClick={() => {
            this.setState({ showRequestModal: true });
          }}
        >
          <FormattedMessage {...messages.requestDemoButton} />
        </button>
      </ClickTracker>,
      <HubspotAlert
        key="request-demo-modal"
        show={showRequestModal}
        onHide={() => {
          this.setState({ showRequestModal: false, showLogo: false });
        }}
        title={<FormattedMessage {...messages.requestDemoButton} />}
        postMsg={
          showLogo ? (
            <div className={requestWhitepaperStyles.downloadLinkContainer}>
              <img src="/fishy_blue_horizontal.png" alt="" />
            </div>
          ) : null
        }
        localeToFormIdMap={localeToFormIdMap}
        onSubmit={this.onSubmit}
      />,
    ];
  }
}

RequestDemoButton.defaultProps = {
  location: null,
};

export default withStyles(requestWhitepaperStyles, s)(RequestDemoButton);
