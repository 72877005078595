import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

// import GetApiKeyButton from 'components/ApiKey/GetApiKeyButton';

import buttonStyle from '../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from './FAQ.scss';
import messages from './messages';

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.renderQuestion = this.renderQuestion.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  renderQuestion(questionId, answerId) {
    return (
      <div>
        <div className={s.faqQuestion}>
          <FormattedHTMLMessage {...messages[questionId]} />
        </div>
        <div className={s.faqAnswer}>
          <FormattedHTMLMessage {...messages[answerId]} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={s.faqContainer}>
        <Row className={layoutStyle.noMargin}>
          <Col mdOffset={1} md={4}>
            <h1>
              <FormattedMessage {...messages.faqGlobalHeader} />
            </h1>
            <div className={s.questionFishy}>
              <img src="fishy_blue_horizontal.png" alt="" />
              <span>?</span>
            </div>
          </Col>
          <Col md={6}>
            <div className={s.faqsColumn}>
              <p>
                <h2>
                  <FormattedMessage {...messages.faqHeader2} />
                </h2>
                {this.renderQuestion('faqQuestion7', 'faqAnswer7')}
                {this.renderQuestion('faqQuestion8', 'faqAnswer8')}
                {this.renderQuestion('faqQuestion9', 'faqAnswer9')}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(layoutStyle, buttonStyle, s)(FAQ);
