import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-bootstrap';
import { injectIntl, intlShape } from 'react-intl';

import s from './LandingPage.scss';
import SearchMaskForm from '../SearchMask/SearchMaskForm';
import searchMastMessages from '../SearchMask/messages';
import ResultsList from '../ResultsList';

class LandingPage extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      skipPageLoadQuery: true,
    };

    this.onSearch = this.onSearch.bind(this);
    this.scrollToResults = this.scrollToResults.bind(this);

    this.resultListRef = React.createRef();
  }

  onSearch(formValues) {
    const filter = { ...formValues.filter };

    if ('displayZollPreference' in filter) {
      filter.displayZollPreference = filter.displayZollPreference.value;
    }

    this.setState({
      skipPageLoadQuery: false,
      filter,
    });
  }

  scrollToResults() {
    if (this.resultListRef.current) {
      window.scroll({
        top: this.resultListRef.current.offsetTop + 74,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  render() {
    const { intl } = this.props;

    console.log(this.state.skipPageLoadQuery); // eslint-disable-line no-console
    console.log(this.state.filter); // eslint-disable-line no-console

    return (
      <div className={s.landingPageContainer}>
        <Row>
          <Col mdOffset={2} md={8}>
            <SearchMaskForm
              onSubmit={this.onSearch}
              initialValues={{
                filter: {
                  priceImportance: 0.5,
                  memoryImportance: 0.5,
                  cameraImportance: 0.5,
                  batteryImportance: 0.5,
                  displayZollPreference: {
                    value: 'preferBig',
                    label: intl.formatMessage(searchMastMessages.preferBig),
                  },
                },
              }}
            />
            <div ref={this.resultListRef}>
              <ResultsList
                filter={this.state.filter}
                skipPageLoadQuery={this.state.skipPageLoadQuery}
                onLoadingFinished={this.scrollToResults}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(LandingPage));
