import React from 'react';
import PropTypes from 'prop-types';
import XLSX from 'xlsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';

import { DATA_PRESET_CUSTOM } from '../../../constants';

import NewAnalysisCard from './NewAnalysisCard';
import templates from './DataPreSettings/templates';
import {
  getColumnDataType,
  generateRulesForColumnType,
  isNumeric,
} from '../../ResultsTable/ColumnHelper';
import messages from './messages';
import { blobToObject, btoaSave } from '../../../../util';

class NewAnalysis extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    onNewAnalysisAdded: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.submitData = this.submitData.bind(this);
    this.saveData = this.saveData.bind(this);
    this.showError = this.showError.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.detectAndStoreDataTypes = this.detectAndStoreDataTypes.bind(this);

    this.state = {
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        onConfirm: null,
      },
    };
  }

  submitData(formValues) {
    const idColumn = parseInt(formValues.settings.uniqueIdColumn, 10) - 1;
    const initialValueRow =
      parseInt(formValues.settings.initialValueRow, 10) - 1;
    const file = formValues.import;
    if (file) {
      blobToObject(file.excel[0]) // first file
        .then(result => {
          // cut off base64 header
          const base64Data = result.data.split('base64,')[1];
          const xlsxData = XLSX.read(base64Data, { type: 'base64' });
          const firstSheetData = xlsxData.Sheets[xlsxData.SheetNames[0]];
          // header: 1 outputs sheet as 2D array
          const worksheet = XLSX.utils
            .sheet_to_json(firstSheetData, {
              header: 1,
              raw: true,
              defval: null,
            })
            .filter(row => !Object.values(row).every(item => item === null));

          if (idColumn > worksheet[0].length || idColumn < 0) {
            this.showError(messages.alertUniqueIdColumnNotFoundMessage);
            return;
          }

          if (initialValueRow > worksheet.length || initialValueRow < 1) {
            this.showError(messages.alertInitialValueRowNotFound);
            return;
          }
          const filteredColumns = [];
          // only take entries with valid header names
          worksheet[0] = worksheet[0].filter((o, i) => {
            // take column with ids, even if title is empty
            if (i === idColumn) return true;

            if (o === null || o === undefined) {
              filteredColumns.push(i);
              return false;
            }
            // filter if only contains whitespaces
            // return o.name.replace(/\s/g, '').length > 0;
            return true;
          });

          for (let column = 1; column < worksheet.length; column += 1) {
            // filter values for empty columns
            worksheet[column] = worksheet[column].filter(
              (item, i) => !filteredColumns.includes(i),
            );
          }
          // insert column information to header row
          worksheet[0] = worksheet[0].map(o => ({
            name: o || ' ',
            dataType: null,
            rules: [],
          }));

          this.detectAndStoreDataTypes(
            worksheet,
            idColumn,
            initialValueRow,
            formValues.settings,
            formValues.datasetName,
          );
        })
        .catch(() => {
          this.showError(messages.alertImporterErrorMessage);
        });
    }
  }

  async detectAndStoreDataTypes(
    worksheet,
    idColumn,
    initialValueRow,
    settings,
    datasetName,
  ) {
    const arrayColumn = (arr, n) => arr.map(x => x[n]);
    // used to check if column should be counted or skipped (no first entry)
    const firstColumn = arrayColumn(worksheet, 0);

    let globalMaximum = 0;
    worksheet[0].forEach((columnHeader, x) => {
      const column = arrayColumn(worksheet, x);
      let columnScore;
      const elements = [];
      column.forEach((element, y) => {
        // check if column has entry at first position
        if (firstColumn[y] || y === initialValueRow || y === idColumn) {
          // skip first row
          if (y !== 0) {
            if (y === initialValueRow) {
              columnScore = element;
            } else {
              elements.push(element);
              // get largest number
              if (isNumeric(element) && element > globalMaximum) {
                globalMaximum = element;
              }
            }
          }
        }
      });
      // eslint-disable-next-line no-param-reassign
      columnHeader.dataType = getColumnDataType(elements);
      // eslint-disable-next-line no-param-reassign
      columnHeader.columnScore = columnScore;
    });

    // filter initial values
    const filteredWorksheet = worksheet.filter((_, y) => y !== initialValueRow);
    await this.saveData(
      filteredWorksheet,
      idColumn,
      initialValueRow,
      settings,
      datasetName,
      globalMaximum,
    );
  }

  async saveData(
    worksheet,
    idColumn,
    initialValueRow,
    settings,
    datasetName,
    globalMaximum,
  ) {
    const columns = [];
    // initialize columns with their names
    worksheet[0].forEach(o => {
      if (o.name) {
        columns.push(o.name);
      }
    });

    const arrayColumn = (arr, n) => arr.map(x => x[n]);
    const firstColumn = arrayColumn(worksheet, 0);

    const data = [];
    const rules = [];
    const columnTypes = [];
    const columnScores = [];
    columns.forEach((column, x) => {
      // get datatype from header entry
      const columnType = worksheet[0][x].dataType;
      const columnScore = isNumeric(worksheet[0][x].columnScore)
        ? parseFloat(worksheet[0][x].columnScore)
        : 0;
      const rows = [];
      arrayColumn(worksheet, x).forEach((element, y) => {
        if (y > 0 && firstColumn[y]) {
          let entry = '';
          if (element !== null && element !== undefined) {
            entry = `${element}`;
          }
          rows.push(entry);
        }
      });

      data.push({
        name: column,
        rows,
        isUniqueIdColumn: x === idColumn,
        columnScore,
      });
      columnScores.push(columnScore);
      columnTypes.push(columnType);
      if (settings.globalMaximum) {
        rules.push(generateRulesForColumnType(columnType, rows, globalMaximum));
      } else {
        rules.push(generateRulesForColumnType(columnType, rows));
      }
    });

    const dataBlob = btoaSave(JSON.stringify(data));
    const rulesBlob = btoaSave(JSON.stringify(rules));
    const columnTypesBlob = btoaSave(JSON.stringify(columnTypes));
    const columnScoresBlob = btoaSave(JSON.stringify(columnScores));

    // Save data like columnScores and rules to reset to inital values for now
    // In the future a new CalibratedData entry can be made for each danubify and the data
    // from the first calibration can be taken for a reset
    const rawDataPatch = {
      name: datasetName,
      rawData: dataBlob,
      rules: rulesBlob,
      columnTypes: columnTypesBlob,
      uniqueIdColumn: idColumn,
      initialValueRow,
      initialSort: settings.initialSort,
    };

    // save raw data before applying rules
    const rawDataResult = await this.props.createOrUpdateRawData(
      null,
      rawDataPatch,
    );
    const datasetId = rawDataResult.data.createOrUpdateRawData.id;

    const columnSettings = data.map(column => ({
      name: column.name,
      skipped: false,
      fixed: false,
      treatEmptyAsZero: false,
    }));
    const columnSettingsBlob = btoaSave(JSON.stringify(columnSettings));
    // First calibrated data entry is just based on initial values
    const calibratedDataPatch = {
      rawDataId: datasetId,
      columnSettings: columnSettingsBlob,
      rules: rulesBlob,
      columnTypes: columnTypesBlob,
      columnScores: columnScoresBlob,
      strategy: settings.strategy,
      mixFactor: settings.mixFactor,
      impact: settings.impact,
    };
    const result = await this.props.createOrUpdateCalibratedData(
      null,
      calibratedDataPatch,
    );
    this.props.onNewAnalysisAdded(result.data.createOrUpdateCalibratedData);
    this.props.history.push({
      pathname: 'results',
      search: `?id=${datasetId}`,
    });
  }

  showError(message) {
    this.setState({
      alertOpts: {
        show: true,
        type: 'error',
        title: <FormattedMessage {...messages.alertErrorTitle} />,
        msg: <FormattedMessage {...message} />,
        showCancel: false,
        onConfirm: this.hideAlert,
        onCancel: null,
      },
    });
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        show: false,
        type: 'success',
        title: '',
        msg: '',
        showCancel: false,
        onConfirm: null,
        onCancel: null,
      },
    });
  }

  render() {
    return (
      <NewAnalysisCard
        onSubmit={this.submitData}
        initialValues={{
          templateName: DATA_PRESET_CUSTOM,
          settings: {
            ...templates[DATA_PRESET_CUSTOM].settings,
            uniqueIdColumn: 1,
            initialValueRow: 2,
            initialSort: true,
          },
        }}
      >
        {this.state.alertOpts.show && (
          <SweetAlert
            type={this.state.alertOpts.type}
            title={this.state.alertOpts.title}
            confirmBtnBsStyle={
              this.state.alertOpts.type === 'warning' ? 'danger' : 'primary'
            }
            cancelBtnBsStyle="default"
            showCancel={this.state.alertOpts.showCancel}
            onConfirm={this.state.alertOpts.onConfirm}
            onCancel={this.state.alertOpts.onCancel}
          >
            {this.state.alertOpts.msg}
          </SweetAlert>
        )}
      </NewAnalysisCard>
    );
  }
}

const createOrUpdateRawData = gql`
  mutation createOrUpdateRawData($id: String, $patch: RawDataInput!) {
    createOrUpdateRawData(id: $id, patch: $patch) {
      id
    }
  }
`;

const createOrUpdateCalibratedData = gql`
  mutation createOrUpdateCalibratedData(
    $id: String
    $patch: CalibratedDataInput!
  ) {
    createOrUpdateCalibratedData(id: $id, patch: $patch) {
      id
      rawDataId
      userId
      columnSettings
      rules
      columnTypes
      columnScores
      updatedAt
      strategy
      mixFactor
      impact
      rawData {
        id
        name
        rawData
        initialValueRow
        uniqueIdColumn
      }
    }
  }
`;

export default compose(
  graphql(createOrUpdateRawData, {
    props: ({ mutate }) => ({
      createOrUpdateRawData: (id, patch) =>
        mutate({
          variables: { id, patch },
        }),
    }),
  }),
  graphql(createOrUpdateCalibratedData, {
    props: ({ mutate }) => ({
      createOrUpdateCalibratedData: (id, patch) =>
        mutate({
          variables: { id, patch },
        }),
    }),
  }),
)(NewAnalysis);
