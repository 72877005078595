import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import { Row, Col, Panel } from 'react-bootstrap';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { SuccessAlert, Alert } from '../../Modal';

import messages from '../messages';
import s from './EditPassword.scss'; // eslint-disable-line css-modules/no-unused-class
import EditPasswordForm from './EditPasswordForm';

class EditPassword extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showSuccess: false,
      showError: false,
      expanded: false,
    };

    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  hideAlert() {
    this.setState({
      showSuccess: false,
      showError: false,
      expanded: false,
    });
  }

  async handleSubmit(formValues) {
    const { updatePassword } = this.props;

    const { password } = formValues;
    const user = {
      password,
    };
    let result;
    try {
      result = await updatePassword(user);
    } catch (e) {
      result = false;
    }

    this.setState({
      showError: !result || result.status === 401,
      showSuccess: result && result.status !== 401,
    });
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { user } = this.props;
    const { showSuccess, showError } = this.state;

    const caretImage = this.state.expanded
      ? '/caret_dark_expanded.png'
      : '/caret_dark.png';
    return (
      <Panel
        className={s.collapsiblePanel}
        expanded={this.state.expanded}
        onToggle={this.toggleExpanded}
      >
        <Panel.Heading className={s.panelHeading}>
          <div className={s.headingContent}>
            <span className={s.title}>
              <FormattedMessage {...messages.changePassword} />
            </span>
          </div>
          <button className={s.expandButton} onClick={this.toggleExpanded}>
            <img src={caretImage} alt="" />
          </button>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body className={s.panelBody}>
            <Row>
              <Col md={12}>
                <EditPasswordForm
                  onSubmit={this.handleSubmit}
                  initialValues={user}
                />
              </Col>
            </Row>
            <SuccessAlert
              show={showSuccess}
              title={<FormattedMessage {...messages.alertSuccessTitle} />}
              msg={<FormattedMessage {...messages.alertSuccessMessage} />}
              hide={this.hideAlert}
            />
            <Alert
              show={showError}
              title={<FormattedMessage {...messages.alertErrorTitle} />}
              msg={<FormattedMessage {...messages.alertErrorMessage} />}
              hide={this.hideAlert}
            />
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    );
  }
}

const updatePasswordMutation = gql`
  mutation updatePassword($user: UserInput!) {
    updateMe(user: $user) {
      id
    }
  }
`;

export default compose(
  withRouter,
  graphql(updatePasswordMutation, {
    props: ({ mutate }) => ({
      updatePassword: user =>
        mutate({
          variables: { user },
        }),
    }),
  }),
)(withStyles(s)(EditPassword));
