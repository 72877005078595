import React from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import TopNavigation from './TopNavigation';
import {
  navItemsLeft as defaultNavItemsLeft,
  navItemsLeftLoggedIn,
  navItemsRight as defaultNavItemsRight,
} from './navItems';
import messages from './messages';
// import loginMessages from '../Auth/login/messages';
import s from './TopNavigation.scss'; // eslint-disable-line css-modules/no-unused-class
import { withLogout } from '../Auth/withLogout';

class TopNavigationWrapper extends React.Component {
  static propTypes = {
    meData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }).isRequired,
    customLogoOnClick: PropTypes.func,
    navItemsLeft: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.any,
        link: PropTypes.string, // either link or subnavs
        subNavs: PropTypes.array, // either link or subnavs
      }),
    ),
    navItemsRight: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.any,
        link: PropTypes.string, // either link or subnavs
        subNavs: PropTypes.array, // either link or subnavs
      }),
    ),
  };

  static defaultProps = {
    customLogoOnClick: null,
    navItemsLeft: null,
    navItemsRight: null,
  };

  constructor(props) {
    super(props);

    this.goToLink = this.goToLink.bind(this);
    this.logout = this.logout.bind(this);
  }

  goToLink({ link }) {
    this.props.history.push(link);
  }

  logout() {
    this.props.logout();
  }

  render() {
    const {
      meData: { loading, me },
      customLogoOnClick,
      navItemsRight,
    } = this.props;

    const finalNavItemsRight = navItemsRight
      ? [...navItemsRight]
      : [...defaultNavItemsRight];

    if (!loading && me) {
      const userDropdown = {
        key: 'current-user',
        label: 'User',
        subNavs: [
          {
            key: 'account',
            label: <FormattedMessage {...messages.account} />,
            link: `my/account`,
          },
          {
            key: 'settings',
            label: <FormattedMessage {...messages.settings} />,
            link: `my/settings`,
          },
          {
            key: 'logout',
            customContent: (
              <button className="btn-link" onClick={this.logout}>
                <FormattedMessage {...messages.logout} />
              </button>
            ),
          },
        ],
      };
      finalNavItemsRight.push(userDropdown);

      return (
        <TopNavigation
          customLogoOnClick={customLogoOnClick}
          navItemsLeft={navItemsLeftLoggedIn || defaultNavItemsLeft}
          navItemsRight={finalNavItemsRight}
        />
      );
    }

    // const loginNavItem = {
    //   key: 'login',
    //   label: <FormattedMessage {...loginMessages.heading} />,
    //   link: 'login',
    // };

    // // FIXME: pages wrapped with access tracking somehow
    // // display two login buttons
    // if (
    //   finalNavItemsRight.findIndex(navItem => navItem.key === 'login') === -1
    // ) {
    //   finalNavItemsRight.push(loginNavItem);
    // }

    return (
      <TopNavigation
        homeUrl="/"
        customLogoOnClick={customLogoOnClick}
        navItemsRight={finalNavItemsRight}
      />
    );
  }
}

const meQuery = gql`
  query meData {
    me {
      id
      username
    }
  }
`;

export default compose(
  graphql(meQuery, {
    name: 'meData',
    options: {
      fetchPolicy: 'no-cache',
    },
  }),
  withRouter,
  withLogout,
  withStyles(s),
)(TopNavigationWrapper);
