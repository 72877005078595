import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import {
  Field,
  reduxForm,
  getFormSyncErrors,
  getFormMeta,
  formValueSelector,
  change,
  untouch,
} from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './ContactForm.scss';
import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import selectStyle from '../ReduxForm/Select.scss'; // eslint-disable-line css-modules/no-unused-class
import render from '../ReduxForm/renderField';
import validations from '../ReduxForm/validations';
import * as normalizers from '../ReduxForm/normalizers';
import validationMessages from '../ReduxForm/messages';
import messages from './messages';
import countryList from './countryList';

/*
const subjects = [
  'integration',
  'dataanalysis',
  'trainingcourse',
  'generalinquiry',
];
*/

export const inquiryFields = [
  'consumerRecommendations',
  'media',
  'eCommerce',
  'recruiting',
  'elearning',
  'school',
  'training',
  'otherfield',
];

const validate = values => {
  const errors = {};
  if (!validations.required(values.firstName)) {
    errors.firstName = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.lastName)) {
    errors.lastName = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.companyName)) {
    errors.companyName = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.jobTitle)) {
    errors.jobTitle = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.country)) {
    errors.country = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.email)) {
    errors.email = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.email(values.email)) {
    errors.email = <FormattedMessage {...validationMessages.email} />;
  }
  if (
    !validations.required(values.apiOrSdk) &&
    !validations.required(values.integration) &&
    !validations.required(values.generalInquiry)
  ) {
    errors.generalInquiry = (
      <FormattedMessage {...validationMessages.oneRequired} />
    );
  }
  if (
    !validations.required(values.recruiting) &&
    !validations.required(values.eLearning) &&
    !validations.required(values.media) &&
    !validations.required(values.school) &&
    !validations.required(values.eCommerce) &&
    !validations.required(values.training) &&
    !validations.required(values.consumerRecommendations) &&
    !validations.required(values.otherField)
  ) {
    errors.otherField = (
      <FormattedMessage {...validationMessages.oneRequired} />
    );
  }
  return errors;
};

class ContactForm extends React.Component {
  static propTypes = {
    emailAddress: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
    selectedInquiry: PropTypes.shape({
      value: PropTypes.string,
    }),
    resetInquiryFields: PropTypes.func.isRequired,
  };

  static defaultProps = {
    errors: [],
    selectedInquiry: null,
  };

  constructor(props) {
    super(props);

    this.renderInquiryQuestions = this.renderInquiryQuestions.bind(this);
    this.renderMediaQuestions = this.renderMediaQuestions.bind(this);
    this.renderRecruitingQuestions = this.renderRecruitingQuestions.bind(this);
    this.renderECommerceQuestions = this.renderECommerceQuestions.bind(this);
    this.renderOtherQuestions = this.renderOtherQuestions.bind(this);
    this.renderLeftTopForm = this.renderLeftTopForm.bind(this);
    this.renderDidntFindTile = this.renderDidntFindTile.bind(this);
    this.renderRightForm = this.renderRightForm.bind(this);
  }

  renderInquiryQuestions(questions) {
    const { intl } = this.props;

    return questions.map((question, index) => (
      <div className={selectStyle.selectAltContainer}>
        <h4>
          <FormattedMessage {...question.question} />
        </h4>
        <div>
          <Field
            id={`answer${index + 1}`}
            name={`answer${index + 1}`}
            component={render.renderSelectAlt}
            isMulti={false}
            options={question.answers.map(answer => ({
              value: answer.defaultMessage,
              label: intl.formatMessage(answer),
            }))}
          />
        </div>
        <div>
          <div className={selectStyle.spacerLine} />
        </div>
      </div>
    ));
  }

  renderMediaQuestions() {
    return this.renderInquiryQuestions([
      {
        question: messages.mediaQ1,
        answers: [messages.mediaQ1A1, messages.mediaQ1A2, messages.mediaQ1A3, messages.mediaQ1A4], // eslint-disable-line prettier/prettier
      },
      {
        question: messages.mediaQ2,
        answers: [messages.mediaQ2A1, messages.mediaQ2A2, messages.mediaQ2A3], // eslint-disable-line prettier/prettier
      },
      {
        question: messages.mediaQ3,
        answers: [messages.mediaQ3A1, messages.mediaQ3A2, messages.mediaQ3A3, messages.mediaQ3A3], // eslint-disable-line prettier/prettier
      },
    ]);
  }

  renderECommerceQuestions() {
    return this.renderInquiryQuestions([
      {
        question: messages.eCommerceQ1,
        answers: [messages.eCommerceQ1A1, messages.eCommerceQ1A2, messages.eCommerceQ1A3], // eslint-disable-line prettier/prettier
      },
      {
        question: messages.eCommerceQ2,
        answers: [messages.eCommerceQ2A1, messages.eCommerceQ2A2, messages.eCommerceQ2A3, messages.eCommerceQ2A4], // eslint-disable-line prettier/prettier
      },
      {
        question: messages.eCommerceQ3,
        answers: [messages.eCommerceQ3A1, messages.eCommerceQ3A2, messages.eCommerceQ3A3], // eslint-disable-line prettier/prettier
      },
    ]);
  }

  renderRecruitingQuestions() {
    return this.renderInquiryQuestions([
      {
        question: messages.recruitingQ1,
        answers: [messages.recruitingQ1A1, messages.recruitingQ1A2, messages.recruitingQ1A3], // eslint-disable-line prettier/prettier
      },
      {
        question: messages.recruitingQ2,
        answers: [messages.recruitingQ2A1, messages.recruitingQ2A2, messages.recruitingQ2A3], // eslint-disable-line prettier/prettier
      },
      {
        question: messages.recruitingQ3,
        answers: [messages.recruitingQ3A1, messages.recruitingQ3A2, messages.recruitingQ3A3], // eslint-disable-line prettier/prettier
      },
    ]);
  }

  renderOtherQuestions() {
    return this.renderInquiryQuestions([
      {
        question: messages.otherFieldQ1,
        answers: [messages.otherFieldQ1A1, messages.otherFieldQ1A2, messages.otherFieldQ1A3, messages.otherFieldQ1A4, messages.otherFieldQ1A5], // eslint-disable-line prettier/prettier
      },
    ]);
  }

  // eslint-disable-next-line class-methods-use-this
  renderLeftTopForm() {
    const { intl, selectedInquiry, resetInquiryFields } = this.props;

    let inquiryQuestions = [];

    if (selectedInquiry && selectedInquiry.value === 'media') {
      inquiryQuestions = this.renderMediaQuestions();
    } else if (selectedInquiry && selectedInquiry.value === 'eCommerce') {
      inquiryQuestions = this.renderECommerceQuestions();
    } else if (selectedInquiry && selectedInquiry.value === 'recruiting') {
      inquiryQuestions = this.renderRecruitingQuestions();
    } else if (selectedInquiry && selectedInquiry.value === 'otherfield') {
      inquiryQuestions = this.renderOtherQuestions();
    }

    return (
      <fieldset>
        <div className={selectStyle.selectAltContainer}>
          <h4>
            <FormattedMessage {...messages.inquiry} />
          </h4>
          <div>
            <Field
              id="inquiry"
              name="inquiry"
              component={render.renderSelectAlt}
              isMulti={false}
              options={[
                /* eslint-disable prettier/prettier */
                { value: 'media', label: intl.formatMessage(messages.media) },
                { value: 'recruiting', label: intl.formatMessage(messages.recruiting) },
                { value: 'eCommerce', label: intl.formatMessage(messages.eCommerce) },
                { value: 'otherfield', label: intl.formatMessage(messages.otherfield) },
                /* eslint-enable prettier/prettier */
              ]}
              onChange={resetInquiryFields}
            />
          </div>
          <div>
            <div className={selectStyle.spacerLine} />
          </div>
        </div>
        {inquiryQuestions}
      </fieldset>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderDidntFindTile() {
    const { emailAddress } = this.props;

    return (
      <div className={s.didntFindTile}>
        <span>
          <FormattedMessage {...messages.info2} />
        </span>
        <div>
          <a href={`mailto:${emailAddress}?subject=danube.ai%20contact`}>
            <button
              type="button"
              className={`btn-secondary btn-round btn-large ${s.button}`}
            >
              <FormattedMessage {...messages.emailButton} />
            </button>
          </a>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderRightForm(inquiryfieldErr) {
    const { errors, intl } = this.props;

    return (
      <div>
        <h4>
          <FormattedMessage {...messages.info} />
        </h4>
        <span className={s.required}>
          <FormattedMessage {...messages.required} />
        </span>
        <fieldset>
          <Field
            id="firstName"
            name="firstName"
            type="text"
            label={intl.formatMessage(messages.firstname)}
            component={render.renderInput}
            horizontal
            required
          />
          <Field
            id="lastName"
            name="lastName"
            type="text"
            label={intl.formatMessage(messages.lastname)}
            component={render.renderInput}
            horizontal
            required
          />
          <Field
            id="companyName"
            name="companyName"
            type="text"
            label={intl.formatMessage(messages.companyname)}
            component={render.renderInput}
            horizontal
            required
          />
          <Field
            id="jobTitle"
            name="jobTitle"
            type="text"
            label={intl.formatMessage(messages.jobtitle)}
            component={render.renderInput}
            horizontal
            required
          />
          <Field
            id="email"
            name="email"
            type="email"
            label={intl.formatMessage(messages.email)}
            component={render.renderInput}
            horizontal
            required
          />
          {/* TODO: VALIDATE phone number */}
          <Field
            id="phone"
            name="phone"
            type="text"
            label={intl.formatMessage(messages.phone)}
            component={render.renderInput}
            horizontal
          />
          <Field
            id="country"
            name="country"
            type="select"
            label={intl.formatMessage(messages.country)}
            component={render.renderSelect}
            optionsKey="id"
            optionsValue="name"
            horizontal
            required
          >
            {countryList.map(country => (
              <option value={country}>{country}</option>
            ))}
          </Field>
        </fieldset>
        <fieldset>
          {inquiryfieldErr && (
            <p className="validationError">
              <FormattedMessage {...validationMessages.oneRequired} />
            </p>
          )}
          <Field
            id="comment"
            name="comment"
            label={<FormattedMessage {...messages.comments} />}
            type="text"
            component={render.renderTextarea}
            normalize={normalizers.booleanNormalizer}
            horizontal
          />
          {errors.length === 0 ? null : (
            <ul style={{ padding: '0', listStyleType: 'none' }}>
              {errors.map(err => (
                <li key={err} className="bg-danger">
                  {err}
                </li>
              ))}
            </ul>
          )}
          <button
            type="submit"
            className="btn-secondary btn-round btn-large btn-wide"
          >
            <FormattedMessage {...messages.submit} />
          </button>
        </fieldset>
      </div>
    );
  }

  render() {
    const { handleSubmit, formErrors, formMeta } = this.props;
    let inquiryfieldErr = false;

    if (formErrors.otherField) {
      inquiryFields.forEach(val => {
        if (formMeta[val] && formMeta[val].touched) {
          inquiryfieldErr = true;
        }
      });
    }

    return (
      <form
        className={`default-form form-horizontal ${s.registrationForm}`}
        onSubmit={handleSubmit}
      >
        <div className={s.left}>
          <div className={s.leftTop}>{this.renderLeftTopForm()}</div>
          <div className={s.leftBottom}>{this.renderDidntFindTile()}</div>
        </div>
        <div className={s.right}>{this.renderRightForm(inquiryfieldErr)}</div>
        <div className={s.showOnMobileOnly}>{this.renderDidntFindTile()}</div>
      </form>
    );
  }
}

const selector = formValueSelector('contact');

const mapStateToProps = state => ({
  selectedInquiry: selector(state, 'inquiry'),
  formErrors: getFormSyncErrors('contact')(state),
  formMeta: getFormMeta('contact')(state),
});

const mapDispatchToProps = dispatch => ({
  resetInquiryFields: () => {
    dispatch(change('contact', 'answer1', null));
    dispatch(untouch('contact', 'answer1'));
    dispatch(change('contact', 'answer2', null));
    dispatch(untouch('contact', 'answer2'));
    dispatch(change('contact', 'answer3', null));
    dispatch(untouch('contact', 'answer3'));
  },
});

export default reduxForm({
  form: 'contact',
  validate,
  touchOnChange: true,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    injectIntl(
      withStyles(layoutStyle, buttonStyle, selectStyle, s)(ContactForm),
    ),
  ),
);
