import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';

import s from './showcase.scss'; //eslint-disable-line

class ShowcaseTemplate extends React.Component {
  static propTypes = {
    pages: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  render() {
    return (
      <div className={s.showcaseTemplate}>
        {this.props.pages.map((page, i) => (
          <div
            id={`page_${i + 1}`}
            key={`page_${i + 1}`}
            className={s.showcasePage}
          >
            {page}
          </div>
        ))}
      </div>
    );
  }
}

export default withStyles(s)(ShowcaseTemplate);
