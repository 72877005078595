import { defineMessages } from 'react-intl';

export const defaultTitle = 'danube.ai - little data beats Big Data';
export const defaultDescription = `
  danube.ai is the AI prediction engine anyone can use.
  In a time of big data, danube.ai let's you understand the little data that count.
`;
export const defaultKeywords =
  'AI, KI, Prediction, Sports, HR, Recruiting, Testing, Fair, Football, Tennis';

const messages = defineMessages({
  defaultPageTitle: {
    id: 'routes.defaultPage.title',
    defaultMessage: defaultTitle,
    description: 'Default title for pages',
  },
  defaultPageDescription: {
    id: 'routes.defaultPage.description',
    defaultMessage: defaultDescription,
    description: 'Default description for pages',
  },
  defaultPageKeywords: {
    id: 'routes.defaultPage.keywords',
    defaultMessage: defaultKeywords,
    description: 'Default keywords for pages',
  },
  landingPageTitle: {
    id: 'routes.landingPage.title',
    defaultMessage: defaultTitle,
    description: 'Title for landing page',
  },
  landingPageDescription: {
    id: 'routes.landingPage.description',
    defaultMessage: defaultDescription,
    description: 'Description for landing page',
  },
  landingPageKeywords: {
    id: 'routes.landingPage.keywords',
    defaultMessage: defaultKeywords,
    description: 'Keywords for landing page',
  },
  loginTitle: {
    id: 'routes.login.title',
    defaultMessage: 'Login',
    description: 'Title for login page',
  },
  loginDescription: {
    id: 'routes.login.description',
    defaultMessage: `
      Login to danube.ai, the AI prediction engine anyone can use.
    `,
    description: 'Description for login page',
  },
  loginKeywords: {
    id: 'routes.login.keywords',
    defaultMessage: defaultKeywords,
    description: 'Keywords for login page',
  },
  registrationTitle: {
    id: 'routes.registration.title',
    defaultMessage: 'Registration',
    description: 'Title for registration page',
  },
  registrationDescription: {
    id: 'routes.registration.description',
    defaultMessage: `
      Sign up for danube.ai
    `,
    description: 'Description for registration page',
  },
  registrationKeywords: {
    id: 'routes.registration.keywords',
    defaultMessage: defaultKeywords,
    description: 'Keywords for registration page',
  },
  useCasesTitle: {
    id: 'routes.useCases.title',
    defaultMessage: 'Use Cases',
    description: 'Title for use cases page',
  },
  useCasesDescription: {
    id: 'routes.useCases.description',
    defaultMessage: `
      danube.ai is an AI engine that lets you predict
      sports results, ranks your recruiting candidates,
      measures your trainee's performance, and
      much more.
    `,
    description: 'Description for use cases page',
  },
  useCasesKeywords: {
    id: 'routes.useCases.keywords',
    defaultMessage: defaultKeywords,
    description: 'Keywords for use cases page',
  },
  technologyTitle: {
    id: 'routes.technology.title',
    defaultMessage: 'Technology',
    description: 'Title for technology page',
  },
  technologyDescription: {
    id: 'routes.technology.description',
    defaultMessage: `Expanding the ai tech stack`,
    description: 'Description for technology page',
  },
  technologyKeywords: {
    id: 'routes.technology.keywords',
    defaultMessage:
      'AI, KI, Prediction, Fintech, Sports, Fair, Football, Tennis',
    description: 'Keywords for technology page',
  },
  integrationsTitle: {
    id: 'routes.integrations.title',
    defaultMessage: 'Integrations',
    description: 'Title for integrations page',
  },
  integrationsDescription: {
    id: 'routes.integrations.description',
    defaultMessage: `
      danube.ai can be easily integrated into your existing
      recruiting or eLearning solution for
      data analysis improvement.
    `,
    description: 'Description for integrations page',
  },
  integrationsKeywords: {
    id: 'routes.integrations.keywords',
    defaultMessage:
      'AI, KI, HR, Recruiting, Testing, e-learning, eLearning, Data Analysis',
    description: 'Keywords for integrations page',
  },
  contactSalesTitle: {
    id: 'routes.contactSales.title',
    defaultMessage: 'Contact',
    description: 'Title for contact page',
  },
  contactSalesDescription: {
    id: 'routes.contactSales.description',
    defaultMessage: 'Get in touch for your personal solution',
    description: 'Description for contact page',
  },
  aboutUsTitle: {
    id: 'routes.aboutUs.title',
    defaultMessage: 'About Us',
    description: 'Title for about us page',
  },
  aboutUsDescription: {
    id: 'routes.aboutUs.description',
    defaultMessage: 'Get to know the team behind danube.ai',
    description: 'Description for about us page',
  },
  pressTitle: {
    id: 'routes.press.title',
    defaultMessage: 'Press',
    description: 'Title for press page',
  },
  pressDescription: {
    id: 'routes.press.description',
    defaultMessage: 'Background information on danube.ai',
    description: 'Description for press page',
  },
  privacyTitle: {
    id: 'routes.privacy.title',
    defaultMessage: 'Privacy',
    description: 'Title for privacy page',
  },
  privacyDescription: {
    id: 'routes.privacy.description',
    defaultMessage: 'Information on data security and privacy',
    description: 'Description for privacy page',
  },
  imprintTitle: {
    id: 'routes.imprint.title',
    defaultMessage: 'Imprint',
    description: 'Title for imprint page',
  },
  imprintDescription: {
    id: 'routes.imprint.description',
    defaultMessage: 'Our company information',
    description: 'Description for imprint page',
  },
  sportsDataTitle: {
    id: 'routes.sportsData.title',
    defaultMessage: 'Sports Data',
    description: 'Title for sports data page',
  },
  sportsDataDescription: {
    id: 'routes.sportsData.description',
    defaultMessage: `
      Get exclusively prepared real sports data
      to be used with danube.ai and start predicting
      sports results!
    `,
    description: 'Description for sports data page',
  },
  sportsDataKeywords: {
    id: 'routes.sportsData.keywords',
    defaultMessage: 'Sports, Prediction, AI, KI, Football, Tennis, Data, Sheet',
    description: 'Keywords for sports data page',
  },
  partnerApaitTitle: {
    id: 'routes.partner.apait.title',
    defaultMessage: 'Partner - APA-IT',
    description: 'Title for APA-IT partner page',
  },
  partnerApaitDescription: {
    id: 'routes.partner.apait.description',
    defaultMessage: 'Learn more about our technology partner',
    description: 'Description for APA-IT partner page',
  },
  partnerDevJobsTitle: {
    id: 'routes.partner.devjobs.title',
    defaultMessage: 'Partner - devjobs.at',
    description: 'Title for devjobs.at partner page',
  },
  partnerDevJobsDescription: {
    id: 'routes.partner.devjobs.description',
    defaultMessage: 'Learn more about our technology partner',
    description: 'Description for devjobs.at partner page',
  },
  partnerEnliteAITitle: {
    id: 'routes.partner.enliteai.title',
    defaultMessage: 'Partner - enliteAI',
    description: 'Title for enliteAI partner page',
  },
  partnerEnliteAIDescription: {
    id: 'routes.partner.enliteai.description',
    defaultMessage: 'Learn more about our technology partner',
    description: 'Description for enliteAI partner page',
  },
  apiWikiTitle: {
    id: 'routes.apiWiki.title',
    defaultMessage: 'danube.ai API',
    description: 'Title for api wiki page',
  },
  apiWikiDescription: {
    id: 'routes.apiWiki.description',
    defaultMessage: 'Learn more about the danube.ai API and SDK',
    description: 'Description for api wiki page',
  },
  apiWikiPredictionTitle: {
    id: 'routes.apiWiki.prediction.title',
    defaultMessage: 'danube.ai API - Prediction',
    description: 'Title for api wiki page',
  },
  apiWikiRecommendationTitle: {
    id: 'routes.apiWiki.recommendation.title',
    defaultMessage: 'danube.ai API - Recommendation',
    description: 'Title for api wiki page',
  },
  apiWikiJsonTitle: {
    id: 'routes.apiWiki.json.title',
    defaultMessage: 'danube.ai API - Json Example',
    description: 'Title for api wiki page',
  },
  pricingTitle: {
    id: 'routes.pricing.title',
    defaultMessage: 'Pricing',
    description: 'Title for pricing page',
  },
  pricingDescription: {
    id: 'routes.pricing.description',
    defaultMessage: 'Get to know the available pricing models',
    description: 'Description for pricing page',
  },
  sdkTitle: {
    id: 'routes.sdk.title',
    defaultMessage: 'danube.ai SDK',
    description: 'Title for sdk page',
  },
  sdkInitializationTitle: {
    id: 'routes.sdk.initialization.title',
    defaultMessage: 'danube.ai SDK - Initialization',
    description: 'Title for sdk page',
  },
  sdkPredictionTitle: {
    id: 'routes.sdk.prediction.title',
    defaultMessage: 'danube.ai SDK - Prediction',
    description: 'Title for sdk page',
  },
  sdkRecommendationTitle: {
    id: 'routes.sdk.recommendation.title',
    defaultMessage: 'danube.ai SDK - Recommendation',
    description: 'Title for sdk page',
  },
  faqTitle: {
    id: 'routes.faq.title',
    defaultMessage: 'FAQ',
    description: 'Title for faq page',
  },
  faqDescription: {
    id: 'routes.faq.description',
    defaultMessage: `Frequently asked questions`,
    description: 'Description for faq page',
  },
  demoTitle: {
    id: 'routes.demo.title',
    defaultMessage: 'Demo',
    description: 'Title for Demo page',
  },
  showcaseGeizhalsTitle: {
    id: 'routes.showcaseGeizhals.title',
    defaultMessage: 'Showcase Geizhals',
    description: 'Title for Geizhals Showcase page',
  },
  showcaseDevjobsTitle: {
    id: 'routes.showcasedevjobs.title',
    defaultMessage: 'Showcase devjobs.at',
    description: 'Title for devjobs.at Showcase page',
  },
  cherryTreeTitle: {
    id: 'routes.cherrytree.title',
    defaultMessage: 'Cherry Tree',
    description: 'Title for cherry tree page',
  },
  cherryTreeDescription: {
    id: 'routes.cherrytree.description',
    defaultMessage: `Cherry Tree - Taking the lead in AI technology`,
    description: 'Description for cherry tree page',
  },
  cherryTreeKeywords: {
    id: 'routes.cherrytree.keywords',
    defaultMessage:
      'AI, KI, genetic, evolution, data-less, decentral, transparent, recommendation',
    description: 'Keywords for cherry tree page',
  },
});

export default messages;
