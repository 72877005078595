import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import buttonStyle from '../../../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import layoutStyle from '../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from '../../../../components/FAQ/FAQ.scss'; // eslint-disable-line css-modules/no-unused-class
import globalDemoMessages from '../../../globalDemoMessages';
import messages from './messages';

class LearnMorePage extends React.Component {
  constructor(props) {
    super(props);
    this.renderParagraph = this.renderParagraph.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  renderParagraph(headerMessage, textMessage) {
    return (
      <div>
        {headerMessage && (
          <div className={s.faqQuestion}>
            <FormattedHTMLMessage {...headerMessage} />
          </div>
        )}
        <div className={s.faqAnswer}>
          <FormattedHTMLMessage {...textMessage} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={s.faqContainer}>
        <Row className={layoutStyle.noMargin}>
          <Col mdOffset={1} md={4}>
            <h1>Recruiting Demo</h1>
            <FormattedHTMLMessage {...messages.description} />
            <br />
            <br />
            <Link to="/demos/recruiting">
              <button className="btn-secondary btn-round">
                <FormattedMessage {...globalDemoMessages.tryIt} />
              </button>
            </Link>
          </Col>
          <Col md={6}>
            <div className={s.faqsColumn} style={{ paddingTop: '30px' }}>
              <p>
                {this.renderParagraph(
                  globalDemoMessages.learnMoreHeader1,
                  messages.text1,
                )}
                {this.renderParagraph(null, messages.text2)}
                {this.renderParagraph(
                  globalDemoMessages.learnMoreHeader2,
                  messages.text3,
                )}
                {this.renderParagraph(
                  globalDemoMessages.learnMoreHeader3,
                  messages.text4,
                )}
                {this.renderParagraph(null, messages.text5)}
                {this.renderParagraph(
                  globalDemoMessages.learnMoreHeader4,
                  messages.text6,
                )}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(layoutStyle, buttonStyle, s)(LearnMorePage);
