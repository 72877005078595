import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet-async';
import { injectIntl, intlShape } from 'react-intl';

import pageMessages from 'routes/messages';

class PageProperties extends React.Component {
  static propTypes = {
    page: PropTypes.shape({
      title: PropTypes.object,
      description: PropTypes.object,
      keywords: PropTypes.object,
    }),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    page: {
      title: pageMessages.defaultPageTitle,
      description: pageMessages.defaultPageDescription,
      keywords: pageMessages.defaultPageKeywords,
    },
  };

  render() {
    const { page, intl } = this.props;

    return (
      <Helmet encodeSpecialCharacters={false}>
        <title>
          {intl.formatMessage(page.title || pageMessages.defaultPageTitle)}
        </title>
        <meta
          name="description"
          content={intl.formatMessage(
            page.description || pageMessages.defaultPageDescription,
          )}
        />
        <meta
          name="keywords"
          content={intl.formatMessage(
            page.keywords || pageMessages.defaultPageKeywords,
          )}
        />
      </Helmet>
    );
  }
}

export default injectIntl(PageProperties);
