import { defineMessages } from 'react-intl';

const messages = defineMessages({
  sdkHeader: {
    id: 'sdk.sdkHeader',
    defaultMessage: 'danube.ai SDK',
    description: 'header for sdk page',
  },
  about: {
    id: 'sdk.sdkAbout',
    defaultMessage: 'About',
    description: 'sub header for sdk page',
  },
  sdkDescription: {
    id: 'sdk.sdkDescription',
    defaultMessage: `
      This SDK provides interfaces for calling the services of danube.ai.
    `,
    description: 'description for sdk page',
  },
  sdkSubscription: {
    id: 'sdk.sdkSubscription',
    defaultMessage: 'Subscription',
    description: 'sub header for sdk page',
  },
  sdkSubscriptionDescription: {
    id: 'sdk.sdkSubscriptionDescription',
    defaultMessage: `
      In order to use the SDK, you need an API key. You can contact us to request an API key.
    `,
    description: 'sub header for sdk page',
  },
  sdkInstallation: {
    id: 'sdk.sdkInstallation',
    defaultMessage: 'Installation',
    description: 'sub header for sdk page',
  },
  sdkInstallationDescription1: {
    id: 'sdk.sdkInstallationDescription1',
    defaultMessage: `
      You can add the SDK as a package to your project via npm or yarn:
    `,
    description: 'text for sdk page',
  },
  sdkInitializeClientText1: {
    id: 'sdk.sdkInitializeClientText1',
    defaultMessage: `
      The package exports a <i>DanubeClient</i> class. When instantiating it, you will have to provide your
      danube.ai API key. After that you are ready to go.
    `,
    description: 'text for sdk page',
  },
  sdkInitializeClientText2: {
    id: 'sdk.sdkInitializeClientText2',
    defaultMessage: `
      Optionally you can also specify an API url (location where the API is running) as a second parameter.
      This will default to <i>https://api.danube.ai/</i>.
    `,
    description: 'text for sdk page',
  },

  danubePredictionOverviewText2: {
    id: 'sdk.danubePredictionOverviewText2',
    defaultMessage: `
      First you call the {setRules} method of your {danubeClient} instance to save your rules-set. Avoid repetitive calls.
    `,
    description: 'text for api page',
  },
  danubePredictionOverviewText3: {
    id: 'sdk.danubePredictionOverviewText3',
    defaultMessage: `
      After that you can call the {danubePrediction} method of your {danubeClient} instance to let danube.ai sort your data.
    `,
    description: 'text for api page',
  },
  danubePredictionOverviewText4: {
    id: 'sdk.danubePredictionOverviewText4',
    defaultMessage: `
      You can call the {getRules} method of your {danubeClient} instance to get a list of all your created rules-sets.
    `,
    description: 'text for api page',
  },
  danubePredictionOverviewDanubeClient: {
    id: 'sdk.danubePredictionOverviewDanubeClient',
    defaultMessage: 'DanubeClient',
    description: 'text for api page',
  },

  sdkDanubePredictionText1: {
    id: 'sdk.sdkDanubePredictionText1',
    defaultMessage: `
      A rules-set can be specified via the <i>setRules</i> method provided by the <i>DanubeClient</i> class.
      <i>setRules</i> will save your rules-set on our side so you just have to provide its id on any following
      calls of the danube.ai predicition service. This needs to be done only once or when your rules have changed.
      Avoid repetitive calls.
    `,
    description: 'text for sdk page',
  },
  sdkDanubePredictionText2: {
    id: 'sdk.sdkDanubePredictionText2',
    defaultMessage: `
      Below, the method's header is shown, as it is provided by <i>DanubeClient</i>.
    `,
    description: 'text for sdk page',
  },
  sdkDanubePredictionText2dot5: {
    id: 'sdk.sdkDanubePredictionText2.5',
    defaultMessage: `
      Objects in the format of the following rules are valid:
    `,
    description: 'text for sdk page',
  },
  sdkDanubePredictionText3: {
    id: 'sdk.sdkDanubePredictionText3',
    defaultMessage: `
      Once a rules-set is defined you can start using the danube.ai prediction service which is provided via the
      <i>danubePrediction</i> method by the <i>DanubeClient</i> class. <i>danubePrediction</i> will send a request with
      your dataset to danube.ai and returns the evaluation results including a new scoring for columns and rows and a
      list of derived percentage matches per row.
    `,
    description: 'text for sdk page',
  },
  sdkDanubePredictionText4: {
    id: 'sdk.sdkDanubePredictionText4',
    defaultMessage: `
      Below, the method's header is shown, as it is provided by <i>DanubeClient</i>.
    `,
    description: 'text for sdk page',
  },
  sdkDanubePredictionText4_1: {
    id: 'sdk.sdkDanubePredictionText4_1',
    defaultMessage: `
      You can see an example on how to encode your data as a json array <a href="/api/formats/prediction-data" target="_blank" rel="noopener noreferrer">here</a>.
    `,
    description: 'text for sdk page',
  },
  sdkDanubePredictionText5: {
    id: 'sdk.sdkDanubePredictionText5',
    defaultMessage: `
      Visit <a href="https://gitlab.com/danube.ai/sdk" target="_blank" rel="noopener noreferrer">https://gitlab.com/danube.ai/sdk</a> to download and try an example for this endpoint.
    `,
    description: 'text for sdk page',
  },
  sdkDanubePredictionText6: {
    id: 'api.sdkDanubePredictionText6',
    defaultMessage: `
      You can get a list of all your previously created rules-sets via the <i>getRules</i> method provided by the <i>DanubeClient</i> class.
    `,
    description: 'text for api page',
  },
  sdkDanubePredictionText7: {
    id: 'api.sdkDanubePredictionText7',
    defaultMessage: `
      A <i>RULES_SET</i> contains the <i>id</i> it was saved with and a list of <i>rules</i>. See <a href="/api/sdk/documentation/prediction/set-rules" target="_blank">setRules</a> for possible <i>RULE</i> types.
    `,
    description: 'text for api page',
  },

  danubeRecommendationOverviewText2: {
    id: 'sdk.danubeRecommendationOverviewText2',
    defaultMessage: `
      All you have to do is to call the {danubeRecommendation} method of your {danubeClient} instance with your data. With each request, the correlation matrix
      of your data history is refined and better recommendations are returned.
    `,
    description: 'text for api page',
  },
  danubeRecommendationOverviewText3: {
    id: 'sdk.danubeRecommendationOverviewText3',
    defaultMessage: `
      You can call the {resetCorrelationMatrix} method of your {danubeClient} instance to reset the current correlation matrix of your API key.
    `,
    description: 'text for api page',
  },

  sdkDanubeRecommendationText1: {
    id: 'sdk.sdkDanubeRecommendationText1',
    defaultMessage: `
      The danube.ai recommendation service can be used via the <i>danubeRecommendation</i> method provided by the
      <i>DanubeClient</i> class. <i>danubeRecommendation</i> will send a request with your current session data to
      danube.ai and returns the <i>n</i> mostly correlated entries from previous sessions as a result.
    `,
    description: 'text for sdk page',
  },
  sdkDanubeRecommendationText2: {
    id: 'sdk.sdkDanubeRecommendationText2',
    defaultMessage: `
      Below, the method's header is shown, as it is provided by <i>DanubeClient</i>.
    `,
    description: 'text for sdk page',
  },
  danubeRecommendationText3: {
    id: 'sdk.danubeRecommendationText3',
    defaultMessage: `
      You can reset the correlation information between all your data elements which has been created from all previous <i>danubeRecommendation</i> method calls
      by using the <i>resetCorrelationMatrix</i> method provided by the <i>DanubeClient</i> class.
    `,
    description: 'text for api page',
  },

  example: {
    id: 'sdk.example',
    defaultMessage: 'Example',
    description: 'sub header for sdk page',
  },
  method: {
    id: 'sdk.method',
    defaultMessage: 'Method',
    description: 'text for sdk page',
  },
  setRulesMethod: {
    id: 'sdk.setRulesMethod',
    defaultMessage: 'setRules method',
    description: 'text for sdk page',
  },
  danubePredictionMethod: {
    id: 'sdk.danubePredictionMethod',
    defaultMessage: 'danubePrediction method',
    description: 'text for sdk page',
  },
  danubeRecommendationMethod: {
    id: 'sdk.danubeRecommendationMethod',
    defaultMessage: 'danubeRecommendation method',
    description: 'text for sdk page',
  },
  sdkExamples: {
    id: 'sdk.sdkExamples',
    defaultMessage: 'Examples',
    description: 'sub header for sdk page',
  },

  sdkExamplesText: {
    id: 'sdk.examplesText',
    defaultMessage: `
      You can also download and try a set of working demo examples for each danube.ai service using the SDK here:
      <a href="https://gitlab.com/danube.ai/sdk" target="_blank" rel="noopener noreferrer">https://gitlab.com/danube.ai/sdk</a>
    `,
    description: 'sub header for sdk page',
  },

  examplesText1: {
    id: 'sdk.examplesText1',
    defaultMessage: `
      In the following, an example call and the according return values using the {endpoint} method of a {danubeClient} instance are shown.
    `,
    description: 'text for api page',
  },
  examplesText3: {
    id: 'sdk.examplesText3',
    defaultMessage: `
      In the following, example calls and the according return values using the {endpoint} method of a {danubeClient} instance are shown.
    `,
    description: 'text for api page',
  },
});

export default messages;
