import { defineMessages } from 'react-intl';

const messages = defineMessages({
  filterAndSort: {
    id: 'demo.avengers.search.filterAndSort',
    defaultMessage: 'Which Avenger do you want to hire?',
    description: 'label for field in search mask',
  },

  strengthLevel1: {
    id: 'demo.avengers.search.strengthLevel1',
    defaultMessage: 'Human',
    description: 'label for field in search mask',
  },
  strengthLevel2: {
    id: 'demo.avengers.search.strengthLevel2',
    defaultMessage: 'Enhanced',
    description: 'label for field in search mask',
  },
  strengthLevel3: {
    id: 'demo.avengers.search.strengthLevel3',
    defaultMessage: 'Superhuman',
    description: 'label for field in search mask',
  },
  strengthLevel4: {
    id: 'demo.avengers.search.strengthLevel4',
    defaultMessage: 'Hulk',
    description: 'label for field in search mask',
  },

  socialLevel1: {
    id: 'demo.avengers.search.socialLevel1',
    defaultMessage: 'Hulk',
    description: 'label for field in search mask',
  },
  socialLevel2: {
    id: 'demo.avengers.search.socialLevel2',
    defaultMessage: 'difficult among people',
    description: 'label for field in search mask',
  },
  socialLevel3: {
    id: 'demo.avengers.search.socialLevel3',
    defaultMessage: 'slightly ill-mannered',
    description: 'label for field in search mask',
  },
  socialLevel4: {
    id: 'demo.avengers.search.socialLevel4',
    defaultMessage: 'team player',
    description: 'label for field in search mask',
  },

  salary: {
    id: 'demo.avengers.search.salary',
    defaultMessage: 'What salary are you willing to pay your Avenger?',
    description: 'label for field in search mask',
  },
  salaryFrom: {
    id: 'demo.avengers.search.salary.from',
    defaultMessage: 'from (€)',
    description: 'label for field in search mask',
  },
  salaryTo: {
    id: 'demo.avengers.search.salary.to',
    defaultMessage: 'to (€)',
    description: 'label for field in search mask',
  },
  salaryImportance: {
    id: 'demo.avengers.search.salary.importance',
    defaultMessage: `
      How important is salary?
    `,
    description: 'label for field in search mask',
  },
  experienceImportance: {
    id: 'demo.avengers.search.experience.importance',
    defaultMessage: `
      How important is experience?
    `,
    description: 'label for field in search mask',
  },
  travelTimeImportance: {
    id: 'demo.avengers.search.travelTime.importance',
    defaultMessage: `
      How important is reaction time?
    `,
    description: 'label for field in search mask',
  },
  strength: {
    id: 'demo.avengers.search.strength',
    defaultMessage: `
      What strength level should your Avenger have?
    `,
    description: 'label for field in search mask',
  },
  social: {
    id: 'demo.avengers.search.social',
    defaultMessage: `
      What social level should your Avenger have?
    `,
    description: 'label for field in search mask',
  },
  superPowers: {
    id: 'demo.avengers.search.superPowers',
    defaultMessage: `
      What super powers should your Avenger have?
    `,
    description: 'label for field in search mask',
  },
  benefits: {
    id: 'demo.avengers.search.benefits',
    defaultMessage: `
      What benefits should your Avenger have?
    `,
    description: 'label for field in search mask',
  },
});

export default messages;
