import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col } from 'react-bootstrap';

import Loading from '../../../../components/Loading';
import s from './LandingPage.scss';
import SearchMaskForm from '../SearchMask/SearchMaskForm';
import ResultsList from '../ResultsList';
// import { COMP_TYPES, JOB_LEVELS } from '../../constants/jobFieldValues';

class LandingPage extends React.Component {
  static propTypes = {
    technologiesQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      technologies: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    benefitsQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      benefits: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      skipPageLoadQuery: true,
    };

    this.onSearch = this.onSearch.bind(this);
    this.scrollToResults = this.scrollToResults.bind(this);

    this.resultListRef = React.createRef();
  }

  onSearch(formValues) {
    this.setState({ skipPageLoadQuery: false });

    const filter = { ...formValues.filter };

    // reformat some parameters
    if ('companyType' in filter) {
      if (filter.companyType == null) filter.companyType = [];
      else filter.companyType = filter.companyType.map(ct => ct.value);
    }
    if ('jobLevel' in filter) {
      if (filter.jobLevel == null) filter.jobLevel = [];
      else filter.jobLevel = filter.jobLevel.map(jl => jl.value);
    }
    if ('technologies' in filter) {
      if (filter.technologies == null) filter.technologies = [];
      else filter.technologies = filter.technologies.map(t => t.value);
    }
    if ('benefits' in filter) {
      if (filter.benefits == null) filter.benefits = [];
      else filter.benefits = filter.benefits.map(b => b.value);
    }
    if ('salaryFrom' in filter && filter.salaryFrom != null) {
      filter.salaryFrom = parseFloat(filter.salaryFrom);
    }
    if ('salaryTo' in filter && filter.salaryTo != null) {
      filter.salaryTo = parseFloat(filter.salaryTo);
    }
    if ('location' in filter && filter.location != null) {
      filter.location = filter.location.value;
    }

    this.setState({ filter });
  }

  scrollToResults() {
    if (this.resultListRef.current) {
      window.scroll({
        top: this.resultListRef.current.offsetTop + 74,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  render() {
    const { technologiesQuery, benefitsQuery } = this.props;

    if (!technologiesQuery || !benefitsQuery) return null;

    if (technologiesQuery.loading || benefitsQuery.loading) {
      return <Loading />;
    }

    return (
      <div className={s.landingPageContainer}>
        <Row>
          <Col mdOffset={2} md={8}>
            <SearchMaskForm
              onSubmit={this.onSearch}
              technologies={technologiesQuery.technologies}
              benefits={benefitsQuery.benefits}
              initialValues={{
                filter: {
                  salaryImportance: 0.5,
                  remoteWorkImportance: 0.5,
                  companyType: [],
                  jobLevel: [],
                  location: {
                    value: 'Linz',
                    label: 'Linz',
                  },
                  locationImportance: 0.5,
                  technologies: [],
                  benefits: [],
                },
              }}
            />
            <div ref={this.resultListRef}>
              <ResultsList
                filter={this.state.filter}
                skipPageLoadQuery={this.state.skipPageLoadQuery}
                onLoadingFinished={this.scrollToResults}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const technologiesQuery = gql`
  query technologiesQuery {
    technologies
  }
`;

const benefitsQuery = gql`
  query benefitsQuery {
    benefits
  }
`;

export default compose(
  graphql(technologiesQuery, {
    name: 'technologiesQuery',
  }),
  graphql(benefitsQuery, {
    name: 'benefitsQuery',
  }),
)(withStyles(s)(LandingPage));
