import { defineMessages } from 'react-intl';

const messages = defineMessages({
  reallyRevokeTitle: {
    id: 'user.reallyRevokeTitle',
    defaultMessage: 'Warning',
    description: 'heading for Revoke Privacy Agreement Popup',
  },
  reallyRevokeMessage: {
    id: 'user.reallyRevokeMessage',
    defaultMessage:
      'If you revoke the privacy agreement you will be logged out.',
    description: 'Description for Revoke Privacy Agreement',
  },
  revokePrivacyAgreement: {
    id: 'user.revokePrivacyAgreement',
    defaultMessage: 'Revoke Privacy Agreement',
    description: 'heading for Revoke Privacy Agreement',
  },
  revokePrivacyAgreementButton: {
    id: 'user.revokePrivacyAgreementButton',
    defaultMessage: 'Revoke',
    description: 'Label for Revoke Privacy Agreement button',
  },
  changePassword: {
    id: 'user.changePassword',
    defaultMessage: 'Change Password',
    description: 'heading for change password',
  },
  username: {
    id: 'user.username',
    defaultMessage: 'Username',
    description: 'Admin user username label',
  },
  password: {
    id: 'user.password',
    defaultMessage: 'Password',
    description: 'Admin user password label',
  },
  passwordConfirmation: {
    id: 'user.passwordConfirmation',
    defaultMessage: 'Password Confirmation',
    description: 'user password confirmation label',
  },
  passwordTitle: {
    id: 'user.passwordTitle',
    defaultMessage: 'Change Password',
    description: 'Change Password label',
  },
  title: {
    id: 'user.userDataTabTitle',
    defaultMessage: 'User',
    description: 'Header of user edit dialog',
  },
  alertSuccessTitle: {
    id: 'user.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'user.alertSuccessMessage',
    defaultMessage: 'User updated successfully!',
    description: 'Message for success alert dialog',
  },
  alertErrorTitle: {
    id: 'user.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessage: {
    id: 'user.alertErrorMessage',
    defaultMessage: 'Updating user failed!',
    description: 'Message for error alert dialog',
  },
  save: {
    id: 'component.save',
    defaultMessage: 'Save',
    description: 'Save label for component form',
  },
  deleteHeading: {
    id: 'user.deleteHeading',
    defaultMessage: `
      Delete Account
    `,
    description: 'Heading for login form',
  },
  deleteSubmit: {
    id: 'user.deleteSubmit',
    defaultMessage: 'Delete',
    description: 'Submit button text in register form',
  },
  reallyDelete: {
    id: 'user.reallyDelete',
    defaultMessage: 'Do you really want to delete your user?',
    description: 'Do you really want to delete text',
  },
  invalidPassword: {
    id: 'user.invalidPassword',
    defaultMessage: 'invalid password',
    description:
      'Error message for invalid credentials in delete account component',
  },
});

export default messages;
