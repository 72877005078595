import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';

import { componentKeys, ClickTracker } from 'components/Analytics';

// eslint-disable-next-line css-modules/no-unused-class
import bs from '../CherryTreeButtons.scss';
import messages from './messages';
import HubspotAlert from '../../HubspotAlert';
import {
  CHERRY_TREE_ARTICLE_FORM_IDS,
  CHERRY_TREE_ARTICLE_DOCUMENTS,
} from '../../../constants';

class CherryTreeArticleButton extends React.Component {
  static propTypes = {
    localeToDocumentLinkMap: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    localeToFormIdMap: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    localeToDocumentLinkMap: CHERRY_TREE_ARTICLE_DOCUMENTS,
    localeToFormIdMap: CHERRY_TREE_ARTICLE_FORM_IDS,
  };

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showLink: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.setState({ showLink: true });
  }

  render() {
    const { localeToFormIdMap } = this.props;
    const { showModal, showLink } = this.state;

    return [
      <ClickTracker
        componentKey={componentKeys.CHERRY_TREE_DOWNLOAD_ARTICLE_BUTTON}
      >
        <button
          key="article-button"
          onClick={() => {
            this.setState({ showModal: true });
          }}
          className={`${bs.cherryTreeButton} ${bs.whitepaperButton}`}
        >
          <FormattedMessage {...messages.label} />
          <img src="/cherrytree/whitepaper.png" alt="" />
        </button>
      </ClickTracker>,
      <HubspotAlert
        key="article-modal"
        show={showModal}
        onHide={() => {
          this.setState({
            showModal: false,
            showLink: false,
          });
        }}
        title={<FormattedMessage {...messages.label} />}
        postMsg={
          showLink ? (
            <div className={bs.downloadLinkContainer}>
              <a
                href={this.props.localeToDocumentLinkMap[this.props.locale]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage {...messages.download} />
              </a>
              <img src="/cherrytree/cherry.png" alt="" />
            </div>
          ) : null
        }
        localeToFormIdMap={localeToFormIdMap}
        onSubmit={this.onSubmit}
      />,
    ];
  }
}

const mapStateToProps = state => ({
  locale: state && state.intl ? state.intl.locale : null,
});

export default connect(mapStateToProps)(
  withStyles(bs)(CherryTreeArticleButton),
);
