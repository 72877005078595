import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-bootstrap';
import FaArrowDown from 'react-icons/lib/md/arrow-downward';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmileBeam } from '@fortawesome/free-regular-svg-icons'; // eslint-disable-line import/extensions
import {
  faArrowUpRightDots,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons'; // eslint-disable-line import/extensions

import { componentKeys, ClickTracker } from 'components/Analytics';

// import CookieBanner from 'react-cookie-banner';
// import { navItemsRight } from 'components/TopNavigation/navItems';
import s from './NewLandingPageStyles.scss'; // eslint-disable-line css-modules/no-unused-class
import topNavStyle from '../TopNavigation/TopNavigation.scss'; // eslint-disable-line css-modules/no-unused-class
import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import RequestDemoButton from '../RequestDemoButton';
import WhitepaperButton from '../WhitepaperButton';
import landingPageMessages from './messages';
import { REQUEST_DEMO_FORM_IDS } from '../../constants';

const scrollToSlide = slideRef => {
  window.scroll({
    top: slideRef.offsetTop,
    left: 0,
    behavior: 'smooth',
  });
};

class LandingPage extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.frontPageRef = React.createRef();
    this.slide1Ref = React.createRef();
  }

  componentDidMount() {
    if (window.location.hash && window.location.hash === '#content') {
      scrollToSlide(this.slide1Ref.current);
    }
  }

  render() {
    return (
      <div className={s.landingPage}>
        <div>
          <Row className={s.firstPage}>
            <Col xs={12} xsPull="right" lg={7}>
              <h1 className={s.headline}>
                <FormattedMessage
                  {...landingPageMessages.landingPageTitle}
                  values={{ br: <br className={s.mobileBreak} /> }}
                />
              </h1>
              <p className={s.subline}>
                <FormattedMessage
                  {...landingPageMessages.landingPageSubtitle}
                  values={{ br: <br /> }}
                />
              </p>
              <div
                className={`${
                  s.buttonsContainer
                } hidden-xs hidden-sm hidden-md`}
              >
                <RequestDemoButton
                  isTopNav={false}
                  localeToFormIdMap={REQUEST_DEMO_FORM_IDS}
                  location={this.props.location}
                />
                <WhitepaperButton location={this.props.location} />
              </div>
            </Col>
            <Col xs={12} lg={5}>
              <div className={s.infoGraphicWrapper}>
                {/* <Link to="/demos/avengers"> */}
                <img src="/empathy_face.svg" alt="" />
                {/* </Link> */}
              </div>
            </Col>
            <Col xs={12} lgHidden>
              <div className={s.buttonsContainer}>
                <RequestDemoButton
                  isTopNav={false}
                  localeToFormIdMap={REQUEST_DEMO_FORM_IDS}
                />
                <WhitepaperButton />
              </div>
            </Col>
            <div
              className={`${
                s.downButtonContainer
              } hidden-xs hidden-sm hidden-md`}
            >
              <button
                onClick={() => {
                  scrollToSlide(this.slide1Ref.current);
                }}
              >
                <FaArrowDown />
              </button>
            </div>
          </Row>
        </div>
        <div className={s.secondPage} ref={this.slide1Ref}>
          <Row>
            <Col className={s.iconColumn} xs={12} lg={4}>
              <FontAwesomeIcon icon={faSmileBeam} />
              <div>
                <FormattedMessage
                  {...landingPageMessages.graphicIcon_1}
                  values={{ br: <br /> }}
                />
              </div>
            </Col>
            <Col className={s.iconColumn} xs={12} lg={4}>
              <FontAwesomeIcon icon={faArrowUpRightDots} />
              <div>
                <FormattedMessage
                  {...landingPageMessages.graphicIcon_2}
                  values={{ br: <br /> }}
                />
              </div>
            </Col>
            <Col className={s.iconColumn} xs={12} lg={4}>
              <FontAwesomeIcon icon={faUserShield} />
              <div>
                <FormattedMessage
                  {...landingPageMessages.graphicIcon_3}
                  values={{ br: <br /> }}
                />
              </div>
            </Col>
          </Row>
          <div
            className={`${s.secondPageSpacer} hidden-xs hidden-sm hidden-md`}
          />
        </div>
        <div className={s.thirdPage}>
          <Row>
            <Col className={`${s.rightColumn} hidden-lg`} xs={12} lg={5}>
              <img src="/danube_mary.png" alt="" />
            </Col>
            <Col xs={12} lg={7}>
              <div className={s.text}>
                <p>
                  <FormattedMessage
                    {...landingPageMessages.infographicSection_1}
                    values={{
                      danube: <span className={s.danubeText}>danube.ai</span>,
                    }}
                  />
                </p>
              </div>
              <div className={s.text}>
                <p>
                  <FormattedMessage
                    {...landingPageMessages.infographicSection_2}
                    values={{
                      danube: <span className={s.danubeText}>danube.ai</span>,
                    }}
                  />
                </p>
              </div>
              <div className={s.learnMoreText}>
                <p>
                  <FormattedMessage
                    {...landingPageMessages.infographicSection_3}
                  />
                </p>
                <Link to="/showcases/geizhals">
                  <ClickTracker
                    componentKey={
                      componentKeys.MAIN_LANDING_PAGE_LEARN_MORE_BUTTON
                    }
                  >
                    <button
                      className={`btn btn-secondary btn-large btn-wide btn-full-round ${
                        s.learnMoreButton
                      }`}
                    >
                      <FormattedMessage {...landingPageMessages.learnMore} />
                    </button>
                  </ClickTracker>
                </Link>
              </div>
            </Col>
            <Col
              className={`${s.rightColumn} hidden-xs hidden-sm hidden-md`}
              xs={12}
              lg={5}
            >
              <img src="/danube_mary.png" alt="" />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(
  injectIntl(withStyles(s, layoutStyle, topNavStyle, buttonStyle)(LandingPage)),
);
