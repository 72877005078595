import React from 'react';
import { pageKeys, withAccessTracking } from 'components/Analytics';
import Loading from 'components/Loading';
import loadable from '@loadable/component';

import LandingPage from './landingPage';
import MediaLandingPage from './landingPage/MediaLandingPage';
import ECommerceLandingPage from './landingPage/ECommerceLandingPage';
import RecruitingLandingPage from './landingPage/RecruitingLandingPage';
import Admin from './admin';
// import Registration from './registration';
import My from './my';
import Results from './results';
import PrivacyAgreement from './privacyAgreement';
// import ResetPassword from './resetPassword';
// import UseCases from './useCases';
import Technology from './technology';
import RealData from './realData';
import Press from './press';
import Imprint from './imprint';
import Privacy from './privacy';
import AboutUs from './about';
import Contact from './contact';
import messages from './messages';
import Apait from './partners/Apait';
import DevJobs from './partners/DevJobs';
import EnliteAI from './partners/EnliteAI';
import Api from './api';
import FAQ from './faq';
import DevJobsDemo from './demos/devJobs';
import DevJobsDemoLearnMore from './demos/devJobs/LearnMore';
import ImmoDemo from './demos/immo';
import O2Demo from './demos/o2';
import AvengersDemo from './demos/avengers';
import RecruitingDemo from './demos/recruiting';
import RecruitingDemoLearnMore from './demos/recruiting/LearnMore';
import GeizhalsDemo from './demos/geizhals';
import ShowcaseGeizhals from './showcases/geizhals';
import ShowcaseDevjobs from './showcases/devjobs';
import Pricing from './pricing';
import CherryTree from './cherrytree';

const AsyncLogin = loadable(() => import('./login'), {
  fallback: <Loading />,
});

const routes = [
  {
    path: '/',
    component: withAccessTracking({ pageKey: pageKeys.LANDING_PAGE })(
      LandingPage,
    ),
    exact: true,
    title: messages.landingPageTitle,
    description: messages.landingPageDescription,
    keywords: messages.landingPageKeywords,
  },
  {
    path: '/media',
    component: withAccessTracking({ pageKey: pageKeys.LANDING_PAGE_MEDIA })(
      MediaLandingPage,
    ),
    exact: true,
    title: messages.landingPageTitle,
    description: messages.landingPageDescription,
    keywords: messages.landingPageKeywords,
  },
  {
    path: '/ecommerce',
    component: withAccessTracking({ pageKey: pageKeys.LANDING_PAGE_ECOMMERCE })(
      ECommerceLandingPage,
    ),
    exact: true,
    title: messages.landingPageTitle,
    description: messages.landingPageDescription,
    keywords: messages.landingPageKeywords,
  },
  {
    path: '/recruiting',
    component: withAccessTracking({
      pageKey: pageKeys.LANDING_PAGE_RECRUITING,
    })(RecruitingLandingPage),
    exact: true,
    title: messages.landingPageTitle,
    description: messages.landingPageDescription,
    keywords: messages.landingPageKeywords,
  },
  {
    path: '/login',
    component: AsyncLogin,
    exact: true,
    title: messages.loginTitle,
    description: messages.loginDescription,
    keywords: messages.loginKeywords,
  },
  {
    path: '/admin',
    component: Admin,
  },
  {
    path: '/my',
    component: My,
  },
  {
    path: '/results/:id',
    component: Results,
  },
  // {
  //   path: '/registration',
  //   component: withAccessTracking({ pageKey: pageKeys.REGISTRATION })(
  //     Registration,
  //   ),
  //   exact: true,
  //   title: messages.registrationTitle,
  //   description: messages.registrationDescription,
  //   keywords: messages.registrationKeywords,
  // },
  {
    path: '/privacy-agreement',
    component: PrivacyAgreement,
  },
  // {
  //   path: '/reset-password',
  //   component: ResetPassword,
  // },
  // {
  //   path: '/use-cases',
  //   component: UseCases,
  //   title: messages.useCasesTitle,
  //   description: messages.useCasesDescription,
  //   keywords: messages.useCasesKeywords,
  // },
  {
    path: '/technology',
    component: Technology,
  },
  {
    path: '/real-data',
    component: RealData,
  },
  {
    path: '/press',
    component: Press,
    exact: true,
  },
  {
    path: '/imprint',
    component: Imprint,
    exact: true,
  },
  {
    path: '/privacy',
    component: Privacy,
    exact: true,
  },
  {
    path: '/about',
    component: AboutUs,
    exact: true,
  },
  {
    path: '/contact',
    component: withAccessTracking({ pageKey: pageKeys.CONTACT })(Contact),
    exact: true,
  },
  {
    path: '/partners/apait',
    component: Apait,
    exact: true,
  },
  {
    path: '/partners/devjobs',
    component: DevJobs,
    exact: true,
  },
  {
    path: '/partners/enliteai',
    component: EnliteAI,
    exact: true,
  },
  {
    path: '/api',
    component: Api,
    exact: false,
  },
  {
    path: '/faq',
    component: FAQ,
    exact: true,
  },
  {
    path: '/demos/jobs',
    component: DevJobsDemo,
    exact: true,
  },
  {
    path: '/demos/jobs/about',
    component: DevJobsDemoLearnMore,
    exact: true,
  },
  {
    path: '/demos/immo',
    component: ImmoDemo,
    exact: true,
  },
  {
    path: '/demos/phones',
    component: O2Demo,
    exact: true,
  },
  {
    path: '/demos/avengers',
    component: AvengersDemo,
    exact: true,
  },
  {
    path: '/demos/recruiting',
    component: withAccessTracking({
      pageKey: pageKeys.RECRUITING_DEMO,
    })(RecruitingDemo),
    exact: true,
  },
  {
    path: '/demos/recruiting/about',
    component: RecruitingDemoLearnMore,
    exact: true,
  },
  {
    path: '/demos/geizhals',
    component: GeizhalsDemo,
    exact: true,
  },
  {
    path: '/showcases/geizhals',
    component: withAccessTracking({ pageKey: pageKeys.GEIZHALS_SHOW_CASE })(
      ShowcaseGeizhals,
    ),
    exact: true,
  },
  {
    path: '/showcases/devjobs',
    component: withAccessTracking({ pageKey: pageKeys.DEVJOBS_SHOW_CASE })(
      ShowcaseDevjobs,
    ),
    exact: true,
  },
  {
    path: '/pricing',
    component: withAccessTracking({ pageKey: pageKeys.PRICING })(Pricing),
    exact: true,
  },
  {
    path: '/cherrytree',
    component: withAccessTracking({ pageKey: pageKeys.CHERRY_TREE })(
      CherryTree,
    ),
    exact: true,
  },
  /* ****************************************************************************
                                  !!! Important !!!
    When adding a new route that should be logged by our recommendation feature,
      please add it to ./routesNamesMap.js, as otherwise it will be excluded.
  **************************************************************************** */
];

export default routes;
