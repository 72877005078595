import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Modal } from 'react-bootstrap';

import s from './SuggestionModal.scss';
import messages from './messages';
import searchMaskMessages from '../SearchMask/messages';
import ResultsList from '../ResultsList/ResultsList';
import { filterType, apartmentType } from '../ResultsList/types';
import { optionParameterNamesMap } from '../SearchMask/SearchMaskForm';

class SuggestionModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    suggestedFields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
    crIdx: PropTypes.number,
    crResult: PropTypes.shape({
      apartments: PropTypes.arrayOf(apartmentType),
      columnScores: PropTypes.arrayOf(PropTypes.number),
    }),
    filter: filterType,
    idLinkMapping: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    onApplySuggestion: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    suggestedFields: [],
    crIdx: -1,
    crResult: {},
    filter: {},
    idLinkMapping: {},
  };

  constructor(props) {
    super(props);

    this.parseFieldName = this.parseFieldName.bind(this);
    this.parseFieldValue = this.parseFieldValue.bind(this);
  }

  parseFieldName(field) {
    const { intl } = this.props;

    /* eslint-disable prettier/prettier */
    switch (field) {
      case 'type_transferType':
        return intl.formatMessage(searchMaskMessages.type);
      case 'localization_address_zip':
        return intl.formatMessage(searchMaskMessages.address);
      case 'priceInformation_primaryPriceFrom':
        return `${intl.formatMessage(searchMaskMessages.price)} - ${intl.formatMessage(searchMaskMessages.fromPrice)}`;
      case 'priceInformation_primaryPriceTo':
        return `${intl.formatMessage(searchMaskMessages.price)} - ${intl.formatMessage(searchMaskMessages.toPrice)}`;
      case 'area_total_areaFrom':
        return `${intl.formatMessage(searchMaskMessages.area)} - ${intl.formatMessage(searchMaskMessages.fromArea)}`;
      case 'area_total_areaTo':
        return `${intl.formatMessage(searchMaskMessages.area)} - ${intl.formatMessage(searchMaskMessages.toArea)}`;
      case 'area_numberOfRoomsFrom':
        return intl.formatMessage(searchMaskMessages.numberOfRooms);
      case 'Balconies_and_Terraces':
        return intl.formatMessage(searchMaskMessages.balconyAndTerrace);
      default:
        return field;
    }
    /* eslint-enable prettier/prettier */
  }

  parseFieldValue(field, value) {
    const { intl } = this.props;

    /* eslint-disable prettier/prettier */
    switch (field) {
      case 'Balconies_and_Terraces':
        return value === 1 ? intl.formatMessage(messages.yes) : intl.formatMessage(searchMaskMessages.any);
      case 'type_transferType':
        return intl.formatMessage(optionParameterNamesMap[value]);
      default:
        return value;
    }
    /* eslint-enable prettier/prettier */
  }

  render() {
    const {
      isOpen,
      onClose,
      suggestedFields,
      crIdx,
      crResult,
      filter,
      idLinkMapping,
      onApplySuggestion,
    } = this.props;

    let fields;
    let newFilter;

    if (crIdx !== -1) {
      fields = suggestedFields[crIdx];
      newFilter = { ...filter };
      fields.forEach(field => {
        newFilter[field.field] = field.value;
      });
    }

    return (
      <Modal
        show={isOpen}
        onHide={onClose}
        dialogClassName={s.suggestionModal}
        backdrop
      >
        <Modal.Body className={s.suggestionModalBody}>
          <div className={s.modalContentContainer}>
            <div>
              {crIdx !== -1 && (
                <div>
                  <h1>
                    <FormattedMessage {...messages.suggestionsModalHeader} />
                  </h1>
                  <h2>
                    <FormattedMessage {...messages.suggestionsModalText1} />
                  </h2>
                  <h3>
                    <FormattedMessage {...messages.suggestionsModalText2} />
                  </h3>
                </div>
              )}
              {crIdx === -1 && (
                <div>
                  <h1>
                    <FormattedMessage {...messages.suggestionsModalHeader2} />
                  </h1>
                  <h2>
                    <FormattedMessage {...messages.suggestionsModalText3} />
                  </h2>
                </div>
              )}
            </div>
            {crIdx !== -1 && [
              <div key="suggested-filter-fields">
                {/* eslint-disable-next-line arrow-body-style */}
                {fields.map((field, index) => {
                  // only show changed fields
                  // TODO - disabled for now
                  // if (filter[field.field] === field.value) return null;
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={s.resultsHeader} key={`field-${index}`}>
                      <span>
                        <span className={s.suggestedFilterField}>
                          {this.parseFieldName(field.field)}:
                        </span>
                        <span>
                          {this.parseFieldValue(field.field, field.value)}
                        </span>
                      </span>
                    </div>
                  );
                })}
              </div>,
              <div key="top-grey-border" className={s.greyBorder} />,
              <div key="suggested-results" className={s.resultsContainer}>
                <ResultsList
                  filter={newFilter}
                  idLinkMapping={idLinkMapping}
                  apartmentsData={{
                    apartments: crResult.apartments,
                    columnScores: crResult.columnScores,
                  }}
                  itemsPerPage={5}
                  compact
                  compactTopResultsCount={3}
                />
              </div>,
              <div key="bottom-grey-border" className={s.greyBorder} />,
            ]}
            <div className={s.buttonBar}>
              <button
                type="submit"
                className="btn-primary btn-round"
                onClick={onClose}
                style={{ marginRight: '10px' }}
              >
                <FormattedMessage {...messages.close} />
              </button>
              <button
                type="button"
                className="btn-secondary btn-round"
                onClick={() => {
                  onApplySuggestion();
                  onClose();
                }}
              >
                <FormattedMessage {...messages.applyFilterSettings} />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default injectIntl(withStyles(s)(SuggestionModal));
