import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from './Imprint.scss';
import messages from './messages';

class Imprint extends React.Component {
  render() {
    return (
      <div className={s.imprintContainer}>
        <Row className={layoutStyle.noMargin}>
          <Col mdOffset={1} md={4}>
            <h1>
              <FormattedMessage {...messages.imprintHeader} />
            </h1>
          </Col>
          <Col md={7} className={s.imprintText}>
            <p>
              danube.ai solutions GmbH
              <br />
              Lambrechtgasse 3/3, 1040 Wien
              <br />
              UID: ATU78644219
              <br />
              FN: 591264 g<br />
            </p>
            <p>
              Member of Wirtschaftskammer Wien, Fachverband UBIT
              <br />
              Profession:
              <br />
              {'Trade regulation act: '}
              <a
                href="http://www.ris.bka.gv.at"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.ris.bka.gv.at
              </a>
              <br />
              Magistratisches Bezirksamt für den 4. Bezirk
              <br />
            </p>
            <p>
              Purpose of the website:
              <br />
              This website contains online services belonging to danube.ai
              solutions GmbH.
              <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.danube.ai"
              >
                danube.ai
              </a>{' '}
              provides information and services of the company’s products.
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://firmen.wko.at/Web/DetailsKontakt.aspx?FirmaID=9c11f3a1-856e-4b8f-a167-39fbfda2e928&StandortID=0&Suchbegriff=3datax+gmbh+%26+co+kg&Page=1"
            >
              <img src="http://images.wko.at/common/icons/ecg.gif" alt="" />
            </a>
            <p>
              danube.ai solutions GmbH, IT-Dienstleistung, 1040 Wien,
              Lambrechtgasse 3/3, WKO: Alle Informationen aus erster Hand, FG
              Unternehmensberatung, Buchhaltung und Informationstechn. /
              IT-Dienstleistung
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle)(Imprint);
