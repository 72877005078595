import React from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { meQuery } from 'components/Auth/withLogin';
import { withLogout } from 'components/Auth/withLogout';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './PrivacyAgreement.scss';
import PrivacyAgreementForm from './PrivacyAgreementForm';
import { setRedirectSource } from '../../actions/redirect';

class PrivacyAgreement extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  static propTypes = {
    logout: PropTypes.func.isRequired,
    acceptPrivacyAgreement: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    redirectSource: PropTypes.string.isRequired,
    setRedirectSource: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(formValues) {
    const { privacyPolicyAccepted } = formValues;
    if (privacyPolicyAccepted) {
      const user = { privacyAgreementAccepted: true };
      const { data } = await this.props.acceptPrivacyAgreement(user);
      if (data.updateMe && data.updateMe.privacyAgreementAccepted) {
        await this.context.client.query({
          query: meQuery,
          name: 'meData',
          fetchPolicy: 'network-only',
        });
        const { history, redirectSource } = this.props;

        if (redirectSource) {
          history.push(redirectSource);
          this.props.setRedirectSource({ source: null });
        } else {
          history.push('/my/dashboard');
        }
      }
    }
  }

  render() {
    const { logout } = this.props;
    return (
      <div className={s.privacyContainer}>
        <Row className="noMargin">
          <Col
            md={3}
            sm={3}
            className={`${s.left} col-md-offset-1 col-sm-offset-1`}
          >
            <img className={s.fish} src="/jailFish.svg" alt="fish" />
          </Col>
          <Col
            md={6}
            sm={7}
            xs={12}
            className={`${s.right} col-md-offset-right-2`}
          >
            <PrivacyAgreementForm
              onSubmit={this.handleSubmit}
              handleCancel={logout}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const acceptPrivacyAgreementMutation = gql`
  mutation acceptPrivacyAgreement($user: UserInput!) {
    updateMe(user: $user) {
      privacyAgreementAccepted
    }
  }
`;

const mapStateToProps = store => ({
  redirectSource: store.redirect.source,
});

const mapDispatchToProps = dispatch => ({
  setRedirectSource: ({ source }) => dispatch(setRedirectSource({ source })),
});

export default withRouter(
  compose(
    withStyles(s),
    graphql(acceptPrivacyAgreementMutation, {
      props: ({ mutate }) => ({
        acceptPrivacyAgreement: user =>
          mutate({
            variables: { user },
          }),
      }),
    }),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(withLogout(PrivacyAgreement)),
);
