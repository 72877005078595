import { defineMessages } from 'react-intl';

const messages = defineMessages({
  // ////////////// OLD
  greetings: {
    id: 'dashboard.greetings',
    defaultMessage: 'Greetings, {name}!',
    description: 'Greetings message for dashboard',
  },
  introText: {
    id: 'dashboard.introText',
    defaultMessage: `
      Here you will find your most recent recalibrations and currently running tasks.
    `,
    description: 'Intro message for dashboard',
  },
  recentTasks: {
    id: 'dashboard.recentTasks',
    defaultMessage: 'Recent Tasks',
    description: 'Label for Recent Tasks header',
  },
  task: {
    id: 'dashboard.task',
    defaultMessage: 'Task',
    description: 'Label for Task table header',
  },
  startedAt: {
    id: 'dashboard.startedAt',
    defaultMessage: 'Started at',
    description: 'Label for Started At table header',
  },
  status: {
    id: 'dashboard.status',
    defaultMessage: 'Status',
    description: 'Label for Status table header',
  },
  statusRunning: {
    id: 'dashboard.statusRunning',
    defaultMessage: 'Running',
    description: 'Label for Running Status message',
  },
  statusCompleted: {
    id: 'dashboard.statusCompleted',
    defaultMessage: 'Completed',
    description: 'Label for Completed Status message',
  },
  statusFailed: {
    id: 'dashboard.statusFailed',
    defaultMessage: 'Failed',
    description: 'Label for Failed Status message',
  },
  viewResults: {
    id: 'dashboard.viewResults',
    defaultMessage: 'View Results',
    description: 'Label for View Results message',
  },
  newRecalibration: {
    id: 'dashboard.newRecalibration',
    defaultMessage: '+ New Recalibration',
    description: 'Label for New Recalibration message',
  },
  // //////////////

  lastChanged: {
    id: 'dashboard.lastChanged',
    defaultMessage: 'Last changed',
    description: 'Label for Last changed message',
  },
  datasets: {
    id: 'dashboard.datasets',
    defaultMessage: 'Datasets',
    description: 'Label for Datasets message',
  },
  fishTotal: {
    id: 'dashboard.fishTotal',
    defaultMessage: 'Fish total',
    description: 'Label for Fish Total message',
  },
  view: {
    id: 'dashboard.datasetCard.view',
    defaultMessage: 'View',
    description: 'Label for View message',
  },
  topRows: {
    id: 'dashboard.datasetCard.topRows',
    defaultMessage: 'Top {count} Rows',
    description: 'Label for Top Rows message',
  },
  topColumns: {
    id: 'dashboard.datasetCard.topColumns',
    defaultMessage: 'Top {count} Columns',
    description: 'Label for Top Rows message',
  },
  graph: {
    id: 'dashboard.datasetCard.graph',
    defaultMessage: 'Graph',
    description: 'Label for Graph message',
  },
  points: {
    id: 'dashboard.datasetCard.points',
    defaultMessage: 'points',
    description: 'Label for Points message',
  },
  deleteDataset: {
    id: 'dashboard.datasetCard.deleteDataset',
    defaultMessage: 'Delete',
    description: 'Button to delete selected dataset',
  },
});

export default messages;
