/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Panel } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './ApiKeyPanel.scss'; // eslint-disable-line css-modules/no-unused-class
import apiKeyStyle from '../../ApiKey/ApiKey.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import ModalStyle from '../../Modal/Modal.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../messages';
import ApiKeyPanelContent from './ApiKeyPanelContent';

class ApiKeyPanel extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      licenseCustomerId: PropTypes.string,
    }).isRequired,
    apiKey: PropTypes.shape({
      token: PropTypes.string,
      name: PropTypes.string,
      validities: PropTypes.array,
      license: PropTypes.shape({
        id: PropTypes.string,
        price: PropTypes.number,
        data: PropTypes.string,
      }),
    }),
    loading: PropTypes.bool.isRequired,
    product: PropTypes.string,
  };

  static defaultProps = {
    apiKey: null,
    product: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      overused: false,
    };

    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.renderPanel = this.renderPanel.bind(this);
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  renderPanel(body) {
    const { product } = this.props;
    const { overused } = this.state;

    const caretImage = this.state.expanded
      ? '/caret_expanded.png'
      : '/caret.png';

    return (
      <Panel
        className={s.collapsiblePanel}
        expanded={this.state.expanded}
        onToggle={this.toggleExpanded}
      >
        <Panel.Heading className={s.panelHeading}>
          <div className={s.headingContent}>
            <span className={s.title}>{product}</span>
            {overused && (
              <tr className={s.requestLimitNotification}>
                <td>
                  <FormattedMessage {...messages.overRequestLimit} />
                </td>
              </tr>
            )}
          </div>
          <button className={s.expandButton} onClick={this.toggleExpanded}>
            <img src={caretImage} alt="" />
          </button>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body className={s.panelBody}>{body}</Panel.Body>
        </Panel.Collapse>
      </Panel>
    );
  }

  render() {
    const { user, apiKey, loading, product } = this.props;

    if (loading) {
      return (
        <div>
          {this.renderPanel(
            <div>
              <FormattedMessage {...messages.loading} />
            </div>,
          )}
        </div>
      );
    }

    return (
      <div>
        {this.renderPanel(
          <ApiKeyPanelContent
            user={user}
            apiKey={apiKey}
            product={product}
            overUsageCallback={overused => {
              if (this.state.overused !== overused) this.setState({ overused });
            }}
          />,
        )}
      </div>
    );
  }
}

export default withStyles(buttonStyle, apiKeyStyle, ModalStyle, s)(ApiKeyPanel);
