/**
 * HOC that checks if the current user is logged in.
 * If so, it renders the wrapped component and
 * passes the current user's information to
 * it via the 'me' prop.
 *
 * Otherwise, it redirects to the homepage.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { meQuery } from 'components/Auth/withLogin';
import Loading from 'components/Loading';

import { setRedirectSource } from '../../actions/redirect';

const AuthenticationWrapper = WrappedComponent => {
  class WithAuthentication extends React.Component {
    static propTypes = {
      meData: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        me: PropTypes.shape({
          id: PropTypes.string.isRequired,
          privacyAgreementAccepted: PropTypes.bool.isRequired,
          roles: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
            }),
          ),
        }),
      }).isRequired,
      match: PropTypes.shape({
        path: PropTypes.string.isRequired,
      }).isRequired,
      redirectSource: PropTypes.string.isRequired,
    };

    render() {
      const {
        meData: { loading, me },
        redirectSource,
      } = this.props;

      if (loading) return <Loading />;
      if (!me) {
        return <Redirect to="/login" />;
      }
      if (!me.privacyAgreementAccepted) {
        return <Redirect to="/privacy-agreement" />;
      }
      if (redirectSource) {
        const url = redirectSource;
        this.props.setRedirectSource({ source: null });
        return <Redirect to={url} />;
      }
      return <WrappedComponent {...this.props} me={me} />;
    }
  }

  const mapStateToProps = store => ({
    redirectSource: store.redirect.source,
  });

  const mapDispatchToProps = dispatch => ({
    setRedirectSource: ({ source }) => dispatch(setRedirectSource({ source })),
  });

  return compose(
    graphql(meQuery, {
      name: 'meData',
    }),
    connect(
      mapStateToProps,
      mapDispatchToProps,
    ),
  )(withRouter(WithAuthentication));
};

export default AuthenticationWrapper;
