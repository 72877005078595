/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import s from './ApiKeyPanel.scss'; // eslint-disable-line css-modules/no-unused-class
import apiKeyStyle from '../../ApiKey/ApiKey.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import ModalStyle from '../../Modal/Modal.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../messages';
import apiKeyMessages from '../../ApiKey/messages';
import { DB_DATE_FORMAT } from '../../../util';
import { Alert } from '../../Modal';
import UpgradeLicenseForm from './UpgradeLicenseForm';

class ApiKeyPanel extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      licenseCustomerId: PropTypes.string,
    }).isRequired,
    apiKey: PropTypes.shape({
      token: PropTypes.string,
      name: PropTypes.string,
      validities: PropTypes.array,
      license: PropTypes.shape({
        id: PropTypes.string,
        price: PropTypes.number,
        data: PropTypes.string,
      }),
    }),
    product: PropTypes.string,
    overUsageCallback: PropTypes.func,
    usage: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      monthlyCredits: PropTypes.shape({
        totalCredits: PropTypes.number.isRequired,
        usedCredits: PropTypes.number.isRequired,
      }).isRequired,
    }),
    licensesData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      licenses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          description: PropTypes.string,
          price: PropTypes.number,
          duration: PropTypes.string,
          data: PropTypes.string,
          enabled: PropTypes.bool,
          visible: PropTypes.bool,
        }),
      ),
    }).isRequired,
    submitLicenseUpgradeRequest: PropTypes.func.isRequired,
    sendLicenseUpgradeRequestMail: PropTypes.func.isRequired,
  };

  static defaultProps = {
    apiKey: null,
    product: '',
    usage: null,
    overUsageCallback: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      waitingForResponse: false,
      success: false,
      error: false,
      errorMessage: null,
      creationAllowed: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderSelectionDialog = this.renderSelectionDialog.bind(this);
    this.renderWaitingDialog = this.renderWaitingDialog.bind(this);
    this.renderSuccessDialog = this.renderSuccessDialog.bind(this);
    this.renderErrorDialog = this.renderErrorDialog.bind(this);
  }

  componentDidUpdate() {
    const { usage, overUsageCallback } = this.props;

    const overused =
      usage && usage.monthlyCredits
        ? usage.monthlyCredits.usedCredits > usage.monthlyCredits.totalCredits
        : false;

    overUsageCallback(overused);
  }

  async onSubmit(formValues) {
    const { user, licensesData, sendLicenseUpgradeRequestMail } = this.props;

    if (!formValues.pricing || !formValues.pricing.value) {
      this.setState({
        waitingForResponse: false,
        success: false,
        error: true,
        errorMessage: 'No pricing selected!',
      });
      return;
    }

    try {
      this.setState({ waitingForResponse: true });

      let selectedLicense = licensesData.licenses.filter(
        l => l.id === formValues.pricing.value,
      );
      selectedLicense = selectedLicense.length > 0 ? selectedLicense[0] : null;

      const data = {
        userId: user.id,
        username: user.username,
        licenseCustomerId: user.licenseCustomerId,
        newLicenseId: formValues.pricing.value,
        newLicenseName: selectedLicense ? selectedLicense.name : '',
      };

      const success = await sendLicenseUpgradeRequestMail(data);

      if (
        !success ||
        !success.data ||
        !success.data.sendLicenseUpgradeRequestMail
      ) {
        this.setState({
          waitingForResponse: false,
          success: false,
          error: true,
          errorMessage: <FormattedMessage {...messages.upgradeErrorMessage} />,
        });
        return;
      }

      this.setState({
        waitingForResponse: false,
        success: true,
      });
    } catch (e) {
      this.setState({
        waitingForResponse: false,
        success: false,
        error: true,
        errorMessage: e.message,
      });
    }
  }

  openModal() {
    this.setState({
      showModal: true,
      waitingForResponse: false,
      success: false,
      error: false,
      errorMessage: null,
      creationAllowed: false,
    });
  }

  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  renderSelectionDialog() {
    const { apiKey, licensesData } = this.props;

    const upgradableLicenses = licensesData.licenses
      .filter(l => l.price > apiKey.license.price)
      .sort((l1, l2) => l1.price - l2.price);

    return (
      <Alert
        show={this.state.showModal}
        dialogClassName={`${ModalStyle.defaultDialog} ${apiKeyStyle.dialog}`}
        title={<FormattedMessage {...messages.upgradeLicense} />}
        msg={
          <UpgradeLicenseForm
            onSubmit={this.onSubmit}
            upgradableLicenses={upgradableLicenses}
            onPricingChange={pricing => {
              const hasSelection = pricing && pricing.value;
              this.setState({ creationAllowed: hasSelection });
            }}
          />
        }
        footer={
          <div>
            <button
              className="btn-primary btn-round"
              style={{ margin: '0 10px' }}
              onClick={this.closeModal}
            >
              <FormattedMessage {...apiKeyMessages.cancel} />
            </button>
            <button
              className="btn-secondary btn-round"
              style={{ margin: '0 10px' }}
              onClick={this.props.submitLicenseUpgradeRequest}
              disabled={!this.state.creationAllowed}
            >
              <FormattedMessage {...messages.upgrade} />
            </button>
          </div>
        }
        hide={this.closeModal}
      />
    );
  }

  renderWaitingDialog() {
    return (
      <Alert
        show={this.state.showModal}
        dialogClassName={`${ModalStyle.defaultDialog} ${apiKeyStyle.dialog}`}
        title={<FormattedMessage {...apiKeyMessages.modalHeaderLoading} />}
        msg={<FormattedMessage {...apiKeyMessages.pleaseWait} />}
        footer={<div />}
        // hide={this.closeModal} // prevent hiding of dialog when loading
      />
    );
  }

  renderSuccessDialog() {
    return (
      <Alert
        show={this.state.showModal}
        dialogClassName={`${ModalStyle.defaultDialog} ${apiKeyStyle.dialog}`}
        title={<FormattedMessage {...apiKeyMessages.modalHeaderSuccess} />}
        msg={<FormattedMessage {...messages.upgradeSuccessMessage} />}
        footer={
          <div>
            <button
              className="btn-primary btn-round"
              style={{ margin: '0 10px' }}
              onClick={this.closeModal}
            >
              <FormattedMessage {...apiKeyMessages.close} />
            </button>
          </div>
        }
        hide={this.closeModal}
      />
    );
  }

  renderErrorDialog() {
    return (
      <Alert
        show={this.state.showModal}
        dialogClassName={`${ModalStyle.defaultDialog} ${apiKeyStyle.dialog}`}
        title={<FormattedMessage {...apiKeyMessages.modalHeaderError} />}
        msg={
          this.state.errorMessage || (
            <FormattedMessage {...messages.upgradeErrorMessage} />
          )
        }
        footer={
          <div>
            <button
              className="btn-primary btn-round"
              style={{ margin: '0 10px' }}
              onClick={this.closeModal}
            >
              <FormattedMessage {...apiKeyMessages.close} />
            </button>
          </div>
        }
        hide={this.closeModal}
      />
    );
  }

  render() {
    const { apiKey, usage, licensesData } = this.props;

    if (!apiKey) {
      return (
        <div>
          <FormattedMessage {...messages.noKey} />
        </div>
      );
    }

    if (usage.loading || licensesData.loading) {
      return (
        <div>
          <FormattedMessage {...messages.loading} />
        </div>
      );
    }

    const { waitingForResponse, error, success } = this.state;
    let alert = null;
    if (waitingForResponse) {
      alert = this.renderWaitingDialog();
    } else if (success) {
      alert = this.renderSuccessDialog();
    } else if (error) {
      alert = this.renderErrorDialog();
    } else {
      alert = this.renderSelectionDialog();
    }

    const expireDate =
      apiKey.validities && apiKey.validities.length > 0
        ? moment(apiKey.validities[0].end, DB_DATE_FORMAT).format(
            'DD.MM.YYYY hh:mm',
          )
        : 'invalid';

    return (
      <div>
        <div>
          <table>
            <tr>
              <th>
                <FormattedMessage {...messages.name} />
                :&nbsp;
              </th>
              <td>{apiKey.name}</td>
            </tr>
            <tr>
              <th>
                <FormattedMessage {...messages.description} />
                :&nbsp;
              </th>
              <td>{apiKey.description}</td>
            </tr>
            <tr>
              <th>
                <FormattedMessage {...messages.key} />
                :&nbsp;
              </th>
              <td style={{ wordBreak: 'break-all' }}>{apiKey.token}</td>
            </tr>
            <tr>
              <th>
                <FormattedMessage {...messages.requestCounter} />
                :&nbsp;
              </th>
              <td>{`${
                usage && usage.monthlyCredits
                  ? usage.monthlyCredits.usedCredits
                  : 0
              }/${
                usage && usage.monthlyCredits
                  ? usage.monthlyCredits.totalCredits
                  : 0
              }`}</td>
            </tr>
            <tr>
              <th>
                <FormattedMessage {...messages.expirationDate} />
                :&nbsp;
              </th>
              <td>{expireDate}</td>
            </tr>
          </table>
          <div className={s.buttonBar}>
            <button
              className="btn-secondary btn-round btn-large"
              onClick={this.openModal}
            >
              <FormattedMessage {...messages.upgradeLicense} />
            </button>
          </div>
        </div>
        {alert}
      </div>
    );
  }
}

const monthlyCreditsQuery = gql`
  query monthlyCredits($apiToken: String!, $product: String!) {
    monthlyCredits(apiToken: $apiToken, product: $product) {
      totalCredits
      usedCredits
    }
  }
`;

const licensesQuery = gql`
  query licenses($product: String!) {
    licenses(product: $product) {
      id
      name
      description
      price
      duration
      credits
      enabled
      visible
    }
  }
`;

const sendLicenseUpgradeRequestMail = gql`
  mutation sendLicenseUpgradeRequestMail($data: LicenseUpgradeData) {
    sendLicenseUpgradeRequestMail(data: $data)
  }
`;

const mapDispatchToProps = dispatch => ({
  submitLicenseUpgradeRequest: () =>
    dispatch(submit('upgradeLicenseModalForm')),
});

export default compose(
  graphql(monthlyCreditsQuery, {
    name: 'usage',
    options: props => ({
      variables: {
        apiToken: props.apiKey ? `Bearer ${props.apiKey.token}` : null,
        product: props.product,
      },
      fetchPolicy: 'network-only',
    }),
    skip: props => !props.apiKey,
  }),
  graphql(licensesQuery, {
    name: 'licensesData',
    options: props => ({
      variables: {
        product: props.product,
      },
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(sendLicenseUpgradeRequestMail, {
    props: ({ mutate }) => ({
      sendLicenseUpgradeRequestMail: data =>
        mutate({
          variables: { data },
        }),
    }),
  }),
  connect(
    null,
    mapDispatchToProps,
  ),
)(withStyles(buttonStyle, apiKeyStyle, ModalStyle, s)(ApiKeyPanel));
