import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';

import s from './Pricing.scss';
import messages from './messages';
import landingPageMessages from '../LandingPage/messages';
import RequestDemoButton from '../RequestDemoButton';
import PricingCard, { POSITIVE_FEATURE } from './PricingCard';
import { REQUEST_DEMO_FORM_IDS } from '../../constants';

const packages = [
  {
    title: <span>Base</span>,
    type: 'base',
    features: [
      {
        text: <FormattedMessage {...messages.cloudApi} />,
        type: POSITIVE_FEATURE,
      },
    ],
  },
  {
    title: <span>Pro</span>,
    type: 'pro',
    features: [
      {
        text: <FormattedMessage {...messages.cloudApi} />,
        type: POSITIVE_FEATURE,
      },
      {
        text: <FormattedMessage {...messages.analyticsDashboard} />,
        type: POSITIVE_FEATURE,
      },
      {
        text: <FormattedMessage {...messages.integrationSupport} />,
        type: POSITIVE_FEATURE,
      },
      {
        text: <FormattedMessage {...messages.continousSupport} />,
        type: POSITIVE_FEATURE,
      },
    ],
  },
  {
    title: <span>Premium</span>,
    type: 'premium',
    features: [
      {
        text: <FormattedMessage {...messages.onPremise} />,
        type: POSITIVE_FEATURE,
      },
      {
        text: <FormattedMessage {...messages.customizedAnalyticsDashboard} />,
        type: POSITIVE_FEATURE,
      },
      {
        text: <FormattedMessage {...messages.fullIntegrationSupport} />,
        type: POSITIVE_FEATURE,
      },
      {
        text: <FormattedMessage {...messages.fullContinousSupport} />,
        type: POSITIVE_FEATURE,
      },
      {
        text: <FormattedMessage {...messages.interactionAnalysis} />,
        type: POSITIVE_FEATURE,
      },
    ],
  },
];

class GeizhalsDevjobs extends React.Component {
  render() {
    return (
      <div className={s.pricingPage}>
        <h1>
          <span className={s.danubeSpan}>danube.ai</span>{' '}
          <FormattedMessage {...messages.packages} />
        </h1>
        <div className={s.packagesContainer}>
          {packages.map((p, i) => (
            <div key={`package_${i + 1}`} className={s.pricingCardWrapper}>
              <PricingCard
                title={p.title}
                type={p.type}
                features={p.features}
              />
            </div>
          ))}
        </div>
        <div className={s.pricingTextContainer}>
          <FormattedMessage
            {...landingPageMessages.landingPageTitle}
            values={{ br: <br /> }}
          />
        </div>
        <div className={s.buttonContainer}>
          <RequestDemoButton
            isTopNav={false}
            localeToFormIdMap={REQUEST_DEMO_FORM_IDS}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(s)(GeizhalsDevjobs));
