import { defineMessages } from 'react-intl';

const messages = defineMessages({
  filterAndSort: {
    id: 'demo.recruiting.search.filterAndSort',
    defaultMessage: `Let's find the right candidate for your job opening`,
    description: 'label for field in search mask',
  },

  educationLevel1: {
    id: 'demo.recruiting.search.educationLevel1',
    defaultMessage: 'College',
    description: 'label for field in search mask',
  },
  educationLevel2: {
    id: 'demo.recruiting.search.educationLevel2',
    defaultMessage: 'Business School',
    description: 'label for field in search mask',
  },
  educationLevel3: {
    id: 'demo.recruiting.search.educationLevel3',
    defaultMessage: 'Bachelor',
    description: 'label for field in search mask',
  },
  educationLevel4: {
    id: 'demo.recruiting.search.educationLevel4',
    defaultMessage: 'Master',
    description: 'label for field in search mask',
  },

  salaryImportance: {
    id: 'demo.recruiting.search.salary.importance',
    defaultMessage: `
      How important is a low salary?
    `,
    description: 'label for field in search mask',
  },
  experienceOfficeImportance: {
    id: 'demo.recruiting.search.experienceOffice.importance',
    defaultMessage: `
      How important is office experience?
    `,
    description: 'label for field in search mask',
  },
  educationLevel: {
    id: 'demo.recruiting.search.educationLevel',
    defaultMessage: `
      What education level should your employee have?
    `,
    description: 'label for field in search mask',
  },
  msOfficeLevelImportance: {
    id: 'demo.recruiting.search.msOfficeLevel.importance',
    defaultMessage: `
      How important is MS Office experience?
    `,
    description: 'label for field in search mask',
  },
  germanLevelImportance: {
    id: 'demo.recruiting.search.germanLevel.importance',
    defaultMessage: `
      How important is it that your employee is fluent in German?
    `,
    description: 'label for field in search mask',
  },
  englishLevelImportance: {
    id: 'demo.recruiting.search.englishLevel.importance',
    defaultMessage: `
      How important is it that your employee is fluent in English?
    `,
    description: 'label for field in search mask',
  },
  additionalSkills: {
    id: 'demo.recruiting.search.additionalSkills',
    defaultMessage: `
      What additional skills should your employee have?
    `,
    description: 'label for field in search mask',
  },
});

export default messages;
