/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import HubspotForm_ from 'react-hubspot-form';
import { connect } from 'react-redux';

import { HUBSPOT_REGION, HUBSPOT_PORTAL_ID } from '../../constants';

class HubspotForm extends React.Component {
  static propTypes = {
    region: PropTypes.string,
    portalId: PropTypes.string,
    localeToFormIdMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onSubmit: PropTypes.func,
    locale: PropTypes.string.isRequired,
  };

  static defaultProps = {
    region: HUBSPOT_REGION,
    portalId: HUBSPOT_PORTAL_ID,
    onSubmit: null,
  };

  constructor(props) {
    super(props);

    this.hubSpotEventHandler = this.hubSpotEventHandler.bind(this);
  }

  componentDidMount() {
    window.addEventListener('message', this.hubSpotEventHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.hubSpotEventHandler);
  }

  hubSpotEventHandler(event) {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted'
    ) {
      if (
        this.props.onSubmit &&
        this.props.localeToFormIdMap &&
        event.data.id === this.props.localeToFormIdMap[this.props.locale]
      ) {
        this.props.onSubmit();
      }
    }
  }

  render() {
    const { region, portalId, localeToFormIdMap, locale } = this.props;

    return (
      <HubspotForm_
        region={region}
        portalId={portalId}
        formId={localeToFormIdMap ? localeToFormIdMap[locale] : null}
        onSubmit={this.hubSpotEventHandler}
        loading={<div>Loading...</div>}
      />
    );
  }
}

const mapStateToProps = state => ({
  locale: state && state.intl ? state.intl.locale : null,
});

export default connect(mapStateToProps)(HubspotForm);
