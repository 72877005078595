import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Panel } from 'react-bootstrap';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import s from './NewAnalysisCard.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import UploadFileForm from './UploadFileForm';
import DataPreSettings from './DataPreSettings';
import validationMessages from '../../ReduxForm/messages';

// TODO - once .csv files work specify it here as an option
const isTableData = filename => /\.(xls|xlsx)$/i.test(filename);

const validate = values => {
  const errors = {};

  if (values.datasetName && values.datasetName.length > 255) {
    errors.datasetName = (
      <FormattedMessage {...validationMessages.datasetName} />
    );
  }

  return errors;
};

class NewAnalysisCard extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    file: PropTypes.array, // eslint-disable-line
  };

  static defaultProps = {
    file: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.forceExpanded = this.forceExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  forceExpanded() {
    this.setState({ expanded: true });
  }

  render() {
    const file =
      this.props.file && this.props.file.length > 0 && this.props.file[0];

    const fileSelected = !!file;
    const correctFormat = fileSelected && isTableData(file.name);

    const caretImage = this.state.expanded
      ? '/caret_expanded.png'
      : '/caret.png';

    return (
      <form onSubmit={this.props.handleSubmit}>
        <Panel
          className={s.collapsiblePanel}
          expanded={this.state.expanded}
          onToggle={this.toggleExpanded}
        >
          <Panel.Heading className={s.panelHeading}>
            <div className={s.headingContent}>
              <h1>
                <FormattedMessage {...messages.newAnalysis} />
              </h1>
              <div className={s.buttonsContainer}>
                <button
                  type="button"
                  className="btn-dark"
                  onClick={this.forceExpanded}
                >
                  <FormattedMessage {...messages.start} />
                </button>
              </div>
            </div>
            <button
              type="button"
              className={s.expandButton}
              onClick={this.toggleExpanded}
            >
              <img src={caretImage} alt="" />
            </button>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body className={s.panelBody}>
              <div hidden={fileSelected}>
                <UploadFileForm />
              </div>
              <div hidden={!(fileSelected && !correctFormat)}>
                <UploadFileForm />
                <div className={s.errorMessageContainer}>
                  <FormattedMessage {...messages.wrongFileFormat} />
                </div>
              </div>
              <div hidden={!(fileSelected && correctFormat)}>
                <DataPreSettings />
              </div>
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  file: formValueSelector('excelUploadForm')(state, 'import.excel'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'excelUploadForm',
    validate,
    pure: false, // this is necessary to trigger form re-renders if the locale changes
  })(withStyles(s, buttonStyle)(NewAnalysisCard)),
);
