import React from 'react';

import PageProperties from 'components/PageProperties';
import Apait from 'components/Partners/Apait';
import routeMessages from 'routes/messages';

class ApaitWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.partnerApaitTitle,
            description: routeMessages.partnerApaitDescription,
            keywords: routeMessages.partnerApaitKeywords,
          }}
        />
        <Apait />
      </div>
    );
  }
}

export default ApaitWrapper;
