import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import RequiresAuthenticationOrResultsTableWidgetWrapper from 'components/Auth/RequiresAuthenticationOrResultsTableWidgetWrapper';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import ColumnImporters from '../ResultsTable/ColumnImporters';
import {
  applyRulesToData,
  formatForAlgorithm,
  isNumeric,
  calculateRowScore,
  getOldSortedIndices,
  generateRuleObject,
  generateRuleArray,
  getFish,
  getColumns,
  parseDataMatrix,
  getRowValuesFromTableValues,
  isAllMatched,
} from '../ResultsTable/ColumnHelper';
import { parseCalibration } from '../ResultsTable/CalibrationValidator';
import { LAST_USECASES_PAGE } from './../../constants';

const getInitialCalibration = gql`
  query getInitialCalibration($datasetId: String!) {
    getInitialCalibration(datasetId: $datasetId) {
      id
      userId
      columnSettings
      rules
      columnTypes
      columnScores
      updatedAt
      strategy
      impact
      mixFactor
      rawData {
        id
        uniqueIdColumn
      }
    }
  }
`;

const getDataset = gql`
  query getData($id: String!) {
    getData(id: $id) {
      id
      columnSettings
      rules
      columnTypes
      columnScores
      strategy
      impact
      mixFactor
      rawData {
        id
        uniqueIdColumn
        rawData
      }
    }
  }
`;

export const viewModes = {
  NORMAL: 'normal',
  COMPACT: 'compact',
  USECASE_WIDGET: 'usecase-widget',
};

class ResultsTable extends React.Component {
  static defaultProps = {
    resultTableValues: null,
    usecaseView: false,
    danubifyStep: LAST_USECASES_PAGE,
  };

  static contextTypes = {
    client: PropTypes.object,
  };

  static propTypes = {
    calibrationId: PropTypes.string.isRequired,
    datasetId: PropTypes.string.isRequired,
    createOrUpdateCalibratedData: PropTypes.func.isRequired,
    resetDataset: PropTypes.func.isRequired,
    startWorkerSync: PropTypes.func.isRequired,
    refreshTableValues: PropTypes.func.isRequired,
    resultTableValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    initialValues: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    usecaseView: PropTypes.bool,
    step: PropTypes.number.isRequired,
    me: PropTypes.shape({
      username: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    forceWidgetHorizontalScroll: PropTypes.bool,
    danubifyStep: PropTypes.number,
  };

  static defaultProps = {
    forceWidgetHorizontalScroll: false,
  };

  constructor(props) {
    super(props);
    this.onDanubifyDataset = this.onDanubifyDataset.bind(this);
    this.onReceiveNewColumnScores = this.onReceiveNewColumnScores.bind(this);
    this.checkIfInitialSortIsPossible = this.checkIfInitialSortIsPossible.bind(
      this,
    );
    this.updateScoreRanking = this.updateScoreRanking.bind(this);
    this.initInitialCalibration = this.initInitialCalibration.bind(this);
    this.onReset = this.onReset.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onDataSettingsApplied = this.onDataSettingsApplied.bind(this);
    this.onApplySettings = this.onApplySettings.bind(this);
    this.onApplySettingsAndSort = this.onApplySettingsAndSort.bind(this);
    this.setViewMode = this.setViewMode.bind(this);
    this.toggleNormalAndCompactView = this.toggleNormalAndCompactView.bind(
      this,
    );

    const rowScoreChanges = [];
    const columnLen = props.initialValues.columnImporters
      ? props.initialValues.columnImporters.length
      : 0;

    let rowLen = 0;
    if (
      props.initialValues.columnImporters &&
      props.initialValues.columnImporters[0].rows
    ) {
      rowLen = props.initialValues.columnImporters[0].rows.length;
    }

    for (let i = 0; i < rowLen; i += 1) {
      rowScoreChanges.push({ posChange: 0, valueDiff: 0 });
    }

    this.initialScoreCalculation = true;
    let viewMode = null;
    if (props.usecaseView) {
      viewMode = viewModes.USECASE_WIDGET;
    } else if (columnLen > 6 || rowLen > 14) {
      viewMode = viewModes.COMPACT;
    } else {
      viewMode = viewModes.NORMAL;
    }
    this.state = {
      columnValues: null,
      rowScores: [],
      rowScoreChanges,
      isDanubifying: false,
      hasChanged: false,
      isUsecaseDanubified: false,
      viewMode,
    };
  }

  componentDidMount() {
    this.initInitialCalibration();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.usecaseView &&
      nextProps.step === this.props.danubifyStep &&
      !this.state.isUsecaseDanubified
    ) {
      this.setState(
        {
          isUsecaseDanubified: true,
        },
        () => {
          this.onDanubifyDataset();
        },
      );
    } else if (
      nextProps.usecaseView &&
      nextProps.step === this.props.danubifyStep - 1 &&
      this.state.isUsecaseDanubified
    ) {
      this.setState(
        {
          isUsecaseDanubified: false,
        },
        () => {
          this.onReset();
        },
      );
    }
  }

  componentDidUpdate() {
    const { resultTableValues } = this.props;
    if (this.initialScoreCalculation && isAllMatched(resultTableValues)) {
      // initial score update
      const tableValues = this.state.columnValues || this.props.initialValues;
      const columnValues = JSON.parse(JSON.stringify(tableValues));
      const data = columnValues.columnImporters;
      const idColumn = data.findIndex(element => element.isUniqueIdColumn);
      // extract column scores and row values(fish)
      const rules = generateRuleArray(data);

      const dataMatrix = parseDataMatrix(data);
      const ruleAppliedData = applyRulesToData(
        dataMatrix,
        data, // used as column settings
        rules,
        idColumn,
      );
      const fish = getFish(ruleAppliedData, data, idColumn);
      const columns = getColumns(data);
      this.updateScoreRanking(
        columnValues,
        fish,
        columns,
        false,
        this.initialScoreCalculation,
        tableValues.settings.initialSort,
      );
      this.initialScoreCalculation = false;
    }
  }

  async onReset() {
    const { datasetId } = this.props;
    const queryResult = await this.props.resetDataset(datasetId);
    const initialCalibrationData = queryResult.data.resetDataset;
    this.state.hasChanged = false;
    // set to null to retrieve initial values instead to fill column importers with
    this.state.columnValues = null;
    // trigger initial score calculation afterwards
    this.initialScoreCalculation = true;
    this.props.refreshTableValues(initialCalibrationData);
  }

  onBack() {
    const { history } = this.props;
    history.push('dashboard');
  }

  async onApplySettings(index, fieldData) {
    const {
      skipped,
      fixed,
      name,
      columnScoreInput,
      columnType,
      oneEqualsTo,
      hundredPercentEqualsTo,
      treatEmptyAsZero,
    } = fieldData;

    const queryResult = await this.context.client.query({
      query: getDataset,
      variables: {
        id: this.props.calibrationId,
      },
      fetchPolicy: 'network-only',
    });
    const latestCalibration = parseCalibration(queryResult.data.getData);

    const rule = generateRuleObject(columnType, {
      hundredPercentEqualsTo,
      oneEqualsTo,
    });

    let changed = false;
    const newColumnSettings = latestCalibration.columnSettings.map(
      (column, curIndex) => {
        const newColumn = column;
        if (index === curIndex) {
          if (
            newColumn.name !== name ||
            newColumn.skipped !== skipped ||
            newColumn.fixed !== fixed ||
            newColumn.treatEmptyAsZero !== treatEmptyAsZero
          ) {
            changed = true;
          }
          newColumn.name = name;
          newColumn.skipped = skipped;
          newColumn.fixed = fixed;
          newColumn.treatEmptyAsZero = treatEmptyAsZero;
        }
        return newColumn;
      },
    );

    const newRules = latestCalibration.rules.map((element, curIndex) => {
      if (index === curIndex) {
        if (element && element[0]) {
          const lastRule = element[0];
          if (
            lastRule.ruleType !== rule.ruleType ||
            lastRule.ruleValue !== rule.ruleValue
          ) {
            changed = true;
          }
        }
        return [rule];
      }
      return element;
    });

    const newColumnTypes = latestCalibration.columnTypes.map(
      (element, curIndex) => {
        let newType = element;
        if (index === curIndex) {
          if (newType !== columnType) {
            changed = true;
          }
          newType = columnType;
        }
        return newType;
      },
    );

    const columnScore = parseFloat(columnScoreInput);
    const newColumnScores = latestCalibration.columnScores.map(
      (element, curIndex) => {
        let newScore = element;
        if (index === curIndex) {
          if (newScore !== columnScore) {
            changed = true;
          }
          newScore = columnScore;
        }
        return newScore;
      },
    );
    const patch = {
      columnTypes: btoa(JSON.stringify(newColumnTypes)),
      rules: btoa(JSON.stringify(newRules)),
      columnSettings: btoa(JSON.stringify(newColumnSettings)),
      columnScores: btoa(JSON.stringify(newColumnScores)),
    };
    await this.props.createOrUpdateCalibratedData(
      this.props.calibrationId,
      patch,
    );

    if (changed) {
      this.setState({ hasChanged: true });
    }
  }

  async onApplySettingsAndSort(index, fieldData) {
    await this.onApplySettings(index, fieldData);
    await this.onReceiveNewColumnScores();
  }

  async onDataSettingsApplied(newSettings) {
    const { resultTableValues } = this.props;
    if (!resultTableValues) return;

    // compare new settings with currently saved settings
    const queryResult = await this.context.client.query({
      query: getDataset,
      variables: {
        id: this.props.calibrationId,
      },
      fetchPolicy: 'network-only',
    });
    const calibration = queryResult.data.getData;
    if (
      calibration.impact === newSettings.impact &&
      calibration.mixFactor === newSettings.mixFactor &&
      calibration.strategy === newSettings.strategy
    ) {
      // no changes
      return;
    }

    const settings = {
      strategy: newSettings.strategy,
      impact: newSettings.impact,
      mixFactor: newSettings.mixFactor,
    };
    await this.props.createOrUpdateCalibratedData(calibration.id, settings);
    this.setState({ hasChanged: true });
  }

  async onReceiveNewColumnScores(newColumnScores) {
    const queryResult = await this.context.client.query({
      query: getDataset,
      variables: {
        id: this.props.calibrationId,
      },
      fetchPolicy: 'network-only',
    });
    const calibration = parseCalibration(queryResult.data.getData);
    if (this.props.usecaseView) {
      calibration.columnScores = newColumnScores;
      calibration.columnScores.unshift(0);
    }
    // copy table values and update column scores and column score input in settings
    const { resultTableValues } = this.props;
    const columnValues = JSON.parse(JSON.stringify(resultTableValues));
    const columnScores = [];
    resultTableValues.columnImporters.forEach((column, x) => {
      let columnScore = isNumeric(calibration.columnScores[x])
        ? parseFloat(calibration.columnScores[x])
        : 0;
      if (columnScore === 0) {
        // column maybe skipped in calibration, take last value instead
        columnScore = column.columnScore;
      }
      columnScores.push(columnScore);
      columnValues.columnImporters[x].columnScore = columnScore;
      columnValues.columnImporters[x].columnScoreInput = columnScore;
    });

    // extract column scores and row values(fish) for score ranking
    // use table values, as the ordering is necessary to compare rank changes
    const dataMatrix = parseDataMatrix(resultTableValues.columnImporters);
    const ruleAppliedData = applyRulesToData(
      dataMatrix,
      calibration.columnSettings,
      calibration.rules,
      calibration.rawData.uniqueIdColumn,
    );
    const fish = getFish(
      ruleAppliedData,
      calibration.columnSettings,
      calibration.rawData.uniqueIdColumn,
    );
    this.updateScoreRanking(columnValues, fish, columnScores, true);
  }

  async onDanubifyDataset() {
    this.setState({ isDanubifying: true });
    const queryResult = await this.context.client.query({
      query: getDataset,
      variables: {
        id: this.props.calibrationId,
      },
      fetchPolicy: 'network-only',
    });

    this.setState({ hasChanged: false });

    const calibration = parseCalibration(queryResult.data.getData);
    const algorithmData = formatForAlgorithm(calibration);

    // store result in db if not danubifying in usecase view
    let calibratedDataId = null;
    if (!this.props.usecaseView) {
      algorithmData.storeResult = true;
      calibratedDataId = this.props.calibrationId;
    }

    const startWorkerSyncResult = await this.props.startWorkerSync({
      data: algorithmData,
      calibratedDataId,
    });
    if (startWorkerSyncResult && startWorkerSyncResult.data) {
      await this.onReceiveNewColumnScores(
        startWorkerSyncResult.data.startWorkerSync.newColumnScores,
      );
    }
    this.setState({ isDanubifying: false });
  }

  setViewMode({ viewMode }) {
    this.setState({ viewMode });
  }

  toggleNormalAndCompactView() {
    if (this.state.viewMode === viewModes.NORMAL) {
      this.setViewMode({ viewMode: viewModes.COMPACT });
    } else {
      this.setViewMode({ viewMode: viewModes.NORMAL });
    }
  }

  updateScoreRanking(
    columnValues,
    rowValues,
    columnScores,
    updateRowScores,
    initialScoreCalculation = false,
    initialSort = true,
  ) {
    const { resultTableValues } = this.props;
    if (!isAllMatched(resultTableValues)) {
      return;
    }
    const previousRowValues = getRowValuesFromTableValues(resultTableValues);
    const scores = [];
    rowValues.forEach(row => {
      scores.push(calculateRowScore(row, columnScores));
    });
    const oldIndices = getOldSortedIndices(scores);
    const rowScoreChanges = [];

    oldIndices.forEach((oldIndex, index) => {
      if (updateRowScores) {
        const posChange = oldIndex - index;
        const valueDiff = scores[oldIndex] - previousRowValues[oldIndex];
        rowScoreChanges.push({ posChange, valueDiff });
      } else {
        rowScoreChanges.push({ posChange: 0, valueDiff: 0 });
      }
    });

    let rowScores;
    const newColumnValues = JSON.parse(JSON.stringify(columnValues));
    if (initialScoreCalculation && !initialSort) {
      rowScores = scores;
    } else {
      rowScores = scores.sort((a, b) => (a < b ? 1 : -1));
      newColumnValues.columnImporters.forEach(column => {
        // create new array containing the sorted values
        const sortedColumn = [];
        oldIndices.forEach((oldIndex, index) => {
          // copy element to new index calculated by score order
          sortedColumn[index] = column.rows[oldIndex];
        });
        column.rows = sortedColumn; // eslint-disable-line no-param-reassign
      });
    }
    this.setState({
      columnValues: newColumnValues,
      rowScoreChanges,
      rowScores,
    });
  }

  // check if dataset is new(no calibrations) and all columns are matched
  async checkIfInitialSortIsPossible() {
    const { resultTableValues } = this.props;
    let matched = true;
    resultTableValues.columnImporters.forEach(column => {
      if (!column.matched) {
        matched = false;
      }
    });

    if (!matched) {
      return false;
    }

    if (this.initialCalibration.id === this.props.calibrationId) {
      return true;
    }

    return false;
  }

  async initInitialCalibration() {
    const queryResult = await this.context.client.query({
      query: getInitialCalibration,
      variables: {
        datasetId: this.props.datasetId,
      },
      fetchPolicy: 'network-only',
    });

    this.initialCalibration = queryResult.data.getInitialCalibration;
  }

  render() {
    const {
      columnValues,
      rowScoreChanges,
      rowScores,
      hasChanged,
      viewMode,
    } = this.state;
    return (
      <div>
        <ColumnImporters
          initialValues={columnValues || this.props.initialValues}
          values={columnValues || this.props.initialValues}
          onSubmit={this.onDanubifyDataset}
          rowScores={rowScores}
          rowScoreChanges={rowScoreChanges}
          onReset={this.onReset}
          onBack={this.onBack}
          onApply={this.onApplySettings}
          onApplyAndSort={this.onApplySettingsAndSort}
          onDataSettingsApplied={this.onDataSettingsApplied}
          hasChanged={hasChanged}
          isDanubifying={this.state.isDanubifying}
          usecaseView={this.props.usecaseView}
          viewMode={viewMode}
          onToggleNormalAndCompactView={this.toggleNormalAndCompactView}
          forceWidgetHorizontalScroll={this.props.forceWidgetHorizontalScroll}
        />
      </div>
    );
  }
}

const createOrUpdateCalibratedData = gql`
  mutation createOrUpdateCalibratedData(
    $id: String
    $patch: CalibratedDataInput!
  ) {
    createOrUpdateCalibratedData(id: $id, patch: $patch) {
      id
      rawDataId
      userId
      columnSettings
      rules
      columnTypes
      columnScores
      updatedAt
      strategy
      mixFactor
      impact
      rawData {
        id
        name
        rawData
        initialValueRow
        uniqueIdColumn
      }
    }
  }
`;

const resetDataset = gql`
  mutation resetDataset($id: String!) {
    resetDataset(id: $id) {
      id
      userId
      columnSettings
      rules
      columnTypes
      columnScores
      updatedAt
      strategy
      impact
      mixFactor
      rawData {
        id
        name
        rawData
        uniqueIdColumn
        initialValueRow
        initialSort
      }
    }
  }
`;

const startWorkerSyncMutation = gql`
  mutation startWorkerSync(
    $data: CalibrationInput!
    $calibratedDataId: String
  ) {
    startWorkerSync(data: $data, calibratedDataId: $calibratedDataId) {
      newColumnScores
    }
  }
`;

const getData = gql`
  query userQuery {
    me {
      id
      username
    }
    getLatestCalibrations {
      id
      rawDataId
      userId
      columnSettings
      rules
      columnTypes
      columnScores
      updatedAt
      strategy
      mixFactor
      impact
      rawData {
        id
        name
        rawData
        initialValueRow
        uniqueIdColumn
      }
    }
  }
`;

const mapStateToProps = store => ({
  step: store.usecases.step,
  resultTableValues: getFormValues('columns')(store),
});

export default compose(
  graphql(getData, {
    name: 'data',
  }),
  graphql(createOrUpdateCalibratedData, {
    props: ({ mutate }) => ({
      createOrUpdateCalibratedData: (id, patch) =>
        mutate({
          variables: { id, patch },
          update: (store, { data }) => {
            // Read the data from our cache for this query.
            const updatedCalibration = data.createOrUpdateCalibratedData;
            const queryData = store.readQuery({ query: getData });

            // update changed calibration
            queryData.getLatestCalibrations = queryData.getLatestCalibrations.map(
              calibration =>
                calibration.id === updatedCalibration.id
                  ? updatedCalibration
                  : calibration,
            );

            // Write our data back to the cache.
            store.writeQuery({ query: getData, data: queryData });
          },
        }),
    }),
  }),
  graphql(resetDataset, {
    props: ({ mutate }) => ({
      resetDataset: id =>
        mutate({
          variables: { id },
        }),
    }),
  }),
  graphql(startWorkerSyncMutation, {
    props: ({ mutate }) => ({
      startWorkerSync: ({ data, calibratedDataId }) =>
        mutate({
          variables: { data, calibratedDataId },
        }),
    }),
  }),
)(
  RequiresAuthenticationOrResultsTableWidgetWrapper(
    connect(mapStateToProps)(ResultsTable),
  ),
);
