import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from '../../../styles/SearchMaskForm.scss';
import buttonStyle from '../../../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import layoutStyle from '../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import renderField from '../../../../components/ReduxForm/renderField';
import messages from './messages';
import { formatSliderLabel } from '../../../util';

const FORM_NAME = 'recrutingPersonsSearchMask';

const validate = formValues => {
  const errors = {};

  if (!('comparisonLink' in formValues.filter)) {
    errors.filter = { comparisonLink: 'Required' };
  }

  return errors;
};

class SearchMaskForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    const { handleSubmit, errors, intl } = this.props;

    return (
      <div className={s.searchMaskContainer}>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <Row>
              <Col xs={12} style={{ margin: '10px 0 10px 0' }}>
                <h1>
                  <FormattedMessage {...messages.filterAndSort} />
                </h1>
              </Col>
              <Col md={6} xs={12}>
                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.comparisonLink} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.comparisonLink"
                      name="filter.comparisonLink"
                      component={renderField.renderInput}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.priceImportance} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.priceImportance"
                      name="filter.priceImportance"
                      formName={FORM_NAME}
                      interval={{ min: 0, max: 1 }}
                      stepSize={0.25}
                      labelFormatter={formatSliderLabel}
                      component={renderField.renderSlider}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.memoryImportance} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.memoryImportance"
                      name="filter.memoryImportance"
                      formName={FORM_NAME}
                      interval={{ min: 0, max: 1 }}
                      stepSize={0.25}
                      labelFormatter={formatSliderLabel}
                      component={renderField.renderSlider}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>
              </Col>
              <Col md={6} xs={12}>
                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.displayZollPreference} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.displayZollPreference"
                      name="filter.displayZollPreference"
                      component={renderField.renderSelectAlt}
                      options={[
                        {
                          value: 'preferBig',
                          label: intl.formatMessage(messages.preferBig),
                        },
                        /*
                        {
                          value: 'preferMedium',
                          label: intl.formatMessage(messages.preferMedium),
                        },
                        */
                        {
                          value: 'preferSmall',
                          label: intl.formatMessage(messages.preferSmall),
                        },
                      ]}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.cameraImportance} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.cameraImportance"
                      name="filter.cameraImportance"
                      formName={FORM_NAME}
                      interval={{ min: 0, max: 1 }}
                      stepSize={0.25}
                      labelFormatter={formatSliderLabel}
                      component={renderField.renderSlider}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.batteryImportance} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.batteryImportance"
                      name="filter.batteryImportance"
                      formName={FORM_NAME}
                      interval={{ min: 0, max: 1 }}
                      stepSize={0.25}
                      labelFormatter={formatSliderLabel}
                      component={renderField.renderSlider}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className={s.buttonbar}>
              <button
                type="submit"
                className="btn-secondary btn-round btn-large"
              >
                danubify
              </button>
              {errors.length === 0 ? null : (
                <ul style={{ padding: '0', listStyleType: 'none' }}>
                  {errors.map(err => (
                    <li key={err} className="bg-danger">
                      {err}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(injectIntl(withStyles(buttonStyle, layoutStyle, s)(SearchMaskForm)));
