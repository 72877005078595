import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

import {
  pageKeys,
  componentKeys,
  eMailKeys,
  otherKeys,
  aggregatedKeys,
} from '../Analytics';
import {
  defaultDataSetOptions,
  allKeyDataDataSetOptions,
} from './analyticsStyles';

class FunnelChart extends React.Component {
  static propTypes = {
    funnel: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        logs: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            count: PropTypes.number,
          }),
        ),
      }),
    ).isRequired,
    includedLogs: PropTypes.arrayOf(PropTypes.string),
    aggregatedLogs: PropTypes.arrayOf(PropTypes.object),
    logHashesOptions: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    includedLogs: null,
    aggregatedLogs: null,
    logHashesOptions: [],
  };

  constructor(props) {
    super(props);

    this.prepareData = this.prepareData.bind(this);
  }

  prepareData() {
    const {
      funnel,
      includedLogs,
      aggregatedLogs,
      logHashesOptions,
    } = this.props;

    const labels = [];
    const datasetsSortedByKey = {};

    const logHashKeys = logHashesOptions.map(log => log.value);

    const allKeys = [
      ...Object.values(pageKeys),
      ...Object.values(componentKeys),
      ...Object.values(eMailKeys),
      ...Object.values(otherKeys),
      ...logHashKeys,
    ];

    allKeys
      .filter(key => !includedLogs || includedLogs.includes(key))
      .forEach(key => {
        datasetsSortedByKey[key] = [];
      });

    Object.values(aggregatedKeys)
      .filter(
        key =>
          aggregatedLogs && aggregatedLogs.map(log => log.key).includes(key),
      )
      .forEach(key => {
        datasetsSortedByKey[key] = [];
      });

    funnel.forEach((funnelEntry, index) => {
      labels.push(moment(funnelEntry.date, 'YYYY-MM-DD').format('DD.MM.YYYY'));

      // init all entries with 0
      Object.keys(datasetsSortedByKey).forEach(key => {
        datasetsSortedByKey[key][index] = 0;
      });

      // override with actual values
      funnelEntry.logs.forEach(log => {
        datasetsSortedByKey[log.key][index] = log.count;
      });
    });

    const datasets = Object.keys(datasetsSortedByKey).map(key => {
      if (logHashKeys.includes(key)) {
        const logHashOption = logHashesOptions.filter(
          log => log.value === key,
        )[0];
        return {
          ...defaultDataSetOptions,
          label: key,
          borderColor: logHashOption.backgroundColor,
          fontColor: logHashOption.fontColor,
          backgroundColor: logHashOption.backgroundColor,
          data: datasetsSortedByKey[key],
        };
      }

      return {
        ...defaultDataSetOptions,
        ...allKeyDataDataSetOptions[key],
        backgroundColor: allKeyDataDataSetOptions[key]
          ? allKeyDataDataSetOptions[key].borderColor
          : null,
        data: datasetsSortedByKey[key],
      };
    });

    return { labels, datasets };
  }

  render() {
    const data = this.prepareData();

    return (
      <Line
        data={data}
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
        height={100}
      />
    );
  }
}

export default FunnelChart;
