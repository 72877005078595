import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
  formValueSelector,
  change,
  untouch,
} from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';

import render from 'components/ReduxForm/renderField';

import s from './ApiKey.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';

const numberWithCommas = x => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

const validate = (values, props) => {
  const errors = {};
  const { product } = values;
  const { ownedProducts, intl } = props;
  if (product && product.value) {
    if (product.value && ownedProducts.includes(product.value.toUpperCase())) {
      errors.product = intl.formatMessage(messages.productAlreadyOwned);
    }
  }
  return errors;
};

class ApiModalForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    predictionLicenses: PropTypes.arrayOf(PropTypes.object),
    recommendationLicenses: PropTypes.arrayOf(PropTypes.object),
    ownedProducts: PropTypes.arrayOf(PropTypes.string),
    onPricingChange: PropTypes.func,
    selectedProduct: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    selectedPricing: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    clearPricing: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    predictionLicenses: [],
    recommendationLicenses: [],
    ownedProducts: [],
    onPricingChange: () => {},
    errors: [],
  };

  render() {
    const {
      handleSubmit,
      predictionLicenses,
      recommendationLicenses,
      onPricingChange,
      selectedProduct,
      selectedPricing,
      clearPricing,
    } = this.props;

    let pricingOptions = [];
    let details = null;

    if (selectedProduct) {
      if (selectedProduct.value === 'Prediction' && predictionLicenses) {
        pricingOptions = predictionLicenses
          .sort((l1, l2) => l1.price - l2.price)
          .map(l => ({
            value: l.id,
            label: l.name,
          }));
      } else if (
        selectedProduct.value === 'Recommendation' &&
        recommendationLicenses
      ) {
        pricingOptions = recommendationLicenses
          .sort((l1, l2) => l1.price - l2.price)
          .map(l => ({
            value: l.id,
            label: l.name,
          }));
      }

      if (selectedPricing && selectedPricing.value) {
        let selectedPricingData = [
          ...predictionLicenses,
          ...recommendationLicenses,
        ].filter(l => l.id === selectedPricing.value);

        selectedPricingData =
          selectedPricingData.length >= 0 ? selectedPricingData[0] : null;

        if (selectedPricingData) {
          details = {
            isTrial: selectedPricingData.price === 0,
            name: selectedPricing.label,
            requests: `${selectedPricingData.credits} requests`,
            price: `${selectedPricingData.price} Euro`,
            rate: `(${Math.round(
              1000 * (selectedPricingData.price / selectedPricingData.credits),
            ) / 1000}/request)`,
          };
        }
      }
    }

    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <fieldset>
          <div className={s.fieldContainer}>
            <FormattedMessage {...messages.modalApiHeader} />
            <Field
              id="product"
              name="product"
              component={render.renderSelectAlt}
              isMulti={false}
              options={[
                {
                  value: 'Prediction',
                  label: 'danube Prediction',
                },
                {
                  value: 'Recommendation',
                  label: 'danube Recommendation',
                },
              ]}
              onChange={() => {
                clearPricing();
                onPricingChange(null);
              }}
            />
            <div className={s.spacerLine} />
          </div>
          <div className={s.fieldContainer}>
            <FormattedMessage {...messages.modalPricingHeader} />
            <Field
              id="pricing"
              name="pricing"
              component={render.renderSelectAlt}
              isMulti={false}
              options={pricingOptions}
              onChange={onPricingChange}
            />
            <div className={s.spacerLine} />
          </div>
          {details && (
            <div className={s.selectionInfo}>
              <div className={s.infoHeader}>
                <FormattedMessage {...messages.modalPricingInfoHeader} />*
              </div>
              <ul>
                <li>{numberWithCommas(details.requests)}</li>
                <li>
                  {numberWithCommas(details.price)}{' '}
                  {numberWithCommas(details.rate)}
                </li>
              </ul>
              <div className={s.netPriceNote}>
                {details.isTrial ? (
                  <span>
                    * <FormattedMessage {...messages.oneTimeNote} />
                  </span>
                ) : (
                  <span>
                    * <FormattedMessage {...messages.netPriceNote} />
                  </span>
                )}
              </div>
            </div>
          )}
        </fieldset>
      </form>
    );
  }
}

const selector = formValueSelector('apiModalForm');

const mapStateToProps = state => ({
  selectedProduct: selector(state, 'product'),
  selectedPricing: selector(state, 'pricing'),
});

const mapDispatchToProps = dispatch => ({
  clearPricing: () => {
    dispatch(change('apiModalForm', 'pricing', null));
    dispatch(untouch('apiModalForm', 'pricing'));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  injectIntl(
    reduxForm({
      form: 'apiModalForm',
      pure: false, // this is necessary to trigger form re-renders if the locale changes
      validate,
    })(withStyles(s)(ApiModalForm)),
  ),
);
