import React from 'react';

import PageProperties from 'components/PageProperties';
import O2Demo from 'demos/o2/components/LandingPage';
import routeMessages from 'routes/messages';

class O2DemoWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.demoPageTitle,
            description: routeMessages.demoDescription,
            keywords: routeMessages.demoKeywords,
          }}
        />
        <O2Demo />
      </div>
    );
  }
}

export default O2DemoWrapper;
