import { defineMessages } from 'react-intl';

const messages = defineMessages({
  apiHeader: {
    id: 'api.header',
    defaultMessage: 'danube.ai API',
    description: 'Header for api docs.',
  },
  preliminaryText1: {
    id: 'api.preliminary.text1',
    defaultMessage: `
      danube.ai provides a GraphQL API (cf. <a href="https://graphql.org/" target="_blank">https://graphql.org/</a>)
      at <i>https://api.danube.ai/graphql</i>. Contrary to traditional APIs like REST, every request is sent as a POST request
      containing a JSON body that specifies if the current request is a read (<i>query</i>) or write (<i>mutation</i>) operation.
    `,
    description: 'Preliminary text.',
  },
  preliminaryText2: {
    id: 'api.preliminary.text2',
    defaultMessage: `
      In order to use an endpoint, you need a danube.ai API key and set it in each POST request's header:
    `,
    description: 'Preliminary text.',
  },
  preliminaryText3: {
    id: 'api.preliminary.text3',
    defaultMessage: `
      You can contact us to request an API key.
    `,
    description: 'Preliminary text.',
  },
  endpointsHeader: {
    id: 'api.endpoints.header',
    defaultMessage: 'Endpoints',
    description: 'Endpoints subheader.',
  },

  request: {
    id: 'api.request',
    defaultMessage: 'Request',
    description: 'text for api page',
  },
  response: {
    id: 'api.response',
    defaultMessage: 'Response',
    description: 'text for api page',
  },

  setRulesRequest: {
    id: 'api.setRulesRequest',
    defaultMessage: 'setRules request',
    description: 'text for api page',
  },
  setRulesResponse: {
    id: 'api.setRulesResponse',
    defaultMessage: 'setRules response',
    description: 'text for api page',
  },
  setRulesExample: {
    id: 'api.setRulesExample',
    defaultMessage: 'setRules example',
    description: 'text for api page',
  },
  danubePredictionRequest: {
    id: 'api.danubePredictionRequest',
    defaultMessage: 'danubePrediction request',
    description: 'text for api page',
  },
  danubePredictionResponse: {
    id: 'api.danubePredictionResponse',
    defaultMessage: 'danubePrediction response',
    description: 'text for api page',
  },
  danubePredictionExample: {
    id: 'api.danubePredictionExample',
    defaultMessage: 'danubePrediction example',
    description: 'text for api page',
  },
  postText: {
    id: 'api.postText',
    defaultMessage: `
      <b>POST</b> <i>https://api.danube.ai/graphql</i>
    `,
    description: 'text for api page',
  },
  returns: {
    id: 'api.returns',
    defaultMessage: 'Returns',
    description: 'text for api page',
  },
  requiredSteps: {
    id: 'api.requiredSteps',
    defaultMessage: 'Required steps',
    description: 'text for api page',
  },
  optionalSteps: {
    id: 'api.optionalSteps',
    defaultMessage: 'Optional steps',
    description: 'text for api page',
  },

  danubePredictionOverviewText1: {
    id: 'api.danubePredictionOverviewText1',
    defaultMessage: `
      The prediction API provides an ai-powered sorting of multi-dimensional data. As a first request,
      you define a set of rules describing the structure of your data (e.g. numerical or textual). Then,
      you can send the data in form of a JSON-list. As a return value, you obtain the sorted list,
      predicting the matching quota.
    `,
    description: 'text for api page',
  },
  danubePredictionOverviewText2: {
    id: 'api.danubePredictionOverviewText2',
    defaultMessage: `
      First you call the {setRules} endpoint to save your rules-set. Avoid repetitive calls.
    `,
    description: 'text for api page',
  },
  danubePredictionOverviewText3: {
    id: 'api.danubePredictionOverviewText3',
    defaultMessage: `
      After that you can call the {danubePrediction} endpoint to let danube.ai sort your data.
    `,
    description: 'text for api page',
  },
  danubePredictionOverviewText4: {
    id: 'api.danubePredictionOverviewText4',
    defaultMessage: `
      You can call the {getRules} endpoint to get a list of all your created rules-sets.
    `,
    description: 'text for api page',
  },

  danubePredictionText1: {
    id: 'api.danubePredictionText1',
    defaultMessage: `
      A rules-set can be specified via the <i>setRules</i> endpoint of the danube.ai cloud API. With a <i>setRules</i>
      request you can save a rules-set on our side so you just have to provide its id on any of your following danube
      prediction API requests. This needs to be done only once or when your rules have changed. Avoid repetitive requests.
    `,
    description: 'text for api page',
  },
  danubePredictionText2: {
    id: 'api.danubePredictionText2',
    defaultMessage: `
      Below, the <i>setRules</i> request's structure is shown.
    `,
    description: 'text for api page',
  },
  danubePredictionText3: {
    id: 'api.danubePredictionText3',
    defaultMessage: `
      The <i>query</i> parameter specifies which GraphQL endpoint to call (see <a href="{exampleLink}" target="_blank" rel="noopener noreferrer">example</a>).
    `,
    description: 'text for api page',
  },
  danubePredictionText3_1: {
    id: 'api.danubePredictionText3_1',
    defaultMessage: `
      The <i>variables</i> have the following meanings:
    `,
    description: 'text for api page',
  },
  danubePredictionText4: {
    id: 'api.danubePredictionText4',
    defaultMessage: `
      <ul>
        <li>
          <b>id:</b> The id of your rules-set. You can have multiple rules-sets with different ids.
        </li>
        <li>
          <b>override:</b> If true, an existing entry with the same id will be overridden.
        </li>
        <li>
          <b>rules:</b> A list of <i>Rules</i>.
        </li>
      </ul>
    `,
    description: 'text for api page',
  },
  danubePredictionText5: {
    id: 'api.danubePredictionText5',
    defaultMessage: `
      A <i>Rule</i> has the following structure:
    `,
    description: 'text for api page',
  },
  danubePredictionText6: {
    id: 'api.danubePredictionText6',
    defaultMessage: `
      <ul>
        <li>
          <b>property:</b> The name of the property this rules is used for.
        </li>
        <li>
          <b>type:</b> The type of this rule. Possible types are:
          <ul>
            <li>
              <b>"PERCENTAGE":</b> This rule maps the values of the corresponding property from
              [minRuleValue, maxRuleValue] to [0, 1].
              <br/>
              - If no <i>maxRuleValue</i> is specified, the maximum of all values of this column will be used.
              <br/>
              - If no <i>minRuleValue</i> is specified and <i>clamping</i> is false or not specified, 0 will be used.
              <br/>
              - If no <i>minRuleValue</i> is specified and <i>clamping</i> is true, the minimum of all values of this column will be used.
              <br/>
            </li>
            <li>
              <b>"INVERSE_PERCENTAGE":</b> This rule works like the <i>"PERCENTAGE"</i> rule, but inverted (i.e. mapping from
              [minRuleValue, maxRuleValue] to [1, 0]).
            </li>
            <li>
              <b>"PERCENTAGE_SINE":</b> Similar to the <i>"PERCENTAGE"</i> rule, this rule maps the values of the corresponding property from
              [minRuleValue, maxRuleValue] to [0, 1]. Additionally, a sine function is used on the normalized values to make the mapping
              non-linear.
              <br/>
            </li>
            <li>
              <b>"INVERSE_PERCENTAGE_SINE":</b> This rule works like the <i>"PERCENTAGE_SINE"</i> rule, but inverted (i.e. mapping from
              [minRuleValue, maxRuleValue] to [1, 0]).
            </li>
            <li>
              <b>"EQUALS":</b> This rule calculates the score-difference between a row's property's
              value and the property's value in the <i>searchData</i> of a <i>danubePrediction</i> call,
              based on the <i>equalityScores</i> in the rule. A lower score-difference gets a higher
              ranking than a higher score-difference.
            </li>
            <li>
              <b>"OVERLAP_LOCAL":</b> This rule calculates the amount of overlap between the values of the property
              in the <i>searchData</i> of a <i>danubePrediction</i> call and the values of a row's property.
            </li>
            <li>
              <b>"OVERLAP_GLOBAL":</b> This rule calculates the amount of overlap between the values of the property
              in the <i>searchData</i> of a <i>danubePrediction</i> call and the values in a row's property. Additionally,
              the overlaps are normalized by the globally highest absolute overlap.
            </li>
          </ul>
        </li>
        <li>
          <b>equalityScores:</b> A list of <i>Equality Scores</i> (Only used by rules of type <i>"EQUALS"</i>).
          See below for the structure of an <i>Equality Scores</i>. 
        </li>
        <li>
          <b>maxRuleValue:</b> The upper bound of the source value range (Only used by rules of type <i>"PERCENTAGE"</i>
          or <i>"INVERSE_PERCENTAGE"</i>).
        </li>
        <li>
          <b>minRuleValue:</b> The lower bound of the source value range (Only used by rules of type <i>"PERCENTAGE"</i>
          or <i>"INVERSE_PERCENTAGE"</i>).
        </li>
        <li>
          <b>clamping:</b> Specifies whether clamping should be applied or not, if no <i>minRuleValue</i> is specified
          (Only used by rules of type <i>"PERCENTAGE"</i> or <i>"INVERSE_PERCENTAGE"</i>).
        </li>
      </ul>
    `,
    description: 'text for api page',
  },
  danubePredictionText6dot5: {
    id: 'api.danubePredictionText6.5',
    defaultMessage: `
      Formulas used by the "PERCENTAGE" rule:
    `,
    description: 'text for api page',
  },
  danubePredictionText7: {
    id: 'api.danubePredictionText7',
    defaultMessage: `
      An <i>Equality Score</i> has the following structure:
    `,
    description: 'text for api page',
  },
  danubePredictionText8: {
    id: 'api.danubePredictionText8',
    defaultMessage: `
      <ul>
        <li>
          <b>value:</b> A possible value of the property.
        </li>
        <li>
          <b>score:</b> The corresponding score for this value.
        </li>
      </ul>
    `,
    description: 'text for api page',
  },
  danubePredictionText9: {
    id: 'api.danubePredictionText9',
    defaultMessage: `
      The <i>setRules</i> endpoint either returns true if the rules-set was successfully saved or false if not.
    `,
    description: 'text for api page',
  },
  danubePredictionText10: {
    id: 'api.danubePredictionText10',
    defaultMessage: `
      Once a rules-set is defined you can start using the <i>danubePrediction</i> endpoint of the danube.ai cloud API.
      <i>danubePrediction</i> will send your dataset to danube.ai and returns the evaluation results including a new
      scoring for columns and rows and a list of derived percentage matches per row.
    `,
    description: 'text for api page',
  },
  danubePredictionText11: {
    id: 'api.danubePredictionText11',
    defaultMessage: `
      Below, the <i>danubePrediction</i> request's structure is shown.
    `,
    description: 'text for api page',
  },
  danubePredictionText11_1: {
    id: 'api.danubePredictionText11_1',
    defaultMessage: `
      The <i>PredictionInputData</i> has the following structure:
    `,
    description: 'text for api page',
  },
  danubePredictionText12: {
    id: 'api.danubePredictionText12',
    defaultMessage: `
      <ul>
        <li>
          <b>rulesId:</b> The id of a rules-set.
        </li>
        <li>
          <b>data:</b> A stringified Json-Array, holding all data elements (rows) as objects. You can see an example on how to encode your data as a json array <a href="/api/formats/prediction-data" target="_blank" rel="noopener noreferrer">here</a>.
        </li>
        <li>
          <b>searchData:</b> A stringified Json-Object with the same structure as a data element.
        </li>
        <li>
          <b>initialColumnScores:</b> A list of initial <i>Column Scores</i>.
        </li>
        <li>
          <b>strategy:</b> Defines the way data attributes are treated by the algorithm. The following options are available:
          <ul>
            <li>
              <b>"exclusive":</b> Rare data attributes tend to obtain more weight.
            </li>
            <li>
              <b>"fair":</b> Overly rare or overly frequent data attributes lose weight.
            </li>
            <li>
              <b>"mixed":</b> Mixes <i>"exclusive"</i> and <i>"fair"</i> strategy (see <i>mixFactor</i>).
            </li>
            <li>
              <b>"inverse":</b> Inverse behavior of <i>"fair"</i> strategy.
            </li>
          </ul>
        </li>
        <li>
          <b>mixFactor:</b> The factor to mix <i>exclusive</i> and <i>fair</i> strategies (Float between 0 and 1; only for <i>mixed</i> strategy.):
          <br />
          0 (= exclusive) ----------x---------- 1 (= fair)
        </li>
        <li>
          <b>impact:</b> Determines how strongly the initial column values are changed. n=1 means one run of the algorithm with small
          changes to the initial values. Higher values of n mean iterative runs of the algorithm with stronger changes.
        </li>
      </ul>
    `,
    description: 'text for api page',
  },
  danubePredictionText13: {
    id: 'api.danubePredictionText13',
    defaultMessage: `
      A <i>ColumnScore</i> has the following structure:
    `,
    description: 'text for api page',
  },
  danubePredictionText14: {
    id: 'api.danubePredictionText14',
    defaultMessage: `
      <ul>
        <li>
          <b>property:</b> The name of a property.
        </li>
        <li>
          <b>score:</b> The property's score.
        </li>
      </ul>
    `,
    description: 'text for api page',
  },
  danubePredictionText15: {
    id: 'api.danubePredictionText15',
    defaultMessage: `
      A response from the <i>danubePrediction</i> endpoint has the following structure:
    `,
    description: 'text for api page',
  },
  danubePredictionText16: {
    id: 'api.danubePredictionText16',
    defaultMessage: `
      <ul>
        <li>
          <b>newColumnScores:</b> A list of new <i>Column Scores</i>.
        </li>
        <li>
          <b>rowScores:</b> A list of row scores (same ordering as the <i>data</i> elements in the request), each determined by danube.ai.
          The row scores define the sorting (highest score = best row).
        </li>
        <li>
          <b>rowMatches:</b> A list of row matches (same ordering as the <i>data</i> elements in the request), each being an array of
          percentage values, describing how well a property value matches the best data value in this column.
        </li>
      </ul>
    `,
    description: 'text for api page',
  },
  danubePredictionText17: {
    id: 'api.danubePredictionText17',
    defaultMessage: `
      You can get a list of all your previously created rules-sets via the <i>getRules</i> endpoint of the danube.ai cloud API.
    `,
    description: 'text for api page',
  },
  danubePredictionText18: {
    id: 'api.danubePredictionText18',
    defaultMessage: `
      Below, the <i>getRules</i> request's structure is shown.
    `,
    description: 'text for api page',
  },
  danubePredictionText19: {
    id: 'api.danubePredictionText19',
    defaultMessage: `
      A response from the <i>getRules</i> endpoint includes a list of your rules-sets and has the following structure:
    `,
    description: 'text for api page',
  },
  danubePredictionText20: {
    id: 'api.danubePredictionText20',
    defaultMessage: `
      Each rules-set in the list has following properties:
      <br /><br />
      <ul>
        <li>
          <b>id:</b> The id of the rules-set.
        </li>
        <li>
          <b>rules:</b> A list of <i>Rules</i> (see the <a href="/api/cloud/documentation/prediction/set-rules" target="_blank">setRules</a> documentation for information on a <i>Rule's</i> structure).
        </li>
      </ul>
    `,
    description: 'text for api page',
  },
  danubePredictionText21: {
    id: 'api.danubePredictionText21',
    defaultMessage: `
      For testing, two rules-set are created by sending the requests below.
    `,
    description: 'text for api page',
  },
  danubePredictionText22: {
    id: 'api.danubePredictionText22',
    defaultMessage: `
      After that, the just created rules-sets can be retrieved by following request:
    `,
    description: 'text for api page',
  },

  danubeRecommendationRequest: {
    id: 'api.danubeRecommendationRequest',
    defaultMessage: 'danubeRecommendation request',
    description: 'text for api page',
  },
  danubeRecommendationResponse: {
    id: 'api.danubeRecommendationResponse',
    defaultMessage: 'danubeRecommendation response',
    description: 'text for api page',
  },
  danubeRecommendationExample1: {
    id: 'api.danubeRecommendationExample1',
    defaultMessage: 'Step 1',
    description: 'text for api page',
  },
  danubeRecommendationExample2: {
    id: 'api.danubeRecommendationExample2',
    defaultMessage: 'Step 2',
    description: 'text for api page',
  },

  danubeRecommendationOverviewText1: {
    id: 'api.danubeRecommendationOverviewText1',
    defaultMessage: `
      The recommendation API finds nearest neighbors on a multi-dimensional data mesh. On the one hand, you
      can add data to your mesh (e.g. by sending usage data of your website). On the other hand, you can request
      the nearest neighbor for a certain data profile (e.g. to recommend sub-pages to a user).
    `,
    description: 'Label for pricing page text.',
  },
  danubeRecommendationOverviewText2: {
    id: 'api.danubeRecommendationOverviewText2',
    defaultMessage: `
      All you have to do is to call the {danubeRecommendation} endpoint with your data. With each request, the correlation matrix
      of your data history is refined and better recommendations are returned.
    `,
    description: 'text for api page',
  },
  danubeRecommendationOverviewText3: {
    id: 'api.danubeRecommendationOverviewText3',
    defaultMessage: `
      You can call the {resetCorrelationMatrix} endpoint to reset the current correlation matrix of your API key.
    `,
    description: 'text for api page',
  },
  danubeRecommendationOverviewContactUs: {
    id: 'api.danubeRecommendationOverviewContactUs',
    defaultMessage: 'contact us',
    description: 'text for api page',
  },

  danubeRecommendationText1: {
    id: 'api.danubeRecommendationText1',
    defaultMessage: `
      Recommendations for your data can be acquired by using the <i>danubeRecommendation</i> endpoint of the danube.ai cloud API.
      <i>danubeRecommendation</i> will send your dataset to danube.ai, where it is used to build correlation information with all other data elements.
      The endpoint returns a list of recommendated data elements based on the correlation from all previous requests.
    `,
    description: 'text for api page',
  },
  danubeRecommendationText2: {
    id: 'api.danubeRecommendationText2',
    defaultMessage: `
      Below, the <i>danubeRecommendation</i> request's structure is shown.
    `,
    description: 'text for api page',
  },
  danubeRecommendationText3: {
    id: 'api.danubeRecommendationText3',
    defaultMessage: `
      The <i>RecommendationInputData</i> has the following structure:
    `,
    description: 'text for api page',
  },
  danubeRecommendationText4: {
    id: 'api.danubeRecommendationText4',
    defaultMessage: `
      <ul>
        <li>
          <b>data:</b> A stringified json array, holding all session entries until now, where a session entry is an object of key-value pairs.
        </li>
        <li>
          <b>n:</b> The maximum number of recommendations which will be returned (sorted by relevance). Has a hard limit of 20.
        </li>
      </ul>
    `,
    description: 'text for api page',
  },
  danubeRecommendationText5: {
    id: 'api.danubeRecommendationText5',
    defaultMessage: `
      A response from the <i>danubeRecommendation</i> endpoint has the following structure:
    `,
    description: 'text for api page',
  },
  danubeRecommendationText6: {
    id: 'api.danubeRecommendationText6',
    defaultMessage: `
      <ul>
        <li>
          <b>correlatedData:</b> A stringified json array, holding all recommended session entries (objects of key-value pairs) sorted by relevance.
        </li>
      </ul>
    `,
    description: 'text for api page',
  },
  danubeRecommendationText7: {
    id: 'api.danubeRecommendationText7',
    defaultMessage: `
      danube.ai’s recommendation endpoint is used here to recommend sub pages to a user. The current session consists of the route "Home - Dashboard".
      Based on the sent request, danube.ai recommends 3 correlated sub pages to choose from: "About", "Imprint" and "Data protection guidelines".
    `,
    description: 'text for api page',
  },
  danubeRecommendationText8: {
    id: 'api.danubeRecommendationText8',
    defaultMessage: `
      The user now continues the page navigation to “About”. The current session now consists of the route "Home - Dashboard - About". Based on the
      sent request, danube.ai recommends 3 correlated sub pages to choose from: "Imprint", "Data protection guidelines" and "Account Settings".
    `,
    description: 'text for api page',
  },

  danubeRecommendationText9: {
    id: 'api.danubeRecommendationText9',
    defaultMessage: `
      You can reset the correlation information between all your data elements which has been created from all previous <i>danubeRecommendation</i> calls
      by using the <i>resetCorrelationMatrix</i> endpoint of the danube.ai cloud API.
    `,
    description: 'text for api page',
  },
  danubeRecommendationResetMatrixWarning: {
    id: 'api.danubeRecommendationResetMatrixWarning',
    defaultMessage: `
      Note that this is a powerful operation as your whole data history will be deleted! If you should accidentally reset your correlation matrix you
      can {contactUs} and we will try to help.
    `,
    description: 'text for api page',
  },
  danubeRecommendationText10: {
    id: 'api.danubeRecommendationText10',
    defaultMessage: `
      Below, the <i>resetCorrelationMatrix</i> request's structure is shown.
    `,
    description: 'text for api page',
  },
  danubeRecommendationText11: {
    id: 'api.danubeRecommendationText11',
    defaultMessage: `
      The <i>resetCorrelationMatrix</i> endpoint either returns true if the correlation matrix was successfully resetted or false if not.
    `,
    description: 'text for api page',
  },

  examplesText1: {
    id: 'api.examplesText1',
    defaultMessage: `
      In the following, an example request and the according response using the {endpoint} endpoint are shown.
    `,
    description: 'text for api page',
  },
  examplesText2: {
    id: 'api.examplesText2',
    defaultMessage: `
      Note: This example assumes the rules have already been set as shown in the {example} example.
    `,
    description: 'text for api page',
  },
  examplesText3: {
    id: 'api.examplesText3',
    defaultMessage: `
      In the following, example requests and the according responses using the {endpoint} endpoint are shown.
    `,
    description: 'text for api page',
  },
});

export default messages;
