import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import s from './Partners.scss';
import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import useCasesStyle from '../UseCases/UseCases.scss'; // eslint-disable-line css-modules/no-unused-class
import wavesStyle from '../LandingPage/waves.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import landingPageMessages from '../LandingPage/messages';

class DevJobs extends React.Component {
  render() {
    return (
      <div className={useCasesStyle.useCaseContainer}>
        <Row
          className={`
            ${layoutStyle.noMargin}
            ${useCasesStyle.useCaseContent}
            ${s.partnerContainer}
          `}
        >
          <Col className={s.logoContainer} xs={12} md={6} lg={4} lgOffset={2}>
            <img src="/partners/devjobs.png" alt="" />
          </Col>
          <Col className={s.textContainer} xs={12} md={6} lg={4}>
            <div className={useCasesStyle.textContainer}>
              <h1>devjobs.at</h1>
              <div className={useCasesStyle.subTextContainer}>
                <span className={useCasesStyle.line}>
                  <FormattedMessage {...messages.devJobsText} />
                </span>
              </div>
              <div className={useCasesStyle.buttonBar}>
                <a
                  href="https://devjobs.at/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn-secondary btn-round btn-large">
                    <FormattedMessage {...landingPageMessages.learnMore} />
                  </button>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(
  s,
  layoutStyle,
  useCasesStyle,
  wavesStyle,
  buttonStyle,
)(DevJobs);
