import { defineMessages } from 'react-intl';

const messages = defineMessages({
  imprintHeader: {
    id: 'imprint.imprintHeader',
    defaultMessage: 'Imprint',
    description: 'Label for Imprint page header.',
  },
});

export default messages;
