import { defineMessages } from 'react-intl';

const messages = defineMessages({
  bestPrice: {
    id: 'demo.phone.results.bestPrice',
    defaultMessage: 'Best price',
    description: 'label in results list',
  },
  smallestDisplay: {
    id: 'demo.phone.results.smallestDisplay',
    defaultMessage: 'Smallest display',
    description: 'label in results list',
  },
  largestDisplay: {
    id: 'demo.phone.results.largestDisplay',
    defaultMessage: 'Largest display',
    description: 'label in results list',
  },
  bestResolution: {
    id: 'demo.phone.results.bestResolution',
    defaultMessage: 'Best resolution (PPI)',
    description: 'label in results list',
  },
  bestCameraMpx: {
    id: 'demo.phone.results.bestCameraMpx',
    defaultMessage: 'Best camera (Mpx)',
    description: 'label in results list',
  },
  bestCameraCount: {
    id: 'demo.phone.results.bestCameraCount',
    defaultMessage: 'Best camera (Count)',
    description: 'label in results list',
  },
  largestStorageRAM: {
    id: 'demo.phone.results.largestStorageRAM',
    defaultMessage: 'Largest storage (RAM)',
    description: 'label in results list',
  },
  largestStorageROM: {
    id: 'demo.phone.results.largestStorageROM',
    defaultMessage: 'Largest storage (ROM)',
    description: 'label in results list',
  },
  lowestWeight: {
    id: 'demo.phone.results.lowestWeight',
    defaultMessage: 'Lowest weight',
    description: 'label in results list',
  },
  longestTalkTime: {
    id: 'demo.phone.results.longestTalkTime',
    defaultMessage: 'Longest talk time',
    description: 'label in results list',
  },
  bestBatteryPower: {
    id: 'demo.phone.results.bestBatteryPower',
    defaultMessage: 'Best battery power',
    description: 'label in results list',
  },
  bestChipDeScore: {
    id: 'demo.phone.results.bestChipDeScore',
    defaultMessage: 'Best Chip.de score',
    description: 'label in results list',
  },
  bestConnectDeScore: {
    id: 'demo.phone.results.bestConnectDeScore',
    defaultMessage: 'Best connect.de score',
    description: 'label in results list',
  },
  bestNetzweltDeScore: {
    id: 'demo.phone.results.bestNetzweltDeScore',
    defaultMessage: 'Best netzwelt.de score',
    description: 'label in results list',
  },
  bestTechstageDeScore: {
    id: 'demo.phone.results.bestTechstageDeScore',
    defaultMessage: 'Best techstage.de score',
    description: 'label in results list',
  },
  bestComputerbildDeScore: {
    id: 'demo.phone.results.bestComputerbildDeScore',
    defaultMessage: 'Best Computerbild.de score',
    description: 'label in results list',
  },
  bestCameraGradeCreativeblog: {
    id: 'demo.phone.results.bestCameraGradeCreativeblog',
    defaultMessage: 'Best Creativeblog camera score',
    description: 'label in results list',
  },
  bestCameraGradeDigitalcameraworld: {
    id: 'demo.phone.results.bestCameraGradeDigitalcameraworld',
    defaultMessage: 'Best Digitalcameraworld camera score',
    description: 'label in results list',
  },
  bestCameraGradeNotebookcheck: {
    id: 'demo.phone.results.bestCameraGradeNotebookcheck',
    defaultMessage: 'Best Notebookcheck camera score',
    description: 'label in results list',
  },
  highestPopularity: {
    id: 'demo.phone.results.highestPopularity',
    defaultMessage: 'Highest popularity',
    description: 'label in results list',
  },

  company: {
    id: 'demo.phone.results.company',
    defaultMessage: 'Company',
    description: 'label in results list',
  },
  price: {
    id: 'demo.phone.results.price',
    defaultMessage: 'Price',
    description: 'label in results list',
  },
  displayZoll: {
    id: 'demo.phone.results.displayZoll',
    defaultMessage: 'Display (Zoll)',
    description: 'label in results list',
  },
  displayPpi: {
    id: 'demo.phone.results.displayPpi',
    defaultMessage: 'Display (ppi)',
    description: 'label in results list',
  },
  cameraMpx: {
    id: 'demo.phone.results.cameraMpx',
    defaultMessage: 'Camera (Mpx)',
    description: 'label in results list',
  },
  cameraCount: {
    id: 'demo.phone.results.cameraCount',
    defaultMessage: 'Camera (Count)',
    description: 'label in results list',
  },
  storageRAM: {
    id: 'demo.phone.results.storageRAM',
    defaultMessage: 'Storage (RAM)',
    description: 'label in results list',
  },
  storageROM: {
    id: 'demo.phone.results.storageROM',
    defaultMessage: 'Storage (ROM)',
    description: 'label in results list',
  },
  weight: {
    id: 'demo.phone.results.weight',
    defaultMessage: 'Weight (g)',
    description: 'label in results list',
  },
  talkTime: {
    id: 'demo.phone.results.talkTime',
    defaultMessage: 'Talk time',
    description: 'label in results list',
  },
  batteryPower: {
    id: 'demo.phone.results.batteryPower',
    defaultMessage: 'Battery power (mAh)',
    description: 'label in results list',
  },
  gradeChipDe: {
    id: 'demo.phone.results.gradeChipDe',
    defaultMessage: 'Grade Chip.de',
    description: 'label in results list',
  },
  percentageConnectDe: {
    id: 'demo.phone.results.percentageConnectDe',
    defaultMessage: 'Percentage connect.de',
    description: 'label in results list',
  },
  pointsNetzweltDe: {
    id: 'demo.phone.results.pointsNetzweltDe',
    defaultMessage: 'Points netzwelt.de',
    description: 'label in results list',
  },
  gradeTechstageDe: {
    id: 'demo.phone.results.gradeTechstageDe',
    defaultMessage: 'Grade techstage.de',
    description: 'label in results list',
  },
  gradeComputerbildDe: {
    id: 'demo.phone.results.gradeComputerbildDe',
    defaultMessage: 'Grade Computerbild.de',
    description: 'label in results list',
  },
  cameraGradeCreativeblog: {
    id: 'demo.phone.results.cameraGradeCreativeblog',
    defaultMessage: 'Camera Grade Creativeblog',
    description: 'label in results list',
  },
  cameraGradeDigitalcameraworld: {
    id: 'demo.phone.results.cameraGradeDigitalcameraworld',
    defaultMessage: 'Camera Grade Digitalcameraworld',
    description: 'label in results list',
  },
  cameraGradeNotebookcheck: {
    id: 'demo.phone.results.cameraGradeNotebookcheck',
    defaultMessage: 'Camera Grade Notebookcheck',
    description: 'label in results list',
  },
  popularity: {
    id: 'demo.phone.results.popularity',
    defaultMessage: 'Popularity',
    description: 'label in results list',
  },
  display: {
    id: 'demo.phone.results.display',
    defaultMessage: 'Display',
    description: 'label in results list',
  },
  camera: {
    id: 'demo.phone.results.camera',
    defaultMessage: 'Camera',
    description: 'label in results list',
  },
  battery: {
    id: 'demo.phone.results.battery',
    defaultMessage: 'Battery',
    description: 'label in results list',
  },
  storage: {
    id: 'demo.phone.results.storage',
    defaultMessage: 'Storage',
    description: 'label in results list',
  },
  externalTests: {
    id: 'demo.phone.results.externalTests',
    defaultMessage: 'External tests',
    description: 'label in results list',
  },
  cameraTests: {
    id: 'demo.phone.results.cameraTests',
    defaultMessage: 'Camera tests',
    description: 'label in results list',
  },
});

export default messages;
