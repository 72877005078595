import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './SearchMaskForm.scss';
import buttonStyle from '../../../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import layoutStyle from '../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import renderField from '../../../../components/ReduxForm/renderField';
import messages from './messages';
import { formatSliderLabel } from '../../../util';

const FORM_NAME = 'superHerosSearchMask';

const validate = () => {
  const errors = {};
  return errors;
};

class SearchMaskForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onMappingFileChange: PropTypes.func.isRequired,
    superPowers: PropTypes.arrayOf(PropTypes.string).isRequired,
    benefits: PropTypes.arrayOf(PropTypes.string).isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    const { handleSubmit, superPowers, benefits, errors, intl } = this.props;

    return (
      <div className={s.searchMaskContainer}>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <Row>
              <Col xs="12" style={{ margin: '10px 0' }}>
                <h1>
                  <FormattedMessage {...messages.filterAndSort} />
                </h1>
              </Col>
              <Col md="6" xs="12">
                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.salary} />
                  </Col>
                  <Col xs="12">
                    <Row>
                      <Col xs="6">
                        <Field
                          id="filter.salaryFrom"
                          name="filter.salaryFrom"
                          placeholder={intl.formatMessage(messages.salaryFrom)}
                          type="number"
                          component={renderField.renderInput}
                        />
                      </Col>
                      <Col xs="6">
                        <Field
                          id="filter.salaryTo"
                          name="filter.salaryTo"
                          placeholder={intl.formatMessage(messages.salaryTo)}
                          type="number"
                          component={renderField.renderInput}
                        />
                      </Col>
                      <Col xs="6">
                        <div className={s.spacerLine} />
                      </Col>
                      <Col xs="6">
                        <div className={s.spacerLine} />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.salaryImportance} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.salaryImportance"
                      name="filter.salaryImportance"
                      formName={FORM_NAME}
                      interval={{ min: 0, max: 1 }}
                      stepSize={0.25}
                      labelFormatter={formatSliderLabel}
                      component={renderField.renderSlider}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.experienceImportance} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.experienceImportance"
                      name="filter.experienceImportance"
                      formName={FORM_NAME}
                      interval={{ min: 0, max: 1 }}
                      stepSize={0.25}
                      labelFormatter={formatSliderLabel}
                      component={renderField.renderSlider}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.travelTimeImportance} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.travelTimeImportance"
                      name="filter.travelTimeImportance"
                      formName={FORM_NAME}
                      interval={{ min: 0, max: 1 }}
                      stepSize={0.25}
                      labelFormatter={formatSliderLabel}
                      component={renderField.renderSlider}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>
              </Col>
              <Col md="6" xs="12">
                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.strength} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.strength"
                      name="filter.strength"
                      component={renderField.renderSelectAlt}
                      isMulti
                      options={[
                        {
                          value: 'Level 1',
                          label: intl.formatMessage(messages.strengthLevel1),
                        },
                        {
                          value: 'Level 2',
                          label: intl.formatMessage(messages.strengthLevel2),
                        },
                        {
                          value: 'Level 3',
                          label: intl.formatMessage(messages.strengthLevel3),
                        },
                        {
                          value: 'Level 4',
                          label: intl.formatMessage(messages.strengthLevel4),
                        },
                      ]}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.social} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.social"
                      name="filter.social"
                      component={renderField.renderSelectAlt}
                      isMulti
                      options={[
                        {
                          value: 'Level 1',
                          label: intl.formatMessage(messages.socialLevel1),
                        },
                        {
                          value: 'Level 2',
                          label: intl.formatMessage(messages.socialLevel2),
                        },
                        {
                          value: 'Level 3',
                          label: intl.formatMessage(messages.socialLevel3),
                        },
                        {
                          value: 'Level 4',
                          label: intl.formatMessage(messages.socialLevel4),
                        },
                      ]}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.superPowers} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.superPowers"
                      name="filter.superPowers"
                      component={renderField.renderSelectAlt}
                      isMulti
                      options={superPowers.map(superPower => ({
                        value: superPower,
                        label: superPower,
                      }))}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.benefits} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.benefits"
                      name="filter.benefits"
                      component={renderField.renderSelectAlt}
                      isMulti
                      options={benefits.map(benefit => ({
                        value: benefit,
                        label: benefit,
                      }))}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className={s.buttonbar}>
              <button
                type="submit"
                className="btn-secondary btn-round btn-large"
              >
                danubify
              </button>
              {errors.length === 0 ? null : (
                <ul style={{ padding: '0', listStyleType: 'none' }}>
                  {errors.map(err => (
                    <li key={err} className="bg-danger">
                      {err}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(injectIntl(withStyles(buttonStyle, layoutStyle, s)(SearchMaskForm)));
