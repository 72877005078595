import { defineMessages } from 'react-intl';

const messages = defineMessages({
  info: {
    id: 'realData.info',
    defaultMessage: `
      Here you can find real {setName} results to
      get you started. Stay tuned for updates!
    `,
    description: 'Info text for real data',
  },
});

export default messages;
