import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Collapse } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FaCaretUp, FaCaretDown } from 'react-icons/lib/fa';

import { phoneShape, filterShape } from '../../constants/types';

import Loading from '../../../../components/Loading';
import s from './ResultsList.scss'; // eslint-disable-line css-modules/no-unused-class
import globalDemoMessages from '../../../globalDemoMessages';
import messages from './messages';

const danubeTagsMap = {
  price: messages.priceTag,
  price_original: messages.priceTag,
  memory: messages.memoryTag,
  memory_original: messages.memoryTag,
  ram: messages.ramTag,
  weight: messages.weightTag,
  max_conversation_time: messages.maxConversationTimeTag,
  display_inches: messages.displayInchesTag,
  display_inches_original: messages.displayInchesTag,
  display_pixels: messages.displayPixelsTag,
  display_pixels_original: messages.displayPixelsTag,
  display_ppi: messages.displayPpiTag,
  camera_front_amount: messages.cameraFrontAmountTag,
  camera_front_highest_mp: messages.cameraFrontHighestMpTag,
  camera_back_amount: messages.cameraBackAmountTag,
  camera_back_highest_mp: messages.cameraBackHighestMpTag,
  battery: messages.batteryTag,
};

const rowIdToBestSlotMessage = {
  price: messages.bestSlotPriceTag,
  memory: messages.bestSlotMemoryTag,
  ram: messages.bestSlotRamTag,
  weight: messages.bestSlotWeightTag,
  max_conversation_time: messages.bestSlotMaxConversationTimeTag,
  display_inches_smallest: messages.bestSlotSmallestDisplayInchesTag,
  display_inches_largest: messages.bestSlotLargestDisplayInchesTag,
  display_pixels: messages.bestSlotDisplayPixelsTag,
  display_ppi: messages.bestSlotDisplayPpiTag,
  camera_front_amount: messages.bestSlotCameraFrontAmountTag,
  camera_front_highest_mp: messages.bestSlotCameraFrontHighestMpTag,
  camera_back_amount: messages.bestSlotCameraBackAmountTag,
  camera_back_highest_mp: messages.bestSlotCameraBackHighestMpTag,
  battery: messages.bestSlotBatteryTag,
};

class ResultsList extends React.Component {
  static propTypes = {
    filter: filterShape,
    geizhalsPhonesQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      geizhalsPhones: PropTypes.shape({
        phones: PropTypes.arrayOf(phoneShape.isRequired),
        columnKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
        columnScores: PropTypes.arrayOf(PropTypes.number).isRequired,
        errors: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    onLoadingFinished: PropTypes.func,
  };

  static defaultProps = {
    filter: {},
    geizhalsPhonesQuery: null,
    onLoadingFinished: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      extendedEntries: {},
    };

    this.renderPhone = this.renderPhone.bind(this);
  }

  componentDidUpdate(prevProps) {
    // close all extended entries on new query
    if (prevProps.filter !== this.props.filter) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        extendedEntries: {},
      });
    }

    if (
      prevProps.geizhalsPhonesQuery &&
      prevProps.geizhalsPhonesQuery.loading &&
      this.props.geizhalsPhonesQuery &&
      !this.props.geizhalsPhonesQuery.loading
    ) {
      if (
        this.props.geizhalsPhonesQuery.geizhalsPhones &&
        (!this.props.geizhalsPhonesQuery.geizhalsPhones.errors ||
          (this.props.geizhalsPhonesQuery.geizhalsPhones.errors &&
            this.props.geizhalsPhonesQuery.geizhalsPhones.errors.length === 0))
      ) {
        this.props.onLoadingFinished();
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderPhone({ phone, index, perfectDanubeScore, columnKeys }) {
    const { filter } = this.props;

    // calculate match score and map it to [0, 100]
    let matchScore = parseFloat(phone.danubeScore / perfectDanubeScore);
    matchScore = Math.max(0, Math.min(100, Math.round(matchScore * 100)));

    // get css-class for match score container
    let matchScoreClass = '';
    if (matchScore >= 50) {
      matchScoreClass = s.optimalScore;
    } else if (matchScore >= 33) {
      matchScoreClass = s.goodScore;
    } else {
      matchScoreClass = s.mediumScore;
    }

    return (
      <div key={phone.id} className={s.listItemContainer}>
        <div className={s.imageWrapper}>
          <div className={s.imageWrapperInner}>
            <img src={phone.image} alt="" />
          </div>
        </div>
        <div className={s.contentWrapper}>
          <div className={s.infoContainer}>
            <div className={s.scoreWrapper}>
              <span className={s.titleContainer}>
                <span>
                  {index + 1}. {phone.name}
                </span>
                <span className={matchScoreClass}>
                  {matchScore}% Match
                  <br />
                </span>
              </span>
            </div>
            <div className={s.bestSlotTagsContainer}>
              {phone.matches.map((match, pIndex) => {
                if (match !== 1) return null;
                const columnKey = columnKeys[pIndex];

                if (columnKey in rowIdToBestSlotMessage) {
                  return (
                    <div
                      key={`bestSlot-${columnKey}`}
                      className={s.bestSlotTag}
                    >
                      <FormattedMessage
                        {...rowIdToBestSlotMessage[columnKey]}
                      />
                    </div>
                  );
                } else if (columnKey === 'display_inches') {
                  return (
                    <div
                      key={`bestSlot-${columnKey}`}
                      className={s.bestSlotTag}
                    >
                      <FormattedMessage
                        {...rowIdToBestSlotMessage[
                          filter.displayZollPreference === 'preferSmall'
                            ? 'display_inches_smallest'
                            : 'display_inches_largest'
                        ]}
                      />
                    </div>
                  );
                }

                return null;
              })}
            </div>
            <div className={s.priceContainer}>
              <span className={s.price}>{phone.price_original} €</span>
            </div>
          </div>
          <div className={s.benefitsContainer}>
            <button
              onClick={() => {
                const newExtendedEntries = { ...this.state.extendedEntries };
                newExtendedEntries[phone.id] = !newExtendedEntries[phone.id]; // eslint-disable-line
                this.setState({
                  extendedEntries: newExtendedEntries,
                });
              }}
            >
              {this.state.extendedEntries[phone.id] ? (
                <FaCaretUp style={{ marginLeft: '2px', marginRight: '15px' }} />
              ) : (
                <FaCaretDown
                  style={{ marginLeft: '2px', marginRight: '15px' }}
                />
              )}
            </button>
            <Collapse in={this.state.extendedEntries[phone.id]}>
              <div>
                <ul>
                  {[
                    { id: 'price_original', suffix: ' €' },
                    { id: 'memory_original', suffix: ' GB' },
                    { id: 'ram', suffix: ' GB' },
                    { id: 'max_conversation_time', suffix: ' h' },
                    { id: 'display_inches_original' },
                    { id: 'display_pixels_original' },
                    { id: 'display_ppi' },
                    { id: 'camera_front_amount' },
                    { id: 'camera_front_highest_mp' },
                    { id: 'camera_back_amount' },
                    { id: 'camera_back_highest_mp' },
                    { id: 'battery' },
                  ].map(prop => (
                    <li key={prop.id} className={s.tag}>
                      <b>
                        <FormattedMessage {...danubeTagsMap[prop.id]} />:
                      </b>
                      &nbsp;
                      {phone[prop.id] != null && phone[prop.id] !== '' ? (
                        <span>
                          {phone[prop.id]}
                          {prop.suffix || ''}
                        </span>
                      ) : (
                        '-'
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { geizhalsPhonesQuery } = this.props;

    if (!geizhalsPhonesQuery) return null;

    if (geizhalsPhonesQuery.loading) {
      return <Loading />;
    }

    if (!geizhalsPhonesQuery.geizhalsPhones) return null;

    const {
      geizhalsPhones: { phones, columnKeys, columnScores, errors },
    } = geizhalsPhonesQuery;

    // normalize column scores
    const total = columnScores.reduce((sum, score) => sum + score, 0);
    const normalizedColumnScores = columnScores.map(
      score => (score / total) * 100,
    );

    // get max column score
    const maxColumnScore = Math.max(...normalizedColumnScores);

    // get the perfect danube score
    const perfectDanubeScore = columnScores.reduce(
      (scoreSum, score) => scoreSum + score,
      0,
    );

    if (errors && errors.length > 0) {
      return (
        <ul style={{ padding: '0', listStyleType: 'none' }}>
          {errors.map(err => (
            <li key={err} className="bg-danger">
              {err}
            </li>
          ))}
        </ul>
      );
    }

    return (
      <div className={s.resultsListContainer}>
        <div className={s.metadataContainer}>
          {normalizedColumnScores.length > 0 && (
            <div>
              {normalizedColumnScores.map((score, index) => (
                <div
                  key={`normalizedColumnScore-${index}`} // eslint-disable-line react/no-array-index-key
                  className={
                    score === maxColumnScore
                      ? s.danubeWrapper
                      : s.danubeWrapperLight
                  }
                >
                  <span className={s.label}>
                    <FormattedMessage {...danubeTagsMap[columnKeys[index]]} />
                    :&nbsp;
                    {Math.round(score)}%
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={s.listItemsContainer}>
          {phones.length === 0 ? (
            <div className={s.noResultsContainer}>
              <FormattedMessage {...globalDemoMessages.noResults} />
            </div>
          ) : (
            <div>
              {phones.map((phone, index) =>
                this.renderPhone({
                  phone,
                  index,
                  perfectDanubeScore,
                  columnKeys,
                }),
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const geizhalsPhonesQuery = gql`
  query geizhalsPhonesQuery($filter: GeizhalsPhonesFilter) {
    geizhalsPhones(filter: $filter) {
      phones {
        id
        name
        image
        price
        price_original
        memory
        memory_original
        ram
        weight
        max_conversation_time
        display_inches
        display_inches_original
        display_pixels
        display_pixels_original
        display_ppi
        camera_front_amount
        camera_front_highest_mp
        camera_back_amount
        camera_back_highest_mp
        battery
        danubeScore
        matches
      }
      columnKeys
      columnScores
      errors
    }
  }
`;

export default compose(
  graphql(geizhalsPhonesQuery, {
    name: 'geizhalsPhonesQuery',
    options: ({ filter }) => ({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
    }),
    skip: ({ skipPageLoadQuery }) => skipPageLoadQuery,
  }),
)(withStyles(s)(ResultsList));
