import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaCircle from 'react-icons/lib/fa/circle';
import MdCheck from 'react-icons/lib/md/check';
import MdMinus from 'react-icons/lib/io/minus';

import s from './PricingCard.scss';

export const NEUTRAL_FEATURE = 'NEUTRAL_FEATURE';
export const POSITIVE_FEATURE = 'POSITIVE_FEATURE';
export const NEGATIVE_FEATURE = 'NEGATIVE_FEATURE';

class PricingCard extends React.Component {
  static propTypes = {
    title: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    type: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.any,
        text: PropTypes.any,
      }),
    ).isRequired,
  };

  static defaultProps = {
    type: 'base',
  };

  constructor(props) {
    super(props);

    this.renderFeature = this.renderFeature.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  renderFeature(feature, index) {
    let textClass = `${s.featureText}`;
    let iconCircleClass = `${s.iconCircle}`;
    let Icon = FaCircle;

    if (feature.type === POSITIVE_FEATURE) {
      Icon = MdCheck;
    } else if (feature.type === NEGATIVE_FEATURE) {
      textClass = `${s.featureText} ${s.negative}`;
      iconCircleClass = `${s.iconCircle} ${s.negative}`;
      Icon = MdMinus;
    }

    return (
      <div key={`feature_${index + 1}`} className={s.featureContainer}>
        <div className={iconCircleClass}>
          <div className={s.iconCircleInner}>
            <Icon className={s.icon} />
          </div>
        </div>
        <span className={textClass}>{feature.text}</span>
      </div>
    );
  }

  render() {
    const { title, type, features } = this.props;

    let titleContainerClass = s.titleContainer;
    if (type === 'base') {
      titleContainerClass = `${s.titleContainer} ${s.base}`;
    } else if (type === 'pro') {
      titleContainerClass = `${s.titleContainer} ${s.pro}`;
    } else if (type === 'premium') {
      titleContainerClass = `${s.titleContainer} ${s.premium}`;
    }

    return (
      <div className={s.pricingCard}>
        <div className={titleContainerClass}>{title}</div>
        <div className={s.bodyContainer}>
          {features.map((feature, i) => this.renderFeature(feature, i))}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(PricingCard);
