import { defineMessages } from 'react-intl';

const messages = defineMessages({
  location: {
    id: 'demo.immo.results.location',
    defaultMessage: 'District/ZIP',
    description: 'label in results list',
  },
  priceTag: {
    id: 'demo.immo.results.priceTag',
    defaultMessage: 'Price',
    description: 'label in results list',
  },
  gardenTag: {
    id: 'demo.immo.results.gardenTag',
    defaultMessage: 'Garden area',
    description: 'label in results list',
  },
  garageTag: {
    id: 'demo.immo.results.garageTag',
    defaultMessage: 'Garage',
    description: 'label in results list',
  },
  imageCountTag: {
    id: 'demo.immo.results.imageCountTag',
    defaultMessage: 'Number of images',
    description: 'label in results list',
  },
});

export default messages;
