import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './TrustedBy.scss';
import messages from './messages';

class TrustedBy extends React.Component {
  static propTypes = {
    partners: PropTypes.arrayOf(
      PropTypes.shape({
        imagePath: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
  };

  render() {
    const { partners } = this.props;

    return (
      <div className={s.trustedByContainer}>
        <div className={s.content}>
          <div className={s.trustedCircle}>
            <FormattedMessage {...messages.trustedBy} />
          </div>
          <div className={s.imagesContainer}>
            {partners.map(partner => (
              <a href={partner.url} target="_blank" rel="noopener noreferrer">
                <img src={partner.imagePath} alt="" />
              </a>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(TrustedBy);
