import React from 'react';

import PageProperties from 'components/PageProperties';
import EnliteAI from 'components/Partners/EnliteAI';
import routeMessages from 'routes/messages';

class EnliteAIWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.partnerEnliteAITitle,
            description: routeMessages.partnerEnliteAIDescription,
            keywords: routeMessages.partnerEnliteAIKeywords,
          }}
        />
        <EnliteAI />
      </div>
    );
  }
}

export default EnliteAIWrapper;
