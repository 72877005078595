import { defineMessages } from 'react-intl';

const messages = defineMessages({
  urlModalTitle: {
    id: 'analytics.hashes.urlModalTitle',
    defaultMessage: 'Created url:',
    description: 'Label for modal in analytics',
  },
  close: {
    id: 'apiButton.close',
    defaultMessage: 'Close',
    description: 'Label for modal in analytics',
  },
});

export default messages;
