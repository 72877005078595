import { defineMessages } from 'react-intl';

const messages = defineMessages({
  apaitText: {
    id: 'partners.apaitText',
    defaultMessage: `
      APA-IT provides Gentics CMS, a successful enterprise solution for web content management and web portals.
      This hybrid content management system has been continuously improved since 2000 and uses danube.ai to
      propose a personalized experience to website visitors.
    `,
    description: 'Text for partner',
  },
  devJobsText: {
    id: 'partners.devJobsText',
    defaultMessage: `
      devjobs.at is Austria's first job and career platform especially for software developers, and was designed
      from developers for developers. So that really every user can find the perfect job, devjobs.at works with a
      new AI-based matching technology made by danube.ai.
    `,
    description: 'Text for partner',
  },
  enliteAIText: {
    id: 'partners.enliteAIText',
    defaultMessage: `
      We are happy to announce that danube.ai is part of the {AILandscapeAustriaLink} 2020. The AI Landscape Austria 
      is a project mapping Austrian businesses working on artificial intelligence and is curated and maintained by enliteAI.
    `,
    description: 'Text for partner',
  },
});

export default messages;
