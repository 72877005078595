import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SyntaxHighlighter from 'react-syntax-highlighter';

// import GetApiKeyButton from 'components/ApiKey/GetApiKeyButton';

import s from '../../Page.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../messages';
import wikiMessages from '../../messages';
import { codeStyle, preliminaryCode } from '../codeBlocks';

class ApiWiki extends React.Component {
  render() {
    return (
      <div className={s.apiText}>
        <p>
          <h1>
            <FormattedMessage {...wikiMessages.useApi} />
          </h1>
          <FormattedHTMLMessage {...messages.preliminaryText1} />
          <br />
          <br />
          <FormattedHTMLMessage {...messages.preliminaryText2} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {preliminaryCode}
          </SyntaxHighlighter>
          <br />
          <FormattedHTMLMessage {...messages.preliminaryText3} />
          {/* <br />
          <br />
          <GetApiKeyButton /> */}
        </p>
      </div>
    );
  }
}

export default withStyles(s)(ApiWiki);
