import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaEnvelope from 'react-icons/lib/fa/envelope';

import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from './PersonCard.scss';

class PersonCard extends React.Component {
  static propTypes = {
    imageUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    socialLinks: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ),
    email: PropTypes.string,
  };

  static defaultProps = {
    title: '',
    socialLinks: [],
    email: null,
  };

  render() {
    const { imageUrl, name, title, socialLinks, email } = this.props;
    return (
      <div className={s.personCardContainer}>
        <div className={s.imageContainer}>
          <img src={imageUrl} alt="" />
        </div>
        <div className={s.textContainer}>
          {name}
          <div className={s.smallTextContainer}>{title}</div>
        </div>
        <div className={s.socialLinksContainer}>
          {socialLinks.map((socialLink, index) => (
            <a
              key={`link-${index}`} // eslint-disable-line
              href={socialLink.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {socialLink.icon}
            </a>
          ))}
          {email && (
            <a href={`mailto:${email}`}>
              <FaEnvelope />
            </a>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle)(PersonCard);
