import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';

import s from './showcase.scss'; //eslint-disable-line

class QuoteTemplate extends React.Component {
  static propTypes = {
    userImage: PropTypes.string.isRequired,
    authorName: PropTypes.element.isRequired,
    quote: PropTypes.element.isRequired,
  };

  render() {
    const { userImage, authorName, quote } = this.props;

    return (
      <div className={s.quoteContainer}>
        <div className={s.imageContainer}>
          <img src={userImage} alt="" />
        </div>
        <p className={s.author}>{authorName}</p>
        <p className={s.quote}>
          &ldquo;
          {quote}
          &#8221;
        </p>
      </div>
    );
  }
}

export default withStyles(s)(QuoteTemplate);
