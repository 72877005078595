import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import footerMessages from '../Footer/messages';

const navItemsLeft = [];

const navItemsLeftLoggedIn = [
  {
    key: 'dashboard',
    label: <FormattedMessage {...messages.dashboard} />,
    link: 'my/dashboard',
  },
];

const navItemsRight = [
  {
    key: 'showcases',
    label: <FormattedMessage {...messages.showcases} />,
    subNavs: [
      {
        key: 'geizhals',
        label: 'Geizhals',
        link: 'showcases/geizhals',
      },
      {
        key: 'devjobs',
        label: 'devjobs.at',
        link: 'showcases/devjobs',
      },
    ],
  },
  {
    key: 'Technology',
    label: <FormattedMessage {...messages.technology} />,
    link: 'technology',
  },
  {
    key: 'Press',
    label: <FormattedMessage {...footerMessages.press} />,
    link: 'press',
  },
  // {
  //   key: 'get-started',
  //   label: <FormattedMessage {...messages.getstarted} />,
  //   subNavs: [
  //     {
  //       key: 'ecommerce',
  //       label: 'Ecommerce',
  //       link: 'use-cases/ecommerce',
  //     },
  //     {
  //       key: 'recruiting',
  //       label: 'Recruiting',
  //       link: 'use-cases/recruiting',
  //     },
  //     {
  //       key: 'netflix',
  //       label: 'Netflix',
  //       link: 'use-cases/media-suggestions',
  //     },
  //   ],
  // },
  // {
  //   key: 'Demos',
  //   label: <FormattedMessage {...messages.demos} />,
  //   subNavs: [
  //     {
  //       key: 'phones',
  //       label: 'Ecommerce',
  //       link: 'demos/phones',
  //     },
  //     {
  //       key: 'recruiting',
  //       label: 'Recruiting',
  //       link: 'demos/recruiting',
  //     },
  //     {
  //       key: 'jobs',
  //       label: 'Job Search',
  //       link: 'demos/jobs',
  //     },
  //     {
  //       key: 'realEstate',
  //       label: 'Real Estate',
  //       link: 'demos/immo',
  //     },
  //   ],
  // },
  // {
  //   key: 'pricing',
  //   label: <FormattedMessage {...messages.pricing} />,
  //   link: 'pricing',
  // },
  // {
  //   key: 'requestDemoButton',
  //   customContent: (
  //     <RequestDemoButton isTopNav localeToFormIdMap={REQUEST_DEMO_FORM_IDS} />
  //   ),
  // },

  // {
  //   key: 'use-cases',
  //   label: <FormattedMessage {...messages.useCases} />,
  //   subNavs: [
  //     {
  //       key: '',
  //       customContent: (
  //         <ul className="dropdown-submenu-wrapper">
  //           <li className="dropdown-submenu">
  //             <FormattedMessage {...messages.eCommerce} />
  //             <span className="caret caret-right" />
  //             <ul className="dropdown-menu">
  //               <li>
  //                 <Link to="/use-cases/media-suggestions">
  //                   <FormattedMessage {...messages.mediaSuggestions} />
  //                 </Link>
  //               </li>
  //               <li>
  //                 <Link to="/use-cases/product-suggestions">
  //                   <FormattedMessage {...messages.productSuggestions} />
  //                 </Link>
  //               </li>
  //             </ul>
  //           </li>
  //         </ul>
  //       ),
  //     },
  //     {
  //       key: 'sports',
  //       customContent: (
  //         <ul className="dropdown-submenu-wrapper">
  //           <li className="dropdown-submenu">
  //             <FormattedMessage {...messages.sports} />
  //             <span className="caret caret-right" />
  //             <ul className="dropdown-menu">
  //               <li>
  //                 <Link to="/use-cases/football">
  //                   <FormattedMessage {...messages.football} />
  //                 </Link>
  //               </li>
  //               <li>
  //                 <Link to="/use-cases/tennis">
  //                   <FormattedMessage {...messages.tennis} />
  //                 </Link>
  //               </li>
  //             </ul>
  //           </li>
  //         </ul>
  //       ),
  //     },
  //     {
  //       key: 'human-resources',
  //       customContent: (
  //         <ul className="dropdown-submenu-wrapper">
  //           <li className="dropdown-submenu">
  //             <FormattedMessage {...messages.humanResources} />
  //             <span className="caret caret-right" />
  //             <ul className="dropdown-menu">
  //               <li>
  //                 <Link to="/use-cases/recruiting">
  //                   <FormattedMessage {...messages.recruiting} />
  //                 </Link>
  //               </li>
  //               <li>
  //                 <Link to="/use-cases/training">
  //                   <FormattedMessage {...messages.training} />
  //                 </Link>
  //               </li>
  //             </ul>
  //           </li>
  //         </ul>
  //       ),
  //     },
  //     {
  //       key: 'testing',
  //       customContent: (
  //         <ul className="dropdown-submenu-wrapper">
  //           <li className="dropdown-submenu">
  //             <FormattedMessage {...messages.testing} />
  //             <span className="caret caret-right" />
  //             <ul className="dropdown-menu">
  //               <li>
  //                 <Link to="/use-cases/education">
  //                   <FormattedMessage {...messages.education} />
  //                 </Link>
  //               </li>
  //               <li>
  //                 <Link to="/use-cases/industry">
  //                   <FormattedMessage {...messages.industry} />
  //                 </Link>
  //               </li>
  //             </ul>
  //           </li>
  //         </ul>
  //       ),
  //     },
  //   ],
  // },
];

export { navItemsLeft, navItemsLeftLoggedIn, navItemsRight };
