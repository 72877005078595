import { defineMessages } from 'react-intl';

const messages = defineMessages({
  // learn more button
  learnMore: {
    id: 'demo.global.learnMore',
    defaultMessage: 'Learn more',
    description: 'global demo label',
  },

  // try it button
  tryIt: {
    id: 'demo.global.tryIt',
    defaultMessage: 'Try demo',
    description: 'global demo label',
  },

  // result messages
  noResults: {
    id: 'demo.global.noResults',
    defaultMessage: 'No results found that match the searching parameters',
    description: 'global demo label',
  },
  resultsFound: {
    id: 'demo.global.resultsFound',
    defaultMessage: 'results found',
    description: 'global demo label',
  },
  changesByDanube: {
    id: 'demo.global.changesByDanube',
    defaultMessage: 'changes by danube.ai',
    description: 'global demo label',
  },

  // importance slider labels
  unimportant: {
    id: 'demo.global.unimportant',
    defaultMessage: 'unimportant',
    description: 'global demo label',
  },
  lessImportant: {
    id: 'demo.global.lessImportant',
    defaultMessage: 'less important',
    description: 'global demo label',
  },
  neutral: {
    id: 'demo.global.neutral',
    defaultMessage: 'neutral',
    description: 'global demo label',
  },
  important: {
    id: 'demo.global.important',
    defaultMessage: 'important',
    description: 'global demo label',
  },
  veryImportant: {
    id: 'demo.global.veryImportant',
    defaultMessage: 'very important',
    description: 'global demo label',
  },

  // level labels
  veryHigh: {
    id: 'demo.global.veryHigh',
    defaultMessage: 'very high',
    description: 'global demo label',
  },
  high: {
    id: 'demo.global.high',
    defaultMessage: 'high',
    description: 'global demo label',
  },
  average: {
    id: 'demo.global.average',
    defaultMessage: 'average',
    description: 'global demo label',
  },
  low: {
    id: 'demo.global.low',
    defaultMessage: 'low',
    description: 'global demo label',
  },
  veryLow: {
    id: 'demo.global.veryLow',
    defaultMessage: 'very low',
    description: 'global demo label',
  },

  // global learn more messages
  learnMoreHeader1: {
    id: 'demo.global.learnMore.header1',
    defaultMessage: `What's this demo about?`,
    description: 'label for learn more page',
  },
  learnMoreHeader2: {
    id: 'demo.global.learnMore.header2',
    defaultMessage: `How does the ai work for this demo?`,
    description: 'label for learn more page',
  },
  learnMoreHeader3: {
    id: 'demo.global.learnMore.header3',
    defaultMessage: `What's behind the results?`,
    description: 'label for learn more page',
  },
  learnMoreHeader4: {
    id: 'demo.global.learnMore.header4',
    defaultMessage: `How does danube.ai work for my application?`,
    description: 'label for learn more page',
  },

  // other global messages
  years: {
    id: 'demo.global.years',
    defaultMessage: 'years',
    description: 'global demo label',
  },
});

export default messages;
