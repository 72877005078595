exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".View-viewContainer-1r1AP {\n  padding: 20px; }\n\n.View-funnelChartContainer-30rSC {\n  position: relative;\n  margin: 25px 0; }\n\n.View-label-2P_yx .View-circle-2Khrv {\n  display: inline-block;\n  width: 15px;\n  height: 15px;\n  border: 2px solid #fff;\n  border-radius: 50%;\n  margin-right: 7px;\n  vertical-align: middle;\n  background-color: #fff; }\n\n.View-label-2P_yx .View-circle-2Khrv .View-circleInner-297iW {\n    width: 100%;\n    height: 100%;\n    border-radius: 50%; }\n\n.View-label-2P_yx span {\n  vertical-align: middle; }\n", "", {"version":3,"sources":["/builds/wa/danube-frontend/src/components/Analytics/View.scss"],"names":[],"mappings":"AAAA;EACE,cAAc,EAAE;;AAElB;EACE,mBAAmB;EACnB,eAAe,EAAE;;AAEnB;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;EACvB,uBAAuB,EAAE;;AAE3B;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB,EAAE;;AAEzB;EACE,uBAAuB,EAAE","file":"View.scss","sourcesContent":[".viewContainer {\n  padding: 20px; }\n\n.funnelChartContainer {\n  position: relative;\n  margin: 25px 0; }\n\n.label .circle {\n  display: inline-block;\n  width: 15px;\n  height: 15px;\n  border: 2px solid #fff;\n  border-radius: 50%;\n  margin-right: 7px;\n  vertical-align: middle;\n  background-color: #fff; }\n\n.label .circle .circleInner {\n    width: 100%;\n    height: 100%;\n    border-radius: 50%; }\n\n.label span {\n  vertical-align: middle; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"viewContainer": "View-viewContainer-1r1AP",
	"funnelChartContainer": "View-funnelChartContainer-30rSC",
	"label": "View-label-2P_yx",
	"circle": "View-circle-2Khrv",
	"circleInner": "View-circleInner-297iW"
};