import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  DATA_PRESET_TEST_RESULTS,
  DATA_PRESET_RECRUITING_LIST,
  DATA_PRESET_SPORTS_LIGA,
  DATA_PRESET_SPORTS_CHAMPIONSHIP,
  DATA_PRESET_CUSTOM,
  ALGORITHM_STRATEGY_FAIR,
  ALGORITHM_STRATEGY_MIXED,
  ALGORITHM_STRATEGY_INVERSE,
} from '../../../../constants';

import messages from '../messages';

const templates = {};
templates[DATA_PRESET_SPORTS_LIGA] = {
  name: <FormattedMessage {...messages.sportsLiga} />,
  settings: {
    strategy: ALGORITHM_STRATEGY_MIXED,
    mixFactor: 0.75,
    impact: 3,
    globalMaximum: true,
  },
};
templates[DATA_PRESET_SPORTS_CHAMPIONSHIP] = {
  name: <FormattedMessage {...messages.sportsChampionship} />,
  settings: {
    strategy: ALGORITHM_STRATEGY_INVERSE,
    mixFactor: 0,
    impact: 3,
    globalMaximum: true,
  },
};
templates[DATA_PRESET_RECRUITING_LIST] = {
  name: <FormattedMessage {...messages.recruitingList} />,
  settings: {
    strategy: ALGORITHM_STRATEGY_MIXED,
    mixFactor: 0.5,
    impact: 3,
    globalMaximum: false,
  },
};
templates[DATA_PRESET_TEST_RESULTS] = {
  name: <FormattedMessage {...messages.testResults} />,
  settings: {
    strategy: ALGORITHM_STRATEGY_FAIR,
    mixFactor: 0,
    impact: 3,
    globalMaximum: false,
  },
};
templates[DATA_PRESET_CUSTOM] = {
  name: <FormattedMessage {...messages.custom} />,
  settings: {
    globalMaximum: false,
  },
};

export default templates;
