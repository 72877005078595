import React from 'react';
import PropTypes from 'prop-types';
import { /* Query , */ graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Collapse } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  FaCaretUp,
  FaCaretDown,
  FaAngleLeft,
  FaAngleDoubleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
  FaBuilding,
  FaUser,
  FaEur,
  FaMapMarker,
  FaLaptop,
  FaCircle,
  FaClockO,
} from 'react-icons/lib/fa';

import { jobsShape, filterShape } from '../../constants/types';

import Loading from '../../../../components/Loading';
import s from './ResultsList.scss';
import globalDemoMessages from '../../../globalDemoMessages';
import messages from './messages';
import { LearnMoreLink } from '../../../util';

const danubeTagsMap = {
  salary: messages.salaryTag,
  daysAgo: messages.daysAgoTag,
  remoteWork: messages.remoteWorkTag,
  companyType: messages.companyTypePreferenceTag,
  jobLevel: messages.jobLevelPreferenceTag,
  // technologies: messages.technologiesTag,
  // benefits: messages.benefitsTag,
  timeDistance: messages.timeDistance,
  technologies: messages.technologiesTag,
  benefits: messages.benefitsTag,
};

class ResultsList extends React.Component {
  static propTypes = {
    filter: filterShape,
    jobsQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      jobs: PropTypes.shape({
        jobs: PropTypes.arrayOf(jobsShape.isRequired),
        columnKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
        columnScores: PropTypes.arrayOf(PropTypes.number).isRequired,
      }),
    }),
    onLoadingFinished: PropTypes.func,
  };

  static defaultProps = {
    filter: {},
    jobsQuery: null,
    onLoadingFinished: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
      itemsPerPage: 10,
      extendedEntries: {},
    };

    this.getLastPage = this.getLastPage.bind(this);
    this.toStart = this.toStart.bind(this);
    this.toEnd = this.toEnd.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.renderButtonBar = this.renderButtonBar.bind(this);
    this.renderJob = this.renderJob.bind(this);
  }

  componentDidUpdate(prevProps) {
    // close all extended entries on new query and go back to page 1
    if (prevProps.filter !== this.props.filter) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        currentPage: 0,
        extendedEntries: {},
      });
    }

    if (
      prevProps.jobsQuery &&
      prevProps.jobsQuery.loading &&
      this.props.jobsQuery &&
      !this.props.jobsQuery.loading
    ) {
      this.props.onLoadingFinished();
    }

    if (!this.props.jobsQuery) return;

    const {
      jobsQuery: { loading },
    } = this.props;

    if (loading) return;

    const { currentPage } = this.state;

    // clamp page to last page
    const lastPage = this.getLastPage();
    if (currentPage > lastPage) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentPage: lastPage });
    }
  }

  getLastPage() {
    const { jobsQuery } = this.props;

    if (jobsQuery.loading) return 0;
    if (!jobsQuery.jobs) return 0;

    return Math.floor(
      Math.max(0, jobsQuery.jobs.jobs.length - 1) / this.state.itemsPerPage,
    );
  }

  toStart() {
    this.setState({
      currentPage: 0,
    });
  }

  toEnd() {
    const {
      jobsQuery: { loading },
    } = this.props;

    if (loading) return;

    this.setState({
      currentPage: this.getLastPage(),
    });
  }

  nextPage() {
    this.setState({
      currentPage: Math.min(this.getLastPage(), this.state.currentPage + 1),
    });
  }

  prevPage() {
    this.setState({
      currentPage: Math.max(0, this.state.currentPage - 1),
    });
  }

  renderButtonBar() {
    return (
      <div className={s.buttonBar}>
        <button className="btn-primary" onClick={this.toStart}>
          <FaAngleDoubleLeft />
        </button>
        <button className="btn-primary" onClick={this.prevPage}>
          <FaAngleLeft />
        </button>
        <div className={s.pageCounter}>
          {this.state.currentPage + 1} / {this.getLastPage() + 1}
        </div>
        <button className="btn-primary" onClick={this.nextPage}>
          <FaAngleRight />
        </button>
        <button className="btn-primary" onClick={this.toEnd}>
          <FaAngleDoubleRight />
        </button>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderJob({ job, index, perfectDanubeScore }) {
    // calculate match score and map it to [0, 100]
    let matchScore = parseFloat(job.danubeScore / perfectDanubeScore);
    matchScore = Math.max(0, Math.min(100, Math.round(matchScore * 100)));

    // get css-class for match score container
    let matchScoreClass = '';
    if (matchScore >= 66) {
      matchScoreClass = s.optimalScore;
    } else if (matchScore >= 50) {
      matchScoreClass = s.goodScore;
    } else {
      matchScoreClass = s.mediumScore;
    }

    return (
      <div key={job.id} className={s.jobContainer}>
        <div className={s.infoContainer}>
          <div className={s.scoreWrapper}>
            <span className={s.titleContainer}>
              <span>
                {index + 1}. {job.title}
              </span>
              <span className={matchScoreClass}>
                {matchScore}% Match
                <br />
                <LearnMoreLink to="/demos/jobs/about" />
              </span>
            </span>
          </div>
          <div className={s.sublineContainer}>
            <FormattedMessage {...messages.field} />: {job.field}
          </div>
          <div className={s.infoLine}>
            {job.companyType && (
              <div>
                <FaBuilding />
                &nbsp;
                {job.companyType}
              </div>
            )}
            {job.jobLevel && (
              <div>
                <FaUser />
                &nbsp;
                {job.jobLevel}
              </div>
            )}
            {(job.salaryFrom || job.salaryTo) && (
              <div className={s.priceInfo}>
                <FaEur />
                &nbsp;
                {job.salaryFrom && <span>Ab {job.salaryFrom}</span>}
                {job.salaryFrom && job.salaryTo && <span> | </span>}
                {job.salaryTo && <span>Bis {job.salaryTo}</span>}
              </div>
            )}
            {job.locations &&
              job.locations.length > 0 && (
                <div>
                  <FaMapMarker />
                  &nbsp;
                  {job.locations.toString().replace(/,/g, ', ')}
                </div>
              )}
            {job.timeDistance !== null && (
              <div>
                <FaClockO /> {Math.round(job.timeDistance / 60)} min
              </div>
            )}
            {job.remoteWork && (
              <div>
                <FaLaptop /> <FormattedMessage {...messages.remoteWorkTag} />
              </div>
            )}
          </div>
        </div>
        <div className={s.tagsList}>
          {job.technologies &&
            job.technologies.map(technology => (
              <div key={technology} className={s.tag}>
                <FaCircle /> {technology}
              </div>
            ))}
        </div>
        <div className={s.benefitsContainer}>
          <button
            onClick={() => {
              const newExtendedEntries = { ...this.state.extendedEntries };
              newExtendedEntries[job.id] = !newExtendedEntries[job.id]; // eslint-disable-line
              this.setState({
                extendedEntries: newExtendedEntries,
              });
            }}
          >
            {this.state.extendedEntries[job.id] ? (
              <FaCaretUp />
            ) : (
              <FaCaretDown />
            )}
            &nbsp;
            <FormattedMessage {...messages.benefitsTag} />
            :&nbsp;
            {job.benefits ? job.benefits.length : 0}
          </button>
          <Collapse in={this.state.extendedEntries[job.id]}>
            <div>
              <ul>
                {job.benefits &&
                  job.benefits.map(benefit => (
                    <li key={benefit} className={s.tag}>
                      {benefit}
                    </li>
                  ))}
              </ul>
            </div>
          </Collapse>
        </div>
      </div>
    );
  }

  render() {
    const { jobsQuery } = this.props;

    if (!jobsQuery) return null;

    if (jobsQuery.loading) {
      return <Loading />;
    }

    if (!jobsQuery.jobs) return null;

    const {
      jobs: { jobs, columnKeys, columnScores },
    } = jobsQuery;
    const mergedColumnKeys = [];
    const mergedColumnScores = [];

    let salaryScore = 0;

    columnKeys.forEach((ck, index) => {
      if (ck === 'salaryFrom' || ck === 'salaryTo') {
        salaryScore += columnScores[index];
      } else {
        mergedColumnKeys.push(ck);
        mergedColumnScores.push(columnScores[index]);
      }
    });

    mergedColumnKeys.unshift('salary');
    mergedColumnScores.unshift(salaryScore);

    // select page of jobs
    const jobsPage = jobs.slice(
      this.state.currentPage * this.state.itemsPerPage,
      (this.state.currentPage + 1) * this.state.itemsPerPage,
    );

    // normalize column scores
    const total = columnScores.reduce((sum, score) => sum + score, 0);
    const normalizedColumnScores = mergedColumnScores.map(
      score => (score / total) * 100,
    );

    // get max column score
    const maxColumnScore = Math.max(...normalizedColumnScores);

    // get the perfect danube score
    const perfectDanubeScore = columnScores.reduce(
      (scoreSum, score) => scoreSum + score,
      0,
    );

    return (
      <div className={s.resultsListContainer}>
        <div className={s.metadataContainer}>
          <div className={s.resultsWrapper}>
            <span className={s.label}>
              {jobs.length}
              &nbsp;
              <FormattedMessage {...globalDemoMessages.resultsFound} />
            </span>
          </div>
          <LearnMoreLink to="/demos/jobs/about" />
          {normalizedColumnScores.length > 0 && (
            <div>
              {normalizedColumnScores.map((score, index) => (
                <div
                  key={`normalizedColumnScore-${index}`} // eslint-disable-line react/no-array-index-key
                  className={
                    score === maxColumnScore
                      ? s.danubeWrapper
                      : s.danubeWrapperLight
                  }
                >
                  <span className={s.label}>
                    <FormattedMessage
                      {...danubeTagsMap[mergedColumnKeys[index]]}
                    />
                    :&nbsp;
                    {Math.round(score)}%
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
        {this.renderButtonBar()}
        <div className={s.jobsContainer}>
          {jobs.length === 0 ? (
            <div className={s.noResultsContainer}>
              <FormattedMessage {...globalDemoMessages.noResults} />
            </div>
          ) : (
            <div>
              {jobsPage.map((job, index) => {
                const rowIndex =
                  this.state.currentPage * this.state.itemsPerPage + index;
                return this.renderJob({
                  job,
                  index: rowIndex,
                  perfectDanubeScore,
                });
              })}
            </div>
          )}
        </div>
        {this.renderButtonBar()}
      </div>
    );
  }
}

const jobsQuery = gql`
  query jobsQuery($filter: JobsFilter) {
    jobs(filter: $filter) {
      jobs {
        id
        title
        field
        salaryFrom
        salaryTo
        locations
        daysAgo
        companyType
        jobLevel
        remoteWork
        technologies
        benefits
        timeDistance
        danubeScore
        matches
      }
      columnKeys
      columnScores
    }
  }
`;

export default compose(
  graphql(jobsQuery, {
    name: 'jobsQuery',
    // eslint-disable-next-line no-unused-vars
    options: ({ filter }) => ({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
    }),
    skip: ({ skipPageLoadQuery }) => skipPageLoadQuery,
  }),
)(withStyles(s)(ResultsList));
