import { defineMessages } from 'react-intl';

const messages = defineMessages({
  packages: {
    id: 'pricing.packages.packages',
    defaultMessage: 'packages',
    description: 'Label for pricing packages.',
  },
  cloudApi: {
    id: 'pricing.packages.cloudApi',
    defaultMessage: 'Cloud-API',
    description: 'Label for pricing packages.',
  },
  onPremise: {
    id: 'pricing.packages.onPremise',
    defaultMessage: 'On-Premise',
    description: 'Label for pricing packages.',
  },
  analyticsDashboard: {
    id: 'pricing.packages.analyticsDashboard',
    defaultMessage: 'Analytics Dashboard',
    description: 'Label for pricing packages.',
  },
  customizedAnalyticsDashboard: {
    id: 'pricing.packages.customizedAnalyticsDashboard',
    defaultMessage: 'Customized Analytics Dashboard',
    description: 'Label for pricing packages.',
  },
  integrationSupport: {
    id: 'pricing.packages.integrationSupport',
    defaultMessage: 'Integration Support',
    description: 'Label for pricing packages.',
  },
  fullIntegrationSupport: {
    id: 'pricing.packages.fullIntegrationSupport',
    defaultMessage: 'Full Integration Support',
    description: 'Label for pricing packages.',
  },
  continousSupport: {
    id: 'pricing.packages.continousSupport',
    defaultMessage: 'Continuous Support for Live-Version',
    description: 'Label for pricing packages.',
  },
  fullContinousSupport: {
    id: 'pricing.packages.fullContinousSupport',
    defaultMessage: 'Complete Technical Support for Live-Version',
    description: 'Label for pricing packages.',
  },
  interactionAnalysis: {
    id: 'pricing.packages.interactionAnalysis',
    defaultMessage: 'Interaction Analysis',
    description: 'Label for pricing packages.',
  },
});

export default messages;
