import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col } from 'react-bootstrap';

import Loading from '../../../../components/Loading';
import s from './LandingPage.scss';
import SearchMaskForm from '../SearchMask/SearchMaskForm';
import ResultsList from '../ResultsList';

class LandingPage extends React.Component {
  static propTypes = {
    superPowersQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      superPowers: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    benefitsQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      superHeroBenefits: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      skipPageLoadQuery: true,
    };

    this.onSearch = this.onSearch.bind(this);
    this.scrollToResults = this.scrollToResults.bind(this);

    this.resultListRef = React.createRef();
  }

  onSearch(formValues) {
    const filter = { ...formValues.filter };

    // reformat some parameters
    if ('salaryFrom' in filter && filter.salaryFrom != null) {
      filter.salaryFrom = parseFloat(filter.salaryFrom);
    }
    if ('salaryTo' in filter && filter.salaryTo != null) {
      filter.salaryTo = parseFloat(filter.salaryTo);
    }
    if ('strength' in filter) {
      if (filter.strength == null) filter.strength = [];
      else filter.strength = filter.strength.map(st => st.value);
    }
    if ('social' in filter) {
      if (filter.social == null) filter.social = [];
      else filter.social = filter.social.map(so => so.value);
    }
    if ('superPowers' in filter) {
      if (filter.superPowers == null) filter.superPowers = [];
      else filter.superPowers = filter.superPowers.map(sp => sp.value);
    }
    if ('benefits' in filter) {
      if (filter.benefits == null) filter.benefits = [];
      else filter.benefits = filter.benefits.map(b => b.value);
    }

    this.setState({
      skipPageLoadQuery: false,
      filter,
    });
  }

  scrollToResults() {
    if (this.resultListRef.current) {
      window.scroll({
        top: this.resultListRef.current.offsetTop + 74,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  render() {
    const { superPowersQuery, benefitsQuery } = this.props;

    if (!superPowersQuery || !benefitsQuery) return null;

    if (superPowersQuery.loading || benefitsQuery.loading) {
      return <Loading />;
    }

    return (
      <div className={s.landingPageContainer}>
        <Row>
          <Col mdOffset={2} md={8}>
            <SearchMaskForm
              onSubmit={this.onSearch}
              superPowers={superPowersQuery.superPowers}
              benefits={benefitsQuery.superHeroBenefits}
              initialValues={{
                filter: {
                  experienceImportance: 0.5,
                  travelTimeImportance: 0.5,
                  salaryImportance: 0.5,
                  strength: [],
                  social: [],
                  superPowers: [],
                  benefits: [],
                },
              }}
            />
            <div ref={this.resultListRef}>
              <ResultsList
                filter={this.state.filter}
                skipPageLoadQuery={this.state.skipPageLoadQuery}
                onLoadingFinished={this.scrollToResults}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const superPowersQuery = gql`
  query superPowersQuery {
    superPowers
  }
`;

const benefitsQuery = gql`
  query benefitsQuery {
    superHeroBenefits
  }
`;

export default compose(
  graphql(superPowersQuery, {
    name: 'superPowersQuery',
  }),
  graphql(benefitsQuery, {
    name: 'benefitsQuery',
  }),
)(withStyles(s)(LandingPage));
