import { defineMessages } from 'react-intl';

const messages = defineMessages({
  pressHeader: {
    id: 'press.pressHeader',
    defaultMessage: 'Press',
    description: 'Label for press page header.',
  },
  oneLineHeader: {
    id: 'press.oneLineHeader',
    defaultMessage: 'danube.ai in one line',
    description: 'Label for press text header.',
  },
  lettersHeader: {
    id: 'press.lettersHeader',
    defaultMessage: 'danube.ai in {count} letters',
    description: 'Label for press text header.',
  },
  in1Line: {
    id: 'press.in1Line',
    defaultMessage: `
      danube.ai is a new AI providing personal recommendation without any user data.
    `,
    description: 'Press text for one-liner.',
  },
  in300Letters: {
    id: 'press.in300Letters',
    defaultMessage: `
      danube.ai is a new AI providing personal recommendation without any user data. Technically 
      speaking, it computes the cost for value for every choice matching your personal preferences. 
      On Austria's biggest price-comparison-platform Geizhals, danube.ai has 3-7% interaction rate and 
      2-5% conversion.
    `,
    description: 'Press text for 300 letters.',
  },
  in500Letters: {
    id: 'press.in500Letters',
    defaultMessage: `
      danube.ai is a new artificial intelligence providing personal recommendation without any user data. 
      danube.ai is a decision engine helping users selecting their perfect choice. Technically speaking, 
      it computes the cost for value for every choice matching your personal preferences. The algorithm can 
      be used for recommendations in ecommerce, streaming, recruiting, education and more. On Austria's 
      biggest price-comparison-platform Geizhals, danube.ai has 3-7% interaction rate and 2-5% conversion.
    `,
    description: 'Press text for 500 letters.',
  },
  in1000Letters: {
    id: 'press.in1000Letters',
    defaultMessage: `
      Have you ever wondered about the poor quality of recommendations on Amazon and Netflix? Like most other 
      companies, Amazon and Netflix are using old school AI based on Big Data for their recommendations. 
      danube.ai is a new AI providing personal recommendation without any user data. As a next step beyond 
      Google's search engine, danube.ai is a decision engine helping users selecting their perfect choice. 
      Technically speaking, danube.ai computes the cost for value for every choice matching your personal 
      preferences. The algorithm can be used for superior recommendations in ecommerce, streaming, recruiting, 
      education and more. On Austria's biggest price-comparison-platform Geizhals, danube.ai delivers 
      extraordinary benchmarks: a rate of 3-7% of users interacting with the recommendations and 2-5% 
      conversion rate depending on the product category. In a world that is moving from mass consumption to 
      more and more personalized products, danube.ai offers the right choice for everyone.
    `,
    description: 'Press text for 1000 letters.',
  },
  logoVariations: {
    id: 'press.logoVariations',
    defaultMessage: `Logo variations`,
    description: 'Header for Logo Variations.',
  },
  downloadLogos: {
    id: 'press.downloadLogos',
    defaultMessage: `Download logo variations`,
    description: 'Header for Logo Variations download.',
  },
  inTheNews: {
    id: 'press.inTheNews',
    defaultMessage: `In the news`,
    description: 'Header for News link section.',
  },
});

export default messages;
