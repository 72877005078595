import { defineMessages } from 'react-intl';

const messages = defineMessages({
  label: {
    id: 'cherryTree.downloadArticle.label',
    defaultMessage: 'Download Article',
    description: 'Label for Download Article button',
  },
  download: {
    id: 'cherryTree.downloadArticle.download',
    defaultMessage: 'Click here to download the Article.',
    description: 'Label for Download Article link',
  },
});

export default messages;
