import { defineMessages } from 'react-intl';

const messages = defineMessages({
  label: {
    id: 'cherryTree.downloadPitchDeck.label',
    defaultMessage: 'Download Pitchdeck',
    description: 'Label for Download PitchDeck button',
  },
  download: {
    id: 'cherryTree.downloadPitchDeck.download',
    defaultMessage: 'Click here to download the Pitchdeck.',
    description: 'Label for Download PitchDeck link',
  },
});

export default messages;
