import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import { Row, Col, Panel } from 'react-bootstrap';
import gql from 'graphql-tag';
import { withLogout } from 'components/Auth/withLogout';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import DeleteAccountForm from './DeleteAccountForm';
import messages from '../messages';
import formMessages from '../../ReduxForm/messages';
import s from './DeleteAccount.scss'; // eslint-disable-line css-modules/no-unused-class
import { SuccessAlert, Alert } from '../../Modal';

const DELETE_ACCOUNT_FORM_NAME = 'delete-account';

class DeleteAccount extends React.Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      showDialog: false,
      showSuccess: false,
      showError: false,
      expanded: false,
    };

    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setErrors = this.setErrors.bind(this);
  }

  setErrors(errors) {
    this.setState({
      errors: errors.map(err => err.message),
      showDialog: false,
      showSuccess: false,
      showError: false,
    });
  }

  hideAlert() {
    this.setState({
      showDialog: false,
      showSuccess: false,
      showError: false,
      expanded: false,
    });
  }

  async deleteAccount() {
    const { deleteAccount, deleteLicenseData, password, intl } = this.props;
    let error = false;
    let result;
    try {
      result = await deleteAccount({ password });
    } catch (e) {
      error = e;
    }
    const {
      data: { deleteAccount: response },
    } = result;
    if (!response.success || error) {
      if (response.error) {
        this.setErrors([
          { message: intl.formatMessage(messages[response.error]) },
        ]);
      } else {
        this.setState({
          showDialog: false,
          showSuccess: false,
          showError: true,
        });
      }
    } else {
      await deleteLicenseData();
      this.setState({
        showDialog: false,
        showSuccess: true,
        showError: false,
      });
    }
  }

  async handleSubmit() {
    this.setState({
      showDialog: true,
      showSuccess: false,
      showError: false,
    });
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { showSuccess, showDialog, showError } = this.state;
    const { logout, user } = this.props;
    const caretImage = this.state.expanded
      ? '/caret_dark_expanded.png'
      : '/caret_dark.png';

    return (
      <Panel
        className={s.collapsiblePanel}
        expanded={this.state.expanded}
        onToggle={this.toggleExpanded}
      >
        <Panel.Heading className={s.panelHeading}>
          <div className={s.headingContent}>
            <span className={s.title}>
              <FormattedMessage {...messages.deleteHeading} />
            </span>
          </div>
          <button className={s.expandButton} onClick={this.toggleExpanded}>
            <img src={caretImage} alt="" />
          </button>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body className={s.panelBody}>
            <Row>
              <Col md={12}>
                <DeleteAccountForm
                  form={DELETE_ACCOUNT_FORM_NAME}
                  errors={this.state.errors}
                  onSubmit={this.handleSubmit}
                  initialValues={user}
                />
              </Col>
              <SuccessAlert
                show={showSuccess}
                title={<FormattedMessage {...messages.alertSuccessTitle} />}
                msg={<FormattedMessage {...messages.alertSuccessMessage} />}
                hide={logout}
              />
              <Alert
                show={showDialog}
                title={<FormattedMessage {...messages.reallyRevokeTitle} />}
                msg={<FormattedMessage {...messages.reallyDelete} />}
                hide={this.hideAlert}
                footerClassName={s.alertFooter}
                footer={
                  <div>
                    <button
                      className={`btn btn-orange btn-full-round btn-threeQuartersWidth ${
                        s.save
                      }`}
                      onClick={this.deleteAccount}
                    >
                      <FormattedMessage {...formMessages.remove} />
                    </button>
                    <button
                      className={`btn btn-white-orange btn-full-round btn-threeQuartersWidth ${
                        s.cancel
                      }`}
                      onClick={this.hideAlert}
                    >
                      <FormattedMessage {...formMessages.cancel} />
                    </button>
                  </div>
                }
              />
              <Alert
                show={showError}
                title={<FormattedMessage {...messages.alertErrorTitle} />}
                msg={<FormattedMessage {...messages.alertErrorMessage} />}
                hide={this.hideAlert}
              />
            </Row>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    );
  }
}

const deleteAccountMutation = gql`
  mutation deleteAccount($user: UserInput!) {
    deleteAccount(user: $user) {
      success
      error
    }
  }
`;

const deleteLicenseDataMutation = gql`
  mutation deleteLicenseData {
    deleteLicenseData {
      success
      error
    }
  }
`;

const selector = formValueSelector(DELETE_ACCOUNT_FORM_NAME);
const mapStateToProps = state => ({
  password: selector(state, 'password'),
});

export default compose(
  connect(mapStateToProps),
  injectIntl,
  withRouter,
  withLogout,
  graphql(deleteLicenseDataMutation, {
    props: ({ mutate }) => ({
      deleteLicenseData: () => mutate(),
    }),
  }),
  graphql(deleteAccountMutation, {
    props: ({ mutate }) => ({
      deleteAccount: user =>
        mutate({
          variables: { user },
        }),
    }),
  }),
)(withStyles(s)(DeleteAccount));
