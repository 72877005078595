import PropTypes from 'prop-types';

export const filterType = PropTypes.shape({
  // filter parameters
  localization_address_zip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  type_transferType: PropTypes.string,
  priceInformation_primaryPriceFrom: PropTypes.number,
  priceInformation_primaryPriceTo: PropTypes.number,
  area_total_areaFrom: PropTypes.number,
  area_total_areaTo: PropTypes.number,
  area_numberOfRoomsFrom: PropTypes.number,
  area_numberOfBalconiesFrom: PropTypes.number,
  area_numberOfTerraceFrom: PropTypes.number,
  showSuggestions: PropTypes.bool,
  // sort parameters
  sortLocalization_address_zipDesc: PropTypes.bool,
  sortPriceInformation_primaryPriceDesc: PropTypes.bool,
  sortArea_total_areaDesc: PropTypes.bool,
  sortLocalization_address_zipAsc: PropTypes.bool,
  sortPriceInformation_primaryPriceAsc: PropTypes.bool,
  sortArea_total_areaAsc: PropTypes.bool,
  sortDanubeAsc: PropTypes.bool,
  sortDanubeDesc: PropTypes.bool,
});

export const apartmentType = PropTypes.shape({
  meta_exposeId: PropTypes.string,
  area_effectiveArea: PropTypes.string,
  area_numberOfRooms: PropTypes.string,
  area_numberOfBalconies: PropTypes.number,
  area_numberOfTerrace: PropTypes.number,
  area_gardenArea: PropTypes.string,
  area_parking_CARPORT_count: PropTypes.string,
  area_parking_GARAGE_count: PropTypes.string,
  condition_type: PropTypes.string,
  fitting_category: PropTypes.string,
  localization_address_zip: PropTypes.string,
  priceInformation_hasCommission: PropTypes.string,
  priceInformation_costs_operationalCosts_net: PropTypes.string,
  type_transferType: PropTypes.string,
  type_estateDetailType: PropTypes.string,
  pictures_Count: PropTypes.string,
  area_numberOfBedrooms: PropTypes.string,
  area_balconyArea: PropTypes.string,
  area_terraceArea: PropTypes.string,
  condition_yearOfConstruction: PropTypes.string,
  condition_energyCertification_type: PropTypes.string,
  localization_information_floor: PropTypes.string,
  object_showAddress: PropTypes.string,
  meta_features_BASIC_LISTING_ON_RESULTLIST_active: PropTypes.string,
  meta_features_PREMIUM_LISTING_ON_RESULTLIST_active: PropTypes.string,
  area_living_area: PropTypes.string,
  area_total_area: PropTypes.string,
  priceInformation_primaryPrice: PropTypes.string,
  KundenTyp: PropTypes.string,
  link: PropTypes.string,
  danubeRanking: PropTypes.number,
  priceSortedPosition: PropTypes.number,
});
