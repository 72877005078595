import React from 'react';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Link } from 'react-router-dom';

import s from './Footer.scss'; // eslint-disable-line css-modules/no-unused-class
import wavesStyle from './waves.scss'; // eslint-disable-line css-modules/no-unused-class
import fishStyle from './fish.scss'; // eslint-disable-line css-modules/no-unused-class
import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.renderLink = this.renderLink.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  renderLink(url, message) {
    return (
      <Link to={url}>
        <div className={s.footerLinkWrapper}>
          <span className={s.footerLink}>
            <FormattedMessage {...message} />
          </span>
        </div>
      </Link>
    );
  }

  render() {
    return (
      <div className={s.footerWrapper}>
        <div className={s.footer}>
          <div className={wavesStyle.fishContainer}>
            <Link to="/demos/avengers">
              <img className={fishStyle.fish} src="/fishy_filled.png" alt="" />
            </Link>
            <img
              className={fishStyle.splash1}
              src="/animated_fish/splash_left.png"
              alt=""
            />
            <img
              className={fishStyle.splash2}
              src="/animated_fish/splash_right.png"
              alt=""
            />
          </div>
          <div className={wavesStyle.wavesContainer}>
            <img className={s.blackWave} src="/dark_wave.svg" alt="" />
          </div>
          <div className={`${layoutStyle.noMargin} ${s.footerContent}`}>
            <div className={s.footerColumn}>
              <h1>danube.ai</h1>
            </div>
            <div className={s.footerColumn}>
              <h2>
                <FormattedMessage {...messages.applications} />
              </h2>
              {this.renderLink('/ecommerce', messages.eCommerce)}
              {this.renderLink('/recruiting', messages.recruiting)}
              {this.renderLink('/media', messages.media)}
            </div>
            <div className={s.footerColumn}>
              <h2>
                <FormattedMessage {...messages.learnMore} />
              </h2>
              {this.renderLink('/partners/enliteai', messages.partners)}
              {this.renderLink('/api/get-api-key', messages.api)}
              {this.renderLink('/faq', messages.faq)}
              <br />
            </div>
            <div className={s.footerColumn}>
              <h2>
                <FormattedMessage {...messages.aboutUs} />
              </h2>
              {this.renderLink('/about', messages.team)}
              {this.renderLink('/contact', messages.contact)}
              {this.renderLink('/privacy', messages.privacy)}
              {this.renderLink('/imprint', messages.imprint)}

              <br />
            </div>
          </div>
          <div className={`${s.iconLinksContainer}`}>
            <div className={s.socialMediaLinks}>
              <a
                className={s.socialMediaIconContainer}
                href="https://www.facebook.com/danubify"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/socialMedia-facebook.png" alt="" />
              </a>
              <a
                className={s.socialMediaIconContainer}
                href="https://www.instagram.com/danubify"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/socialMedia-instagram.png" alt="" />
              </a>
              <a
                className={s.socialMediaIconContainer}
                href="https://www.linkedin.com/company/danubify"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/socialMedia-linkedin.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  withStyles(layoutStyle, wavesStyle, fishStyle, s)(Footer),
);
