import { SET_REDIRECT_SOURCE, SET_REDIRECT_ACTION } from '../constants';

export function setRedirectSource({ source }) {
  return {
    type: SET_REDIRECT_SOURCE,
    payload: {
      source,
    },
  };
}

export function setRedirectAction({ action }) {
  return {
    type: SET_REDIRECT_ACTION,
    payload: {
      action,
    },
  };
}
