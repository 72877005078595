/* eslint-disable no-underscore-dangle */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
  FormattedMessage,
  FormattedHTMLMessage,
  intlShape,
  injectIntl,
} from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaChevronDown from 'react-icons/lib/fa/chevron-down';
import { Link } from 'react-router-dom';

import TopNavigation from 'components/TopNavigation';
import { navItemsRight } from 'components/TopNavigation/navItems';
import { componentKeys, ClickTracker } from 'components/Analytics';

import s from './SubLandingPages.scss'; // eslint-disable-line css-modules/no-unused-class
import landingPageStyle from '../LandingPage.scss'; // eslint-disable-line css-modules/no-unused-class
import mainLandingPageStyle from '../MainLandingPage/LandingPageCarousel.scss'; // eslint-disable-line css-modules/no-unused-class
import topNavStyle from '../../TopNavigation/TopNavigation.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import mainLandingPageMessages from '../MainLandingPage/messages';
import globalMessages from '../../messages/globalMessages';
import landingPageMessages from '../messages';
import globalDemoMessages from '../../../demos/globalDemoMessages';
import TrustedBy from '../../TrustedBy';
import Tile from './Tile';
import RecommendationComponent from '../../Recommendation';
import CheckTable from '../Illustrations/CheckTable';
import Footer from '../../Footer';

const scrollToSlide = slideRef => {
  const topOffset = window.innerWidth <= 1080 ? 75 : 100;

  window.scroll({
    top: slideRef.offsetTop - topOffset,
    left: 0,
    behavior: 'smooth',
  });
};

const TryDemoButton = (
  <ClickTracker
    className={s.contentButton}
    componentKey={componentKeys.RECRUITING_DEMO_LINK}
    style={{ display: 'inline-block', marginLeft: '10px' }}
  >
    <Link to="/demos/recruiting">
      <button className="btn-secondary btn-round btn-large">
        <FormattedMessage {...globalDemoMessages.tryIt} />
      </button>
    </Link>
  </ClickTracker>
);

const TryDemoButtonWide = (
  <ClickTracker
    className={s.headerButton}
    componentKey={componentKeys.RECRUITING_DEMO_LINK}
    style={{ display: 'inline-block', marginLeft: '10px' }}
  >
    <Link to="/demos/recruiting">
      {/* eslint-disable-next-line prettier/prettier */}
      <button className={`btn-secondary btn-round btn-large ${s.learnMoreButton}`}>
        <FormattedMessage {...globalDemoMessages.tryIt} />
      </button>
    </Link>
  </ClickTracker>
);

class RecruitingLandingPage extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    recommendations: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  constructor(props) {
    super(props);

    this.frontPageRef = React.createRef();
    this.slide1Ref = React.createRef();

    this.renderFrontPage = this.renderFrontPage.bind(this);
    this.renderSlide1 = this.renderSlide1.bind(this);
    this.renderSlide2 = this.renderSlide2.bind(this);
    this.renderTrustedSlide = this.renderTrustedSlide.bind(this);
    this.renderSlide3 = this.renderSlide3.bind(this);
    this.renderSlide4 = this.renderSlide4.bind(this);
  }

  componentDidMount() {
    if (window.location.hash && window.location.hash === '#content') {
      scrollToSlide(this.slide1Ref.current);
    }
  }

  renderFrontPage() {
    return (
      <div
        className={`${s.frontPage} ${s.marginRemover}`}
        ref={this.frontPageRef}
      >
        <img
          className={`${s.bgImage} ${s.hideOnMobile}`}
          src="/landing_page/WDanube-Landingpage-Recruitment.jpg"
          alt=""
        />
        <img
          className={`${s.bgImage} ${s.hideOnDesktop}`}
          src="/landing_page/WDanube-Landingpage-Recruitment_mobile.jpg"
          alt=""
        />
        <div className={mainLandingPageStyle.bgOverlay} />
        <div className={s.content}>
          <div className={s.inner}>
            <div className={s.leftContent}>
              <h1>
                <FormattedHTMLMessage
                  {...mainLandingPageMessages.recruitmentHeader}
                />
              </h1>
              <ClickTracker
                className={s.headerButton}
                componentKey={
                  componentKeys.RECRUITING_LANDING_PAGE_LEARN_MORE_BUTTON
                }
                style={{ display: 'inline-block' }}
              >
                <button
                  className={`btn-secondary btn-round btn-large ${s.learnMoreButton}`} // eslint-disable-line prettier/prettier
                  onClick={() => {
                    scrollToSlide(this.slide1Ref.current);
                  }}
                >
                  <FormattedMessage {...landingPageMessages.learnMore} />
                </button>
              </ClickTracker>
              {TryDemoButtonWide}
            </div>
          </div>
          <div className={s.downButtonContainer}>
            <ClickTracker
              componentKey={
                componentKeys.RECRUITING_LANDING_PAGE_LEARN_MORE_BUTTON
              }
              style={{ display: 'inline-block' }}
            >
              <button
                onClick={() => {
                  scrollToSlide(this.slide1Ref.current);
                }}
              >
                <FaChevronDown />
              </button>
            </ClickTracker>
          </div>
        </div>
      </div>
    );
  }

  renderSlide1() {
    return (
      <div
        className={`${s.page} ${s.darkBluePage} ${s.marginRemover}`}
        ref={this.slide1Ref}
      >
        <h1>
          <FormattedMessage {...messages.recruitingSlide1Text1} />
        </h1>
        <div className={s.tilesWrapper}>
          <Tile
            imagePath="/landing_page/sub_landing_pages/circle_questionmark.png"
            content={
              <FormattedHTMLMessage {...messages.recruitingSlide1TileText1} />
            }
          />
          <Tile
            imagePath="/landing_page/sub_landing_pages/circle_inside.png"
            content={
              <FormattedHTMLMessage {...messages.recruitingSlide1TileText2} />
            }
          />
          <Tile
            imagePath="/landing_page/sub_landing_pages/arrows_miss.png"
            content={
              <FormattedHTMLMessage {...messages.recruitingSlide1TileText3} />
            }
          />
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSlide2() {
    return (
      <div className={`${s.page} ${s.lightBluePage} ${s.marginRemover}`}>
        <h1>
          <FormattedMessage
            {...messages.recruitingSlide2Text1}
            values={{
              danube: <span className={s.blueText}>danube.ai</span>,
            }}
          />
        </h1>
        <div className={s.tilesWrapper}>
          <Tile
            imagePath="/landing_page/sub_landing_pages/circle_outside_highlighted.png"
            content={
              <FormattedHTMLMessage {...messages.recruitingSlide2TileText1} />
            }
            light
          />
          <Tile
            imagePath="/landing_page/sub_landing_pages/circle_inside_highlighted.png"
            content={
              <FormattedHTMLMessage {...messages.recruitingSlide2TileText2} />
            }
            light
          />
          <Tile
            imagePath="/landing_page/sub_landing_pages/arrow_hit.png"
            content={
              <FormattedHTMLMessage {...messages.recruitingSlide2TileText3} />
            }
            light
          />
        </div>
        <div className={s.centeredButtonContainer}>
          <ClickTracker
            className={s.contentButton}
            componentKey={componentKeys.RECRUITING_LANDING_PAGE_CONTACT_BUTTON}
            style={{ display: 'inline-block' }}
          >
            <Link to="/contact?inquiry=recruiting">
              <button className="btn-secondary btn-round btn-large">
                <FormattedMessage {...globalMessages.getInTouch} />
              </button>
            </Link>
          </ClickTracker>
          {TryDemoButton}
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderTrustedSlide() {
    return (
      <div className={s.marginRemover}>
        <TrustedBy
          partners={[
            {
              imagePath: '/partners/devjobs.png',
              url: 'https://devjobs.at/',
            },
          ]}
        />
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSlide3() {
    return (
      <div className={`${s.page} ${s.darkBluePage} ${s.marginRemover}`}>
        <h1>
          <FormattedMessage
            {...messages.recruitingSlide3Text1}
            values={{
              danube: <span className={s.blueText}>danube.ai</span>,
            }}
          />
        </h1>
        <div className={s.illustrationWrapper}>
          <CheckTable
            tableRows={[
              [
                null,
                <b className={s.blueText}>danube.ai</b>,
                <span className={s.greyText}>
                  <FormattedMessage {...messages.recruitingSlide3Text2} />
                </span>,
              ],
              [
                <span className={s.blueText}>
                  <FormattedMessage {...messages.recruitingSlide3Text3} />
                </span>,
                true,
                false,
              ],
              [
                <span className={s.blueText}>
                  <FormattedMessage {...messages.recruitingSlide3Text4} />
                </span>,
                true,
                false,
              ],
              [
                <span className={s.blueText}>
                  <FormattedMessage {...messages.recruitingSlide3Text5} />
                </span>,
                true,
                false,
              ],
              [
                <span className={s.blueText}>
                  <FormattedMessage {...messages.recruitingSlide3Text6} />
                </span>,
                true,
                false,
              ],
              [
                <span className={s.blueText}>
                  <FormattedMessage {...messages.recruitingSlide3Text7} />
                </span>,
                true,
                false,
              ],
              [
                <span className={s.blueText}>
                  <FormattedMessage {...messages.recruitingSlide3Text8} />
                </span>,
                true,
                false,
              ],
            ]}
          />
        </div>
        <div className={s.centeredButtonContainer}>
          <ClickTracker
            className={s.contentButton}
            componentKey={componentKeys.RECRUITING_LANDING_PAGE_CONTACT_BUTTON}
            style={{ display: 'inline-block' }}
          >
            <Link to="/contact?inquiry=recruiting">
              <button className="btn-secondary btn-round btn-large">
                <FormattedMessage {...globalMessages.getInTouch} />
              </button>
            </Link>
          </ClickTracker>
          {TryDemoButton}
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSlide4() {
    return (
      <div className={`${s.page} ${s.lightBluePage}  ${s.marginRemover}`}>
        <h1>
          <FormattedMessage {...messages.recruitingSlide4Text1} />
        </h1>
        <div className={s.illustrationWrapper}>
          <table>
            <tbody>
              <tr>
                <td>
                  <img
                    src="/landing_page/sub_landing_pages/recruiting/applies-unordered.png"
                    alt=""
                    className={s.recrutingAppliesImg1}
                  />
                </td>
                <td>
                  <img
                    src="/landing_page/sub_landing_pages/recruiting/arrow-right.png"
                    alt=""
                    className={s.recrutingAppliesImg2}
                  />
                </td>
                <td>
                  <img
                    src="/landing_page/sub_landing_pages/recruiting/applies-ordered.png"
                    alt=""
                    className={s.recrutingAppliesImg3}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <div className={s.recrutingAppliesText1}>
                    <FormattedMessage {...messages.recruitingSlide4Text2} />
                  </div>
                </td>
                <td />
                <td>
                  <div className={s.recrutingAppliesText3}>
                    <FormattedMessage
                      {...messages.recruitingSlide4Text3}
                      values={{
                        danube: (
                          <b>
                            <span className={s.blueText}>danube.ai</span>
                          </b>
                        ),
                      }}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {/*
          <img
            src="/landing_page/sub_landing_pages/recruiting/graph_2.png"
            alt=""
          />
          */}
        </div>
        <div className={s.centeredButtonContainer}>
          <ClickTracker
            className={s.contentButton}
            componentKey={componentKeys.RECRUITING_LANDING_PAGE_CONTACT_BUTTON}
            style={{ display: 'inline-block' }}
          >
            <Link to="/contact?inquiry=recruiting">
              <button className="btn-secondary btn-round btn-large">
                <FormattedMessage {...globalMessages.getInTouch} />
              </button>
            </Link>
          </ClickTracker>
          {TryDemoButton}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <TopNavigation
          navItemsRight={navItemsRight}
          // customLogoOnClick={() => scrollToSlide(this.frontPageRef.current)}
        />
        {this.renderFrontPage()}
        {this.renderSlide1()}
        {this.renderSlide2()}
        {this.renderTrustedSlide()}
        {this.renderSlide3()}
        {this.renderSlide4()}
        <div className={s.marginRemover}>
          <RecommendationComponent useDarkMode />
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(
  injectIntl(
    withStyles(
      s,
      topNavStyle,
      buttonStyle,
      landingPageStyle,
      mainLandingPageStyle,
    )(RecruitingLandingPage),
  ),
);

/* eslint-enable no-underscore-dangle */
