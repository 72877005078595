import { defineMessages } from 'react-intl';

const messages = defineMessages({
  getApiKey: {
    id: 'apiButton.getApiKey',
    defaultMessage: 'Get API Key',
    description: 'text for api button.',
  },
  modalHeader: {
    id: 'apiButton.modalHeader',
    defaultMessage: 'Select your API key',
    description: 'text for product selection modal.',
  },
  modalApiHeader: {
    id: 'apiButton.apiHeader',
    defaultMessage: 'Choose your API',
    description: 'text for product selection modal.',
  },
  modalPricingHeader: {
    id: 'apiButton.pricingHeader',
    defaultMessage: 'Choose your package',
    description: 'text for product selection modal.',
  },
  modalPricingInfoHeader: {
    id: 'apiButton.pricingInfoHeader',
    defaultMessage: 'Package details',
    description: 'text for product selection modal.',
  },
  modalPricingInfoLink: {
    id: 'apiButton.pricingInfoLink',
    defaultMessage: 'Compare our packages here',
    description: 'text for product selection modal.',
  },
  danubePredicitionTrial: {
    id: 'apiButton.danubePredicitionTrial',
    defaultMessage: 'Predicition API Trial',
    description: 'text for product selection modal.',
  },
  danubeRecommendationTrial: {
    id: 'apiButton.danubeRecommendationTrial',
    defaultMessage: 'Recommendation API Trial',
    description: 'text for product selection modal.',
  },
  modalHeaderLoading: {
    id: 'apiButton.modalHeaderLoading',
    defaultMessage: 'Loading',
    description: 'text for product selection modal.',
  },
  pleaseWait: {
    id: 'apiButton.pleaseWait',
    defaultMessage: 'Please wait...',
    description: 'text for product selection modal.',
  },
  modalHeaderSuccess: {
    id: 'apiButton.modalHeaderSuccess',
    defaultMessage: 'Success',
    description: 'text for product selection modal.',
  },
  successMessage1: {
    id: 'apiButton.successMessage1',
    defaultMessage: `
      Your API key has been successfully created.
    `,
    description: 'text for product selection modal.',
  },
  successMessage2: {
    id: 'apiButton.successMessage2',
    defaultMessage: `
      You can also find your generated API keys under <i>User > Account</i>.
    `,
    description: 'text for product selection modal.',
  },
  modalHeaderError: {
    id: 'apiButton.modalHeaderError',
    defaultMessage: 'Error',
    description: 'text for product selection modal.',
  },
  errorMessage: {
    id: 'apiButton.errorMessage',
    defaultMessage: `
      Failed to create a new API key. Please check if you already own a key for the selected product under User > Account.
    `,
    description: 'text for product selection modal.',
  },
  cancel: {
    id: 'apiButton.cancel',
    defaultMessage: 'Cancel',
    description: 'text for product selection modal.',
  },
  submit: {
    id: 'apiButton.submit',
    defaultMessage: 'Get Key',
    description: 'text for product selection modal.',
  },
  close: {
    id: 'apiButton.close',
    defaultMessage: 'Close',
    description: 'text for product selection modal.',
  },
  noLicensesAvaiable: {
    id: 'apiButton.noLicensesAvaiable',
    defaultMessage: 'No licenses available.',
    description: 'text for product selection modal.',
  },
  ownedLicenses: {
    id: 'apiButton.ownedLicenses',
    defaultMessage: 'Owned licenses:',
    description: 'text for product selection modal.',
  },
  productAlreadyOwned: {
    id: 'pricing.productAlreadyOwned',
    defaultMessage:
      'You already own an key for this API. Use "Upgrade License" in detail view.',
    description: 'Label for product already owned error message.',
  },

  netPriceNote: {
    id: 'pricing.netPriceNote',
    defaultMessage: 'requests and net prices per month',
    description: 'Label for pricing page text.',
  },
  oneTimeNote: {
    id: 'pricing.oneTimeNote',
    defaultMessage: 'one-time request contingent',
    description: 'Label for pricing page text.',
  },
});

export default messages;
