export const codeStyle = {
  padding: '10px',
  border: 'none',
  background: 'white',
  fontSize: '18px',
};

/* eslint-disable prettier/prettier */

export const preliminaryCode = (
`
headers: {
  "apitoken": "Bearer <my-api-key>",
}
`.trim());

export const setRulesRequest = (
  `
body: {
  "query": String,
  "variables": {
    "id": String,
    "override": Boolean,
    "rules": [Rule]
  }
}
`.trim());

export const ruleType = (
  `
type Rule: {
  "property": String,
  "type": String,
  "equalityScores": [EqualityScore],
  "maxRuleValue": Float,
  "minRuleValue": Float,
  "clamping": Boolean
}
`.trim());

export const equalityScoreType = (
  `
type EqualityScore: {
  "value:": String,
  "score:": Float
}
`.trim());

export const setRulesResponse = (
  `
{
  "data": {
    "setRules": Boolean
  }
}
`.trim());

export const setRulesExample = (
  `
body: {
  "query": "mutation ($id: String!, $override: Boolean, $rules: [ClientRule]!) { setRules(id: $id, override: $override, rules: $rules) }",
  "variables": {
    "id": "my-rules-set-1",
    "override": true,
    "rules": [
      {
        "property": "salaryFrom",
        "type": "PERCENTAGE"
      },
      {
        "property": "salaryTo",
        "type": "PERCENTAGE"
      },
      {
        "property": "daysAgo",
        "type": "INVERSE_PERCENTAGE"
      },
      {
      "property": "companyType",
      "type": "EQUALS",
      "equalityScores": [
          {
            "value": "Startup",
            "score": 1
          },
          {
            "value": "Digital agency",
            "score": 2
          },
          {
            "value": "Established company",
            "score": 3
          }
        ]
      },
      {
        "property": "jobLevel",
        "type": "EQUALS",
        "equalityScores": [
          {
            "value": "Junior",
            "score": 1
          },
          {
            "value": "Experienced",
            "score": 2
          },
          {
            "value": "Senior",
            "score": 3
          },
          {
            "value": "Lead",
            "score": 4
          }
        ]
      },
      {
        "property": "technologies",
        "type": "OVERLAP_GLOBAL"
      },
      {
        "property": "benefits",
        "type": "OVERLAP_LOCAL"
      }
    ]
  }
}
`.trim());

export const setRulesExampleResult = (
  `
{
  "data": {
    "setRules": true
  }
}
`.trim());

export const danubePredictionRequest = (
  `
body: {
  "query": String,
  "variables": {
    "data": PredictionInputData
  }
}
`.trim());

export const predictionInputDataType = (
  `
type PredictionInputData: {
  "rulesId": String,
  "data": String,
  "searchData": String,
  "initialColumnScores": [ColumnScore],
  "strategy": String,
  "mixFactor": Float,
  "impact": Float
}
`.trim());

export const columnScoreType = (
  `
type ColumnScore: {
  "property": String,
  "score": Float
}
`.trim());

export const danubePredictionResponse = (
  `
{
  "data": {
    "danubePrediction": {
      "newColumnScores": [ColumnScore],
      "rowScores": [Float],
      "rowMatches": [[Float]]
    }
  }
}
`.trim());

export const danubePredictionExampleData = (
  `
const testData = [
  {
    id: 0,
    title: "job-1",
    field: "Test/QA",
    salaryFrom: 36000,
    salaryTo: 50000,
    daysAgo: 240,
    companyType: "Startup",
    jobLevel: "Experienced",
    technologies: ["Python", "Java", "C++", "C"],
    benefits: ["Flexible working hours", "Team events"]
  },
  {
    id: 1,
    title: "job-2",
    field: "Software",
    salaryFrom: 42000,
    salaryTo: 60000,
    daysAgo: 100,
    companyType: "Established company",
    // jobLevel missing --> data may be incomplete
    technologies: ["Git", "Docker", "JavaScript"]
    // benefits missing --> data may be incomplete
  },
];

const stringifiedTestData = JSON.stringify(testData);
`.trim());

export const danubePredictionExampleSearchData = (
  `
const testSearchData = {
  companyType: ["Startup"],
  jobLevel: ["Junior", "Experienced"],
  technologies: ["SQL", "Java", "Linux"],
  benefits: ["Flexible working hours", "Home office"]
};

const stringifiedTestSearchData = JSON.stringify(testSearchData);
`.trim());

export const danubePredictionExample = (
  `
body: {
  "query": "mutation ($data: PredictionInputData!) { danubePrediction(data: $data) { newColumnScores { property, score }, rowScores, rowMatches } }",
  "variables": {
    "data": {
      "rulesId": "my-rules-set-1",
      "data": {{stringifiedTestData}},
      "searchData": {{stringifiedTestSearchData}},
      "initialColumnScores": [
        {"property": "salaryFrom", "score": 1},
        {"property": "salaryTo", "score": 1},
        {"property": "daysAgo", "score": 0.5}, // might be initialized less important
        {"property": "companyType", "score": 1},
        {"property": "jobLevel", "score": 1},
        {"property": "technologies", "score": 2}, // might be initialized more important
        {"property": "benefits", "score": 1}
      ],
      "strategy": "mixed",
      "mixFactor": 0.75,
      "impact": 1
    }
  }
}
`.trim());

export const danubePredictionExampleResult = (
  `
{
  "data": {
    "danubePrediction": {
      "newColumnScores": [
        {"property": "salaryFrom", "score": 0.97},
        {"property": "salaryTo", "score": 0.97},
        {"property": "daysAgo", "score": 1.15},
        {"property": "companyType", "score": 0.70},
        {"property": "jobLevel", "score": 1.56},
        {"property": "technologies", "score": 1.20},
        {"property": "benefits", "score": 0.95}
      ],
      "rowScores": [3.99, 2.61, ...],
      "rowMatches": [
        [0.83, 0.83, 0.00, 1.00, 0.00, 1.00, 0.50],
        [1.00, 1.00, 0.58, 0.00, 0.00, 0.00, 0.00],
        ...
      ]
    }
  }
}
`.trim());

export const danubeRecommendationRequest = (
  `
body: {
  "query": String,
  "variables": {
    "data": RecommendationInputData
  }
}
`.trim());

export const recommendationInputDataType = (
  `
type RecommendationInputData: {
  "data": String,
  "n": Integer
}
`.trim());

export const danubeRecommendationResponse = (
  `
{
  "data": {
    "danubeRecommendation": {
      "correlatedData": String
    }
  }
}
`.trim());

export const resetCorrelationMatrixRequest = (
  `
body: {
  "query": String
}
`.trim());

export const resetCorrelationMatrixResponse = (
  `
{
  "data": {
    "resetCorrelationMatrix": Boolean
  }
}
`.trim());

export const danubeRecommendationExample1Data = (
  `
const testData = [
  {"page": "Homepage"},
  {"page": "Dashboard"}
];

const stringifiedTestData = JSON.stringify(testData);
`.trim());

export const danubeRecommendationExample1 = (
  `
body: {
  "query": "mutation ($data: RecommendationInputData!) { danubeRecommendation(data: $data) { correlatedData } }",
  "variables": {
    "data": {
      "data": {{stringifiedTestData}},
      "n": 3
    }
  }
}
`.trim());

export const danubeRecommendationExample1Result = (
  `
{
  "data": {
    "danubeRecommendation": {
      "correlatedData": {{stringifiedCorrelatedData}}
    }
  }
}
`.trim());

export const danubeRecommendationExample1ResultData = (
  `
const correlatedData = JSON.parse(stringifiedCorrelatedData);

/*
[
  {"page": "About"},
  {"page": "Imprint"},
  {"page": "Data protection guidelines"}
]
*/
`.trim());

export const danubeRecommendationExample2Data = (
  `
const testData = [
  {"page": "Homepage"},
  {"page": "Dashboard"},
  {"page": "About"}
];

const stringifiedTestData = JSON.stringify(testData);
`.trim());

export const danubeRecommendationExample2 = (
  `
body: {
  "query": "mutation ($data: RecommendationInputData!) { danubeRecommendation(data: $data) { correlatedData } }",
  "variables": {
    "data": {
      "data": {{stringifiedTestData}},
      "n": 3
    }
  }
}
`.trim());

export const danubeRecommendationExample2Result = (
  `
{
  "data": {
    "danubeRecommendation": {
      "correlatedData": {{stringifiedCorrelatedData}}
    }
  }
}
`.trim());

export const danubeRecommendationExample2ResultData = (
  `
const correlatedData = JSON.parse(stringifiedCorrelatedData);

/*
[
  {"page": "Imprint"},
  {"page": "Data protection guidelines"},
  {"page": "Account Settings"}
]
*/
`.trim());

export const resetCorrelationMatrixExample = (
  `
body: {
  "query": "mutation { resetCorrelationMatrix }"
}
`.trim());

export const resetCorrelationMatrixExampleResult = (
  `
{
  "data": {
    "resetCorrelationMatrix": true
  }
}
`.trim());

export const getRulesRequest = (
  `
body: {
  "query": String
}
`.trim());

export const getRulesResponse = (
  `
body: {
  "data": {
    "getRules": [RulesSet]
  }
}
`.trim());

export const getRulesResponseRulesSet = (
  `
type RulesSet: {
  "id": String,
  "rules": [Rule]
}
`.trim());

export const getRulesExampleSetup1 = (
  `
{
  "query": "mutation ($id: String!, $override: Boolean, $rules: [ClientRule]!) { setRules(id: $id, override: $override, rules: $rules) }",
  "variables": {
    "id": "test-rules-1",
    "override": true,
    "rules": [
      {
        "property": "p1",
        "type": "PERCENTAGE"
      },
      {
        "property": "p2",
        "type": "INVERSE_PERCENTAGE"
      }
    ]
  }
}
`.trim());

export const getRulesExampleSetup2 = (
  `
{
  "query": "mutation ($id: String!, $override: Boolean, $rules: [ClientRule]!) { setRules(id: $id, override: $override, rules: $rules) }",
  "variables": {
    "id": "test-rules-2",
    "override": true,
    "rules": [
      {
        "property": "p3",
        "type": "EQUALS",
        "equalityScores": [
          {
            "value": "v1",
            "score": 1
          },
          {
            "value": "v2",
            "score": 2
          }
        ]
      },
      {
        "property": "p4",
        "type": "OVERLAP_GLOBAL"
      }
    ]
  }
}
`.trim());

export const getRulesExampleRequest = (
  `
{
  "query": "query { getRules { id rules { property type equalityScores { value score } minRuleValue maxRuleValue clamping } } }"
}
`.trim());

export const getRulesExampleResult = (
  `
{
  "data": {
    "getRules": [
      {
        "id": "test-rules-1",
        "rules": [
          {
            "property": "p1",
            "type": "PERCENTAGE",
            "equalityScores": null,
            "minRuleValue": null,
            "maxRuleValue": null,
            "clamping": null
          },
          {
            "property": "p2",
            "type": "INVERSE_PERCENTAGE",
            "equalityScores": null,
            "minRuleValue": null,
            "maxRuleValue": null,
            "clamping": null
          }
        ]
      },
      {
        "id": "test-rules-2",
        "rules": [
          {
            "property": "p3",
            "type": "EQUALS",
            "equalityScores": [
              {
                "value": "v1",
                "score": 1
              },
              {
                "value": "v2",
                "score": 2
              }
            ],
            "minRuleValue": null,
            "maxRuleValue": null,
            "clamping": null
          },
          {
            "property": "p4",
            "type": "OVERLAP_GLOBAL",
            "equalityScores": null,
            "minRuleValue": null,
            "maxRuleValue": null,
            "clamping": null
          }
        ]
      }
    ]
  }
}
`.trim());

export const percentageRuleFormulas = (
  `
// Clamping:
clampedValue = max(min(value, maxRuleValue), minRuleValue)

// Normalizing:
normalizedValue = (clampedValue - minRuleValue) / (maxRuleValue - minRuleValue)
`.trim());

/* eslint-enable prettier/prettier */
