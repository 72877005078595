import { defineMessages } from 'react-intl';

const messages = defineMessages({
  // tags
  priceTag: {
    id: 'demo.geizhals.results.priceTag',
    defaultMessage: `Price`,
    description: 'label for field in results list',
  },
  memoryTag: {
    id: 'demo.geizhals.results.memoryTag',
    defaultMessage: `Memory`,
    description: 'label for field in results list',
  },
  ramTag: {
    id: 'demo.geizhals.results.ramTag',
    defaultMessage: `RAM`,
    description: 'label for field in results list',
  },
  weightTag: {
    id: 'demo.geizhals.results.weightTag',
    defaultMessage: `Weight`,
    description: 'label for field in results list',
  },
  maxConversationTimeTag: {
    id: 'demo.geizhals.results.maxConversationTimeTag',
    defaultMessage: `Conversation time`,
    description: 'label for field in results list',
  },
  displayInchesTag: {
    id: 'demo.geizhals.results.displayInchesTag',
    defaultMessage: `Display (inches)`,
    description: 'label for field in results list',
  },
  displayPixelsTag: {
    id: 'demo.geizhals.results.displayPixelsTag',
    defaultMessage: `Display (px)`,
    description: 'label for field in results list',
  },
  displayPpiTag: {
    id: 'demo.geizhals.results.displayPpiTag',
    defaultMessage: `Display (ppi)`,
    description: 'label for field in results list',
  },
  cameraFrontAmountTag: {
    id: 'demo.geizhals.results.cameraFrontAmountTag',
    defaultMessage: `Front cameras`,
    description: 'label for field in results list',
  },
  cameraFrontHighestMpTag: {
    id: 'demo.geizhals.results.cameraFrontHighestMpTag',
    defaultMessage: `Front camera MP`,
    description: 'label for field in results list',
  },
  cameraBackAmountTag: {
    id: 'demo.geizhals.results.cameraBackAmountTag',
    defaultMessage: `Back cameras`,
    description: 'label for field in results list',
  },
  cameraBackHighestMpTag: {
    id: 'demo.geizhals.results.cameraBackHighestMpTag',
    defaultMessage: `Back camera MP`,
    description: 'label for field in results list',
  },
  batteryTag: {
    id: 'demo.geizhals.results.batteryTag',
    defaultMessage: `Battery (W)`,
    description: 'label for field in results list',
  },

  // best in slot tags
  bestSlotPriceTag: {
    id: 'demo.geizhals.results.bestSlotPriceTag',
    defaultMessage: `Lowest price`,
    description: 'label for field in results list',
  },
  bestSlotMemoryTag: {
    id: 'demo.geizhals.results.bestSlotMemoryTag',
    defaultMessage: `Largest memory`,
    description: 'label for field in results list',
  },
  bestSlotRamTag: {
    id: 'demo.geizhals.results.bestSlotRamTag',
    defaultMessage: `Largest RAM`,
    description: 'label for field in results list',
  },
  bestSlotWeightTag: {
    id: 'demo.geizhals.results.bestSlotWeightTag',
    defaultMessage: `Lowest weight`,
    description: 'label for field in results list',
  },
  bestSlotMaxConversationTimeTag: {
    id: 'demo.geizhals.results.bestSlotMaxConversationTimeTag',
    defaultMessage: `Longest conversation time`,
    description: 'label for field in results list',
  },
  bestSlotSmallestDisplayInchesTag: {
    id: 'demo.geizhals.results.bestSlotSmallestDisplayInchesTag',
    defaultMessage: `Smallest display`,
    description: 'label for field in results list',
  },
  bestSlotLargestDisplayInchesTag: {
    id: 'demo.geizhals.results.bestSlotLargestDisplayInchesTag',
    defaultMessage: `Largest display`,
    description: 'label for field in results list',
  },
  bestSlotDisplayPixelsTag: {
    id: 'demo.geizhals.results.bestSlotDisplayPixelsTag',
    defaultMessage: `Most pixels`,
    description: 'label for field in results list',
  },
  bestSlotDisplayPpiTag: {
    id: 'demo.geizhals.results.bestSlotDisplayPpiTag',
    defaultMessage: `Highest ppi`,
    description: 'label for field in results list',
  },
  bestSlotCameraFrontAmountTag: {
    id: 'demo.geizhals.results.bestSlotCameraFrontAmountTag',
    defaultMessage: `Most front cameras`,
    description: 'label for field in results list',
  },
  bestSlotCameraFrontHighestMpTag: {
    id: 'demo.geizhals.results.bestSlotCameraFrontHighestMpTag',
    defaultMessage: `Highest MPs on front camera`,
    description: 'label for field in results list',
  },
  bestSlotCameraBackAmountTag: {
    id: 'demo.geizhals.results.bestSlotCameraBackAmountTag',
    defaultMessage: `Most back cameras`,
    description: 'label for field in results list',
  },
  bestSlotCameraBackHighestMpTag: {
    id: 'demo.geizhals.results.bestSlotCameraBackHighestMpTag',
    defaultMessage: `Highest MPs on back camera`,
    description: 'label for field in results list',
  },
  bestSlotBatteryTag: {
    id: 'demo.geizhals.results.bestSlotBatteryTag',
    defaultMessage: `Highest battery power`,
    description: 'label for field in results list',
  },
});

export default messages;
