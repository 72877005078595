import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SyntaxHighlighter from 'react-syntax-highlighter';

import s from '../../../Page.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../../messages';
import wikiMessages from '../../../messages';
import {
  codeStyle,
  danubeRecommendation1,
  danubeRecommendation2,
} from '../../codeBlocks';

class DanubePredicition extends React.Component {
  render() {
    return (
      <div className={s.apiText}>
        <p>
          <h1>
            <FormattedHTMLMessage {...wikiMessages.danubeRecommendation} />
          </h1>
          <FormattedHTMLMessage {...messages.sdkDanubeRecommendationText1} />
        </p>
        <p>
          <h2>
            <FormattedMessage {...messages.method} />
          </h2>
          <FormattedHTMLMessage {...messages.sdkDanubeRecommendationText2} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {danubeRecommendation1}
          </SyntaxHighlighter>
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {danubeRecommendation2}
          </SyntaxHighlighter>
        </p>
      </div>
    );
  }
}

export default withStyles(s)(DanubePredicition);
