import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';

import { ClickTracker } from 'components/Analytics';

import s from '../WhitepaperButton/WhitepaperButton.scss'; // eslint-disable-line css-modules/no-unused-class
import HubspotAlert from '../HubspotAlert';

const messages = defineMessages({
  label: {
    id: 'downloadCaseStudy.label',
    defaultMessage: 'Download Case Study',
    description: 'Label for DownloadCaseStudy button',
  },
  download: {
    id: 'downloadCaseStudy.download',
    defaultMessage: 'Click here to view the case study.',
    description: 'Label for DownloadCaseStudy link',
  },
});

class DownloadCaseStudy extends React.Component {
  static propTypes = {
    localeToDocumentLinkMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    localeToFormIdMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    clickTrackerComponentKey: PropTypes.string,
  };

  static defaultProps = {
    clickTrackerComponentKey: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      showRequestModal: false,
      showLink: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.setState({ showLink: true });
  }

  render() {
    const { localeToFormIdMap, clickTrackerComponentKey } = this.props;
    const { showRequestModal, showLink } = this.state;

    let buttonComp = (
      <button
        onClick={() => {
          this.setState({ showRequestModal: true });
        }}
        className="btn-orange btn-high btn-large btn-full-round"
        style={{ padding: '10px 30px' }}
      >
        <FormattedMessage {...messages.label} />
      </button>
    );

    if (clickTrackerComponentKey != null) {
      buttonComp = (
        <ClickTracker componentKey={clickTrackerComponentKey}>
          {buttonComp}
        </ClickTracker>
      );
    }

    return (
      <div>
        {buttonComp}
        <HubspotAlert
          show={showRequestModal}
          onHide={() => {
            this.setState({
              showRequestModal: false,
              showLink: false,
            });
          }}
          title={<FormattedMessage {...messages.label} />}
          postMsg={
            showLink ? (
              <div className={s.downloadLinkContainer}>
                <a
                  href={this.props.localeToDocumentLinkMap[this.props.locale]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage {...messages.download} />
                </a>
                <img src="/fishy_blue_horizontal.png" alt="" />
              </div>
            ) : null
          }
          localeToFormIdMap={localeToFormIdMap}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locale: state && state.intl ? state.intl.locale : null,
});

export default connect(mapStateToProps)(withStyles(s)(DownloadCaseStudy));
