/* eslint-disable no-shadow */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Nav, NavDropdown, MenuItem } from 'react-bootstrap';

import { setLocale } from '../../actions/intl';

const localeDict = {
  /* @intl-code-template '${lang}-${COUNTRY}': '${Name}', */
  'en-US': 'English',
  'de-DE': 'Deutsch',
  /* @intl-code-template-end */
};

class LanguageSwitcher extends React.Component {
  static propTypes = {
    currentLocale: PropTypes.string.isRequired,
    availableLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
    setLocale: PropTypes.func.isRequired,
    className: PropTypes.string,
    dropDownClassName: PropTypes.string,
    pullRight: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    dropDownClassName: null,
    pullRight: false,
  };

  render() {
    const { currentLocale, availableLocales, setLocale } = this.props;
    // const isSelected = locale => locale === currentLocale;
    const localeName = locale => localeDict[locale] || locale;

    return (
      <Nav
        className={this.props.className}
        pullRight={this.props.pullRight}
        onSelect={e => {
          setLocale({ locale: e.slice('lang.'.length) });
        }}
      >
        <NavDropdown
          className={this.props.dropDownClassName}
          eventKey="lang"
          title={localeName(currentLocale)}
          id="locale-nav-dropdown"
        >
          {availableLocales.map(locale => (
            <MenuItem key={locale} eventKey={`lang.${locale}`}>
              {localeName(locale)}
            </MenuItem>
          ))}
        </NavDropdown>
      </Nav>

      /*
      <div>
        {availableLocales.map(locale => (
          <span key={locale}>
            {isSelected(locale) ? (
              <span>{localeName(locale)}</span>
            ) : (
              <a
                href={`?lang=${locale}`}
                onClick={e => {
                  setLocale({ locale });
                  e.preventDefault();
                }}
              >
                {localeName(locale)}
              </a>
            )}{' '}
          </span>
        ))}
      </div>
      */
    );
  }
}

const mapState = state => ({
  availableLocales: state.runtime.availableLocales,
  currentLocale: state.intl.locale,
});

const mapDispatch = {
  setLocale,
};

export default connect(
  mapState,
  mapDispatch,
)(LanguageSwitcher);
