import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { injectIntl, intlShape } from 'react-intl';
import _ from 'lodash';

import Loading from 'components/Loading';
import Alert, { alertOpts } from 'components/Alert';

import messages from './messages';
import UserEditForm from './EditForm';

class UserEdit extends React.Component {
  static propTypes = {
    userQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
      }),
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        username: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    updateUser: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeAlert = this.closeAlert.bind(this);

    this.state = {
      tabs: {
        activeKey: 'userData',
      },
      alert: {
        ...alertOpts.success,
      },
    };
  }

  handleTabSelect(key) {
    this.setState({ tabs: { activeKey: key } });
  }

  async handleSubmit(formValues) {
    try {
      await this.props.updateUser({
        ..._.omit(formValues, ['__typename']),
      });
      this.setState({ alert: { show: true } });
    } catch (e) {
      this.setState({
        alert: {
          ...alertOpts.error,
          show: true,
          msg: e.message,
        },
      });
    }
  }

  closeAlert() {
    this.setState({ alert: { show: false } });
  }

  render() {
    const {
      userQuery: { loading, user },
      intl,
    } = this.props;
    const { alert } = this.state;
    if (loading) return <Loading />;
    return (
      <div>
        <Tabs
          id="user-edit-tabs"
          activeKey={this.state.tabs.activeKey}
          onSelect={this.handleTabSelect}
          animation={false}
        >
          <Tab
            eventKey="userData"
            title={intl.formatMessage(messages.userDataTabTitle)}
          >
            <Row style={{ marginTop: '15px' }}>
              <Col xs={12}>
                <UserEditForm
                  initialValues={{
                    id: user.id,
                    username: user.username,
                    active: user.active,
                  }}
                  onSubmit={this.handleSubmit}
                />
              </Col>
            </Row>
          </Tab>
          <Tab
            eventKey="settings"
            title={intl.formatMessage(messages.settingsTabTitle)}
          >
            <Row style={{ marginTop: '15px' }}>
              <Col xs={12}>additional settings</Col>
            </Row>
          </Tab>
        </Tabs>
        <Alert {...alert} onConfirm={this.closeAlert} />
      </div>
    );
  }
}

const userQuery = gql`
  query userQuery($username: String!) {
    user(username: $username) {
      id
      username
      active
    }
  }
`;

const updateUserMutation = gql`
  mutation updateUser($user: UserInput!) {
    updateUser(user: $user) {
      id
      username
      active
    }
  }
`;

export default compose(
  graphql(userQuery, {
    name: 'userQuery',
    options: ({ match }) => ({
      variables: {
        username: match.params.username,
        filter: {},
      },
    }),
  }),
  graphql(updateUserMutation, {
    props: ({ mutate }) => ({
      updateUser: user =>
        mutate({
          variables: { user },
        }),
    }),
  }),
)(injectIntl(UserEdit));
