import { defineMessages } from 'react-intl';

const messages = defineMessages({
  label: {
    id: 'cherryTree.downloadScientificPaper.label',
    defaultMessage: 'Download Scientific Paper',
    description: 'Label for Scientific Paper button',
  },
  download: {
    id: 'cherryTree.downloadScientificPaper.download',
    defaultMessage: 'Click here to download the Scientific Paper.',
    description: 'Label for Scientific Paper link',
  },
});

export default messages;
