import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-bootstrap';
// import FaTwitter from 'react-icons/lib/fa/twitter';
import FaLinkedin from 'react-icons/lib/fa/linkedin';

import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from './AboutUs.scss';
import PersonCard from './PersonCard';

const personsData = [
  {
    imageUrl: '/profiles/cherry-tree/Philipp.png',
    name: 'Philipp Wissgott',
    title: 'CEO',
    socialLinks: [
      {
        icon: <FaLinkedin />,
        url: 'https://at.linkedin.com/in/philippwissgott',
      },
    ],
    email: 'philipp@danube.ai',
  },
  {
    imageUrl: '/profiles/cherry-tree/Klemens.png',
    name: 'Klemens Senn',
    title: 'Cloud & DevOps',
    socialLinks: [
      {
        icon: <FaLinkedin />,
        url: 'https://at.linkedin.com/in/klemenssenn',
      },
    ],
    email: 'klemens@danube.ai',
  },
  {
    imageUrl: '/profiles/cherry-tree/Andreas.png',
    name: 'Andreas Roschal',
    title: 'CTO',
    email: 'andreas@danube.ai',
  },
  {
    imageUrl: '/profiles/cherry-tree/Martin.png',
    name: 'Martin Bär',
    title: 'AI Engineer',
    socialLinks: [
      {
        icon: <FaLinkedin />,
        url: 'https://www.linkedin.com/in/martin-baer-nlp',
      },
    ],
    email: 'martin@danube.ai',
  },
  {
    imageUrl: '/profiles/cherry-tree/TingTing.png',
    name: 'Yiguang Wang',
    title: 'Art, AI & Data',
    email: 'yiguang@danube.ai',
  },
  {
    imageUrl: '/profiles/cherry-tree/Heinz.png',
    name: 'Heinz Popovic',
    title: 'Business Advisor',
    socialLinks: [
      {
        icon: <FaLinkedin />,
        url: 'https://www.linkedin.com/in/heinzpopovic',
      },
    ],
    email: 'heinz@popovic.cc',
  },
  {
    imageUrl: '/profiles/cherry-tree/Gabi.png',
    name: 'Gabriele Schwab',
    title: 'Communications & Ethics',
    email: 'gabriele@danube.ai',
  },
  {
    imageUrl: '/profiles/cherry-tree/Matthias.png',
    name: 'Matthias Maschek',
    title: 'Product Dev',
    email: 'matthias@danube.ai',
  },
];

class Press extends React.Component {
  render() {
    return (
      <div className={s.aboutUsContainer}>
        <div className={s.wavesContainer}>
          <img src="/waves_static.png" alt="" />
        </div>
        <div className={s.submarineContainer}>
          <img className={s.submarineTop} src="/submarine-top.png" alt="" />
          <div className={s.submarineBody}>
            <div className={s.backgroundContainer} />
            <div className={s.profilesContainer}>
              <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
                {personsData.map((personData, index) => (
                  // eslint-disable-next-line
                  <Col key={`person-${index}`} xs={12} sm={6} md={4} lg={3}>
                    <PersonCard {...personData} />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          <img
            className={s.submarineBottom}
            src="/submarine-bottom.png"
            alt=""
          />
        </div>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle)(Press);
