import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { Link } from 'react-router-dom';

import layoutStyle from '../../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from '../../../Page.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../../messages';
import wikiMessages from '../../../messages';
import {
  codeStyle,
  danubeRecommendationExampleCall,
  danubeRecommendationExampleReturn,
} from '../../codeBlocks';

class DanubeRecommendationExamples extends React.Component {
  render() {
    return (
      <div className={s.apiText}>
        <p>
          <h1>
            <FormattedMessage {...wikiMessages.danubeRecommendation} />
            {' - '}
            <FormattedMessage {...wikiMessages.examples} />
          </h1>
          <FormattedMessage
            {...messages.examplesText3}
            values={{
              endpoint: (
                <Link
                  to="/api/sdk/documentation/recommendation/danube-recommendation"
                  target="_blank"
                >
                  <FormattedMessage {...wikiMessages.danubeRecommendation} />
                </Link>
              ),
              danubeClient: (
                <i>
                  <FormattedMessage
                    {...messages.danubePredictionOverviewDanubeClient}
                  />
                </i>
              ),
            }}
          />
          <br />
          <br />
          <FormattedHTMLMessage {...messages.sdkExamplesText} />
        </p>
        <p>
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {danubeRecommendationExampleCall}
          </SyntaxHighlighter>
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {danubeRecommendationExampleReturn}
          </SyntaxHighlighter>
        </p>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle)(DanubeRecommendationExamples);
