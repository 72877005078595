import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import copy from 'copy-to-clipboard';
import { FaClipboard, FaCheck } from 'react-icons/lib/fa';

import Loading from 'components/Loading';
import Table from 'components/Table';

import s from './Hashes.scss';
import ModalStyle from '../../Modal/Modal.scss'; // eslint-disable-line css-modules/no-unused-class
import { Alert } from '../../Modal';
import { DB_DATE_FORMAT } from '../../../util';
import CreateHashForm from './CreateHashForm';
import messages from './messages';
import { pageKeys } from '../keys';

const dateFormatExact = 'DD.MM.YYYY HH:mm:ss';

const sortDates = format => (a, b) => {
  if (a === b) return 0;

  const timeA = moment(a, format);
  const timeB = moment(b, format);

  if (!timeA.isValid()) return -1;
  if (!timeB.isValid()) return 1;

  return Math.sign(timeA.diff(timeB, 'seconds'));
};

class HashesView extends React.Component {
  static propTypes = {
    logHashesQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      logHashes: PropTypes.arrayOf(
        PropTypes.shape({
          hash: PropTypes.string,
          pageKey: PropTypes.string,
          description: PropTypes.string,
        }),
      ).isRequired,
    }).isRequired,
    createLogHash: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showUrlModal: false,
      url: null,
      errors: [],
      copiedUrl: null,
    };

    this.createHash = this.createHash.bind(this);
    this.parseUrl = this.parseUrl.bind(this);
  }

  async createHash(formValues) {
    const { createLogHash } = this.props;

    try {
      const result = await createLogHash({
        pageKey: formValues.pageKey.value,
        description: formValues.description,
      });

      if (!result || !result.data || !result.data.createLogHash) {
        this.setState({ errors: ['unexpected error'] });
        return;
      }

      const logHash = result.data.createLogHash;

      this.setState({
        showUrlModal: true,
        url: this.parseUrl(logHash),
        errors: [],
      });
    } catch (e) {
      this.setState({ errors: [e.message] });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  parseUrl(logHash) {
    let page = '';

    switch (logHash.pageKey) {
      case pageKeys.LANDING_PAGE: {
        page = '';
        break;
      }
      case pageKeys.LANDING_PAGE_MEDIA: {
        page = '/media';
        break;
      }
      case pageKeys.LANDING_PAGE_ECOMMERCE: {
        page = '/ecommerce';
        break;
      }
      case pageKeys.LANDING_PAGE_RECRUITING: {
        page = '/recruiting';
        break;
      }
      case pageKeys.RECRUITING_DEMO: {
        page = '/demos/recruiting';
        break;
      }
      default:
        page = '';
        break;
    }

    return `${window.location.host}${page}?token=${logHash.hash}`;
  }

  render() {
    const {
      logHashesQuery: { loading },
    } = this.props;

    if (loading) return <Loading />;

    const { logHashes } = this.props.logHashesQuery;
    const { errors } = this.state;

    return (
      <div className={s.viewContainer}>
        <CreateHashForm onSubmit={this.createHash} />
        {errors &&
          errors.map(error => (
            <p
              className="bg-danger"
              style={{ marginTop: '10px', padding: '5px' }}
            >
              {error}
            </p>
          ))}
        <hr />
        <Table
          tableId="log-hashes-table"
          data={logHashes}
          columns={[
            {
              Header: 'Hash',
              accessor: 'hash',
              width: 140,
            },
            {
              Header: 'Page Key',
              accessor: 'pageKey',
            },
            {
              Header: 'Description',
              accessor: 'description',
              width: 300,
            },
            {
              Header: 'Url',
              Cell: row => {
                const url = this.parseUrl(row.original);
                return (
                  <div>
                    <button
                      className={s.copyButton}
                      onClick={() => {
                        copy(url);
                        this.setState({ copiedUrl: url });
                      }}
                    >
                      {url === this.state.copiedUrl ? (
                        <FaCheck />
                      ) : (
                        <FaClipboard />
                      )}
                    </button>
                    {url}
                  </div>
                );
              },
              width: 450,
            },
            {
              Header: 'Created at',
              id: 'createdAt',
              accessor: user =>
                moment(user.createdAt, DB_DATE_FORMAT).format(dateFormatExact), // eslint-disable-line prettier/prettier
              sortMethod: sortDates(dateFormatExact),
              width: 185,
            },
            {
              Header: 'Updated at',
              id: 'updatedAt',
              accessor: user =>
                moment(user.updatedAt, DB_DATE_FORMAT).format(dateFormatExact), // eslint-disable-line prettier/prettier
              sortMethod: sortDates(dateFormatExact),
              width: 185,
            },
          ]}
          tableSettings={{
            defaultSorted: [
              {
                id: 'createdAt',
                desc: true,
              },
            ],
          }}
        />
        <Alert
          show={this.state.showUrlModal}
          dialogClassName={`${ModalStyle.defaultDialog} ${s.dialog}`}
          title={<FormattedMessage {...messages.urlModalTitle} />}
          msg={
            <div>
              <button
                className={s.copyButton}
                onClick={() => {
                  copy(this.state.url);
                  this.setState({ copiedUrl: this.state.url });
                }}
              >
                {this.state.url === this.state.copiedUrl ? (
                  <FaCheck />
                ) : (
                  <FaClipboard />
                )}
              </button>
              {this.state.url}
            </div>
          }
          footer={
            <div>
              <button
                className="btn-primary btn-round"
                style={{ margin: '0 10px' }}
                onClick={() => {
                  this.setState({ showUrlModal: false });
                }}
              >
                <FormattedMessage {...messages.close} />
              </button>
            </div>
          }
          hide={() => {
            this.setState({ showUrlModal: false });
          }}
        />
      </div>
    );
  }
}

const logHashesQuery = gql`
  query logHashesQuery {
    logHashes {
      hash
      pageKey
      description
      createdAt
      updatedAt
    }
  }
`;

const createLogHashMutation = gql`
  mutation createLogHash($pageKey: String!, $description: String) {
    createLogHash(pageKey: $pageKey, description: $description) {
      hash
      pageKey
      description
    }
  }
`;

export default compose(
  graphql(logHashesQuery, {
    name: 'logHashesQuery',
  }),
  graphql(createLogHashMutation, {
    props: ({ mutate }) => ({
      createLogHash: ({ pageKey, description }) =>
        mutate({
          variables: { pageKey, description },
          refetchQueries: () => [
            {
              query: logHashesQuery,
            },
          ],
        }),
    }),
  }),
)(withStyles(ModalStyle, s)(HashesView));
