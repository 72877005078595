import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './DeleteAccountForm.scss';

import Loading from '../../Loading';
import messages from '../messages';
import render from '../../ReduxForm/renderField';
import validations from '../../modules/form/validations';
import validationMessages from '../../ReduxForm/messages';

const validate = values => {
  const errors = {};
  if (!validations.required(values.username)) {
    errors.username = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.password)) {
    errors.password = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.passwordControll)) {
    errors.passwordControll = (
      <FormattedMessage {...validationMessages.required} />
    );
  }
  if (!(values.passwordControll === values.password)) {
    errors.passwordControll = (
      <FormattedMessage {...validationMessages.passwordConfirmation} />
    );
  }

  return errors;
};

class DeleteAccountForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
    user: PropTypes.shape({
      id: PropTypes.number,
      username: PropTypes.string,
    }),
    initialValues: PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    errors: [],
    user: {
      id: 0,
      username: '',
    },
  };

  render() {
    const { handleSubmit, errors, initialValues } = this.props;
    if (!initialValues) {
      return <Loading />;
    }
    return (
      <div className={s.deleteAccountForm}>
        <form className="default-form" onSubmit={handleSubmit}>
          <fieldset>
            <Field
              id="deleteUsername"
              name="username"
              type="text"
              label={<FormattedMessage {...messages.username} />}
              component={render.renderInput}
              disabled
            />
            <Field
              id="deletePassword"
              name="password"
              type="password"
              label={<FormattedMessage {...messages.password} />}
              component={render.renderInput}
            />
            {errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {errors.map(err => (
                  <li key={err} className="validationError">
                    {err}
                  </li>
                ))}
              </ul>
            )}
            <button type="submit" className="btn-secondary btn-round btn-large">
              <FormattedMessage {...messages.deleteSubmit} />
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  validate,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(withStyles(s)(injectIntl(DeleteAccountForm)));
