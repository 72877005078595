import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SyntaxHighlighter from 'react-syntax-highlighter';

// import GetApiKeyButton from 'components/ApiKey/GetApiKeyButton';

import s from '../../Page.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../messages';
import wikiMessages from '../../messages';
import { codeStyle, npmInstall, yarnInstall } from '../codeBlocks';

class ApiWiki extends React.Component {
  render() {
    return (
      <div className={s.apiText}>
        <p>
          <h1>
            <FormattedMessage {...wikiMessages.installSdk} />
          </h1>
          <h2>
            <FormattedMessage {...messages.about} />
          </h2>
          <FormattedHTMLMessage {...messages.sdkDescription} />
        </p>
        <p>
          <h2>
            <FormattedMessage {...messages.sdkSubscription} />
          </h2>
          <FormattedHTMLMessage {...messages.sdkSubscriptionDescription} />
          {/* <br />
          <br />
          <GetApiKeyButton /> */}
        </p>
        <p>
          <h2>
            <FormattedMessage {...messages.sdkInstallation} />
          </h2>
          <FormattedHTMLMessage {...messages.sdkInstallationDescription1} />
          <br />
          <br />
          <SyntaxHighlighter language="shell" customStyle={codeStyle}>
            {npmInstall}
          </SyntaxHighlighter>
          <SyntaxHighlighter language="shell" customStyle={codeStyle}>
            {yarnInstall}
          </SyntaxHighlighter>
        </p>
      </div>
    );
  }
}

export default withStyles(s)(ApiWiki);
