import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col } from 'react-bootstrap';
import { injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loading from 'components/Loading';
import Alert, { alertOpts } from 'components/Alert';

import s from './UserSettings.scss';
import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import EditPassword from './changePassword/EditPassword';
import RevokePrivacyAgreement from './revokePrivacyAgreement/RevokePrivacyAgreement';
import DeleteAccount from './deleteAccount/DeleteAccount';

class UserEdit extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.closeAlert = this.closeAlert.bind(this);

    this.state = {
      alert: {
        ...alertOpts.success,
      },
    };
  }

  closeAlert() {
    this.setState({ alert: { show: false } });
  }

  render() {
    const {
      user: { loading },
    } = this.props;
    const { alert } = this.state;
    if (loading) return <Loading />;

    return (
      <div className={s.userSettingsContainer}>
        <div>
          <Row className={layoutStyle.noMargin}>
            <Col sm={12} md={8} lg={6}>
              <EditPassword user={this.props.user} />
            </Col>
          </Row>
          <Row className={layoutStyle.noMargin}>
            <Col sm={12} md={8} lg={6}>
              <RevokePrivacyAgreement user={this.props.user} />
            </Col>
          </Row>
          <Row className={layoutStyle.noMargin}>
            <Col sm={12} md={8} lg={6}>
              <DeleteAccount user={this.props.user} />
            </Col>
          </Row>
        </div>
        <Alert {...alert} onConfirm={this.closeAlert} />
      </div>
    );
  }
}

const userQuery = gql`
  query userQuery {
    me {
      id
      username
    }
  }
`;
export default compose(
  withStyles(s, layoutStyle),
  graphql(userQuery, {
    name: 'user',
    props: ({ user }) => ({
      user: {
        ...user.me,
        loading: user.loading,
        refetch: user.refetch,
      },
    }),
  }),
)(injectIntl(UserEdit));
