import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import {
  ALGORITHM_STRATEGY_FAIR,
  ALGORITHM_STRATEGY_EXCLUSIVE,
  ALGORITHM_STRATEGY_MIXED,
  ALGORITHM_STRATEGY_INVERSE,
} from '../../../../constants';

import s from './DataPreSettings.scss'; // eslint-disable-line css-modules/no-unused-class
import layoutStyle from '../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../messages';
import resultTableMessages from '../../../ResultsTable/messages';
import render from '../../../ReduxForm/renderField';
import * as normalizers from '../../../ReduxForm/normalizers';

class DataSettings extends React.Component {
  static defaultProps = {
    enableRowAndColSettings: false,
    onDataSettingsApplied: null,
    showApplyButton: false,
  };

  static propTypes = {
    formValues: PropTypes.shape({
      templateName: PropTypes.any,
      settings: PropTypes.shape({
        strategy: PropTypes.string,
      }).isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
    showImportSettings: PropTypes.bool.isRequired,
    showGlobalMaximum: PropTypes.bool.isRequired,
    showApplyButton: PropTypes.bool,
    enableRowAndColSettings: PropTypes.bool,
    formName: PropTypes.string.isRequired,
    onDataSettingsApplied: PropTypes.func,
  };

  render() {
    const { intl, enableRowAndColSettings, onDataSettingsApplied } = this.props;
    return (
      <Row>
        <Col lg={6}>
          <Row className={`${layoutStyle.noMargin} ${s.row}`}>
            <Col className={`${layoutStyle.noPadding} ${s.col}`} md={5}>
              <FormattedMessage {...messages.idColumn} />
            </Col>
            <Col className={`${layoutStyle.noPadding} ${s.col}`} md={7}>
              <Field
                id="settings.uniqueIdColumn"
                name="settings.uniqueIdColumn"
                type="number"
                component={render.renderInput}
                disabled={!enableRowAndColSettings}
              />
            </Col>
          </Row>
        </Col>

        <Col lg={6}>
          <Row className={`${layoutStyle.noMargin} ${s.row}`}>
            <Col className={`${layoutStyle.noPadding} ${s.col}`} md={5}>
              <FormattedMessage {...messages.initialValueRow} />
            </Col>
            <Col className={`${layoutStyle.noPadding} ${s.col}`} md={7}>
              <Field
                id="settings.initialValueRow"
                name="settings.initialValueRow"
                type="number"
                component={render.renderInput}
                disabled={!enableRowAndColSettings}
              />
            </Col>
          </Row>
        </Col>

        <Col lg={6}>
          <Row className={`${layoutStyle.noMargin} ${s.row}`}>
            <Col className={`${layoutStyle.noPadding} ${s.col}`} md={5}>
              <FormattedMessage {...messages.strategy} />
            </Col>
            <Col className={`${layoutStyle.noPadding} ${s.col}`} md={7}>
              <Field
                id="settings.strategy"
                name="settings.strategy"
                component={render.renderSelect}
              >
                <option value={ALGORITHM_STRATEGY_FAIR}>
                  {intl.formatMessage(messages.fair)}
                </option>
                <option value={ALGORITHM_STRATEGY_EXCLUSIVE}>
                  {intl.formatMessage(messages.exclusive)}
                </option>
                <option value={ALGORITHM_STRATEGY_MIXED}>
                  {intl.formatMessage(messages.mixed)}
                </option>
                <option value={ALGORITHM_STRATEGY_INVERSE}>
                  {intl.formatMessage(messages.inverse)}
                </option>
              </Field>
            </Col>
          </Row>
        </Col>

        <Col lg={6}>
          <Row className={`${layoutStyle.noMargin} ${s.row}`}>
            <Col className={`${layoutStyle.noPadding} ${s.col}`} md={5}>
              <FormattedMessage {...messages.mixingFactor} />
            </Col>
            <Col className={`${layoutStyle.noPadding} ${s.col}`} md={7}>
              <Field
                id="settings.mixFactor"
                name="settings.mixFactor"
                formName={this.props.formName}
                interval={{ min: 0, max: 1 }}
                stepSize={0.05}
                disabled={
                  !this.props.formValues ||
                  !this.props.formValues.settings ||
                  this.props.formValues.settings.strategy !==
                    ALGORITHM_STRATEGY_MIXED
                }
                component={render.renderSlider}
              />
            </Col>
          </Row>
        </Col>

        <Col lg={6}>
          <Row className={`${layoutStyle.noMargin} ${s.row}`}>
            <Col className={`${layoutStyle.noPadding} ${s.col}`} md={5}>
              <FormattedMessage {...messages.algorithmImpact} />
            </Col>
            <Col className={`${layoutStyle.noPadding} ${s.col}`} md={7}>
              <Field
                id="settings.impact"
                name="settings.impact"
                formName={this.props.formName}
                interval={{ min: 1, max: 7 }}
                stepSize={2}
                labelFormatter={value => {
                  switch (value) {
                    case 1:
                      return intl.formatMessage(messages.low);
                    case 3:
                      return intl.formatMessage(messages.medium);
                    case 5:
                      return intl.formatMessage(messages.hard);
                    case 7:
                      return intl.formatMessage(messages.hardest);
                    default:
                      return value;
                  }
                }}
                component={render.renderSlider}
              />
            </Col>
          </Row>
        </Col>

        {this.props.showImportSettings && (
          <Col lg={6}>
            <Row className={`${layoutStyle.noMargin} ${s.row}`}>
              <Col className={`${layoutStyle.noPadding} ${s.col}`} md={5}>
                <FormattedMessage {...messages.initialSort} />
              </Col>
              <Col className={`${layoutStyle.noPadding} ${s.col}`} md={7}>
                <Field
                  id="settings.initialSort"
                  name="settings.initialSort"
                  type="checkbox"
                  component={render.renderCheckbox}
                  normalize={normalizers.booleanNormalizer}
                />
              </Col>
            </Row>
          </Col>
        )}
        {this.props.showGlobalMaximum && (
          <Col lg={6}>
            <Row className={`${layoutStyle.noMargin} ${s.row}`}>
              <Col className={`${layoutStyle.noPadding} ${s.col}`} md={5}>
                <FormattedMessage {...messages.globalMaximum} />
              </Col>
              <Col className={`${layoutStyle.noPadding} ${s.col}`} md={7}>
                <Field
                  id="settings.globalMaximum"
                  name="settings.globalMaximum"
                  type="checkbox"
                  component={render.renderCheckbox}
                  normalize={normalizers.booleanNormalizer}
                />
              </Col>
            </Row>
          </Col>
        )}
        {this.props.showApplyButton && (
          <Col lg={6}>
            <Row className={`${layoutStyle.noMargin} ${s.row}`} md={12}>
              <button
                className="btn-primary"
                style={{ width: '100%', marginTop: '7px' }}
                type="button"
                onClick={() =>
                  onDataSettingsApplied(this.props.formValues.settings)
                }
              >
                <FormattedMessage {...resultTableMessages.apply} />
              </button>
            </Row>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state, props) => ({
  formValues: getFormValues(props.formName)(state),
});

export default injectIntl(
  connect(mapStateToProps)(
    withStyles(s, buttonStyle, layoutStyle)(DataSettings),
  ),
);
