import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import moment from 'moment';

import { componentKeys, ClickTracker } from 'components/Analytics';

import s from './ApiKey.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import ModalStyle from '../Modal/Modal.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import { Alert } from '../Modal';
import ApiModalForm from './ApiModalForm';
import {
  API_PRODUCT_PREDICTION,
  API_PRODUCT_RECOMMENDATION,
  REDIRECT_ACTION_OPEN_API_KEY_MODAL,
} from '../../constants';
import { setRedirectSource, setRedirectAction } from '../../actions/redirect';
import { apiKeysQuery } from '../UserAccount/apiKeys/ApiKeys';

class GetApiKeyButton extends React.Component {
  static propTypes = {
    noButtonStyle: PropTypes.bool,
    meData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      me: PropTypes.shape({
        id: PropTypes.string.isRequired,
        privacyAgreementAccepted: PropTypes.bool.isRequired,
        licenseCustomerId: PropTypes.string,
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
          }),
        ),
      }),
    }).isRequired,
    predictionLicenses: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      licenses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          description: PropTypes.string,
          price: PropTypes.number,
          duration: PropTypes.string,
          credits: PropTypes.number,
          enabled: PropTypes.bool,
          visible: PropTypes.bool,
        }),
      ),
    }).isRequired,
    recommendationLicenses: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      licenses: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          description: PropTypes.string,
          price: PropTypes.number,
          duration: PropTypes.string,
          credits: PropTypes.number,
          data: PropTypes.string,
          enabled: PropTypes.bool,
          visible: PropTypes.bool,
        }),
      ),
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    updateMe: PropTypes.func.isRequired,
    createCustomer: PropTypes.func.isRequired,
    createApiKey: PropTypes.func.isRequired,
    submitApiKeyRequest: PropTypes.func.isRequired,
  };

  static defaultProps = {
    noButtonStyle: false,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: false,
      waitingForResponse: false,
      error: false,
      errorMessage: null,
      success: false,
      apiKey: null,
      creationAllowed: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.renderProductSelectionDialog = this.renderProductSelectionDialog.bind(this); // eslint-disable-line prettier/prettier
    this.renderWaitingDialog = this.renderWaitingDialog.bind(this);
    this.renderSuccessDialog = this.renderSuccessDialog.bind(this);
    this.renderErrorDialog = this.renderErrorDialog.bind(this);

    if (
      props.meData.me &&
      props.redirectAction === REDIRECT_ACTION_OPEN_API_KEY_MODAL
    ) {
      props.setRedirectAction({
        action: null,
      });
      this.state.showModal = true;
    }
  }

  async onSubmit(formValues) {
    const {
      meData: { me },
      updateMe,
      createCustomer,
      createApiKey,
    } = this.props;

    if (!formValues.pricing || !formValues.pricing.value) {
      this.setState({
        waitingForResponse: false,
        success: false,
        error: true,
        errorMessage: 'No pricing selected!',
      });
      return;
    }

    const licenseId = formValues.pricing.value;

    try {
      this.setState({ waitingForResponse: true });

      // check if user already has a customer
      let { licenseCustomerId } = me;
      if (!licenseCustomerId) {
        const createCustomerResult = await createCustomer({
          name: me.username,
          email: me.email,
        });
        licenseCustomerId = createCustomerResult.data.createCustomer;
        await updateMe({
          id: me.id,
          licenseCustomerId,
        });
      }

      const apiKey = await createApiKey({
        licenseCustomerId,
        licenseId,
        startDate: moment().format('YYYY-MM-DD'),
      });

      this.setState({
        waitingForResponse: false,
        success: true,
        apiKey,
      });
    } catch (e) {
      this.setState({
        waitingForResponse: false,
        success: false,
        error: true,
        errorMessage: e.message,
      });
    }
  }

  openModal() {
    const {
      meData,
      predictionLicenses,
      recommendationLicenses,
      match: { path },
      history,
    } = this.props;

    // still loading --> do nothing yet
    if (
      meData.loading ||
      predictionLicenses.loading ||
      recommendationLicenses.loading
    ) {
      return;
    }

    // not logged in
    if (!meData.me) {
      this.props.setRedirectSource({
        source: path,
      });
      this.props.setRedirectAction({
        action: REDIRECT_ACTION_OPEN_API_KEY_MODAL,
      });
      history.push('/login'); // set href directly as history is acting weird
      return;
    } else if (!meData.me.privacyAgreementAccepted) {
      window.location.href = '/privacy-agreement'; // set href directly as history is acting weird
      return;
    }

    this.setState({
      showModal: true,
      waitingForResponse: false,
      error: false,
      errorMessage: null,
      success: false,
      apiKey: null,
      creationAllowed: false,
    });
  }

  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  renderProductSelectionDialog() {
    const {
      meData,
      predictionLicenses,
      recommendationLicenses,
      ownedProducts,
    } = this.props;

    let content = null;

    if (
      !meData.loading &&
      !predictionLicenses.loading &&
      !recommendationLicenses.loading
    ) {
      content = (
        <div className={s.modalContent}>
          <ApiModalForm
            onSubmit={this.onSubmit}
            predictionLicenses={predictionLicenses.licenses}
            recommendationLicenses={recommendationLicenses.licenses}
            ownedProducts={ownedProducts ? ownedProducts.ownedProducts : []}
            onPricingChange={pricing => {
              const hasSelection = pricing && pricing.value;
              this.setState({ creationAllowed: hasSelection });
            }}
          />
          <div style={{ textAlign: 'right' }}>
            <a href="/pricing" target="_blank" rel="noopener noreferrer">
              <FormattedMessage {...messages.modalPricingInfoLink} />
            </a>
          </div>
        </div>
      );
    }

    return (
      <Alert
        show={this.state.showModal}
        dialogClassName={`${ModalStyle.defaultDialog} ${s.dialog}`}
        title={<FormattedMessage {...messages.modalHeader} />}
        msg={content}
        footer={
          <div>
            <button
              className="btn btn-high btn-orange btn-full-round btn-threeQuartersWidth"
              style={{ margin: '5px 10px' }}
              onClick={this.props.submitApiKeyRequest}
              disabled={!this.state.creationAllowed}
            >
              <FormattedMessage {...messages.submit} />
            </button>
            <button
              className="btn btn-high btn-white-orange btn-full-round btn-threeQuartersWidth"
              style={{ margin: '5px 10px' }}
              onClick={this.closeModal}
            >
              <FormattedMessage {...messages.cancel} />
            </button>
          </div>
        }
        hide={this.closeModal}
      />
    );
  }

  renderWaitingDialog() {
    return (
      <Alert
        show={this.state.showModal}
        dialogClassName={`${ModalStyle.defaultDialog} ${s.dialog}`}
        title={<FormattedMessage {...messages.modalHeaderLoading} />}
        msg={<FormattedMessage {...messages.pleaseWait} />}
        footer={<div />}
        // hide={this.closeModal} // prevent hiding of dialog when loading
      />
    );
  }

  renderSuccessDialog() {
    const { apiKey } = this.state;

    let displayKey = '';
    if (apiKey && apiKey.data) {
      if (apiKey.data.createApiKey) {
        displayKey = apiKey.data.createApiKey.token;
      }
    }

    return (
      <Alert
        show={this.state.showModal}
        dialogClassName={`${ModalStyle.defaultDialog} ${s.dialog}`}
        title={<FormattedMessage {...messages.modalHeaderSuccess} />}
        msg={
          <div>
            <div>
              <FormattedMessage {...messages.successMessage1} />
            </div>
            <br />
            <div style={{ textAlign: 'center', wordBreak: 'break-all' }}>
              <b>{displayKey}</b>
            </div>
            <br />
            <div>
              <FormattedHTMLMessage {...messages.successMessage2} />
            </div>
          </div>
        }
        footer={
          <div>
            <button
              className="btn btn-high btn-white-orange btn-full-round btn-threeQuartersWidth"
              style={{ margin: '0 10px' }}
              onClick={this.closeModal}
            >
              <FormattedMessage {...messages.close} />
            </button>
          </div>
        }
        hide={this.closeModal}
      />
    );
  }

  renderErrorDialog() {
    return (
      <Alert
        show={this.state.showModal}
        dialogClassName={`${ModalStyle.defaultDialog} ${s.dialog}`}
        title={<FormattedMessage {...messages.modalHeaderError} />}
        msg={
          this.state.errorMessage || (
            <FormattedMessage {...messages.errorMessage} />
          )
        }
        footer={
          <div>
            <button
              className="btn btn-high btn-white-orange btn-full-round btn-threeQuartersWidth"
              style={{ margin: '0 10px' }}
              onClick={this.closeModal}
            >
              <FormattedMessage {...messages.close} />
            </button>
          </div>
        }
        hide={this.closeModal}
      />
    );
  }

  render() {
    const { noButtonStyle } = this.props;
    const { waitingForResponse, error, success } = this.state;
    let alert = null;

    if (waitingForResponse) {
      alert = this.renderWaitingDialog();
    } else if (success) {
      alert = this.renderSuccessDialog();
    } else if (error) {
      alert = this.renderErrorDialog();
    } else {
      alert = this.renderProductSelectionDialog();
    }

    const getSdkButtonStyle = noButtonStyle
      ? s.noButtonStyle
      : 'btn-primary btn-round btn-large';

    return [
      <ClickTracker
        componentKey={componentKeys.GET_API_KEY_BUTTON}
        style={{ display: 'inline-block' }}
      >
        <button className={getSdkButtonStyle} onClick={this.openModal}>
          <FormattedMessage {...messages.getApiKey} />
        </button>
      </ClickTracker>,
      alert,
    ];
  }
}

const licensesQuery = gql`
  query licenses($product: String!) {
    licenses(product: $product) {
      id
      name
      description
      price
      duration
      credits
      enabled
      visible
    }
  }
`;

const ownedProductsQuery = gql`
  query ownedProducts($licenseCustomerId: String!) {
    ownedProducts(licenseCustomerId: $licenseCustomerId)
  }
`;

const meQuery = gql`
  query me {
    me {
      id
      username
      email
      privacyAgreementAccepted
      licenseCustomerId
      roles {
        id
        name
      }
    }
  }
`;

const updateMeMutation = gql`
  mutation updateMe($user: UserInput!) {
    updateMe(user: $user) {
      id
      username
      email
      privacyAgreementAccepted
      licenseCustomerId
      roles {
        id
        name
      }
    }
  }
`;

const createCustomerMutation = gql`
  mutation createCustomer($name: String!, $email: String!) {
    createCustomer(name: $name, email: $email)
  }
`;

const createApiKeyMutation = gql`
  mutation createApiKey(
    $licenseCustomerId: String!
    $licenseId: String!
    $startDate: String
  ) {
    createApiKey(
      licenseCustomerId: $licenseCustomerId
      licenseId: $licenseId
      startDate: $startDate
    ) {
      id
      name
      description
      enabled
      token
      updatedAt
      validationInterval
      renewable
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  submitApiKeyRequest: () => dispatch(submit('apiModalForm')),
  setRedirectSource: ({ source }) => dispatch(setRedirectSource({ source })),
  setRedirectAction: ({ action }) => dispatch(setRedirectAction({ action })),
});

const mapStateToProps = store => ({
  redirectAction: store.redirect.action,
});

export default compose(
  graphql(meQuery, {
    name: 'meData',
    options: { fetchPolicy: 'network-only' },
  }),
  graphql(ownedProductsQuery, {
    name: 'ownedProducts',
    skip: props =>
      !props.meData || !props.meData.me || !props.meData.me.licenseCustomerId,
    options: props => ({
      variables: {
        licenseCustomerId: props.meData.me.licenseCustomerId,
      },
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(licensesQuery, {
    name: 'predictionLicenses',
    options: () => ({
      variables: {
        product: API_PRODUCT_PREDICTION,
      },
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(licensesQuery, {
    name: 'recommendationLicenses',
    options: () => ({
      variables: {
        product: API_PRODUCT_RECOMMENDATION,
      },
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(updateMeMutation, {
    props: ({ mutate }) => ({
      updateMe: user =>
        mutate({
          variables: { user },
          update: (store, { data }) => {
            // Write our data back to the cache.
            const updatedMe = { me: data.updateMe };
            store.writeQuery({ query: meQuery, data: updatedMe });
          },
        }),
    }),
  }),
  graphql(createCustomerMutation, {
    props: ({ mutate }) => ({
      createCustomer: ({ name, email }) =>
        mutate({
          variables: { name, email },
        }),
    }),
  }),
  graphql(createApiKeyMutation, {
    props: ({ mutate }) => ({
      createApiKey: ({ licenseCustomerId, licenseId, startDate }) =>
        mutate({
          variables: { licenseCustomerId, licenseId, startDate },
          refetchQueries: () => [
            {
              query: apiKeysQuery,
              variables: { licenseCustomerId },
            },
          ],
        }),
    }),
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(withRouter(withStyles(s, buttonStyle, ModalStyle)(GetApiKeyButton)));
