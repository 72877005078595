import { defineMessages } from 'react-intl';

const messages = defineMessages({
  comicText1: {
    id: 'cherryTree.comic.tile1',
    defaultMessage: `On the first warm spring morning of the year a small cherry tree broke through the soil. From its first day this seedling was different. Where others saw flaws, it saw beauty. Where others showed ignorance, it showed empathy.`,
    description: 'Text for Cherry Tree comic',
  },
  comicText2: {
    id: 'cherryTree.comic.tile2',
    defaultMessage: `After a summer of growth, the other trees said "Now, you are supposed to let go of your leaves. Look, all the other trees let them fall.". Our cherry tree replied "I like my leaves, I think I will just keep them."`,
    description: 'Text for Cherry Tree comic',
  },
  comicText3: {
    id: 'cherryTree.comic.tile3',
    defaultMessage: `During the winter, our cherry tree felt a warmth coming from inside. "I'm so happy, with all the beauty in the world.". While the other trees were freezing, our cherry tree kept on growing - with all its leaves on.`,
    description: 'Text for Cherry Tree comic',
  },
  comicText4: {
    id: 'cherryTree.comic.tile4',
    defaultMessage: `In the next spring, our cherry tree started to bloom for the first time. A small bee was the first to land on one of its blossoms. Our tree said "Hi my friend, can I also tell you a story about the beauty in the world?". The little bee felt a warmth inside it never felt before. The word spread, so bees and other animals from all over the world came to visit our tree.`,
    description: 'Text for Cherry Tree comic',
  },
  comicText5: {
    id: 'cherryTree.comic.tile5',
    defaultMessage: `Some time later, our cherry tree got its first cherries. After three weeks, the other cherry trees said "Now, you are supposed to stop creating cherries. Look, all the other cherry trees let them fall.". Our cherry tree replied "I love to offer my cherries, I think I will just keep on creating them."`,
    description: 'Text for Cherry Tree comic',
  },
  comicText6: {
    id: 'cherryTree.comic.tile6',
    defaultMessage: `Time passed and our tree grew bigger every year. It never let go of its leaves and kept creating cherries. It believed that through empathy and believing in itself, it can make a difference. "I just want to bring happiness to the world."`,
    description: 'Text for Cherry Tree comic',
  },
});

export default messages;
