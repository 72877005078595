import { defineMessages } from 'react-intl';

const messages = defineMessages({
  hidden: {
    id: 'demo.recruiting.results.hidden',
    defaultMessage: 'hidden champion',
    description: 'label in results list',
  },
  overrated: {
    id: 'demo.recruiting.results.overrated',
    defaultMessage: 'overrated',
    description: 'label in results list',
  },

  salaryLevelTag: {
    id: 'demo.recruiting.results.salaryLevelTag',
    defaultMessage: 'Salary',
    description: 'label in results list',
  },
  ageTag: {
    id: 'demo.recruiting.results.ageTag',
    defaultMessage: 'Age',
    description: 'label in results list',
  },
  numberOfPreviousEmployersTag: {
    id: 'demo.recruiting.results.numberOfPreviousEmployersTag',
    defaultMessage: 'Number of previous employers',
    description: 'label in results list',
  },
  experienceGeneralTag: {
    id: 'demo.recruiting.results.experienceGeneralTag',
    defaultMessage: 'Experience',
    description: 'label in results list',
  },
  experienceOfficeTag: {
    id: 'demo.recruiting.results.experienceOfficeTag',
    defaultMessage: 'Office experience',
    description: 'label in results list',
  },
  educationTag: {
    id: 'demo.recruiting.results.educationTag',
    defaultMessage: 'Education level',
    description: 'label in results list',
  },
  experienceMsOfficeTag: {
    id: 'demo.recruiting.results.experienceMsOfficeTag',
    defaultMessage: 'MS Office experience',
    description: 'label in results list',
  },
  germanTag: {
    id: 'demo.recruiting.results.germanTag',
    defaultMessage: 'German skills',
    description: 'label in results list',
  },
  englishTag: {
    id: 'demo.recruiting.results.englishTag',
    defaultMessage: 'English skills',
    description: 'label in results list',
  },
  otherLanguagesTag: {
    id: 'demo.recruiting.results.otherLanguagesTag',
    defaultMessage: 'Other known languages',
    description: 'label in results list',
  },
  additionalSkillsTag: {
    id: 'demo.recruiting.results.additionalSkillsTag',
    defaultMessage: 'Additional skills',
    description: 'label in results list',
  },

  bestSlotSalaryLevelTag: {
    id: 'demo.recruiting.results.bestSlotSalaryLevelTag',
    defaultMessage: 'Lowest salary',
    description: 'label in results list',
  },
  bestSlotExperienceGeneralTag: {
    id: 'demo.recruiting.results.bestSlotExperienceGeneralTag',
    defaultMessage: 'Highest experience',
    description: 'label in results list',
  },
  bestSlotExperienceOfficeTag: {
    id: 'demo.recruiting.results.bestSlotExperienceOfficeTag',
    defaultMessage: 'Highest office experience',
    description: 'label in results list',
  },
  bestSlotMsOfficeLevelTag: {
    id: 'demo.recruiting.results.bestSlotMsOfficeLevelTag',
    defaultMessage: 'Highest MS Office experience',
    description: 'label in results list',
  },
  bestSlotGermanLevelTag: {
    id: 'demo.recruiting.results.bestSlotGermanLevelTag',
    defaultMessage: 'Best German skills',
    description: 'label in results list',
  },
  bestSlotEnglishLevelTag: {
    id: 'demo.recruiting.results.bestSlotEnglishLevelTag',
    defaultMessage: 'Best English skills',
    description: 'label in results list',
  },
  bestSlotOtherLanguagesTag: {
    id: 'demo.recruiting.results.bestSlotOtherLanguagesTag',
    defaultMessage: 'Most other known languages',
    description: 'label in results list',
  },
});

export default messages;
