import React from 'react';

import PageProperties from 'components/PageProperties';
import JobsDemoLearnMore from 'demos/devJobs/components/LearnMore';
import routeMessages from 'routes/messages';

class JobsDemoLearnMoreWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.demoPageTitle,
            description: routeMessages.demoDescription,
            keywords: routeMessages.demoKeywords,
          }}
        />
        <JobsDemoLearnMore />
      </div>
    );
  }
}

export default JobsDemoLearnMoreWrapper;
