import React from 'react';

import PageProperties from 'components/PageProperties';
import ImmoDemo from 'demos/immo/components/LandingPage';
import routeMessages from 'routes/messages';

class ImmoDemoWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.demoPageTitle,
            description: routeMessages.demoDescription,
            keywords: routeMessages.demoKeywords,
          }}
        />
        <ImmoDemo />
      </div>
    );
  }
}

export default ImmoDemoWrapper;
