import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withLogout } from 'components/Auth/withLogout';

import s from './NotFound.scss';

class Header extends React.Component {
  render() {
    return (
      <div className={s.notFoundWrapper}>
        <img className={s.deadFish} src="/fishy_dead.svg" alt="" />
        <p className={s.fishNotFound}>Error 404</p>
      </div>
    );
  }
}

export default withLogout(withStyles(s)(Header));
