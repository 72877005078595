import React from 'react';
import PropTypes from 'prop-types';

import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import RouteBundler from 'components/RouteBundler';
import Dashboard from 'components/Dashboard';
import UserAccount from 'components/UserAccount';
import UserSettings from 'components/UserSettings';
import ResultsWrapper from '../results';

/* eslint-disable prettier/prettier */
const routes = [
  { path: '', component: Dashboard, exact: true },
  { path: 'dashboard', component: Dashboard, exact: true },
  { path: 'account', component: UserAccount },
  { path: 'settings', component: UserSettings },
  { path: 'results', component: ResultsWrapper, exact: true},
];
/* eslint-enable prettier/prettier */

class Admin extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    return (
      <RouteBundler
        prefix={`${this.props.match.path}/`}
        routes={routes}
        me={this.props.me}
      />
    );
  }
}

export default AuthenticationWrapper(Admin);
