import { defineMessages } from 'react-intl';

const devjobsMessages = defineMessages({
  statistics_1: {
    id: 'showcase.devjobs.statistics_1',
    defaultMessage: 'Customised',
    description: 'Label for statistics_1 in statistics module of showcase',
  },
  statisticsSublabel_1: {
    id: 'showcase.devjobs.statisticsSublabel_1',
    defaultMessage: 'Your Preferences Matter',
    description:
      'Sub-Label for statisticsSublabel_1 in statistics module of showcase',
  },
  statistics_2: {
    id: 'showcase.devjobs.statistics_2',
    defaultMessage: 'Matches Delivered',
    description: 'Label for statistics_2 statistics module of showcase',
  },
  statisticsSublabel_2: {
    id: 'showcase.devjobs.statisticsSublabel_2',
    defaultMessage: 'Your Personal Dream Job',
    description: 'Label for statisticsSublabel_2 statistics module of showcase',
  },
  statistics_3: {
    id: 'showcase.devjobs.statistics_3',
    defaultMessage: 'Satisfaction Rate',
    description: 'Label for statistics_3 statistics module of showcase',
  },
  statisticsSublabel_3: {
    id: 'showcase.devjobs.statisticsSublabel_3',
    defaultMessage: 'Matching jobs only, no spamming',
    description: 'Label for statisticsSublabel_3 statistics module of showcase',
  },
  statisticsTitle: {
    id: 'showcase.devjobs.statisticsTitle',
    defaultMessage: 'devjobs.at - Personalized Developer Jobs',
    description: 'Label for "statisticsTitle" statistics module of showcase',
  },
  quoteAttribution: {
    id: 'showcase.devjobs.quoteAttribution',
    defaultMessage: '{author}, Co-Founder',
    description:
      'Label for author of the quote in quote page of the devjobs showcase',
  },
  customerQuote: {
    id: 'showcase.devjobs.customerQuote',
    defaultMessage:
      'Devs are currently in high demand. Targeting them with personal matches is key.',
    description: 'Customer quote in quote page of the devjobs showcase',
  },
  showcaseTextTitle: {
    id: 'showcase.devjobs.showcaseTextTitle',
    defaultMessage: 'Summary',
    description: 'Showcase Title for the text section',
  },
  showcaseText_1: {
    id: 'showcase.devjobs.showcaseText_1',
    defaultMessage:
      'Looking for a job can be frustrating: why browse through jobs every day, if there are no new openings available? On the other hand search agents tend to spam the user.',
    description: 'devjobs Showcase Text paragraphs',
  },
  showcaseText_2: {
    id: 'showcase.devjobs.showcaseText_2',
    defaultMessage:
      'Available AI solutions cannot make personalized recommendations. But how to avoid missing the perfect job?',
    description: 'devjobs Showcase Text paragraphs',
  },
  showcaseText_3: {
    id: 'showcase.devjobs.showcaseText_3',
    defaultMessage:
      'Together with {devjobs}, {danube} created a new AI search agent: every day, all available jobs are matched to the individual profiles of every single user.',
    description: 'devjobs Showcase Text paragraphs',
  },
  showcaseText_4: {
    id: 'showcase.devjobs.showcaseText_4',
    defaultMessage: `You'll never miss a job opportunity again, since {danube} cleverly weights pros against cons. Or wouldn't you go that extra mile for your dream job?`,
    description: 'devjobs Showcase Text paragraphs',
  },
  showcaseText_5: {
    id: 'showcase.devjobs.showcaseText_5',
    defaultMessage: `{devjobs} asked their users: How do you like the jobs recommended by {danube}? 87% said they are satisfied with the agents performance.`,
    description: 'devjobs Showcase Text paragraphs',
  },
  showcaseText_6: {
    id: 'showcase.devjobs.showcaseText_6',
    defaultMessage: `The {danube} agent has calculated around 140.000 matches between fall 2019 and fall 2021. In collaboration with {devjobs} we continuously improve the solution.`,
    description: 'devjobs Showcase Text paragraphs',
  },
});

export default devjobsMessages;
