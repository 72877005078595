import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, change, reset, formValueSelector } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import {
  DATA_PRESET_TEST_RESULTS,
  DATA_PRESET_RECRUITING_LIST,
  DATA_PRESET_SPORTS_LIGA,
  DATA_PRESET_SPORTS_CHAMPIONSHIP,
  DATA_PRESET_CUSTOM,
} from '../../../../constants';

import s from './DataPreSettings.scss'; // eslint-disable-line css-modules/no-unused-class
import layoutStyle from '../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../messages';
import render from '../../../ReduxForm/renderField';
import templates from './templates';

const trimFileExtension = filename => filename.replace(/\.[^/.]+$/, '');

class DataPreSettings extends React.Component {
  static propTypes = {
    changeFieldValue: PropTypes.func.isRequired,
    file: PropTypes.array, // eslint-disable-line
    resetForm: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.setTemplate = this.setTemplate.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  componentWillReceiveProps(props) {
    const { file } = props;
    const fileName =
      file && file.length > 0 ? trimFileExtension(file[0].name) : '';
    this.props.changeFieldValue('excelUploadForm', 'datasetName', fileName);
  }

  setTemplate(templateId) {
    const template = templates[templateId];

    // set settings
    if (template.settings) {
      this.props.changeFieldValue(
        'excelUploadForm',
        'settings.strategy',
        template.settings.strategy,
      );
      this.props.changeFieldValue(
        'excelUploadForm',
        'settings.mixFactor',
        template.settings.mixFactor,
      );
      this.props.changeFieldValue(
        'excelUploadForm',
        'settings.impact',
        template.settings.impact,
      );
      this.props.changeFieldValue(
        'excelUploadForm',
        'settings.globalMaximum',
        template.settings.globalMaximum,
      );
    }
  }

  resetForm() {
    this.props.resetForm('excelUploadForm');
  }

  render() {
    const { intl } = this.props;

    return (
      <div className={s.templateSelector}>
        <Row>
          <Col xs={12} md={6}>
            <Row className={`${layoutStyle.noMargin} ${s.headerRow}`} />
            <Row className={`${layoutStyle.noMargin} ${s.row}`}>
              <Col className={s.col}>
                <Field
                  id="datasetName"
                  name="datasetName"
                  maxLength="255"
                  component={render.renderInput}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row className={`${layoutStyle.noMargin} ${s.headerRow}`}>
              <FormattedMessage {...messages.preSettingsHeader} />
            </Row>
            <Row className={`${layoutStyle.noMargin} ${s.row}`}>
              <Col className={`${s.selectionField} ${s.col}`}>
                <img
                  className={s.dataFieldFish}
                  src="/fishy_darkblue.png"
                  alt=""
                />
                <Field
                  id="templateName"
                  name="templateName"
                  component={render.renderSelect}
                  onChange={event => {
                    this.setTemplate(event.target.value);
                  }}
                >
                  <option value={DATA_PRESET_SPORTS_LIGA}>
                    {intl.formatMessage(messages.sportsLiga)}
                  </option>
                  <option value={DATA_PRESET_SPORTS_CHAMPIONSHIP}>
                    {intl.formatMessage(messages.sportsChampionship)}
                  </option>
                  <option value={DATA_PRESET_RECRUITING_LIST}>
                    {intl.formatMessage(messages.recruitingList)}
                  </option>
                  <option value={DATA_PRESET_TEST_RESULTS}>
                    {intl.formatMessage(messages.testResults)}
                  </option>
                  <option value={DATA_PRESET_CUSTOM}>
                    {intl.formatMessage(messages.custom)}
                  </option>
                </Field>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <div className={s.buttonContainer}>
              <button
                type="button"
                className="btn-primary"
                onClick={this.resetForm}
              >
                <FormattedMessage {...messages.cancel} />
              </button>
              <button type="submit" className="btn-dark">
                <FormattedMessage {...messages.next} />
              </button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  file: formValueSelector('excelUploadForm')(state, 'import.excel'),
});

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (form, field, value) =>
    dispatch(change(form, field, value)),
  resetForm: form => dispatch(reset(form)),
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(s, layoutStyle, buttonStyle)(DataPreSettings)),
);
