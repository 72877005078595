import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SyntaxHighlighter from 'react-syntax-highlighter';

import s from '../Page.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import wikiMessages from '../messages';
import { codeStyle, jsonExample } from './codeBlocks';
import DatasetWidget from '../../UseCases/DatasetWidget';

class PredictionDataFormat extends React.Component {
  render() {
    return (
      <div className={s.apiText}>
        <p>
          <h1>
            <FormattedMessage {...wikiMessages.predictionData} />
          </h1>
          <FormattedMessage {...messages.jsonExampleText1} />
        </p>
        <p>
          <FormattedHTMLMessage {...messages.jsonExampleText2} />
        </p>
        <p>
          <div>
            <DatasetWidget
              datasetId="3dd33bda-ffe3-11e9-9a9f-362b9e155667"
              forceWidgetHorizontalScroll
              danubifyStep={1}
            />
          </div>
          <div>
            <SyntaxHighlighter language="json" customStyle={codeStyle}>
              {jsonExample}
            </SyntaxHighlighter>
          </div>
        </p>
      </div>
    );
  }
}

export default withStyles(s)(PredictionDataFormat);
