import React from 'react';
import PropTypes from 'prop-types';

import PageProperties from 'components/PageProperties';
import RouteBundler from 'components/RouteBundler';
import routeMessages from 'routes/messages';

import RealDataProvider from 'components/RealDataProvider';

/* eslint-disable prettier/prettier */
const routes = [
  { path: '', component: RealDataProvider, exact: true },
  { path: ':category/:set', component: RealDataProvider, exact: true },
];
/* eslint-enable prettier/prettier */

class UseCaseWrapper extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    return [
      <PageProperties
        key="sports-data-page-properties"
        page={{
          title: routeMessages.sportsDataTitle,
          description: routeMessages.sportsDataDescription,
          keywords: routeMessages.sportsDataKeywords,
        }}
      />,
      <RouteBundler
        key="real-data-routes-bundler"
        prefix={`${this.props.match.path}/`}
        routes={routes}
      />,
    ];
  }
}

export default UseCaseWrapper;
