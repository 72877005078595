import PropTypes from 'prop-types';

/* eslint-disable prettier/prettier */
const phoneShapeObj = {};
phoneShapeObj.id = PropTypes.string;
phoneShapeObj.name = PropTypes.string;
phoneShapeObj.image = PropTypes.string;
phoneShapeObj.price = PropTypes.number;
phoneShapeObj.price_original = PropTypes.number;
phoneShapeObj.memory = PropTypes.number;
phoneShapeObj.memory_original = PropTypes.number;
phoneShapeObj.ram = PropTypes.number;
phoneShapeObj.weight = PropTypes.number;
phoneShapeObj.max_conversation_time = PropTypes.number;
phoneShapeObj.display_inches = PropTypes.number;
phoneShapeObj.display_inches_original = PropTypes.number;
phoneShapeObj.display_pixels = PropTypes.number;
phoneShapeObj.display_pixels_original = PropTypes.number;
phoneShapeObj.display_ppi = PropTypes.number;
phoneShapeObj.camera_front_amount = PropTypes.number;
phoneShapeObj.camera_front_highest_mp = PropTypes.number;
phoneShapeObj.camera_back_amount = PropTypes.number;
phoneShapeObj.camera_back_highest_mp = PropTypes.number;
phoneShapeObj.battery = PropTypes.number;
phoneShapeObj.danubeScore = PropTypes.number;
phoneShapeObj.matches = PropTypes.arrayOf(PropTypes.number);

const filterShapeObj = {};
filterShapeObj.comparisonLink = PropTypes.string;
filterShapeObj.memoryImportance = PropTypes.number;
filterShapeObj.priceImportance = PropTypes.number;
filterShapeObj.cameraImportance = PropTypes.number;
filterShapeObj.batteryImportance = PropTypes.number;
filterShapeObj.displayZollPreference = PropTypes.string;
/* eslint-enable prettier/prettier */

export const phoneShape = PropTypes.shape(phoneShapeObj);
export const filterShape = PropTypes.shape(filterShapeObj);
