import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import layoutStyle from '../../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from '../../../Page.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../../messages';
import wikiMessages from '../../../messages';

class Overview extends React.Component {
  render() {
    return (
      <div className={s.apiText}>
        <p>
          <h1>
            <FormattedMessage {...wikiMessages.prediction} />
          </h1>
          <FormattedMessage {...messages.danubePredictionOverviewText1} />
        </p>
        <p>
          <h2>
            <FormattedMessage {...messages.requiredSteps} />
          </h2>
          <ul>
            <li>
              <FormattedMessage
                {...messages.danubePredictionOverviewText2}
                values={{
                  setRules: (
                    <Link
                      to="/api/cloud/documentation/prediction/set-rules"
                      target="_blank"
                    >
                      <FormattedMessage {...wikiMessages.setRules} />
                    </Link>
                  ),
                }}
              />
            </li>
            <li>
              <FormattedMessage
                {...messages.danubePredictionOverviewText3}
                values={{
                  danubePrediction: (
                    <Link
                      to="/api/cloud/documentation/prediction/danube-prediction"
                      target="_blank"
                    >
                      <FormattedMessage {...wikiMessages.danubePrediction} />
                    </Link>
                  ),
                }}
              />
            </li>
          </ul>
        </p>
        <p>
          <h2>
            <FormattedMessage {...messages.optionalSteps} />
          </h2>
          <ul>
            <li>
              <FormattedMessage
                {...messages.danubePredictionOverviewText4}
                values={{
                  getRules: (
                    <Link
                      to="/api/cloud/documentation/prediction/get-rules"
                      target="_blank"
                    >
                      <FormattedMessage {...wikiMessages.getRules} />
                    </Link>
                  ),
                }}
              />
            </li>
          </ul>
        </p>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle)(Overview);
