import { defineMessages } from 'react-intl';

const messages = defineMessages({
  jsonExampleText1: {
    id: 'api.jsonExampleText1',
    defaultMessage: `
      Here is an example on how your data (table) can be represented as a json array.
    `,
    description: 'text for api page',
  },
  jsonExampleText2: {
    id: 'api.jsonExampleText2',
    defaultMessage: `
      Note: In order to use your data in the SDK or via the API, you need to further stringify the json array (You can use something like
      <i>JSON.stringify()</i> for this.).
    `,
    description: 'text for api page',
  },
});

export default messages;
