import { defineMessages } from 'react-intl';

const messages = defineMessages({
  privacyHeader: {
    id: 'privacy.header',
    defaultMessage: 'Privacy',
    description: 'Label for privacy page header.',
  },
  policyHeader: {
    id: 'privacy.policyHeader',
    defaultMessage: '(1) Privacy Policy',
    description: 'Label for privacy policy header.',
  },
  policyContent: {
    id: 'privacy.policyContent',
    defaultMessage: `The protection of your personal data is very important to us.
      Therefore we process your data exclusively on the basis of the
      legal regulations (DSGVO, TKG 2003). In this privacy statement
      we inform you about the most important aspects of data processing
      within our website as well as our services and products.`,
    description: 'Content for privacy policy.',
  },
  contactHeader: {
    id: 'privacy.contactHeader',
    defaultMessage: '(2) Contacting us',
    description: 'Label for privacy contact header.A',
  },
  contactContent: {
    id: 'privacy.contactContent',
    defaultMessage: `If you contact us via the form on the website or by e-mail, your
      data will be stored for six months to process the request and in
      case of follow-up questions. We will not share this information
      without your consent.`,
    description: 'Content for privacy contact.',
  },
  storageHeader: {
    id: 'privacy.storageHeader',
    defaultMessage: '(3) Data Storage',
    description: 'Label for privacy storage header.',
  },
  storageContent: {
    id: 'privacy.storageContent',
    defaultMessage: `We point out that in order to use our services and products, it is
      necessary to save your personal data in order to provide the
      login. This covers the following basic data:`,
    description: 'Content for privacy storage.',
  },
  storageLogin: {
    id: 'privacy.storageLogin',
    defaultMessage: `If a user is inactive for 2 years, we will delete this login information.
      When registering for any of our services and products,
      you must agree to the storage of the basic data.`,
    description: 'storageLogin for privacy.',
  },
  thirdPartyLogin: {
    id: 'privacy.thirdPartyLogin',
    defaultMessage: `For some of our services and products it is possible to login via a third party,
      e.g. Facebook login, Google login. In this case,
      the following data is stored, which is loaded by the third party:
      `,
    description: 'thirdPartyLogin for privacy.',
  },
  thirdParty: {
    id: 'privacy.thirdParty',
    defaultMessage: `Id from third party provider (e.g., Facebook Id)`,
    description: 'thirdParty for privacy.',
  },
  storageServices: {
    id: 'privacy.storageServices',
    defaultMessage: `  Some of our services and products store user data
      that goes beyond the basic data. This may include content that you upload,
      your user behavior, etc. The exact scope of data storage of any of our services
      and products can be found in the appendix of the privacy policy
      relating to the specific service or product. When you use any of our services
      or products for the first time, you will be prompted to accept this usage in detail
      in order to use this service or product. All data concerning user behavior
      will be deleted by us after 1 year at the latest.
      `,
    description: 'storageServices for privacy.',
  },
  storageEu: {
    id: 'privacy.storageEU',
    defaultMessage: `If not explicitly stated otherwise, all of the data are safely stored on servers within the EU.`,
    description: 'storageEU for privacy.',
  },
  purchaseHeader: {
    id: 'privacy.purchaseHeader',
    defaultMessage: '(4) Processed data in context of a purchase transaction',
    description: 'Label for privacy purchase header.',
  },
  purchaseStorage: {
    id: 'privacy.purchaseStorage',
    defaultMessage: `Please note that the name of the purchaser is stored
      for the purpose of simplifying the process of purchasing software licenses
      for services and products and for later execution of the contract.
      Entered credit card details are not stored directly by us,
      but processed for the purpose of payment by our billing partner stripe
      (see the privacy policy of billing partner stripe).`,
    description: 'purchaseStorage for privacy purchase.',
  },
  purchaseData: {
    id: 'privacy.purchaseData',
    defaultMessage:
      'In order to fulfill the contract the following data are stored:',
    description: 'header for privacy purchaseData list.',
  },
  purchaseDataBuyer: {
    id: 'privacy.purchaseDataBuyer',
    defaultMessage: 'Name of buyer',
    description: 'Name of buyer for privacy purchaseData list.',
  },
  purchaseDataProduct: {
    id: 'privacy.purchaseDataProduct',
    defaultMessage:
      'Name of the product or service, information about the purchased license',
    description: 'product for privacy purchaseData list.',
  },
  purchaseDataDate: {
    id: 'privacy.purchaseDataDate',
    defaultMessage: 'Date of purchase',
    description: 'Date for privacy purchaseData list.',
  },
  purchaseDataRequirement: {
    id: 'privacy.purchaseDataRequirement',
    defaultMessage: `The data provided by you are required to fulfill the contract
      or to carry out pre-contractual steps. There is no data transfer of your data to third parties,
      with the exception of transmitting the credit card data to the payment provider stripe
      for the purpose of debiting the purchase price as well as to our tax advisor to satisfy our legal accounting obligations.
    `,
    description: 'Requirement for privacy purchase.',
  },
  purchaseCancel: {
    id: 'privacy.purchaseCancel',
    defaultMessage: `When canceling the purchase process, the your transaction data will be deleted.
      In the case of a contract, all data from the contractual relationship are stored until the expiry of the tax retention period (7 years).
      `,
    description: 'purchaseCancel for privacy purchase.',
  },
  cookieHeader: {
    id: 'privacy.cookieHeader',
    defaultMessage: '(5) Cookies',
    description: 'Label for privacy Cookie header.',
  },
  cookieDescription: {
    id: 'privacy.cookieDescription',
    defaultMessage: `Our website uses so-called cookies.
      These are small text files that are stored on your device.
      They do no harm.`,
    description: 'cookie Description for privacy Cookie.',
  },
  cookieStorage: {
    id: 'privacy.cookieStorage',
    defaultMessage: `We use cookies to improve the user-friendliness of our products and services.
      Some cookies remain stored on your device until you delete them.
      They allow us to recognize your browser on your next visit.
      `,
    description: 'cookie Storage for privacy Cookie.',
  },
  cookieDeny: {
    id: 'privacy.cookieDeny',
    defaultMessage: `If you do not want this, you can set up your browser such that
      you will be asked specifically about the use of individual cookies.
      Disabling cookies may limit the functionality of our website.
    `,
    description: 'cookie Denial for privacy Cookie.',
  },
  analysisHeader: {
    id: 'privacy.analysisHeader',
    defaultMessage: '(6) Web analysis',
    description: 'Label for privacy analysisHeader header.',
  },
  analysisDescription: {
    id: 'privacy.analysisDescription',
    defaultMessage: `Our website employs analytics to store user behavior.
      For this purpose, we track visits to our website, clicks on certain buttons and login behavior.
      These data are used to improve user experience and are stored on our servers.
      In particular these data are not shared with others.
      `,
    description: 'Analysis Description for privacy analysis.',
  },
  analysisLegal: {
    id: 'privacy.analysisLegal',
    defaultMessage: `The data processing takes place on the basis of the legal regulations of the § 96 Abs 3 TKG
      as well as the art 6 Abs 1 a) (consent) and / or f) (legitimate interest) of the DSGVO.
      Our concern in the sense of the DSGVO (legitimate interest) is the improvement of our offer and our website.
      `,
    description: 'analysis Legal for privacy Analysis.',
  },
  analysisPeriod: {
    id: 'privacy.analysisPeriod',
    defaultMessage: `The user data is kept for a period of 16 months.`,
    description: 'analysis Period for privacy Analysis.',
  },
  dataDeletionHeader: {
    id: 'privacy.dataDeletionHeader',
    defaultMessage: '(7) Data deletion',
    description: 'Label for privacy deletion header.',
  },
  dataDeletionContent: {
    id: 'privacy.dataDeletionContent',
    defaultMessage: `You can always delete your data from the servers of danube.ai solutions gmbh
      and all products & services at any time. To do this, go to your user account and select “Delete account”.
      Upon confirmation, all content in Products & Services will be deleted in addition to your basic data.
      In particular, no further confirmation of the deletion follows.
      `,
    description: 'Deletion Description for privacy Data.',
  },
  privacyRightsHeader: {
    id: 'privacy.privacyRightsHeader',
    defaultMessage: '(8) Your Privacy Rights',
    description: 'Label for privacy Rights header.',
  },
  privacyRightsContent: {
    id: 'privacy.privacyRightsContent',
    defaultMessage: `In principle, you have the rights to information,
      correction, deletion, restriction, data portability, revocation and opposition.
      If you believe that the processing of your data violates data protection law
      or if your data protection claims have otherwise been violated in a way, you can complain to the supervisory authority.
      In Austria, this is the data protection authority.
      `,
    description: 'Rights Description for privacy.',
  },
  privacyContactDetails: {
    id: 'privacy.privacyContactDetails',
    defaultMessage: `<p>You can reach us under the following contact details:</p>
      Company name: danube.ai solutions gmbh<br />
      Commercial Register: 591264g<br />
      Address: Lambrechtgasse 3/3 1040 Vienna<br />
      Data protection officer: Klemens Senn, privacy@danube.ai<br />
      `,
    description: 'privacy Contact Details for privacy.',
  },
  updated: {
    id: 'privacy.updated',
    defaultMessage: `Date: 09.11.2018`,
    description: 'privacy updated at.',
  },
  apendixAHeader: {
    id: 'privacy.apendixAHeader',
    defaultMessage: `(A) Appendix to the privacy policy for individual services and products`,
    description: 'Header for apendix A in privacy',
  },
  apendixA: {
    id: 'privacy.apendixA',
    defaultMessage: `A confirmation of the privacy policy in the appendix is necessary
      at the first use of the respective service or product.
      `,
    description: 'apendix A in privacy',
  },
  apendixA5Header: {
    id: 'privacy.apendixA5Header',
    defaultMessage: '(A5) Processed data',
    description: 'Header for apendix A5 in privacy',
  },
  apendixA5: {
    id: 'privacy.apendixA5',
    defaultMessage: `The following data are processed and / or stored by us
    during the use of danube.ai (justification in parentheses) [deletion information in square brackets]:`,
    description: 'apendix A5 in privacy',
  },
  apendixA51: {
    id: 'privacy.apendixA51',
    defaultMessage: `(A5.1) Uploaded data, i.e. the data the user uploads for analysis.
      (these data are necessary for the danube.ai service itself)
      [deletion: manually when the account is deactivated]
      `,
    description: 'apendix A51 in privacy',
  },
  apendixA52: {
    id: 'privacy.apendixA52',
    defaultMessage: `(A5.2) Results, i.e. the resulting data features values after danubifying.
      (these data are necessary for user experience, such that the user can quickly review results)
      [deletion: manually when the account is deactivated]
      `,
    description: 'apendix A52 in privacy',
  },
  footer: {
    id: 'privacy.footer',
    defaultMessage: `danube.ai solutions gmbh is not responsible for content that the user has uploaded to the danube.ai website.`,
    description: 'footer in privacy',
  },
  username: {
    id: 'privacy.username',
    defaultMessage: 'Username',
    description: 'Username point in data list.',
  },
  email: {
    id: 'privacy.email',
    defaultMessage: 'Email Address',
    description: 'Email point in data list.',
  },
  password: {
    id: 'privacy.password',
    defaultMessage: 'Password',
    description: 'Password point in data list.',
  },
  locale: {
    id: 'privacy.locale',
    defaultMessage: 'Locale (default: language setting from the browser)',
    description: 'Locale point in data list.',
  },
  date: {
    id: 'privacy.date',
    defaultMessage: 'Date of last successful login',
    description: 'Date point in data list',
  },
  tokens: {
    id: 'privacy.tokens',
    defaultMessage: 'Tokens (login information for use in other cookies)',
    description: 'Tokens point in data list',
  },
  status: {
    id: 'privacy.status',
    defaultMessage: `Status of consent to privacy policy (also stored separately
      for each service and product)`,
    description: 'Status point in data list',
  },
  hubspot: {
    id: 'privacy.hubspot',
    defaultMessage: `
      When requesting a demo, downloading case studies, our whitepaper, or other materials, we ask you to provide the following contact information fields:
    `,
    description: 'Privacy hubspot text',
  },
  firstname: {
    id: 'privacy.firstname',
    defaultMessage: `Firstname`,
    description: 'Privacy hubspot field',
  },
  lastname: {
    id: 'privacy.lastname',
    defaultMessage: `Lastname`,
    description: 'Privacy hubspot field',
  },
  company: {
    id: 'privacy.company',
    defaultMessage: `Company (optional)`,
    description: 'Privacy hubspot field',
  },
  hubspot2: {
    id: 'privacy.hubspot2',
    defaultMessage: `
      This is such that we can get in contact with you if needed or send additonal information.
      These data are stored at our service provider HubSpot via Amazon Web Services (AWS) hosted in Germany.
    `,
    description: 'Privacy hubspot text',
  },
});

export default messages;
