import { SET_RECOMMENDATIONS } from '../constants';

const initialState = {
  recommendations: [],
};

export default function redirect(state = { ...initialState }, action) {
  switch (action.type) {
    case SET_RECOMMENDATIONS:
      return {
        recommendations: action.recommendations,
      };
    default:
      return state;
  }
}
