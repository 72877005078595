import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import s from './SearchMaskForm.scss';
import buttonStyle from '../../../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import renderField from '../../../../components/ReduxForm/renderField';
import * as normalizers from '../../../../components/ReduxForm/normalizers';
import messages from './messages';
import Loading from '../../../../components/Loading';

/* eslint-disable prettier/prettier */
export const optionParameterNamesMap = {
  RENT: messages.type_rent,
  TEMPORARY_RENT: messages.type_temporary_rent,
  BUY: messages.type_buy,
  LEASE: messages.type_lease,
};
/* eslint-enable prettier/prettier */

export const OptionParameterNamesMap = optionParameterNamesMap;

const validate = () => {
  const errors = {};
  return errors;
};

class SearchMaskForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
    zipCodeQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      zipCodes: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      danubeSelected: props.initialValues.sort.strategy.value === 'danube', // eslint-disable-line react/prop-types
    };
  }

  render() {
    const { errors, intl, handleSubmit, zipCodeQuery } = this.props;

    if (zipCodeQuery.loading) return <Loading />;

    return (
      <div className={s.searchMaskContainer}>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <Row>
              <Col xs="12" style={{ margin: '10px 0' }}>
                <h1>
                  <FormattedMessage {...messages.headline} />
                </h1>
              </Col>
              <Col md="6" xs="12">
                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.address} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.localization_address_zip"
                      name="filter.localization_address_zip"
                      component={renderField.renderSelectAlt}
                      isMulti
                      options={zipCodeQuery.zipCodes.map(zipCode => ({
                        value: zipCode,
                        label: zipCode,
                      }))}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.type} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.type_transferType"
                      name="filter.type_transferType"
                      component={renderField.renderSelectAlt}
                      options={[
                        {
                          value: 'BUY',
                          label: intl.formatMessage(
                            optionParameterNamesMap.BUY,
                          ),
                        },
                        {
                          value: 'RENT',
                          label: intl.formatMessage(
                            optionParameterNamesMap.RENT,
                          ),
                        },
                      ]}
                      placeholder={intl.formatMessage(messages.choose)}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.price} />
                  </Col>
                  <Col xs="12">
                    <Row>
                      <Col xs="6">
                        <Field
                          id="filter.priceInformation_primaryPriceFrom"
                          name="filter.priceInformation_primaryPriceFrom"
                          placeholder={intl.formatMessage(messages.fromPrice)}
                          type="number"
                          component={renderField.renderInput}
                        />
                      </Col>
                      <Col xs="6">
                        <Field
                          id="filter.priceInformation_primaryPriceTo"
                          name="filter.priceInformation_primaryPriceTo"
                          placeholder={intl.formatMessage(messages.toPrice)}
                          type="number"
                          component={renderField.renderInput}
                        />
                      </Col>
                      <Col xs="6">
                        <div className={s.spacerLine} />
                      </Col>
                      <Col xs="6">
                        <div className={s.spacerLine} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col md="6" xs="12">
                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.areaRange} />
                  </Col>
                  <Col xs="12">
                    <Row>
                      <Col xs="6">
                        <Field
                          id="filter.area_total_areaFrom"
                          name="filter.area_total_areaFrom"
                          placeholder={intl.formatMessage(messages.fromArea)}
                          type="number"
                          component={renderField.renderInput}
                        />
                      </Col>
                      <Col xs="6">
                        <Field
                          id="filter.area_total_areaTo"
                          name="filter.area_total_areaTo"
                          placeholder={intl.formatMessage(messages.toArea)}
                          type="number"
                          component={renderField.renderInput}
                        />
                      </Col>
                      <Col xs="6">
                        <div className={s.spacerLine} />
                      </Col>
                      <Col xs="6">
                        <div className={s.spacerLine} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.numberOfRooms} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.area_numberOfRoomsFrom"
                      name="filter.area_numberOfRoomsFrom"
                      type="number"
                      component={renderField.renderInput}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>
                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.furtherOptions} />
                  </Col>
                  <Col xs="12">
                    <Row>
                      <Col xs="6" className={s.checkboxWrapper}>
                        <Field
                          id="filter.Balconies_and_Terraces"
                          name="filter.Balconies_and_Terraces"
                          label={
                            <FormattedMessage {...messages.balconyAndTerrace} />
                          }
                          type="checkbox"
                          component={renderField.renderCheckbox}
                          normalize={normalizers.booleanNormalizer}
                          showErrors={false}
                        />
                      </Col>
                      <Col xs="6" className={s.checkboxWrapper}>
                        <Field
                          id="filter.showSuggestions"
                          name="filter.showSuggestions"
                          label={
                            <FormattedMessage {...messages.showSuggestions} />
                          }
                          type="checkbox"
                          component={renderField.renderCheckbox}
                          normalize={normalizers.booleanNormalizer}
                          showErrors={false}
                        />
                      </Col>
                      <Col xs="6">
                        <div className={s.spacerLine} />
                      </Col>
                      <Col xs="6">
                        <div className={s.spacerLine} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="6" xs="12">
                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.sortStrategy} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="sort.strategy"
                      name="sort.strategy"
                      component={renderField.renderSelectAlt}
                      options={[
                        {
                          value: '',
                          label: intl.formatMessage(messages.none),
                        },
                        {
                          value: 'zip',
                          label: intl.formatMessage(messages.sortByAddress),
                        },
                        {
                          value: 'price',
                          label: intl.formatMessage(messages.sortByPrice),
                        },
                        {
                          value: 'area',
                          label: intl.formatMessage(messages.sortByArea),
                        },
                        {
                          value: 'danube',
                          label: intl.formatMessage(messages.sortWithDanubeAI),
                        },
                      ]}
                      styles={{
                        option: (provided, state) => {
                          const style = { ...provided };
                          if (state.value === 'danube') {
                            if (!state.isSelected) style.color = '#0db5ff';
                            style.fontWeight = 'bold';
                          }
                          return style;
                        },
                        singleValue: provided => {
                          const style = { ...provided };
                          if (this.state.danubeSelected) {
                            style.color = '#0db5ff';
                            style.fontWeight = 'bold';
                          }
                          return style;
                        },
                      }}
                      placeholder={intl.formatMessage(messages.choose)}
                      onChange={value => {
                        this.setState({
                          danubeSelected: value.value === 'danube',
                        });
                      }}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>
              </Col>
              <Col md="6" xs="12">
                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.sortOrdering} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="sort.ordering"
                      name="sort.ordering"
                      component={renderField.renderSelectAlt}
                      options={[
                        {
                          value: 'asc',
                          label: intl.formatMessage(messages.ascending),
                        },
                        {
                          value: 'desc',
                          label: intl.formatMessage(messages.descending),
                        },
                      ]}
                      placeholder={intl.formatMessage(messages.choose)}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className={s.buttonbar}>
              <button
                type="submit"
                className="btn-secondary btn-round btn-large"
              >
                {this.state.danubeSelected ? (
                  <span>danubify</span>
                ) : (
                  <FormattedMessage {...messages.search} />
                )}
              </button>
              {errors.length === 0 ? null : (
                <ul style={{ padding: '0', listStyleType: 'none' }}>
                  {errors.map(err => (
                    <li key={err} className="bg-danger">
                      {err}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

const zipCodeQuery = gql`
  query zipCodeQuery {
    zipCodes
  }
`;

export default compose(
  graphql(zipCodeQuery, {
    name: 'zipCodeQuery',
  }),
)(
  reduxForm({
    form: 'immoSearchMask',
    validate,
    pure: false, // this is necessary to trigger form re-renders if the locale changes
  })(injectIntl(withStyles(buttonStyle, s)(SearchMaskForm))),
);
