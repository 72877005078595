import { defineMessages } from 'react-intl';

const messages = defineMessages({
  heading: {
    id: 'analytics.filter.heading',
    defaultMessage: 'Filter',
    description: 'Heading for analytics filter',
  },
});

export default messages;
