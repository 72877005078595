import { defineMessages } from 'react-intl';

const messages = defineMessages({
  description: {
    id: 'demo.recruiting.learnMore.description',
    defaultMessage: `
      danube.ai helps you to find the right candidate
    `,
    description: 'label for learn more page',
  },
  text1: {
    id: 'demo.recruiting.learnMore.text1',
    defaultMessage: `
      Selecting the right person for a job opening is a difficult task. In this demo, we show you how danube.ai can
      help to find the optimal candidate for a (virtual) office position (the candidate names and properties are random).
      With the sliders and dropdowns you can tell the ai which properties and skills are important for you.
    `,
    description: 'label for learn more page',
  },
  text2: {
    id: 'demo.recruiting.learnMore.text2',
    defaultMessage: `
      By pressing 'danubify' you can activate the ai returning a sorted list of candidates with weighted matching values.
    `,
    description: 'label for learn more page',
  },
  text3: {
    id: 'demo.recruiting.learnMore.text3',
    defaultMessage: `
      Candidates with important skills for you have a higher influence on the results than weaker ones. Simply put, danube.ai
      values all skills of strong candidates (not only those you specified). In the next step, our ai algorithm compares the properties
      of every candidate with all other persons, calculating a weight profile for the pool.
    `,
    description: 'label for learn more page',
  },
  text4: {
    id: 'demo.recruiting.learnMore.text4',
    defaultMessage: `
      danube.ai weights the properties of the candidates according to your preferences and their availability in the pool. The ai
      algorithm calculates the weight profile for the given candidate pool. You can find the weights for your search directly above
      your candidate list. For example, if salary is 20% and English skills 10%, then salary is considered twice as important for
      sorting the candidates.
    `,
    description: 'label for learn more page',
  },
  text5: {
    id: 'demo.recruiting.learnMore.text5',
    defaultMessage: `
      The list below shows the profile of the candidate and their weighted matching values. You also see if a candidate is best in a skill
      or property, e.g. 'Best English skills'. danube.ai also identifies 'hidden champions' - candidates that may be missed otherwise.
      On the other hand, if a candidate's skills are deemed not so relevant, then he/she gets the label 'overrated'.
    `,
    description: 'label for learn more page',
  },
  text6: {
    id: 'demo.recruiting.learnMore.text6',
    defaultMessage: `
      danube.ai adapts to your preferences and to your candidate pool. Hence, with danube.ai you get a candidate list perfectly matching your
      industry and business.
    `,
    description: 'label for learn more page',
  },
});

export default messages;
