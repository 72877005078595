import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';

import s from './Recommendation.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import routesNamesMap from '../../routes/routesNamesMap';
import { setRecommendations as setRecommendationsAction } from '../../actions/recommendationHistory';

const cookies = new Cookies();

const COOKIE_LIFESPAN = 30;

const randomString = (charCount, chars) => {
  let randString = '';
  for (let i = 0; i < charCount; i += 1) {
    randString += chars[Math.floor(Math.random() * chars.length)];
  }
  return randString;
};

class RecommendationComponent extends React.Component {
  static propTypes = {
    danubeRecommendation: PropTypes.func.isRequired,
    setRecommendations: PropTypes.func.isRequired,
    useDarkMode: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      recommendations: null,
    };

    /* eslint-disable prettier/prettier */
    this.getNewPageRecommendations = this.getNewPageRecommendations.bind(this);
    this.handleSessionHistoryChange = this.handleSessionHistoryChange.bind(this);
    this.normalizePagePath = this.normalizePagePath.bind(this);
    this.renderCard = this.renderCard.bind(this);
    /* eslint-enable prettier/prettier */
  }

  async componentDidMount() {
    await this.handleSessionHistoryChange();
  }

  shouldComponentUpdate() {
    return true;
  }

  async componentDidUpdate() {
    await this.handleSessionHistoryChange();
  }

  async getNewPageRecommendations(danubeUserSession, expirationData) {
    const { setRecommendations } = this.props;

    const data = {
      sessionId: danubeUserSession.sessionId,
      data: JSON.stringify(danubeUserSession.pageHistory),
      n: 10,
    };

    const result = await this.props.danubeRecommendation(data);

    if (result && result.data && result.data.danubeRecommendation) {
      const correlatedPages = JSON.parse(
        result.data.danubeRecommendation.correlatedData,
      );
      // set state with new recommendations and also cache it in a cookie
      this.setState(
        {
          recommendations: correlatedPages,
        },
        () => {
          // save recommendations in redux store (used by other components)
          setRecommendations({ recommendations: correlatedPages });
          // save recommendations in cookie
          cookies.set('danubeUserSessionRecommendations', correlatedPages, {
            path: '/',
            expires: expirationData,
          });
        },
      );
    }
  }

  async handleSessionHistoryChange() {
    const currentPage = this.normalizePagePath(window.location.pathname);

    // check if page is included in the route-to-name mapping
    if (!(currentPage in routesNamesMap)) return;

    // get current session from cookies
    const danubeUserSession = cookies.get('danubeUserSession');

    // session is active for specified amount of minutes (refreshes with every new request)
    const expirationData = new Date();
    expirationData.setMinutes(expirationData.getMinutes() + COOKIE_LIFESPAN);

    // existing session
    if (danubeUserSession) {
      const { sessionId, pageHistory } = danubeUserSession;

      // check if page has changed and if so, update cookie and load new recommendations
      if (pageHistory[pageHistory.length - 1].page !== currentPage) {
        const newPageHistory = [...pageHistory, { page: currentPage }];
        const newDanubeUserSession = { sessionId, pageHistory: newPageHistory };

        cookies.set('danubeUserSession', newDanubeUserSession, {
          path: '/',
          expires: expirationData,
        });

        await this.getNewPageRecommendations(
          newDanubeUserSession,
          expirationData,
        );
      }
    }
    // new session
    else {
      const sessionId = randomString(64, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'); // eslint-disable-line prettier/prettier
      const newPageHistory = [{ page: currentPage }];
      const newDanubeUserSession = { sessionId, pageHistory: newPageHistory };

      cookies.set('danubeUserSession', newDanubeUserSession, {
        path: '/',
        expires: expirationData,
      });

      await this.getNewPageRecommendations(
        newDanubeUserSession,
        expirationData,
      );
    }
  }

  // eslint-disable-next-line class-methods-use-this
  normalizePagePath(pagePath) {
    let path = pagePath;

    // trim last '/' if path is not just '/'
    if (path !== '/' && path.endsWith('/')) {
      path = path.slice(0, -1);
    }

    return path;
  }

  // eslint-disable-next-line class-methods-use-this
  renderCard(recommendation) {
    const path = this.normalizePagePath(recommendation.page);
    const messageData = routesNamesMap[path];

    return (
      <Link key={recommendation.page} className={s.card} to={path}>
        <div className={s.cardInner}>
          {messageData && messageData.title ? (
            <FormattedMessage {...messageData.title} />
          ) : (
            <span>{path}</span>
          )}
        </div>
      </Link>
    );
  }

  render() {
    // get recommendations from state --> most up-to-date
    let { recommendations } = this.state;

    // get cached recommendations from cookie --> fallback
    if (!recommendations) {
      recommendations = cookies.get('danubeUserSessionRecommendations');
    }

    // decide between dark mode and light mode
    const recommendationComponentContainerStyle = this.props.useDarkMode
      ? s.recommendationComponentContainerDark
      : s.recommendationComponentContainer;

    if (!recommendations || recommendations.length === 0) {
      return <div className={recommendationComponentContainerStyle} />;
    }

    return (
      <div className={recommendationComponentContainerStyle}>
        <div className={s.recommendationComponentContainerInner}>
          <h1>
            <FormattedMessage {...messages.recommendationComponentHeader} />
          </h1>
          <div className={s.cardsWrapper}>
            {recommendations
              .filter(r => Object.keys(routesNamesMap).includes(r.page))
              .slice(0, 3)
              .map(recommendation => this.renderCard(recommendation))}
          </div>
        </div>
      </div>
    );
  }
}

const recommendationMutation = gql`
  mutation danubeRecommendation($data: RecommendationInputData!) {
    danubeRecommendation(data: $data) {
      correlatedData
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  setRecommendations: ({ recommendations }) =>
    dispatch(setRecommendationsAction({ recommendations })),
});

export default compose(
  graphql(recommendationMutation, {
    props: ({ mutate }) => ({
      danubeRecommendation: data =>
        mutate({
          variables: { data },
        }),
    }),
  }),
  connect(
    null,
    mapDispatchToProps,
  ),
  withRouter,
  withStyles(s),
)(RecommendationComponent);
