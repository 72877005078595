import PropTypes from 'prop-types';

/* eslint-disable prettier/prettier */
const jobsShapeObj = {};
jobsShapeObj.id = PropTypes.string;
jobsShapeObj.title = PropTypes.string;
jobsShapeObj.field = PropTypes.string;
jobsShapeObj.salaryFrom = PropTypes.number;
jobsShapeObj.salaryTo = PropTypes.number;
jobsShapeObj.locations = PropTypes.arrayOf(PropTypes.string);
jobsShapeObj.daysAgo = PropTypes.number;
jobsShapeObj.companyType = PropTypes.string;
jobsShapeObj.jobLevel = PropTypes.string;
jobsShapeObj.remoteWork = PropTypes.bool;
jobsShapeObj.technologies = PropTypes.arrayOf(PropTypes.string);
jobsShapeObj.benefits = PropTypes.arrayOf(PropTypes.string);
jobsShapeObj.timeDistance = PropTypes.number;
jobsShapeObj.danubeScore = PropTypes.number;
jobsShapeObj.matches = PropTypes.arrayOf(PropTypes.number);
// jobsShapeObj.danubeRanking = PropTypes.number;
// jobsShapeObj.fish = PropTypes.arrayOf(PropTypes.number);
/* eslint-enable prettier/prettier */

export const jobsShape = PropTypes.shape(jobsShapeObj);

/* eslint-disable prettier/prettier */
const filterShapeObj = {};
filterShapeObj.salaryFrom = PropTypes.number;
filterShapeObj.salaryTo = PropTypes.number;
filterShapeObj.salaryImportance = PropTypes.number;
filterShapeObj.location = PropTypes.string;
filterShapeObj.companyType = PropTypes.arrayOf(PropTypes.string);
filterShapeObj.jobLevel = PropTypes.arrayOf(PropTypes.string);
filterShapeObj.remoteWorkImportance = PropTypes.number;
filterShapeObj.technologies = PropTypes.arrayOf(PropTypes.string);
filterShapeObj.benefits = PropTypes.arrayOf(PropTypes.string);
filterShapeObj.salaryImportance = PropTypes.locationImportance;
/* eslint-enable prettier/prettier */

export const filterShape = PropTypes.shape(filterShapeObj);
