import { defineMessages } from 'react-intl';

const messages = defineMessages({
  suggestionsModalHeader: {
    id: 'demo.immo.suggestionsModal.header',
    defaultMessage: 'Found nothing?',
    description: 'label in filter suggestions modal',
  },
  suggestionsModalText1: {
    id: 'demo.immo.suggestionsModal.text1',
    defaultMessage: 'The following objects could be of interest for you.',
    description: 'label in filter suggestions modal',
  },
  suggestionsModalText2: {
    id: 'demo.immo.suggestionsModal.text2',
    defaultMessage: `
      Note: Your filter settings have been extended with the following search criteria.
    `,
    description: 'label in filter suggestions modal',
  },
  suggestionsModalHeader2: {
    id: 'demo.immo.suggestionsModal.header2',
    defaultMessage: 'No suggestions avaiable',
    description: 'label in filter suggestions modal',
  },
  suggestionsModalText3: {
    id: 'demo.immo.suggestionsModal.text3',
    defaultMessage: `
      No suggestions could be found.
    `,
    description: 'label in filter suggestions modal',
  },
  yes: {
    id: 'demo.immo.suggestionsModal.yes',
    defaultMessage: 'Yes',
    description: 'label in filter suggestions modal',
  },
  no: {
    id: 'demo.immo.suggestionsModal.no',
    defaultMessage: 'No',
    description: 'label in filter suggestions modal',
  },
  close: {
    id: 'demo.immo.suggestionsModal.close',
    defaultMessage: 'Close',
    description: 'label in filter suggestions modal',
  },
  applyFilterSettings: {
    id: 'demo.immo.suggestionsModal.applyFilterSettings',
    defaultMessage: 'Apply filter settings',
    description: 'label in filter suggestions modal',
  },
});

export default messages;
