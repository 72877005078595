import React from 'react';

import PageProperties from 'components/PageProperties';
import DevJobs from 'components/Partners/DevJobs';
import routeMessages from 'routes/messages';

class DevJobsWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.partnerDevJobsTitle,
            description: routeMessages.partnerDevJobsDescription,
            keywords: routeMessages.partnerDevJobsKeywords,
          }}
        />
        <DevJobs />
      </div>
    );
  }
}

export default DevJobsWrapper;
