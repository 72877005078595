/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Slider from 'react-slick';
import FaChevronLeft from 'react-icons/lib/fa/chevron-left';
import FaChevronRight from 'react-icons/lib/fa/chevron-right';
import slickStyl1 from 'slick-carousel/slick/slick.css';
import slickStyl2 from 'slick-carousel/slick/slick-theme.css';

import s from './Carousel.scss';

const PrevArrow = props => {
  // eslint-disable-next-line react/prop-types
  const { className, onClick } = props;
  return (
    <div className={`${className} ${s.leftArrow}`} onClick={onClick}>
      <FaChevronLeft />
    </div>
  );
};

const NextArrow = props => {
  // eslint-disable-next-line react/prop-types
  const { className, onClick } = props;
  return (
    <div className={`${className} ${s.rightArrow}`} onClick={onClick}>
      <FaChevronRight />
    </div>
  );
};

class Carousel extends React.Component {
  static propTypes = {
    sliderProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
  };

  static defaultProps = {
    sliderProps: {},
  };

  render() {
    const { sliderProps, children } = this.props;

    const settings = {
      className: s.carousel,
      customPaging: i => <span className={s.dot}>{i + 1}</span>,
      dots: true,
      dotsClass: `slick-dots slick-thumb ${s.dots}`,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      ...sliderProps,
    };

    return <Slider {...settings}>{children}</Slider>;
  }
}

export default withStyles(slickStyl1, slickStyl2, s)(Carousel);

/* eslint-enable jsx-a11y/no-static-element-interactions */
/* eslint-enable jsx-a11y/click-events-have-key-events */
