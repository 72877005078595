/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';

export const TASK_STATUS_RUNNING = 'TASK_STATUS_RUNNING';
export const TASK_STATUS_COMPLETED = 'TASK_STATUS_COMPLETED';
export const TASK_STATUS_FAILED = 'TASK_STATUS_FAILED';

export const COLUMN_TYPE_NONE = 'COLUMN_TYPE_NONE';
export const COLUMN_TYPE_TEXT = 'COLUMN_TYPE_TEXT';
export const COLUMN_TYPE_NUMBER = 'COLUMN_TYPE_NUMBER';
export const COLUMN_TYPE_BOOLEAN = 'COLUMN_TYPE_BOOLEAN'; // only used to summarise for display
export const COLUMN_TYPE_BOOLEAN_TEXT = 'COLUMN_TYPE_BOOLEAN_TEXT';
export const COLUMN_TYPE_BOOLEAN_NUMERIC = 'COLUMN_TYPE_BOOLEAN_NUMERIC';
export const COLUMN_TYPE_NUMERIC_PERCENTAGE = 'COLUMN_TYPE_NUMERIC_PERCENTAGE';

export const COLUMN_RULE_EQUAL = 'COLUMN_RULE_EQUAL';
export const COLUMN_RULE_SMALLER_THAN = 'COLUMN_RULE_SMALLER_THAN';
export const COLUMN_RULE_SMALLER_THAN_OR_EQUAL = 'COLUMN_RULE_SMALLER_THAN_OR_EQUAL'; // eslint-disable-line
export const COLUMN_RULE_LARGER_THAN = 'COLUMN_RULE_LARGER_THAN';
export const COLUMN_RULE_LARGER_THAN_OR_EQUAL = 'COLUMN_RULE_LARGER_THAN_OR_EQUAL'; // eslint-disable-line
export const COLUMN_RULE_HAS_FEATURE = 'COLUMN_RULE_HAS_FEATURE';
export const COLUMN_RULE_MAX_PERCENTAGE = 'COLUMN_RULE_MAX_PERCENTAGE';

export const DATA_PRESET_TEST_RESULTS = 'DATA_PRESET_TEST_RESULTS';
export const DATA_PRESET_RECRUITING_LIST = 'DATA_PRESET_RECRUITING_LIST';
export const DATA_PRESET_SPORTS_LIGA = 'DATA_PRESET_SPORTS_LIGA';
export const DATA_PRESET_SPORTS_CHAMPIONSHIP = 'DATA_PRESET_SPORTS_CHAMPIONSHIP'; // eslint-disable-line
export const DATA_PRESET_CUSTOM = 'DATA_PRESET_CUSTOM';

export const ALGORITHM_STRATEGY_FAIR = 'fair';
export const ALGORITHM_STRATEGY_EXCLUSIVE = 'exclusive';
export const ALGORITHM_STRATEGY_MIXED = 'mixed';
export const ALGORITHM_STRATEGY_INVERSE = 'inverse';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';

export const SET_USECASE = 'SET_USECASE';
export const SET_STEP = 'SET_STEP';

export const SET_REDIRECT_SOURCE = 'SET_REDIRECT_SOURCE';
export const SET_REDIRECT_ACTION = 'SET_REDIRECT_ACTION';

export const REDIRECT_ACTION_OPEN_API_KEY_MODAL = 'apiKeyModal';

export const LAST_USECASES_PAGE = 2;

export const API_PRODUCT_PREDICTION = 'PREDICTION';
export const API_PRODUCT_RECOMMENDATION = 'RECOMMENDATION';
export const API_PRODUCT_RECALIBRATION = 'RECALIBRATION';

export const SET_RECOMMENDATIONS = 'SET_RECOMMENDATIONS';

export const PRODUCT_KEY_RECOMMENDATION = 'danube-api-recommendation';
export const PRODUCT_KEY_PREDICTION = 'danube-api-prediction';
export const PRODUCT_KEY_RECALIBRATION = 'danube-api-recalibration';

export const HUBSPOT_REGION = 'eu1';
export const HUBSPOT_PORTAL_ID = '25662188';

export const GEIZHALS_SHOWCASE_FORM_IDS = {
  'en-US': 'e9a855ed-0afb-42ff-a96c-5b6126e73aef',
  'de-DE': '99f2815e-7399-41fe-af8b-97e6e522948a',
};

export const GEIZHALS_SHOWCASE_DOCUMENTS = {
  'en-US': '/danube-showcases-geizhals.pdf',
  'de-DE': '/danube-showcases-geizhals.pdf',
};

export const DEVJOBS_SHOWCASE_FORM_IDS = {
  'en-US': '1da67616-85a5-42bf-acb9-edc282a4b7c5',
  'de-DE': 'c8c3ec6b-bca9-4060-8089-0d3c6708dd0e',
};

export const DEVJOBS_SHOWCASE_DOCUMENTS = {
  'en-US': '/danube-showcases-devjobs.pdf',
  'de-DE': '/danube-showcases-devjobs.pdf',
};

export const WHITEPAPER_FORM_IDS = {
  'en-US': '346f18a8-5eeb-4afe-b3d1-b27344450239',
  'de-DE': '36181e2c-999f-401a-beed-30a914355f1c',
};

export const WHITEPAPER_DOCUMENTS = {
  'en-US': '/danube-whitepaper.pdf',
  'de-DE': '/danube-whitepaper.pdf',
};

export const REQUEST_DEMO_FORM_IDS = {
  'en-US': 'bc9900d4-89b0-48c6-aef1-ed100536ede3',
  'de-DE': '33121664-07c3-467d-bd5c-55ec8a5919ff',
};

export const CHERRY_TREE_WHITEPAPER_FORM_IDS = {
  'en-US': '444f8825-15c6-4bda-acae-7499c3a2d92c',
  'de-DE': '4e16cf35-5271-4b56-9c74-9d2af2ba05cd',
};

export const CHERRY_TREE_WHITEPAPER_DOCUMENTS = {
  'en-US': '/cherrytree/cherrytree-whitepaper.pdf',
  'de-DE': '/cherrytree/cherrytree-whitepaper.pdf',
};

export const CHERRY_TREE_SCIENTIFIC_PAPER_FORM_IDS = {
  'en-US': 'b1c01c15-ca46-45a8-9f49-6ba1725c4e44',
  'de-DE': '83e65066-caaf-4df1-9a59-96b285bfa249',
};

export const CHERRY_TREE_SCIENTIFIC_PAPER_DOCUMENTS = {
  'en-US': '/cherrytree/cherrytree-scientific-paper.pdf',
  'de-DE': '/cherrytree/cherrytree-scientific-paper.pdf',
};

export const CHERRY_TREE_PITCH_DECK_FORM_IDS = {
  'en-US': '95bae908-aa06-469c-ba7c-25d645321e20',
  'de-DE': '051d4b32-2b14-4fe8-b7b1-5527d3d003fd',
};

export const CHERRY_TREE_PITCH_DECK_DOCUMENTS = {
  'en-US': '/cherrytree/cherrytree-pitchdeck.pdf',
  'de-DE': '/cherrytree/cherrytree-pitchdeck.pdf',
};

export const CHERRY_TREE_ARTICLE_FORM_IDS = {
  'en-US': '0cce44ee-ff4d-47d0-b7c8-44a6258976b5',
  'de-DE': 'c5b6f63b-c2b9-430a-b91b-5e8d276f2997',
};

export const CHERRY_TREE_ARTICLE_DOCUMENTS = {
  'en-US': '/cherrytree/cherrytree-article.pdf',
  'de-DE': '/cherrytree/cherrytree-article.pdf',
};
