import React from 'react';
import PropTypes from 'prop-types';
import { /* Query , */ graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Collapse } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaCaretUp from 'react-icons/lib/fa/caret-up';
import FaCaretDown from 'react-icons/lib/fa/caret-down';
import FaAngleLeft from 'react-icons/lib/fa/angle-left';
import FaAngleDoubleLeft from 'react-icons/lib/fa/angle-double-left';
import FaAngleRight from 'react-icons/lib/fa/angle-right';
import FaAngleDoubleRight from 'react-icons/lib/fa/angle-double-right';
import FaImage from 'react-icons/lib/fa/image';

import {
  COMPANY,
  PRICE_TOTAL,
  DISPLAY_ZOLL,
  DISPLAY_PPI,
  CAMERA_MPX,
  CAMERA_COUNT,
  STORAGE_RAM,
  STORAGE_ROM,
  WEIGHT,
  TALK_TIME,
  BATTERY_POWER,
  GRADE_CHIP_DE,
  PERCENTAGE_CONNECT_DE,
  POINTS_NETZWELT_DE,
  GRADE_TECHSTAGE_DE,
  GRADE_COMPUTERBILD_DE,
  CAMERA_GRADE_CREATIVEBLOG,
  CAMERA_GRADE_DIGITALCAMERAWORLD,
  CAMERA_GRADE_NOTEBOOKCHECK,
  POPULARITY,
  DISPLAY,
  CAMERA,
  BATTERY,
  STORAGE,
  EXTERNAL_TESTS,
  CAMERA_TESTS,
  rowNameToId,
  rowIdToMessage,
  rowIdToBestSlotMessage,
  handyContractShape,
} from '../../../../data/graphql/Database/demos/o2/phoneProperties';

import Loading from '../../../../components/Loading';
import s from './ResultsList.scss';
import globalDemoMessages from '../../../globalDemoMessages';
// import messages from './messages';

class ResultsList extends React.Component {
  static propTypes = {
    filter: PropTypes.shape({
      PRICE_TOTAL_to: PropTypes.number,
      PRICE_TOTAL_from: PropTypes.number,
      CAMERA_importance: PropTypes.number,
      BATTERY_importance: PropTypes.number,
      DISPLAY_ZOLL_preference: PropTypes.string,
      companies: PropTypes.arrayOf(PropTypes.string),
      include_external_tests: PropTypes.bool,
      include_popularity: PropTypes.bool,
    }),
    handyContractsQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      handyContracts: PropTypes.shape({
        handyContracts: PropTypes.arrayOf(handyContractShape.isRequired),
        columnKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
        columnScores: PropTypes.arrayOf(PropTypes.number).isRequired,
      }),
    }),
    onLoadingFinished: PropTypes.func,
  };

  static defaultProps = {
    filter: {},
    handyContractsQuery: null,
    onLoadingFinished: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
      itemsPerPage: 10,
      extendedEntries: {},
    };

    this.getLastPage = this.getLastPage.bind(this);
    this.toStart = this.toStart.bind(this);
    this.toEnd = this.toEnd.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.renderButtonBar = this.renderButtonBar.bind(this);
    this.renderHandyContract = this.renderHandyContract.bind(this);
  }

  componentDidUpdate(prevProps) {
    // close all extended entries on new query and go back to page 1
    if (prevProps.filter !== this.props.filter) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        currentPage: 0,
        extendedEntries: {},
      });
    }

    if (
      prevProps.handyContractsQuery &&
      prevProps.handyContractsQuery.loading &&
      this.props.handyContractsQuery &&
      !this.props.handyContractsQuery.loading
    ) {
      this.props.onLoadingFinished();
    }

    if (!this.props.handyContractsQuery) return;

    const {
      handyContractsQuery: { loading },
    } = this.props;

    if (loading) return;

    const { currentPage } = this.state;

    // clamp page to last page
    const lastPage = this.getLastPage();
    if (currentPage > lastPage) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentPage: lastPage });
    }
  }

  getLastPage() {
    const { handyContractsQuery } = this.props;

    if (handyContractsQuery.loading || !handyContractsQuery.handyContracts)
      return 0;

    return Math.floor(
      Math.max(
        0,
        handyContractsQuery.handyContracts.handyContracts.length - 1,
      ) / this.state.itemsPerPage,
    );
  }

  toStart() {
    this.setState({
      currentPage: 0,
    });
  }

  toEnd() {
    const {
      handyContractsQuery: { loading },
    } = this.props;

    if (loading) return;

    this.setState({
      currentPage: this.getLastPage(),
    });
  }

  nextPage() {
    this.setState({
      currentPage: Math.min(this.getLastPage(), this.state.currentPage + 1),
    });
  }

  prevPage() {
    this.setState({
      currentPage: Math.max(0, this.state.currentPage - 1),
    });
  }

  renderButtonBar() {
    return (
      <div className={s.buttonBar}>
        <button className="btn-primary" onClick={this.toStart}>
          <FaAngleDoubleLeft />
        </button>
        <button className="btn-primary" onClick={this.prevPage}>
          <FaAngleLeft />
        </button>
        <div className={s.pageCounter}>
          {this.state.currentPage + 1} / {this.getLastPage() + 1}
        </div>
        <button className="btn-primary" onClick={this.nextPage}>
          <FaAngleRight />
        </button>
        <button className="btn-primary" onClick={this.toEnd}>
          <FaAngleDoubleRight />
        </button>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderHandyContract({
    handyContract,
    index,
    perfectDanubeScore,
    // fixedPriceScore,
    // fixedDisplayZollScore,
    // priceColumnIndex,
    // meanPriceScore, // not used atm
  }) {
    if (!this.props.handyContractsQuery.handyContracts) return null;

    const {
      handyContracts: { columnKeys, columnScores },
    } = this.props.handyContractsQuery;

    const img = `/demos/o2/handy-images/${handyContract.id.replace(
      / /g,
      '_',
    )}.jpg`;

    const renderDetailInfo = (labelId, labelValue) => (
      <tr>
        <td>
          <span className={s.label}>
            <FormattedMessage {...rowIdToMessage[labelId]} />:
          </span>
        </td>
        <td>
          <span className={s.value}>
            {labelValue || handyContract[labelId]}
          </span>
        </td>
      </tr>
    );

    // calculate danube score with fixed price score
    let fixedDanubeScore = 0;
    columnKeys.forEach((key, columnIndex) => {
      fixedDanubeScore +=
        handyContract.fish[columnIndex] * columnScores[columnIndex];
      /*
      if (key === rowNameToId[PRICE_TOTAL]) {
        fixedDanubeScore += fixedPriceScore * columnScores[columnIndex];
      } else if (key === rowNameToId[DISPLAY_ZOLL]) {
        fixedDanubeScore += fixedDisplayZollScore * columnScores[columnIndex];
      } else {
        fixedDanubeScore +=
          handyContract.fish[columnIndex] * columnScores[columnIndex];
      }
      */
    });

    // calculate match score
    let matchScore = Math.round(
      parseFloat(fixedDanubeScore / perfectDanubeScore) * 100,
      /*
      parseFloat(
        (fixedDanubeScore) / (perfectDanubeScore - columnScores[priceColumnIndex]), // eslint-disable-line
      ) * 100,
      */
    );
    // clamp match score to [0, 100]
    matchScore = Math.max(0, Math.min(100, matchScore));

    // get css-class for match score container
    let matchScoreClass = '';
    if (matchScore >= 66) {
      matchScoreClass = s.optimalScore;
    } else if (matchScore >= 50) {
      matchScoreClass = s.goodScore;
    } else {
      matchScoreClass = s.mediumScore;
    }

    return (
      <div key={`handyContract-${index}`} className={s.handyContractContainer}>
        <div className={s.imgContainer}>
          {img ? (
            <div className={s.imgContainerInner}>
              <img src={img} alt="" />
            </div>
          ) : (
            <div className={s.noImgContainerInner}>
              <FaImage />
            </div>
          )}
        </div>
        <div className={s.infoContainer}>
          <div className={s.scoreWrapper}>
            <span className={s.phoneNameContainer}>
              {index + 1}. {handyContract.id}
              <span className={matchScoreClass}>{matchScore}% Match</span>
            </span>
          </div>
          <div className={s.bestSlotTagsContainer}>
            {handyContract.fish.map((score, scoreIndex) => {
              if (score === 1) {
                let columnKey = columnKeys[scoreIndex];
                // filter out unimportant fields
                if (
                  (columnKey === rowNameToId[DISPLAY_ZOLL] && this.props.filter.DISPLAY_ZOLL_preference === 'preferMedium') // eslint-disable-line
                ) {
                  return null;
                }
                if (columnKey === rowNameToId[DISPLAY_ZOLL]) {
                  if (
                    this.props.filter.DISPLAY_ZOLL_preference === 'preferBig'
                  ) {
                    columnKey = 'DISPLAY_ZOLL_LARGEST';
                  } else {
                    columnKey = 'DISPLAY_ZOLL_SMALLEST';
                  }
                }
                return (
                  <div key={`bestSlot-${columnKey}`} className={s.bestSlotTag}>
                    <FormattedMessage {...rowIdToBestSlotMessage[columnKey]} />
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className={s.priceMonthContainer}>
            <span className={s.pricePart1}>
              {handyContract[rowNameToId[PRICE_TOTAL]]} €
            </span>
          </div>
          <div className={s.details}>
            <button
              onClick={() => {
                const newExtendedEntries = { ...this.state.extendedEntries };
                newExtendedEntries[handyContract.id] = !newExtendedEntries[handyContract.id]; // eslint-disable-line
                this.setState({
                  extendedEntries: newExtendedEntries,
                });
              }}
            >
              {this.state.extendedEntries[handyContract.id] ? (
                <FaCaretUp />
              ) : (
                <FaCaretDown />
              )}
            </button>
            <Collapse in={this.state.extendedEntries[handyContract.id]}>
              <div>
                <div className={s.collapsableContainer}>
                  <table>
                    <tbody>
                      {renderDetailInfo(rowNameToId[COMPANY])}
                      {renderDetailInfo(
                        rowNameToId[DISPLAY_ZOLL],
                        handyContract.DISPLAY_ZOLL_ORIGINAL,
                      )}
                      {renderDetailInfo(rowNameToId[DISPLAY_PPI])}
                      {renderDetailInfo(rowNameToId[CAMERA_MPX])}
                      {renderDetailInfo(rowNameToId[CAMERA_COUNT])}
                      {renderDetailInfo(rowNameToId[STORAGE_RAM])}
                      {renderDetailInfo(rowNameToId[STORAGE_ROM])}
                      {renderDetailInfo(rowNameToId[WEIGHT])}
                      {renderDetailInfo(rowNameToId[TALK_TIME])}
                      {renderDetailInfo(rowNameToId[BATTERY_POWER])}
                      {renderDetailInfo(rowNameToId[GRADE_CHIP_DE])}
                      {renderDetailInfo(rowNameToId[PERCENTAGE_CONNECT_DE])}
                      {renderDetailInfo(rowNameToId[POINTS_NETZWELT_DE])}
                      {renderDetailInfo(rowNameToId[GRADE_TECHSTAGE_DE])}
                      {renderDetailInfo(rowNameToId[GRADE_COMPUTERBILD_DE])}
                      {renderDetailInfo(rowNameToId[CAMERA_GRADE_CREATIVEBLOG])}
                      {renderDetailInfo(
                        rowNameToId[CAMERA_GRADE_DIGITALCAMERAWORLD],
                      )}
                      {renderDetailInfo(
                        rowNameToId[CAMERA_GRADE_NOTEBOOKCHECK],
                      )}
                      {renderDetailInfo(rowNameToId[POPULARITY])}
                    </tbody>
                  </table>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { handyContractsQuery /* , filter */ } = this.props;

    if (!handyContractsQuery) return null;

    if (handyContractsQuery.loading) {
      return <Loading />;
    }

    if (!handyContractsQuery.handyContracts) return null;

    const {
      handyContracts: { handyContracts, columnKeys, columnScores },
    } = handyContractsQuery;

    /*
    // make a correction of the price column scores (undo the min value clamping)
    const priceColumnIndex = columnKeys.indexOf(rowNameToId[PRICE_TOTAL]);
    const prices = handyContracts.map(
      handyContract => handyContract.PRICE_TOTAL,
    );
    const maxPriceScore = Math.max(...prices);
    const minPriceScore = Math.min(...prices);
    const lowerPriceBound = minPriceScore / maxPriceScore;
    const fixedPriceScores = handyContracts.map(
      handyContract =>
        // map price score from [0, 1] to [lowerPriceBound, 1 + lowerPriceBound]
        lowerPriceBound + handyContract.fish[priceColumnIndex],
    );

    // make a correction of the display zoll column scores (undo the min value clamping when 'preferSmall' is selected)
    const displayZollColumnIndex = columnKeys.indexOf(
      rowNameToId[DISPLAY_ZOLL],
    );
    const displayZolls = handyContracts.map(
      handyContract => handyContract.DISPLAY_ZOLL,
    );
    const maxDisplayZollScore = Math.max(...displayZolls);
    const minDisplayZollScore = Math.min(...displayZolls);
    const lowerDisplayZollBound = minDisplayZollScore / maxDisplayZollScore;
    const fixedDisplayZollScores = handyContracts.map(handyContract => {
      if (filter.DISPLAY_ZOLL_preference === 'preferSmall') {
        // map display zoll score from [0, 1] to [lowerDisplayZollBound, 1 + lowerDisplayZollBound]
        return (
          lowerDisplayZollBound + handyContract.fish[displayZollColumnIndex]
        );
      }
      // return normal value when 'preferBig' is selected
      return handyContract.fish[displayZollColumnIndex];
    });
    */

    // select page of handy contracts
    const handyContractsPage = handyContracts.slice(
      this.state.currentPage * this.state.itemsPerPage,
      (this.state.currentPage + 1) * this.state.itemsPerPage,
    );

    // normalize column scores
    const total = columnScores.reduce((sum, score) => sum + score, 0);
    const normalizedColumnScores = columnScores.map(
      score => (score / total) * 100,
    );

    // remap normalized column scores (some properties are grouped)
    const groupedColumKeys = [];
    const groupedNormalizedColumnScores = [];
    columnKeys.forEach((columnKey, index) => {
      if (
        // group "display" together
        columnKey === rowNameToId[DISPLAY_ZOLL] ||
        columnKey === rowNameToId[DISPLAY_PPI]
      ) {
        if (!groupedColumKeys.includes(rowNameToId[DISPLAY])) {
          groupedColumKeys.push(rowNameToId[DISPLAY]);
          groupedNormalizedColumnScores.push(normalizedColumnScores[index]);
        } else {
          const cIndex = groupedColumKeys.indexOf(rowNameToId[DISPLAY]);
          groupedNormalizedColumnScores[cIndex] +=
            normalizedColumnScores[index];
        }
      } else if (
        // group "camera" together
        columnKey === rowNameToId[CAMERA_MPX] ||
        columnKey === rowNameToId[CAMERA_COUNT]
      ) {
        if (!groupedColumKeys.includes(rowNameToId[CAMERA])) {
          groupedColumKeys.push(rowNameToId[CAMERA]);
          groupedNormalizedColumnScores.push(normalizedColumnScores[index]);
        } else {
          const cIndex = groupedColumKeys.indexOf(rowNameToId[CAMERA]);
          groupedNormalizedColumnScores[cIndex] +=
            normalizedColumnScores[index];
        }
      } else if (
        // group "camera grade" together
        columnKey === rowNameToId[CAMERA_GRADE_CREATIVEBLOG] ||
        columnKey === rowNameToId[CAMERA_GRADE_DIGITALCAMERAWORLD] ||
        columnKey === rowNameToId[CAMERA_GRADE_NOTEBOOKCHECK]
      ) {
        if (!groupedColumKeys.includes(rowNameToId[CAMERA_TESTS])) {
          groupedColumKeys.push(rowNameToId[CAMERA_TESTS]);
          groupedNormalizedColumnScores.push(normalizedColumnScores[index]);
        } else {
          const cIndex = groupedColumKeys.indexOf(rowNameToId[CAMERA_TESTS]);
          groupedNormalizedColumnScores[cIndex] +=
            normalizedColumnScores[index];
        }
      } else if (
        // group "battery" together
        columnKey === rowNameToId[BATTERY_POWER] ||
        columnKey === rowNameToId[TALK_TIME]
      ) {
        if (!groupedColumKeys.includes(rowNameToId[BATTERY])) {
          groupedColumKeys.push(rowNameToId[BATTERY]);
          groupedNormalizedColumnScores.push(normalizedColumnScores[index]);
        } else {
          const cIndex = groupedColumKeys.indexOf(rowNameToId[BATTERY]);
          groupedNormalizedColumnScores[cIndex] +=
            normalizedColumnScores[index];
        }
      } else if (
        // group "storage" together
        columnKey === rowNameToId[STORAGE_RAM] ||
        columnKey === rowNameToId[STORAGE_ROM]
      ) {
        if (!groupedColumKeys.includes(rowNameToId[STORAGE])) {
          groupedColumKeys.push(rowNameToId[STORAGE]);
          groupedNormalizedColumnScores.push(normalizedColumnScores[index]);
        } else {
          const cIndex = groupedColumKeys.indexOf(rowNameToId[STORAGE]);
          groupedNormalizedColumnScores[cIndex] +=
            normalizedColumnScores[index];
        }
      } else if (
        // group "external tests" together
        columnKey === rowNameToId[GRADE_CHIP_DE] ||
        columnKey === rowNameToId[PERCENTAGE_CONNECT_DE] ||
        columnKey === rowNameToId[POINTS_NETZWELT_DE] ||
        columnKey === rowNameToId[GRADE_TECHSTAGE_DE] ||
        columnKey === rowNameToId[GRADE_COMPUTERBILD_DE]
      ) {
        if (!groupedColumKeys.includes(rowNameToId[EXTERNAL_TESTS])) {
          groupedColumKeys.push(rowNameToId[EXTERNAL_TESTS]);
          groupedNormalizedColumnScores.push(normalizedColumnScores[index]);
        } else {
          const cIndex = groupedColumKeys.indexOf(rowNameToId[EXTERNAL_TESTS]);
          groupedNormalizedColumnScores[cIndex] +=
            normalizedColumnScores[index];
        }
      } else {
        // no grouping
        groupedColumKeys.push(columnKey);
        groupedNormalizedColumnScores.push(normalizedColumnScores[index]);
      }
    });

    // get max column score
    const maxColumnScore = Math.max(...groupedNormalizedColumnScores);

    // get the perfect danube score
    const perfectDanubeScore = columnScores.reduce(
      (scoreSum, score) => scoreSum + score,
      0,
    );

    /*
    // get the mean score for the price column
    const meanPriceScore =
      (handyContracts.reduce(
        (sum, handyContract) => sum + handyContract.fish[priceColumnIndex],
        0,
      ) / handyContracts.length) * columnScores[priceColumnIndex]; // eslint-disable-line
    */

    return (
      <div className={s.resultsListContainer}>
        <div className={s.metadataContainer}>
          <div className={s.resultsWrapper}>
            <span className={s.label}>
              {handyContracts.length}
              &nbsp;
              <FormattedMessage {...globalDemoMessages.resultsFound} />
            </span>
          </div>
          {groupedNormalizedColumnScores.length > 0 && (
            <div>
              {groupedNormalizedColumnScores.map((score, index) => (
                <div
                  key={`normalizedColumnScore-${index}`} // eslint-disable-line react/no-array-index-key
                  className={
                    score === maxColumnScore
                      ? s.danubeWrapper
                      : s.danubeWrapperLight
                  }
                >
                  <span className={s.label}>
                    <FormattedMessage
                      {...rowIdToMessage[groupedColumKeys[index]]}
                    />
                    :&nbsp;
                    {Math.round(score)}%
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
        {this.renderButtonBar()}
        <div className={s.handyContractsContainer}>
          {handyContracts.length === 0 ? (
            <div className={s.noResultsContainer}>
              <FormattedMessage {...globalDemoMessages.noResults} />
            </div>
          ) : (
            <div>
              {handyContractsPage.map((handyContract, index) => {
                const rowIndex =
                  this.state.currentPage * this.state.itemsPerPage + index;
                return this.renderHandyContract({
                  handyContract,
                  index: rowIndex,
                  perfectDanubeScore,
                  // fixedPriceScore: fixedPriceScores[rowIndex],
                  // fixedDisplayZollScore: fixedDisplayZollScores[rowIndex],
                  // priceColumnIndex,
                  // meanPriceScore,
                });
              })}
            </div>
          )}
        </div>
        {this.renderButtonBar()}
      </div>
    );
  }
}

const handyContractsQuery = gql`
  query handyContractsQuery($filter: HandyContractsFilter) {
    handyContracts(filter: $filter) {
      handyContracts {
        id
        COMPANY
        PRICE_TOTAL
        DISPLAY_ZOLL_ORIGINAL
        DISPLAY_ZOLL
        DISPLAY_PPI
        CAMERA_MPX
        CAMERA_COUNT
        STORAGE_RAM
        STORAGE_ROM
        WEIGHT
        TALK_TIME
        BATTERY_POWER
        GRADE_CHIP_DE
        PERCENTAGE_CONNECT_DE
        POINTS_NETZWELT_DE
        GRADE_TECHSTAGE_DE
        GRADE_COMPUTERBILD_DE
        CAMERA_GRADE_CREATIVEBLOG
        CAMERA_GRADE_DIGITALCAMERAWORLD
        CAMERA_GRADE_NOTEBOOKCHECK
        POPULARITY
        danubeScore
        danubeRanking
        fish
      }
      columnKeys
      columnScores
    }
  }
`;

export default compose(
  graphql(handyContractsQuery, {
    name: 'handyContractsQuery',
    options: ({ filter }) => ({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
    }),
    skip: ({ skipPageLoadQuery }) => skipPageLoadQuery,
  }),
)(withStyles(s)(ResultsList));
