import { defineMessages } from 'react-intl';

const messages = defineMessages({
  label: {
    id: 'cherryTree.downloadWhitepaper.label',
    defaultMessage: 'Download Whitepaper',
    description: 'Label for Download Whitepaper button',
  },
  download: {
    id: 'cherryTree.downloadWhitepaper.download',
    defaultMessage: 'Click here to download the Whitepaper.',
    description: 'Label for Download Whitepaper link',
  },
});

export default messages;
