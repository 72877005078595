/**
 * HOC that checks if the current user is logged in,
 * and additionally checks if the current route
 * is a use cases route.
 * If so, it renders the wrapped component and
 * passes the current user's information to
 * it via the 'me' prop, where me is either
 * the current user, or the demo user if
 * the current route is a use cases route.
 *
 * Otherwise, it redirects to the homepage.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { meQuery } from 'components/Auth/withLogin';
import Loading from 'components/Loading';

const RequiresAuthenticationOrResultsTableWidgetWrapper = WrappedComponent => {
  class WithAuthentication extends React.Component {
    static propTypes = {
      meData: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        me: PropTypes.shape({
          id: PropTypes.string.isRequired,
          privacyAgreementAccepted: PropTypes.bool.isRequired,
          roles: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
            }),
          ),
        }),
      }).isRequired,
      match: PropTypes.shape({
        path: PropTypes.string.isRequired,
      }).isRequired,
    };

    render() {
      const {
        meData: { loading, me },
        match: { path },
      } = this.props;

      if (loading) return <Loading />;

      const isUseCaseRoute =
        path.indexOf('/use-cases') !== -1 || path.indexOf('/api') !== -1;
      if (isUseCaseRoute) {
        return (
          <WrappedComponent
            {...this.props}
            me={{ ...window.App.useCaseWidgetDemoUser }}
          />
        );
      }

      if (!me) {
        return <Redirect to="/login" />;
      }
      if (!me.privacyAgreementAccepted) {
        return <Redirect to="/privacy-agreement" />;
      }

      return <WrappedComponent {...this.props} me={me} />;
    }
  }

  return compose(
    graphql(meQuery, {
      name: 'meData',
      options: {
        fetchPolicy: 'no-cache',
      },
    }),
  )(withRouter(WithAuthentication));
};

export default RequiresAuthenticationOrResultsTableWidgetWrapper;
