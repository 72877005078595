export default function importer(
  state = { rulesExpanded: false, hoveredRow: -1 },
  action,
) {
  switch (action.type) {
    case 'TOGGLE_RULES_EXPANDED':
      return {
        ...state,
        rulesExpanded: !state.rulesExpanded,
      };
    case 'TOGGLE_HOVERED_ROW':
      return {
        ...state,
        hoveredRow: action.hoveredRow,
      };
    default:
      return state;
  }
}
