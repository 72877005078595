import _ from 'lodash';

const validations = {
  required: value => value,
  requiredArray: value =>
    _.isEmpty(value)
      ? false
      : value.filter(v => !_.isEmpty(v) && /^\d+$/.test(v)).length !== 0,
  email: value =>
    !(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)),
  checkPassword: value =>
    /^(?=.*\d)(?=.*[A-Z])(?=.*[!"§$%&/()=?@#'+~*^+.,;:_-]).{8,}$/.test(value),
  slug: value => value && /^[a-z0-9]+(?:-[a-z0-9]+)*$/.test(value),
};

export default validations;
