import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  FaCaretUp,
  FaCaretDown,
  FaAngleLeft,
  FaAngleDoubleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
  FaImage,
} from 'react-icons/lib/fa';
import seedrandom from 'seedrandom';

import layoutStyle from '../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from './ResultsList.scss';
import globalDemoMessages from '../../../globalDemoMessages';
import messages from './messages';
import searchMaskMessages from '../SearchMask/messages';
import { OptionParameterNamesMap } from '../SearchMask/SearchMaskForm';
import { filterType, apartmentType } from '../ResultsList/types';

const rentImages = [
  '/demos/immo/real-estate-images/rent_1.jpg',
  '/demos/immo/real-estate-images/rent_2.jpg',
  '/demos/immo/real-estate-images/rent_3.jpg',
  '/demos/immo/real-estate-images/rent_4.jpg',
  '/demos/immo/real-estate-images/rent_5.jpg',
  '/demos/immo/real-estate-images/rent_6.jpg',
  '/demos/immo/real-estate-images/rent_7.jpg',
  '/demos/immo/real-estate-images/rent_8.jpg',
  '/demos/immo/real-estate-images/rent_9.jpg',
  '/demos/immo/real-estate-images/rent_10.jpg',
  '/demos/immo/real-estate-images/rent_11.jpg',
  '/demos/immo/real-estate-images/rent_12.jpg',
];

const buyImages = [
  '/demos/immo/real-estate-images/buy_1.jpg',
  '/demos/immo/real-estate-images/buy_2.jpg',
  '/demos/immo/real-estate-images/buy_3.jpg',
  '/demos/immo/real-estate-images/buy_4.jpg',
  '/demos/immo/real-estate-images/buy_5.jpg',
  '/demos/immo/real-estate-images/buy_6.jpg',
  '/demos/immo/real-estate-images/buy_7.jpg',
  '/demos/immo/real-estate-images/buy_8.jpg',
  '/demos/immo/real-estate-images/buy_9.jpg',
  '/demos/immo/real-estate-images/buy_10.jpg',
  '/demos/immo/real-estate-images/buy_11.jpg',
  '/demos/immo/real-estate-images/buy_12.jpg',
];

class ResultsList extends React.Component {
  static propTypes = {
    filter: filterType,
    apartmentsData: PropTypes.shape({
      apartments: PropTypes.arrayOf(apartmentType).isRequired,
      columnScores: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    itemsPerPage: PropTypes.number,
    compact: PropTypes.bool,
    compactTopResultsCount: PropTypes.number,
  };

  static defaultProps = {
    filter: {},
    itemsPerPage: 10,
    compact: false,
    compactTopResultsCount: 3,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
    };

    this.getLastPage = this.getLastPage.bind(this);
    this.toStart = this.toStart.bind(this);
    this.toEnd = this.toEnd.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.renderButtonBar = this.renderButtonBar.bind(this);
    this.renderApartment = this.renderApartment.bind(this);
  }

  componentDidUpdate() {
    const { currentPage } = this.state;

    // clamp page to last page
    const lastPage = this.getLastPage();
    if (currentPage > lastPage) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentPage: lastPage });
    }
  }

  getLastPage() {
    const {
      apartmentsData: { apartments },
    } = this.props;

    return Math.floor(
      Math.max(0, apartments.length - 1) / this.props.itemsPerPage,
    );
  }

  toStart() {
    this.setState({
      currentPage: 0,
    });
  }

  toEnd() {
    this.setState({
      currentPage: this.getLastPage(),
    });
  }

  nextPage() {
    this.setState({
      currentPage: Math.min(this.getLastPage(), this.state.currentPage + 1),
    });
  }

  prevPage() {
    this.setState({
      currentPage: Math.max(0, this.state.currentPage - 1),
    });
  }

  renderButtonBar() {
    return (
      <div className={s.buttonBar}>
        <button className="btn-primary" onClick={this.toStart}>
          <FaAngleDoubleLeft />
        </button>
        <button className="btn-primary" onClick={this.prevPage}>
          <FaAngleLeft />
        </button>
        <div className={s.pageCounter}>
          {this.state.currentPage + 1} / {this.getLastPage() + 1}
        </div>
        <button className="btn-primary" onClick={this.nextPage}>
          <FaAngleRight />
        </button>
        <button className="btn-primary" onClick={this.toEnd}>
          <FaAngleDoubleRight />
        </button>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderApartment(apartment, index) {
    let img = null;

    // get dummy image based on seeded randomizer with meta_exposeId as seed
    const randomNumberGenerator = seedrandom(apartment.meta_exposeId);

    if (
      apartment.type_transferType === 'RENT' ||
      apartment.type_transferType === 'TEMPORARY_RENT'
    ) {
      const randIndex = Math.floor(randomNumberGenerator() * rentImages.length);
      img = rentImages[randIndex];
    } else if (
      apartment.type_transferType === 'BUY' ||
      apartment.type_transferType === 'LEASE'
    ) {
      const randIndex = Math.floor(randomNumberGenerator() * buyImages.length);
      img = buyImages[randIndex];
    }

    let posChange =
      apartment.priceSortedPosition !== null
        ? apartment.priceSortedPosition - index
        : 0;
    if (this.props.filter && this.props.filter.sortDanubeDesc) {
      posChange *= -1;
    }
    let posClass = '';
    if (posChange > 0) posClass = s.positiveChange;
    if (posChange < 0) posClass = s.negativeChange;

    return (
      <div key={`apartment-${index}`} className={s.apartmentContainer}>
        <div className={s.imgContainer}>
          {img ? (
            <img src={img} alt="" />
          ) : (
            <div className={s.noImgContainer}>
              <FaImage />
            </div>
          )}
        </div>
        <div className={s.infoContainer}>
          <div className={s.scoreWrapper}>
            {posChange !== 0 ? (
              <span>
                <div style={{ display: 'inline-block' }}>
                  <span className={s.position}>{index + 1}.</span>
                </div>
                <div style={{ display: 'inline-block' }}>
                  <span className={posClass}>
                    {posChange > 0 && (
                      <span>
                        <FaCaretUp />+
                      </span>
                    )}
                    {posChange < 0 && (
                      <span>
                        <FaCaretDown />
                      </span>
                    )}
                    {posChange !== 0 && <span>{posChange}</span>}
                  </span>
                </div>
              </span>
            ) : (
              <span className={s.position}>{index + 1}.</span>
            )}
          </div>
          <div>
            <span className={s.label}>
              <FormattedMessage {...messages.location} />:
            </span>
            &nbsp;
            {apartment.localization_address_zip}
          </div>
          <div className={s.tags}>
            {apartment.area_numberOfBalconies > 0 && (
              <span className={s.tag}>
                <FormattedMessage {...searchMaskMessages.numberOfBalconies} />:
                &nbsp;
                {apartment.area_numberOfBalconies}
              </span>
            )}
            {apartment.area_numberOfTerrace > 0 && (
              <span className={s.tag}>
                <FormattedMessage {...searchMaskMessages.numberOfTerrace} />:
                &nbsp;
                {apartment.area_numberOfTerrace}
              </span>
            )}
          </div>
          <Row
            // eslint-disable-next-line prettier/prettier
            className={`${layoutStyle.noMargin} ${layoutStyle.noPadding} ${s.importantInfo}`}
          >
            <Col className={s.cell} xs="12" md="4">
              <div>
                <span className={s.label}>
                  <FormattedMessage {...searchMaskMessages.area} /> m²
                </span>
              </div>
              <div>
                <span className={s.value}>
                  {apartment.area_total_area !== 'NULL' &&
                  apartment.area_total_area != null ? (
                    <FormattedNumber value={apartment.area_total_area} />
                  ) : (
                    '-'
                  )}
                </span>
              </div>
            </Col>
            <Col className={`${s.cell} ${s.cellMiddle}`} xs="12" md="4">
              <div>
                <span className={s.label}>
                  <FormattedMessage {...searchMaskMessages.rooms} />
                </span>
              </div>
              <div>
                <span className={s.value}>
                  {apartment.area_numberOfRooms !== 'NULL' &&
                  apartment.area_numberOfRooms != null
                    ? apartment.area_numberOfRooms
                    : '-'}
                </span>
              </div>
            </Col>
            <Col className={s.cell} xs="12" md="4">
              <div>
                <span className={s.label}>
                  <FormattedMessage
                    {...OptionParameterNamesMap[apartment.type_transferType]}
                  />
                  &nbsp; (€):
                </span>
              </div>
              <div>
                <span className={s.value}>
                  {apartment.priceInformation_primaryPrice !== 'NULL' &&
                  apartment.priceInformation_primaryPrice !== null ? (
                    <FormattedNumber
                      value={apartment.priceInformation_primaryPrice}
                    />
                  ) : (
                    '-'
                  )}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    const {
      filter,
      apartmentsData,
      compact,
      compactTopResultsCount,
    } = this.props;
    const { apartments, columnScores } = apartmentsData;

    // select page of apartments
    let apartmentsPage;

    if (compact) {
      apartmentsPage = apartments.slice(0, compactTopResultsCount);
    } else {
      apartmentsPage = apartments.slice(
        this.state.currentPage * this.props.itemsPerPage,
        (this.state.currentPage + 1) * this.props.itemsPerPage,
      );
    }

    // count changes of danube
    let danubeChanges = 0;
    if (filter.sortDanubeAsc || filter.sortDanubeDesc) {
      danubeChanges = apartments.reduce((counter, apartment, index) => {
        let add = 0;
        if ('priceSortedPosition' in apartment) {
          add = Math.abs(apartment.priceSortedPosition - index);
        }
        return counter + add;
      }, 0);
    }

    let normalizedColumnScores = [];
    let maxScore = -1;

    // normalize column scores
    if (columnScores && columnScores.length > 0) {
      const total = columnScores.reduce((sum, score) => sum + score, 0);
      normalizedColumnScores = columnScores.map(score => (score / total) * 100);
      maxScore = Math.max(...normalizedColumnScores);
    }

    const columnScoresMapping = [
      <FormattedMessage {...messages.priceTag} />,
      <FormattedMessage {...searchMaskMessages.rooms} />,
      <FormattedMessage {...searchMaskMessages.balconyAndTerrace} />,
      <FormattedMessage {...messages.gardenTag} />,
      <FormattedMessage {...messages.garageTag} />,
      <FormattedMessage {...messages.imageCountTag} />,
      <FormattedMessage {...searchMaskMessages.area} />,
    ];

    return (
      <div className={s.resultsListContainer}>
        {!compact && (
          <div className={s.metadataContainer}>
            <div className={s.resultsWrapper}>
              <span className={s.label}>
                {apartments.length}
                &nbsp;
                <FormattedMessage {...globalDemoMessages.resultsFound} />
              </span>
            </div>
            {(filter.sortDanubeAsc || filter.sortDanubeDesc) && (
              <div className={s.danubeResultCountWrapper}>
                <span className={s.label}>
                  {danubeChanges}
                  &nbsp;
                  <FormattedMessage {...globalDemoMessages.changesByDanube} />
                </span>
              </div>
            )}
            {normalizedColumnScores.length > 0 && (
              <div>
                {normalizedColumnScores.map((score, index) => (
                  <div
                    key={`score-${index}`} // eslint-disable-line react/no-array-index-key
                    className={
                      score === maxScore
                        ? s.danubeWrapper
                        : s.danubeWrapperLight
                    }
                  >
                    <span className={s.label}>
                      {columnScoresMapping[index]}: &nbsp;
                      {Math.round(score)}%
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {!compact && this.renderButtonBar()}
        <div className={s.apartmentsContainer}>
          {apartments.length === 0 ? (
            <div className={s.noResultsContainer}>
              <FormattedMessage {...globalDemoMessages.noResults} />
            </div>
          ) : (
            <div>
              {apartmentsPage.map((apartment, index) =>
                this.renderApartment(
                  apartment,
                  this.state.currentPage * this.props.itemsPerPage + index,
                ),
              )}
            </div>
          )}
        </div>
        {!compact && this.renderButtonBar()}
      </div>
    );
  }
}

export default withStyles(layoutStyle, s)(ResultsList);
