import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Field } from 'redux-form';

import s from './NewAnalysisCard.scss'; // eslint-disable-line css-modules/no-unused-class
import render from '../../ReduxForm/renderField';

class UploadFileForm extends React.Component {
  render() {
    return (
      <div className={s.dropzoneContainer}>
        <Field
          name="import.excel"
          component={render.renderDropzone}
          multiple={false}
        />
      </div>
    );
  }
}

export default withStyles(s)(UploadFileForm);
