import { defineMessages } from 'react-intl';

const messages = defineMessages({
  // gernal messages used by more than 2 sub landing pages
  betterThanOthers: {
    id: 'landingPage.sub.betterThanOthers',
    defaultMessage: 'More than 20% better than other methods',
    description: 'Label for landing page.',
  },

  // messages for Media Landing Page
  mediaFrontPageText: {
    id: 'landingPage.media.frontPageText',
    defaultMessage: 'What your reader likes gets harder to tell every day',
    description: 'Label for landing page.',
  },
  mediaSlide1Text1: {
    id: 'landingPage.media.slide1Text1',
    defaultMessage: 'You have great content, but...',
    description: 'Label for landing page.',
  },
  mediaSlide1TileText1: {
    id: 'landingPage.media.slide1TileText1',
    defaultMessage: `
      Readers
      <br />
      may miss your
      <br />
      best stuff
    `,
    description: 'Label for landing page.',
  },
  mediaSlide1TileText2: {
    id: 'landingPage.media.slide1TileText2',
    defaultMessage: `
      Handling
      <br />
      Big Data may cost
      <br />
      you dearly
    `,
    description: 'Label for landing page.',
  },
  mediaSlide1TileText3: {
    id: 'landingPage.media.slide1TileText3',
    defaultMessage: `
      In the end
      <br />
      readers look for
      <br />
      alternatives
    `,
    description: 'Label for landing page.',
  },
  mediaSlide2Text1: {
    id: 'landingPage.media.slide2Text1',
    defaultMessage: '{danube} makes your readers happy',
    description: 'Label for landing page.',
  },
  mediaSlide2TileText1: {
    id: 'landingPage.media.slide2TileText1',
    defaultMessage: `
      Recommends
      <br />
      the right content for
      <br />
      every single reader
    `,
    description: 'Label for landing page.',
  },
  mediaSlide2TileText2: {
    id: 'landingPage.media.slide2TileText2',
    defaultMessage: `
      Provides
      <br />
      Plug & Play with
      <br />
      ready-made SDK
    `,
    description: 'Label for landing page.',
  },
  mediaSlide2TileText3: {
    id: 'landingPage.media.slide2TileText3',
    defaultMessage: `
      In the end
      <br />
      your readers
      <br />
      stay longer
    `,
    description: 'Label for landing page.',
  },
  mediaSlide4Text1: {
    id: 'landingPage.media.slide4Text1',
    defaultMessage: `
      Improve your recommendations and increase the time the user spends on your site.
    `,
    description: 'Label for landing page.',
  },
  mediaSlide4Text2: {
    id: 'landingPage.media.slide4Text2',
    defaultMessage: `
      Understand your users and find surprising correlations between user paths.
    `,
    description: 'Label for landing page.',
  },
  mediaSlide4Text3: {
    id: 'landingPage.media.slide4Text3',
    defaultMessage: `
      Understand every single user and find surprising correlations behind your content.
    `,
    description: 'Label for landing page.',
  },

  // messages for E-Commerce Landing Page
  ecommerceFrontPageText: {
    id: 'landingPage.ecommerce.frontPageText',
    defaultMessage: 'What your customer likes gets harder to tell every day',
    description: 'Label for landing page.',
  },
  ecommerceSlide1Text1: {
    id: 'landingPage.ecommerce.slide1Text1',
    defaultMessage: 'You have great products, but...',
    description: 'Label for landing page.',
  },
  ecommerceSlide1TileText1: {
    id: 'landingPage.ecommerce.slide1TileText1',
    defaultMessage: `
      Customers
      <br />
      may miss your
      <br />
      best stuff
    `,
    description: 'Label for landing page.',
  },
  ecommerceSlide1TileText2: {
    id: 'landingPage.ecommerce.slide1TileText2',
    defaultMessage: `
      The wrong
      <br />
      product can deter
      <br />
      customers
    `,
    description: 'Label for landing page.',
  },
  ecommerceSlide1TileText3: {
    id: 'landingPage.ecommerce.slide1TileText3',
    defaultMessage: `
      You want
      <br />
      sticking customers not
      <br />
      sticking products
    `,
    description: 'Label for landing page.',
  },
  ecommerceSlide2Text1: {
    id: 'landingPage.ecommerce.slide2Text1',
    defaultMessage: '{danube} makes your customers happy',
    description: 'Label for landing page.',
  },
  ecommerceSlide2TileText1: {
    id: 'landingPage.ecommerce.slide2TileText1',
    defaultMessage: `
      Customers get
      <br />
      what they are
      <br />
      looking for
    `,
    description: 'Label for landing page.',
  },
  ecommerceSlide2TileText2: {
    id: 'landingPage.ecommerce.slide2TileText2',
    defaultMessage: `
      Keep your
      <br />
      customers in
      <br />
      your store
    `,
    description: 'Label for landing page.',
  },
  ecommerceSlide2TileText3: {
    id: 'landingPage.ecommerce.slide2TileText3',
    defaultMessage: `
      Recommending
      <br />
      the right products
      <br />
      gets them sold
    `,
    description: 'Label for landing page.',
  },
  ecommerceSlide4Text1: {
    id: 'landingPage.ecommerce.slide4Text1',
    defaultMessage: `
      Improve your recommendations and sell more products.
    `,
    description: 'Label for landing page.',
  },
  ecommerceSlide4Text2: {
    id: 'landingPage.ecommerce.slide4Text2',
    defaultMessage: `
      Get to know your users and discover paired products beyond categories.
    `,
    description: 'Label for landing page.',
  },
  ecommerceSlide4Text3: {
    id: 'landingPage.ecommerce.slide4Text3',
    defaultMessage: `
      Understand every single user and find surprising correlations behind your products.
    `,
    description: 'Label for landing page.',
  },

  // messages for Recruiting Landing Page
  recruitingFrontPageText: {
    id: 'landingPage.recruiting.frontPageText',
    defaultMessage: 'Recruiting the right people is critical to your business',
    description: 'Label for landing page.',
  },
  recruitingSlide1Text1: {
    id: 'landingPage.recruiting.slide1Text1',
    defaultMessage: 'You are looking hard for good talent, but...',
    description: 'Label for landing page.',
  },
  recruitingSlide1TileText1: {
    id: 'landingPage.recruiting.slide1TileText1',
    defaultMessage: `
      Sometimes
      <br />
      your pool
      <br />
      seems empty
    `,
    description: 'Label for landing page.',
  },
  recruitingSlide1TileText2: {
    id: 'landingPage.recruiting.slide1TileText2',
    defaultMessage: `
      In other cases you
      <br />
      seem to be overflowing
      <br />
      with candidates
    `,
    description: 'Label for landing page.',
  },
  recruitingSlide1TileText3: {
    id: 'landingPage.recruiting.slide1TileText3',
    defaultMessage: `
      In the end
      <br />
      you may miss
      <br />
      the best
    `,
    description: 'Label for landing page.',
  },
  recruitingSlide2Text1: {
    id: 'landingPage.recruiting.slide2Text1',
    defaultMessage: '{danube} fills your open position',
    description: 'Label for landing page.',
  },
  recruitingSlide2TileText1: {
    id: 'landingPage.recruiting.slide2TileText1',
    defaultMessage: `
      Finds matching
      <br />
      candidates outside
      <br />
      your pool
    `,
    description: 'Label for landing page.',
  },
  recruitingSlide2TileText2: {
    id: 'landingPage.recruiting.slide2TileText2',
    defaultMessage: `
      Finds the top
      <br />
      talents in your
      <br />
      candidate pool
    `,
    description: 'Label for landing page.',
  },
  recruitingSlide2TileText3: {
    id: 'landingPage.recruiting.slide2TileText3',
    defaultMessage: `
      You will never
      <br />
      miss a talent
      <br />
      again
    `,
    description: 'Label for landing page.',
  },
  recruitingSlide3Text1: {
    id: 'landingPage.recruiting.slide3Text1',
    defaultMessage: '{danube} works where others fail',
    description: 'Label for landing page.',
  },
  recruitingSlide3Text2: {
    id: 'landingPage.recruiting.slide3Text2',
    defaultMessage: 'Classical AI',
    description: 'Label for landing page.',
  },
  recruitingSlide3Text3: {
    id: 'landingPage.recruiting.slide3Text3',
    defaultMessage: 'No preselection',
    description: 'Label for landing page.',
  },
  recruitingSlide3Text4: {
    id: 'landingPage.recruiting.slide3Text4',
    defaultMessage: 'No candidate categories',
    description: 'Label for landing page.',
  },
  recruitingSlide3Text5: {
    id: 'landingPage.recruiting.slide3Text5',
    defaultMessage: 'No prejudice',
    description: 'Label for landing page.',
  },
  recruitingSlide3Text6: {
    id: 'landingPage.recruiting.slide3Text6',
    defaultMessage: 'Expands candidate pool',
    description: 'Label for landing page.',
  },
  recruitingSlide3Text7: {
    id: 'landingPage.recruiting.slide3Text7',
    defaultMessage: 'Compares real people',
    description: 'Label for landing page.',
  },
  recruitingSlide3Text8: {
    id: 'landingPage.recruiting.slide3Text8',
    defaultMessage: 'Individual talent matches',
    description: 'Label for landing page.',
  },
  recruitingSlide4Text1: {
    id: 'landingPage.recruiting.slide4Text1',
    defaultMessage: 'Recruiting the right way',
    description: 'Label for landing page.',
  },
  recruitingSlide4Text2: {
    id: 'landingPage.recruiting.slide4Text2',
    defaultMessage: 'Candidates apply to your comany',
    description: 'Label for landing page.',
  },
  recruitingSlide4Text3: {
    id: 'landingPage.recruiting.slide4Text3',
    defaultMessage: '{danube} selects the top talents',
    description: 'Label for landing page.',
  },
});

export default messages;
