import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SyntaxHighlighter from 'react-syntax-highlighter';

import layoutStyle from '../../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from '../../../Page.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../../messages';
import wikiMessages from '../../../messages';
import { codeStyle, getRules, rulesSet } from '../../codeBlocks';

class GetRules extends React.Component {
  render() {
    return (
      <div className={s.apiText}>
        <p>
          <h1>
            <FormattedMessage {...wikiMessages.getRules} />
          </h1>
          <FormattedHTMLMessage {...messages.sdkDanubePredictionText6} />
        </p>

        <p>
          <h2>
            <FormattedMessage {...messages.method} />
          </h2>
          <FormattedHTMLMessage {...messages.sdkDanubePredictionText2} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {getRules}
          </SyntaxHighlighter>
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {rulesSet}
          </SyntaxHighlighter>
          <br />
          <FormattedHTMLMessage {...messages.sdkDanubePredictionText7} />
        </p>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle)(GetRules);
