import { defineMessages } from 'react-intl';

const messages = defineMessages({
  description: {
    id: 'demo.jobs.learnMore.description',
    defaultMessage: `
      danube.ai helps you to find the right job
    `,
    description: 'label for learn more page',
  },
  text1: {
    id: 'demo.jobs.learnMore.text1',
    defaultMessage: `
      The complexity and variety of job listings makes them difficult to compare. In this demo, we show how danube.ai can help you to
      find the optimal developer job (openings are anonymized data from our partner <a href="/partners/devjobs" target="_blank">devjobs.at</a>).
      With the sliders and drop-downs you can tell the ai what job features are important for you as well as your developer skills.
    `,
    description: 'label for learn more page',
  },
  text2: {
    id: 'demo.jobs.learnMore.text2',
    defaultMessage: `
      By pressing 'danubify' you can activate the ai returning a sorted list of jobs with weighted matching values.
    `,
    description: 'label for learn more page',
  },
  text3: {
    id: 'demo.jobs.learnMore.text3',
    defaultMessage: `
      The more features a job has that you prefer the more influence does the opening have on the weighting process. Simply put, danube.ai
      values all features of good jobs higher (not only those you specified). In the next step, our ai algorithm compares the properties
      of each opening with all other jobs, calculating a weight profile for the job pool.
    `,
    description: 'label for learn more page',
  },
  text4: {
    id: 'demo.jobs.learnMore.text4',
    defaultMessage: `
      danube.ai weights the features of a job according to your setup and their availability in the pool. The ai algorithm calculates the
      weight profile for the given job pool. You can find the weights for your search directly above your job list. For example, if salary
      is 20% and job level 10%, then salary is considered twice as important for sorting the jobs.
    `,
    description: 'label for learn more page',
  },
  text5: {
    id: 'demo.jobs.learnMore.text5',
    defaultMessage: `
      The list below the percentages shows the profile of a particular job and their weighted matching values. In this example, all values
      above 66% are considered a 'good match'.
    `,
    description: 'label for learn more page',
  },
  text6: {
    id: 'demo.jobs.learnMore.text6',
    defaultMessage: `
      danube.ai adapts to your preferences and to your job pool. Hence, with danube.ai you get a job list perfectly matching your search field
      and personal skills.
    `,
    description: 'label for learn more page',
  },
});

export default messages;
