import { defineMessages } from 'react-intl';

const messages = defineMessages({
  technologyTitle: {
    id: 'technology.technologyTitle',
    defaultMessage: 'Distilling the essence of your data',
    description: 'Text for the title of the technology page',
  },
  technologyPageSubtitle: {
    id: 'technology.technologyPageSubtitle',
    defaultMessage:
      'Our AI uses a novel process preserving individuality without the storage of any personal data.',
    description: 'Text for the sub-title of the landing page',
  },
  graphicIcon_1: {
    id: 'technology.graphicIcon_1',
    defaultMessage: 'Real-time AI for every user',
    description: 'Text for graphicIcon_1',
  },
  graphicIcon_2: {
    id: 'technology.graphicIcon_2',
    defaultMessage: 'High quality starting from the first click',
    description: 'Text for graphicIcon_2',
  },
  graphicIcon_3: {
    id: 'technology.graphicIcon_3',
    defaultMessage: 'No login needed',
    description: 'Text for graphicIcon_3',
  },
  infographicSection_1: {
    id: 'technology.infographicSection_1',
    defaultMessage:
      'Big-Data-AI wants you to follow the majority with "Others bought", neglecting your preferences and mood.',
    description: 'Text for fact for  infographicSection_1',
  },
  infographicSection_2: {
    id: 'technology.infographicSection_2',
    defaultMessage:
      '{danube} creates an AI just for you, using the distilled data of the other users. This personal AI is destroyed directly after usage (hey, it fulfilled its purpose).',
    description: 'Text for fact for  infographicSection_2',
  },
});

export default messages;
