/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

class ClickTracker extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    componentKey: PropTypes.string.isRequired,
    logClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.logClick = this.logClick.bind(this);
  }

  logClick() {
    const { componentKey, logClick } = this.props;
    if (!componentKey) {
      // eslint-disable-next-line no-console
      console.warn(
        'Component is wrapped in click wrapper, but no component key was provided.',
      );
    } else {
      logClick({ componentKey });
    }
  }

  render() {
    const { children, componentKey, logClick, ...rest } = this.props;

    return (
      <div {...rest} onClick={this.logClick}>
        {children}
      </div>
    );
  }
}

const logClickMutation = gql`
  mutation logClickMutation($payload: ClickLogInput!) {
    createOrUpdateClickLog(payload: $payload) {
      id
    }
  }
`;

export default compose(
  graphql(logClickMutation, {
    props: ({ mutate }) => ({
      logClick: payload =>
        mutate({
          variables: { payload },
        }),
    }),
  }),
)(ClickTracker);
