import { defineMessages } from 'react-intl';

const messages = defineMessages({
  faqGlobalHeader: {
    id: 'faq.globalHeader',
    defaultMessage: 'FAQ',
    description: 'Label for header in FAQ',
  },

  faqHeader1: {
    id: 'faq.header1',
    defaultMessage: 'General',
    description: 'Label for header in FAQ',
  },

  faqQuestion1: {
    id: 'faq.question1',
    defaultMessage: `
      What is danube.ai?
    `,
    description: 'Label for question in FAQ',
  },
  faqAnswer1: {
    id: 'faq.answer1',
    defaultMessage: `
      danube.ai is a prediction engine superior to existing solutions for little data.
    `,
    description: 'Label for answer in FAQ',
  },

  faqQuestion2: {
    id: 'faq.question2',
    defaultMessage: `
      How much better is danube.ai?
    `,
    description: 'Label for question in FAQ',
  },
  faqAnswer2: {
    id: 'faq.answer2',
    defaultMessage: `
      danube.ai performs more than 20% better than classical AI algorithms in the range of little data.
    `,
    description: 'Label for answer in FAQ',
  },

  faqQuestion3: {
    id: 'faq.question3',
    defaultMessage: `
      What are little data, can I use danube.ai for my application?
    `,
    description: 'Label for question in FAQ',
  },
  faqAnswer3: {
    id: 'faq.answer3',
    defaultMessage: `
      The definition of 'little' depends on the field of your data. Generally, data are not 'Big' when you
      cannot create a meaningful statistics out of them. That means (a) you are in the range where individual
      data sets are important (b) there are approximately less or equal data sets as categories in your data.
      <a href="/contact">Get in touch</a> if you want to find out if your data problem can be solved by danube.ai.
    `,
    description: 'Label for answer in FAQ',
  },

  faqQuestion4: {
    id: 'faq.question4',
    defaultMessage: `
      What is the pricing model of danube.ai?
    `,
    description: 'Label for question in FAQ',
  },
  faqAnswer4: {
    id: 'faq.answer4',
    defaultMessage: `
      Pricing is based on the number of your API requests per month (<a href="/pricing">see details</a>). The applied pricing model depends
      on the used API and the usage model, Cloud or On Premises.
    `,
    description: 'Label for answer in FAQ',
  },

  faqQuestion5: {
    id: 'faq.question5',
    defaultMessage: `
      What is the danube.ai prediction API?
    `,
    description: 'Label for question in FAQ',
  },
  faqAnswer5: {
    id: 'faq.answer5',
    defaultMessage: `
      The <a href="/api/cloud/prediction">Prediction API</a> can be used to determine matching values for data sets. With this API you can sort products,
      users, or any other data and predict a highly individualized matching value for your application.
    `,
    description: 'Label for answer in FAQ',
  },

  faqQuestion6: {
    id: 'faq.question6',
    defaultMessage: `
      What is the danube.ai recommendation API?
    `,
    description: 'Label for question in FAQ',
  },
  faqAnswer6: {
    id: 'faq.answer6',
    defaultMessage: `
      The <a href="/api/cloud/recommendation">Recommendation API</a> can be used to get weighted recommendations based on collected usage statistics. The API
      builds a correlation matrix from all data of your previous requests and returns recommendations for every new request of an active user session.
      With this API you obtain highly individualized user recommendations.
    `,
    description: 'Label for answer in FAQ',
  },

  faqHeader2: {
    id: 'faq.header2',
    defaultMessage: 'Technology',
    description: 'Label for header in FAQ',
  },

  faqQuestion7: {
    id: 'faq.question7',
    defaultMessage: `
      How does it work?
    `,
    description: 'Label for question in FAQ',
  },
  faqAnswer7: {
    id: 'faq.answer7',
    defaultMessage: `
      danube.ai is a self-feedback simulation algorithm that determines the relevance of data features by a purely local
      approach. To that end, danube.ai calculates the individual 'strength' of each data set.
    `,
    description: 'Label for answer in FAQ',
  },

  faqQuestion8: {
    id: 'faq.question8',
    defaultMessage: `
      Is danube.ai based on machine learning?
    `,
    description: 'Label for question in FAQ',
  },
  faqAnswer8: {
    id: 'faq.answer8',
    defaultMessage: `
      No, danube.ai uses a completely new approach and has little to do with machine learning. Mathematically speaking,
      danube.ai is a multi-dimensional sorting algorithm.
    `,
    description: 'Label for answer in FAQ',
  },

  faqQuestion9: {
    id: 'faq.question9',
    defaultMessage: `
      Wait, then danube.ai is not artificial intelligence (AI)?
    `,
    description: 'Label for question in FAQ',
  },
  faqAnswer9: {
    id: 'faq.answer9',
    defaultMessage: `
      Depends on how you define AI. danube.ai is an algorithm to predict user behavior, recruiting processes and even
      sports events. So, it is an intelligent prediction technology not unlike classical, machine-learning-based AI.
    `,
    description: 'Label for answer in FAQ',
  },
});

export default messages;
