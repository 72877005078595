import React from 'react';
import PropTypes from 'prop-types';
import ReactTimePicker from 'rc-time-picker';
import moment from 'moment';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import baseStyles from 'rc-time-picker/assets/index.css';

import s from './TimePicker.scss';

class TimePicker extends React.Component {
  static calculateInitialValue(props) {
    const { interval } = props;
    const now = moment();
    // round down to interval
    const diffUp = interval - (now.minute() % interval);
    const diffDown = now.minute() % interval;
    let roundedTime;
    if (diffUp < diffDown) {
      roundedTime = now.add(diffUp, 'minutes');
    } else {
      roundedTime = now.subtract(diffDown, 'minutes');
    }

    return roundedTime;
  }

  static propTypes = {
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.shape({
        id: PropTypes.string,
      }),
      warning: PropTypes.bool,
    }).isRequired,
    input: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
    }).isRequired,
    formConfig: PropTypes.shape({
      formName: PropTypes.string.isRequired,
      fieldName: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    interval: PropTypes.number.isRequired,
    handleUpdate: PropTypes.func,
  };

  static defaultProps = {
    handleUpdate: null,
  };

  constructor(props) {
    super(props);

    // this.openTimePicker = this.openTimePicker.bind(this);
    // this.closeTimePicker = this.closeTimePicker.bind(this);
    // this.handleOnFocus = this.handleOnFocus.bind(this);
    // this.handleClose = this.handleClose.bind(this);

    const value =
      props.input && props.input.value
        ? moment(props.input.value, 'HH:mm:ss')
        : TimePicker.calculateInitialValue(props);

    this.state = {
      // open: false,
      value,
    };
  }

  componentDidMount() {
    this.handleValueChange(this.state.value);
  }

  // openTimePicker() {
  //   this.setState({ open: true });
  // }

  // closeTimePicker() {
  //   this.setState({ open: false });
  // }

  // handleOnFocus(event) {
  //   this.openTimePicker();
  //   this.props.input.onFocus(event);
  // }

  // handleClose(event) {
  //   this.closeTimePicker();
  //   this.props.input.onBlur(event);
  // }

  handleValueChange = value => {
    const {
      formConfig: { formName, fieldName },
      dispatch,
    } = this.props;
    dispatch(change(formName, fieldName, value));
    if (this.props.handleUpdate) {
      this.props.handleUpdate();
    }

    this.setState({ value });

    // TODO: the following part is responsible to close
    // the time picker when clicking on the "minutes" part,
    // however, it seems to crash from time to time
    //
    // if (value && this.state.value) {
    //   const diff = Math.abs(this.state.value.diff(value, 'minutes'));

    //   const { formConfig: { formName, fieldName }, dispatch } = this.props;
    //   dispatch(change(formName, fieldName, value));
    //   if (this.props.handleUpdate) {
    //     this.props.handleUpdate();
    //   }

    //   this.setState({
    //     open: diff >= 60, // close on minute click
    //     value,
    //   });
    // }
  };

  render() {
    const {
      interval,
      meta: { touched, error, warning },
    } = this.props;

    return (
      <div>
        <ReactTimePicker
          className={s.timePicker}
          defaultValue={this.state.value}
          onChange={this.handleValueChange}
          showSecond={false}
          minuteStep={interval}
          focusOnOpen
          // open={this.state.open}
          // onFocus={this.handleOnFocus}
          // onClose={this.handleClose}
        />
        {touched &&
          ((error && <p className="bg-danger">{error}</p>) ||
            (warning && <p className="bg-warning">{warning}</p>))}
      </div>
    );
  }
}

export default withStyles(baseStyles, s)(connect()(TimePicker));
