import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Link } from 'react-router-dom';

import { componentKeys, ClickTracker } from 'components/Analytics';

import s from './SearchMaskForm.scss';
import buttonStyle from '../../../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import layoutStyle from '../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import renderField from '../../../../components/ReduxForm/renderField';
import messages from './messages';
import globalMessages from '../../../../components/messages/globalMessages';
import { formatSliderLabel, LearnMoreLink } from '../../../util';

const FORM_NAME = 'recrutingPersonsSearchMask';

const validate = () => {
  const errors = {};
  return errors;
};

class SearchMaskForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    educationLevels: PropTypes.arrayOf(PropTypes.string).isRequired,
    additionalSkills: PropTypes.arrayOf(PropTypes.string).isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    const { handleSubmit, additionalSkills, errors, intl } = this.props;

    return (
      <div className={s.searchMaskContainer}>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <Row>
              <Col xs={12} style={{ margin: '10px 0' }}>
                <h1>
                  <FormattedMessage {...messages.filterAndSort} />
                </h1>
                <LearnMoreLink to="/demos/recruiting/about" />
              </Col>
              <Col md={6} xs={12}>
                <Row className={s.fieldContainer}>
                  <Col xs={12} style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.salaryImportance} />
                  </Col>
                  <Col xs={12}>
                    <Field
                      id="filter.salaryImportance"
                      name="filter.salaryImportance"
                      formName={FORM_NAME}
                      interval={{ min: 0, max: 1 }}
                      stepSize={0.25}
                      labelFormatter={formatSliderLabel}
                      component={renderField.renderSlider}
                    />
                  </Col>
                  <Col xs={12}>
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs={12} style={{ marginBottom: '10px' }}>
                    <FormattedMessage
                      {...messages.experienceOfficeImportance}
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      id="filter.experienceOfficeImportance"
                      name="filter.experienceOfficeImportance"
                      formName={FORM_NAME}
                      interval={{ min: 0, max: 1 }}
                      stepSize={0.25}
                      labelFormatter={formatSliderLabel}
                      component={renderField.renderSlider}
                    />
                  </Col>
                  <Col xs={12}>
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs={12} style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.msOfficeLevelImportance} />
                  </Col>
                  <Col xs={12}>
                    <Field
                      id="filter.msOfficeLevelImportance"
                      name="filter.msOfficeLevelImportance"
                      formName={FORM_NAME}
                      interval={{ min: 0, max: 1 }}
                      stepSize={0.25}
                      labelFormatter={formatSliderLabel}
                      component={renderField.renderSlider}
                    />
                  </Col>
                  <Col xs={12}>
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs={12} style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.educationLevel} />
                  </Col>
                  <Col xs={12}>
                    <Field
                      id="filter.educationLevel"
                      name="filter.educationLevel"
                      component={renderField.renderSelectAlt}
                      isMulti
                      options={[
                        {
                          value: 'Level 1',
                          label: intl.formatMessage(messages.educationLevel1),
                        },
                        {
                          value: 'Level 2',
                          label: intl.formatMessage(messages.educationLevel2),
                        },
                        {
                          value: 'Level 3',
                          label: intl.formatMessage(messages.educationLevel3),
                        },
                        {
                          value: 'Level 4',
                          label: intl.formatMessage(messages.educationLevel4),
                        },
                      ]}
                    />
                  </Col>
                  <Col xs={12}>
                    <div className={s.spacerLine} />
                  </Col>
                </Row>
              </Col>
              <Col md={6} xs={12}>
                <Row className={s.fieldContainer}>
                  <Col xs={12} style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.germanLevelImportance} />
                  </Col>
                  <Col xs={12}>
                    <Field
                      id="filter.germanLevelImportance"
                      name="filter.germanLevelImportance"
                      formName={FORM_NAME}
                      interval={{ min: 0, max: 1 }}
                      stepSize={0.25}
                      labelFormatter={formatSliderLabel}
                      component={renderField.renderSlider}
                    />
                  </Col>
                  <Col xs={12}>
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs={12} style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.englishLevelImportance} />
                  </Col>
                  <Col xs={12}>
                    <Field
                      id="filter.englishLevelImportance"
                      name="filter.englishLevelImportance"
                      formName={FORM_NAME}
                      interval={{ min: 0, max: 1 }}
                      stepSize={0.25}
                      labelFormatter={formatSliderLabel}
                      component={renderField.renderSlider}
                    />
                  </Col>
                  <Col xs={12}>
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs={12} style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.additionalSkills} />
                  </Col>
                  <Col xs={12}>
                    <Field
                      id="filter.additionalSkills"
                      name="filter.additionalSkills"
                      component={renderField.renderSelectAlt}
                      isMulti
                      options={additionalSkills.map(skill => ({
                        value: skill,
                        label: skill,
                      }))}
                    />
                  </Col>
                  <Col xs={12}>
                    <div className={s.spacerLine} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className={s.buttonbar}>
              <button
                type="submit"
                className="btn-secondary btn-round btn-large"
              >
                danubify
              </button>
              <ClickTracker
                componentKey={componentKeys.RECRUITING_DEMO_CONTACT_BUTTON}
                style={{ display: 'inline-block', marginLeft: '10px' }}
              >
                <Link to="/contact">
                  <button
                    type="button"
                    className="btn-secondary btn-round btn-large"
                  >
                    <FormattedMessage {...globalMessages.getInTouch} />
                  </button>
                </Link>
              </ClickTracker>
              <LearnMoreLink to="/demos/recruiting/about" />
              {errors.length === 0 ? null : (
                <ul style={{ padding: '0', listStyleType: 'none' }}>
                  {errors.map(err => (
                    <li key={err} className="bg-danger">
                      {err}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(injectIntl(withStyles(buttonStyle, layoutStyle, s)(SearchMaskForm)));
