import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './SearchMaskForm.scss';
import buttonStyle from '../../../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import renderField from '../../../../components/ReduxForm/renderField';
import * as normalizers from '../../../../components/ReduxForm/normalizers';
import messages from './messages';
import { formatSliderLabel } from '../../../util';

const FORM_NAME = 'phonesSearchMask';

const validate = () => {
  const errors = {};
  return errors;
};

class SearchMaskForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    const { errors, intl, handleSubmit } = this.props;

    return (
      <div className={s.searchMaskContainer}>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <Row>
              <Col xs="12" style={{ margin: '10px 0' }}>
                <h1>
                  <FormattedMessage {...messages.filterAndSort} />
                </h1>
              </Col>
              <Col md="6" xs="12">
                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.maxPriceMonthly} />
                  </Col>
                  <Col xs="12">
                    <Row>
                      <Col xs="6">
                        <Field
                          id="filter.PRICE_TOTAL_from"
                          name="filter.PRICE_TOTAL_from"
                          placeholder={intl.formatMessage(messages.fromPrice)}
                          type="number"
                          component={renderField.renderInput}
                        />
                      </Col>
                      <Col xs="6">
                        <Field
                          id="filter.PRICE_TOTAL_to"
                          name="filter.PRICE_TOTAL_to"
                          placeholder={intl.formatMessage(messages.toPrice)}
                          type="number"
                          component={renderField.renderInput}
                        />
                      </Col>
                      <Col xs="6">
                        <div className={s.spacerLine} />
                      </Col>
                      <Col xs="6">
                        <div className={s.spacerLine} />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.cameraImportance} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.CAMERA_importance"
                      name="filter.CAMERA_importance"
                      formName={FORM_NAME}
                      interval={{ min: 0, max: 1 }}
                      stepSize={0.25}
                      labelFormatter={formatSliderLabel}
                      component={renderField.renderSlider}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.batteryImportance} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.BATTERY_importance"
                      name="filter.BATTERY_importance"
                      formName={FORM_NAME}
                      interval={{ min: 0, max: 1 }}
                      stepSize={0.25}
                      labelFormatter={formatSliderLabel}
                      component={renderField.renderSlider}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>
              </Col>
              <Col md="6" xs="12">
                <Row className={s.fieldContainer}>
                  <Col xs="12" style={{ marginBottom: '10px' }}>
                    <FormattedMessage {...messages.displayZollPreference} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.DISPLAY_ZOLL_preference"
                      name="filter.DISPLAY_ZOLL_preference"
                      component={renderField.renderSelectAlt}
                      options={[
                        {
                          value: 'preferBig',
                          label: intl.formatMessage(messages.preferBig),
                        },
                        {
                          value: 'preferMedium',
                          label: intl.formatMessage(messages.preferMedium),
                        },
                        {
                          value: 'preferSmall',
                          label: intl.formatMessage(messages.preferSmall),
                        },
                      ]}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col
                    xs="12"
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <FormattedMessage {...messages.companies} />
                  </Col>
                  <Col xs="12">
                    <Field
                      id="filter.companies"
                      name="filter.companies"
                      component={renderField.renderSelectAlt}
                      isMulti
                      options={[
                        {
                          value: 'Samsung',
                          label: 'Samsung',
                        },
                        {
                          value: 'Apple',
                          label: 'Apple',
                        },
                        {
                          value: 'Huawei',
                          label: 'Huawei',
                        },
                        {
                          value: 'Xiaomi',
                          label: 'Xiaomi',
                        },
                        {
                          value: 'Sony',
                          label: 'Sony',
                        },
                        {
                          value: 'Nokia',
                          label: 'Nokia',
                        },
                        {
                          value: 'LG',
                          label: 'LG',
                        },
                        {
                          value: 'ZTE',
                          label: 'ZTE',
                        },
                        {
                          value: 'Emporia',
                          label: 'Emporia',
                        },
                        {
                          value: 'Motorola',
                          label: 'Motorola',
                        },
                      ]}
                    />
                  </Col>
                  <Col xs="12">
                    <div className={s.spacerLine} />
                  </Col>
                </Row>

                <Row className={s.fieldContainer}>
                  <Col
                    xs="12"
                    style={{ marginTop: '10px', marginBottom: '20px' }}
                  >
                    <FormattedMessage {...messages.additionalOptions} />
                  </Col>
                  <Col xs="12">
                    <Row>
                      <Col xs="4" className={s.checkboxWrapper}>
                        <Field
                          id="filter.include_external_tests"
                          name="filter.include_external_tests"
                          label={
                            <FormattedMessage
                              {...messages.includeExternalTests}
                            />
                          }
                          type="checkbox"
                          component={renderField.renderCheckbox}
                          normalize={normalizers.booleanNormalizer}
                          style={{ marginTop: '10px' }}
                        />
                      </Col>
                      <Col xs="4" className={s.checkboxWrapper}>
                        <Field
                          id="filter.include_popularity"
                          name="filter.include_popularity"
                          label={
                            <FormattedMessage
                              {...messages.includeO2Popularity}
                            />
                          }
                          type="checkbox"
                          component={renderField.renderCheckbox}
                          normalize={normalizers.booleanNormalizer}
                          style={{ marginTop: '-22px' }}
                        />
                      </Col>
                      <Col xs="4" className={s.checkboxWrapper}>
                        <Field
                          id="filter.include_camera_tests"
                          name="filter.include_camera_tests"
                          label={
                            <FormattedMessage
                              {...messages.includeCameraTests}
                            />
                          }
                          type="checkbox"
                          component={renderField.renderCheckbox}
                          normalize={normalizers.booleanNormalizer}
                          style={{ marginTop: '10px' }}
                        />
                      </Col>
                      <Col xs="4">
                        <div className={s.spacerLine} />
                      </Col>
                      <Col xs="4">
                        <div className={s.spacerLine} />
                      </Col>
                      <Col xs="4">
                        <div className={s.spacerLine} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className={s.buttonbar}>
              <button
                type="submit"
                className="btn-secondary btn-round btn-large"
              >
                <span>danubify</span>
              </button>
              {errors.length === 0 ? null : (
                <ul style={{ padding: '0', listStyleType: 'none' }}>
                  {errors.map(err => (
                    <li key={err} className="bg-danger">
                      {err}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(injectIntl(withStyles(buttonStyle, s)(SearchMaskForm)));
