import React from 'react';

import PageProperties from 'components/PageProperties';
import FAQ from 'components/FAQ';
import routeMessages from 'routes/messages';

class FaqWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.faqTitle,
            description: routeMessages.faqDescription,
            keywords: routeMessages.faqKeywords,
          }}
        />
        <FAQ />
      </div>
    );
  }
}

export default FaqWrapper;
