import { defineMessages } from 'react-intl';

const messages = defineMessages({
  trustedBy: {
    id: 'trustedBy',
    defaultMessage: 'TRUSTED BY',
    description: 'Label for Trusted By component.',
  },
});

export default messages;
