import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import baseStyles from '../../styles/app.scss';

class FullWidthLayoutWithoutHeaderAndFooter extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div key="app-content">
        <div className="container-fluid">{this.props.children}</div>
      </div>
    );
  }
}

export default withStyles(baseStyles)(FullWidthLayoutWithoutHeaderAndFooter);
