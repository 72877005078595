import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-bootstrap';

import s from './LandingPage.scss';
import SearchMaskForm from '../SearchMask/SearchMaskForm';
import ResultsList from '../ResultsList';
import messages from '../SearchMask/messages';

class LandingPage extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      skipPageLoadQuery: true,
    };

    this.onSearch = this.onSearch.bind(this);
    this.scrollToResults = this.scrollToResults.bind(this);

    this.resultListRef = React.createRef();
  }

  onSearch(formValues) {
    this.setState({ skipPageLoadQuery: false });
    const filter = { ...formValues.filter };

    // reformat some parameters
    if ('PRICE_TOTAL_to' in filter) {
      filter.PRICE_TOTAL_to = parseFloat(filter.PRICE_TOTAL_to);
    }
    if ('PRICE_TOTAL_from' in filter) {
      filter.PRICE_TOTAL_from = parseFloat(filter.PRICE_TOTAL_from);
    }
    filter.DISPLAY_ZOLL_preference = filter.DISPLAY_ZOLL_preference.value;
    filter.companies = filter.companies.map(company => company.value);

    this.setState({ filter });
  }

  scrollToResults() {
    if (this.resultListRef.current) {
      window.scroll({
        top: this.resultListRef.current.offsetTop + 74,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  render() {
    const { intl } = this.props;

    return (
      <div className={s.landingPageContainer}>
        <Row>
          <Col mdOffset={2} md={8}>
            <SearchMaskForm
              onSubmit={this.onSearch}
              initialValues={{
                filter: {
                  CAMERA_importance: 0.5,
                  BATTERY_importance: 0.5,
                  companies: [
                    {
                      value: 'Samsung',
                      label: 'Samsung',
                    },
                    {
                      value: 'Apple',
                      label: 'Apple',
                    },
                    {
                      value: 'Huawei',
                      label: 'Huawei',
                    },
                    {
                      value: 'Xiaomi',
                      label: 'Xiaomi',
                    },
                    {
                      value: 'Sony',
                      label: 'Sony',
                    },
                    {
                      value: 'Nokia',
                      label: 'Nokia',
                    },
                    {
                      value: 'LG',
                      label: 'LG',
                    },
                    {
                      value: 'ZTE',
                      label: 'ZTE',
                    },
                    {
                      value: 'Emporia',
                      label: 'Emporia',
                    },
                    {
                      value: 'Motorola',
                      label: 'Motorola',
                    },
                  ],
                  DISPLAY_ZOLL_preference: {
                    value: 'preferBig',
                    label: intl.formatMessage(messages.preferBig),
                  },
                  include_external_tests: true,
                  include_popularity: true,
                },
              }}
            />
            <div ref={this.resultListRef}>
              <ResultsList
                filter={this.state.filter}
                skipPageLoadQuery={this.state.skipPageLoadQuery}
                onLoadingFinished={this.scrollToResults}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(LandingPage));
