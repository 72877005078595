import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import TopNavigation from 'components/TopNavigation';
import baseStyles from '../../styles/app.scss';
import RecommendationComponent from '../Recommendation';
import Footer from '../Footer';

class UserLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div key="app-content">
        <TopNavigation />
        <div className="container-fluid" style={{ padding: '0' }}>
          <div className="content" style={{ paddingTop: '100px' }}>
            {this.props.children}
          </div>
        </div>
        <RecommendationComponent />
        <Footer />
      </div>
    );
  }
}

export default withStyles(baseStyles)(UserLayout);
