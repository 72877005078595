import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Panel } from 'react-bootstrap';
import FaChevronDown from 'react-icons/lib/fa/chevron-down';
import FaChevronRight from 'react-icons/lib/fa/chevron-right';
import { Link } from 'react-router-dom';

import s from './SideNavigation.scss';

const initalNavExpand = (navItem, initalNavPath, initalExpandedNavs) => {
  if (navItem.subNavs) {
    let includesInitalNav = false;
    navItem.subNavs.forEach(subNavItem => {
      const subNavIncludesInitalNav = initalNavExpand(
        subNavItem,
        initalNavPath,
        initalExpandedNavs,
      );
      if (subNavIncludesInitalNav) {
        // eslint-disable-next-line no-param-reassign
        initalExpandedNavs[navItem.id] = true;
        includesInitalNav = true;
      }
    });
    return includesInitalNav;
  }
  return navItem.path === initalNavPath;
};

class SideNavigation extends React.Component {
  static propTypes = {
    navItems: PropTypes.arrayOf(PropTypes.object),
    onNavItemSelect: PropTypes.func,
  };

  static defaultProps = {
    navItems: [],
    onNavItemSelect: () => {},
  };

  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      expandedNavigations: {},
      activeNavigation: null,
    };
  }

  componentDidMount() {
    const { navItems } = this.props;

    const initialNavPath = window.location.pathname;
    const initalExpandedNavs = {};
    navItems.forEach(navItem => {
      initalNavExpand(navItem, initialNavPath, initalExpandedNavs);
    });

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      expandedNavigations: initalExpandedNavs,
      activeNavigation: window.location.pathname,
    });
  }

  handleSelect(navItem) {
    const { onNavItemSelect } = this.props;

    if (navItem.subNavs) {
      const newExpandedNavigations = this.state.expandedNavigations;
      newExpandedNavigations[navItem.id] = !newExpandedNavigations[navItem.id]; // eslint-disable-line prettier/prettier
      this.setState({ expandedNavigations: newExpandedNavigations });
    } else {
      this.setState({ activeNavigation: navItem.path }, () => {
        onNavItemSelect(navItem);
      });
    }
  }

  renderNavItem(navItem, level) {
    let levelClass = '';
    if (level <= 1) levelClass = s.level1;
    if (level === 2) levelClass = s.level2;
    if (level >= 3) levelClass = s.level3;

    if (navItem.subNavs) {
      const isExpanded = this.state.expandedNavigations[navItem.id] === true;

      return (
        <Panel
          key={navItem.name}
          className={s.collapsiblePanel}
          expanded={isExpanded}
          onToggle={() => {
            this.handleSelect(navItem);
          }}
        >
          <Panel.Heading className={s.panelHeading}>
            <button
              className={`${s.navButton} ${s.navItem} ${levelClass}`}
              onClick={() => {
                this.handleSelect(navItem);
              }}
            >
              {navItem.name}
              <span className={`${s.arrow} ${levelClass}`}>
                {isExpanded ? <FaChevronDown /> : <FaChevronRight />}
              </span>
            </button>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body className={s.panelBody}>
              {navItem.subNavs.map(subNavItem =>
                this.renderNavItem(subNavItem, level + 1),
              )}
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      );
    }

    const isSelected = this.state.activeNavigation === navItem.path;
    const navButtonClass = isSelected
      ? `${s.navButton} ${s.navItem} ${s.selected} ${levelClass}`
      : `${s.navButton} ${s.navItem} ${levelClass}`;

    return (
      <button
        key={navItem.name}
        className={navButtonClass}
        onClick={() => {
          this.handleSelect(navItem);
        }}
      >
        <Link className={s.leaf} to={navItem.path}>
          {navItem.name}
        </Link>
      </button>
    );
  }

  render() {
    const { navItems } = this.props;
    return (
      <div className={s.sideNavigationContainer}>
        {navItems.map(navItem => this.renderNavItem(navItem, 1))}
      </div>
    );
  }
}

export default withStyles(s)(SideNavigation);
