import { defineMessages } from 'react-intl';

const messages = defineMessages({
  headline: {
    id: 'demo.immo.search.headline',
    defaultMessage: 'Find your perfect apartment',
    description: 'label for field in search mask',
  },

  address: {
    id: 'demo.immo.search.adress',
    defaultMessage: 'Where do you want to live (district/ZIP)?',
    description: 'label for field in search mask',
  },
  type: {
    id: 'demo.immo.search.type',
    defaultMessage: 'Do you want to buy or rent a place?',
    description: 'label for field in search mask',
  },
  type_rent: {
    id: 'demo.immo.search.type.rent',
    defaultMessage: 'Rent',
    description: 'label for field in search mask',
  },
  type_temporary_rent: {
    id: 'demo.immo.search.type.emporaryRent',
    defaultMessage: 'Temporary rent',
    description: 'label for field in search mask',
  },
  type_buy: {
    id: 'demo.immo.search.type.buy',
    defaultMessage: 'Buy',
    description: 'label for field in search mask',
  },
  type_lease: {
    id: 'demo.immo.search.type.lease',
    defaultMessage: 'Lease',
    description: 'label for field in search mask',
  },
  price: {
    id: 'demo.immo.search.price',
    defaultMessage: 'How much are you willing to pay?',
    description: 'label for field in search mask',
  },
  fromPrice: {
    id: 'demo.immo.search.fromPrice',
    defaultMessage: 'from (€)',
    description: 'label for field in search mask',
  },
  toPrice: {
    id: 'demo.immo.search.toPrice',
    defaultMessage: 'to (€)',
    description: 'label for field in search mask',
  },
  areaRange: {
    id: 'demo.immo.search.areaRange',
    defaultMessage: 'How large should your apartment be?',
    description: 'label for field in search mask',
  },
  area: {
    id: 'demo.immo.search.area',
    defaultMessage: 'Area',
    description: 'label for field in search mask',
  },
  fromArea: {
    id: 'demo.immo.search.fromArea',
    defaultMessage: 'from (m²)',
    description: 'label for field in search mask',
  },
  toArea: {
    id: 'demo.immo.search.toArea',
    defaultMessage: 'to (m²)',
    description: 'label for field in search mask',
  },
  numberOfRooms: {
    id: 'demo.immo.search.numberOfRooms',
    defaultMessage: 'What is the minimum number of rooms you want?',
    description: 'label for field in search mask',
  },
  rooms: {
    id: 'demo.immo.search.rooms',
    defaultMessage: 'Rooms',
    description: 'label for field in search mask',
  },
  balcony: {
    id: 'demo.immo.search.balcony',
    defaultMessage: 'Balcony',
    description: 'label for field in search mask',
  },
  numberOfBalconies: {
    id: 'demo.immo.search.numberOfBalconies',
    defaultMessage: 'Balconies',
    description: 'label for field in search mask',
  },
  terrace: {
    id: 'demo.immo.search.terrace',
    defaultMessage: 'Terrace',
    description: 'label for field in search mask',
  },
  numberOfTerrace: {
    id: 'demo.immo.search.numberOfTerrace',
    defaultMessage: 'Terraces',
    description: 'label for field in search mask',
  },
  furtherOptions: {
    id: 'demo.immo.search.furtherOptions',
    defaultMessage: 'Further options:',
    description: 'label for field in search mask',
  },
  balconyAndTerrace: {
    id: 'demo.immo.search.balconyAndTerrace',
    defaultMessage: 'Balcony/Terrace',
    description: 'label for field in search mask',
  },

  sortStrategy: {
    id: 'demo.immo.search.sortStrategy',
    defaultMessage: 'Which sorting strategy should be used?',
    description: 'label for field in search mask',
  },
  sortByAddress: {
    id: 'demo.immo.search.sortByAddress',
    defaultMessage: 'By district (ZIP)',
    description: 'label for field in search mask',
  },
  sortByPrice: {
    id: 'demo.immo.search.sortByPrice',
    defaultMessage: 'By price',
    description: 'label for field in search mask',
  },
  sortByArea: {
    id: 'demo.immo.search.sortByArea',
    defaultMessage: 'By area',
    description: 'label for field in search mask',
  },
  sortWithDanubeAI: {
    id: 'demo.immo.search.sortWithDanubeAI',
    defaultMessage: 'ai powered',
    description: 'label for field in search mask',
  },

  sortOrdering: {
    id: 'demo.immo.search.sortOrdering',
    defaultMessage: 'How should the results be ordered?',
    description: 'label for field in search mask',
  },
  ascending: {
    id: 'demo.immo.search.ascending',
    defaultMessage: 'Ascending',
    description: 'label for field in search mask',
  },
  descending: {
    id: 'demo.immo.search.descending',
    defaultMessage: 'Descending',
    description: 'label for field in search mask',
  },

  any: {
    id: 'demo.immo.search.any',
    defaultMessage: 'Any',
    description: 'label for field in search mask',
  },
  none: {
    id: 'demo.immo.search.none',
    defaultMessage: 'None',
    description: 'label for field in search mask',
  },
  choose: {
    id: 'demo.immo.search.choose',
    defaultMessage: 'Please choose...',
    description: 'label for select placeholder in search mask',
  },
  search: {
    id: 'demo.immo.search.search',
    defaultMessage: 'Search & sort',
    description: 'label for search button in search mask',
  },
  showSuggestions: {
    id: 'demo.immo.search.showSuggestions',
    defaultMessage: 'Show filter-suggestions',
    description: 'label for field in search mask',
  },
});

export default messages;
