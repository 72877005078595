import XLSX from 'xlsx';

const isImage = filename => /\.(gif|jpe?g|png)$/i.test(filename);

/* eslint-disable no-restricted-properties */
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const measures = ['Bytes', 'KB', 'MB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const convertedBytes = parseFloat(bytes / Math.pow(k, i)).toFixed(decimals);

  return `${convertedBytes}${measures[i]}`;
};

const blobToObject = file =>
  new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      // eslint-disable-next-line no-console
      console.warn(`${file.name} is not a blob.`);
      resolve(null);
    }

    const reader = new FileReader();
    reader.onload = () =>
      resolve({
        name: file.name,
        filename: file.name,
        size: file.size,
        mimetype: file.type,
        data: reader.result,
      });
    reader.onerror = error => reject(error);
    reader.readAsDataURL(file);
  });

const parseWorksheets = async file => {
  const worksheets = {};
  const wb = XLSX.read(file.buffer, { type: 'buffer', cellDates: true });
  // const wbs = args.map(e => e.name);
  const wbs = ['Sheet1', 'Sheet2'];
  wbs.forEach(name => {
    if (wb.SheetNames.indexOf(name) !== -1) {
      const ws = wb.Sheets[name];
      worksheets[name] = XLSX.utils
        .sheet_to_json(ws, { raw: true, defval: null })
        .filter(row => !Object.values(row).every(item => item === null));
    } else {
      worksheets[name] = null;
    }
  });
  return worksheets;
};

// filter everything not contained in extended ascii (or else btoa will throw an error)
// eslint-disable-next-line no-control-regex
const btoaSave = data => btoa(data.replace(/[^\x00-\xFF]/g, ''));

export { isImage, formatBytes, blobToObject, parseWorksheets, btoaSave };
