import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import baseStyles from '../../styles/app.scss';
import TopNavigation from '../TopNavigation';
import RecommendationComponent from '../Recommendation';
import Footer from '../Footer';

class FullWidthLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    darkBackground: PropTypes.bool,
    addRecommendations: PropTypes.bool,
    darkRecommendations: PropTypes.bool,
  };

  static defaultProps = {
    darkBackground: false,
    addRecommendations: true,
    darkRecommendations: false,
  };

  render() {
    return (
      <div key="app-content">
        <TopNavigation />
        <div
          className={`container-fluid ${
            this.props.darkBackground ? 'dark' : ''
          }`}
        >
          {this.props.children}
        </div>
        {this.props.addRecommendations && (
          <RecommendationComponent
            useDarkMode={this.props.darkRecommendations}
          />
        )}
        <Footer />
      </div>
    );
  }
}

export default withStyles(baseStyles)(FullWidthLayout);
