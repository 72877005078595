import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import { generateRulesForColumnType, isNumeric } from './ColumnHelper';
import s from './ColumnImporter.scss'; // eslint-disable-line css-modules/no-unused-class
import RowsContainer from './RowsContainer';
import ColumnSettingsContainer from './ColumnSettingsContainer';
import { viewModes } from '../ResultsTable';

import { COLUMN_TYPE_BOOLEAN } from './../../constants';

class ColumnImporter extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    field: PropTypes.string.isRequired,
    fieldData: PropTypes.shape({
      name: PropTypes.string.isRequired,
      columnType: PropTypes.string.isRequired,
      rows: PropTypes.arrayOf(PropTypes.string).isRequired,
      isUniqueIdColumn: PropTypes.bool.isRequired,
      matched: PropTypes.bool.isRequired,
      skipped: PropTypes.bool.isRequired,
      fixed: PropTypes.bool.isRequired,
      columnScore: PropTypes.number.isRequired,
      oneEqualsTo: PropTypes.string,
      hundredPercentEqualsTo: PropTypes.number,
      treatEmptyAsZero: PropTypes.bool.isRequired,
    }).isRequired,
    rowScores: PropTypes.arrayOf(PropTypes.number).isRequired,
    rowScoreChanges: PropTypes.arrayOf(PropTypes.number).isRequired,
    onApply: PropTypes.func.isRequired,
    onApplyAndSort: PropTypes.func.isRequired,
    changeFieldValue: PropTypes.func.isRequired,
    usecaseView: PropTypes.bool.isRequired,
    viewMode: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      settingsExpanded: false,
    };

    this.applySettings = this.applySettings.bind(this);
    this.applySettingsAndSort = this.applySettingsAndSort.bind(this);
    this.onToggleSettings = this.onToggleSettings.bind(this);
    this.onColumnTypeChanged = this.onColumnTypeChanged.bind(this);
  }

  onToggleSettings() {
    this.setState({
      settingsExpanded: !this.state.settingsExpanded,
    });
  }

  onColumnTypeChanged(field, newValue) {
    const {
      fieldData: { rows },
    } = this.props;
    const rule = generateRulesForColumnType(newValue, rows)[0];
    let newRuleValue = rule.ruleValue;
    if (
      rule.ruleValue === undefined ||
      rule.ruleValue === null ||
      rule.ruleValue === ''
    ) {
      newRuleValue = rows.find(
        val => val !== null && val !== undefined && val !== '',
      );
    }
    if (newValue === COLUMN_TYPE_BOOLEAN) {
      this.props.changeFieldValue(
        'columns',
        `${field}.oneEqualsTo`,
        newRuleValue,
      );
    } else {
      this.props.changeFieldValue(
        'columns',
        `${field}.hundredPercentEqualsTo`,
        newRuleValue,
      );
    }
  }

  applySettings() {
    const { fieldData, index } = this.props;
    const columnScore = parseFloat(fieldData.columnScoreInput);
    if (!isNumeric(columnScore)) {
      return;
    }

    this.props.changeFieldValue(
      'columns',
      `${this.props.field}.columnScore`,
      parseFloat(columnScore),
    );

    this.props.onApply(index, fieldData);

    // close settings panel
    this.setState({ settingsExpanded: false });
  }

  applySettingsAndSort() {
    const { fieldData, index } = this.props;
    this.props.onApplyAndSort(index, fieldData);

    this.setState({ settingsExpanded: false });
  }

  render() {
    const {
      field,
      index,
      fieldData: {
        name,
        columnType,
        rows,
        isUniqueIdColumn,
        matched,
        skipped,
        fixed,
        columnScore,
      },
      rowScores,
      rowScoreChanges,
      usecaseView,
      viewMode,
    } = this.props;

    let containerClass = `${s.container}`;
    if (isUniqueIdColumn) {
      containerClass += ` ${s.unique} ${s.withSpacing}`;
    } else if (skipped) {
      containerClass += ` ${s.skipped}`;
    } else if (fixed) {
      containerClass += ` ${s.fixed}`;
    } else if (matched) {
      containerClass += ` ${s.matched}`;
    } else {
      containerClass += ` ${s.unmatched}`;
    }

    if (viewMode === viewModes.COMPACT) {
      containerClass += ` ${s.compact}`;
    }

    if (viewMode === viewModes.USECASE_WIDGET) {
      containerClass += ` ${s.compact} ${s.widget}`;
    }

    const columnClass = isUniqueIdColumn
      ? `${s.columnImporterContainer} ${s.withSpacing}`
      : `${s.columnImporterContainer}`;

    let settingsArrowIcon;
    if (this.state.settingsExpanded) {
      settingsArrowIcon = '/doubleArrow-left.png';
    } else {
      settingsArrowIcon = '/doubleArrow-right.png';
    }

    return (
      <div className={containerClass}>
        <div className={columnClass}>
          <div className={s.innerContainer}>
            <div className={s.headline}>
              <div className={s.columnNameContainer}>
                <h1 title={name}>{name}</h1>
              </div>
              {!isUniqueIdColumn &&
                !usecaseView && (
                  <button
                    className={s.contextButton}
                    type="button"
                    onClick={this.onToggleSettings}
                  >
                    <img src={settingsArrowIcon} alt="" />
                  </button>
                )}
            </div>
            <div className={s.scoreContainer}>
              {!isUniqueIdColumn && <h4>{columnScore.toFixed(2)}</h4>}
            </div>
            <RowsContainer
              index={index}
              rows={rows}
              isUniqueIdColumn={isUniqueIdColumn}
              rowScores={rowScores}
              rowScoreChanges={rowScoreChanges}
            />
          </div>
        </div>
        {!isUniqueIdColumn &&
          !usecaseView && (
            <ColumnSettingsContainer
              field={field}
              columnType={columnType}
              rows={rows}
              skipped={skipped}
              settingsExpanded={this.state.settingsExpanded}
              fixed={fixed}
              onApply={this.applySettings}
              onApplyAndSort={this.applySettingsAndSort}
              onColumnTypeChanged={this.onColumnTypeChanged}
            />
          )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (form, field, value) =>
    dispatch(change(form, field, value)),
});

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(s)(ColumnImporter));
