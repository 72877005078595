import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { ScrollSyncPane } from 'react-scroll-sync';

import s from './ColumnImporter.scss'; // eslint-disable-line css-modules/no-unused-class

class RowsContainer extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    rows: PropTypes.arrayOf(PropTypes.string).isRequired,
    isUniqueIdColumn: PropTypes.bool.isRequired,
    rowScores: PropTypes.arrayOf(PropTypes.number).isRequired,
    rowScoreChanges: PropTypes.arrayOf(
      PropTypes.shape({
        posChange: PropTypes.number.isRequired,
        valueDiff: PropTypes.number.isRequired,
      }),
    ).isRequired,
    hoveredRow: PropTypes.number.isRequired,
    setHoveredRow: PropTypes.func.isRequired,
  };

  render() {
    const {
      index,
      rows,
      isUniqueIdColumn,
      rowScores,
      rowScoreChanges,
      hoveredRow,
      setHoveredRow,
    } = this.props;

    let rowNameContainerClass = `${s.rowNameContainer}`;
    if (isUniqueIdColumn) rowNameContainerClass += ` ${s.leftAligned}`;
    if (!isUniqueIdColumn) rowNameContainerClass += ` ${s.rowValueContainer}`;

    return (
      <ScrollSyncPane group="vertical">
        <div
          className={s.rowsContainer}
          style={{ overflow: index === 0 ? 'auto' : 'hidden' }}
        >
          <div className={s.firefoxMarginFixContainer} />
          {rows.map((row, rowIndex) => {
            const rowScoreChange = rowScoreChanges[rowIndex]
              ? rowScoreChanges[rowIndex].posChange
              : 0;
            const rowValueDiff =
              rowScoreChanges[rowIndex] && rowScoreChanges[rowIndex].valueDiff
                ? rowScoreChanges[rowIndex].valueDiff.toFixed(2)
                : 0;

            let rowScoreChangeString;
            let rankChangeClass = ``;

            if (rowScoreChange > 0) {
              rowScoreChangeString = `+${rowScoreChange}`;
              rankChangeClass = s.rankChangePositive;
            } else if (rowScoreChange < 0) {
              rowScoreChangeString = `-${Math.abs(rowScoreChange)}`;
              rankChangeClass = s.rankChangeNegative;
            } else {
              rowScoreChangeString = '';
            }

            let rowValueDiffString;
            let diffChangeClass = ``;

            if (rowValueDiff > 0) {
              rowValueDiffString = `+${rowValueDiff}`;
              diffChangeClass = s.rankChangePositive;
            } else if (rowValueDiff < 0) {
              rowValueDiffString = `-${Math.abs(rowValueDiff)}`;
              diffChangeClass = s.rankChangeNegative;
            } else {
              rowValueDiffString = '';
            }

            const rowContainerClass =
              rowIndex === hoveredRow
                ? `${s.rowContainer} ${s.hovered}`
                : s.rowContainer;

            let rowScoreString = '';

            if (
              rowScores[rowIndex] !== undefined &&
              rowScores[rowIndex] !== null
            ) {
              rowScoreString = rowScores[rowIndex].toFixed(2);
            }
            return (
              <div
                className={rowContainerClass}
                key={`row-${rowIndex}`} // eslint-disable-line
                onMouseEnter={() => {
                  setHoveredRow(rowIndex);
                }}
                onMouseLeave={() => {
                  setHoveredRow(-1);
                }}
              >
                <div className={s.rowIndexContainer}>
                  {isUniqueIdColumn && <span>{rowIndex + 1}</span>}
                </div>
                <div className={s.rowScoreChangeContainer}>
                  {isUniqueIdColumn && (
                    <div>
                      {rowScoreChange > 0 && (
                        <img
                          className={s.rankArrow}
                          src="/arrow_up.svg"
                          alt=""
                        />
                      )}
                      {rowScoreChange < 0 && (
                        <img
                          className={s.rankArrow}
                          src="/arrow_down.svg"
                          alt=""
                        />
                      )}

                      <span className={rankChangeClass}>
                        {rowScoreChangeString}
                      </span>
                    </div>
                  )}
                </div>
                <div className={rowNameContainerClass}>
                  <span>{row}</span>
                </div>
                {isUniqueIdColumn &&
                  rowValueDiff !== 0 && (
                    <div className={s.rowScoreContainer}>
                      <span className={`${s.rowValueDiff} ${diffChangeClass}`}>
                        {rowValueDiffString}
                      </span>
                    </div>
                  )}
                <div className={s.rowScoreContainer}>
                  {isUniqueIdColumn && <span>{rowScoreString}</span>}
                </div>
              </div>
            );
          })}
          <div className={s.firefoxMarginFixContainer} />
        </div>
      </ScrollSyncPane>
    );
  }
}

const mapStateToProps = state => ({
  hoveredRow: state.importer.hoveredRow,
});

const mapDispatchToProps = dispatch => ({
  setHoveredRow: row =>
    dispatch({ type: 'TOGGLE_HOVERED_ROW', hoveredRow: row }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(s)(RowsContainer));
