import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import baseStyles from '../../styles/app.scss';
import s from './Layout.css';
// import Header from '../Header';

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return [
      // <Header key="app-header" />,
      <div key="app-content">
        <div className="container">{this.props.children}</div>
      </div>,
    ];
  }
}

export default withStyles(baseStyles, s)(Layout);
