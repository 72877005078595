import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import reactTableStyle from 'react-table/react-table.css';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import Loading from 'components/Loading';

import s from './Table.scss'; // eslint-disable-line css-modules/no-unused-class

const CheckboxTable = checkboxHOC(ReactTable);

class Table extends React.Component {
  static propTypes = {
    isCheckable: PropTypes.bool,
    keyField: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    tableId: PropTypes.string.isRequired,
    defaultPageSize: PropTypes.number,
    tableSettings: PropTypes.object, //eslint-disable-line
  };

  static defaultProps = {
    isCheckable: false,
    keyField: 'id',
    defaultPageSize: 10,
    tableSettings: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      selection: {},
      selectAll: false,
      pageSize: 0,
    };

    this.toggleSelection = this.toggleSelection.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.savePageSizeInLocalStorage = this.savePageSizeInLocalStorage.bind(this); //eslint-disable-line
  }

  componentDidMount() {
    const { tableId, defaultPageSize } = this.props;

    const pageSize =
      Number(localStorage.getItem(`${tableId}_pageSize`)) || defaultPageSize;

    this.setState({ pageSize }); // eslint-disable-line
  }

  savePageSizeInLocalStorage = pageSize => {
    localStorage.setItem(`${this.props.tableId}_pageSize`, pageSize);
  };

  /**
   * toggles the selection of the row with this key
   * @param {*} key
   */
  toggleSelection(key) {
    const newSelection = { ...this.state.selection };

    if (newSelection[key]) {
      newSelection[key] = false;
    } else {
      newSelection[key] = true;
    }

    this.setState({ selection: newSelection });
  }

  /**
   * selects or unselects all rows
   */
  toggleAll() {
    const newSelectAll = !this.state.selectAll;
    const newSelection = {};

    if (newSelectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      currentRecords.forEach(item => {
        newSelection[item._original.id] = true; // eslint-disable-line no-underscore-dangle
      });
    }

    this.setState({ selectAll: newSelectAll, selection: newSelection });
  }

  isSelected = key => this.state.selection[key] === true; // ask for true as undefined should correspond to false

  render() {
    const { data, columns, tableSettings } = this.props;
    const { toggleSelection, toggleAll, isSelected } = this;
    const { selectAll, pageSize } = this.state;
    const { isCheckable, keyField } = this.props;

    if (pageSize === 0) {
      return <Loading />;
    }

    if (isCheckable) {
      const checkboxProps = {
        selectAll,
        isSelected,
        toggleSelection,
        toggleAll,
        selectType: 'checkbox',
        getTrProps: (st, r) => {
          const selected = r && this.isSelected(r.original.id);
          if (selected) {
            return {
              style: {
                backgroundColor: 'lightgreen',
              },
            };
          }
          return {
            style: {},
          };
        },
      };

      return (
        <div>
          <CheckboxTable
            ref={r => (this.checkboxTable = r)} // eslint-disable-line no-return-assign
            columns={columns}
            data={data}
            defaultPageSize={pageSize}
            onPageSizeChange={this.savePageSizeInLocalStorage}
            {...tableSettings}
            keyField={keyField}
            {...checkboxProps}
          />
        </div>
      );
    }

    return (
      <div>
        <ReactTable
          columns={columns}
          data={data}
          defaultPageSize={pageSize}
          onPageSizeChange={this.savePageSizeInLocalStorage}
          {...tableSettings}
        />
      </div>
    );
  }
}

export default withStyles(s, reactTableStyle)(Table);
