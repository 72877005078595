import { defineMessages } from 'react-intl';

const messages = defineMessages({
  gdpr: {
    id: 'privacyAgreement.dsgvo',
    defaultMessage: 'GDPR',
    description: 'Heading in privacy agreement',
  },
  submit: {
    id: 'privacyAgreementForm.submit',
    defaultMessage: 'Accept',
    description: 'Submit button text in privacyAgreementForm form',
  },
  reject: {
    id: 'privacyAgreementForm.reject',
    defaultMessage: 'Reject',
    description: 'Reject button text in privacyAgreementForm form',
  },
  privacyPolicyRead: {
    id: 'privacyAgreement.privacyPolicyRead',
    defaultMessage: `
      I have read the
      <a href="https://www.danubify.com/privacy" title="Privacy Policy" target="_blank">privacy policy</a><br />
      and fully accept it.
    `,
    description: 'Label for privacy policy field in privacy agreement',
  },
});

export default messages;
