import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import MdClose from 'react-icons/lib/io/close';

import s from './HubspotAlert.scss';
import modalStyle from '../Modal/Modal.scss'; // eslint-disable-line css-modules/no-unused-class
import HubspotForm from '../HubspotForm';
import { Alert } from '../Modal';
import { HUBSPOT_REGION, HUBSPOT_PORTAL_ID } from '../../constants';

class HubspotAlert extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    title: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    preMsg: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    postMsg: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    footer: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    portalId: PropTypes.string,
    region: PropTypes.string,
    localeToFormIdMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    show: false,
    onHide: () => {},
    title: null,
    preMsg: null,
    postMsg: null,
    footer: null,
    region: HUBSPOT_REGION,
    portalId: HUBSPOT_PORTAL_ID,
    onSubmit: () => {},
  };

  render() {
    const {
      show,
      onHide,
      title,
      preMsg,
      postMsg,
      footer,
      region,
      portalId,
      localeToFormIdMap,
      onSubmit,
    } = this.props;

    return (
      <Alert
        dialogClassName={`${modalStyle.defaultDialog} ${s.hubspotModal}`}
        show={show}
        hide={onHide}
        title={
          <div className={s.titleContainer}>
            <div className={s.title}>{title}</div>
            <button onClick={onHide}>
              <MdClose />
            </button>
          </div>
        }
        footer={footer}
        withDefaultFooter={false}
        msg={
          <>
            {preMsg}
            <HubspotForm
              region={region}
              portalId={portalId}
              localeToFormIdMap={localeToFormIdMap}
              onSubmit={onSubmit}
            />
            {postMsg}
          </>
        }
      />
    );
  }
}

export default withStyles(modalStyle, s)(HubspotAlert);
