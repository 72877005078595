import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, ButtonToolbar } from 'react-bootstrap';
import { Field, reduxForm, reset } from 'redux-form';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import { connect } from 'react-redux';

import render from 'components/ReduxForm/renderField';

import { pageKeys, componentKeys, eMailKeys, otherKeys } from '../index';
import {
  keyDataSetOptions,
  aggregatedKeyDataDataSetOptions,
} from '../analyticsStyles';

export const getLogsOptions = () => {
  const accessLogsOptions = [];
  const clickLogsOptions = [];
  const eMailLogsOptions = [];
  const otherLogsOptions = [];
  const aggregatedLogsOptions = [];

  Object.keys(keyDataSetOptions).forEach(key => {
    const option = {
      value: key,
      label: keyDataSetOptions[key].label,
      backgroundColor: keyDataSetOptions[key].borderColor,
      fontColor: keyDataSetOptions[key].tagFontColor,
    };

    if (Object.values(pageKeys).includes(key)) {
      accessLogsOptions.push(option);
    } else if (Object.values(componentKeys).includes(key)) {
      clickLogsOptions.push(option);
    } else if (Object.values(eMailKeys).includes(key)) {
      eMailLogsOptions.push(option);
    } else if (Object.values(otherKeys).includes(key)) {
      otherLogsOptions.push(option);
    }
  });

  Object.keys(aggregatedKeyDataDataSetOptions).forEach(key => {
    const option = {
      value: key,
      label: aggregatedKeyDataDataSetOptions[key].label,
      backgroundColor: aggregatedKeyDataDataSetOptions[key].borderColor,
      fontColor: aggregatedKeyDataDataSetOptions[key].tagFontColor,
    };
    aggregatedLogsOptions.push(option);
  });

  return {
    accessLogsOptions,
    clickLogsOptions,
    eMailLogsOptions,
    otherLogsOptions,
    aggregatedLogsOptions,
  };
};

const {
  accessLogsOptions,
  clickLogsOptions,
  eMailLogsOptions,
  otherLogsOptions,
  aggregatedLogsOptions,
} = getLogsOptions();

const selectStyles = {
  option: (styles, { data }) => ({
    ...styles,
    backgroundColor: data.backgroundColor,
    color: data.fontColor,
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: data.backgroundColor,
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.fontColor,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.fontColor,
    ':hover': {
      backgroundColor: data.backgroundColor,
    },
  }),
};

const messages = defineMessages({
  from: {
    id: 'analytics.filterForm.from',
    defaultMessage: 'From',
    description: 'Label for from field in filter form',
  },
  to: {
    id: 'analytics.filterForm.to',
    defaultMessage: 'To',
    description: 'Label for to field in filter form',
  },
  submit: {
    id: 'analytics.filterForm.submit',
    defaultMessage: 'Apply',
    description: 'Submit button text in filter form',
  },
  reset: {
    id: 'analytics.filterForm.reset',
    defaultMessage: 'Reset',
    description: 'Reset button text in filter form',
  },
  accessLogs: {
    id: 'analytics.filterForm.accessLogs',
    defaultMessage: 'Access logs',
    description: 'Label for from field in filter form',
  },
  clickLogs: {
    id: 'analytics.filterForm.clickLogs',
    defaultMessage: 'Click logs',
    description: 'Label for from field in filter form',
  },
  logHashes: {
    id: 'analytics.filterForm.logHashes',
    defaultMessage: 'Log hashes',
    description: 'Label for from field in filter form',
  },
  eMailLogs: {
    id: 'analytics.filterForm.eMailLogs',
    defaultMessage: 'E-Mail logs',
    description: 'Label for from field in filter form',
  },
  otherLogs: {
    id: 'analytics.filterForm.otherLogs',
    defaultMessage: 'Other logs',
    description: 'Label for from field in filter form',
  },
  aggregatedLogs: {
    id: 'analytics.filterForm.aggregatedLogs',
    defaultMessage: 'Aggregated logs',
    description: 'Label for from field in filter form',
  },
});

class FilterForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    onReset: PropTypes.func.isRequired,
    logHashesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    intl: intlShape.isRequired,
    resetForm: PropTypes.func.isRequired,
  };

  render() {
    const {
      handleSubmit,
      submitting,
      onReset,
      logHashesOptions,
      resetForm,
    } = this.props;

    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <fieldset>
          <Row>
            <Col xs={12} md={4}>
              <Field
                id="from"
                name="from"
                label={<FormattedMessage {...messages.from} />}
                component={render.renderDate}
              />
            </Col>
            <Col xs={12} md={4}>
              <Field
                id="to"
                name="to"
                label={<FormattedMessage {...messages.to} />}
                component={render.renderDate}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={8} lg={6}>
              <Field
                id="accessLogs"
                name="accessLogs"
                label={<FormattedMessage {...messages.accessLogs} />}
                component={render.renderSelectAlt}
                isMulti
                options={accessLogsOptions}
                styles={selectStyles}
                innerPadding={0}
              />
              <Field
                id="clickLogs"
                name="clickLogs"
                label={<FormattedMessage {...messages.clickLogs} />}
                component={render.renderSelectAlt}
                isMulti
                options={clickLogsOptions}
                styles={selectStyles}
                innerPadding={0}
              />
              <Field
                id="logHashes"
                name="logHashes"
                label={<FormattedMessage {...messages.logHashes} />}
                component={render.renderSelectAlt}
                isMulti
                options={logHashesOptions}
                styles={selectStyles}
                innerPadding={0}
              />
            </Col>
            <Col xs={12} md={8} lg={6}>
              <Field
                id="eMailLogs"
                name="eMailLogs"
                label={<FormattedMessage {...messages.eMailLogs} />}
                component={render.renderSelectAlt}
                isMulti
                options={eMailLogsOptions}
                styles={selectStyles}
                innerPadding={0}
              />
              <Field
                id="otherLogs"
                name="otherLogs"
                label={<FormattedMessage {...messages.otherLogs} />}
                component={render.renderSelectAlt}
                isMulti
                options={otherLogsOptions}
                styles={selectStyles}
                innerPadding={0}
              />
              <Field
                id="aggregatedLogs"
                name="aggregatedLogs"
                label={<FormattedMessage {...messages.aggregatedLogs} />}
                component={render.renderSelectAlt}
                isMulti
                options={aggregatedLogsOptions}
                styles={selectStyles}
                innerPadding={0}
              />
            </Col>
          </Row>
          <ButtonToolbar>
            <button
              type="submit"
              className="btn-secondary btn-round"
              disabled={submitting}
            >
              <FormattedMessage {...messages.submit} />
            </button>
            <button
              type="button"
              className="btn-primary btn-round"
              disabled={submitting}
              onClick={() => {
                resetForm();
                onReset();
              }}
            >
              <FormattedMessage {...messages.reset} />
            </button>
          </ButtonToolbar>
        </fieldset>
        <hr />
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  resetForm: () => dispatch(reset('analyticsFilterForm')),
});

export default connect(
  null,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'analyticsFilterForm',
  })(injectIntl(FilterForm)),
);
