import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-bootstrap';
import FaArrowDown from 'react-icons/lib/md/arrow-downward';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmileBeam } from '@fortawesome/free-regular-svg-icons'; // eslint-disable-line import/extensions
import { faForward, faUserShield } from '@fortawesome/free-solid-svg-icons'; // eslint-disable-line import/extensions

// import CookieBanner from 'react-cookie-banner';
// import { navItemsRight } from 'components/TopNavigation/navItems';
import s from './Technology.scss'; // eslint-disable-line css-modules/no-unused-class
import topNavStyle from '../TopNavigation/TopNavigation.scss'; // eslint-disable-line css-modules/no-unused-class
import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import buttonStyle from '../../styles/base/button.scss'; // eslint-disable-line css-modules/no-unused-class
import WhitepaperButton from '../WhitepaperButton';
import messages from './messages';

const scrollToSlide = slideRef => {
  window.scroll({
    top: slideRef.offsetTop,
    left: 0,
    behavior: 'smooth',
  });
};

class LandingPage extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.frontPageRef = React.createRef();
    this.slide1Ref = React.createRef();
  }

  componentDidMount() {
    if (window.location.hash && window.location.hash === '#content') {
      scrollToSlide(this.slide1Ref.current);
    }
  }

  render() {
    return (
      <div className={s.technologyPage}>
        <div>
          <Row sm={12} md={12} className={`${s.firstPage} nowrap`}>
            <Col xsPull="right" sm={6} md={6} lg={7}>
              <h1 className={s.headline}>
                <FormattedMessage
                  {...messages.technologyTitle}
                  values={{ br: <br className={s.mobileBreak} /> }}
                />
              </h1>
              <p className={s.subline}>
                <FormattedMessage
                  {...messages.technologyPageSubtitle}
                  values={{ br: <br /> }}
                />
              </p>
              <div className={`${s.buttonsContainer}`}>
                <WhitepaperButton />
              </div>
            </Col>
            <Col sm={6} md={6} lg={5} className="hidden-xs">
              <div className={s.infoGraphicWrapper}>
                <Link to="/demos/avengers">
                  <img src="/laboratory.png" alt="" />
                </Link>
              </div>
            </Col>
            <div
              className={`${
                s.downButtonContainer
              } hidden-xs hidden-sm hidden-md`}
            >
              <button
                onClick={() => {
                  scrollToSlide(this.slide1Ref.current);
                }}
              >
                <FaArrowDown />
              </button>
            </div>
          </Row>
        </div>
        <div className={s.secondPage} ref={this.slide1Ref}>
          <Row>
            <Col className={s.iconColumn} xs={12} lg={4}>
              <FontAwesomeIcon icon={faForward} />
              <div>
                <FormattedMessage
                  {...messages.graphicIcon_1}
                  values={{ br: <br /> }}
                />
              </div>
            </Col>
            <Col className={s.iconColumn} xs={12} lg={4}>
              <FontAwesomeIcon icon={faSmileBeam} />
              <div>
                <FormattedMessage
                  {...messages.graphicIcon_2}
                  values={{ br: <br /> }}
                />
              </div>
            </Col>
            <Col className={s.iconColumn} xs={12} lg={4}>
              <FontAwesomeIcon icon={faUserShield} />
              <div>
                <FormattedMessage
                  {...messages.graphicIcon_3}
                  values={{ br: <br /> }}
                />
              </div>
            </Col>
          </Row>
          <div
            className={`${s.secondPageSpacer} hidden-xs hidden-sm hidden-md`}
          />
        </div>
        <div className={s.thirdPage}>
          <Row>
            <Col className={`${s.rightColumn} hidden-lg`} xs={12} lg={5}>
              <img src="/danube_mary.png" alt="" />
            </Col>
            <Col xs={12} lg={7}>
              <div className={s.text}>
                <p>
                  <FormattedMessage
                    {...messages.infographicSection_1}
                    values={{
                      danube: <span className={s.danubeText}>danube.ai</span>,
                    }}
                  />
                </p>
              </div>
              <div className={s.text}>
                <p>
                  <FormattedMessage
                    {...messages.infographicSection_2}
                    values={{
                      danube: <span className={s.danubeText}>danube.ai</span>,
                    }}
                  />
                </p>
              </div>
            </Col>
            <Col
              className={`${s.rightColumn} hidden-xs hidden-sm hidden-md`}
              xs={12}
              lg={5}
            >
              <img src="/danube_mary.png" alt="" />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(
  injectIntl(withStyles(s, layoutStyle, topNavStyle, buttonStyle)(LandingPage)),
);
