import React from 'react';

import NotFound from 'components/NotFound';

class NotFoundWrapper extends React.Component {
  render() {
    return (
      <div>
        <NotFound />
      </div>
    );
  }
}

export default NotFoundWrapper;
