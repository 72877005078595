import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Tabs, Tab } from 'react-bootstrap';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import FaRepeat from 'react-icons/lib/fa/repeat';

import Loading from 'components/Loading';
import Table from 'components/Table';

import s from './View.scss';
import messages from './messages';
import AnalyticsFilter from './Filter';
import FunnelChart from './FunnelChart';
import { keyDataSetOptions } from './analyticsStyles';
import { DB_DATE_FORMAT } from '../../util';
import { aggregationData } from './index';
import { getLogsOptions } from './Filter/FilterForm';

const dateFormat = 'DD.MM.YYYY';
const dateFormatExact = 'DD.MM.YYYY HH:mm:ss';

const sortDates = format => (a, b) => {
  if (a === b) return 0;

  const timeA = moment(a, format);
  const timeB = moment(b, format);

  if (!timeA.isValid()) return -1;
  if (!timeB.isValid()) return 1;

  return Math.sign(timeA.diff(timeB, 'seconds'));
};

const formatFilterValues = filter => {
  const includedLogs = [
    ...(filter.accessLogs ? filter.accessLogs.map(log => log.value) : []),
    ...(filter.clickLogs ? filter.clickLogs.map(log => log.value) : []),
    ...(filter.eMailLogs ? filter.eMailLogs.map(log => log.value) : []),
    ...(filter.otherLogs ? filter.otherLogs.map(log => log.value) : []),
    ...(filter.logHashes ? filter.logHashes.map(log => log.value) : []),
  ];

  const aggregatedLogs = [
    ...(filter.aggregatedLogs
      ? filter.aggregatedLogs.map(log => aggregationData[log.value])
      : []),
  ];

  const newFilter = {
    ...filter,
    includedLogs,
    aggregatedLogs,
  };

  delete newFilter.accessLogs;
  delete newFilter.clickLogs;
  delete newFilter.eMailLogs;
  delete newFilter.otherLogs;
  delete newFilter.logHashes;

  return newFilter;
};

const {
  accessLogsOptions,
  clickLogsOptions,
  eMailLogsOptions,
  otherLogsOptions,
  aggregatedLogsOptions,
} = getLogsOptions();

const defaultFilter = {
  accessLogs: [...accessLogsOptions],
  clickLogs: [...clickLogsOptions],
  eMailLogs: [...eMailLogsOptions],
  otherLogs: [...otherLogsOptions],
  aggregatedLogs: [...aggregatedLogsOptions],
  logHashes: [],
};

const formattedDefaultFilter = formatFilterValues(defaultFilter);
const defaultVariables = {
  filter: {
    includedLogs: formattedDefaultFilter.includedLogs,
    aggregatedLogs: formattedDefaultFilter.aggregatedLogs,
  },
};

class AnalyticsView extends React.Component {
  static propTypes = {
    analyticsData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      accessLogs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      ),
      clickLogs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      ),
      userLogs: PropTypes.arrayOf(
        PropTypes.shape({
          username: PropTypes.string.isRequired,
        }),
      ),
      calibrationLogs: PropTypes.arrayOf(
        PropTypes.shape({
          createdAt: PropTypes.string.isRequired,
          updatedAt: PropTypes.string.isRequired,
          rawData: PropTypes.shape({
            name: PropTypes.string.isRequired,
          }).isRequired,
        }),
      ),
      funnel: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string,
          logs: PropTypes.arrayOf(
            PropTypes.shape({
              key: PropTypes.string,
              count: PropTypes.number,
            }),
          ),
        }),
      ),
      logHashes: PropTypes.arrayOf(
        PropTypes.shape({
          hash: PropTypes.string,
          pageKey: PropTypes.string,
          description: PropTypes.string,
        }),
      ),
    }).isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.applyFilters = this.applyFilters.bind(this);

    this.state = {
      activeTabKey: 'accessLogs',
      filter: defaultFilter,
      includedLogs: defaultVariables.filter.includedLogs,
      aggregatedLogs: defaultVariables.filter.aggregatedLogs,
    };
  }

  handleTabSelect(tabKey) {
    const { filter } = this.state;

    this.setState({ activeTabKey: tabKey }, () => {
      this.applyFilters({ filter });
    });
  }

  async applyFilters({ filter }) {
    const newFilter = filter ? formatFilterValues(filter) : {};

    this.setState({
      filter,
      includedLogs: newFilter.includedLogs || null,
      aggregatedLogs: newFilter.aggregatedLogs || null,
    });

    this.props.analyticsData.refetch({
      filter: newFilter,
    });
  }

  render() {
    const {
      analyticsData: {
        loading,
        accessLogs,
        clickLogs,
        userLogs,
        calibrationLogs,
        funnel,
        logHashes,
      },
      intl,
    } = this.props;
    const { filter, includedLogs, aggregatedLogs } = this.state;

    if (loading) return <Loading />;

    const logHashesMap = logHashes.reduce((map, logHash) => {
      // eslint-disable-next-line no-param-reassign
      map[logHash.hash] = logHash;
      return map;
    }, {});

    const logHashesOptions = logHashes.map(logHash => ({
      value: logHash.hash,
      label: `${logHash.description} (${logHash.hash})`,
      backgroundColor: keyDataSetOptions[logHash.pageKey].borderColor,
      fontColor: keyDataSetOptions[logHash.pageKey].tagFontColor,
    }));

    return (
      <div className={s.viewContainer}>
        <AnalyticsFilter
          onFilterApply={this.applyFilters}
          appliedValues={filter}
          logHashesOptions={logHashesOptions}
        />
        <button
          className="btn-secondary btn-round"
          style={{ padding: '8px 12px' }}
          onClick={() => {
            this.applyFilters({ filter });
          }}
        >
          <FaRepeat />
        </button>
        <div className={s.funnelChartContainer}>
          <FunnelChart
            funnel={funnel}
            includedLogs={includedLogs}
            aggregatedLogs={aggregatedLogs}
            logHashesOptions={logHashesOptions}
          />
        </div>
        <button
          className="btn-secondary btn-round"
          style={{ padding: '8px 12px', marginBottom: '20px' }}
          onClick={() => {
            this.applyFilters({ filter });
          }}
        >
          <FaRepeat />
        </button>
        <Tabs
          activeKey={this.state.activeTabKey}
          onSelect={this.handleTabSelect}
          id="analytics-tabs"
        >
          <Tab
            eventKey="accessLogs"
            title={intl.formatMessage(messages.accessLogs)}
          >
            <Table
              tableId="access-logs-table"
              data={accessLogs.map(({ pageKey, date, count }) => {
                const data = {
                  date: moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                  count,
                };

                // check if log hash or normal entry (in case of log hash, pageKey is the hash)
                const lh = logHashesMap[pageKey];
                if (lh) {
                  data.backgroundColor = keyDataSetOptions[lh.pageKey]
                    ? keyDataSetOptions[lh.pageKey].borderColor
                    : null;
                  data.label = `${lh.description} (${lh.hash})`;
                } else {
                  data.backgroundColor = keyDataSetOptions[pageKey]
                    ? keyDataSetOptions[pageKey].borderColor
                    : null;
                  data.label = keyDataSetOptions[pageKey]
                    ? keyDataSetOptions[pageKey].label
                    : pageKey;
                }

                return data;
              })}
              columns={[
                {
                  Header: 'Page',
                  accessor: 'pageKey',
                  Cell: cellInfo => (
                    <div className={s.label}>
                      <div className={s.circle}>
                        <div
                          className={s.circleInner}
                          style={{
                            backgroundColor: cellInfo.original.backgroundColor,
                          }}
                        />
                      </div>
                      <span>{cellInfo.original.label}</span>
                    </div>
                  ),
                },
                {
                  id: 'date',
                  Header: 'Date',
                  accessor: 'date',
                  sortMethod: sortDates(dateFormat),
                },
                {
                  Header: 'Count',
                  accessor: 'count',
                },
              ]}
              tableSettings={{
                defaultSorted: [
                  {
                    id: 'date',
                    desc: true,
                  },
                ],
              }}
            />
          </Tab>
          <Tab
            eventKey="clickLogs"
            title={intl.formatMessage(messages.clickLogs)}
          >
            <Table
              tableId="click-logs-table"
              data={clickLogs.map(({ componentKey, date, count }) => ({
                componentKey,
                date: moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
                count,
              }))}
              columns={[
                {
                  Header: 'Component',
                  accessor: 'componentKey',
                  Cell: cellInfo => (
                    <div className={s.label}>
                      <div className={s.circle}>
                        <div
                          className={s.circleInner}
                          style={{
                            // eslint-disable-next-line prettier/prettier
                            backgroundColor: keyDataSetOptions[cellInfo.original.componentKey] ? keyDataSetOptions[cellInfo.original.componentKey].borderColor : null,
                          }}
                        />
                      </div>
                      <span>
                        {/* eslint-disable-next-line prettier/prettier */}
                        {keyDataSetOptions[cellInfo.original.componentKey] ? keyDataSetOptions[cellInfo.original.componentKey].label : cellInfo.original.componentKey}
                      </span>
                    </div>
                  ),
                },
                {
                  id: 'date',
                  Header: 'Date',
                  accessor: 'date',
                  sortMethod: sortDates(dateFormat),
                },
                {
                  Header: 'Count',
                  accessor: 'count',
                },
              ]}
              tableSettings={{
                defaultSorted: [
                  {
                    id: 'date',
                    desc: true,
                  },
                ],
              }}
            />
          </Tab>
          <Tab
            eventKey="userLogs"
            title={intl.formatMessage(messages.userLogs)}
          >
            <Table
              tableId="user-logs-table"
              data={userLogs}
              columns={[
                { Header: 'Username', accessor: 'username' },
                { Header: 'E-Mail', accessor: 'email' },
                {
                  Header: 'Active',
                  id: 'active',
                  accessor: user => (user.active ? 'Yes' : 'No'),
                },
                {
                  Header: 'E-Mail confirmed',
                  id: 'emailConfirmed',
                  accessor: user => (user.emailConfirmed ? 'Yes' : 'No'),
                },
                {
                  Header: 'Privacy Agreement Accepted',
                  id: 'privacyAgreementAccepted',
                  accessor: user =>
                    user.privacyAgreementAccepted ? 'Yes' : 'No',
                },
                {
                  Header: 'Created at',
                  id: 'createdAt',
                  accessor: user =>
                    moment(user.createdAt, DB_DATE_FORMAT).format(dateFormatExact), // eslint-disable-line prettier/prettier
                  sortMethod: sortDates(dateFormatExact),
                },
                {
                  Header: 'Updated at',
                  id: 'updatedAt',
                  accessor: user =>
                    moment(user.updatedAt, DB_DATE_FORMAT).format(dateFormatExact), // eslint-disable-line prettier/prettier
                  sortMethod: sortDates(dateFormatExact),
                },
                {
                  Header: 'Activated on',
                  id: 'activatedOn',
                  accessor: user =>
                    user.activatedOn
                      ? moment(user.activatedOn, DB_DATE_FORMAT).format(dateFormatExact) // eslint-disable-line prettier/prettier
                      : 'Not activated',
                  sortMethod: sortDates(dateFormatExact),
                },
              ]}
              tableSettings={{
                defaultSorted: [
                  {
                    id: 'createdAt',
                    desc: true,
                  },
                ],
              }}
            />
          </Tab>
          <Tab
            eventKey="calibrationLogs"
            title={intl.formatMessage(messages.calibrationLogs)}
          >
            <Table
              tableId="calibration-logs-table"
              data={calibrationLogs}
              columns={[
                {
                  Header: 'Name',
                  id: 'name',
                  accessor: calibration =>
                    calibration.rawData ? calibration.rawData.name : 'No name',
                },
                {
                  Header: 'User',
                  id: 'user',
                  accessor: calibration =>
                    calibration.user ? calibration.user.username : 'No user',
                },
                {
                  Header: 'Created at',
                  id: 'createdAt',
                  accessor: calibration =>
                    moment(calibration.createdAt, DB_DATE_FORMAT).format(dateFormatExact), // eslint-disable-line prettier/prettier
                  sortMethod: sortDates(dateFormatExact),
                },
                {
                  Header: 'Updated at',
                  id: 'updatedAt',
                  accessor: calibration =>
                    moment(calibration.updatedAt, DB_DATE_FORMAT).format(dateFormatExact), // eslint-disable-line prettier/prettier
                  sortMethod: sortDates(dateFormatExact),
                },
              ]}
              tableSettings={{
                defaultSorted: [
                  {
                    id: 'createdAt',
                    desc: true,
                  },
                ],
              }}
            />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const analyticsDataQuery = gql`
  query analyticsData($filter: AnalyticsFilter) {
    accessLogs(filter: $filter) {
      id
      pageKey
      date
      count
      metaData
      createdAt
      updatedAt
    }
    clickLogs(filter: $filter) {
      id
      componentKey
      date
      count
      metaData
      createdAt
      updatedAt
    }
    userLogs(filter: $filter) {
      username
      email
      active
      emailConfirmed
      privacyAgreementAccepted
      createdAt
      updatedAt
      privacyAgreementRevokedOn
      activatedOn
    }
    calibrationLogs(filter: $filter) {
      id
      createdAt
      updatedAt
      rawData {
        id
        name
      }
    }
    funnel(filter: $filter) {
      date
      logs {
        key
        count
      }
    }
    logHashes {
      hash
      pageKey
      description
      createdAt
      updatedAt
    }
  }
`;

export default compose(
  graphql(analyticsDataQuery, {
    name: 'analyticsData',
    options: () => ({
      variables: defaultVariables,
    }),
  }),
)(injectIntl(withStyles(s)(AnalyticsView)));
