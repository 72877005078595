import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';

// import GetApiKeyButton from 'components/ApiKey/GetApiKeyButton';

import s from '../Page.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';
import wikiMessages from '../messages';

class GetApiKey extends React.Component {
  render() {
    return (
      <div className={s.apiText}>
        <p>
          <h1>
            <FormattedMessage {...wikiMessages.getApiKey} />
          </h1>
          <FormattedMessage {...messages.getApiKeyText} />
          {/* <br />
          <br />
          <GetApiKeyButton /> */}
        </p>
      </div>
    );
  }
}

export default withStyles(s)(GetApiKey);
