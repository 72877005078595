import React from 'react';
import PropTypes from 'prop-types';
import { change } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';

import s from './Switcher.scss';

class Switcher extends React.Component {
  static propTypes = {
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.shape({
        id: PropTypes.string,
      }),
      warning: PropTypes.bool,
    }).isRequired,
    input: PropTypes.shape({
      value: PropTypes.string,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
    }).isRequired,
    formConfig: PropTypes.shape({
      formName: PropTypes.string.isRequired,
      fieldName: PropTypes.string.isRequired,
    }).isRequired,
    values: PropTypes.shape({
      left: PropTypes.string,
      right: PropTypes.string,
    }).isRequired,
    labelFormatter: PropTypes.func,
    disabled: PropTypes.bool,
    changeFieldValue: PropTypes.func.isRequired,
    onSwitch: PropTypes.func,
  };

  static defaultProps = {
    labelFormatter: value => value,
    disabled: false,
    onSwitch: null,
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const {
      input: { value },
      formConfig: { formName, fieldName },
      values,
      onSwitch,
    } = this.props;

    let newValue;
    if (value === values.left) {
      newValue = values.right;
    } else {
      newValue = values.left;
    }

    this.props.changeFieldValue(formName, fieldName, newValue);

    if (onSwitch) {
      onSwitch(newValue);
    }
  }

  render() {
    const {
      meta: { touched, error, warning },
      input: { value },
      values,
      labelFormatter,
      disabled,
    } = this.props;

    const containerClass = disabled
      ? `${s.container} ${s.disabled}`
      : `${s.container}`;

    let switchClass = `${s.switch}`;
    let labelContainerClass = `${s.labelContainer}`;
    if (values.left === value) {
      switchClass += ` ${s.left}`;
      labelContainerClass += ` ${s.left}`;
    } else if (values.right === value) {
      switchClass += ` ${s.right}`;
      labelContainerClass += ` ${s.right}`;
    }

    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <div className={containerClass}>
        <div className={s.track} onClick={this.toggle}>
          <div className={switchClass} />
        </div>
        <div className={labelContainerClass}>{labelFormatter(value)}</div>
        {touched &&
          ((error && <p className="bg-danger">{error}</p>) ||
            (warning && <p className="bg-warning">{warning}</p>))}
      </div>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
    /* eslint-enable jsx-a11y/click-events-have-key-events */
  }
}

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (form, field, value) =>
    dispatch(change(form, field, value)),
});

export default withStyles(s)(
  connect(
    null,
    mapDispatchToProps,
  )(Switcher),
);
