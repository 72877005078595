import PropTypes from 'prop-types';

import messages from '../../../../../demos/o2/components/ResultsList/messages';

/* eslint-disable prettier/prettier, no-underscore-dangle */
export const COMPANY = 'Hersteller';
export const PRICE_TOTAL = 'Preis (einmalig)';
export const DISPLAY_ZOLL_ORIGINAL = 'Display (Zoll)';
export const DISPLAY_ZOLL = 'Display (Zoll)';
export const DISPLAY_PPI = 'Display (ppi)';
export const CAMERA_MPX = 'Kamera Mpx';
export const CAMERA_COUNT = 'Kamera (Anzahl)';
export const STORAGE_RAM = 'Speicher (RAM)';
export const STORAGE_ROM = 'Speicher (ROM)';
export const WEIGHT = 'Gewicht (g)';
export const TALK_TIME = 'Gesprächszeit';
export const BATTERY_POWER = 'Akkuleistung (mAh)';
export const GRADE_CHIP_DE = 'Note Chip.de';
export const PERCENTAGE_CONNECT_DE = 'Prozent connect.de';
export const POINTS_NETZWELT_DE = 'Punkte netzwelt.de';
export const GRADE_TECHSTAGE_DE = 'Note techstage.de';
export const GRADE_COMPUTERBILD_DE = 'Note Computerbild.de';
export const CAMERA_GRADE_CREATIVEBLOG = 'Camera: creativebloq';
export const CAMERA_GRADE_DIGITALCAMERAWORLD = 'Camera: digitalcameraworld';
export const CAMERA_GRADE_NOTEBOOKCHECK = 'Camera: notebookcheck';
export const POPULARITY = 'Beliebtheit';
// merged properties
export const DISPLAY = 'Display';
export const CAMERA = 'Kamera';
export const BATTERY = 'Akkuleistung';
export const STORAGE = 'Speicher';
export const EXTERNAL_TESTS = 'Externe Tests';
export const CAMERA_TESTS = 'Camera Tests';

const _rowNameToId = {};
_rowNameToId.id = 'id';
_rowNameToId[COMPANY] = 'COMPANY';
_rowNameToId[PRICE_TOTAL] = 'PRICE_TOTAL';
// rowNameToId[DISPLAY_ZOLL_ORIGINAL] = 'DISPLAY_ZOLL_ORIGINAL'; // same key, cannot use it here
_rowNameToId[DISPLAY_ZOLL] = 'DISPLAY_ZOLL';
_rowNameToId[DISPLAY_PPI] = 'DISPLAY_PPI';
_rowNameToId[CAMERA_MPX] = 'CAMERA_MPX';
_rowNameToId[CAMERA_COUNT] = 'CAMERA_COUNT';
_rowNameToId[STORAGE_RAM] = 'STORAGE_RAM';
_rowNameToId[STORAGE_ROM] = 'STORAGE_ROM';
_rowNameToId[WEIGHT] = 'WEIGHT';
_rowNameToId[TALK_TIME] = 'TALK_TIME';
_rowNameToId[BATTERY_POWER] = 'BATTERY_POWER';
_rowNameToId[GRADE_CHIP_DE] = 'GRADE_CHIP_DE';
_rowNameToId[PERCENTAGE_CONNECT_DE] = 'PERCENTAGE_CONNECT_DE';
_rowNameToId[POINTS_NETZWELT_DE] = 'POINTS_NETZWELT_DE';
_rowNameToId[GRADE_TECHSTAGE_DE] = 'GRADE_TECHSTAGE_DE';
_rowNameToId[GRADE_COMPUTERBILD_DE] = 'GRADE_COMPUTERBILD_DE';
_rowNameToId[CAMERA_GRADE_CREATIVEBLOG] = 'CAMERA_GRADE_CREATIVEBLOG';
_rowNameToId[CAMERA_GRADE_DIGITALCAMERAWORLD] = 'CAMERA_GRADE_DIGITALCAMERAWORLD';
_rowNameToId[CAMERA_GRADE_NOTEBOOKCHECK] = 'CAMERA_GRADE_NOTEBOOKCHECK';
_rowNameToId[POPULARITY] = 'POPULARITY';
// merged properties
_rowNameToId[DISPLAY] = 'DISPLAY';
_rowNameToId[CAMERA] = 'CAMERA';
_rowNameToId[BATTERY] = 'BATTERY';
_rowNameToId[STORAGE] = 'STORAGE';
_rowNameToId[EXTERNAL_TESTS] = 'EXTERNAL_TESTS';
_rowNameToId[CAMERA_TESTS] = 'CAMERA_TESTS';

export const rowNameToId = _rowNameToId;

const _rowIdToName = {};
_rowIdToName.id = 'id';
_rowIdToName.COMPANY = COMPANY;
_rowIdToName.PRICE_TOTAL = PRICE_TOTAL;
_rowIdToName.DISPLAY_ZOLL_ORIGINAL = DISPLAY_ZOLL_ORIGINAL;
_rowIdToName.DISPLAY_ZOLL = DISPLAY_ZOLL;
_rowIdToName.DISPLAY_PPI = DISPLAY_PPI;
_rowIdToName.CAMERA_MPX = CAMERA_MPX;
_rowIdToName.CAMERA_COUNT = CAMERA_COUNT;
_rowIdToName.STORAGE_RAM = STORAGE_RAM;
_rowIdToName.STORAGE_ROM = STORAGE_ROM;
_rowIdToName.WEIGHT = WEIGHT;
_rowIdToName.TALK_TIME = TALK_TIME;
_rowIdToName.BATTERY_POWER = BATTERY_POWER;
_rowIdToName.GRADE_CHIP_DE = GRADE_CHIP_DE;
_rowIdToName.PERCENTAGE_CONNECT_DE = PERCENTAGE_CONNECT_DE;
_rowIdToName.POINTS_NETZWELT_DE = POINTS_NETZWELT_DE;
_rowIdToName.GRADE_TECHSTAGE_DE = GRADE_TECHSTAGE_DE;
_rowIdToName.GRADE_COMPUTERBILD_DE = GRADE_COMPUTERBILD_DE;
_rowIdToName[CAMERA_GRADE_CREATIVEBLOG] = CAMERA_GRADE_CREATIVEBLOG;
_rowIdToName[CAMERA_GRADE_DIGITALCAMERAWORLD] = CAMERA_GRADE_DIGITALCAMERAWORLD;
_rowIdToName[CAMERA_GRADE_NOTEBOOKCHECK] = CAMERA_GRADE_NOTEBOOKCHECK;
_rowIdToName.POPULARITY = POPULARITY;
// merged properties
_rowIdToName.DISPLAY = DISPLAY;
_rowIdToName.CAMERA = CAMERA;
_rowIdToName.BATTERY = BATTERY;
_rowIdToName.STORAGE = STORAGE;
_rowIdToName.EXTERNAL_TESTS = EXTERNAL_TESTS;
_rowIdToName.CAMERA_TESTS = CAMERA_TESTS;

export const rowIdToName = _rowIdToName;

const _rowIdToMessage = {};
// properties
_rowIdToMessage.COMPANY = messages.company;
_rowIdToMessage.PRICE_TOTAL = messages.price;
_rowIdToMessage.DISPLAY_ZOLL = messages.displayZoll;
_rowIdToMessage.DISPLAY_PPI = messages.displayPpi;
_rowIdToMessage.CAMERA_MPX = messages.cameraMpx;
_rowIdToMessage.CAMERA_COUNT = messages.cameraCount;
_rowIdToMessage.STORAGE_RAM = messages.storageRAM;
_rowIdToMessage.STORAGE_ROM = messages.storageROM;
_rowIdToMessage.WEIGHT = messages.weight;
_rowIdToMessage.TALK_TIME = messages.talkTime;
_rowIdToMessage.BATTERY_POWER = messages.batteryPower;
_rowIdToMessage.GRADE_CHIP_DE = messages.gradeChipDe;
_rowIdToMessage.PERCENTAGE_CONNECT_DE = messages.percentageConnectDe;
_rowIdToMessage.POINTS_NETZWELT_DE = messages.pointsNetzweltDe;
_rowIdToMessage.GRADE_TECHSTAGE_DE = messages.gradeTechstageDe;
_rowIdToMessage.GRADE_COMPUTERBILD_DE = messages.gradeComputerbildDe;
_rowIdToMessage.CAMERA_GRADE_CREATIVEBLOG = messages.cameraGradeCreativeblog;
_rowIdToMessage.CAMERA_GRADE_DIGITALCAMERAWORLD = messages.cameraGradeDigitalcameraworld;
_rowIdToMessage.CAMERA_GRADE_NOTEBOOKCHECK = messages.cameraGradeNotebookcheck;
_rowIdToMessage.POPULARITY = messages.popularity;
// merged properties
_rowIdToMessage.DISPLAY = messages.display;
_rowIdToMessage.CAMERA = messages.camera;
_rowIdToMessage.BATTERY = messages.battery;
_rowIdToMessage.STORAGE = messages.storage;
_rowIdToMessage.EXTERNAL_TESTS = messages.externalTests;
_rowIdToMessage.CAMERA_TESTS = messages.cameraTests;

export const rowIdToMessage = _rowIdToMessage;

const _rowIdToBestSlotMessage = {};
_rowIdToBestSlotMessage.PRICE_TOTAL = messages.bestPrice;
_rowIdToBestSlotMessage.DISPLAY_ZOLL_SMALLEST = messages.smallestDisplay;
_rowIdToBestSlotMessage.DISPLAY_ZOLL_LARGEST = messages.largestDisplay;
_rowIdToBestSlotMessage.DISPLAY_PPI = messages.bestResolution;
_rowIdToBestSlotMessage.CAMERA_MPX = messages.bestCameraMpx;
_rowIdToBestSlotMessage.CAMERA_COUNT = messages.bestCameraCount;
_rowIdToBestSlotMessage.STORAGE_RAM = messages.largestStorageRAM;
_rowIdToBestSlotMessage.STORAGE_ROM = messages.largestStorageROM;
_rowIdToBestSlotMessage.WEIGHT = messages.lowestWeight;
_rowIdToBestSlotMessage.TALK_TIME = messages.longestTalkTime;
_rowIdToBestSlotMessage.BATTERY_POWER = messages.bestBatteryPower;
_rowIdToBestSlotMessage.GRADE_CHIP_DE = messages.bestChipDeScore;
_rowIdToBestSlotMessage.PERCENTAGE_CONNECT_DE = messages.bestConnectDeScore;
_rowIdToBestSlotMessage.POINTS_NETZWELT_DE = messages.bestNetzweltDeScore;
_rowIdToBestSlotMessage.GRADE_TECHSTAGE_DE = messages.bestTechstageDeScore;
_rowIdToBestSlotMessage.GRADE_COMPUTERBILD_DE = messages.bestComputerbildDeScore;
_rowIdToBestSlotMessage.CAMERA_GRADE_CREATIVEBLOG = messages.bestCameraGradeCreativeblog;
_rowIdToBestSlotMessage.CAMERA_GRADE_DIGITALCAMERAWORLD = messages.bestCameraGradeDigitalcameraworld;
_rowIdToBestSlotMessage.CAMERA_GRADE_NOTEBOOKCHECK = messages.bestCameraGradeNotebookcheck;
_rowIdToBestSlotMessage.POPULARITY = messages.highestPopularity;

export const rowIdToBestSlotMessage = _rowIdToBestSlotMessage;

const shapeObj = {};
shapeObj.COMPANY = PropTypes.string;
shapeObj.PRICE_TOTAL = PropTypes.number;
shapeObj.DISPLAY_ZOLL_ORIGINAL = PropTypes.number;
shapeObj.DISPLAY_ZOLL = PropTypes.number;
shapeObj.DISPLAY_PPI = PropTypes.number;
shapeObj.CAMERA_MPX = PropTypes.number;
shapeObj.CAMERA_COUNT = PropTypes.number;
shapeObj.STORAGE_RAM = PropTypes.number;
shapeObj.STORAGE_ROM = PropTypes.number;
shapeObj.WEIGHT = PropTypes.number;
shapeObj.TALK_TIME = PropTypes.number;
shapeObj.BATTERY_POWER = PropTypes.number;
shapeObj.GRADE_CHIP_DE = PropTypes.number;
shapeObj.PERCENTAGE_CONNECT_DE = PropTypes.number;
shapeObj.POINTS_NETZWELT_DE = PropTypes.number;
shapeObj.GRADE_TECHSTAGE_DE = PropTypes.number;
shapeObj.GRADE_COMPUTERBILD_DE = PropTypes.number;
shapeObj.CAMERA_GRADE_CREATIVEBLOG = PropTypes.number;
shapeObj.CAMERA_GRADE_DIGITALCAMERAWORLD = PropTypes.number;
shapeObj.CAMERA_GRADE_NOTEBOOKCHECK = PropTypes.number;
shapeObj.POPULARITY = PropTypes.number;
shapeObj.danubeScore = PropTypes.number;
shapeObj.danubeRanking = PropTypes.number;
shapeObj.fish = PropTypes.arrayOf(PropTypes.number);

export const handyContractShape = PropTypes.shape(shapeObj);
/* eslint-enable prettier/prettier, no-underscore-dangle */
