import { defineMessages } from 'react-intl';

const messages = defineMessages({
  accessLogs: {
    id: 'analytics.accessLogs',
    defaultMessage: 'Access Logs',
    description: 'Label for access logs in analytics',
  },
  clickLogs: {
    id: 'analytics.clickLogs',
    defaultMessage: 'Click Logs',
    description: 'Label for click logs in analytics',
  },
  userLogs: {
    id: 'analytics.userLogs',
    defaultMessage: 'User Logs',
    description: 'Label for user logs in analytics',
  },
  calibrationLogs: {
    id: 'analytics.calibrationLogs',
    defaultMessage: 'Calibration Logs',
    description: 'Label for calibration logs in analytics',
  },
});

export default messages;
