export const codeStyle = {
  padding: '10px',
  border: 'none',
  background: 'white',
  fontSize: '18px',
  wordBreak: 'normal',
};

/* eslint-disable prettier/prettier */

export const npmInstall = '$ npm install danube-sdk';

export const yarnInstall = '$ yarn add danube-sdk';

export const clientInitialization = (
  `
const { DanubeClient } = require('danube-sdk');

// Initialize DanubeClient with your API key.
const danubeClient = new DanubeClient(
  'my-api-key',
);
`.trim());

export const setRules = (
  `
/*
 * Saves a set of rules which can later be used under the specified id.
 *
 * @param id: The id of the new rule set (can be any string).
 * @param override: If set to trua and a set of rules already exists for this id, it will be overridden.
 * @param rules: An array of rules (see below for possible options).
 *
 * @returns true if the rules-set was successfully saved.
 */
public async setRules(
  id: string,
  override: boolean,
  rules: RULE[],
): Promise<boolean> {
  ...
}
`.trim());

export const percentageRule = (
  `
/*
 * The "PERCENTAGE" rule maps the values of the corresponding property from [minRuleValue, maxRuleValue] to [0, 1].
 *
 * @field type: The rule's type.
 * @field minRuleValue (OPTIONAL): The lower bound of the source value range. Defaults to the lowest value of the property, if not specified and 'clamping' is true. Defaults to 0 otherwise.
 * @field maxRuleValue (OPTIONAL): The upper bound of the source value range. Defaults to the hightest value of the property, if not specified.
 * @field clamping (OPTIONAL): Specifies whether clamping should be applied or not, if no 'minRuleValue' is specified.
 * 
 * Formulas:
 * Clamping:
 *   clampedValue = max(min(value, maxRuleValue), minRuleValue)
 * Normalizing:
 *   normalizedValue = (clampedValue - minRuleValue) / (maxRuleValue - minRuleValue)
 */
interface PERCENTAGE_RULE extends RULE {
  type: 'PERCENTAGE';
  minRuleValue?: number;
  maxRuleValue?: number;
  clamping?: boolean;
}
`.trim());

export const inversePercentageRule = (
  `
/*
 * The "INVERSE_PERCENTAGE" rule works like the "PERCENTAGE" rule, but inverted (i.e. mapping from [minRuleValue, maxRuleValue] to [1, 0]).
 *
 * @field type: The rule's type.
 * @field minRuleValue (OPTIONAL): see "PERCENTAGE" rule.
 * @field maxRuleValue (OPTIONAL): see "PERCENTAGE" rule.
 * @field clamping (OPTIONAL): see "PERCENTAGE" rule.
 */
interface INVERSE_PERCENTAGE_RULE extends RULE {
  type: 'INVERSE_PERCENTAGE';
  minRuleValue?: number;
  maxRuleValue?: number;
  clamping?: boolean;
}
`.trim());

export const percentageSineRule = (
  `
/*
 * The "PERCENTAGE_SINE" rule works like the "PERCENTAGE" rule, but additionally applies a sine function on the normalized values to make the
 * mapping non-linear.
 *
 * @field type: The rule's type.
 * @field minRuleValue (OPTIONAL): see "PERCENTAGE" rule.
 * @field maxRuleValue (OPTIONAL): see "PERCENTAGE" rule.
 * @field clamping (OPTIONAL): see "PERCENTAGE" rule.
 */
interface PERCENTAGE_SINE_RULE extends RULE {
  type: 'PERCENTAGE_SINE';
  minRuleValue?: number;
  maxRuleValue?: number;
  clamping?: boolean;
}
`.trim());

export const inversePercentageSineRule = (
  `
/*
 * The "INVERSE_PERCENTAGE_SINE" rule works like the "PERCENTAGE_SINE" rule, but inverted (i.e. mapping from [minRuleValue, maxRuleValue] to [1, 0]).
 *
 * @field type: The rule's type.
 * @field minRuleValue (OPTIONAL): see "PERCENTAGE" rule.
 * @field maxRuleValue (OPTIONAL): see "PERCENTAGE" rule.
 * @field clamping (OPTIONAL): see "PERCENTAGE" rule.
 */
interface INVERSE_PERCENTAGE_SINE_RULE extends RULE {
  type: 'INVERSE_PERCENTAGE_SINE';
  minRuleValue?: number;
  maxRuleValue?: number;
  clamping?: boolean;
}
`.trim());

export const equalsRule = (
  `
/*
 * The "EQUALS" rule calculates the score-difference between a row's property's value and the property's value in the 'searchData' of a 'danubePrediction' call, based on the 'equalityScores' in the rule. A lower score-difference gets a higher ranking than a higher score-difference.
 * 
 * @field type: The rule's type.
 * @field equalityScores: An array of equality scores.
 */
interface EQUALS_RULE extends RULE {
  type: 'EQUALS';
  equalityScores: EQUALITY_SCORE[];
}

/*
 * An EQUALITY_SCORE maps a possible property value to a score.
 *
 * @field value: A possible value of the property.
 * @field score: The corresponding score for this value.
 */
interface EQUALITY_SCORE {
  value: string,
  score: number,
}
`.trim());

export const localOverlapRule = (
  `
/*
 * The "OVERLAP_LOCAL" rule calculates the amount of overlap between the values of the property in the 'searchData' of a 'danubePrediction' call and
 * the values of a row's property.
 * 
 * @field type: The rule's type.
 */
interface OVERLAP_LOCAL_RULE extends RULE {
  type: 'OVERLAP_LOCAL';
}
`.trim());

export const globalOverlapRule = (
  `
/*
 * The "OVERLAP_GLOBAL" rule calculates the amount of overlap between the values of the property in the 'searchData' of a 'danubePrediction' call and
 * the values in a row's property. Additionally, the overlaps are normalized by the globally highest absolute overlap.
 * 
 * @field type: The rule's type.
 */
interface OVERLAP_GLOBAL_RULE extends RULE {
  type: 'OVERLAP_GLOBAL';
}
`.trim());

export const danubePrediction1 = (
  `
/*
 * Performs a danube.ai prediction for the specified data and settings.
 *
 * @param rulesId: The id of a set of rules.
 * @param data: A stringified Json-Array, holding all data elements (rows) as objects.
 * @param searchData: A stringified Json-Object with the same structure as a data element.
 * @param initialColumnScores: An array of initial column names and scores (see interface below).
 * @param strategy: Defines the way data attributes are treated by the algorithm. The following options are available:
 *                  - "exclusive": Rare data attributes tend to obtain more weight.
 *                  - "fair": Overly rare or overly frequent data attributes lose weight.
 *                  - "mixed": Mixes "exclusive" and "fair" strategy (see mixFactor).
 *                  - "inverse": Inverse behavior of "fair" strategy.
 * @param mixFactor: The factor to mix "exclusive" and "fair" strategies (Float between 0 and 1; only for "mixed" strategy.):
 *                   0 (= exclusive) ----------x---------- 1 (= fair)
 * @param impact: Determines how strongly the initial column values are changed. n=1 means one run of the algorithm with small changes to the initial values. Higher values of n mean iterative runs of the algorithm with stronger changes.
 * 
 * @returns a set of new column- and row-scores as well as matches (see interface below).
 */
public async danubePrediction(
  rulesId: string,
  data: string,
  searchData: string,
  initialColumnScores: COLUMN_SCORE[],
  strategy: 'exclusive' | 'fair' | 'mixed' | 'inverse',
  mixFactor: number,
  impact: number,
): Promise<DANUBE_PREDICTION_RESULT | null> {
  ...
}
`.trim());

export const danubePrediction2 = (
  `
/*
 * A ColumnScore maps a column (property) to a score.
 *
 * @field property: The name of a property.
 * @field type: The property's score.
 */
interface COLUMN_SCORE {
  property: string;
  score: number;
}
`.trim());

export const danubePrediction3 = (
  `
/*
 * The result of a 'danubePrediction' call.
 *
 * @field newColumnScores: An array of new column scores.
 * @field rowScores: An array of row scores (same ordering as the 'data' elements in the request), each determined by danube.ai. The row scores define the sorting (highest score = best row).
 * @field rowMatches: An array of row matches (same ordering as the 'data' elements in the request), each being an array of percentage values, describing how well a property value matches the best data value in this column.
 */
interface DANUBE_PREDICTION_RESULT {
  newColumnScores: COLUMN_SCORE[],
  rowScores: number[],
  rowMatches: number[][],
}
`.trim());

export const danubeRecommendation1 = (
  `
/*
 * Performs a danube.ai recommendation for the specified data and settings.
 *
 * @param data: A stringified Json-Array, holding all current session elements as objects.
 * @param n: The number of returned recommendations.
 */
public async danubeRecommendation(
  data: string,
  n: number,
): Promise<DANUBE_RECOMMENDATION_RESULT | null> {
  ...
}
`.trim());

export const danubeRecommendation2 = (
  `
/*
 * The result of a 'danubeRecommendation' call.
 *
 * @field correlatedData: A stringified Json-Array, holding all recommended elements as objects.
 */
interface DANUBE_RECOMMENDATION_RESULT {
  correlatedData: String,
}
`.trim());

export const resetCorrelationMatríx = (
  `
/*
 * Resets the current correlation matrix of this API key.
 *
 * @returns true if the correlation matrix was successfully resetted.
 */
public async resetCorrelationMatrix(): Promise<boolean> {
  ...
}
`.trim());

export const setRulesExampleCall = (
  `
const { DanubeClient } = require('danube-sdk'); 

// Initialize a DanubeClient with your API key.
const danubeClient = new DanubeClient(
  'my-api-key',
);

async function runTest() {
  const rulesId = 'my-sdk-test-rule-set'; // Define a name for your rule-set.
  const override = true; // Define if your rule-set will override a rule-set with the same id, if it already exists.

  // Specify example rules.
  const rules = [
    {
      property: 'salaryFrom',
      type: 'PERCENTAGE',
    },
    {
      property: 'salaryTo',
      type: 'PERCENTAGE',
    },
    {
      property: 'daysAgo',
      type: 'INVERSE_PERCENTAGE',
    },
    {
      property: 'companyType',
      type: 'EQUALS',
      equalityScores: [
        {
          value: 'Startup',
          score: 1,
        },
        {
          value: 'Digital agency',
          score: 2,
        },
        {
          value: 'Established company',
          score: 3,
        },
      ],
    },
    {
      property: 'jobLevel',
      type: 'EQUALS',
      equalityScores: [
        {
          value: 'Junior',
          score: 1,
        },
        {
          value: 'Experienced',
          score: 2,
        },
        {
          value: 'Senior',
          score: 3,
        },
        {
          value: 'Lead',
          score: 4,
        },
      ],
    },
    {
      property: 'timeDistance',
      type: 'INVERSE_PERCENTAGE',
    },
    {
      property: 'technologies',
      type: 'OVERLAP_GLOBAL',
    },
    {
      property: 'benefits',
      type: 'OVERLAP_LOCAL',
    },
  ];

  // Save your rule-set.
  const setRulesSuccess = await danubeClient.setRules(
    rulesId,
    override,
    rules,
  );
}

runTest();
`.trim());

export const setRulesExampleReturn = (
  `
// Check if rules have been saved successfully.
console.log('Rules successfully saved:', setRulesSuccess);

/*
Rules successfully saved: true
*/
`.trim());

export const danubePredictionExampleCall = (
  `
const { DanubeClient } = require('danube-sdk'); 

// Initialize a DanubeClient with your API key.
const danubeClient = new DanubeClient(
  'my-api-key',
);

async function runTest() {
  const rulesId = 'my-sdk-test-rule-set'; // The id of your saved rules-set.

  // Create some example test data and stringify it as a JSON.
  const testData = [
    {
      id: 0,
      title: "job-1",
      field: "Test/QA",
      salaryFrom: 36000,
      salaryTo: 50000,
      daysAgo: 240,
      companyType: "Startup",
      jobLevel: "Experienced",
      technologies: ["Python", "Java", "C++", "C"],
      benefits: ["Flexible working hours", "Team events"]
    },
    {
      id: 1,
      title: "job-2",
      field: "Software",
      salaryFrom: 42000,
      salaryTo: 60000,
      daysAgo: 100,
      companyType: "Established company",
      // jobLevel missing --> data may be incomplete
      technologies: ["Git", "Docker", "JavaScript"]
      // benefits missing --> data may be incomplete
    },
  ];

  const stringifiedTestData = JSON.stringify(testData);

  // Create some example search data and stringify it as a JSON.
  const testSearchData = {
    companyType: ["Startup"],
    jobLevel: ["Junior", "Experienced"],
    technologies: ["SQL", "Java", "Linux"],
    benefits: ["Flexible working hours", "Home office"]
  };

  const stringifiedTestSearchData = JSON.stringify(testSearchData);

  // Define initial scores.
  const initialScores = [
    {property: "salaryFrom", score: 1},
    {property: "salaryTo", score: 1},
    {property: "daysAgo", score: 0.5}, // might be weighted less important
    {property: "companyType", score: 1},
    {property: "jobLevel", score: 1},
    {property: "technologies", score: 2}, // might be weighted more important
    {property: "benefits", score: 1},
  ];

  // Let danube.ai sort your data.
  const results = await danubeClient.danubePrediction(
    rulesId,
    stringifiedTestData,
    stringifiedTestSearchData,
    initialScores,
    'mixed', // strategy
    0.75, // mix-factor
    1, // impact
  );
}

runTest();
`.trim());

export const danubePredictionExampleReturn = (
  `
// Output results.
console.log(results);

/*
{
  newColumnScores: [
    {
      property: 'salaryFrom',
      score: 0.9249125017979084
    },
    {
      property: 'salaryTo',
      score: 0.9520168249967466
    },
    {
      property: 'daysAgo',
      score: 1.2820712588097503
    },
    {
      property: 'companyType',
      score: 0.6732114039806347
    },
    {
      property: 'jobLevel',
      score: 1.6026413313951928
    },
    {
      property: 'technologies',
      score: 1.1732114039806347
    },
    {
      property: 'benefits',
      score: 0.8919352750391324
    }
  ],
  rowScores: [ 3.8785199440429987, 3.159000585604405 ],
  rowMatches: [
    [ 0.8571428571428571, 0.8333333333333334, 0, 1, 0, 1, 0.5 ],
    [ 1, 1, 1, 0, 0, 0, 0 ]
  ]
}
*/
`.trim());

export const danubeRecommendationExampleCall = (
  `
const { DanubeClient } = require('danube-sdk'); 

// Initialize a DanubeClient with your API key.
const danubeClient = new DanubeClient(
  'my-api-key',
);

async function runTest() {
  // Session 1

  // Step 1
  const result_1_1 = await danubeClient.danubeRecommendation(
    JSON.stringify([{ page: 'Home' }]),
    3,
  );

  // Step 2
  const result_1_2 = await danubeClient.danubeRecommendation(
    JSON.stringify([{ page: 'Home' }, { page: 'About' }]),
    3,
  );

  // Step 3
  const result_1_3 = await danubeClient.danubeRecommendation(
    JSON.stringify([{ page: 'Home' }, { page: 'About' }, { page: 'Pricing' }]),
    3,
  );


  // Session 2

  // Step 1
  const result_2_1 = await danubeClient.danubeRecommendation(
    JSON.stringify([{ page: 'Home' }]),
    3,
  );

  // Step 2
  const result_2_2 = await danubeClient.danubeRecommendation(
    JSON.stringify([{ page: 'Home' }, { page: 'Pricing' }]),
    3,
  );

  // Step 3
  const result_2_3 = await danubeClient.danubeRecommendation(
    JSON.stringify([{ page: 'Home' }, { page: 'Pricing' }, { page: 'Imprint' }]),
    3,
  );


  // Session 3

  // Step 1
  const result_3_1 = await danubeClient.danubeRecommendation(
    JSON.stringify([{ page: 'Home' }]),
    3,
  );
}

runTest();
`.trim());

export const danubeRecommendationExampleReturn = (
  `
console.log('Session 1:');
console.log('Step 1:', result_1_1);
console.log('Step 2:', result_1_2);
console.log('Step 3:', result_1_3);

console.log('Session 2:');
console.log('Step 1:', result_2_1);
console.log('Step 2:', result_2_2);
console.log('Step 3:', result_2_3);

console.log('Session 3:');
console.log('Step 1:', result_3_1);

/*
Session 1:
Step 1: { correlatedData: '[]' }
Step 2: { correlatedData: '[]' }
Step 3: { correlatedData: '[]' }

Session 2:
Step 1: { correlatedData: '[{"page":"Pricing","_danubeScore":4},{"page":"About","_danubeScore":0}]' }
Step 2: { correlatedData: '[{"page":"About","_danubeScore":0}]' }
Step 3: { correlatedData: '[{"page":"About","_danubeScore":0}]' }

Session 3:
Step 1: { correlatedData: '[{"page":"Pricing","_danubeScore":6},{"page":"Imprint","_danubeScore":3.785370547493044},{"page":"About","_danubeScore":0.13677942320303804}]' }
*/
`.trim());

export const resetCorrelationMatrixExampleCall = (
  `
const { DanubeClient } = require('danube-sdk'); 

// Initialize a DanubeClient with your API key.
const danubeClient = new DanubeClient(
  'my-api-key',
);

async function runTest() {
  const resetCorrelationMatrixSuccess = await danubeClient.resetCorrelationMatrix();
}

runTest();
`.trim());

export const resetCorrelationMatrixExampleReturn = (
  `
// Check if the correlation matrix has successfully been resetted.
console.log('Correlation matrix successfully resetted:', resetCorrelationMatrixSuccess);

/*
Correlation matrix successfully resetted: true
*/
`.trim());

export const getRules = (
  `
/*
 * Gets all set of rules which have been saved for this API-key.
 *
 * @returns a list of set of rules.
 */
public async getRules(): Promise<RULES_SET[]> {
  ...
}
`.trim());

export const rulesSet = (
  `
/*
 * A set of rules defined by an id and containing a list of rules.
 *
 * @field id: An id of this rules-set.
 * @field rules: A list of rules.
 */
export interface RULES_SET {
  id: string;
  rules: RULE[];
}
`.trim());

export const getRulesExampleCall = (
  `
const { DanubeClient } = require('danube-sdk'); 

// Initialize a DanubeClient with your API key.
const danubeClient = new DanubeClient(
  'my-api-key',
);

async function runTest() {
  const rulesId_1 = 'my-sdk-test-rule-set-1';
  const override_1 = true;
  const rules_1 = [
    {
      property: 'p1',
      type: 'PERCENTAGE',
    },
    {
      property: 'p2',
      type: 'PERCENTAGE',
    },
  ];

  await danubeClient.setRules(
    rulesId_1,
    override_1,
    rules_1,
  );

  const rulesId_2 = 'my-sdk-test-rule-set-1';
  const override_2 = true;
  const rules_2 = [
    {{
      property: 'p3',
      type: 'PERCENTAGE',
    },
    {
      property: 'p4',
      type: 'EQUALS',
      equalityScores: [
        {
          value: 'v1',
          score: 1,
        },
        {
          value: 'v2',
          score: 2,
        },
      ],
    },
  ];

  await danubeClient.setRules(
    rulesId_2,
    override_2,
    rules_2,
  );

  const savedRulesSets = await danubeClient.getRules();
}

runTest();
`.trim());

export const getRulesExampleReturn = (
  `
// Output retrieved rules-sets.
console.log(savedRulesSets);

/*
[
  {
    id: 'my-sdk-test-rule-set-1',
    rules: [
      { property: 'p1', type: 'PERCENTAGE' },
      { property: 'p2', type: 'PERCENTAGE' }
    ],
  },
  {
    id: 'my-sdk-test-rule-set-2',
    rules: [
      { property: 'p3', type: 'PERCENTAGE' },
      { 
        property: 'p4',
        type: 'EQUALS',
        equalityScores: [
          { value: 'v1', score: 1 },
          { value: 'v2', score: 2 }
        ]
      }
    ]
  }
]
*/
`.trim());

/* eslint-enable prettier/prettier */
