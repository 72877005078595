import React from 'react';

import PageProperties from 'components/PageProperties';
import AboutUs from 'components/AboutUs';
import routeMessages from 'routes/messages';

class About extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.aboutUsTitle,
            description: routeMessages.aboutUsDescription,
            keywords: routeMessages.aboutUsKeywords,
          }}
        />
        <AboutUs />
      </div>
    );
  }
}

export default About;
