import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { reduxForm } from 'redux-form';

import s from './DataPreSettings.scss'; // eslint-disable-line css-modules/no-unused-class
import fileUploadStyle from '../../../FileUpload/FileUpload.scss'; // eslint-disable-line css-modules/no-unused-class
import layoutStyle from '../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import TemplateSelector from './TemplateSelector';
import DataSettingsPanel from './DataSettingsPanel';

class DataPreSettings extends React.Component {
  render() {
    return (
      <div>
        <TemplateSelector />
        <DataSettingsPanel
          showImportSettings
          showGlobalMaximum
          enableRowAndColSettings
          formName="excelUploadForm"
        />
      </div>
    );
  }
}

export default reduxForm({
  form: 'excelUploadForm',
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(withStyles(s, layoutStyle, fileUploadStyle)(DataPreSettings));
