import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'import.header',
    defaultMessage: 'Import',
    description: 'Header message for Import Wizard',
  },
  alertErrorTitle: {
    id: 'import.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertImporterErrorMessage: {
    id: 'import.alertImporterErrorMessage',
    defaultMessage: 'There has been an error while reading the imported file!',
    description: 'Message for error alert dialog if imported data is faulty',
  },
  alertUniqueIdColumnNotFoundMessage: {
    id: 'import.alertUniqueIdColumnNotFoundMessage',
    defaultMessage: 'Id column could not be found!',
    description:
      'Message for error alert dialog if no unique column was found when uploading',
  },
  alertInitialValueRowNotFound: {
    id: 'import.alertInitialValueRowNotFound',
    defaultMessage: 'Initial value row could not be found!',
    description:
      'Message for error alert dialog if no initial value row was found when uploading',
  },
  introText: {
    id: 'import.introText',
    defaultMessage: `
      Now map the columns from your uploaded file to the corresponding fields.
    `,
    description: 'Intro message for Import Wizard',
  },
  uniqueId: {
    id: 'import.uniqueId',
    defaultMessage: 'Unique ID',
    description: 'Unique ID label for Column Importer',
  },
  uniqueIdInfoText: {
    id: 'import.uniqueIdInfoText',
    defaultMessage: 'Every recalibration needs a unique id per row.',
    description: 'Unique ID info text for Column Importer',
  },
  columnTypeNone: {
    id: 'import.columnTypeNone',
    defaultMessage: 'Treat column as',
    description: 'Placeholder text for column type select',
  },
  columnTypeText: {
    id: 'import.columnTypeText',
    defaultMessage: 'Text',
    description: 'Text option for column type select',
  },
  columnTypeNumber: {
    id: 'import.columnTypeNumber',
    defaultMessage: 'Number',
    description: 'Number option for column type select',
  },
  columnTypeBoolean: {
    id: 'import.columnTypeBoolean',
    defaultMessage: 'Boolean',
    description: 'Boolean option for column type select',
  },
  columnTypeNumericPercentage: {
    id: 'import.columnTypeNumericPercentage',
    defaultMessage: 'Numeric Percentage',
    description: 'Numeric Percentage option for column type select',
  },
  rules: {
    id: 'import.rules',
    defaultMessage: 'Rules',
    description: 'Rules label for Column Importer',
  },
  addRule: {
    id: 'import.addRule',
    defaultMessage: '+ Add Rule',
    description: 'Add Rule label for button in Column Importer',
  },
  columRuleEqual: {
    id: 'import.columRuleEqual',
    defaultMessage: '=',
    description: 'Equal rule for Column Importer',
  },
  columRuleSmallerThan: {
    id: 'import.columRuleSmallerThan',
    defaultMessage: '<',
    description: 'Smaller Than rule for Column Importer',
  },
  columRuleSmallerThanOrEqual: {
    id: 'import.columRuleSmallerThanOrEqual',
    defaultMessage: '<=',
    description: 'Smaller Than Or Equal rule for Column Importer',
  },
  columRuleLargerThan: {
    id: 'import.columRuleLargerThan',
    defaultMessage: '>',
    description: 'Larger Than rule for Column Importer',
  },
  columRuleLargerThanOrEqual: {
    id: 'import.columRuleLargerThanOrEqual',
    defaultMessage: '>=',
    description: 'Larger Than Or Equal rule for Column Importer',
  },
  columRuleHasFeature: {
    id: 'import.columRuleHasFeature',
    defaultMessage: 'Has feature',
    description: 'Has Feature rule for Column Importer',
  },
  columRuleMaxPercentage: {
    id: 'import.columRuleMaxPercentage',
    defaultMessage: 'Max %',
    description: 'Max Percentage rule for Column Importer',
  },
  uploadHelpText: {
    id: 'import.uploadHelpText',
    defaultMessage: `Choose a file from which to import the base data for this recalibration
    from. This can be either a CSV or XLS(X)-File. Each base data file needs
    to have a unique id column (e.g., test number, proband it, ...) and results
    that should be recalibrated.`,
    description: 'Help Text for excel file upload',
  },
  reset: {
    id: 'import.reset',
    defaultMessage: 'Reset',
    description: 'Reset label for button in Column Importer',
  },
  back: {
    id: 'import.back',
    defaultMessage: 'Back',
    description: 'Back label for button in Column Importer',
  },
  initialSort: {
    id: 'import.initialSort',
    defaultMessage: 'Initial sort',
    description: 'Initial Sort label for button in Column Importer',
  },
  danubify: {
    id: 'import.danubify',
    defaultMessage: 'danubify',
    description: 'Danubify label for button in Column Importer',
  },

  /* statistics messages */
  skipped: {
    id: 'column.statistics.skipped',
    defaultMessage: 'Skipped',
    description: 'label for skipped statistic.',
  },
  matched: {
    id: 'column.statistics.matched',
    defaultMessage: 'Matched',
    description: 'label for matched statistic.',
  },
  unmatched: {
    id: 'column.statistics.unmatched',
    defaultMessage: 'Not matched',
    description: 'label for unmatched statistic.',
  },

  /* column settings messages */
  skip: {
    id: 'column.settings.skip',
    defaultMessage: 'Skip',
    description: 'label for skip toggle.',
  },
  fixed: {
    id: 'column.settings.fixed',
    defaultMessage: 'Fixed',
    description: 'label for fixed toggle.',
  },
  name: {
    id: 'column.settings.name',
    defaultMessage: 'Name',
    description: 'label for name field in column settings.',
  },
  binary: {
    id: 'column.settings.binary',
    defaultMessage: 'Binary',
    description: 'label for binary option in column settings.',
  },
  percentage: {
    id: 'column.settings.percentage',
    defaultMessage: 'Percentage',
    description: 'label for percentage option in column settings.',
  },
  hundredPercentAbove: {
    id: 'column.settings.hundredPercentAbove',
    defaultMessage: '100%',
    description: 'label for 100% above field in column settings.',
  },
  treatAsZero: {
    id: 'column.settings.treatAsZero',
    defaultMessage: 'Treat empty as 0',
    description: 'label for treat empty as 0 field in column settings.',
  },
  treatAsZeroPercentage: {
    id: 'column.settings.treatAsZeroPercentage',
    defaultMessage: 'Treat empty as 0%',
    description: 'label for treat empty as 0% field in column settings.',
  },
  apply: {
    id: 'column.settings.apply',
    defaultMessage: 'Apply',
    description: 'label for apply button in column settings.',
  },
  applyAndSort: {
    id: 'column.settings.applyAndSort',
    defaultMessage: 'Apply & Sort',
    description: 'label for apply and sort button in column settings',
  },
  columnScore: {
    id: 'column.settings.columnScore',
    defaultMessage: 'Column Score',
    description: 'label for column score input field in column settings',
  },
});

export default messages;
