import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { FormattedMessage, defineMessages } from 'react-intl';

import { isNumeric } from '../ResultsTable/ColumnHelper';

const messages = defineMessages({
  firstFifth: {
    id: 'barchart.firstFifth',
    defaultMessage: '1st Fifth',
    description: 'Tooltip message for barchart',
  },
  secondFifth: {
    id: 'barchart.secondFifth',
    defaultMessage: '2nd Fifth',
    description: 'Tooltip message for barchart',
  },
  thirdFifth: {
    id: 'barchart.thirdFifth',
    defaultMessage: '3rd Fifth',
    description: 'Tooltip message for barchart',
  },
  fourthFifth: {
    id: 'barchart.fourthFifth',
    defaultMessage: '4th Fifth',
    description: 'Tooltip message for barchart',
  },
  fifthFitfth: {
    id: 'barchart.fifthFitfth',
    defaultMessage: '5th Fifth',
    description: 'Tooltip message for barchart',
  },
});

const CustomTooltip = props => {
  const { active, external, quantiles, label, payload } = props; // eslint-disable-line react/prop-types
  if (active) {
    const style = {
      padding: 6,
      backgroundColor: '#fff',
      border: '1px solid #ccc',
    };
    let currData;
    if (payload && payload[0] && payload[0].payload && payload[0].payload.key) {
      currData = external.filter(
        entry => entry.key === payload[0].payload.key,
      )[0];
    } else {
      currData = external.filter(entry => entry.name === label)[0];
    }
    let totalFish = 0;
    quantiles.forEach(quantile => {
      totalFish += quantile.length;
    });
    let message = null;
    if (!currData) {
      return <div />;
    }
    switch (currData.key) {
      case 0:
        message = messages.firstFifth;
        break;
      case 1:
        message = messages.secondFifth;
        break;
      case 2:
        message = messages.thirdFifth;
        break;
      case 3:
        message = messages.fourthFifth;
        break;
      case 4:
        message = messages.fifthFitfth;
        break;
      default:
        message = messages.firstFifth;
        break;
    }
    return (
      <div className="area-chart-tooltip" style={style}>
        <FormattedMessage {...message} />
        <p>{`${
          quantiles[quantiles.length - 1 - currData.key].length
        } / ${totalFish} Fish`}</p>
      </div>
    );
  }

  return null;
};

class DatasetGraph extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    ),
    scores: PropTypes.arrayOf(PropTypes.number).isRequired,
  };

  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);

    this.renderTopScoreBar = this.renderTopScoreBar.bind(this);
    this.renderQuantilesBar = this.renderQuantilesBar.bind(this);
  }

  renderTopScoreBar() {
    // data needs to be in props
    const { data } = this.props;

    return (
      <ResponsiveContainer height={320}>
        <BarChart data={data} margin={{ top: 20 }}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip cursor={{ fill: 'white' }} />
          <Legend />
          <Bar dataKey="score">
            {data.map((entry, index) => {
              let opacity;
              switch (index) {
                case 0:
                  opacity = 1;
                  break;
                case 1:
                  opacity = 0.5;
                  break;
                case 2:
                  opacity = 0.3;
                  break;
                default:
                  opacity = 1;
              }
              return (
                <Cell key={entry.score} fill="#00293e" opacity={opacity} />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }

  renderQuantilesBar(percentage) {
    const { scores } = this.props;

    const quantiles = [];
    const data = [];

    if (scores.length !== 0) {
      const sortedScores = scores.sort((val1, val2) => val2 - val1);
      const maxValue = sortedScores[0];

      const steps = maxValue * percentage;

      for (let i = 0; i < 100 / (percentage * 100); i += 1) {
        quantiles[i] = [];
      }

      sortedScores.forEach(score => {
        let quantile = Math.ceil(score / steps) - 1;
        quantile = quantile === -1 ? 0 : quantile; // special case for score = 0
        if (quantiles[quantile]) {
          quantiles[quantile].push(score);
        }
      });
      for (let i = 0; i < 100 / (percentage * 100); i += 1) {
        if (quantiles[i]) {
          let name = '';
          const inversedIndex = quantiles.length - 1 - i;
          const lowerBound = quantiles[i][quantiles[i].length - 1];
          name = isNumeric(lowerBound) ? `>${lowerBound.toFixed(0)}` : '';

          data[inversedIndex] = {
            name,
            percentage: quantiles[i].length / sortedScores.length,
            key: inversedIndex,
          };
        }
      }
    }

    const toPercent = (decimal, fixed = 0) =>
      `${(decimal * 100).toFixed(fixed)}%`;

    return (
      <ResponsiveContainer height={320}>
        <BarChart data={data} margin={{ top: 20 }}>
          <XAxis dataKey="name" />
          <YAxis tickFormatter={toPercent} />
          <Tooltip
            cursor={{ fill: 'white' }}
            content={<CustomTooltip external={data} quantiles={quantiles} />}
          />
          <Legend />
          <Bar dataKey="percentage">
            {scores.map((entry, index) => {
              const opacity = 1 + percentage - (index + 1) * percentage;

              return (
                <Cell
                  key={`BarChartCell${index}`} // eslint-disable-line react/no-array-index-key
                  fill="#00293e"
                  opacity={opacity}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  }

  render() {
    // Labels not automatically switching to other percentages. Must be done manually.
    return this.renderQuantilesBar(0.2);
  }
}

export default DatasetGraph;
