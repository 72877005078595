import { defineMessages } from 'react-intl';

const messages = defineMessages({
  experienceTag: {
    id: 'demo.avengers.results.experienceTag',
    defaultMessage: 'Experience',
    description: 'label in results list',
  },
  travelTimeTag: {
    id: 'demo.avengers.results.travelTimeTag',
    defaultMessage: 'Travel time',
    description: 'label in results list',
  },
  salaryTag: {
    id: 'demo.avengers.results.salaryTag',
    defaultMessage: 'Salary',
    description: 'label in results list',
  },
  strengthTag: {
    id: 'demo.avengers.results.strengthTag',
    defaultMessage: 'Strength',
    description: 'label in results list',
  },
  socialTag: {
    id: 'demo.avengers.results.socialTag',
    defaultMessage: 'Social',
    description: 'label in results list',
  },
  superPowersTag: {
    id: 'demo.avengers.results.superPowersTag',
    defaultMessage: 'Super powers',
    description: 'label in results list',
  },
  benefitsTag: {
    id: 'demo.avengers.results.benefitsTag',
    defaultMessage: 'Benefits',
    description: 'label in results list',
  },
});

export default messages;
