import React from 'react';
import PropTypes from 'prop-types';
import ReactDropzone from 'react-dropzone';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import s from './Dropzone.scss'; // eslint-disable-line css-modules/no-unused-class

const messages = defineMessages({
  uploadFile: {
    id: 'dropzone.uploadFile',
    defaultMessage: 'Upload File',
    description: 'Message displayed in dropzone',
  },
  innerMessage: {
    id: 'dropzone.inner',
    defaultMessage: 'Click here or drag a file onto here to upload it',
    description: 'Message displayed in dropzone',
  },
});

class Dropzone extends React.Component {
  static propTypes = {
    input: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    meta: PropTypes.shape({
      touched: PropTypes.bool.isRequired,
      error: PropTypes.bool,
      warning: PropTypes.bool,
    }).isRequired,
    name: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    persistedFiles: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
  };

  static defaultProps = {
    multiple: true,
    persistedFiles: [],
  };

  render() {
    const {
      input,
      meta: { touched, error, warning },
      name,
      multiple,
    } = this.props;

    return (
      <div>
        <ReactDropzone
          name={name}
          className={s.dropzone}
          activeClassName={s.dropzoneActive}
          onDrop={filesToUpload => input.onChange(filesToUpload)}
          multiple={multiple}
          maxSize={1024 * 1024 * 4}
        >
          <div className={s.dropzone__inner}>
            <div className={s.imageContainer}>
              <img src="/waterdrop.png" alt="" />
              <span className={s.imageText}>
                <FormattedMessage {...messages.uploadFile} />
              </span>
            </div>
            <div>
              <FormattedMessage {...messages.innerMessage} />
            </div>
          </div>
        </ReactDropzone>
        {touched &&
          ((error && <p className="bg-danger">{error}</p>) ||
            (warning && <p className="bg-warning">{warning}</p>))}
      </div>
    );
  }
}

export default injectIntl(withStyles(s)(Dropzone));
