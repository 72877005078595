import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Tile.scss';

class Tile extends React.Component {
  static propTypes = {
    imagePath: PropTypes.string.isRequired,
    content: PropTypes.element.isRequired,
    light: PropTypes.bool,
  };

  static defaultProps = {
    light: false,
  };

  render() {
    const { imagePath, content, light } = this.props;

    let tileClass;

    if (light) {
      tileClass = `${s.tile} ${s.light}`;
    } else {
      tileClass = `${s.tile} ${s.dark}`;
    }

    return (
      <div className={s.tileWrapper}>
        <div className={tileClass}>
          <img src={imagePath} alt="" />
          <div>{content}</div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(Tile);
