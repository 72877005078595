import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loading from 'components/Loading';

import s from './RealDataProvider.scss';
import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from './messages';

class RealDataProvider extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        category: PropTypes.string,
        set: PropTypes.string,
      }),
    }),
    datasheetsQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      danubeDataSheets: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  };

  static defaultProps = {
    match: {
      params: {
        category: 'sports',
        set: 'football',
      },
    },
  };

  render() {
    const {
      datasheetsQuery: { loading, danubeDataSheets },
      match: {
        params: { category, set },
      },
    } = this.props;

    if (loading) return <Loading />;

    if (danubeDataSheets.length === 0) {
      return <Redirect to="/404" />;
    }

    return (
      <div className={s.container}>
        <Row className={layoutStyle.noMargin}>
          <Col mdOffset={1} md={4}>
            <h1>
              {`${category.charAt(0).toUpperCase()}${category.substring(1)}`}
              &nbsp;-&nbsp;
              {`${set.charAt(0).toUpperCase()}${set.substring(1)}`}
            </h1>
          </Col>
          <Col md={6}>
            <p className={s.paragraph}>
              <FormattedMessage {...messages.info} values={{ setName: set }} />
            </p>
            <ul className={s.downloadLinks}>
              {danubeDataSheets.map(file => (
                <li key={file.name}>
                  <a href={file.url} title={file.name}>
                    {file.name}
                  </a>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </div>
    );
  }
}

const datasheetsQuery = gql`
  query datasheetsQuery($category: String!, $set: String!) {
    danubeDataSheets(category: $category, set: $set) {
      name
      url
    }
  }
`;

export default compose(
  graphql(datasheetsQuery, {
    name: 'datasheetsQuery',
    options: ({ match }) => ({
      variables: {
        category: match.params.category,
        set: match.params.set,
      },
    }),
  }),
)(withStyles(s, layoutStyle)(RealDataProvider));
