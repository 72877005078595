import { defineMessages } from 'react-intl';

const messages = defineMessages({
  discordButton: {
    id: 'cherryTree.discordButton',
    defaultMessage: 'Join on Discord',
    description: 'Label for Cherry Tree button',
  },
  pikaiaButton: {
    id: 'cherryTree.cherryTreePikaiaButton',
    defaultMessage: 'Check out Pikaia',
    description: 'Label for Cherry Tree button',
  },
  readMoreButton: {
    id: 'cherryTree.readMoreButton',
    defaultMessage: 'Technology',
    description: 'Label for Cherry Tree button',
  },
  page2Header: {
    id: 'cherryTree.page2Header',
    defaultMessage: '{logo} is based on {method}',
    description: 'Text for Cherry Tree page',
  },
  page2Line1: {
    id: 'cherryTree.page2Line1',
    defaultMessage: 'For more details see:',
    description: 'Text for Cherry Tree page',
  },
  page2Line2: {
    id: 'cherryTree.page2Line2',
    defaultMessage: 'Get the Python package:',
    description: 'Text for Cherry Tree page',
  },
});

export default messages;
