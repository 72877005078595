import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage } from 'react-intl';
import FaDownload from 'react-icons/lib/md/file-download';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';

import { componentKeys, ClickTracker } from 'components/Analytics';

import s from './WhitepaperButton.scss';
import HubspotAlert from '../HubspotAlert';
import { WHITEPAPER_FORM_IDS, WHITEPAPER_DOCUMENTS } from '../../constants';
import { parseGetParameters } from '../../core/url';

const messages = defineMessages({
  label: {
    id: 'downloadWhitepaper.label',
    defaultMessage: 'Download Whitepaper',
    description: 'Label for DownloadWhitepaper button',
  },
  download: {
    id: 'downloadWhitepaper.download',
    defaultMessage: 'Click here to view the Whitepaper.',
    description: 'Label for DownloadWhitepaper link',
  },
});

class WhitepaperButton extends React.Component {
  static propTypes = {
    localeToDocumentLinkMap: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    localeToFormIdMap: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  };

  static defaultProps = {
    localeToDocumentLinkMap: WHITEPAPER_DOCUMENTS,
    localeToFormIdMap: WHITEPAPER_FORM_IDS,
    location: null,
  };

  constructor(props) {
    super(props);

    let showWhitePaperModal = false;

    if (props.location && props.location.search) {
      const getParameters = parseGetParameters(props.location.search);
      if (getParameters.downloadWhitepaper) {
        showWhitePaperModal = true;
      }
    }

    this.state = {
      showWhitePaperModal,
      showLink: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    this.setState({ showLink: true });
  }

  render() {
    const { localeToFormIdMap } = this.props;
    const { showWhitePaperModal, showLink } = this.state;

    return [
      <ClickTracker componentKey={componentKeys.DOWNLOAD_WHITEPAPER_BUTTON}>
        <button
          key="whitepaper-button"
          onClick={() => {
            this.setState({ showWhitePaperModal: true });
          }}
          className={`btn-blue-white btn-high btn-large btn-full-round ${
            s.whitepaperButton
          }`}
        >
          <FaDownload className={s.icon} />
          <FormattedMessage className={s.text} {...messages.label} />
        </button>
      </ClickTracker>,
      <HubspotAlert
        key="whitepaper-modal"
        show={showWhitePaperModal}
        onHide={() => {
          this.setState({
            showWhitePaperModal: false,
            showLink: false,
          });
        }}
        title={<FormattedMessage {...messages.label} />}
        postMsg={
          showLink ? (
            <div className={s.downloadLinkContainer}>
              <a
                href={this.props.localeToDocumentLinkMap[this.props.locale]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage {...messages.download} />
              </a>
              <img src="/fishy_blue_horizontal.png" alt="" />
            </div>
          ) : null
        }
        localeToFormIdMap={localeToFormIdMap}
        onSubmit={this.onSubmit}
      />,
    ];
  }
}

const mapStateToProps = state => ({
  locale: state && state.intl ? state.intl.locale : null,
});

export default connect(mapStateToProps)(withStyles(s)(WhitepaperButton));
