import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import PropTypes from 'prop-types';

import s from './showcase.scss'; //eslint-disable-line

class StatisticsTemplate extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    mainLabel: PropTypes.element.isRequired,
    subLabel: PropTypes.element,
  };

  static defaultProps = {
    subLabel: <div />,
  };

  render() {
    const { text, mainLabel, subLabel } = this.props;

    return (
      <div className={s.statisticsBox}>
        <div className={s.text}>{text}</div>
        <div className={s.mainLabel}>{mainLabel}</div>
        <hr className={s.line} />
        <div className={s.subLabel}>{subLabel}</div>
      </div>
    );
  }
}

export default withStyles(s)(StatisticsTemplate);
