import { defineMessages } from 'react-intl';

const messages = defineMessages({
  apiKeys: {
    id: 'user.account.apiKeys',
    defaultMessage: 'My API keys',
    description: 'heading for Api Keys',
  },
  name: {
    id: 'user.account.name',
    defaultMessage: 'Name',
    description: 'label for products',
  },
  description: {
    id: 'user.account.description',
    defaultMessage: 'Description',
    description: 'label for products',
  },
  key: {
    id: 'user.account.key',
    defaultMessage: 'Key',
    description: 'label for products',
  },
  requestCounter: {
    id: 'user.account.requestCounter',
    defaultMessage: 'Request counter',
    description: 'label for request counter',
  },
  expirationDate: {
    id: 'user.account.expirationDate',
    defaultMessage: 'Expiration date',
    description: 'label for expiration date',
  },
  overRequestLimit: {
    id: 'user.account.overRequestLimit',
    defaultMessage: 'Available requests exceed the monthly request limit!',
    description: 'Notification for exceeded request limit',
  },
  noKey: {
    id: 'user.account.noKey',
    defaultMessage: 'You do not own a key for this API yet.',
    description: 'label no key message',
  },
  loading: {
    id: 'user.account.loading',
    defaultMessage: 'Loading...',
    description: 'label no key message',
  },
  upgradeLicense: {
    id: 'user.account.upgradeLicense',
    defaultMessage: 'Upgrade license',
    description: 'label for upgrade license text',
  },
  upgrade: {
    id: 'user.account.upgrade',
    defaultMessage: 'Upgrade',
    description: 'label for upgrade text',
  },
  upgradeSuccessMessage: {
    id: 'user.account.upgradeSuccessMessage',
    defaultMessage: `
      Thank you. An e-mail with your license upgrade request has been sent to us. We will come back to you.
    `,
    description: 'label for upgrade success date',
  },
  upgradeErrorMessage: {
    id: 'user.account.upgradeErrorMessage',
    defaultMessage: `
      There was an error sending your license upgrade request. Please try again!
    `,
    description: 'label for upgrade error date',
  },
});

export default messages;
