// @flow
import { ApolloClient } from 'apollo-client';
import { from, ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import apolloLogger from 'apollo-link-logger';
import Cookies from 'js-cookie';
import createCache from './createCache';

const link = from([
  new ApolloLink((operation, forward) => {
    const token = Cookies.get('id_token');
    const authHeader = token ? `Bearer ${token}` : null;
    operation.setContext({
      headers: {
        authorization: authHeader,
      },
    });

    return forward(operation);
  }),
  onError(test => {
    const { graphQLErrors, networkError, response } = test;
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message === 'license_expired') {
          response.errors = null;
        } else {
          console.warn(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        }
      });
    }

    if (networkError) console.warn(`[Network error]: ${networkError}`);
  }),
  ...(__DEV__ ? [apolloLogger] : []),
  new HttpLink({
    uri: '/graphql',
    credentials: 'include',
  }),
]);

const cache = createCache();

export default function createApolloClient() {
  return new ApolloClient({
    link,
    cache: cache.restore(window.App.apolloState),
    queryDeduplication: true,
    connectToDevTools: true,
  });
}
