import React from 'react';
import PropTypes from 'prop-types';
import { /* Query , */ graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Collapse } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  FaCaretUp,
  FaCaretDown,
  FaAngleLeft,
  FaAngleDoubleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
  FaEur,
  FaClockO,
  FaGraduationCap,
  FaGlobe,
  FaAngleDoubleUp,
  FaAngleDoubleDown,
} from 'react-icons/lib/fa';

import { personShape, filterShape } from '../../constants/types';

import Loading from '../../../../components/Loading';
import s from './ResultsList.scss'; // eslint-disable-line css-modules/no-unused-class
import globalDemoMessages from '../../../globalDemoMessages';
import messages from './messages';
import searchMaskMessages from '../SearchMask/messages';
import { LearnMoreLink } from '../../../util';

const danubeTagsMap = {
  salaryLevel: messages.salaryLevelTag,
  prevEmployerCount: messages.numberOfPreviousEmployersTag,
  educationLevel: messages.educationTag,
  age: messages.ageTag,
  experienceGeneral: messages.experienceGeneralTag,
  experienceOffice: messages.experienceOfficeTag,
  msOfficeLevel: messages.experienceMsOfficeTag,
  germanLevel: messages.germanTag,
  englishLevel: messages.englishTag,
  otherLanguages: messages.otherLanguagesTag,
  additionalSkills: messages.additionalSkillsTag,
};

const rowIdToBestSlotMessage = {
  salaryLevel: messages.bestSlotSalaryLevelTag,
  experienceGeneral: messages.bestSlotExperienceGeneralTag,
  experienceOffice: messages.bestSlotExperienceOfficeTag,
  msOfficeLevel: messages.bestSlotMsOfficeLevelTag,
  germanLevel: messages.bestSlotGermanLevelTag,
  englishLevel: messages.bestSlotEnglishLevelTag,
  otherLanguages: messages.bestSlotOtherLanguagesTag,
};

const educationLevelMap = {
  'Level 1': searchMaskMessages.educationLevel1,
  'Level 2': searchMaskMessages.educationLevel2,
  'Level 3': searchMaskMessages.educationLevel3,
  'Level 4': searchMaskMessages.educationLevel4,
};

const prevEmployerCountMap = {
  '#0': 0,
  '#1': 1,
  '#2': 2,
  '#3': 3,
  '#4': 4,
  '#5': 5,
  '#6': 6,
  '#7': 7,
  '#8': 8,
  '#9': 9,
};

class ResultsList extends React.Component {
  static propTypes = {
    filter: filterShape,
    recruitingPersonsQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      recruitingPersons: PropTypes.shape({
        persons: PropTypes.arrayOf(personShape.isRequired),
        columnKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
        columnScores: PropTypes.arrayOf(PropTypes.number).isRequired,
      }),
    }),
    onLoadingFinished: PropTypes.func,
  };

  static defaultProps = {
    filter: {},
    recruitingPersonsQuery: null,
    onLoadingFinished: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
      itemsPerPage: 10,
      extendedEntries: {},
    };

    this.getLastPage = this.getLastPage.bind(this);
    this.toStart = this.toStart.bind(this);
    this.toEnd = this.toEnd.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.calculateLevelMaps = this.calculateLevelMaps.bind(this);
    this.renderButtonBar = this.renderButtonBar.bind(this);
    this.renderPerson = this.renderPerson.bind(this);
  }

  componentDidUpdate(prevProps) {
    // close all extended entries on new query and go back to page 1
    if (prevProps.filter !== this.props.filter) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        currentPage: 0,
        extendedEntries: {},
      });
    }

    if (
      prevProps.recruitingPersonsQuery &&
      prevProps.recruitingPersonsQuery.loading &&
      this.props.recruitingPersonsQuery &&
      !this.props.recruitingPersonsQuery.loading
    ) {
      this.props.onLoadingFinished();
    }

    if (!this.props.recruitingPersonsQuery) return;

    const {
      recruitingPersonsQuery: { loading },
    } = this.props;

    if (loading) return;

    const { currentPage } = this.state;

    // clamp page to last page
    const lastPage = this.getLastPage();
    if (currentPage > lastPage) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentPage: lastPage });
    }
  }

  getLastPage() {
    const { recruitingPersonsQuery } = this.props;

    if (recruitingPersonsQuery.loading) return 0;
    if (!recruitingPersonsQuery.recruitingPersons) return 0;

    return Math.floor(
      Math.max(0, recruitingPersonsQuery.recruitingPersons.persons.length - 1) /
        this.state.itemsPerPage,
    );
  }

  toStart() {
    this.setState({
      currentPage: 0,
    });
  }

  toEnd() {
    const {
      recruitingPersonsQuery: { loading },
    } = this.props;

    if (loading) return;

    this.setState({
      currentPage: this.getLastPage(),
    });
  }

  nextPage() {
    this.setState({
      currentPage: Math.min(this.getLastPage(), this.state.currentPage + 1),
    });
  }

  prevPage() {
    this.setState({
      currentPage: Math.max(0, this.state.currentPage - 1),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  calculateLevelMaps(persons, fields) {
    const levelsMap = fields.reduce((map, field) => {
      persons.forEach(person => {
        if (field in person) {
          if (field in map) {
            if (person[field] < map[field].min) {
              map[field].min = person[field]; // eslint-disable-line no-param-reassign
            }
            if (person[field] > map[field].max) {
              map[field].max = person[field]; // eslint-disable-line no-param-reassign
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            map[field] = {
              min: person[field],
              max: person[field],
            };
          }
        }
      });
      return map;
    }, {});

    const fieldFormatter = (person, field, inverseColor, inverseLabel) => {
      const percentageValue =
        (person[field] - levelsMap[field].min) /
        (levelsMap[field].max - levelsMap[field].min);

      // get css-class for match score container
      let matchScoreClass = '';
      let message = null;

      const colors = [s.green, s.yellow, s.red];
      const labels = [
        globalDemoMessages.veryHigh,
        globalDemoMessages.high,
        globalDemoMessages.average,
        globalDemoMessages.low,
        globalDemoMessages.veryLow,
      ];

      if (percentageValue >= 0.8) {
        matchScoreClass = inverseColor ? colors[2] : colors[0];
        message = inverseLabel ? labels[4] : labels[0];
      } else if (percentageValue >= 0.6) {
        matchScoreClass = inverseColor ? colors[2] : colors[0];
        message = inverseLabel ? labels[3] : labels[1];
      } else if (percentageValue >= 0.4) {
        matchScoreClass = colors[1];
        message = labels[2];
      } else if (percentageValue >= 0.2) {
        matchScoreClass = inverseColor ? colors[0] : colors[2];
        message = inverseLabel ? labels[1] : labels[3];
      } else {
        matchScoreClass = inverseColor ? colors[0] : colors[2];
        message = inverseLabel ? labels[0] : labels[4];
      }

      return (
        <span className={matchScoreClass}>
          <FormattedMessage {...message} />
        </span>
      );
    };

    return fieldFormatter;
  }

  renderButtonBar() {
    return (
      <div className={s.buttonBar}>
        <button className="btn-primary" onClick={this.toStart}>
          <FaAngleDoubleLeft />
        </button>
        <button className="btn-primary" onClick={this.prevPage}>
          <FaAngleLeft />
        </button>
        <div className={s.pageCounter}>
          {this.state.currentPage + 1} / {this.getLastPage() + 1}
        </div>
        <button className="btn-primary" onClick={this.nextPage}>
          <FaAngleRight />
        </button>
        <button className="btn-primary" onClick={this.toEnd}>
          <FaAngleDoubleRight />
        </button>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderPerson({
    person,
    index,
    perfectDanubeScore,
    fieldFormatter,
    naiveRank,
    naiveScore, // eslint-disable-line no-unused-vars
    perfectNaiveScore, // eslint-disable-line no-unused-vars
    columnKeys,
  }) {
    // calculate match score and map it to [0, 100]
    let matchScore = parseFloat(person.danubeScore / perfectDanubeScore);
    matchScore = Math.max(0, Math.min(100, Math.round(matchScore * 100)));

    // get css-class for match score container
    let matchScoreClass = '';
    if (matchScore >= 50) {
      matchScoreClass = s.optimalScore;
    } else if (matchScore >= 33) {
      matchScoreClass = s.goodScore;
    } else {
      matchScoreClass = s.mediumScore;
    }

    /*
    // calculate naive match score and map it to [0, 100]
    let naiveMatchScore = parseFloat(naiveScore / perfectNaiveScore);
    naiveMatchScore = Math.max(0, Math.min(100, Math.round(naiveMatchScore * 100))); // eslint-disable-line prettier/prettier

    // get css-class for naive match score container
    let naiveMatchScoreClass = '';
    if (naiveMatchScore >= 50) {
      naiveMatchScoreClass = s.optimalScore;
    } else if (naiveMatchScore >= 33) {
      naiveMatchScoreClass = s.goodScore;
    } else {
      naiveMatchScoreClass = s.mediumScore;
    }
    */

    const naiveRankDifference = naiveRank - index;

    return (
      <div key={person.name} className={s.listItemContainer}>
        <div className={s.contentWrapper}>
          <div className={s.infoContainer}>
            <div className={s.scoreWrapper}>
              <span className={s.titleContainer}>
                <span>
                  {index + 1}. {person.name}
                </span>
                {naiveRankDifference >= 3 && (
                  <span className={`${s.hiddenTag} ${s.hideOnMobile}`}>
                    <FaAngleDoubleUp style={{ marginBottom: '2px' }} />
                    <FormattedMessage {...messages.hidden} />
                  </span>
                )}
                {naiveRankDifference <= -3 && (
                  <span className={`${s.overratedTag} ${s.hideOnMobile}`}>
                    <FaAngleDoubleDown style={{ marginBottom: '1px' }} />
                    <FormattedMessage {...messages.overrated} />
                  </span>
                )}
                {/*
                <span
                  className={naiveMatchScoreClass}
                  style={{ fontSize: '14px' }}
                >
                  ({naiveMatchScore}% Match)
                </span>
                <span style={{ float: 'right' }}>&nbsp;|&nbsp;</span>
                */}
                <span className={matchScoreClass}>
                  {matchScore}% Match
                  <br />
                  <LearnMoreLink to="/demos/recruiting/about" />
                </span>
              </span>
            </div>
            <div>
              {naiveRankDifference >= 3 && (
                <span
                  className={`${s.hiddenTag} ${s.hideOnDesktop}`}
                  style={{ marginLeft: '0' }}
                >
                  <FaAngleDoubleUp style={{ marginBottom: '2px' }} />
                  <FormattedMessage {...messages.hidden} />
                </span>
              )}
              {naiveRankDifference <= -3 && (
                <span
                  className={`${s.overratedTag} ${s.hideOnDesktop}`}
                  style={{ marginLeft: '0' }}
                >
                  <FaAngleDoubleDown style={{ marginBottom: '1px' }} />
                  <FormattedMessage {...messages.overrated} />
                </span>
              )}
            </div>
            <div className={s.bestSlotTagsContainer}>
              {person.matches.map((match, pIndex) => {
                if (match !== 1) return null;
                const columnKey = columnKeys[pIndex];

                if (
                  columnKey === 'salaryLevel' ||
                  columnKey === 'experienceGeneral' ||
                  columnKey === 'experienceOffice' ||
                  columnKey === 'msOfficeLevel' ||
                  columnKey === 'germanLevel' ||
                  columnKey === 'englishLevel' ||
                  columnKey === 'otherLanguages'
                ) {
                  return (
                    <div
                      key={`bestSlot-${columnKey}`}
                      className={s.bestSlotTag}
                    >
                      <FormattedMessage
                        {...rowIdToBestSlotMessage[columnKey]}
                      />
                    </div>
                  );
                }

                return null;
              })}
            </div>
            <div className={s.infoLine}>
              <div>
                <FaEur />
              </div>
              {'salaryLevel' in person && (
                <div>
                  <b>
                    <FormattedMessage {...messages.salaryLevelTag} />:
                  </b>
                  &nbsp;
                  {fieldFormatter(person, 'salaryLevel', true)}
                </div>
              )}
              <div>|</div>
              {'prevEmployerCount' in person && (
                <div>
                  <b>
                    <FormattedMessage
                      {...messages.numberOfPreviousEmployersTag}
                    />
                    :
                  </b>
                  &nbsp;
                  {prevEmployerCountMap[person.prevEmployerCount]}
                </div>
              )}
            </div>
            <div className={s.infoLine}>
              <div>
                <FaGraduationCap />
              </div>
              {'educationLevel' in person && (
                <div>
                  <b>
                    <FormattedMessage {...messages.educationTag} />:
                  </b>
                  &nbsp;
                  <FormattedMessage
                    {...educationLevelMap[person.educationLevel]}
                  />
                </div>
              )}
              <div>|</div>
              {'age' in person && (
                <div>
                  <b>
                    <FormattedMessage {...messages.ageTag} />:
                  </b>
                  &nbsp;
                  {person.age}
                  &nbsp;
                  <FormattedMessage {...globalDemoMessages.years} />
                </div>
              )}
            </div>
            <div className={s.infoLine}>
              <div>
                <FaClockO />
              </div>
              {'experienceGeneral' in person && (
                <div>
                  <b>
                    <FormattedMessage {...messages.experienceGeneralTag} />:
                  </b>
                  &nbsp;
                  {person.experienceGeneral}
                  &nbsp;
                  <FormattedMessage {...globalDemoMessages.years} />
                </div>
              )}
              <div>|</div>
              {'experienceOffice' in person && (
                <div>
                  <b>
                    <FormattedMessage {...messages.experienceOfficeTag} />:
                  </b>
                  &nbsp;
                  {person.experienceOffice}
                  &nbsp;
                  <FormattedMessage {...globalDemoMessages.years} />
                </div>
              )}
              <div>|</div>
              {'msOfficeLevel' in person && (
                <div>
                  <b>
                    <FormattedMessage {...messages.experienceMsOfficeTag} />:
                  </b>
                  &nbsp;
                  {fieldFormatter(person, 'msOfficeLevel')}
                </div>
              )}
            </div>
            <div className={s.infoLine}>
              <div>
                <FaGlobe />
              </div>
              {'germanLevel' in person && (
                <div>
                  <b>
                    <FormattedMessage {...messages.germanTag} />:
                  </b>
                  &nbsp;
                  {fieldFormatter(person, 'germanLevel')}
                </div>
              )}
              <div>|</div>
              {'englishLevel' in person && (
                <div>
                  <b>
                    <FormattedMessage {...messages.englishTag} />:
                  </b>
                  &nbsp;
                  {fieldFormatter(person, 'englishLevel')}
                </div>
              )}
              <div>|</div>
              {'otherLanguages' in person && (
                <div>
                  <b>
                    <FormattedMessage {...messages.otherLanguagesTag} />:
                  </b>
                  &nbsp;
                  {person.otherLanguages}
                </div>
              )}
            </div>
          </div>
          <div className={s.benefitsContainer}>
            <button
              onClick={() => {
                const newExtendedEntries = { ...this.state.extendedEntries };
                newExtendedEntries[person.name] = !newExtendedEntries[person.name]; // eslint-disable-line
                this.setState({
                  extendedEntries: newExtendedEntries,
                });
              }}
            >
              {this.state.extendedEntries[person.name] ? (
                <FaCaretUp style={{ marginLeft: '2px', marginRight: '15px' }} />
              ) : (
                <FaCaretDown
                  style={{ marginLeft: '2px', marginRight: '15px' }}
                />
              )}
              <b>
                <FormattedMessage {...messages.additionalSkillsTag} />:
              </b>
              &nbsp;
              <span>
                {'additionalSkills' in person
                  ? person.additionalSkills.length
                  : 0}
              </span>
            </button>
            <Collapse in={this.state.extendedEntries[person.name]}>
              <div>
                <ul>
                  {'additionalSkills' in person &&
                    person.additionalSkills.map(skill => (
                      <li key={skill} className={s.tag}>
                        {skill}
                      </li>
                    ))}
                </ul>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { recruitingPersonsQuery } = this.props;

    if (!recruitingPersonsQuery) return null;

    if (recruitingPersonsQuery.loading) {
      return <Loading />;
    }

    if (!recruitingPersonsQuery.recruitingPersons) return null;

    const {
      recruitingPersons: { persons, columnKeys, columnScores },
    } = recruitingPersonsQuery;

    // select page of persons
    const page = persons.slice(
      this.state.currentPage * this.state.itemsPerPage,
      (this.state.currentPage + 1) * this.state.itemsPerPage,
    );

    // normalize column scores
    const total = columnScores.reduce((sum, score) => sum + score, 0);
    const normalizedColumnScores = columnScores.map(
      score => (score / total) * 100,
    );

    // get max column score
    const maxColumnScore = Math.max(...normalizedColumnScores);

    // get the perfect danube score
    const perfectDanubeScore = columnScores.reduce(
      (scoreSum, score) => scoreSum + score,
      0,
    );

    // calculate a naive score where all columns are weighted with 1
    const naiveScores = persons
      .map(person => ({
        name: person.name,
        score: person.matches.reduce((sum, m) => sum + m, 0),
      }))
      .sort((person1, person2) => person2.score - person1.score);

    const naiveRanking = naiveScores.reduce((ranking, person, index) => {
      // eslint-disable-next-line no-param-reassign
      ranking[person.name] = {
        rank: index,
        score: person.score,
      };
      return ranking;
    }, {});

    const perfectNaiveScore = columnKeys.length;

    // get label fieldFormatter
    const fieldFormatter = this.calculateLevelMaps(persons, [
      'salaryLevel',
      'msOfficeLevel',
      'germanLevel',
      'englishLevel',
      'otherLanguages',
    ]);

    return (
      <div className={s.resultsListContainer}>
        <div className={s.metadataContainer}>
          <div className={s.resultsWrapper}>
            <span className={s.label}>
              {persons.length}
              &nbsp;
              <FormattedMessage {...globalDemoMessages.resultsFound} />
            </span>
          </div>
          <LearnMoreLink to="/demos/recruiting/about" />
          {normalizedColumnScores.length > 0 && (
            <div>
              {normalizedColumnScores.map((score, index) => (
                <div
                  key={`normalizedColumnScore-${index}`} // eslint-disable-line react/no-array-index-key
                  className={
                    score === maxColumnScore
                      ? s.danubeWrapper
                      : s.danubeWrapperLight
                  }
                >
                  <span className={s.label}>
                    <FormattedMessage {...danubeTagsMap[columnKeys[index]]} />
                    :&nbsp;
                    {Math.round(score)}%
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
        {this.renderButtonBar()}
        <div className={s.listItemsContainer}>
          {persons.length === 0 ? (
            <div className={s.noResultsContainer}>
              <FormattedMessage {...globalDemoMessages.noResults} />
            </div>
          ) : (
            <div>
              {page.map((person, index) => {
                const rowIndex =
                  this.state.currentPage * this.state.itemsPerPage + index;
                return this.renderPerson({
                  person,
                  index: rowIndex,
                  perfectDanubeScore,
                  fieldFormatter,
                  naiveRank: naiveRanking[person.name].rank,
                  naiveScore: naiveRanking[person.name].score,
                  perfectNaiveScore,
                  columnKeys,
                });
              })}
            </div>
          )}
        </div>
        {this.renderButtonBar()}
      </div>
    );
  }
}

const recruitingPersonsQuery = gql`
  query recruitingPersonsQuery($filter: RecruitingPersonsFilter) {
    recruitingPersons(filter: $filter) {
      persons {
        name
        salaryLevel
        prevEmployerCount
        educationLevel
        age
        experienceGeneral
        experienceOffice
        msOfficeLevel
        germanLevel
        englishLevel
        otherLanguages
        additionalSkills
        danubeScore
        matches
      }
      columnKeys
      columnScores
    }
  }
`;

export default compose(
  graphql(recruitingPersonsQuery, {
    name: 'recruitingPersonsQuery',
    options: ({ filter }) => ({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
    }),
    skip: ({ skipPageLoadQuery }) => skipPageLoadQuery,
  }),
)(withStyles(s)(ResultsList));
