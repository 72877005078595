import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'; // eslint-disable-line import/extensions

import s from './ComicTile.scss';

class ComicTile extends React.Component {
  static propTypes = {
    image: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    previous: PropTypes.bool.isRequired,
    next: PropTypes.bool.isRequired,
    opacity: PropTypes.number.isRequired,
    gotoPrevTile: PropTypes.func.isRequired,
    gotoNextTile: PropTypes.func.isRequired,
    onSkip: PropTypes.func.isRequired,
    showButtons: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <div className={s.comicTile} style={{ opacity: this.props.opacity }}>
        <div className={s.imageWrapper}>
          <img src={this.props.image} alt="" />
          <div className={s.fadeOverlay} />
        </div>
        <div className={s.panelContainer}>
          <div className={s.textContainer}>{this.props.text}</div>
          <div
            className={s.buttonsContainer}
            style={{ opacity: this.props.showButtons ? 1 : 0 }}
          >
            <button
              onClick={this.props.gotoPrevTile}
              style={{
                visibility: this.props.previous ? 'visible' : 'hidden',
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button onClick={this.props.onSkip}>SKIP</button>
            <button
              onClick={this.props.gotoNextTile}
              style={{ visibility: this.props.next ? 'visible' : 'hidden' }}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(ComicTile);
