import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import { to } from '../../util';
import { SuccessAlert, Alert } from '../Modal';
import s from './Contact.scss';
import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import ContactForm, { inquiryFields } from './ContactForm';
import messages from './messages';
import Loading from '../Loading';
import { componentKeys } from '../Analytics';

class Contact extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
    emailAddressData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      emailAddress: PropTypes.string,
    }).isRequired,
    sendContactMail: PropTypes.func.isRequired,
    logClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      alertOpts: {
        showError: false,
        showSuccess: false,
      },
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  hideAlert() {
    this.setState({
      alertOpts: {
        showError: false,
        showSuccess: false,
      },
    });
  }

  async handleSubmit(formValues) {
    const { sendContactMail, logClick } = this.props;
    const contactData = { ...formValues };

    if (contactData.inquiry) contactData.inquiry = contactData.inquiry.value;
    if (contactData.answer1) contactData.answer1 = contactData.answer1.value;
    if (contactData.answer2) contactData.answer2 = contactData.answer2.value;
    if (contactData.answer3) contactData.answer3 = contactData.answer3.value;

    const [err, result] = await to(sendContactMail(contactData));

    if (err || (result && !result.data.sendContactMail)) {
      console.error(err);
      this.setState({
        alertOpts: {
          showError: true,
        },
      });
      return;
    }

    let inqueryKey;

    switch (contactData.inquiry) {
      case 'media':
        inqueryKey = componentKeys.MEDIA_INQUIRY_EMAIL;
        break;
      case 'eCommerce':
        inqueryKey = componentKeys.ECOMMERCE_INQUIRY_EMAIL;
        break;
      case 'recruiting':
        inqueryKey = componentKeys.RECRUITING_INQUIRY_EMAIL;
        break;
      case 'otherfield':
        inqueryKey = componentKeys.OTHER_FIELD_INQUIRY_EMAIL;
        break;
      default:
        inqueryKey = componentKeys.OTHER_FIELD_INQUIRY_EMAIL;
        break;
    }

    to(logClick({ componentKey: inqueryKey }));

    this.setState({
      alertOpts: {
        showSuccess: true,
      },
    });
  }

  render() {
    const { intl, history, emailAddressData } = this.props;
    const { alertOpts } = this.state;

    if (emailAddressData.loading) return <Loading />;

    let inquiry = null;

    if (history && history.location && history.location.search) {
      let { search } = history.location;
      search = search.slice(1); // remove '?'
      const searchParamsStrings = search.split('&');
      const searchParams = searchParamsStrings.map(sps => sps.split('='));
      const inquirySearchParam = searchParams.find(sp => sp[0] === 'inquiry');
      if (inquirySearchParam) inquiry = inquirySearchParam[1];
    }

    const initialValues = { country: 'Austria' };

    if (inquiry && inquiryFields.includes(inquiry)) {
      initialValues.inquiry = {
        value: inquiry,
        label: intl.formatMessage(messages[inquiry]),
      };
    } else {
      initialValues.inquiry = {
        value: 'otherfield',
        label: intl.formatMessage(messages.otherfield),
      };
    }

    return (
      <div className={`${s.marginRemover}`}>
        <div className={s.headerImageContainer}>
          <div className={s.content}>
            <h1>
              <FormattedMessage {...messages.heading} />
            </h1>
          </div>
          <div className={s.bgImageWrapper}>
            <img src="/profiles/Team_2000x1040.jpg" alt="" />
          </div>
        </div>
        <div>
          <ContactForm
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            emailAddress={emailAddressData.emailAddress}
          />
          <SuccessAlert
            show={alertOpts.showSuccess}
            title={<FormattedMessage {...messages.alertSuccessTitle} />}
            msg={<FormattedMessage {...messages.alertSuccessMessage} />}
            hide={this.hideAlert}
            footer={
              <button
                className="btn btn-white-orange btn-full-round btn-threeQuartersWidth btn-high"
                style={{ display: 'block' }}
                onClick={() => history.push({ pathname: '/' })}
              >
                OK
              </button>
            }
          />
          <Alert
            show={alertOpts.showError}
            title={<FormattedMessage {...messages.alertErrorTitle} />}
            msg={<FormattedMessage {...messages.alertErrorMessage} />}
            hide={this.hideAlert}
          />
        </div>
      </div>
    );
  }
}

const emailAddressQuery = gql`
  query emailAddress {
    emailAddress
  }
`;

const sendContactMail = gql`
  mutation sendContactMail($contact: Contact) {
    sendContactMail(contact: $contact)
  }
`;

const logClickMutation = gql`
  mutation logClickMutation($payload: ClickLogInput!) {
    createOrUpdateClickLog(payload: $payload) {
      id
    }
  }
`;

export default compose(
  withStyles(layoutStyle, s),
  withRouter,
  graphql(emailAddressQuery, {
    name: 'emailAddressData',
  }),
  graphql(sendContactMail, {
    props: ({ mutate }) => ({
      sendContactMail: contact =>
        mutate({
          variables: { contact },
        }),
    }),
  }),
  graphql(logClickMutation, {
    props: ({ mutate }) => ({
      logClick: payload =>
        mutate({
          variables: { payload },
        }),
    }),
  }),
)(injectIntl(Contact));
