import { defineMessages } from 'react-intl';

const messages = defineMessages({
  recommendationComponentHeader: {
    id: 'recommendationComponent.header',
    defaultMessage: 'Individualized recommendations for you',
    description: 'Message for recommendation component',
  },
});

export default messages;
