export const COMP_TYPES = {
  STARTUP: 'Startup',
  DIGITALE_AGENTUR: 'Digitale Agentur',
  ETABLIERTE_FIRMA: 'Etablierte Firma',
};

export const JOB_LEVELS = {
  JUNIOR: 'Junior',
  ERFAHREN: 'Erfahren',
  SENIOR: 'Senior',
  LEAD: 'Lead',
};
