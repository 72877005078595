import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

const logHashQuery = gql`
  query logHashQuery($hash: String!) {
    logHash(hash: $hash) {
      hash
      pageKey
    }
  }
`;

const AccessTrackWrapper = ({ pageKey }) => WrappedComponent => {
  class WithAccessTracking extends React.Component {
    static contextTypes = {
      client: PropTypes.object.isRequired,
    };

    static propTypes = {
      logAccess: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);
      this.onMount = this.onMount.bind(this);
    }

    componentDidMount() {
      this.onMount();
    }

    async onMount() {
      const { logAccess } = this.props;

      let hash = null;
      const search = window.location.search.substring(1);
      const searchParams = search.split('&');
      for (let i = 0; i < searchParams.length; i += 1) {
        const searchParam = searchParams[i];
        if (searchParam.startsWith('token=')) {
          const paramAndValue = searchParam.split('=');
          hash = paramAndValue[1];
          break;
        }
      }

      if (!pageKey) {
        // eslint-disable-next-line no-console
        console.warn(
          'Trying to log access for page but no page key was provided.',
        );
        return;
      }

      if (hash != null) {
        try {
          const result = await this.context.client.query({
            query: logHashQuery,
            variables: {
              hash,
            },
            fetchPolicy: 'network-only',
          });

          if (!result || !result.data || !result.data.logHash) {
            console.warn(
              'No log hash found for this token. Writing default page access instead.',
            );
            logAccess({ pageKey });
          } else if (result.data.logHash.pageKey !== pageKey) {
            console.warn(
              'Page key of found log hash does not match with this page. Writing default page access instead.',
            );
            logAccess({ pageKey });
          } else {
            logAccess({
              pageKey: hash,
              metaData: `hashed access log for ${pageKey}`,
            });
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(
            'Error while loading log hash. Writing default page access instead:',
            e.message,
          );
          logAccess({ pageKey });
        }
      } else {
        logAccess({ pageKey });
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const logAccessMutation = gql`
    mutation logAccessMutation($payload: AccessLogInput!) {
      createOrUpdateAccessLog(payload: $payload) {
        id
      }
    }
  `;

  return compose(
    graphql(logAccessMutation, {
      props: ({ mutate }) => ({
        logAccess: payload =>
          mutate({
            variables: { payload },
          }),
      }),
    }),
  )(WithAccessTracking);
};

export default AccessTrackWrapper;
