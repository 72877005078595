import React from 'react';

import PageProperties from 'components/PageProperties';
import routeMessages from 'routes/messages';
import ShowcaseDevjobs from '../../../components/ShowcaseDevjobs';

class ShowcaseDevjobsWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          key="showcaseDevjobs-page-properties"
          page={{
            title: routeMessages.showcaseDevjobsTitle,
            description: routeMessages.showcaseDevjobsDescription,
            keywords: routeMessages.showcaseDevjobsKeywords,
          }}
        />
        <ShowcaseDevjobs />
      </div>
    );
  }
}

export default ShowcaseDevjobsWrapper;
