import React from 'react';

import PageProperties from 'components/PageProperties';
import ECommerceLandingPage from 'components/LandingPage/SubLandingPages/ECommerceLandingPage';
import routeMessages from 'routes/messages';

class ECommerceLandingPageWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.landingPageTitle,
            description: routeMessages.landingPageDescription,
            keywords: routeMessages.landingPageKeywords,
          }}
        />
        <ECommerceLandingPage />
      </div>
    );
  }
}

export default ECommerceLandingPageWrapper;
