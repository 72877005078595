import React from 'react';

import PageProperties from 'components/PageProperties';
import RecruitingDemo from 'demos/recruiting/components/LandingPage';
import routeMessages from 'routes/messages';

class RecruitingDemoWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.demoPageTitle,
            description: routeMessages.demoDescription,
            keywords: routeMessages.demoKeywords,
          }}
        />
        <RecruitingDemo />
      </div>
    );
  }
}

export default RecruitingDemoWrapper;
