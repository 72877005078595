import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { injectIntl, intlShape } from 'react-intl';

import Loading from '../../../../components/Loading';
import SuggestionsModal from '../SuggestionsModal';
import ResultsList from './ResultsList';
import { filterType, apartmentType } from './types';
import { optionParameterNamesMap } from '../SearchMask/SearchMaskForm';

class ResultsListWrapper extends React.Component {
  static propTypes = {
    filter: filterType,
    skipPageLoadQuery: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
    sessionId: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    apartmentQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      apartments: PropTypes.shape({
        apartments: PropTypes.arrayOf(apartmentType),
        columnScores: PropTypes.arrayOf(PropTypes.number),
        correlatedEntryFields: PropTypes.arrayOf(PropTypes.any),
        crIdx: PropTypes.number,
        crResult: PropTypes.shape({
          apartments: PropTypes.arrayOf(apartmentType),
          columnScores: PropTypes.arrayOf(PropTypes.number),
        }),
      }),
    }),
    intl: intlShape.isRequired,
    changeFieldValue: PropTypes.func.isRequired,
    onLoadingFinished: PropTypes.func,
  };

  static defaultProps = {
    filter: {},
    skipPageLoadQuery: true,
    apartmentQuery: null,
    onLoadingFinished: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      showSuggestionsModal: false,
      useSuggestedResultList: false,
    };

    this.applyCurrentSuggestion = this.applyCurrentSuggestion.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.apartmentQuery &&
      prevProps.apartmentQuery.loading &&
      this.props.apartmentQuery &&
      !this.props.apartmentQuery.loading
    ) {
      this.props.onLoadingFinished();
    }

    if (!this.props.apartmentQuery || this.props.apartmentQuery.loading) return;

    if (this.state.useSuggestedResultList && this.ownPrevProps !== this.props) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ useSuggestedResultList: false });
    }

    // show suggestions modal
    if (
      this.props.filter.showSuggestions &&
      this.ownPrevProps !== this.props &&
      this.props.apartmentQuery &&
      this.props.apartmentQuery.apartments &&
      this.props.apartmentQuery.apartments.correlatedEntryFields
    ) {
      const {
        apartments: { correlatedEntryFields, crIdx },
      } = this.props.apartmentQuery;
      if (
        crIdx !== -1 &&
        correlatedEntryFields &&
        correlatedEntryFields.length > 0
      ) {
        let newZip = null;
        let newPriceTo = null;
        correlatedEntryFields[crIdx].forEach(field => {
          const parsedField = JSON.parse(field);
          if (parsedField.field === 'localization_address_zip') {
            newZip = parsedField.value;
          } else if (parsedField.field === 'priceInformation_primaryPriceTo') {
            newPriceTo = parsedField.value;
          }
        });

        const { filter } = this.props;
        const zips = filter.localization_address_zip || null;

        const showModal =
          !zips ||
          !zips.includes(newZip) ||
          newPriceTo > filter.priceInformation_primaryPriceTo;

        if (showModal) {
          this.modalTimeout = setTimeout(() => {
            this.setState({ showSuggestionsModal: true });
          }, 3000);
        }
      }
      this.ownPrevProps = this.props; // own hacky prevProps object to prevent endless updates
    }
  }

  componentWillUnmount() {
    if (this.modalTimeout) clearTimeout(this.modalTimeout);
  }

  applyCurrentSuggestion() {
    const {
      filter,
      apartmentQuery: {
        apartments: { correlatedEntryFields, crIdx },
      },
      intl,
      changeFieldValue,
    } = this.props;

    if (crIdx === -1) return;

    const parsedFields = correlatedEntryFields[crIdx].map(field =>
      JSON.parse(field),
    );

    parsedFields.forEach(field => {
      const fieldName = field.field;
      let fieldValue = field.value;

      if (fieldName === 'localization_address_zip') {
        const zips = filter.localization_address_zip || [];
        if (!zips.includes(field.value)) {
          zips.push(field.value);
        }
        fieldValue = zips.map(zip => ({
          value: zip,
          label: zip,
        }));
      } else if (fieldName === 'type_transferType') {
        fieldValue = {
          value: field.value,
          label: intl.formatMessage(optionParameterNamesMap[field.value]),
        };
      }

      changeFieldValue('immoSearchMask', `filter.${fieldName}`, fieldValue);
    });

    this.setState({ useSuggestedResultList: true });
  }

  render() {
    const { filter, apartmentQuery } = this.props;

    if (!apartmentQuery) return null;

    if (apartmentQuery.loading) {
      return <Loading />;
    }
    if (!apartmentQuery.apartments) return <div />;

    const {
      apartments: { correlatedEntryFields, crIdx, crResult },
    } = apartmentQuery;

    let apartments;
    let columnScores;

    if (this.state.useSuggestedResultList) {
      apartments = crResult.apartments;
      columnScores = crResult.columnScores;
    } else {
      apartments = this.props.apartmentQuery.apartments.apartments;
      columnScores = this.props.apartmentQuery.apartments.columnScores;
    }

    // parse correlated entry fields
    const suggestedFields = correlatedEntryFields
      ? correlatedEntryFields.map(fields =>
          fields.map(field => JSON.parse(field)),
        )
      : [];

    return (
      <div style={{ padding: '30px 0' }}>
        <ResultsList
          filter={filter}
          apartmentsData={{
            apartments,
            columnScores,
          }}
        />
        <SuggestionsModal
          isOpen={this.state.showSuggestionsModal}
          onClose={() => {
            this.setState({ showSuggestionsModal: false });
            clearTimeout(this.modalTimeout);
          }}
          suggestedFields={suggestedFields}
          crIdx={crIdx}
          crResult={crResult}
          filter={filter}
          onApplySuggestion={this.applyCurrentSuggestion}
        />
      </div>
    );
  }
}

const apartmentQuery = gql`
  query apartmentQuery(
    $filter: ApartmentFilter
    $sessionId: String
    $sendFilterData: Boolean
    $showSuggestions: Boolean
  ) {
    apartments(
      filter: $filter
      sessionId: $sessionId
      sendFilterData: $sendFilterData
      showSuggestions: $showSuggestions
    ) {
      apartments {
        meta_exposeId
        localization_address_zip
        type_transferType
        priceInformation_primaryPrice
        area_numberOfRooms
        area_numberOfBalconies
        area_numberOfTerrace
        area_total_area
        priceSortedPosition
      }
      columnScores
      correlatedEntryFields
      crIdx
      crResult {
        apartments {
          meta_exposeId
          localization_address_zip
          type_transferType
          priceInformation_primaryPrice
          area_numberOfRooms
          area_numberOfBalconies
          area_numberOfTerrace
          area_total_area
          priceSortedPosition
        }
        columnScores
      }
    }
  }
`;

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (form, field, value) => dispatch(change(form, field, value)), // eslint-disable-line prettier/prettier
});

export default compose(
  graphql(apartmentQuery, {
    name: 'apartmentQuery',
    options: ({ filter, sessionId }) => ({
      variables: {
        filter,
        sessionId,
        sendFilterData: true,
        showSuggestions: filter.showSuggestions,
      },
      fetchPolicy: 'no-cache',
    }),
    skip: ({ skipPageLoadQuery }) => skipPageLoadQuery,
  }),
  connect(
    null,
    mapDispatchToProps,
  ),
)(injectIntl(ResultsListWrapper));
