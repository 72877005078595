/* eslint-disable prettier/prettier */

export const pageKeys = {
  LANDING_PAGE: 'LANDING_PAGE',
  LANDING_PAGE_MEDIA: 'LANDING_PAGE_MEDIA',
  LANDING_PAGE_ECOMMERCE: 'LANDING_PAGE_ECOMMERCE',
  LANDING_PAGE_RECRUITING: 'LANDING_PAGE_RECRUITING',
  MEDIA_DEMO: 'MEDIA_DEMO',
  ECOMMERCE_DEMO: 'ECOMMERCE_DEMO',
  RECRUITING_DEMO: 'RECRUITING_DEMO',
  CONTACT: 'CONTACT',
  REGISTRATION: 'REGISTRATION',
  GEIZHALS_SHOW_CASE: 'GEIZHALS_SHOW_CASE',
  DEVJOBS_SHOW_CASE: 'DEVJOBS_SHOW_CASE',
  PRICING: 'PRICING',
  CHERRY_TREE: 'CHERRY_TREE',
};

export const componentKeys = {
  MAIN_LANDING_PAGE_MEDIA_LINK: 'MAIN_LANDING_PAGE_MEDIA_LINK', // click on 'Media' link on main landing page
  MAIN_LANDING_PAGE_ECOMMERCE_LINK: 'MAIN_LANDING_PAGE_ECOMMERCE_LINK', // click on 'E-Commerce' link on main landing page
  MAIN_LANDING_PAGE_RECRUITING_LINK: 'MAIN_LANDING_PAGE_RECRUITING_LINK', // click on 'Recruting' link on main landing page
  MAIN_LANDING_PAGE_LEARN_MORE_BUTTON: 'MAIN_LANDING_PAGE_LEARN_MORE_BUTTON', // click on 'learn more' button on main landing page

  MEDIA_LANDING_PAGE_LEARN_MORE_BUTTON: 'MEDIA_LANDING_PAGE_LEARN_MORE_BUTTON', // click on 'learn more' button on media sub landing page
  MEDIA_LANDING_PAGE_CONTACT_BUTTON: 'MEDIA_LANDING_PAGE_CONTACT_BUTTON', // click on 'get in touch' button on media sub landing page
  MEDIA_DEMO_LINK: 'MEDIA_DEMO_LINK', // click on 'try demo' for media
  MEDIA_DEMO_CONTACT_BUTTON: 'MEDIA_DEMO_CONTACT_BUTTON', // click on 'try demo' for ecommerce

  ECOMMERCE_LANDING_PAGE_LEARN_MORE_BUTTON: 'ECOMMERCE_LANDING_PAGE_LEARN_MORE_BUTTON', // click on 'learn more' button on ecommerce sub landing page
  ECOMMERCE_LANDING_PAGE_CONTACT_BUTTON: 'ECOMMERCE_LANDING_PAGE_CONTACT_BUTTON', // click on 'get in touch' button on ecommerce sub landing page
  ECOMMERCE_DEMO_LINK: 'ECOMMERCE_DEMO_LINK', // click on 'try demo' for ecommerce
  ECOMMERCE_DEMO_CONTACT_BUTTON: 'ECOMMERCE_DEMO_CONTACT_BUTTON', // click on 'try demo' for ecommerce

  RECRUITING_LANDING_PAGE_LEARN_MORE_BUTTON: 'RECRUITING_LANDING_PAGE_LEARN_MORE_BUTTON', // click on 'learn more' button on recruiting sub landing page
  RECRUITING_LANDING_PAGE_CONTACT_BUTTON: 'RECRUITING_LANDING_PAGE_CONTACT_BUTTON', // click on 'get in touch' button on recruiting sub landing page
  RECRUITING_DEMO_LINK: 'RECRUITING_DEMO_LINK', // click on 'try demo' for recruiting
  RECRUITING_DEMO_CONTACT_BUTTON: 'RECRUITING_DEMO_CONTACT_BUTTON', // click on 'try demo' for ecommerce

  TECHNOLOGY_CONTACT_BUTTON: 'TECHNOLOGY_CONTACT_BUTTON',
  GET_API_KEY_BUTTON: 'GET_API_KEY_BUTTON',

  REQUEST_DEMO_BUTTON: 'REQUEST_DEMO_BUTTON',
  DOWNLOAD_WHITEPAPER_BUTTON: 'DOWNLOAD_WHITEPAPER_BUTTON',

  GEIZHALS_DOWNLOAD_CASE_STUDY_BUTTON: 'GEIZHALS_DOWNLOAD_CASE_STUDY_BUTTON',
  DEVJOBS_DOWNLOAD_CASE_STUDY_BUTTON: 'DEVJOBS_DOWNLOAD_CASE_STUDY_BUTTON',
  
  CHERRY_TREE_DOWNLOAD_WHITEPAPER_BUTTON: 'CHERRY_TREE_DOWNLOAD_WHITEPAPER_BUTTON',
  CHERRY_TREE_DOWNLOAD_SCIENTIFIC_PAPER_BUTTON: 'CHERRY_TREE_DOWNLOAD_SCIENTIFIC_PAPER_BUTTON',
  CHERRY_TREE_DOWNLOAD_PITCH_DECK_BUTTON: 'CHERRY_TREE_DOWNLOAD_PITCH_DECK_BUTTON',
  CHERRY_TREE_DOWNLOAD_ARTICLE_BUTTON: 'CHERRY_TREE_DOWNLOAD_ARTICLE_BUTTON',
};

export const eMailKeys = {
  MEDIA_INQUIRY_EMAIL: 'MEDIA_INQUIRY_EMAIL',
  ECOMMERCE_INQUIRY_EMAIL: 'ECOMMERCE_INQUIRY_EMAIL',
  RECRUITING_INQUIRY_EMAIL: 'RECRUITING_INQUIRY_EMAIL',
  OTHER_FIELD_INQUIRY_EMAIL: 'OTHER_FIELD_INQUIRY_EMAIL',
};

export const otherKeys = {
  REGISTRATIONS: 'REGISTRATIONS',
  ACTIVATIONS: 'ACTIVATIONS',
  CALIBRATIONS: 'CALIBRATIONS',
};

export const aggregatedKeys = {
  ALL_LEARN_MORE_BUTTONS: 'ALL_LEARN_MORE_BUTTONS',
  ALL_CONTACT_BUTTONS: 'ALL_CONTACT_BUTTONS',
  ALL_INQUIRY_MAILY: 'ALL_INQUIRY_MAILY',
};

export const aggregationData = {
  [aggregatedKeys.ALL_LEARN_MORE_BUTTONS]: {
    key: aggregatedKeys.ALL_LEARN_MORE_BUTTONS,
    aggregatedKeys: [
      componentKeys.MEDIA_LANDING_PAGE_LEARN_MORE_BUTTON,
      componentKeys.ECOMMERCE_LANDING_PAGE_LEARN_MORE_BUTTON,
      componentKeys.RECRUITING_LANDING_PAGE_LEARN_MORE_BUTTON,
    ],
  },
  [aggregatedKeys.ALL_CONTACT_BUTTONS]: {
    key: aggregatedKeys.ALL_CONTACT_BUTTONS,
    aggregatedKeys: [
      componentKeys.MEDIA_LANDING_PAGE_CONTACT_BUTTON,
      componentKeys.MEDIA_DEMO_CONTACT_BUTTON,
      componentKeys.ECOMMERCE_LANDING_PAGE_CONTACT_BUTTON,
      componentKeys.ECOMMERCE_DEMO_CONTACT_BUTTON,
      componentKeys.RECRUITING_LANDING_PAGE_CONTACT_BUTTON,
      componentKeys.RECRUITING_DEMO_CONTACT_BUTTON,
      componentKeys.TECHNOLOGY_CONTACT_BUTTON,
    ],
  },
  [aggregatedKeys.ALL_INQUIRY_MAILY]: {
    key: aggregatedKeys.ALL_INQUIRY_MAILY,
    aggregatedKeys: [
      eMailKeys.MEDIA_INQUIRY_EMAIL,
      eMailKeys.ECOMMERCE_INQUIRY_EMAIL,
      eMailKeys.RECRUITING_INQUIRY_EMAIL,
      eMailKeys.OTHER_FIELD_INQUIRY_EMAIL,
    ],
  },
};

/* eslint-enable prettier/prettier */
