import React from 'react';

import PageProperties from 'components/PageProperties';
import routeMessages from 'routes/messages';
import ShowcaseGeizhals from '../../../components/ShowcaseGeizhals';

class ShowcaseGeizhalsWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          key="showcaseGeizhals-page-properties"
          page={{
            title: routeMessages.showcaseGeizhalsTitle,
            description: routeMessages.showcaseGeizhalsDescription,
            keywords: routeMessages.showcaseGeizhalsKeywords,
          }}
        />
        <ShowcaseGeizhals />
      </div>
    );
  }
}

export default ShowcaseGeizhalsWrapper;
