import { defineMessages } from 'react-intl';

const messages = defineMessages({
  next: {
    id: 'global.next',
    defaultMessage: 'Next',
    description: 'Message for Next label.',
  },
  previous: {
    id: 'global.previous',
    defaultMessage: 'Previous',
    description: 'Message for Previous label.',
  },
  danubify: {
    id: 'global.danubify',
    defaultMessage: 'danubify',
    description: 'Message for Danubify label.',
  },
  getInTouch: {
    id: 'global.getInTouch',
    defaultMessage: 'Get in touch',
    description: 'Message for Get In Touch label',
  },
  cancel: {
    id: 'global.cancel',
    defaultMessage: 'Cancel',
    description: 'Message for Cancel label',
  },
  submit: {
    id: 'global.submit',
    defaultMessage: 'Submit',
    description: 'Message for Submit label',
  },
});

export default messages;
