import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { withRouter } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import FaChevronDown from 'react-icons/lib/fa/chevron-down';

import { componentKeys } from 'components/Analytics';

import s from '../ShowcaseTemplate/showcase.scss'; // eslint-disable-line css-modules/no-unused-class
import ShowcaseTemplate from '../ShowcaseTemplate/ShowcaseTemplate';
import StatisticsTemplate from '../ShowcaseTemplate/StatisticsTemplate';
import DownloadCaseStudy from '../DownloadCaseStudy';
import QuoteTemplate from '../ShowcaseTemplate/QuoteTemplate';
import geizhalsMessages from './geizhalsMessages';
import {
  GEIZHALS_SHOWCASE_FORM_IDS,
  GEIZHALS_SHOWCASE_DOCUMENTS,
} from '../../constants';

const scrollToSlide = slideRef => {
  const topOffset = window.innerWidth <= 1080 ? 75 : 100;

  window.scroll({
    top: slideRef.offsetTop - topOffset,
    left: 0,
    behavior: 'smooth',
  });
};

class GeizhalsShowcase extends React.Component {
  constructor(props) {
    super(props);

    this.frontPageRef = React.createRef();
    this.slide1Ref = React.createRef();
  }

  componentDidMount() {
    if (window.location.hash && window.location.hash === '#content') {
      scrollToSlide(this.slide1Ref.current);
    }
  }

  render() {
    return (
      <div>
        <ShowcaseTemplate
          pages={[
            <div className={`${s.showcasePageContent} ${s.firstPage}`}>
              <Row>
                <Col xs={12} sm={4}>
                  <StatisticsTemplate
                    text="3-7%"
                    mainLabel={
                      <FormattedMessage {...geizhalsMessages.statistics_1} />
                    }
                    subLabel={
                      <FormattedMessage
                        {...geizhalsMessages.industryStandard}
                        values={{ percent: '<1' }}
                      />
                    }
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <StatisticsTemplate
                    text="2-5%"
                    mainLabel={
                      <FormattedMessage {...geizhalsMessages.statistics_2} />
                    }
                    subLabel={
                      <FormattedMessage
                        {...geizhalsMessages.industryStandard}
                        values={{ percent: '<0.5' }}
                      />
                    }
                  />
                </Col>
                <Col xs={12} sm={4}>
                  <StatisticsTemplate
                    text="72%"
                    mainLabel={
                      <FormattedMessage {...geizhalsMessages.statistics_3} />
                    }
                    subLabel={
                      <FormattedMessage
                        {...geizhalsMessages.statisticsSublabel_3}
                      />
                    }
                  />
                </Col>
              </Row>
              <div>
                <p className={s.statisticsTitle}>
                  <FormattedMessage {...geizhalsMessages.statisticsTitle} />
                </p>
                <DownloadCaseStudy
                  localeToDocumentLinkMap={GEIZHALS_SHOWCASE_DOCUMENTS}
                  localeToFormIdMap={GEIZHALS_SHOWCASE_FORM_IDS}
                  clickTrackerComponentKey={
                    componentKeys.GEIZHALS_DOWNLOAD_CASE_STUDY_BUTTON
                  }
                />
              </div>
              <div className={s.downButtonContainer}>
                <button
                  onClick={() => {
                    scrollToSlide(this.slide1Ref.current);
                  }}
                >
                  <FaChevronDown />
                </button>
              </div>
            </div>,
            <div className={s.showcasePageContent} ref={this.slide1Ref}>
              <QuoteTemplate
                userImage="/profiles/Philipp_400x400.jpg"
                authorName={
                  <FormattedMessage
                    {...geizhalsMessages.quoteAttribution}
                    values={{ author: 'Philipp Wissgott' }}
                  />
                }
                quote={
                  <FormattedHTMLMessage {...geizhalsMessages.customerQuote} />
                }
              />
            </div>,
            <div className={s.showcasePageContent}>
              <p className={s.showcaseTitle}>
                <FormattedMessage {...geizhalsMessages.showcaseTextTitle} />
              </p>
              <div className={s.infographic}>
                <img src="/showcase_geizhals_screenshot.png" alt="" />
              </div>
              <Row className={s.showcaseText}>
                <Col className={s.row} md={5}>
                  <div>
                    <FormattedMessage {...geizhalsMessages.showcaseText_1} />
                  </div>
                  <div>
                    <FormattedMessage
                      {...geizhalsMessages.showcaseText_2}
                      values={{ danube: <Link to="/">danube.ai </Link> }}
                    />
                  </div>
                  <div>
                    <FormattedMessage {...geizhalsMessages.showcaseText_3} />
                  </div>
                </Col>

                <Col className={s.row} md={5} mdOffset={1}>
                  <div>
                    <FormattedMessage {...geizhalsMessages.showcaseText_4} />
                  </div>
                  <div>
                    <a href="https://geizhals.at/?cat=umtsover">
                      <FormattedMessage {...geizhalsMessages.showcaseText_5} />
                    </a>
                  </div>
                  <div>
                    <FormattedMessage {...geizhalsMessages.showcaseText_6} />
                  </div>
                </Col>
              </Row>
              <DownloadCaseStudy
                localeToDocumentLinkMap={GEIZHALS_SHOWCASE_DOCUMENTS}
                localeToFormIdMap={GEIZHALS_SHOWCASE_FORM_IDS}
                clickTrackerComponentKey={
                  componentKeys.GEIZHALS_DOWNLOAD_CASE_STUDY_BUTTON
                }
              />
            </div>,
          ]}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(s)(GeizhalsShowcase));
