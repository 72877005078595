import { defineMessages } from 'react-intl';

const messages = defineMessages({
  getApiKeyText: {
    id: 'api.otherPages.getApiKeyText',
    defaultMessage: `
      In order to use the API or SDK, you need an API Key. You can can contact us to request an API key.
    `,
    description: 'Text for api docs.',
  },
});

export default messages;
