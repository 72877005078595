import { defineMessages } from 'react-intl';

const messages = defineMessages({
  heading: {
    id: 'contact.heading',
    defaultMessage: 'Contact',
    description: 'Heading for contact form',
  },
  info: {
    id: 'contact.info',
    defaultMessage: `
      Please complete this form and we come back to you.
    `,
    description: 'Info text for contact form',
  },
  info2: {
    id: 'contact.info2',
    defaultMessage: `
      Didn't find what you were looking for?
    `,
    description: 'Info text for contact form',
  },
  required: {
    id: 'contact.required',
    defaultMessage: `
      Fields for dialog (* for required):
    `,
    description: 'required text for contact form',
  },
  email: {
    id: 'contact.email',
    defaultMessage: 'E-Mail Address',
    description: 'E-Mail label in contact form',
  },
  emailButton: {
    id: 'contact.emailButton',
    defaultMessage: 'Send e-mail',
    description: 'Label for the send e-mail button',
  },
  firstname: {
    id: 'contact.firstname',
    defaultMessage: 'First Name',
    description: 'First Name label in contact form',
  },
  lastname: {
    id: 'contact.lastname',
    defaultMessage: 'Last Name',
    description: 'lastname label in contact form',
  },
  companyname: {
    id: 'contact.companyname',
    defaultMessage: 'Company Name',
    description: 'Company Name label in contact form',
  },
  jobtitle: {
    id: 'contact.jobtitle',
    defaultMessage: 'Job Title',
    description: 'Job Title label in contact form',
  },
  phone: {
    id: 'contact.phone',
    defaultMessage: 'Phone Number',
    description: 'Phone Number label in contact form',
  },
  submit: {
    id: 'contact.submit',
    defaultMessage: 'Send',
    description: 'Submit button text in contact form',
  },
  country: {
    id: 'contact.country',
    defaultMessage: 'Country',
    description: 'Label for Country in contact form',
  },
  generalinquiry: {
    id: 'contact.generalinquiry',
    defaultMessage: 'General Inquiry',
    description: 'Label for generalinquiry in contact form',
  },
  media: {
    id: 'contact.media',
    defaultMessage: 'Media',
    description: 'Label for media in contact form & list entry',
  },
  recruiting: {
    id: 'contact.recruiting',
    defaultMessage: 'Recruiting',
    description: 'Label for recruiting in contact form & list entry',
  },
  eCommerce: {
    id: 'contact.eCommerce',
    defaultMessage: 'eCommerce',
    description: 'Label for eCommerce in contact form & list entry',
  },
  otherfield: {
    id: 'contact.otherfield',
    defaultMessage: 'Other field',
    description: 'Label for otherfield in contact form',
  },
  inquiry: {
    id: 'contact.inquiry',
    defaultMessage: 'What field are you interested in?',
    description: 'Label for Inquiry fields legend in contact form',
  },
  subject: {
    id: 'contact.subject',
    defaultMessage: 'Subject of inquiry',
    description: 'Label for Inquiry Subject legend in contact form',
  },
  comments: {
    id: 'contact.comments',
    defaultMessage: 'Comments',
    description: 'Label for comments in contact form',
  },
  alldata: {
    id: 'contact.alldata',
    defaultMessage: 'all your data',
    description: 'List entry in contact description',
  },
  sorting: {
    id: 'contact.sorting',
    defaultMessage: 'sorting',
    description: 'List2 entry in contact description',
  },
  rating: {
    id: 'contact.rating',
    defaultMessage: 'rating',
    description: 'List2 entry in contact description',
  },
  filtering: {
    id: 'contact.filtering',
    defaultMessage: 'filtering',
    description: 'List2 entry in contact description',
  },
  evaluating: {
    id: 'contact.evaluating',
    defaultMessage: 'evaluating',
    description: 'List2 entry in contact description',
  },
  complete: {
    id: 'contact.complete',
    defaultMessage: 'a complete data analysis',
    description: 'List2 entry in contact description',
  },
  for: {
    id: 'contact.for',
    defaultMessage: 'for',
    description: 'for in list entry in contact description',
  },
  alertSuccessTitle: {
    id: 'contact.alertSuccessTitle',
    defaultMessage: 'Success',
    description: 'Header for success alert dialog',
  },
  alertSuccessMessage: {
    id: 'contact.alertSuccessMessage',
    defaultMessage:
      'Your inquiry was sent. We will try to reply as soon as possible!',
    description: 'Message for success alert dialog',
  },
  alertErrorTitle: {
    id: 'contact.alertErrorTitle',
    defaultMessage: 'Error',
    description: 'Header for error alert dialog',
  },
  alertErrorMessage: {
    id: 'contact.alertErrorMessage',
    defaultMessage: 'Sending the inquiry failed, please try again!',
    description: 'Message for error alert dialog',
  },

  // recruiting questions and answers
  recruitingQ1: {
    id: 'contact.recruitingQ1',
    defaultMessage: `Are you looking for a recruiting tool for your own business?`,
    description: 'Recruiting question 1 in contact form',
  },
  recruitingQ1A1: {
    id: 'contact.recruitingQ1A1',
    defaultMessage: 'Yes',
    description: 'Recruiting question 1 answer 1 in contact form',
  },
  recruitingQ1A2: {
    id: 'contact.recruitingQ1A2',
    defaultMessage: 'No, we are a job agency',
    description: 'Recruiting question 1 answer 2 in contact form',
  },
  recruitingQ1A3: {
    id: 'contact.recruitingQ1A3',
    defaultMessage: `No, it's something else`,
    description: 'Recruiting question 1 answer 3 in contact form',
  },
  recruitingQ2: {
    id: 'contact.recruitingQ2',
    defaultMessage: `How can we help you with recruiting?`,
    description: 'Recruiting question 2 in contact form',
  },
  recruitingQ2A1: {
    id: 'contact.recruitingQ2A1',
    defaultMessage: 'I have a problem finding candidates',
    description: 'Recruiting question 2 answer 1 in contact form',
  },
  recruitingQ2A2: {
    id: 'contact.recruitingQ2A2',
    defaultMessage: `In my candidate pool, I'd like to find the right talents`,
    description: 'Recruiting question 2 answer 2 in contact form',
  },
  recruitingQ2A3: {
    id: 'contact.recruitingQ2A3',
    defaultMessage: 'I have another problem',
    description: 'Recruiting question 2 answer 3 in contact form',
  },
  recruitingQ3: {
    id: 'contact.recruitingQ3',
    defaultMessage: `Do you already use a recruiting/HR software tool?`,
    description: 'Recruiting question 3 in contact form',
  },
  recruitingQ3A1: {
    id: 'contact.recruitingQ3A1',
    defaultMessage: 'Yes',
    description: 'Recruiting question 3 answer 1 in contact form',
  },
  recruitingQ3A2: {
    id: 'contact.recruitingQ3A2',
    defaultMessage: 'No',
    description: 'Recruiting question 3 answer 2 in contact form',
  },
  recruitingQ3A3: {
    id: 'contact.recruitingQ3A3',
    defaultMessage: `I don't know`,
    description: 'Recruiting question 3 answer 3 in contact form',
  },

  // media questions and answers
  mediaQ1: {
    id: 'contact.mediaQ1',
    defaultMessage: `How can we help you with recommendations?`,
    description: 'Media question 1 in contact form',
  },
  mediaQ1A1: {
    id: 'contact.mediaQ1A1',
    defaultMessage: `I'd like to increase the time on site of my readers`,
    description: 'Media question 1 answer 1 in contact form',
  },
  mediaQ1A2: {
    id: 'contact.mediaQ1A2',
    defaultMessage: `I'd like to increase the traffic on my site`,
    description: 'Media question 1 answer 2 in contact form',
  },
  mediaQ1A3: {
    id: 'contact.mediaQ1A3',
    defaultMessage: `I'd like to inrease the return rate on my site`,
    description: 'Media question 1 answer 3 in contact form',
  },
  mediaQ1A4: {
    id: 'contact.mediaQ1A4',
    defaultMessage: `I'm interested to raise multiple KPIs of my site`,
    description: 'Media question 1 answer 4 in contact form',
  },
  mediaQ2: {
    id: 'contact.mediaQ2',
    defaultMessage: `What type of content do you have?`,
    description: 'Media question 2 in contact form',
  },
  mediaQ2A1: {
    id: 'contact.mediaQ2A1',
    defaultMessage: 'My content changes daily',
    description: 'Media question 2 answer 1 in contact form',
  },
  mediaQ2A2: {
    id: 'contact.mediaQ2A2',
    defaultMessage: `My content changes monthly`,
    description: 'Media question 2 answer 2 in contact form',
  },
  mediaQ2A3: {
    id: 'contact.mediaQ2A3',
    defaultMessage: 'My content changes once in a while',
    description: 'Media question 2 answer 3 in contact form',
  },
  mediaQ3: {
    id: 'contact.mediaQ3',
    defaultMessage: `What range does your media have?`,
    description: 'Media question 3 in contact form',
  },
  mediaQ3A1: {
    id: 'contact.mediaQ3A1',
    defaultMessage: 'I want to use danube with a national range',
    description: 'Media question 3 answer 1 in contact form',
  },
  mediaQ3A2: {
    id: 'contact.mediaQ3A2',
    defaultMessage: 'I want to use danube with an international range',
    description: 'Media question 3 answer 2 in contact form',
  },
  mediaQ3A3: {
    id: 'contact.mediaQ3A3',
    defaultMessage: `I want to use danube with a regional range`,
    description: 'Media question 3 answer 3 in contact form',
  },
  mediaQ3A4: {
    id: 'contact.mediaQ3A4',
    defaultMessage: `I want to use danube in an intranet`,
    description: 'Media question 3 answer 4 in contact form',
  },

  // eCommerce questions and answers
  eCommerceQ1: {
    id: 'contact.eCommerceQ1',
    defaultMessage: `How can we help you with recommendations?`,
    description: 'ECommerce question 1 in contact form',
  },
  eCommerceQ1A1: {
    id: 'contact.eCommerceQ1A1',
    defaultMessage: `I'd like to increase the conversion rate for my products`,
    description: 'ECommerce question 1 answer 1 in contact form',
  },
  eCommerceQ1A2: {
    id: 'contact.eCommerceQ1A2',
    defaultMessage: `I'd like to send personalized emails to my customers`,
    description: 'ECommerce question 1 answer 2 in contact form',
  },
  eCommerceQ1A3: {
    id: 'contact.eCommerceQ1A3',
    defaultMessage: `I'm interested to raise multiple KPIs of my site`,
    description: 'ECommerce question 1 answer 3 in contact form',
  },
  eCommerceQ2: {
    id: 'contact.eCommerceQ2',
    defaultMessage: `What's the type of your eCommerce?`,
    description: 'ECommerce question 2 in contact form',
  },
  eCommerceQ2A1: {
    id: 'contact.eCommerceQ2A1',
    defaultMessage: 'My product portfolio has more then 1000 products',
    description: 'ECommerce question 2 answer 1 in contact form',
  },
  eCommerceQ2A2: {
    id: 'contact.eCommerceQ2A2',
    defaultMessage: `My product portfolio has more then 100 products`,
    description: 'ECommerce question 2 answer 2 in contact form',
  },
  eCommerceQ2A3: {
    id: 'contact.eCommerceQ2A3',
    defaultMessage: 'My product portfolio has less then 100 products',
    description: 'ECommerce question 2 answer 3 in contact form',
  },
  eCommerceQ2A4: {
    id: 'contact.eCommerceQ2A4',
    defaultMessage: 'I do not have a product portfolio',
    description: 'ECommerce question 2 answer 4 in contact form',
  },
  eCommerceQ3: {
    id: 'contact.eCommerceQ3',
    defaultMessage: `Do you already use a recommendation tool?`,
    description: 'ECommerce question 3 in contact form',
  },
  eCommerceQ3A1: {
    id: 'contact.eCommerceQ3A1',
    defaultMessage: 'Yes',
    description: 'ECommerce question 3 answer 1 in contact form',
  },
  eCommerceQ3A2: {
    id: 'contact.eCommerceQ3A2',
    defaultMessage: 'No',
    description: 'ECommerce question 3 answer 2 in contact form',
  },
  eCommerceQ3A3: {
    id: 'contact.eCommerceQ3A3',
    defaultMessage: `I don't know`,
    description: 'ECommerce question 3 answer 3 in contact form',
  },

  // other field questions and answers
  otherFieldQ1: {
    id: 'contact.otherFieldQ1',
    defaultMessage: `How can danube.ai help you?`,
    description: 'Ohter field question 1 in contact form',
  },
  otherFieldQ1A1: {
    id: 'contact.otherFieldQ1A1',
    defaultMessage: `I'd like to increase the time on site of my users`,
    description: 'Ohter field question 1 answer 1 in contact form',
  },
  otherFieldQ1A2: {
    id: 'contact.otherFieldQ1A2',
    defaultMessage: `I'd like to increase the traffic on my site`,
    description: 'Ohter field question 1 answer 2 in contact form',
  },
  otherFieldQ1A3: {
    id: 'contact.otherFieldQ1A3',
    defaultMessage: `I'd like to provide better recommendations`,
    description: 'Ohter field question 1 answer 3 in contact form',
  },
  otherFieldQ1A4: {
    id: 'contact.otherFieldQ1A4',
    defaultMessage: `I'd like to sell more stuff`,
    description: 'Ohter field question 1 answer 4 in contact form',
  },
  otherFieldQ1A5: {
    id: 'contact.otherFieldQ1A5',
    defaultMessage: `I'd like to increase multiple KPI's on my site`,
    description: 'Ohter field question 1 answer 5 in contact form',
  },
});

export default messages;
