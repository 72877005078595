import { defineMessages } from 'react-intl';

const messages = defineMessages({
  dashboard: {
    id: 'topNavigation.dashboard',
    defaultMessage: 'Dashboard',
    description: 'Label for "Dashboard" link in user top navigation',
  },
  newAnalysis: {
    id: 'topNavigation.newAnalysis',
    defaultMessage: 'New Analysis',
    description: 'Label for "New Analysis" link in user top navigation',
  },
  technology: {
    id: 'topNavigation.technology',
    defaultMessage: 'Technology',
    description: 'Label for "Technology" link in user top navigation',
  },
  background: {
    id: 'topNavigation.technology.background',
    defaultMessage: 'Background',
    description: 'Label for "Background" technology link in top navigation',
  },
  statistics: {
    id: 'topNavigation.technology.statistics',
    defaultMessage: 'Statistics',
    description: 'Label for "Statistics" technology link in top navigation',
  },
  useCases: {
    id: 'topNavigation.useCases',
    defaultMessage: 'Other Use Cases',
    description: 'Label for "Other Use Cases" link in user top navigation',
  },
  integrations: {
    id: 'topNavigation.integrations',
    defaultMessage: 'Integrations',
    description: 'Label for "Integrations" link in user top navigation',
  },
  eCommerce: {
    id: 'topNavigation.useCases.eCommerce',
    defaultMessage: 'eCommerce',
    description:
      'Label for "eCommerce" as use cases link in user top navigation',
  },
  mediaSuggestions: {
    id: 'topNavigation.useCases.eCommerce.mediaSuggestions',
    defaultMessage: 'Media suggestions',
    description:
      'Label for "Media suggestions" use case link in use cases subnavigation',
  },
  productSuggestions: {
    id: 'topNavigation.useCases.eCommerce.productSuggestions',
    defaultMessage: 'Product suggestions',
    description:
      'Label for "Product suggestions" use case link in use cases subnavigation',
  },
  sports: {
    id: 'topNavigation.useCases.sports',
    defaultMessage: 'Sports',
    description: 'Label for "Sports" use cases link in user top navigation',
  },
  football: {
    id: 'topNavigation.useCases.sports.football',
    defaultMessage: 'Football',
    description:
      'Label for "Football" use case link in use cases subnavigation',
  },
  tennis: {
    id: 'topNavigation.useCases.sports.tennis',
    defaultMessage: 'Tennis',
    description: 'Label for "Tennis" use case link in uses cases subnavigation',
  },
  formula1: {
    id: 'topNavigation.formula1',
    defaultMessage: 'Formula 1',
    description: 'Label for "Formula 1" use case link in user top navigation',
  },
  humanResources: {
    id: 'topNavigation.useCases.humanResources',
    defaultMessage: 'Human Resources',
    description:
      'Label for "Human Resources" use cases link in user top navigation',
  },
  recruiting: {
    id: 'topNavigation.useCases.humanResources.recruiting',
    defaultMessage: 'Recruiting',
    description:
      'Label for "Recruiting" use case link in use cases subnavigation',
  },
  training: {
    id: 'topNavigation.useCases.humanResources.training',
    defaultMessage: 'Training',
    description:
      'Label for "Training" use case link in use cases subnavigation',
  },
  school: {
    id: 'topNavigation.school',
    defaultMessage: 'School',
    description: 'Label for "School" use case link in user top navigation',
  },
  testing: {
    id: 'topNavigation.useCases.testing',
    defaultMessage: 'Testing',
    description: 'Label for "Testing" use case link in user top navigation',
  },
  education: {
    id: 'topNavigation.useCases.education',
    defaultMessage: 'Education',
    description:
      'Label for "Education" use case link in use cases subnavigation',
  },
  industry: {
    id: 'topNavigation.useCases.industry',
    defaultMessage: 'Industry',
    description:
      'Label for "Industry" use case link in use cases subnavigation',
  },
  sportsData: {
    id: 'topNavigation.sportsData',
    defaultMessage: 'Sports Data',
    description: 'Label for "Sports Data" link in top navigation',
  },
  skiing: {
    id: 'topNavigation.skiing',
    defaultMessage: 'Skiing',
    description: 'Label for "Skiing" sports data link in user top navigation',
  },
  account: {
    id: 'topNavigation.account',
    defaultMessage: 'Account',
    description: 'Message for top navigation',
  },
  settings: {
    id: 'topNavigation.settings',
    defaultMessage: 'Settings',
    description: 'Message for top navigation',
  },
  logout: {
    id: 'topNavigation.logout',
    defaultMessage: 'Logout',
    description: 'Message for top navigation',
  },
  contactSales: {
    id: 'topNavigation.contactSales',
    defaultMessage: 'Contact',
    description: 'Message for top navigation',
  },
  otherUses: {
    id: 'topNavigation.otherUses',
    defaultMessage: 'Other uses',
    description: 'Message for top navigation',
  },
  api: {
    id: 'topNavigation.api',
    defaultMessage: 'API',
    description: 'Message for top navigation',
  },
  demos: {
    id: 'topNavigation.demos',
    defaultMessage: 'Demos',
    description: 'Message for top navigation',
  },
  pricing: {
    id: 'topNavigation.pricing',
    defaultMessage: 'Pricing',
    description: 'Message for top navigation',
  },
  showcases: {
    id: 'topNavigation.showcases',
    defaultMessage: 'Showcases',
    description: 'Message for top navigation',
  },
  getstarted: {
    id: 'topNavigation.getstarted',
    defaultMessage: 'Get Started',
    description: 'Message for top navigation',
  },
  requestDemoButton: {
    id: 'topNavigation.requestDemoButton',
    defaultMessage: 'Request Demo',
    description: 'Message for top navigation',
  },
});

export default messages;
