import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import Loading from 'components/Loading';

import ResultsTable from 'components/ResultsTable';
import {
  parseCalibration,
  validateCalibration,
} from '../../components/ResultsTable/CalibrationValidator';
import { prepareColumnImporterData } from '../../components/ResultsTable/ColumnHelper';

const getLatestCalibration = gql`
  query getLatestCalibration($datasetId: String!) {
    getLatestCalibration(datasetId: $datasetId) {
      id
      columnSettings
      rules
      columnTypes
      columnScores
      updatedAt
      strategy
      mixFactor
      impact
      rawData {
        id
        name
        rawData
        uniqueIdColumn
        initialValueRow
        initialSort
      }
    }
  }
`;

class ResultsWrapper extends React.Component {
  static contextTypes = {
    client: PropTypes.object,
  };

  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.prepareData = this.prepareData.bind(this);
    this.refreshTableValues = this.refreshTableValues.bind(this);

    this.state = {
      initialized: false,
    };
  }

  componentWillMount() {
    this.prepareData();
  }

  async prepareData() {
    const calibrationParameter = this.props.location.search;
    if (!calibrationParameter) {
      // TODO: show 404?
    }
    const datasetId = calibrationParameter.split('?id=')[1];
    if (!datasetId) {
      // show 404
    }

    // TODO: check redux store for data
    const latestCalibration = await this.context.client.query({
      query: getLatestCalibration,
      variables: {
        datasetId,
      },
      fetchPolicy: 'network-only',
    });

    const calibration = latestCalibration.data.getLatestCalibration;
    this.setState({
      initialized: true,
      calibration: parseCalibration(calibration),
    });
  }

  refreshTableValues(calibration) {
    this.setState({
      calibration: parseCalibration(calibration),
    });
  }

  render() {
    const { initialized, calibration } = this.state;
    if (!initialized) return <Loading />;

    // prepare data to show in column importers
    const initialValues = { columnImporters: [] };
    if (validateCalibration(calibration)) {
      const uniqueIdColumn = parseInt(calibration.rawData.uniqueIdColumn, 10);
      initialValues.columnImporters = prepareColumnImporterData(calibration);
      initialValues.settings = {
        strategy: calibration.strategy,
        impact: calibration.impact,
        mixFactor: calibration.mixFactor,
        uniqueIdColumn: `${parseInt(uniqueIdColumn, 10) + 1}`,
        initialValueRow: calibration.rawData.initialValueRow + 1,
        initialSort: calibration.rawData.initialSort,
      };
    }
    return (
      <div>
        <ResultsTable
          calibrationId={calibration.id}
          datasetId={calibration.rawData.id}
          initialValues={initialValues}
          refreshTableValues={this.refreshTableValues}
        />
      </div>
    );
  }
}

export default ResultsWrapper;
