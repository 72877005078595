import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { Link } from 'react-router-dom';

import layoutStyle from '../../../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from '../../../Page.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../../messages';
import wikiMessages from '../../../messages';
import {
  codeStyle,
  resetCorrelationMatrixRequest,
  resetCorrelationMatrixResponse,
} from '../../codeBlocks';

class ResetCorrelationMatrix extends React.Component {
  render() {
    return (
      <div className={s.apiText}>
        <p>
          <h1>
            <FormattedMessage {...wikiMessages.resetCorrelationMatrix} />
          </h1>
          <FormattedHTMLMessage {...messages.danubeRecommendationText9} />{' '}
          <FormattedMessage
            {...messages.danubeRecommendationResetMatrixWarning}
            values={{
              contactUs: (
                <Link to="/contact" target="_blank">
                  <FormattedMessage
                    {...messages.danubeRecommendationOverviewContactUs}
                  />
                </Link>
              ),
            }}
          />
        </p>
        <p>
          <h2>
            <FormattedMessage {...messages.request} />
          </h2>
          <FormattedHTMLMessage {...messages.danubeRecommendationText10} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {resetCorrelationMatrixRequest}
          </SyntaxHighlighter>
          <br />
          <FormattedHTMLMessage
            {...messages.danubePredictionText3}
            values={{
              exampleLink:
                '/api/cloud/examples/recommendation/reset-correlation-matrix',
            }}
          />
        </p>
        <p>
          <h2>
            <FormattedMessage {...messages.response} />
          </h2>
          <FormattedHTMLMessage {...messages.danubeRecommendationText11} />
          <br />
          <br />
          <SyntaxHighlighter language="javascript" customStyle={codeStyle}>
            {resetCorrelationMatrixResponse}
          </SyntaxHighlighter>
        </p>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle)(ResetCorrelationMatrix);
