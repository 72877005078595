import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import GetApiKeyButton from 'components/ApiKey/GetApiKeyButton';

import messages from '../messages';
import s from './ApiKeys.scss';
import layoutStyle from '../../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import ApiKeyPanel from './ApiKeyPanel';
import {
  API_PRODUCT_PREDICTION,
  API_PRODUCT_RECOMMENDATION,
} from '../../../constants';

class ApiKeys extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      licenseCustomerId: PropTypes.string,
    }).isRequired,
    apiKeysData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      apiKeys: PropTypes.arrayOf(
        PropTypes.shape({
          token: PropTypes.string,
          name: PropTypes.string,
          license: PropTypes.shape({
            id: PropTypes.string,
            price: PropTypes.number,
            data: PropTypes.string,
          }),
        }),
      ),
    }),
    productTypesData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      productTypesForLicenseIds: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.string),
      ),
    }),
  };

  static defaultProps = {
    apiKeysData: {
      loading: false,
      apiKeys: [],
    },
    productTypesData: {
      loading: false,
      productTypesForLicenseIds: [],
    },
  };

  render() {
    const { user, apiKeysData, productTypesData } = this.props;

    const keysLoading = apiKeysData.loading || productTypesData.loading;

    let predictionApiKey = null;
    let recommendationApiKey = null;

    if (!keysLoading) {
      const { apiKeys } = apiKeysData;
      const productTypes = productTypesData.productTypesForLicenseIds;

      const predictionApiKeys = apiKeys
        ? apiKeys.filter((apiKey, index) =>
            productTypes[index].includes(API_PRODUCT_PREDICTION),
          )
        : [];
      predictionApiKey =
        predictionApiKeys.length > 0
          ? predictionApiKeys.sort(
              (k1, k2) => k2.license.price - k1.license.price,
            )[0]
          : null;

      const recommendationApiKeys = apiKeys
        ? apiKeys.filter((apiKey, index) =>
            productTypes[index].includes(API_PRODUCT_RECOMMENDATION),
          )
        : [];
      recommendationApiKey =
        recommendationApiKeys.length > 0
          ? recommendationApiKeys.sort(
              (k1, k2) => k2.license.price - k1.license.price,
            )[0]
          : null;
    }

    return (
      <div>
        <Row className={layoutStyle.noMargin}>
          <Col lg={12}>
            <div className={s.apiKeysHeaderContainer}>
              <h1>
                <FormattedMessage {...messages.apiKeys} />
              </h1>
              <GetApiKeyButton />
            </div>
          </Col>
          <Col lg={12}>
            <Row>
              <Col md={12} lg={6}>
                <ApiKeyPanel
                  user={user}
                  apiKey={predictionApiKey}
                  loading={keysLoading}
                  product={API_PRODUCT_PREDICTION}
                />
              </Col>
              <Col md={12} lg={6}>
                <ApiKeyPanel
                  user={user}
                  apiKey={recommendationApiKey}
                  loading={keysLoading}
                  product={API_PRODUCT_RECOMMENDATION}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export const apiKeysQuery = gql`
  query apiKeys($licenseCustomerId: String!) {
    apiKeys(licenseCustomerId: $licenseCustomerId) {
      id
      name
      description
      enabled
      token
      updatedAt
      validationInterval
      renewable
      validities {
        id
        start
        end
        enabled
      }
      license {
        id
        name
        description
        price
        duration
        enabled
        visible
      }
    }
  }
`;

const productTypesQuery = gql`
  query productTypesForLicenseIds($licenseIds: [String]!) {
    productTypesForLicenseIds(licenseIds: $licenseIds)
  }
`;

export default compose(
  graphql(apiKeysQuery, {
    name: 'apiKeysData',
    skip: props => !props.user.licenseCustomerId,
    options: props => ({
      variables: {
        licenseCustomerId: props.user.licenseCustomerId,
      },
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(productTypesQuery, {
    name: 'productTypesData',
    skip: props => !props.apiKeysData || props.apiKeysData.loading,
    options: props => ({
      variables: {
        licenseIds: props.apiKeysData.apiKeys.map(apiKey => apiKey.license.id),
      },
      fetchPolicy: 'network-only',
    }),
  }),
)(withStyles(s, layoutStyle)(ApiKeys));
