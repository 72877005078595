import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { connect } from 'react-redux';

import Loading from '../Loading';
import {
  parseCalibration,
  validateCalibration,
} from '../ResultsTable/CalibrationValidator';
import { prepareColumnImporterData } from '../ResultsTable/ColumnHelper';
import ResultsTable from '../ResultsTable';
import { LAST_USECASES_PAGE } from './../../constants';

const getDataset = gql`
  query getData($id: String!) {
    getData(id: $id) {
      id
      rawDataId
      userId
      columnSettings
      rules
      columnTypes
      columnScores
      updatedAt
      strategy
      mixFactor
      impact
      rawData {
        id
        name
        rawData
        initialValueRow
        uniqueIdColumn
        initialSort
      }
    }
  }
`;

class DatasetWidget extends React.Component {
  static contextTypes = {
    client: PropTypes.object,
  };

  static propTypes = {
    datasetId: PropTypes.string.isRequired,
    forceWidgetHorizontalScroll: PropTypes.bool,
    danubifyStep: PropTypes.number,
  };

  static defaultProps = {
    forceWidgetHorizontalScroll: false,
    danubifyStep: LAST_USECASES_PAGE,
  };

  constructor(props) {
    super(props);

    this.child = React.createRef();

    this.prepareData = this.prepareData.bind(this);
    this.refreshTableValues = this.refreshTableValues.bind(this);

    this.state = {
      initialized: false,
    };
  }

  async componentDidMount() {
    await this.prepareData();
  }

  async prepareData() {
    const { datasetId } = this.props;
    // TODO: get from redux store if exists
    const queryResult = await this.context.client.query({
      query: getDataset,
      variables: {
        id: datasetId,
      },
      fetchPolicy: 'network-only',
    });
    const calibration = parseCalibration(queryResult.data.getData);

    this.setState({
      initialized: true,
      calibration,
    });
  }

  refreshTableValues(calibration) {
    this.setState({
      calibration: parseCalibration(calibration),
    });
  }

  render() {
    const { forceWidgetHorizontalScroll, danubifyStep } = this.props;
    const { initialized, calibration } = this.state;
    if (!initialized) return <Loading />;

    // prepare data to show in column importers
    const initialValues = { columnImporters: [] };
    if (validateCalibration(calibration)) {
      const uniqueIdColumn = parseInt(calibration.rawData.uniqueIdColumn, 10);

      initialValues.columnImporters = prepareColumnImporterData(calibration);
      initialValues.settings = {
        strategy: calibration.strategy,
        impact: calibration.impact,
        mixFactor: calibration.mixFactor,
        uniqueIdColumn: `${parseInt(uniqueIdColumn, 10) + 1}`,
        initialValueRow: calibration.rawData.initialValueRow + 1,
        initialSort: calibration.rawData.initialSort,
      };
    }

    return (
      <div>
        <ResultsTable
          calibrationId={calibration.id}
          datasetId={calibration.rawData.id}
          initialValues={initialValues}
          refreshTableValues={this.refreshTableValues}
          usecaseView
          ref={this.child}
          forceWidgetHorizontalScroll={forceWidgetHorizontalScroll}
          danubifyStep={danubifyStep}
        />
      </div>
    );
  }
}

const mapStateToProps = store => ({
  step: store.usecases.step,
});

export default connect(mapStateToProps)(DatasetWidget);
