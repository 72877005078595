import React from 'react';
import { injectIntl, defineMessages, intlShape } from 'react-intl';
import CookieBanner from 'react-cookie-banner';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './cookieBanner.scss';

const messages = defineMessages({
  cookieMessage: {
    id: 'cookieMessage',
    defaultMessage:
      'By continuing to use our website, you are accepting the use of our required Cookies.',
    description: 'Text for Cookie footer message.',
  },
  cookieButton: {
    id: 'cookieButton',
    defaultMessage: 'Accept',
    description: 'Text for Cookie accept button.',
  },
});

class CookieBannerBottom extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { show: false };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ show: true });
  }

  render() {
    const { intl } = this.props;
    return (
      this.state.show && (
        <CookieBanner
          message={intl.formatMessage(messages.cookieMessage)}
          buttonMessage={intl.formatMessage(messages.cookieButton)}
          onAccept={() => {}}
          dismissOnScroll={false}
          disableStyle
          className={s.cookieBanner}
          cookie="user-has-accepted-cookies"
        />
      )
    );
  }
}

export default injectIntl(withStyles(s)(CookieBannerBottom));
