import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import DataSettingsPanel from './NewAnalysis/DataPreSettings/DataSettingsPanel';

class DatasetCardSettingsForm extends React.Component {
  static defaultProps = {
    onDataSettingsApplied: null,
  };

  static propTypes = {
    formName: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onDataSettingsApplied: PropTypes.func,
  };

  render() {
    return (
      <DataSettingsPanel
        formName={this.props.formName}
        onDataSettingsApplied={this.props.onDataSettingsApplied}
      />
    );
  }
}

export default reduxForm({
  enableReinitialize: true,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(DatasetCardSettingsForm);
