import {
  COLUMN_TYPE_BOOLEAN,
  COLUMN_TYPE_NUMERIC_PERCENTAGE,
  COLUMN_TYPE_NONE,
  COLUMN_TYPE_TEXT,
  COLUMN_RULE_EQUAL,
  COLUMN_RULE_MAX_PERCENTAGE,
  ALGORITHM_STRATEGY_MIXED,
} from '../../constants';

const booleanStrings = [
  { name: 'yes', val: 1 },
  { name: 'no', val: 0 },
  { name: 'true', val: 1 },
  { name: 'false', val: 0 },
  { name: 'y', val: 1 },
  { name: 'n', val: 0 },
  { name: 't', val: 1 },
  { name: 'f', val: 0 },
];

export function isNumeric(n) {
  // eslint-disable-next-line
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isNumericColumn(column) {
  let numeric = true;
  column.forEach(element => {
    // already negative -> skip
    if (!numeric) return;

    if (!isNumeric(element) && element && element !== '') {
      numeric = false;
    }
  });
  return numeric;
}

function getUniqueColumnValues(column) {
  const values = [];
  column.forEach(element => {
    if (element && element !== '' && !values.includes(element)) {
      values.push(element);
    }
  });
  return values;
}

export function getColumnDataType(column) {
  if (isNumericColumn(column)) {
    return COLUMN_TYPE_NUMERIC_PERCENTAGE;
  }

  if (getUniqueColumnValues(column).length <= 2) {
    return COLUMN_TYPE_BOOLEAN;
  }
  return COLUMN_TYPE_NONE;
}

export function generateRuleObject(
  columnType,
  { hundredPercentEqualsTo, oneEqualsTo },
) {
  let rule = {};
  if (columnType === COLUMN_TYPE_NUMERIC_PERCENTAGE) {
    rule = {
      ruleType: COLUMN_RULE_MAX_PERCENTAGE,
      ruleValue: hundredPercentEqualsTo,
    };
  } else if (columnType === COLUMN_TYPE_BOOLEAN) {
    rule = {
      ruleType: COLUMN_RULE_EQUAL,
      ruleValue: oneEqualsTo,
    };
  }
  return rule;
}

export function generateRuleArray(calibrationData) {
  return calibrationData.map(column => {
    const ruleObject = generateRuleObject(column.columnType, {
      hundredPercentEqualsTo: column.hundredPercentEqualsTo,
      oneEqualsTo: column.oneEqualsTo,
    });
    return ruleObject === {} ? [] : [ruleObject];
  });
}

export function generateRulesForColumnType(columnType, row, globalMaximum) {
  const rules = [];
  const columnValues = getUniqueColumnValues(row);
  switch (columnType) {
    case COLUMN_TYPE_BOOLEAN:
      // cannot match values automatically
      if (columnValues.length > 2) {
        rules.push({ ruleType: COLUMN_RULE_EQUAL, ruleValue: '' });
      } else {
        columnValues.forEach(element => {
          const booleanElement = booleanStrings.find(
            n => element.toLowerCase() === n.name,
          );

          // element can be matched to true boolean
          if (booleanElement && booleanElement.val === 1) {
            rules.push({
              ruleType: COLUMN_RULE_EQUAL,
              ruleValue: booleanElement.name,
            });
          }
        });

        // no booleans found, just pick first element
        if (rules.length === 0) {
          rules.push({
            ruleType: COLUMN_RULE_EQUAL,
            ruleValue: columnValues[0],
          });
        }
      }
      break;
    case COLUMN_TYPE_NUMERIC_PERCENTAGE:
      if (isNumericColumn(row)) {
        const validValues = row.filter(n => n);
        let ruleValue = 0;

        if (globalMaximum) {
          ruleValue = globalMaximum;
        } else if (validValues.length > 0) {
          // check if array is empty, otherwise spread operator would return -Infinity
          ruleValue = Math.max(...row.filter(n => n));
        }
        rules.push({
          ruleType: COLUMN_RULE_MAX_PERCENTAGE,
          ruleValue: `${ruleValue}`,
        });
      }
      break;
    default:
      break;
  }
  return rules;
}

// get matrix values with treatEmptyAsZero in mind
export function parseDataMatrix(rawData) {
  return rawData.map(column => {
    let newColumnValues = column.rows; // rows refers to data values for each row
    if (column.treatEmptyAsZero) {
      newColumnValues = column.rows.map(
        val => (val === null || val === undefined || val === '' ? 0 : val),
      );
    }
    return newColumnValues;
  });
}

// dataMatrix is an array of columns with each column containing all row values
export function applyRulesToData(
  dataMatrix,
  columnSettings,
  rules,
  uniqueIdColumn,
) {
  // copy data by value
  const newDataMatrix = JSON.parse(JSON.stringify(dataMatrix));
  return newDataMatrix.map((columnValues, y) => {
    if (y === uniqueIdColumn || columnSettings[y].skipped) {
      return columnValues;
    }
    return columnValues.map(x => {
      if (x === '') {
        return null;
      }
      let val = parseFloat(x);
      let newVal = 1;
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(val)) {
        // use passed string
        val = x;
      }
      // go through all rules and check if they all apply
      rules[y].forEach(rule => {
        // one rule already not applicable
        // skip other rules
        if (newVal === 0) {
          return;
        }
        switch (rule.ruleType) {
          case COLUMN_RULE_EQUAL:
            if (val === rule.ruleValue) {
              newVal = 1;
            } else {
              newVal = 0;
            }
            break;
          case COLUMN_RULE_MAX_PERCENTAGE:
            if (rule.ruleValue !== 0) {
              newVal = val / rule.ruleValue;
            } else {
              newVal = 0;
            }
            break;
          default:
            break;
        }
      });
      return newVal;
    });
  });
}

// sort and remember old index
export function getOldSortedIndices(scores) {
  let oldIndices = [];
  oldIndices = scores.map((element, i) => ({ score: element, index: i }));
  oldIndices = oldIndices.sort((a, b) => (a.score < b.score ? 1 : -1));
  return oldIndices.map(element => element.index);
}

export function getTopXRowsByScoreFromDataset(
  x,
  rowScores,
  dataset,
  uniqueIdColumn,
) {
  const filterCountX = Math.min(x, rowScores.length);
  const oldIndices = getOldSortedIndices(rowScores);
  const rowNames = dataset[uniqueIdColumn].rows;
  const topRows = [];
  for (let i = 0; i < filterCountX; i += 1) {
    const row = oldIndices[i];
    if (row !== null && rowNames[row] && rowScores[row]) {
      const score = isNumeric(rowScores[row]) ? rowScores[row].toFixed(2) : 0;
      topRows.push({ name: rowNames[row], score });
    }
  }
  return topRows;
}

export function getTopXColumnsByScoreFromDataset(
  x,
  columnScores,
  columnSettings,
) {
  const filterCountX = Math.min(x, columnScores.length);
  const oldIndices = getOldSortedIndices(columnScores);
  const topColumns = [];
  for (let i = 0; i < filterCountX; i += 1) {
    const column = oldIndices[i];
    if (column !== null) {
      const score = isNumeric(columnScores[column])
        ? columnScores[column].toFixed(2)
        : 0;
      topColumns.push({
        name: columnSettings[column].name,
        score,
      });
    }
  }
  return topColumns;
}

export function calculateRowScore(row, columnScores) {
  // dot product between row data and column scores
  return row
    .map((x, i) => x * columnScores[i])
    .reduce((total, num) => total + num);
}

// get row values
export function getFish(dataMatrix, columnSettings, uniqueIdColumn) {
  const rowNames = dataMatrix[uniqueIdColumn];
  const fish = [];
  rowNames.forEach((_, y) => {
    const fishEntry = [];
    dataMatrix.forEach((columnValues, x) => {
      if (x !== uniqueIdColumn && !columnSettings[x].skipped) {
        fishEntry.push(columnValues[y]);
      } else {
        fishEntry.push(null);
      }
    });
    fish.push(fishEntry);
  });
  return fish;
}

export function getColumns(data) {
  const columns = [];
  data.forEach(column => {
    columns.push(column.columnScore);
  });
  return columns;
}

// algversion 0.1
// trim unwanted data
// return fish(rule applied row array)
// columns(initial values of column)
// settings to transfer to api
export function packAlgorithmData(
  dataMatrix,
  columnSettings,
  columnScores,
  uniqueIdColumn,
  settings,
) {
  const rowNames = dataMatrix[uniqueIdColumn];
  const fish = [];
  const columns = [];
  // only add columns once
  let columnsDone = false;

  // loop through rows and filter out fish and column vavlues
  rowNames.forEach((_, y) => {
    const fishEntry = [];
    if (columns.length > 0) columnsDone = true; // only save column score once per column

    // for each row loop through their columns to get all row values
    dataMatrix.forEach((columnValues, x) => {
      // ignore column entirely if unique column or skipped
      if (
        x !== uniqueIdColumn &&
        !columnSettings[x].skipped &&
        !columnSettings[x].fixed
      ) {
        fishEntry.push(columnValues[y]);
        if (!columnsDone) columns.push(columnScores[x]);
      }
    });
    fish.push(fishEntry);
  });

  const algData = { fish, columns, nfish: fish.length };

  // add settings
  algData.strategy = settings.strategy;
  if (algData.strategy === ALGORITHM_STRATEGY_MIXED) {
    algData.mixFactor = settings.mixFactor;
  }
  algData.impact = settings.impact;
  return algData;
}

// takes an parsed CalibratedData object and formats it to send to api and danubify
export function formatForAlgorithm(calibration) {
  const uniqueIdColumn = JSON.parse(calibration.rawData.uniqueIdColumn);
  // get 2d data array with emptyAsZero rule applied
  const dataMatrix = parseDataMatrix(calibration.rawData.rawData);
  // apply all rules to matrix
  const ruleAppliedMatrix = applyRulesToData(
    dataMatrix,
    calibration.columnSettings,
    calibration.rules,
    uniqueIdColumn,
  );
  // trim and pack all necessary data and format in base64
  return packAlgorithmData(
    ruleAppliedMatrix,
    calibration.columnSettings,
    calibration.columnScores,
    uniqueIdColumn,
    {
      strategy: calibration.strategy,
      impact: calibration.impact,
      mixFactor: calibration.mixFactor,
    },
  );
}

export function prepareColumnImporterData(calibration) {
  const columnImporters = [];

  const uniqueIdColumn = parseInt(calibration.rawData.uniqueIdColumn, 10);
  calibration.rawData.rawData.forEach((column, x) => {
    const rows = [];
    column.rows.forEach(element => {
      let entry = '';
      if (element !== null && element !== undefined) {
        entry = `${element}`;
      }
      rows.push(entry);
    });
    const rule = calibration.rules[x][0];
    let oneEqualsTo;
    let hundredPercentEqualsTo;
    if (rule) {
      oneEqualsTo = rule.ruleType === COLUMN_RULE_EQUAL ? rule.ruleValue : null;
      hundredPercentEqualsTo =
        rule.ruleType === COLUMN_RULE_MAX_PERCENTAGE
          ? parseFloat(rule.ruleValue)
          : null;
    }

    const columnScore = isNumeric(calibration.columnScores[x])
      ? parseFloat(calibration.columnScores[x])
      : 0;
    const { columnSettings } = calibration;
    const datasetName = calibration.rawData.name;
    columnImporters.push({
      name: x === uniqueIdColumn ? datasetName : columnSettings[x].name,
      columnType: calibration.columnTypes[x],
      rows,
      isUniqueIdColumn: x === uniqueIdColumn,
      oneEqualsTo,
      hundredPercentEqualsTo,
      matched:
        calibration.columnTypes[x] !== COLUMN_TYPE_TEXT &&
        calibration.columnTypes[x] !== COLUMN_TYPE_NONE,
      skipped: columnSettings[x].skipped ? columnSettings[x].skipped : false,
      columnScore,
      columnScoreInput: columnScore,
      fixed: !!columnSettings[x].fixed,
      treatEmptyAsZero: !!columnSettings[x].treatEmptyAsZero,
    });
  });

  return columnImporters;
}

export function getRowValuesFromTableValues(tableValues) {
  const data = tableValues.columnImporters;
  const idColumn = data.findIndex(element => element.isUniqueIdColumn);
  // extract column scores and row values(fish)
  const rules = generateRuleArray(data);

  const dataMatrix = parseDataMatrix(data);
  const ruleAppliedData = applyRulesToData(
    dataMatrix,
    data, // used as column settings
    rules,
    idColumn,
  );
  const fish = getFish(ruleAppliedData, data, idColumn);
  const columns = getColumns(data);
  return fish.map(row => calculateRowScore(row, columns));
}

export function isAllMatched(tableValues) {
  let allMatched = true;
  tableValues.columnImporters.forEach(column => {
    if (!column.matched && !column.skipped) {
      allMatched = false;
    }
  });
  return allMatched;
}
