import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loading from 'components/Loading';

import s from './UserAccount.scss';
import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import ApiKeys from './apiKeys/ApiKeys';

class UserAccount extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      licenseCustomerId: PropTypes.string,
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    intl: intlShape.isRequired,
  };

  render() {
    const { user } = this.props;

    if (user.loading) return <Loading />;

    return (
      <div>
        <ApiKeys user={user} />
      </div>
    );
  }
}

const userQuery = gql`
  query userQuery {
    me {
      id
      username
      licenseCustomerId
    }
  }
`;

export default compose(
  withStyles(s, layoutStyle),
  graphql(userQuery, {
    name: 'user',
    props: ({ user }) => ({
      user: {
        ...user.me,
        loading: user.loading,
        refetch: user.refetch,
      },
    }),
  }),
)(injectIntl(UserAccount));
