import { defineMessages } from 'react-intl';

const messages = defineMessages({
  filterAndSort: {
    id: 'demo.phone.search.filterAndSort',
    defaultMessage: 'Find your perfect phone',
    description: 'label for field in search mask',
  },

  maxPriceMonthly: {
    id: 'demo.phone.search.maxPriceMonthly',
    defaultMessage: 'How much do you want to spend at most?',
    description: 'label for field in search mask',
  },
  cameraImportance: {
    id: 'demo.phone.search.cameraImportance',
    defaultMessage: 'How important is a good camera for you?',
    description: 'label for field in search mask',
  },
  batteryImportance: {
    id: 'demo.phone.search.batteryImportance',
    defaultMessage: 'How important is a good battery power for you?',
    description: 'label for field in search mask',
  },
  displayZollPreference: {
    id: 'demo.phone.search.displayZollPreference',
    defaultMessage: 'What display size do you prefer?',
    description: 'label for field in search mask',
  },
  preferBig: {
    id: 'demo.phone.search.preferBig',
    defaultMessage: 'Larger is better',
    description: 'label for field in search mask',
  },
  preferMedium: {
    id: 'demo.phone.search.preferMedium',
    defaultMessage: 'Medium display size',
    description: 'label for field in search mask',
  },
  preferSmall: {
    id: 'demo.phone.search.preferSmall',
    defaultMessage: 'Smaller is better',
    description: 'label for field in search mask',
  },
  companies: {
    id: 'demo.phone.search.companies',
    defaultMessage: 'Which companies do you prefer?',
    description: 'label for field in search mask',
  },
  additionalOptions: {
    id: 'demo.phone.search.additionalOptions',
    defaultMessage: 'Additional information used for sorting',
    description: 'label for field in search mask',
  },
  includeExternalTests: {
    id: 'demo.phone.search.includeExternalTests',
    defaultMessage: 'External tests',
    description: 'label for field in search mask',
  },
  includeCameraTests: {
    id: 'demo.phone.search.includeCameraTests',
    defaultMessage: 'Camera tests',
    description: 'label for field in search mask',
  },
  includeO2Popularity: {
    id: 'demo.phone.search.includeO2Popularity',
    defaultMessage: 'Popularity',
    description: 'label for field in search mask',
  },
  fromPrice: {
    id: 'demo.phone.search.fromPrice',
    defaultMessage: 'from (€)',
    description: 'label for field in search mask',
  },
  toPrice: {
    id: 'demo.phone.search.toPrice',
    defaultMessage: 'to (€)',
    description: 'label for field in search mask',
  },
});

export default messages;
