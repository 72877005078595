import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FaExternalLink } from 'react-icons/lib/fa/';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import globalDemoMessages from './globalDemoMessages';
import s from './util.scss';

export const formatSliderLabel = value => {
  let formattedLabel = globalDemoMessages.neutral;
  if (value <= 0.2) {
    formattedLabel = globalDemoMessages.unimportant;
  } else if (value <= 0.4) {
    formattedLabel = globalDemoMessages.lessImportant;
  } else if (value >= 0.8) {
    formattedLabel = globalDemoMessages.veryImportant;
  } else if (value >= 0.6) {
    formattedLabel = globalDemoMessages.important;
  }

  return (
    <span style={{ fontSize: '16px' }}>
      <FormattedMessage {...formattedLabel} />
    </span>
  );
};

class _LearnMoreLink extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    withLeftMargin: PropTypes.bool,
  };

  static defaultProps = {
    withLeftMargin: true,
  };

  render() {
    return (
      // eslint-disable-next-line prettier/prettier
      <span className={`${s.learnMoreButton} ${this.props.withLeftMargin ? s.withLeftMargin : ''}`}>
        <Link to={this.props.to} target="_blank">
          <FormattedMessage {...globalDemoMessages.learnMore} />
          &nbsp;
          <FaExternalLink />
        </Link>
      </span>
    );
  }
}

export const LearnMoreLink = withStyles(s)(_LearnMoreLink);
