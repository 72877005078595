import { defineMessages } from 'react-intl';

const messages = defineMessages({
  whyDanube: {
    id: 'landingPage.whyDanube',
    defaultMessage: 'Why danube.ai',
    description: 'Label for landing page header.',
  },
  whatIsDanube: {
    id: 'landingPage.whatIsDanube',
    defaultMessage: 'What is danube.ai',
    description: 'Label for landing page header.',
  },

  slide1Text1: {
    id: 'landingPage.slide1Text1',
    defaultMessage: 'Predict user behavior.',
    description: 'Text for 1st slide of landing page.',
  },
  slide1Text2: {
    id: 'landingPage.slide1Text2',
    defaultMessage: 'Better recommendations with {productName}',
    description: 'Text for 1st slide of landing page.',
  },
  slide1Text3: {
    id: 'landingPage.slide1Text3',
    defaultMessage: 'little data beats Big Data',
    description: 'Text for 1st slide of landing page.',
  },
  getStarted: {
    id: 'landingPage.getStarted',
    defaultMessage: 'Get started',
    description: 'Label for Get Started button.',
  },
  realEstateDemo: {
    id: 'landingPage.realEstateDemo',
    defaultMessage: 'Real Estate Demo',
    description: 'Label for Real Estate Demo button.',
  },
  recruitingDemo: {
    id: 'landingPage.recruitingDemo',
    defaultMessage: 'Recruiting Demo',
    description: 'Label for Recruiting Demo button.',
  },
  phonesDemo: {
    id: 'landingPage.phonesDemo',
    defaultMessage: 'Phones Demo',
    description: 'Label for Phones Demo button.',
  },
  learnMore: {
    id: 'landingPage.learnMore',
    defaultMessage: 'Learn more',
    description: 'Label for Learn More button.',
  },

  slide2Text1: {
    id: 'landingPage.slide2Text1',
    defaultMessage: 'Traditional AI puts you into a category',
    description: 'Text for 2nd slide of landing page.',
  },

  slide3Text1: {
    id: 'landingPage.slide3Text1',
    defaultMessage: 'But every user is special',
    description: 'Text for 3rd slide of landing page.',
  },

  slide4Text1: {
    id: 'landingPage.slide4Text1',
    defaultMessage: "With danube.ai you'll make the right recommendations",
    description: 'Text for 4th slide of landing page.',
  },

  recruitingText1: {
    id: 'landingPage.recruiting.text1',
    defaultMessage: 'Find your perfect job.',
    description: 'Text for recruiting landing page.',
  },

  realEstateText1: {
    id: 'landingPage.realEstate.text1',
    defaultMessage: 'Find your perfect apartment.',
    description: 'Text for realEstate landing page.',
  },

  phonesText1: {
    id: 'landingPage.phones.text1',
    defaultMessage: 'Find your perfect phone.',
    description: 'Text for realEstate landing page.',
  },

  demosText2: {
    id: 'landingPage.demos.text2',
    defaultMessage: 'Predictive analytics with {productName}',
    description: 'Text for recruiting landing page.',
  },
  tryDemo: {
    id: 'landingPage.tryDemo',
    defaultMessage: 'Try Demo',
    description: 'Label for try-demo button.',
  },
  landingPageTitle: {
    id: 'landingPage.landingPageTitle',
    defaultMessage: 'Personalization that works',
    description: 'Text for the title of the landing page',
  },
  landingPageSubtitle: {
    id: 'landingPage.landingPageSubtitle',
    defaultMessage:
      'We do not store personal data -{br}little data beats Big Data.',
    description: 'Text for the sub-title of the landing page',
  },
  requestDemo: {
    id: 'landingPage.requestDemo',
    defaultMessage: 'Request Demo',
    description: 'Text for the request demo button',
  },
  graphicIcon_1: {
    id: 'landingPage.graphicIcon_1',
    defaultMessage: 'All your preferences covered',
    description: 'Text for graphicIcon_1',
  },
  graphicIcon_2: {
    id: 'landingPage.graphicIcon_2',
    defaultMessage: 'Conversion-Boost {br} through novel technology',
    description: 'Text for graphicIcon_2',
  },
  graphicIcon_3: {
    id: 'landingPage.graphicIcon_3',
    defaultMessage:
      'personalization {br} without personal data {br} (gdpr compliant)',
    description: 'Text for graphicIcon_3',
  },
  infographicSection_1: {
    id: 'landingPage.infographicSection_1',
    defaultMessage:
      '{danube} provides user-tailored recommendations, helping undecided users make the right choice.',
    description: 'Text for fact for  infographicSection_1',
  },
  infographicSection_2: {
    id: 'landingPage.infographicSection_2',
    defaultMessage:
      '{danube} creates a personal AI for every single user, taking into account all preferences - and all this in real-time.',
    description: 'Text for fact for  infographicSection_2',
  },
  infographicSection_3: {
    id: 'landingPage.infographicSection_3',
    defaultMessage: "Sounds impossible? Let us show you how it's done!",
    description: 'Text for fact for  infographicSection_3',
  },
  competitionAnalysisHeader: {
    id: 'landingPage.competitionAnalysisHeader',
    defaultMessage: 'How we fare to other solutions',
    description: 'Text for fact for  competitionAnalysisHeader',
  },
});

export default messages;
