import React from 'react';

import PageProperties from 'components/PageProperties';
import Contact from 'components/Contact';
import routeMessages from 'routes/messages';

class ContactWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.contactSalesTitle,
            description: routeMessages.contactSalesDescription,
            keywords: routeMessages.contactSalesKeywords,
          }}
        />
        <Contact />
      </div>
    );
  }
}

export default ContactWrapper;
