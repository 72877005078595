/* eslint-disable prettier/prettier */
/**
 * Wrappes a promise, resolves the success response to an array with the return data as second item.
 * And the Error received from the catch as the first.
 *
 * @param {promise} promise A promise to resolve or catch
 * @return {Array} A array with catched error as first element and resloved response as second.
 */
export const to = promise =>
  promise.then(data => [null, data]).catch(err => [err]);

export const getRandomDigits = (length = 20, min = 0, max = 9) =>
  Array.from({ length }, () => Math.floor(Math.random() * ((max - min) + 1)) + min).join('');

export const DB_DATE_FORMAT = 'ddd MMM D YYYY HH:mm:ss ZZ';
export const DATEPICKER_DATE_FORMAT = 'DD.MM.YYYY';
