import { defineMessages } from 'react-intl';

const messages = defineMessages({
  salaryTag: {
    id: 'demo.devjobs.results.salaryTag',
    defaultMessage: 'Salary',
    description: 'label in results list',
  },
  daysAgoTag: {
    id: 'demo.devjobs.results.daysAgoTag',
    defaultMessage: 'Last updated',
    description: 'label in results list',
  },
  remoteWorkTag: {
    id: 'demo.devjobs.results.remoteWorkTag',
    defaultMessage: 'Remote Work',
    description: 'label in results list',
  },
  companyTypePreferenceTag: {
    id: 'demo.devjobs.results.companyTypePreferenceTag',
    defaultMessage: 'Company type',
    description: 'label in results list',
  },
  jobLevelPreferenceTag: {
    id: 'demo.devjobs.results.jobLevelPreferenceTag',
    defaultMessage: 'Job level',
    description: 'label in results list',
  },
  technologiesTag: {
    id: 'demo.devjobs.results.technologiesTag',
    defaultMessage: 'Technologies',
    description: 'label in results list',
  },
  timeDistance: {
    id: 'demo.devjobs.results.timeDistance',
    defaultMessage: 'Travel Time',
    description: 'label in results list',
  },
  benefitsTag: {
    id: 'demo.devjobs.results.benefitsTag',
    defaultMessage: 'Benefits',
    description: 'label in results list',
  },
  field: {
    id: 'demo.devjobs.results.field',
    defaultMessage: 'Field',
    description: 'label in results list',
  },
});

export default messages;
