import { SET_USECASE, SET_STEP } from '../constants';

const initialState = {
  usecase: 'school',
  step: 0,
};

export default function usecase(state = { ...initialState }, action) {
  switch (action.type) {
    case SET_USECASE:
      return {
        ...state,
        ...action.payload.usecase,
      };
    case SET_STEP:
      return {
        ...state,
        step: action.payload.step,
      };
    default:
      return state;
  }
}
