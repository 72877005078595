import { defineMessages } from 'react-intl';

const messages = defineMessages({
  filterAndSort: {
    id: 'demo.devjobs.search.filterAndSort',
    defaultMessage: 'Which developer job fits you best?',
    description: 'label for field in search mask',
  },

  salary: {
    id: 'demo.devjobs.search.salary',
    defaultMessage: 'How much would you like to earn per year?',
    description: 'label for field in search mask',
  },
  salaryFrom: {
    id: 'demo.devjobs.search.salary.from',
    defaultMessage: 'from (€)',
    description: 'label for field in search mask',
  },
  salaryTo: {
    id: 'demo.devjobs.search.salary.to',
    defaultMessage: 'to (€)',
    description: 'label for field in search mask',
  },
  salaryImportance: {
    id: 'demo.devjobs.search.salary.importance',
    defaultMessage: 'How important is a high salary for you?',
    description: 'label for field in search mask',
  },
  location: {
    id: 'demo.devjobs.search.location',
    defaultMessage: 'Where do you live?',
    description: 'label for field in search mask',
  },
  locationImportance: {
    id: 'demo.devjobs.search.location.importance',
    defaultMessage: 'How important is a short travel distance to work for you?',
    description: 'label for field in search mask',
  },
  companyType: {
    id: 'demo.devjobs.search.companyType',
    defaultMessage: 'In which kind of company would you like to work?',
    description: 'label for field in search mask',
  },
  jobLevel: {
    id: 'demo.devjobs.search.jobLevel',
    defaultMessage: 'Which job level do you desire?',
    description: 'label for field in search mask',
  },
  remoteWorkImportance: {
    id: 'demo.devjobs.search.remoteWork.importance',
    defaultMessage: 'How important is the option of remote work for you?',
    description: 'label for field in search mask',
  },
  technologies: {
    id: 'demo.devjobs.search.technologies',
    defaultMessage: `
      Which technologies are you familiar with and want to work with?
    `,
    description: 'label for field in search mask',
  },
  benefits: {
    id: 'demo.devjobs.search.benefits',
    defaultMessage: `
      Which benefits are important for you?
    `,
    description: 'label for field in search mask',
  },
});

export default messages;
