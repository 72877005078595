import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { FormattedMessage, injectIntl /* , intlShape */ } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';

import render from 'components/ReduxForm/renderField';

import s from '../../ApiKey/ApiKey.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../../ApiKey/messages';

const numberWithCommas = x => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

class ApiModalForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    upgradableLicenses: PropTypes.arrayOf(PropTypes.object),
    onPricingChange: PropTypes.func,
    selectedPricing: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    upgradableLicenses: [],
    onPricingChange: () => {},
    errors: [],
  };

  render() {
    const {
      errors,
      handleSubmit,
      upgradableLicenses,
      onPricingChange,
      selectedPricing,
    } = this.props;

    const pricingOptions = upgradableLicenses
      .sort((l1, l2) => l1.price - l2.price)
      .map(l => ({
        value: l.id,
        label: l.name,
      }));

    let details = null;

    if (selectedPricing && selectedPricing.value) {
      let selectedPricingData = upgradableLicenses.filter(
        l => l.id === selectedPricing.value,
      );

      selectedPricingData =
        selectedPricingData.length >= 0 ? selectedPricingData[0] : null;

      if (selectedPricingData) {
        details = {
          isTrial: selectedPricingData.price === 0,
          name: selectedPricing.label,
          requests: `${selectedPricingData.credits} requests`,
          price: `${selectedPricingData.price} Euro`,
          rate: `(${Math.round(
            1000 * (selectedPricingData.price / selectedPricingData.credits),
          ) / 1000}/request)`,
        };
      }
    }

    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <fieldset>
          <div className={s.fieldContainer}>
            <FormattedMessage {...messages.modalPricingHeader} />
            <Field
              id="pricing"
              name="pricing"
              component={render.renderSelectAlt}
              isMulti={false}
              options={pricingOptions}
              onChange={onPricingChange}
            />
            <div className={s.spacerLine} />
          </div>
          {details && (
            <div className={s.selectionInfo}>
              <div className={s.infoHeader}>
                <FormattedMessage {...messages.modalPricingInfoHeader} />*
              </div>
              <ul>
                <li>{numberWithCommas(details.requests)}</li>
                <li>
                  {numberWithCommas(details.price)}{' '}
                  {numberWithCommas(details.rate)}
                </li>
              </ul>
              <div className={s.netPriceNote}>
                {details.isTrial ? (
                  <span>
                    * <FormattedMessage {...messages.oneTimeNote} />
                  </span>
                ) : (
                  <span>
                    * <FormattedMessage {...messages.netPriceNote} />
                  </span>
                )}
              </div>
            </div>
          )}
          {errors.length === 0 ? null : (
            <ul style={{ padding: '0', listStyleType: 'none' }}>
              {errors.map(err => (
                <li key={err} className="validationError">
                  {err}
                </li>
              ))}
            </ul>
          )}
        </fieldset>
      </form>
    );
  }
}

const selector = formValueSelector('upgradeLicenseModalForm');

const mapStateToProps = state => ({
  selectedPricing: selector(state, 'pricing'),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'upgradeLicenseModalForm',
    pure: false, // this is necessary to trigger form re-renders if the locale changes
  })(injectIntl(withStyles(s)(ApiModalForm))),
);
