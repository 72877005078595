import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import s from '../../../Page.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../../messages';
import wikiMessages from '../../../messages';
import apiMessages from '../../../ApiPages/messages';

class DanubePredicition extends React.Component {
  render() {
    return (
      <div className={s.apiText}>
        <p>
          <h1>
            <FormattedHTMLMessage {...wikiMessages.recommendation} />
          </h1>
          <FormattedHTMLMessage
            {...apiMessages.danubeRecommendationOverviewText1}
          />
        </p>
        <p>
          <h2>
            <FormattedMessage {...apiMessages.requiredSteps} />
          </h2>
          <ul>
            <li>
              <FormattedMessage
                {...messages.danubeRecommendationOverviewText2}
                values={{
                  danubeRecommendation: (
                    <Link
                      to="/api/cloud/documentation/recommendation/danube-recommendation"
                      target="_blank"
                    >
                      <FormattedMessage
                        {...wikiMessages.danubeRecommendation}
                      />
                    </Link>
                  ),
                  danubeClient: (
                    <i>
                      <FormattedMessage
                        {...messages.danubePredictionOverviewDanubeClient}
                      />
                    </i>
                  ),
                }}
              />
            </li>
          </ul>
        </p>
        <p>
          <h2>
            <FormattedMessage {...apiMessages.optionalSteps} />
          </h2>
          <ul>
            <li>
              <span>
                <FormattedMessage
                  {...messages.danubeRecommendationOverviewText3}
                  values={{
                    resetCorrelationMatrix: (
                      <Link
                        to="/api/cloud/documentation/recommendation/reset-correlation-matrix"
                        target="_blank"
                      >
                        <FormattedMessage
                          {...wikiMessages.resetCorrelationMatrix}
                        />
                      </Link>
                    ),
                    danubeClient: (
                      <i>
                        <FormattedMessage
                          {...messages.danubePredictionOverviewDanubeClient}
                        />
                      </i>
                    ),
                  }}
                />{' '}
                <FormattedMessage
                  {...apiMessages.danubeRecommendationResetMatrixWarning}
                  values={{
                    contactUs: (
                      <Link to="/contact" target="_blank">
                        <FormattedMessage
                          {...apiMessages.danubeRecommendationOverviewContactUs}
                        />
                      </Link>
                    ),
                  }}
                />
              </span>
            </li>
          </ul>
        </p>
      </div>
    );
  }
}

export default withStyles(s)(DanubePredicition);
