import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

// eslint-disable-next-line css-modules/no-unused-class
import s from './CherryTree.scss';
// eslint-disable-next-line css-modules/no-unused-class
import bs from './CherryTreeButtons.scss';
import messages from './messages';
import Comic from './Comic';

const fadeDuration = 2000;

function setComicViewedCookie(viewed) {
  Cookies.set('comic_viewed', viewed, { expires: 365 });
}

class FirstPage extends React.Component {
  static propTypes = {
    comicViewed: PropTypes.bool.isRequired,
    scrollToSecondPage: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.fadeOut = this.fadeOut.bind(this);
    this.fadeIn = this.fadeIn.bind(this);

    this.secondPageRef = React.createRef();

    if (props.comicViewed) {
      this.state = {
        animating: false,
        logoPageOpacity: 1,
        logoPageRendered: true,
      };
    } else {
      this.state = {
        animating: false,
        logoPageOpacity: 0,
        logoPageRendered: false,
      };
    }
  }

  componentWillUnmount() {
    if (this.fadeTimeout) {
      clearTimeout(this.fadeTimeout);
    }
  }

  fadeOut(onFinish) {
    this.setState({ animating: true, logoPageOpacity: 0 });

    this.fadeTimeout = setTimeout(() => {
      this.setState({ animating: false, logoPageRendered: false });

      if (onFinish) {
        onFinish();
      }
    }, fadeDuration);
  }

  fadeIn(onFinish) {
    this.setState({
      animating: true,
      logoPageRendered: true,
      logoPageOpacity: 1,
    });

    this.fadeTimeout = setTimeout(() => {
      this.setState({ animating: false });

      if (onFinish) {
        onFinish();
      }
    }, fadeDuration);
  }

  render() {
    return (
      <>
        <Comic
          startComicOnMount={!this.props.comicViewed}
          onComicFinished={() => {
            setComicViewedCookie(true);
            this.fadeIn();
          }}
          onComicRestart={onFinish => {
            this.fadeOut(onFinish);
          }}
          showRestartButton={this.state.logoPageOpacity === 1}
        />
        <div
          key="firstPage"
          className={s.firstPageLogo}
          style={{
            opacity: this.state.logoPageOpacity,
            pointerEvents: this.state.logoPageRendered ? 'auto' : 'none',
          }}
        >
          <div className={s.logoContainer}>
            <h1 className={s.title}>Cherry Tree</h1>
            <img src="/cherrytree/cherrytree-logo.png" alt="" />
            <h1 className={s.titleXs}>Cherry Tree</h1>
          </div>
          <div className={s.buttonSection}>
            <a
              href="https://discord.gg/9WByQwgyRs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className={`${bs.cherryTreeButton} ${bs.discordButton}`}
                disabled={this.state.animating}
              >
                <FormattedMessage {...messages.discordButton} />
                <img src="/cherrytree/discord.png" alt="" />
              </button>
            </a>
            <button
              className={`${bs.cherryTreeButton} ${bs.readMoreButton}`}
              onClick={this.props.scrollToSecondPage}
              disabled={this.state.animating}
            >
              <FormattedMessage {...messages.readMoreButton} />
              <img src="/cherrytree/readmore.png" alt="" />
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  locale: state && state.intl ? state.intl.locale : null,
});

export default connect(mapStateToProps)(withStyles(s, bs)(FirstPage));
