import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import {
  intlShape,
  injectIntl,
  defineMessages,
  FormattedMessage,
} from 'react-intl';

import render from 'components/ReduxForm/renderField';
import validations from '../../ReduxForm/validations';
import validationMessages from '../../ReduxForm/messages';
import { pageKeys } from '../keys';

const messages = defineMessages({
  pageKey: {
    id: 'analytics.logHashes.pageKey',
    defaultMessage: 'Page key',
    description: 'Label for field in hashes form',
  },
  description: {
    id: 'analytics.logHashes.description',
    defaultMessage: 'Short description',
    description: 'Label for field in hashes form',
  },
  submit: {
    id: 'analytics.logHashes.submit',
    defaultMessage: 'Create hash',
    description: 'Submit button text in hashes form',
  },
});

const validate = values => {
  const errors = {};

  if (!validations.required(values.pageKey)) {
    errors.pageKey = <FormattedMessage {...validationMessages.required} />;
  }

  return errors;
};

class HashesForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  render() {
    const { handleSubmit, intl } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <fieldset>
          <Row>
            <Col xs={12} md={4}>
              <Field
                id="pageKey"
                name="pageKey"
                placeholder={intl.formatMessage(messages.pageKey)}
                component={render.renderSelectAlt}
                isMulti={false}
                options={[
                  /* eslint-disable prettier/prettier */
                  { value: pageKeys.LANDING_PAGE, label: pageKeys.LANDING_PAGE },
                  { value: pageKeys.LANDING_PAGE_MEDIA, label: pageKeys.LANDING_PAGE_MEDIA },
                  { value: pageKeys.LANDING_PAGE_ECOMMERCE, label: pageKeys.LANDING_PAGE_ECOMMERCE },
                  { value: pageKeys.LANDING_PAGE_RECRUITING, label: pageKeys.LANDING_PAGE_RECRUITING },
                  { value: pageKeys.RECRUITING_DEMO, label: pageKeys.RECRUITING_DEMO },
                  /* eslint-enable prettier/prettier */
                ]}
              />
            </Col>
            <Col xs={12} md={8}>
              <Field
                id="description"
                name="description"
                placeholder={intl.formatMessage(messages.description)}
                type="text"
                component={render.renderInput}
              />
            </Col>
            <Col xs={12}>
              <button type="submit" className="btn-secondary btn-round">
                <FormattedMessage {...messages.submit} />
              </button>
            </Col>
          </Row>
        </fieldset>
      </form>
    );
  }
}

export default reduxForm({
  form: 'hashesForm',
  validate,
  pure: false, // this is necessary to trigger form re-renders if the locale changes
})(injectIntl(HashesForm));
