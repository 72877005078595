import atob from 'atob';
import { isNumeric } from './ColumnHelper';

export function parseCalibration(calibration) {
  const parsedCalibration = JSON.parse(JSON.stringify(calibration));
  if (parsedCalibration.rules) {
    try {
      parsedCalibration.rules = JSON.parse(atob(parsedCalibration.rules));
    } catch (e) {
      parsedCalibration.rules = JSON.parse(parsedCalibration.rules);
    }
  }

  if (parsedCalibration.columnTypes) {
    try {
      parsedCalibration.columnTypes = JSON.parse(
        atob(parsedCalibration.columnTypes),
      );
    } catch (e) {
      parsedCalibration.columnTypes = JSON.parse(parsedCalibration.columnTypes);
    }
  }

  if (parsedCalibration.columnSettings) {
    try {
      parsedCalibration.columnSettings = JSON.parse(
        atob(parsedCalibration.columnSettings),
      );
    } catch (e) {
      // TODO: for some reason sometimes not in base64
      parsedCalibration.columnSettings = JSON.parse(
        parsedCalibration.columnSettings,
      );
    }
  }

  if (parsedCalibration.columnScores) {
    try {
      parsedCalibration.columnScores = JSON.parse(
        atob(parsedCalibration.columnScores),
      );
    } catch (e) {
      parsedCalibration.columnScores = JSON.parse(
        parsedCalibration.columnScores,
      );
    }
  }

  if (parsedCalibration.rawData) {
    if (parsedCalibration.rawData.rules) {
      parsedCalibration.rawData.rules = JSON.parse(
        atob(parsedCalibration.rawData.rules),
      );
    }

    if (parsedCalibration.rawData.columnTypes) {
      parsedCalibration.rawData.columnTypes = JSON.parse(
        atob(parsedCalibration.rawData.columnTypes),
      );
    }

    if (parsedCalibration.rawData.rawData) {
      try {
        parsedCalibration.rawData.rawData = JSON.parse(
          atob(parsedCalibration.rawData.rawData),
        );
      } catch (e) {
        // TODO: for some reason sometimes not in base64
        parsedCalibration.rawData.rawData = JSON.parse(
          parsedCalibration.rawData.rawData,
        );
      }
    }
  }
  return parsedCalibration;
}

export function validateCalibration(calibration) {
  if (
    !calibration.columnSettings ||
    !calibration.columnScores ||
    !calibration.columnTypes ||
    !calibration.rules
  ) {
    return false;
  }

  if (!calibration.columnScores) {
    return false;
  }
  let columnScoresValid = true;
  calibration.columnScores.forEach(score => {
    if (!isNumeric(score)) {
      columnScoresValid = false;
    }
  });
  if (!columnScoresValid) return false;

  return true;
}
