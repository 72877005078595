import React from 'react';
import PropTypes from 'prop-types';
import { change } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import Slider from 'rc-slider';
import sliderStyle from 'rc-slider/assets/index.css';

import s from './Slider.scss';

class Slider_ extends React.Component {
  static propTypes = {
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.shape({
        id: PropTypes.string,
      }),
      warning: PropTypes.bool,
    }).isRequired,
    input: PropTypes.shape({
      value: PropTypes.number,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
    }).isRequired,
    formConfig: PropTypes.shape({
      formName: PropTypes.string.isRequired,
      fieldName: PropTypes.string.isRequired,
    }).isRequired,
    interval: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }).isRequired,
    stepSize: PropTypes.number.isRequired,
    labelFormatter: PropTypes.func,
    disabled: PropTypes.bool,
    changeFieldValue: PropTypes.func.isRequired,
  };

  static defaultProps = {
    labelFormatter: value => value,
    disabled: false,
  };

  handleValueChange = value => {
    const {
      formConfig: { formName, fieldName },
    } = this.props;

    this.props.changeFieldValue(formName, fieldName, value);
  };

  render() {
    const {
      meta: { touched, error, warning },
      input: { value },
      interval,
      stepSize,
      labelFormatter,
      disabled,
    } = this.props;

    const labelContainerClass = disabled
      ? `${s.labelContainer} ${s.disabled}`
      : s.labelContainer;

    return (
      <div className={s.container}>
        <div className={labelContainerClass}> {labelFormatter(value)} </div>
        <div className={s.sliderContainer}>
          <Slider
            min={interval.min}
            max={interval.max}
            step={stepSize}
            value={value}
            onChange={this.handleValueChange}
            disabled={disabled}
          />
        </div>
        {touched &&
          ((error && <p className="bg-danger">{error}</p>) ||
            (warning && <p className="bg-warning">{warning}</p>))}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (form, field, value) =>
    dispatch(change(form, field, value)),
});

export default withStyles(s, sliderStyle)(
  connect(
    null,
    mapDispatchToProps,
  )(Slider_),
);
