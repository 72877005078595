import React from 'react';

import PageProperties from 'components/PageProperties';
import Privacy from 'components/Privacy';
import routeMessages from 'routes/messages';

class PrivacyWrapper extends React.Component {
  render() {
    return (
      <div>
        <PageProperties
          page={{
            title: routeMessages.privacyTitle,
            description: routeMessages.privacyDescription,
            keywords: routeMessages.privacyKeywords,
          }}
        />
        <Privacy />
      </div>
    );
  }
}

export default PrivacyWrapper;
