import { defineMessages } from 'react-intl';

const geizhalsMessages = defineMessages({
  statistics_1: {
    id: 'showcase.geizhals.statistics_1',
    defaultMessage: 'Interaction Rate',
    description: 'Label for statistics_1 statistics module of showcase',
  },
  industryStandard: {
    id: 'showcase.geizhals.industryStandard',
    defaultMessage: 'Industry Average: {percent}%',
    description: 'Label for "industryStandard" statistics module of showcase',
  },
  statistics_2: {
    id: 'showcase.geizhals.statistics_2',
    defaultMessage: 'Conversion Rate',
    description: 'Label for statistics_2 statistics module of showcase',
  },
  statistics_3: {
    id: 'showcase.geizhals.statistics_3',
    defaultMessage: 'Net Conversion',
    description: 'Label for statistics_3 in statistics module of showcase',
  },
  statisticsSublabel_3: {
    id: 'showcase.geizhals.statisticsSublabel_3',
    defaultMessage: 'Conversion Per Unique Interaction',
    description: 'Sub-Label for statistics_3 in statistics module of showcase',
  },
  statisticsTitle: {
    id: 'showcase.geizhals.statisticsTitle',
    defaultMessage: 'Geizhals: Personalized Product Recommendations',
    description: 'Label for "statisticsTitle" statistics module of showcase',
  },
  quoteAttribution: {
    id: 'showcase.geizhals.quoteAttribution',
    defaultMessage: '{author}, Co-Founder',
    description:
      'Label for author of the quote in quote page of the Geizhals showcase',
  },
  customerQuote: {
    id: 'showcase.geizhals.customerQuote',
    defaultMessage:
      'The cool thing about this solution is that danube.ai finds out <i> why </i> a user browses a product. It works really well - without any user data.',
    description: 'Customer quote in quote page of the Geizhals showcase',
  },
  showcaseTextTitle: {
    id: 'showcase.geizhals.showcaseTextTitle',
    defaultMessage: 'Summary',
    description: 'Showcase Title for the text section',
  },
  showcaseText_1: {
    id: 'showcase.geizhals.showcaseText_1',
    defaultMessage:
      "Geizhals is the biggest platform for finding a product's best price in Austria.",
    description: 'Geizhals Showcase Text paragraphs',
  },
  showcaseText_2: {
    id: 'showcase.geizhals.showcaseText_2',
    defaultMessage:
      'If you browse a product on Geizhals, {danube} finds alternatives with similar or better price/value.',
    description: 'Geizhals Showcase Text paragraphs',
  },
  showcaseText_3: {
    id: 'showcase.geizhals.showcaseText_3',
    defaultMessage:
      'These recommendations are customized to your preferences without collecting any of your data.',
    description: 'Geizhals Showcase Text paragraphs',
  },
  showcaseText_4: {
    id: 'showcase.geizhals.showcaseText_4',
    defaultMessage:
      'Directly on the product page you can compare product features and even add an additional boost to their importance.',
    description: 'Geizhals Showcase Text paragraphs',
  },
  showcaseText_5: {
    id: 'showcase.geizhals.showcaseText_5',
    defaultMessage: 'Try out the solution to find the best phone for you',
    description: 'Geizhals Showcase Text paragraphs',
  },
  showcaseText_6: {
    id: 'showcase.geizhals.showcaseText_6',
    defaultMessage:
      'Start by selecting any phone in the list and select "similar products".',
    description: 'Geizhals Showcase Text paragraphs',
  },
});

export default geizhalsMessages;
