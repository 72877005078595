import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import s from './DataPreSettings.scss'; // eslint-disable-line css-modules/no-unused-class
import messages from '../messages';
import DataSettings from './DataSettings';

class DataSettingsPanel extends React.Component {
  static propTypes = {
    showImportSettings: PropTypes.bool,
    showGlobalMaximum: PropTypes.bool,
    showApplyButton: PropTypes.bool,
    enableRowAndColSettings: PropTypes.bool,
    formName: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    onDataSettingsApplied: PropTypes.func,
  };

  static defaultProps = {
    showImportSettings: false,
    showGlobalMaximum: false,
    showApplyButton: false,
    enableRowAndColSettings: false,
    onDataSettingsApplied: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const caretImage = this.state.expanded
      ? '/caret_expanded.png'
      : '/caret.png';

    return (
      <Panel
        className={s.settingsPanel}
        expanded={this.state.expanded}
        onToggle={this.toggleExpanded}
      >
        <Panel.Heading className={s.panelHeading}>
          <div>
            <img className={s.settingsIcon} src="/wheel.png" alt="" />
          </div>
          <div className={s.settingsLabel}>
            <FormattedMessage {...messages.settings} />
          </div>
          <button
            type="button"
            className={s.expandButton}
            onClick={this.toggleExpanded}
          >
            <img src={caretImage} alt="" />
          </button>
        </Panel.Heading>
        <Panel.Collapse>
          <Panel.Body className={s.panelBody}>
            <DataSettings
              formName={this.props.formName}
              showImportSettings={this.props.showImportSettings}
              showGlobalMaximum={this.props.showGlobalMaximum}
              showApplyButton={this.props.showApplyButton}
              enableRowAndColSettings={this.props.enableRowAndColSettings}
              onDataSettingsApplied={this.props.onDataSettingsApplied}
            />
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
    );
  }
}

export default DataSettingsPanel;
