import {
  pageKeys,
  componentKeys,
  eMailKeys,
  otherKeys,
  aggregatedKeys,
} from '../Analytics';

export const defaultDataSetOptions = {
  borderWidth: 2,
  fill: false,
};

/* eslint-disable prettier/prettier */
export const keyDataSetOptions = {
  // Landing page
  [pageKeys.LANDING_PAGE]: { label: 'LP', borderColor: 'rgba(13, 181, 255, 0.9)', tagFontColor: '#ffffff' },
  [componentKeys.MAIN_LANDING_PAGE_LEARN_MORE_BUTTON]: { label: 'LP -> Learn More', borderColor: 'rgba(13, 181, 255, 0.4)', tagFontColor: '#000000' },
  
  // show cases
  [pageKeys.GEIZHALS_SHOW_CASE]: { label: 'Geizhals Showcase', borderColor: 'rgba(35, 204, 198, 0.9)', tagFontColor: '#ffffff' },
  [componentKeys.GEIZHALS_DOWNLOAD_CASE_STUDY_BUTTON]: { label: 'Geizhals -> Download Case Study', borderColor: 'rgba(35, 204, 198, 0.4)', tagFontColor: '#000000' },

  [pageKeys.DEVJOBS_SHOW_CASE]: { label: 'DevJobs Showcase', borderColor: 'rgba(35, 204, 198, 0.9)', tagFontColor: '#ffffff' },
  [componentKeys.DEVJOBS_DOWNLOAD_CASE_STUDY_BUTTON]: { label: 'DevJobs -> Download Case Study', borderColor: 'rgba(35, 204, 198, 0.4)', tagFontColor: '#000000' },

  // media
  [pageKeys.LANDING_PAGE_MEDIA]: { label: 'Media', borderColor: 'rgba(168, 50, 72, 0.9)', tagFontColor: '#ffffff' },
  [componentKeys.MAIN_LANDING_PAGE_MEDIA_LINK]: { label: 'LP -> Media', borderColor: 'rgba(168, 50, 72, 0.65)', tagFontColor: '#000000' },
  [componentKeys.MEDIA_LANDING_PAGE_LEARN_MORE_BUTTON]: { label: 'Media -> Learn More', borderColor: 'rgba(168, 50, 72, 0.4)', tagFontColor: '#000000' },
  [componentKeys.MEDIA_LANDING_PAGE_CONTACT_BUTTON]: { label: 'Media -> Contact', borderColor: 'rgba(168, 50, 72, 0.4)', tagFontColor: '#000000' },
  // enable once implemented
  // [componentKeys.MEDIA_DEMO_LINK]: { label: 'Media -> Demo', borderColor: 'rgba(168, 50, 72, 0.4)', tagFontColor: '#000000' },
  // [componentKeys.MEDIA_DEMO_CONTACT_BUTTON]: { label: 'Media Demo -> Contact', borderColor: 'rgba(168, 50, 72, 0.4)', tagFontColor: '#000000' },

  // eCommerce
  [pageKeys.LANDING_PAGE_ECOMMERCE]: { label: 'eCommerce', borderColor: 'rgba(50, 168, 87, 0.9)', tagFontColor: '#ffffff' },
  [componentKeys.MAIN_LANDING_PAGE_ECOMMERCE_LINK]: { label: 'LP -> eCommerce', borderColor: 'rgba(50, 168, 87, 0.65)', tagFontColor: '#000000' },
  [componentKeys.ECOMMERCE_LANDING_PAGE_LEARN_MORE_BUTTON]: { label: 'eCommerce -> Learn More', borderColor: 'rgba(50, 168, 87, 0.4)', tagFontColor: '#000000' },
  [componentKeys.ECOMMERCE_LANDING_PAGE_CONTACT_BUTTON]: { label: 'eCommerce -> Contact', borderColor: 'rgba(50, 168, 87, 0.4)', tagFontColor: '#000000' },
  // enable once implemented
  // [componentKeys.ECOMMERCE_DEMO_LINK]: { label: 'eCommerce -> Demo', borderColor: 'rgba(50, 168, 87, 0.4)', tagFontColor: '#000000' },
  // [componentKeys.ECOMMERCE_DEMO_CONTACT_BUTTON]: { label: 'eCommerce Demo -> Contact', borderColor: 'rgba(50, 168, 87, 0.4)', tagFontColor: '#000000' },

  // recruiting
  [pageKeys.LANDING_PAGE_RECRUITING]: { label: 'Recruiting', borderColor: 'rgba(168, 146, 50, 0.9)', tagFontColor: '#ffffff' },
  [componentKeys.MAIN_LANDING_PAGE_RECRUITING_LINK]: { label: 'LP -> Recruiting', borderColor: 'rgba(168, 146, 50, 0.65)', tagFontColor: '#000000' },
  [componentKeys.RECRUITING_LANDING_PAGE_LEARN_MORE_BUTTON]: { label: 'Recruiting -> Learn More', borderColor: 'rgba(168, 146, 50, 0.4)', tagFontColor: '#000000' },
  [componentKeys.RECRUITING_LANDING_PAGE_CONTACT_BUTTON]: { label: 'Recruiting -> Contact', borderColor: 'rgba(168, 146, 50, 0.4)', tagFontColor: '#000000' },
  [componentKeys.RECRUITING_DEMO_LINK]: { label: 'Recruiting -> Demo', borderColor: 'rgba(168, 146, 50, 0.4)', tagFontColor: '#000000' },
  [componentKeys.RECRUITING_DEMO_CONTACT_BUTTON]: { label: 'Recruiting Demo -> Contact', borderColor: 'rgba(168, 146, 50, 0.4)', tagFontColor: '#000000' },

  // demos
  // enable once implemented
  // [pageKeys.MEDIA_DEMO]: { label: 'Media Demo', borderColor: 'rgba(168, 50, 72, 0.65)', tagFontColor: '#000000' },
  // [pageKeys.ECOMMERCE_DEMO]: { label: 'eCommerce Demo', borderColor: 'rgba(50, 168, 87, 0.65)', tagFontColor: '#000000' },
  [pageKeys.RECRUITING_DEMO]: { label: 'Recruiting Demo', borderColor: 'rgba(168, 146, 50, 0.65)', tagFontColor: '#000000' },

  // mails
  [eMailKeys.MEDIA_INQUIRY_EMAIL]: { label: 'Media mails', borderColor: 'rgba(46, 83, 219, 0.4)', tagFontColor: '#000000' },
  [eMailKeys.ECOMMERCE_INQUIRY_EMAIL]: { label: 'eCommerce mails', borderColor: 'rgba(46, 83, 219, 0.4)', tagFontColor: '#000000' },
  [eMailKeys.RECRUITING_INQUIRY_EMAIL]: { label: 'Recruiting mails', borderColor: 'rgba(46, 83, 219, 0.4)', tagFontColor: '#000000' },
  [eMailKeys.OTHER_FIELD_INQUIRY_EMAIL]: { label: 'Other field mails', borderColor: 'rgba(46, 83, 219, 0.4)', tagFontColor: '#000000' },

  // others
  [pageKeys.REGISTRATION]: { label: 'Registration', borderColor: 'rgba(50, 168, 168, 0.4)', tagFontColor: '#000000' },
  [pageKeys.CONTACT]: { label: 'Contact', borderColor: 'rgba(50, 168, 168, 0.4)', tagFontColor: '#000000' },
  [pageKeys.PRICING]: { label: 'Pricing', borderColor: 'rgba(50, 168, 168, 0.4)', tagFontColor: '#000000' },
  [componentKeys.TECHNOLOGY_CONTACT_BUTTON]: { label: 'Technology -> Contact', borderColor: 'rgba(168, 85, 40, 0.4)', tagFontColor: '#000000' },
  [componentKeys.GET_API_KEY_BUTTON]: { label: 'Get API key', borderColor: 'rgba(104, 50, 168, 0.9)', tagFontColor: '#ffffff' },
  [componentKeys.REQUEST_DEMO_BUTTON]: { label: 'Request Demo', borderColor: 'rgba(104, 50, 168, 0.9)', tagFontColor: '#ffffff' },
  [componentKeys.DOWNLOAD_WHITEPAPER_BUTTON]: { label: 'Download Whitepaper', borderColor: 'rgba(104, 50, 168, 0.9)', tagFontColor: '#ffffff' },
  [otherKeys.REGISTRATIONS]: { label: 'Registrations', borderColor: 'rgba(50, 168, 168, 0.4)', tagFontColor: '#000000' },
  [otherKeys.ACTIVATIONS]: { label: 'Activations', borderColor: 'rgba(50, 168, 168, 0.4)', tagFontColor: '#000000' },
  [otherKeys.CALIBRATIONS]: { label: 'Calibrations', borderColor: 'rgba(50, 168, 168, 0.4)', tagFontColor: '#000000' },

  // cherry tree
  [pageKeys.CHERRY_TREE]: { label: 'Cherry Tree', borderColor: 'rgba(199, 20, 77, 0.9)', tagFontColor: '#ffffff' },
  [componentKeys.CHERRY_TREE_DOWNLOAD_WHITEPAPER_BUTTON]: { label: 'Download CT Whitepaper', borderColor: 'rgba(199, 20, 77, 0.6)', tagFontColor: '#ffffff' },
  [componentKeys.CHERRY_TREE_DOWNLOAD_SCIENTIFIC_PAPER_BUTTON]: { label: 'Download CT Scientific Paper', borderColor: 'rgba(199, 20, 77, 0.6)', tagFontColor: '#ffffff' },
  [componentKeys.CHERRY_TREE_DOWNLOAD_PITCH_DECK_BUTTON]: { label: 'Download CT Pitch Deck', borderColor: 'rgba(199, 20, 77, 0.6)', tagFontColor: '#ffffff' },
  [componentKeys.CHERRY_TREE_DOWNLOAD_ARTICLE_BUTTON]: { label: 'Download CT Article', borderColor: 'rgba(199, 20, 77, 0.6)', tagFontColor: '#ffffff' },
};

export const aggregatedKeyDataDataSetOptions = {
  [aggregatedKeys.ALL_LEARN_MORE_BUTTONS]: { label: 'ALL -> Learn More', borderColor: 'rgba(219, 46, 196, 0.9)', tagFontColor: '#ffffff' },
  [aggregatedKeys.ALL_CONTACT_BUTTONS]: { label: 'ALL -> Contact', borderColor: 'rgba(219, 46, 196, 0.9)', tagFontColor: '#ffffff' },

  [aggregatedKeys.ALL_INQUIRY_MAILY]: { label: 'ALL mails', borderColor: 'rgba(46, 83, 219, 0.9)', tagFontColor: '#ffffff' },
};

export const allKeyDataDataSetOptions = {
  ...keyDataSetOptions,
  ...aggregatedKeyDataDataSetOptions,
};

/* eslint-enable prettier/prettier */
