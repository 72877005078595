exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Filter-filter-1nlES h2 {\n  margin-top: 0;\n  font-size: 1.2em; }\n\n.Filter-filter-1nlES button {\n  margin-right: 10px; }\n", "", {"version":3,"sources":["/builds/wa/danube-frontend/src/components/Analytics/Filter/Filter.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB,EAAE;;AAErB;EACE,mBAAmB,EAAE","file":"Filter.scss","sourcesContent":[".filter h2 {\n  margin-top: 0;\n  font-size: 1.2em; }\n\n.filter button {\n  margin-right: 10px; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"filter": "Filter-filter-1nlES"
};