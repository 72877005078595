import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Filter.scss';
import messages from './messages';
import FilterForm, { getLogsOptions } from './FilterForm';

const {
  accessLogsOptions,
  clickLogsOptions,
  eMailLogsOptions,
  otherLogsOptions,
  aggregatedLogsOptions,
} = getLogsOptions();

class Filter extends React.Component {
  static propTypes = {
    onFilterApply: PropTypes.func.isRequired,
    appliedValues: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
      user: PropTypes.string,
    }),
    logHashesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    appliedValues: {},
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleSubmit(formValues) {
    this.props.onFilterApply({ filter: formValues });
  }

  handleReset() {
    this.props.onFilterApply({
      filter: {
        accessLogs: [...accessLogsOptions],
        clickLogs: [...clickLogsOptions],
        otherLogs: [...otherLogsOptions],
        eMailLogs: [...eMailLogsOptions],
        aggregatedLogs: [...aggregatedLogsOptions],
        logHashes: [],
      },
    });
  }

  render() {
    const { appliedValues, logHashesOptions } = this.props;

    return (
      <div className={s.filter}>
        <h2>
          <FormattedMessage {...messages.heading} />
        </h2>
        <FilterForm
          initialValues={{
            ...appliedValues,
          }}
          onSubmit={this.handleSubmit}
          onReset={this.handleReset}
          logHashesOptions={logHashesOptions}
        />
      </div>
    );
  }
}

export default withStyles(s)(Filter);
