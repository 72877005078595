import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons'; // eslint-disable-line import/extensions

import s from './Comic.scss';
import messages from './messages';
import ComicTile from './ComicTile';

const tiles = [
  {
    image: '/cherrytree/comic/tile1.jpg',
    text: <FormattedMessage {...messages.comicText1} />,
    previous: false,
    next: true,
  },
  {
    image: '/cherrytree/comic/tile2.jpg',
    text: <FormattedMessage {...messages.comicText2} />,
    previous: true,
    next: true,
  },
  {
    image: '/cherrytree/comic/tile3.jpg',
    text: <FormattedMessage {...messages.comicText3} />,
    previous: true,
    next: true,
  },
  {
    image: '/cherrytree/comic/tile4.jpg',
    text: <FormattedMessage {...messages.comicText4} />,
    previous: true,
    next: true,
  },
  {
    image: '/cherrytree/comic/tile5.jpg',
    text: <FormattedMessage {...messages.comicText5} />,
    previous: true,
    next: true,
  },
  {
    image: '/cherrytree/comic/tile6.jpg',
    text: <FormattedMessage {...messages.comicText6} />,
    previous: true,
    next: true,
  },
];

const fadeDuration = 2000;
const fadePause = 500;

class Comic extends React.Component {
  static propTypes = {
    startComicOnMount: PropTypes.bool.isRequired,
    onComicFinished: PropTypes.func.isRequired,
    onComicRestart: PropTypes.func.isRequired,
    showRestartButton: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.fadeOut = this.fadeOut.bind(this);
    this.fadeIn = this.fadeIn.bind(this);
    this.gotoPrevTile = this.gotoPrevTile.bind(this);
    this.gotoNextTile = this.gotoNextTile.bind(this);
    this.finishComic = this.finishComic.bind(this);

    this.state = { currentTile: 0, animating: false, tileOpacity: 0 };
  }

  componentDidMount() {
    // preload images to prevent lag
    tiles.forEach(tile => {
      const img = new Image();
      img.src = tile.image;
    });

    if (this.props.startComicOnMount) {
      this.fadeTimeout = setTimeout(() => {
        this.fadeIn();
      }, 1000);
    }
  }

  componentWillUnmount() {
    if (this.fadeTimeout) {
      clearTimeout(this.fadeTimeout);
    }
  }

  fadeOut(onFinish) {
    this.setState({ animating: true, tileOpacity: 0 });

    this.fadeTimeout = setTimeout(() => {
      this.setState({ animating: false });

      if (onFinish) {
        onFinish();
      }
    }, fadeDuration);
  }

  fadeIn(onFinish) {
    this.setState({ animating: true, tileOpacity: 1 });

    this.fadeTimeout = setTimeout(() => {
      this.setState({ animating: false });

      if (onFinish) {
        onFinish();
      }
    }, fadeDuration);
  }

  gotoPrevTile() {
    if (this.state.animating) {
      return;
    }

    if (this.state.currentTile <= 0) {
      return;
    }

    this.fadeOut(() => {
      this.setState({ currentTile: this.state.currentTile - 1 });

      this.fadeTimeout = setTimeout(() => {
        this.fadeIn();
      }, fadePause);
    });
  }

  gotoNextTile() {
    if (this.state.animating) {
      return;
    }

    this.fadeOut(() => {
      this.fadeTimeout = setTimeout(() => {
        if (this.state.currentTile < tiles.length - 1) {
          this.setState({ currentTile: this.state.currentTile + 1 });
          this.fadeIn();
        } else {
          this.props.onComicFinished();
        }
      }, fadePause);
    });
  }

  finishComic() {
    if (this.state.animating) {
      return;
    }

    this.fadeOut(() => {
      this.props.onComicFinished();
    });
  }

  render() {
    return (
      <>
        <div className={s.comic}>
          <div className={s.topSpacer} />
          <ComicTile
            {...tiles[this.state.currentTile]}
            opacity={this.state.tileOpacity}
            gotoPrevTile={this.gotoPrevTile}
            gotoNextTile={this.gotoNextTile}
            onSkip={this.finishComic}
            showButtons={!this.state.animating}
          />
          <div className={s.bottomSpacer} />
        </div>
        <button
          className={s.restartButton}
          disabled={!this.props.showRestartButton || this.state.animating}
          onClick={() => {
            this.props.onComicRestart(() => {
              this.setState({ currentTile: 0 });
              this.fadeIn();
            });
          }}
          style={{ opacity: this.props.showRestartButton ? 1 : 0 }}
        >
          <FontAwesomeIcon icon={faUndo} />
        </button>
      </>
    );
  }
}

export default withStyles(s)(Comic);
