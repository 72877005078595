import React from 'react';
import PropTypes from 'prop-types';
import { /* Query , */ graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { Collapse } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  FaCaretUp,
  FaCaretDown,
  FaAngleLeft,
  FaAngleDoubleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
  FaHistory,
  FaGroup,
  FaHandGrabO,
  FaEur,
  FaCircle,
  FaClockO,
} from 'react-icons/lib/fa';

import { superHeroShape, filterShape } from '../../constants/types';

import Loading from '../../../../components/Loading';
import s from './ResultsList.scss'; // eslint-disable-line css-modules/no-unused-class
import globalDemoMessages from '../../../globalDemoMessages';
import messages from './messages';
import searchMaskMessages from '../SearchMask/messages';

const danubeTagsMap = {
  experience: messages.experienceTag,
  travelTime: messages.travelTimeTag,
  salary: messages.salaryTag,
  strength: messages.strengthTag,
  social: messages.socialTag,
  superPowers: messages.superPowersTag,
  benefits: messages.benefitsTag,
};

const strengthMap = {
  'Level 1': searchMaskMessages.strengthLevel1,
  'Level 2': searchMaskMessages.strengthLevel2,
  'Level 3': searchMaskMessages.strengthLevel3,
  'Level 4': searchMaskMessages.strengthLevel4,
};

const socialMap = {
  'Level 1': searchMaskMessages.socialLevel1,
  'Level 2': searchMaskMessages.socialLevel2,
  'Level 3': searchMaskMessages.socialLevel3,
  'Level 4': searchMaskMessages.socialLevel4,
};

class ResultsList extends React.Component {
  static propTypes = {
    filter: filterShape,
    superHeroesQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      superHeroes: PropTypes.shape({
        superHeroes: PropTypes.arrayOf(superHeroShape.isRequired),
        columnKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
        columnScores: PropTypes.arrayOf(PropTypes.number).isRequired,
      }),
    }),
    onLoadingFinished: PropTypes.func,
  };

  static defaultProps = {
    filter: {},
    superHeroesQuery: null,
    onLoadingFinished: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
      itemsPerPage: 10,
      extendedEntries: {},
    };

    this.getLastPage = this.getLastPage.bind(this);
    this.toStart = this.toStart.bind(this);
    this.toEnd = this.toEnd.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.renderButtonBar = this.renderButtonBar.bind(this);
    this.renderSuperHero = this.renderSuperHero.bind(this);
  }

  componentDidUpdate(prevProps) {
    // close all extended entries on new query and go back to page 1
    if (prevProps.filter !== this.props.filter) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        currentPage: 0,
        extendedEntries: {},
      });
    }

    if (
      prevProps.superHeroesQuery &&
      prevProps.superHeroesQuery.loading &&
      this.props.superHeroesQuery &&
      !this.props.superHeroesQuery.loading
    ) {
      this.props.onLoadingFinished();
    }

    if (!this.props.superHeroesQuery) return;

    const {
      superHeroesQuery: { loading },
    } = this.props;

    if (loading) return;

    const { currentPage } = this.state;

    // clamp page to last page
    const lastPage = this.getLastPage();
    if (currentPage > lastPage) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ currentPage: lastPage });
    }
  }

  getLastPage() {
    const { superHeroesQuery } = this.props;

    if (superHeroesQuery.loading) return 0;
    if (!superHeroesQuery.superHeroes) return 0;

    return Math.floor(
      Math.max(0, superHeroesQuery.superHeroes.superHeroes.length - 1) /
        this.state.itemsPerPage,
    );
  }

  toStart() {
    this.setState({
      currentPage: 0,
    });
  }

  toEnd() {
    const {
      superHeroesQuery: { loading },
    } = this.props;

    if (loading) return;

    this.setState({
      currentPage: this.getLastPage(),
    });
  }

  nextPage() {
    this.setState({
      currentPage: Math.min(this.getLastPage(), this.state.currentPage + 1),
    });
  }

  prevPage() {
    this.setState({
      currentPage: Math.max(0, this.state.currentPage - 1),
    });
  }

  renderButtonBar() {
    return (
      <div className={s.buttonBar}>
        <button className="btn-primary" onClick={this.toStart}>
          <FaAngleDoubleLeft />
        </button>
        <button className="btn-primary" onClick={this.prevPage}>
          <FaAngleLeft />
        </button>
        <div className={s.pageCounter}>
          {this.state.currentPage + 1} / {this.getLastPage() + 1}
        </div>
        <button className="btn-primary" onClick={this.nextPage}>
          <FaAngleRight />
        </button>
        <button className="btn-primary" onClick={this.toEnd}>
          <FaAngleDoubleRight />
        </button>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSuperHero({ superHero, index, perfectDanubeScore }) {
    // calculate match score and map it to [0, 100]
    let matchScore = parseFloat(superHero.danubeScore / perfectDanubeScore);
    matchScore = Math.max(0, Math.min(100, Math.round(matchScore * 100)));

    // get css-class for match score container
    let matchScoreClass = '';
    if (matchScore >= 50) {
      matchScoreClass = s.optimalScore;
    } else if (matchScore >= 25) {
      matchScoreClass = s.goodScore;
    } else {
      matchScoreClass = s.mediumScore;
    }

    return (
      <div key={superHero.name} className={s.listItemContainer}>
        {/*
        <div className={s.imageWrapper}>
          <div className={s.imageWrapperInner}>
            <img src={`/demos/avengers/images/${superHero.name}.jpg`} alt="" />
          </div>
        </div>
        */}
        <div className={s.contentWrapper}>
          <div className={s.infoContainer}>
            <div className={s.scoreWrapper}>
              <span className={s.titleContainer}>
                <span>
                  {index + 1}. {superHero.name}
                </span>
                <span className={matchScoreClass}>{matchScore}% Match</span>
              </span>
            </div>
            <div className={s.infoLine}>
              {superHero.salary && (
                <div className={s.priceInfo}>
                  <FaEur />
                  &nbsp;
                  {superHero.salary}
                </div>
              )}
              {superHero.experience && (
                <div>
                  <FaHistory />
                  &nbsp;
                  {superHero.experience}
                  &nbsp;
                  <FormattedMessage {...globalDemoMessages.years} />
                </div>
              )}
              {superHero.strength && (
                <div>
                  <FaHandGrabO />
                  &nbsp;
                  <FormattedMessage {...strengthMap[superHero.strength]} />
                </div>
              )}
              {superHero.social && (
                <div>
                  <FaGroup />
                  &nbsp;
                  <FormattedMessage {...socialMap[superHero.social]} />
                </div>
              )}
              {superHero.travelTime !== null && (
                <div>
                  <FaClockO /> {Math.round(superHero.travelTime)} min
                </div>
              )}
            </div>
          </div>
          <div className={s.tagsList}>
            {superHero.superPowers &&
              superHero.superPowers.map(superPower => (
                <div key={superPower} className={s.tag}>
                  <FaCircle /> {superPower}
                </div>
              ))}
          </div>
          <div className={s.benefitsContainer}>
            <button
              onClick={() => {
                const newExtendedEntries = { ...this.state.extendedEntries };
                newExtendedEntries[superHero.name] = !newExtendedEntries[superHero.name]; // eslint-disable-line
                this.setState({
                  extendedEntries: newExtendedEntries,
                });
              }}
            >
              {this.state.extendedEntries[superHero.name] ? (
                <FaCaretUp />
              ) : (
                <FaCaretDown />
              )}
              &nbsp;
              <FormattedMessage {...messages.benefitsTag} />
              :&nbsp;
              {superHero.benefits ? superHero.benefits.length : 0}
            </button>
            <Collapse in={this.state.extendedEntries[superHero.name]}>
              <div>
                <ul>
                  {superHero.benefits &&
                    superHero.benefits.map(benefit => (
                      <li key={benefit} className={s.tag}>
                        {benefit}
                      </li>
                    ))}
                </ul>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { superHeroesQuery } = this.props;

    if (!superHeroesQuery) return null;

    if (superHeroesQuery.loading) {
      return <Loading />;
    }

    if (!superHeroesQuery.superHeroes) return null;

    const {
      superHeroes: { superHeroes, columnKeys, columnScores },
    } = superHeroesQuery;

    // select page of superheroes
    const page = superHeroes.slice(
      this.state.currentPage * this.state.itemsPerPage,
      (this.state.currentPage + 1) * this.state.itemsPerPage,
    );

    // normalize column scores
    const total = columnScores.reduce((sum, score) => sum + score, 0);
    const normalizedColumnScores = columnScores.map(
      score => (score / total) * 100,
    );

    // get max column score
    const maxColumnScore = Math.max(...normalizedColumnScores);

    // get the perfect danube score
    const perfectDanubeScore = columnScores.reduce(
      (scoreSum, score) => scoreSum + score,
      0,
    );

    return (
      <div className={s.resultsListContainer}>
        <div className={s.metadataContainer}>
          <div className={s.resultsWrapper}>
            <span className={s.label}>
              {superHeroes.length}
              &nbsp;
              <FormattedMessage {...globalDemoMessages.resultsFound} />
            </span>
          </div>
          {normalizedColumnScores.length > 0 && (
            <div>
              {normalizedColumnScores.map((score, index) => (
                <div
                  key={`normalizedColumnScore-${index}`} // eslint-disable-line react/no-array-index-key
                  className={
                    score === maxColumnScore
                      ? s.danubeWrapper
                      : s.danubeWrapperLight
                  }
                >
                  <span className={s.label}>
                    <FormattedMessage {...danubeTagsMap[columnKeys[index]]} />
                    :&nbsp;
                    {Math.round(score)}%
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>
        {this.renderButtonBar()}
        <div className={s.listItemsContainer}>
          {superHeroes.length === 0 ? (
            <div className={s.noResultsContainer}>
              <FormattedMessage {...globalDemoMessages.noResults} />
            </div>
          ) : (
            <div>
              {page.map((superHero, index) => {
                const rowIndex =
                  this.state.currentPage * this.state.itemsPerPage + index;
                return this.renderSuperHero({
                  superHero,
                  index: rowIndex,
                  perfectDanubeScore,
                });
              })}
            </div>
          )}
        </div>
        {this.renderButtonBar()}
      </div>
    );
  }
}

const superHeroesQuery = gql`
  query superHeroesQuery($filter: SuperHeroesFilter) {
    superHeroes(filter: $filter) {
      superHeroes {
        name
        experience
        travelTime
        salary
        strength
        social
        superPowers
        benefits
        danubeScore
        matches
      }
      columnKeys
      columnScores
    }
  }
`;

export default compose(
  graphql(superHeroesQuery, {
    name: 'superHeroesQuery',
    options: ({ filter }) => ({
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
    }),
    skip: ({ skipPageLoadQuery }) => skipPageLoadQuery,
  }),
)(withStyles(s)(ResultsList));
