import PropTypes from 'prop-types';

/* eslint-disable prettier/prettier */
const personShapeObj = {};
personShapeObj.name = PropTypes.string;
personShapeObj.salaryLevel = PropTypes.number;
personShapeObj.age = PropTypes.number;
personShapeObj.prevEmployerCount = PropTypes.string;
personShapeObj.experienceGeneral = PropTypes.number;
personShapeObj.experienceOffice = PropTypes.number;
personShapeObj.educationLevel = PropTypes.string;
personShapeObj.msOfficeLevel = PropTypes.number;
personShapeObj.germanLevel = PropTypes.number;
personShapeObj.englishLevel = PropTypes.number;
personShapeObj.otherLanguages = PropTypes.number;
personShapeObj.additionalSkills = PropTypes.arrayOf(PropTypes.string);
export const personShape = PropTypes.shape(personShapeObj);

const filterShapeObj = {};
filterShapeObj.prevEmployerCount = PropTypes.string;
filterShapeObj.salaryImportance = PropTypes.number;
filterShapeObj.experienceOfficeImportance = PropTypes.number;
filterShapeObj.educationLevel = PropTypes.string;
filterShapeObj.msOfficeLevelImportance = PropTypes.number;
filterShapeObj.germanLevelImportance = PropTypes.number;
filterShapeObj.englishLevelImportance = PropTypes.number;
filterShapeObj.additionalSkills = PropTypes.arrayOf(PropTypes.string);
/* eslint-enable prettier/prettier */

export const filterShape = PropTypes.shape(filterShapeObj);
