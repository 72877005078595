import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import layoutStyle from '../../styles/base/layout.scss'; // eslint-disable-line css-modules/no-unused-class
import s from './Privacy.scss';
import messages from './messages';

const renderParagraph = (content, header = null) => (
  <div>
    {header && <h2>{header}</h2>}
    <p className={s.paragraph}>{content}</p>
  </div>
);

const renderList = (items, no, start = 1) => (
  <div>
    <ul className={s.list}>
      {items.map((item, index) => (
        <li key={item.props.id}>
          {`(${no}.${index + start}) `}
          {item}
        </li>
      ))}
    </ul>
  </div>
);

class Privacy extends React.Component {
  render() {
    return (
      <div className={s.privacyContainer}>
        <Row className={layoutStyle.noMargin}>
          <Col mdOffset={1} md={4}>
            <h1>
              <FormattedMessage {...messages.privacyHeader} />
            </h1>
          </Col>
          <Col md={6}>
            {renderParagraph(
              <FormattedMessage {...messages.policyContent} />,
              <FormattedMessage {...messages.policyHeader} />,
            )}
            {renderParagraph(
              <FormattedMessage {...messages.contactContent} />,
              <FormattedMessage {...messages.contactHeader} />,
            )}
            {renderParagraph(
              <FormattedMessage {...messages.storageContent} />,
              <FormattedMessage {...messages.storageHeader} />,
            )}
            {renderList(
              [
                <FormattedMessage {...messages.username} />,
                <FormattedMessage {...messages.email} />,
                <FormattedMessage {...messages.password} />,
                <FormattedMessage {...messages.locale} />,
                <FormattedMessage {...messages.date} />,
                <FormattedMessage {...messages.tokens} />,
                <FormattedMessage {...messages.status} />,
              ],
              3,
            )}
            {renderParagraph(<FormattedMessage {...messages.storageLogin} />)}
            {renderParagraph(
              <FormattedMessage {...messages.thirdPartyLogin} />,
            )}
            {renderList(
              [
                <FormattedMessage {...messages.thirdParty} />,
                <FormattedMessage {...messages.username} />,
                <FormattedMessage {...messages.email} />,
              ],
              3,
              8,
            )}
            {renderParagraph(<FormattedMessage {...messages.hubspot} />)}
            {renderList(
              [
                <FormattedMessage {...messages.firstname} />,
                <FormattedMessage {...messages.lastname} />,
                <FormattedMessage {...messages.email} />,
                <FormattedMessage {...messages.company} />,
              ],
              4,
              11,
            )}
            {renderParagraph(<FormattedMessage {...messages.hubspot2} />)}
            {renderParagraph(
              <FormattedMessage {...messages.storageServices} />,
            )}
            {renderParagraph(<FormattedMessage {...messages.storageEu} />)}
            {renderParagraph(
              <FormattedMessage {...messages.purchaseStorage} />,
              <FormattedMessage {...messages.purchaseHeader} />,
            )}
            {renderParagraph(<FormattedMessage {...messages.purchaseData} />)}
            {renderList(
              [
                <FormattedMessage {...messages.purchaseDataBuyer} />,
                <FormattedMessage {...messages.purchaseDataProduct} />,
                <FormattedMessage {...messages.purchaseDataDate} />,
              ],
              4,
            )}
            {renderParagraph(
              <FormattedMessage {...messages.purchaseDataRequirement} />,
            )}
            {renderParagraph(
              <FormattedMessage {...messages.cookieDescription} />,
              <FormattedMessage {...messages.cookieHeader} />,
            )}
            {renderParagraph(<FormattedMessage {...messages.cookieStorage} />)}
            {renderParagraph(<FormattedMessage {...messages.cookieDeny} />)}
            {renderParagraph(
              <FormattedMessage {...messages.analysisDescription} />,
              <FormattedMessage {...messages.analysisHeader} />,
            )}
            {renderParagraph(<FormattedMessage {...messages.analysisLegal} />)}
            {renderParagraph(<FormattedMessage {...messages.analysisPeriod} />)}
            {renderParagraph(
              <FormattedMessage {...messages.dataDeletionContent} />,
              <FormattedMessage {...messages.dataDeletionHeader} />,
            )}
            {renderParagraph(
              <FormattedMessage {...messages.privacyRightsContent} />,
              <FormattedMessage {...messages.privacyRightsHeader} />,
            )}
            {renderParagraph(
              <FormattedHTMLMessage {...messages.privacyContactDetails} />,
            )}
            {renderParagraph(<FormattedMessage {...messages.updated} />)}
            {renderParagraph(
              <FormattedMessage {...messages.apendixA} />,
              <FormattedMessage {...messages.apendixAHeader} />,
            )}
            {renderParagraph(
              <FormattedMessage {...messages.apendixA5} />,
              <FormattedMessage {...messages.apendixA5Header} />,
            )}
            {renderParagraph(<FormattedMessage {...messages.apendixA51} />)}
            {renderParagraph(<FormattedMessage {...messages.apendixA52} />)}
            {renderParagraph(<FormattedMessage {...messages.footer} />)}
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(s, layoutStyle)(Privacy);
